import {
  AppBar, Backdrop, Dialog, DialogContent, IconButton, InputAdornment, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Toolbar, Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { PuffLoader } from "react-spinners";
import { useStyles } from "./ProspectUserCss";
import { styled } from "@mui/material/styles";
import { usePost } from "../../utils/apiHelper";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@material-ui/icons/Close";
import { ARMLogo } from "../../constants/Constant";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import { useGlobalState } from "../../store/GlobalStore";
import { Transition } from "../GlobalStyles/DialogBoxTransition";
import { IProspectUsersHistory, IProspectUsersDetails } from "../../models/accesscontrol/ProspectUsersDetails";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ViewHistory: React.FC<{ userRow: IProspectUsersDetails; userFirstName: String; userLastName: String; }> = (props) => {
  const { userRow, userFirstName, userLastName } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [prospectUserHistory, setProspectUserHistory] = useState<IProspectUsersHistory[]>([]);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const { state } = useGlobalState();
  const [searchParameters, setSearchParameters] = useState<string>("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  let EmailSentToValues;

  const handleClickOpen = () => {
    setOpen(true);
    getProspectHistory();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProspectHistory = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        prospectUserId: userRow?.prospectUserId,
      };
      await usePost<{ prospectUserHistoryResult: IProspectUsersHistory[] }>( "User/GetProspectUserHistory", request).then((GetAllUsersHistory) => {
          setProspectUserHistory(GetAllUsersHistory?.data["prospectUserHistoryResult"]);
        })
        .finally(() => {
          setShowProgressBar(false);
        });
    })();
  };

  const handleSearchEmail = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        prospectUserId: userRow,
        searchParameters: searchParameters,
      };
      await usePost<IProspectUsersHistory[]>("User/GetProspectUserHistory",request).then((result) => {
          setProspectUserHistory(result?.data["prospectUserHistoryResult"]);
        }).finally(() => {
          setShowProgressBar(false);
        });
    })();
  };

  const handleSearchEmailClear = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        prospectUserId: userRow,
        searchParameters: "",
      };
      await usePost<IProspectUsersHistory[]>( "User/GetProspectUserHistory", request).then((result) => {
          setProspectUserHistory(result?.data["prospectUserHistoryResult"]);
        }).finally(() => {
          setSearchParameters("");
          setShowProgressBar(false);
        });
    })();
  };

  const handleChangeValues = (e) => {
    setSearchParameters(e.target.value);
  }

  function clearSearchValues() {
    setSearchParameters("");
    setShowSearchIcon(true);
    handleSearchEmailClear();
  }

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      // {
      //   id: "organizationName",
      //   disablePadding: false,
      //   label: "ORGANIZATION NAME",
      //   sortable: false,
      // },
      {
        id: "activityName",
        disablePadding: false,
        label: "ACTIVITY NAME",
        sortable: false,
      },
      {
        id: "oldEmailAddress",
        disablePadding: false,
        label: "OLD EMAIL ADDRESS",
        sortable: false,
      },
      {
        id: "newEmailAddress",
        disablePadding: false,
        label: "NEW EMAIL ADDRESS",
        sortable: false,
      },
      {
        id: "actionTakenBy",
        disablePadding: false,
        label: "ACTION TAKEN BY",
        sortable: false,
      },
      {
        id: "emailSendto",
        disablePadding: false,
        label: "EMAIL SENT TO",
        sortable: false,
      },
      {
        id: "message",
        disablePadding: false,
        label: "DESCRIPTION",
        sortable: false,
      },
      {
        id: "actionTakenDate",
        disablePadding: false,
        label: "ACTION DATE",
        sortable: false,
      },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div>
      <IconButton style={{ padding: "5px" }} onClick={handleClickOpen}>
        <HistoryIcon style={{ color: "#B30987" }} />
      </IconButton>

      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarStyle}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <img
                alt="ARM_Solutions"
                className={classes.menuButton1}
                src={ARMLogo}
              />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <b>
                USER SIGNUP AND ACTIVATION HISTORY ({userFirstName} {userLastName} - {userRow?.prospectEmailAddress} - FROM : {userRow?.organizationName}) 
              </b>
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
              style={{ padding: "5px" }}
            >
              <CloseIcon className={classes.closeIconButton} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent>
          <TextField
            value={searchParameters}
            variant="outlined"
            type="text"
            placeholder="Search..."
            className={classes.searchBox}
            onChange={(e) => { handleChangeValues(e); }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && searchParameters != "") {
                handleSearchEmail();
                setShowSearchIcon(false);
              } else if (event.key === "Enter" && searchParameters === "") {
                handleSearchEmailClear();
                setShowSearchIcon(true);
              }
            }}
            inputProps={{ maxlength: 255 }}
            InputProps={{
              classes: {
                root: classes.outlinedInputs,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showSearchIcon === true ? (
                    <IconButton
                      onClick={(e) => {
                        handleSearchEmail();
                        setShowSearchIcon(false);
                      }}
                      style={{ marginLeft: 15 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => {
                        setSearchParameters("");
                        clearSearchValues();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TableContainer
            component={Paper}
            className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}
          >
            <Table size="small" aria-label="customized table" stickyHeader>
              <EnhancedTableHead classes={classes} />
              {prospectUserHistory?.map((row) => (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.activityName?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                        {row?.activityName}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.oldAdminEmail?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                        {row?.oldAdminEmail}
                      </span>
                    </TableCell>
                    <TableCell>
                    <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.updatedAdminEmail?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                    {row?.updatedAdminEmail}
                  </span>
                    </TableCell>
                    <TableCell>
                      <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.actionTakenBy?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                        {row?.actionTakenBy}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.emailSentTo?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                        {EmailSentToValues = (row?.emailSentTo)?.split(",")?.map((emailsentTo) => {
                          return (
                            <div>
                              {emailsentTo}
                            </div>
                          )
                        })}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`${searchParameters?.toLocaleLowerCase() === "" ? classes.tableCellFontSize : row?.message?.toLowerCase()?.includes(searchParameters?.toLowerCase()) ? classes.TextHighlight : classes.tableCellFontSize}`}>
                        {EmailSentToValues = (row?.message)?.split("\n")?.map((description) => {
                          return (
                            <div>
                              {description}
                            </div>
                          )
                        })}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" className={classes.tableCellFontSize}>
                        {moment(row?.actionTakenDate)?.format(state?.GlobalUtils?.settingValue)}{" "}
                        {moment(row?.actionTakenDate).format("HH:mm")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
            {!prospectUserHistory?.length ? (
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                className={classes.ErrorMessageStyle1}
              >
                No history to display..
              </Typography>
            ) : null}
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewHistory;
