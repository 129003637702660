import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
    AppBar, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Hidden,
    IconButton, makeStyles, TextField, Toolbar, Typography
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SendIcon from "@material-ui/icons/Send";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { msalConfig } from '../auth/authConfig';
import { StyledBadge } from '../components/GlobalStyles/ActiveUser';
import { Transition } from '../components/GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import { ARMLogo } from '../constants/Constant';
import EmailBlock from '../images/header/emailblock.svg';
import EmailBlock1 from '../images/header/emailblock1.svg';
import AwaitinDocs from "../images/Placement/AwaitingDocs.svg";
import AgentUser from '../images/users/AgentUser.svg';
import GlobalAdmin from '../images/users/ClientAdmin.svg';
import FileAdmin from '../images/users/FileAdmin.svg';
import ClientAdmin from '../images/users/GlobalAdmin.svg';
import RegularUser from '../images/users/RegularUser.svg';
import SuperAdmin from '../images/users/SuperAdmin.svg';
import { AccessContext } from '../models/accesscontrol/AccessContext';
import { agentAccessContext } from '../models/accesscontrol/agentAccessContext';
import { IWidgetInfo } from '../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../models/accesscontrol/WidgetMap';
import { IAgentClientCode } from '../models/AgentUser/IAgentClientDetails';
import { IUserInvited } from '../models/common/IUserInvited';
import { IFTPFiles } from '../models/Files/IFTPFiles';
import { GlobalDateTimeUtil } from '../models/GlobalUtil/GlobalDateTimeUtil';
import { IGlobalUtil } from '../models/GlobalUtil/IGlobalUtil';
import Routes from '../Routes';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import { getUserInitials } from '../utils/stringUtils';
import NavigationDrawer from './NavigationDrawer';
import SessionTimeout from './SessionTimeout';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        width: 170
    },
    userName: {
        marginRight: 10
    },
    clientAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    globalAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    regularUser: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    fileAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    logout: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        border: '1px solid',
        marginLeft: '5px'
    },
    logout1: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        border: '1px solid',
        marginLeft: "10px"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    description: {
        width: '100%',
    },
    text: {
        marginLeft: "35%"
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    dialogbox: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        height: '100 %'
    },
    titleHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Image: {
        height: 80,
        width: 80
    },
    textfield: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20,
        color: 'green'
    },
    textfield1: {
        textAlign: 'center',
        marginTop: "3px",
        marginBottom: 15,
        margin: theme.spacing(1),
        fontSize: 16
    },
    appBarStyle: {
        backgroundColor: 'white',
        color: 'black',
        marginLeft: '-10px',
        width: '101%'
    },
    logoimage: {
        width: "140px",
        height: "45px"
    },
    toolbarStyle: {
        minHeight: '50px',
        color: 'black',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    toolbarHeader: {
        minHeight: '50px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    sendbutton: {
        marginLeft: "5cm",
        marginTop: "0.5cm",
        paddingBottom: "10px",
        width: 165,
        borderRadius: 10,
    },
    BlockEmailStyle: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    text2: {
        textAlign: 'center',
        margin: theme.spacing(1),
        fontSize: 17
    },
    emailColor: {
        color: 'blue'
    },
    deleteIcon: {
        color: 'red',
        fontSize: '35px',
        display: 'flex'
    },
    ButtonPadding: {
        padding: '0px'
    },
    yesButton: {
        background: "green",
        color: 'white',
        borderRadius: 20,
        fontSize: 12,
        right: '15px',
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        borderRadius: 20,
        display: 'flex',
        color: 'white',
        right: '10px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    MessageStyle: {
        color: "black",
        fontSize: 18
    },
    progress: {
        marginRight: 10,
        // marginTop: "12px"
    }
}))

const Header = () => {
    let history = useHistory();
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
    const allowedRoutes = Routes.filter(r => { return r.UserRole?.some(role => role == state.userAccessContext?.role) });
    const allowedAgentRoutes = Routes.filter(r => { return r.AgentUserRole?.some(role => role == state?.AgentUserAccessContext?.userRole) })
    const [finalRoutes, setFinalRoutes] = useState([]);
    const [finalAgentRoutes, setFinalAgentRoutes] = useState([]);
    const [message, setMessage] = useState<string>("");
    const [inputVal, setinputVal] = useState('');
    const [success, setSuccess] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [emailBlock, setemailBlock] = React.useState(false);
    const timer = React.useRef<number>();
    const [IsTriggered, setIsTriggered] = useState(false);
    const [userStatus, setUserStatus] = useState(false);
    const [CheckInvited, setCheckInvited] = useState<number>(0);
    const [blockedId, setBlockedId] = useState<string>("");
    const [BlockEmail, setBlockEmail] = useState(false);
    const [isAwaitingDocs, setIsAwaitingDocs] = useState(false);
    const [confirmDialogBox, setConfirmDialogBox] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isAgent, setIsAgent] = useState(false);

    useEffect(() => {
        (async () => {
            let request = { "email": state?.AgentUserAccessContext?.emailAddress }
            await usePost<any>("AgentUser/CheckAgentUser", request).then((r) => {
                setIsAgent(r?.data[0]?.isAgentUser);
            }).catch(() => {
                setIsAgent(false);
            });
        })()
    }, [state?.AgentUserAccessContext?.emailAddress, state?.userAccessContext?.userName])

    function closeMobileDrawer() {
        setMobileDrawerOpen(false);
    }

    // Inspired by the microsoft spinners.
    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    useEffect(() => {
        (async () => {
            let filteredRoutes = allowedRoutes.filter(x => !x.noNavLink);
            let reportId = filteredRoutes.findIndex(x => x.title === "Reports");
            let placementId = filteredRoutes.findIndex(x => x.title === "Placement");
            let rollId = filteredRoutes.findIndex(x => x.title === "Roll");
            let SettingsId = filteredRoutes.findIndex(x => x.title === "Settings");
            if (state.userAccessContext?.editReports === false) {
                delete filteredRoutes[reportId];
            }
            if (state.userAccessContext?.rollAccounts === false) {
                delete filteredRoutes[rollId];
            }
            if (state.userAccessContext?.placeAccounts === false) {
                delete filteredRoutes[placementId];
            }
            if (state.userAccessContext?.createNewUsers === false
                && state.userAccessContext?.clientConfirmation === false) {
                delete filteredRoutes[SettingsId];
            }
            setFinalRoutes(filteredRoutes);
        })()
    }, [state.userAccessContext?.role])

    useEffect(() => {
        (async () => {
            let filteredRoutes = allowedAgentRoutes.filter(x => !x.noNavLink);
            let agentReportsId = filteredRoutes?.findIndex(x => x.title === "Agent Report");
            let agentCaseCreatedReportsId = filteredRoutes?.findIndex(x => x.path === "/agentcasecreatedreport");
            let notification = filteredRoutes?.findIndex(x => x.path === "/agentNotifications");
            let agendashboard = filteredRoutes?.findIndex(x => x.path === "/agentdashboard");
            if (state.AgentUserAccessContext?.agentUsersReport === false) {
                delete filteredRoutes[agentReportsId];
            }           
            if (!state.AgentUserAccessContext?.backupDocument  && !state.AgentUserAccessContext?.disputeValidation  && !state.AgentUserAccessContext?.paymentVerification ) {
                delete filteredRoutes[agendashboard];
                delete filteredRoutes[notification];
            }

            setFinalAgentRoutes(filteredRoutes);
        })()
    }, [state.AgentUserAccessContext?.userRole])

    const signout = () => {
        window.onbeforeunload = null;
        setConfirmDialogBox(false);
        instance.logout({
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        })
    }

    const handleSwitchToOriginalUser = () => {
        setUserStatus(true);
        let requestGuid = { "objectId": state?.user?.objectId };
        usePost<AccessContext>("User/GetUserByObjectId", requestGuid).then((userObj) => {
            useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userObj.data.id).then((clientsWidgets) => {
                for (let index = 1; index <= WidgetMap.size; index++) {
                    const value = WidgetMap.get(index);
                    if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                        userObj.data[value] = true;
                    }
                    let IAgentUserAccessContext: agentAccessContext;
                    let IAgentClientDetails: IAgentClientCode[] = [];
                    dispatch({ type: GlobalStateAction.AgentUserAccessContext, AgentUserAccessContext: IAgentUserAccessContext });
                    dispatch({ type: GlobalStateAction.AgentClientDetails, AgentClientDetails: IAgentClientDetails });
                    dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userObj.data });
                };
                if (userObj.data.role === 2 || userObj.data.role === 3) {
                    history.push('/admin');
                    setUserStatus(false);
                } else if (userObj.data.role === 0 || userObj.data.role === 1) {
                    history.push('/dashboard')
                    setUserStatus(false);
                } else {
                    history.push('/ManageClientFolderAccess')
                    setUserStatus(false);
                }
            });
        }).catch(() => {
            setUserStatus(false);
        });
    };

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={30}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={30}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    useEffect(() => {
        (async () => {
            await useFetch<GlobalDateTimeUtil>("GlobalSettings/GetDateSettings").then((r) => {
                dispatch({
                    type: GlobalStateAction.GlobalUtils, GlobalUtils: {
                        enumKey: r.data.enumKey,
                        settingName: r.data.settingName,
                        settingValue: r.data.settingValue
                    }
                });
            })
        })()
    }, [isAuthenticated])

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, [message]);

    const handleDialogClose = () => {
        setOpen(false);
        setSuccess(false);
        setemailBlock(false);
        setMessage("");
        setinputVal('');
        setConfirmDialogBox(false);
    };

    const handleAwaitDocDialogClose = () => {
        setIsAwaitingDocs(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsTriggered(false);
    };
    useEffect(() => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state.userAccessContext?.id}`).then((r) => {
                    setCheckInvited(r.data[0]?.user_id);
                });
            }
        })()
    }, []);

    const SendBeginReorderEmail = () => {
        (async () => {
            try {
                if (CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) {
                    let request = {
                        "Username": state?.userAccessContext?.userName,
                        "clientName": state?.userAccessContext?.userName?.split("@")[0],
                        "clientID": state?.userAccessContext?.clientCodes?.join(","),
                        "message": message
                    }
                    await usePost<any>("Account/SendBeginReorderEmail", request);
                    setIsTriggered(true);
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }
    useEffect(() => {
        (async () => {
            await useFetch<IGlobalUtil[]>("GlobalSettings/GetAllSettings").then((GetAllGlobalSettings) => {
                setBlockEmail(GetAllGlobalSettings.data[8].is_Active);
                setBlockedId(GetAllGlobalSettings.data[8].settingValue);
            })
        })()
    }, [])

    const handleSettlementOfferClick = () => {
        SendBeginReorderEmail();
        setOpen(false);
        handleDialogClose();
    }

    useEffect(() => {
        (async () => {
            let request = {
                "searchText": "",
                "userId": state?.userAccessContext?.id
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<IFTPFiles[]>("Placements/GetFtpFilesbyUser", request).then((GetAllFtp) => {
                setIsAwaitingDocs(GetAllFtp?.data?.length > 0 ? true : false);
            });
        })()
    }, [state?.userAccessContext?.id])

    return (
        <React.Fragment>
            {isAgent ?
                <React.Fragment>
                    <AppBar position="static" style={{ backgroundColor: 'white' }}>
                        <Toolbar component="nav" className={`${classes.toolbarStyle} ${"app-header"}`}>
                            <img alt="ARM_Solutions Logo" className={classes.logoimage} src={ARMLogo} />
                            <Box display="flex" alignItems="center" alignContent="center">
                                <Hidden smDown>
                                    <Typography variant="h6" component="h6" className={classes.progress}>
                                        {userStatus  === true ? <Progress /> : null}
                                    </Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography variant="h6" component="h6" className={classes.progress}>
                                        {!state?.AgentUserAccessContext?.firstName?.length ? <Progress /> : state?.AgentUserAccessContext?.firstName + " " + state?.AgentUserAccessContext?.lastName}
                                    </Typography>
                                </Hidden>
                                <IconButton size="medium" aria-label="menu" style={{ marginLeft: 10 }} onClick={handleSwitchToOriginalUser} >
                                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                        <Avatar className={classes.superAdminColor} src={AgentUser}> {getUserInitials(state?.user?.name ?? "")} </Avatar>
                                    </StyledBadge>
                                </IconButton>
                                <LightTooltip title="Contact Us">
                                    <IconButton id="Contact" className={classes.logout} onClick={() => { setOpen(true) }} >
                                        <HeadsetMicIcon fontSize="inherit" />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title="Sign-out">
                                    <IconButton id="signout" className={classes.logout1} onClick={() => { setConfirmDialogBox(true); }}>
                                        <ExitToAppIcon fontSize="inherit" />
                                    </IconButton>
                                </LightTooltip>
                                {BlockEmail ?
                                    <div className="zoom-in-out-box" onClick={() => { setemailBlock(true) }} >
                                        <LightTooltip title="Block Email">
                                            <img src={EmailBlock} width="30px" height="30px" />
                                        </LightTooltip>
                                    </div>
                                    : null
                                }
                            </Box>
                            <Hidden mdUp>
                                <IconButton
                                    onClick={() => setMobileDrawerOpen(true)}
                                    aria-label="Open Navigation"
                                >
                                    <MenuIcon fontSize="large" color="primary" />
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                        <Hidden smDown>
                            <Toolbar component="nav" className={`${classes.toolbarHeader} ${"app-header-navigation"}`}>
                                {finalAgentRoutes.map((route, key) => {
                                    return (
                                        <NavLink
                                            id="Header_NavigationLink"
                                            style={{ fontSize: '15px' }}
                                            className="header"
                                            to={route.path}
                                            key={route.title}
                                            activeStyle={{ borderBottom: '3px solid white', fontWeight: 'bold' }}
                                        >
                                            {route.title}
                                        </NavLink>
                                    )
                                })}
                            </Toolbar>
                        </Hidden>
                        <NavigationDrawer
                            open={mobileDrawerOpen}
                            onClose={closeMobileDrawer}
                        />
                    </AppBar>

                    <SessionTimeout isAuthenticated={isAuthenticated} />

                    <Dialog TransitionComponent={Transition} open={open} keepMounted onClose={handleDialogClose} maxWidth={'sm'}
                        PaperProps={{ style: { borderRadius: 10 } }}
                        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" gutterBottom className={classes.titleheader}>
                                CONTACT US
                            </Typography>
                        </DialogTitleHeader>
                        <DialogContent>
                            <Grid item xs={12} >
                                <TextField
                                    id="BeginReorder_MessageBox"
                                    variant="outlined"
                                    placeholder="Contact us"
                                    size="medium"
                                    multiline
                                    rows={6}
                                    value={message}
                                    onChange={(e) => { setMessage(e.target.value); setinputVal(e.target.value); }}
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.description}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </Grid>
                            <Button
                                id="BeginReorder_SendNotifications"
                                variant="contained"
                                color="primary"
                                className={`${classes.sendbutton} ${classes.buttonSuccess}`}
                                disabled={!inputVal}
                                onClick={() => { handleSettlementOfferClick(); }}
                                endIcon={success ? "" : <SendIcon />}
                            >
                                Send Message
                            </Button>
                            <Typography variant="subtitle1" className={classes.text} gutterBottom>
                                Phone: (888) 772-6468
                            </Typography>
                        </DialogContent>
                    </Dialog>

                    <Dialog TransitionComponent={Transition}
                        fullScreen={fullScreen} open={confirmDialogBox} onClose={handleDialogClose} maxWidth={'xs'}
                        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                        <DialogTitleDelete id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" className={classes.titleheader}>
                                CONFIRMATION MESSAGE
                            </Typography>
                        </DialogTitleDelete>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="h6" className={classes.MessageStyle}>
                                    Are you sure you want to Sign-out?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions >
                            <Button id="DU_ok_btn" size="small" variant="contained" onClick={signout} className={classes.yesButton}>
                                Yes
                            </Button>
                            <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog TransitionComponent={Transition} open={emailBlock} keepMounted
                        onClose={handleDialogClose}
                        PaperProps={{
                            style: {
                                border: "1px solid #0D324D",
                                borderRadius: 10,
                                boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                            }
                        }}
                        maxWidth={'sm'}
                        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" gutterBottom className={classes.BlockEmailStyle}>
                                BLOCK EMAIL
                            </Typography>
                        </DialogTitleHeader>
                        <DialogContent>
                            <DialogTitle className={classes.titleHeader}>
                                <img src={EmailBlock1} alt="Block_Email" className={classes.Image} />
                            </DialogTitle>
                            <Typography variant="h5" gutterBottom className={classes.textfield}>
                                Email Feature Flow Up
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.textfield1}>
                                The Email feature has been blocked, All the Emails will be flow through this Email ID "{blockedId}".
                            </Typography>
                        </DialogContent>
                    </Dialog>

                    <Snackbar open={IsTriggered} className="snackbarStyle" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                            Message Sent Successfully!
                        </Alert>
                    </Snackbar>
                </React.Fragment>
                :
                <React.Fragment >
                    <AppBar position="static" style={{ backgroundColor: 'white' }}>
                        <Toolbar component="nav" className={`${classes.toolbarStyle} ${"app-header"}`}>
                            <img alt="ARM_Solutions Logo" className={classes.logoimage} src={ARMLogo} />
                            <Box display="flex" alignItems="center" alignContent="center">
                                <Hidden smDown>
                                    <Typography variant="h6" component="h6" className={classes.progress}>
                                        {userStatus === true ? <Progress /> : null}
                                    </Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography variant="h6" component="h6" className={classes.progress}>
                                        {!state?.userAccessContext?.firstName.length ? <Progress /> : state?.userAccessContext?.firstName + " " + state?.userAccessContext.lastName}
                                    </Typography>
                                </Hidden>
                                <IconButton size="medium" aria-label="menu" style={{ marginLeft: 10 }} onClick={handleSwitchToOriginalUser} >
                                    {state.userAccessContext?.role === 3 ?
                                        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                            <Avatar className={classes.superAdminColor} src={SuperAdmin}> {getUserInitials(state?.user?.name ?? "")} </Avatar>
                                        </StyledBadge>
                                        : state.userAccessContext?.role === 2 ?
                                            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                <Avatar className={classes.globalAdmin} src={GlobalAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                            </StyledBadge>
                                            : state.userAccessContext?.role === 1 ?
                                                <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                    <Avatar className={classes.clientAdmin} src={ClientAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                                </StyledBadge>
                                                : state.userAccessContext?.role === 0 ?
                                                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                        <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                                    </StyledBadge>
                                                    : <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                        <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                                    </StyledBadge>
                                    }
                                </IconButton>
                                <LightTooltip title="Contact Us">
                                    <IconButton id="Contact" className={classes.logout} onClick={() => { setOpen(true) }} >
                                        <HeadsetMicIcon fontSize="inherit" />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title="Sign-out">
                                    <IconButton id="signout" className={classes.logout1} onClick={() => { setConfirmDialogBox(true); }}>
                                        <ExitToAppIcon fontSize="inherit" />
                                    </IconButton>
                                </LightTooltip>
                                {BlockEmail ?
                                    <div className="zoom-in-out-box" onClick={() => { setemailBlock(true) }} >
                                        <LightTooltip title="Block Email">
                                            <img src={EmailBlock} width="30px" height="30px" />
                                        </LightTooltip>
                                    </div>
                                    : null
                                }
                            </Box>
                            <Hidden mdUp>
                                <IconButton
                                    onClick={() => setMobileDrawerOpen(true)}
                                    aria-label="Open Navigation"
                                >
                                    <MenuIcon fontSize="large" color="primary" />
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                        <Hidden smDown>
                            <Toolbar component="nav" className={`${classes.toolbarHeader} ${"app-header-navigation"}`}>
                                {finalRoutes.map((route, key) => {
                                    return (
                                        <NavLink
                                            id="Header_NavigationLink"
                                            style={{ fontSize: '15px' }}
                                            className="header"
                                            to={route.path}
                                            key={route.title}
                                            activeStyle={{ borderBottom: '3px solid white', fontWeight: 'bold' }}
                                        >
                                            {route.title}
                                        </NavLink>
                                    )
                                })}
                            </Toolbar>
                        </Hidden>
                        <NavigationDrawer
                            open={mobileDrawerOpen}
                            onClose={closeMobileDrawer}
                        />
                    </AppBar>

                    <SessionTimeout isAuthenticated={isAuthenticated} />

                    <Dialog TransitionComponent={Transition} open={open} keepMounted onClose={handleDialogClose} maxWidth={'sm'}
                        PaperProps={{ style: { borderRadius: 10 } }}
                        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" gutterBottom className={classes.titleheader}>
                                CONTACT US
                            </Typography>
                        </DialogTitleHeader>
                        <DialogContent>
                            <Grid item xs={12} >
                                <TextField
                                    id="BeginReorder_MessageBox"
                                    variant="outlined"
                                    placeholder="Contact us"
                                    size="medium"
                                    multiline
                                    rows={6}
                                    value={message}
                                    onChange={(e) => { setMessage(e.target.value); setinputVal(e.target.value); }}
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.description}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </Grid>
                            <Button
                                id="BeginReorder_SendNotifications"
                                variant="contained"
                                color="primary"
                                className={`${classes.sendbutton} ${classes.buttonSuccess}`}
                                disabled={!inputVal}
                                onClick={() => { handleSettlementOfferClick(); }}
                                endIcon={success ? "" : <SendIcon />}
                            >
                                Send Message
                            </Button>
                            <Typography variant="subtitle1" className={classes.text} gutterBottom>
                                Phone: (888) 772-6468
                            </Typography>
                        </DialogContent>
                    </Dialog>

                    <Dialog TransitionComponent={Transition}
                        fullScreen={fullScreen} open={confirmDialogBox} onClose={handleDialogClose} maxWidth={'xs'}
                        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                        <DialogTitleDelete id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" className={classes.titleheader}>
                                CONFIRMATION MESSAGE
                            </Typography>
                        </DialogTitleDelete>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="h6" className={classes.MessageStyle}>
                                    Are you sure you want to Sign-out?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions >
                            <Button id="DU_ok_btn" size="small" variant="contained" onClick={signout} className={classes.yesButton}>
                                Yes
                            </Button>
                            <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog TransitionComponent={Transition} open={emailBlock} keepMounted
                        onClose={handleDialogClose}
                        PaperProps={{
                            style: {
                                border: "1px solid #0D324D",
                                borderRadius: 10,
                                boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                            }
                        }}
                        maxWidth={'sm'}
                        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                            <Typography variant="h6" gutterBottom className={classes.BlockEmailStyle}>
                                BLOCK EMAIL
                            </Typography>
                        </DialogTitleHeader>
                        <DialogContent>
                            <DialogTitle className={classes.titleHeader}>
                                <img src={EmailBlock1} alt="Block_Email" className={classes.Image} />
                            </DialogTitle>
                            <Typography variant="h5" gutterBottom className={classes.textfield}>
                                Email Feature Flow Up
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.textfield1}>
                                The Email feature has been blocked, All the Emails will be flow through this Email ID "{blockedId}".
                            </Typography>
                        </DialogContent>
                    </Dialog>

                    <Dialog TransitionComponent={Transition} open={isAwaitingDocs} keepMounted
                        PaperProps={{ style: { borderRadius: 10 } }}
                        maxWidth={'md'}
                        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleAwaitDocDialogClose}>
                            <Typography variant="h5" gutterBottom className={classes.BlockEmailStyle}>
                                YOU HAVE ACCOUNTS TO PLACE FOR COLLECTION
                            </Typography>
                        </DialogTitleHeader>
                        <DialogContent>
                            <DialogTitle className={classes.titleHeader}>
                                <img src={AwaitinDocs} alt="AwaitingDocs" className={classes.Image} />
                            </DialogTitle>
                            <Typography variant="h6" gutterBottom className={classes.text2}>
                                We would like to bring to your attention that there are Placement files that have been transmitted to us by you that are waiting on our FTP / Server for your approval.
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.text2}>
                                If you no longer want to place the file you can remove it using the Delete Icon <IconButton className={classes.ButtonPadding}> <DeleteForeverIcon className={classes.deleteIcon} /> </IconButton> during the placement process.
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.text2}>
                                If you have any questions contact "<b className={classes.emailColor}>clientservices@armsolutions.com</b>"
                            </Typography>
                        </DialogContent>
                    </Dialog>

                    <Snackbar open={IsTriggered} className="snackbarStyle" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                            Message Sent Successfully!
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            }
        </React.Fragment >
    )
}

export default Header