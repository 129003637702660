import {
    Backdrop, Box, Button, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableContainer,
    TableHead, TablePagination, TableRow, Theme,
    Toolbar, Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import printImg from "../../images/ACKReport/AckPrint.svg";
import download from "../../images/files/download01.svg";
import { IPerformanceReportsValuesResult } from '../../models/PerformanceReport/IPerformanceReportsValuesResult';
import { Columns } from '../../models/PerformanceReport/ReportConfig';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import Footer from '../Footer/Footer';
import LightTooltip from '../GlobalStyles/LightTooltip';
import { StyledTableCell } from '../GlobalStyles/TableStyledCell';
import PerformanceBuilder from './PerformanceBuilder';


const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridPadding: {
            padding: '8px'
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            fontSize: 12,
            fontWeight: 'bold',
            padding: '8px 5px !important'
        },
        tableHeadRadius: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px'
        },
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            minHeight: '35px'
        },
        paper: {
            width: '100%',
        },
        titleColor: {
            color: "blue",
            fontWeight: 'bold',
            marginTop: '10px',
            marginBottom: '10px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        footerBoxStyle: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        tablebody: {
            maxHeight: 700,
            minHeight: 700,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 218px)',
                minHeight: 'calc( 100vh - 218px)',
            },
        },
        noRecordsStyle: {
            color: "red",
            marginTop: "5px",
            fontSize: 18
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        },
        textdisplay: {
            color: 'black',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        footer: {
            marginTop: '15px',
            float: 'right',
            marginRight: '90px'
        },
        appBar: {
            position: 'relative',
            color: 'white',
            backgroundColor: '#007fff',
            minHeight: '50px'
        },
        menuButton: {
            marginRight: theme.spacing(1),
            width: 180
        },
        printTitle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 20,
            textAlign: 'center'
        },
        CloseIconColor: {
            color: 'white'
        },
        downloadIconStyle: {
            color: "#a1a1a1",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '12px'
        },
        downloadIconStyle1: {
            color: "#2377E4",
            fontSize: 29,
            // marginTop: '5px',
            marginLeft: '12px'
        },
        printStyle: {
            borderRadius: 30,
            marginTop: '12px',
            padding: '2px 2px'
        },
        armlogo: {
            width: 170
        },
        fabProgressPrint: {
            color: green[500],
            position: 'absolute',
            top: 3,
            left: 8,
            zIndex: 1,
        },
        floatTopRight: {
            backgroundColor: 'white',
            top: '15px',
            right: '25px',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            height: '70px',
        },
        tableContainerPadding: {
            padding: '15px',
            marginTop: '20px'
        },
        summeryBoxShadow: {
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        },
        boxStyle: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: '#316b97',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '430px',
            height: '220',
            marginTop: '25px'
        },
        sumGap: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: 'green',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '450px',
            height: '200px',
            marginTop: '25px'
        },
        sumGap1: {
            gap: '5%',
            marginLeft: '10px',
            borderRadius: 15,
            color: 'black',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '500px',
            height: '280px',
            marginTop: 15,
            marginRight: 30,
            flexDirection: 'column'
        },
        sumGap2: {
            gap: '5%',
            fontWeight: 'bold',
            marginTop: '8px',
            fontSize: 27,
            color: 'black'
        },
        sumGap3: {
            gap: '5%',
            fontWeight: 'bold',
            marginTop: '8px',
            fontSize: 19,
            color: 'black'
        },
        sumGap4: {
            gap: '5%',
            marginTop: '8px',
            fontSize: 16,
            color: 'green'
        },
        sumGap5: {
            gap: '5%',
            marginTop: '8px',
            fontSize: 18,
            color: 'black'
        },
        GridStyle: {
            maxHeight: '30%'
        },
        div: {
            flexGrow: 1,
        },
        GridBoxStyle: {
            marginTop: '15px'
        },
        tablebodyPrint: {
            overflowX: 'hidden',
            marginTop: 5

        },
        textStyle: {
            textAlign: 'right',
            marginTop: '20px',
            fontSize: 16
        },
        textStyle1: {
            textAlign: 'right',
            marginTop: '20px',
            marginRight: 10,
            fontSize: 16
        },
        totalRecord: {
            gap: '5%',
            marginRight: '10px',
            backgroundColor: '#316b97',
            borderRadius: 15,
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '360px',
            height: '130px',
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        report: {
            overflowX: 'hidden'
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            marginRight: 20,
        },
        tableCellColor: {
            color: "white",
            fontWeight: 'bold',
            fontSize: '12px !important',
            padding: '5px !important'
        },
        tableCellColor1: {
            fontSize: '12px !important',
            padding: '12px !important'
        },
        wrapper: {
            position: 'relative',
        },
        backbutton: {
            borderRadius: 20,
            fontSize: 11,
            fontWeight: 'bold',
            background: "#007FFF",
            color: "white",
            marginTop: '10px',
            marginRight: '10px'
        },
        bottomFooter: {
            marginTop: '-5px',
            marginBottom: '5px'
        },
        tablefont: {
            fontSize: 12
        },
        tableHeader: {
            overflow: 'wrap',
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            background: "#007FFF",
        },
        maingrid: {
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        },
    })
);


const PerformanceReportBuilder = (props) => {
    const classes = useRowStyles();
    const { state } = useGlobalState();

    const [PerformanceReportValue, setPerformanceReportValue] = useState<IPerformanceReportsValuesResult[]>([]);
    const [TotalRecords, setTotalRecords] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(0);

    const [loading, setLoading] = useState(false);

    let controlValue: string = props?.location?.state?.controlValue;
    let startDate: string = props?.location?.state?.startDate;
    let endDate: string = props?.location?.state?.endDate;
    let performanceReportName: string = props?.location?.state?.reportName;
    let ProcedureName: string = props?.location?.state?.procedureName;

    useEffect(() => {
        setLoading(true);
        (async () => {
            let request = {
                "clientNo": controlValue,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": moment(endDate).format("YYYY-MM-DD"),
                "dbT_Desk": "All",
                "transCutOffDate": "",
                "customerGroup": "",
                "procedureName": ProcedureName
            }
            await usePost<{ totalRecordCount: number, reports: IPerformanceReportsValuesResult[] }>("GenerateContingencySingleClientReport", request).then((r) => {
                setPerformanceReportValue(r.data["reports"]);
                setTotalRecords(r.data?.totalRecordCount);
            });
        })().finally(() => {
            setLoading(false);
        })
    }, [])

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const EnhancedTableToolbar = () => {
        const classes = useRowStyles();
        return (
            <Toolbar className={`${classes.root} ${classes.tableHeadRadius}`}>
                <Grid container spacing={0}>
                    <Box p={0} flexShrink={0} width="87%" display="flex" >
                        <Typography variant="h6" className={`${classes.titleColor} ${"headertitle"}`}>
                            {`${performanceReportName} Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                        </Typography>
                    </Box>
                    <Box display="flex" width="13%">
                        <div className={classes.wrapper}>
                            <LightTooltip title="Back">
                                <Button id="PLC_Back_btn" className={classes.backbutton}
                                    variant="contained" color="primary" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}>
                                    Back
                                </Button>
                            </LightTooltip>
                        </div>
                        <div className={classes.wrapper}>
                            <LightTooltip title="Print Reports List">
                                <IconButton className={classes.printStyle}>
                                    <img src={printImg} alt="Icon for Print" width="25px" height="25px" className={classes.downloadIconStyle1} />
                                </IconButton>
                            </LightTooltip>
                        </div>
                        <div className={classes.wrapper}>
                            <LightTooltip title="Download Reports List">
                                <IconButton className={classes.printStyle}>
                                    <img src={download} alt="Icon for Download" width="30px" height="30px" className={classes.downloadIconStyle1} />
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </Box>
                </Grid>
            </Toolbar>
        );
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.gridPadding}>
                    <Grid item xs={12} component={Paper} className={classes.maingrid}>
                        <EnhancedTableToolbar />
                        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table id="PreReportsDetailList" aria-label="customized table" size="small" stickyHeader>
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        {Columns.map((headCell) => (
                                            <StyledTableCell style={{ fontSize: 11, color: 'white' }}
                                                align={headCell.type == "string" ? "left" : "left"}
                                                className={classes.tableHeaderStyle}
                                                key={headCell.id}>
                                                <b>{headCell.field.toUpperCase()}</b>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {PerformanceReportValue?.map((r) => {
                                        return (
                                            <PerformanceBuilder row={r} />
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                            {!loading && !PerformanceReportValue.length ? <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                No records to display...
                            </Typography> : null}
                        </TableContainer>
                        <Grid container spacing={0} className={classes.footerBoxStyle}>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Inquiry_TablePagination"
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={TotalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    );
}

export default PerformanceReportBuilder;