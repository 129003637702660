import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
      marginBottom: "0%",
      width: "100%",
      marginTop: "15px",
    },
  },
  formControlCtEt: {
    width: "100%",
    borderRadius: "10px",
    marginTop: "15px",
  },
  grid: {
    flexGrow: 1,
    marginTop: "-5px",
  },
  boxview: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    marginBottom: "5px",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      "& .MuiTypography-root:first-child": {
        color: "green",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  boxviewWidgetsNotifications: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      "& .MuiTypography-root:first-child": {
        color: "green",
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  titleHeader: {
    fontWeight: "bold",
    marginTop: "5px",
    color: "white",
    fontSize: 18,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  titleHeaderStyle: {
    fontWeight: "bold",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  phoneNumberFieldStyle: {
    borderRadius: "10px",
    minWidth: "100%",
    fontSize: 16,
  },
  dialogactions: {
    float: "right",
    marginTop: "2px",
    marginBottom: "2px",
  },
  submitbtn: {
    borderRadius: 20,
    backgroundColor: "green",
    padding: "3px 12px",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "green",
    },
  },
  cancelbtn: {
    borderRadius: 20,
    backgroundColor: "red",
    padding: "3px 12px",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "red",
    },
  },
  dialogHeaderStyle: {
    marginLeft: "15px",
    marginTop: "10px",
    fontSize: 12,
    fontWeight: "bold",
  },
  FileFolderStyle: {
    fontSize: "15px",
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingTop: "5px",
    "& .MuiIconButton-root": {
      padding: "0px 8px",
    },
  },
  userStyle: {
    fontSize: "15px",
    fontWeight: "bold",
    "& .MuiIconButton-root": {
      padding: "0px 8px",
    },
  },
  GreenColor: {
    color: "green",
  },
  clientCodeBox: {
    width: "100%",
    marginTop: "5px",
    borderRadius: "4px 4px 8px 8px",
    "& .MuiOutlinedInput-root": {
      borderBottom: "3px solid blue",
      borderRadius: "4px 4px 8px 8px",
      padding: '0px !important;',
    },
  },
  dropdownStyle: {
    width: "100%",
    padding: "0px 10px",
    fontSize: 12,
  },
  cloudfolderStyle: {
    borderRadius: "10px",
  },
  fileContainsStyle: {
    width: "100%",
    margin: "10px 0px 10px 10px",
    borderRadius: "10px",
    paddingRight: "20px",
    "& .MuiFormHelperText-contained": {
      fontSize: "11px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  fileContainsStyle1: {
    width: "100%",
    margin: "10px 0px 10px 10px",
    borderRadius: "10px",
    paddingRight: "20px",
    "& .MuiFormHelperText-contained": {
      fontSize: "11px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  paymentFileContainsStyle: {
    width: "100%",
    margin: "10px 0px 10px 10px",
    borderRadius: "10px",
    paddingRight: "20px",
  },
  ackfolderStyle: {
    borderRadius: "10px",
  },
  ackfileContainsStyle: {
    width: "100%",
    margin: "10px 0px 10px 10px",
    borderRadius: "10px",
    paddingRight: "20px",
  },
  cloudFolderStyle: {
    width: "100%",
    marginTop: "11px",
    borderRadius: "10px",
    fontSize: 12,
  },
  EditTextStyle: {
    fontWeight: "bold",
    marginTop: "5px",
    color: "white",
  },
  paper1: {
    border: "2px solid black",
  },
  yesCrtEtButton: {
    background: "red",
    color: "white",
    fontSize: 12,
    borderRadius: 20,
    right: "15px",
    "&:hover": {
      background: "red",
    },
  },
  MessageStyle: {
    fontSize: "17px",
    color: "black",
    fontWeight: "bold",
  },
  checkBoxStyle: {
    marginLeft: "5px",
  },
  permission: {
    margin: 0,
    fontSize: 14,
  },
  dialogContentTextStyle: {
    marginTop: "10px",
    border: "1px solid blue",
    boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.6)",
  },
  boxStyle: {
    border: "1px solid blue",
  },
  boxStyle1: {
    border: "1px solid blue",
    padding: "2px",
  },
  textFieldBorder: {
    borderRadius: "5px",
    fontSize: "12px",
  },
  colPadding: {
    padding: "0px 5px",
    "& .MuiTypography-body1": {
      fontSize: "13px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "13px",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "13px",
    },
  },
  colorGreen: {
    color: "green",
  },
  createBtn: {
    borderRadius: 20,
    fontSize: 11,
  },
  createButton: {
    borderRadius: 20,
    fontSize: 11,
    background: "green",
    "&:hover": {
      background: "green",
    },
  },
  createBtn1: {
    borderRadius: 20,
    fontSize: 11,
    minHeight: "28px",
    maxHeight: "28px",
  },
  btnPadding: {
    marginRight: "10px",
  },
  editButton: {
    fontSize: 11,
    borderRadius: 20,
    backgroundColor: "green",
    "&:hover": {
      background: "green",
    },
  },
  formControl: {
    minwidth: 300,
    position: "absolute",
  },
  button: {
    fontSize: 11,
    borderRadius: 20,
    background: "#215176",
    "&:hover": {
      background: "#215176",
    },
  },
  pendingButton: {
    fontSize: 11,
    borderRadius: 20,
    background: "#cf7602",
    "&:hover": {
      background: "#cf7602",
    },
  },
  AcceptedButton: {
    fontSize: 11,
    borderRadius: 20,
    background: "green",
    "&:hover": {
      background: "green",
    },
  },
  yesButton: {
    background: "green",
    color: "white",
    borderRadius: 20,
    fontSize: 12,
    right: "15px",
    "&:hover": {
      background: "green",
    },
  },
  noButton: {
    background: "red",
    borderRadius: 20,
    display: "flex",
    color: "white",
    right: "10px",
    fontSize: 12,
    "&:hover": {
      background: "red",
    },
  },
  titleheader: {
    fontWeight: "bold",
    color: "white",
    fontSize: 18,
  },
  textfield: {
    color: "black",
  },
  userNameEmail: {
    minWidth: "100%",
    fontSize: 13,
  },
  cloneYesButton: {
    bottom: "0.2cm",
    marginTop: "0.3cm",
    fontWeight: "bold",
    padding: "4px 10px",
  },
  cloneNoButton: {
    marginTop: "0.3cm",
    bottom: "0.2cm",
    fontWeight: "bold",
    padding: "4px 10px",
  },
  clonebutton: {
    fontSize: 11,
    borderRadius: 20,
    background: "#215176",
    "&:hover": {
      background: "#215176",
    },
  },
  okButton: {
    background: "red",
    color: "white",
    borderRadius: 20,
    right: "15px",
    "&:hover": {
      background: "red",
    },
  },
  colPaddingClone: {
    padding: "0px 15px",
    marginTop: "15px",
  },
  boxview2: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      "& .MuiTypography-root:first-child": {
        color: "green",
      },
    },
  },
  textBoxStyle: {
    marginTop: "12px",
    fontSize: 10,
    "& .MuiChip-labelSmall": {
      paddingLeft: "5px",
      paddingLight: "5px",
      fontSize: "10px",
    },
  },
  textBoxStyle1: {
    marginTop: "9px",
    fontSize: 10,
    "& .MuiChip-labelSmall": {
      paddingLeft: "5px",
      paddingLight: "5px",
      fontSize: "10px",
    },
  },
  LabelBoxStyle: {
    margin: "6px 2px",
  },
  IconSizeStyle: {
    height: "20px",
    width: "20px",
  },
  paper2: {
    border: "2px solid black",
    height: "auto",
    margin: 0,
  },
  searchIcon1: {
    borderRadius: 50,
    background: "white",
    width: "100%",
    height: "35px",
    border: "2px #215176 solid",
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    fontSize: 14,
    textIndent: "10px",
  },
  reportStyle: {
    borderRadius: 10,
    background: "white",
    width: "100%",
    marginTop: "15px",
    height: "100px",
    border: "2px #215176 solid",
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    fontSize: 12,
  },
  outlinedInput: {
    fontSize: 12,
    color: "black",
    paddingLeft: "10px",
    marginTop: "0px",
    "&$focused $notchedOutline": {
      color: "white",
      border: "0px",
    },
  },
  notchedOutline1: {
    color: "white",
    border: "0px",
  },
  formFlex: {
    display: "flex",
  },
  searchTextStyle: {
    padding: "10px",
  },
  scrollMargin: {
    "& div:first-child": {
      marginBottom: "5px",
    },
    "& div:nth-child(3) div": {
      backgroundColor: "#2377e4 !important",
    },
    "& div:nth-child(3)": {
      top: "10px !important",
      bottom: "5px !important",
    },
  },
  blueBorderBottom: {
    fontSize: 12,
    borderRadius: "4px 4px 8px 8px",
    "& .MuiOutlinedInput-input": {
      borderBottom: "3px solid blue",
      borderRadius: "4px 4px 8px 8px",
    },
    "& .MuiInputBase-input": {
      padding: "11px",
    },
  },
  blueBorderBottom1: {
    fontSize: 12,
    borderRadius: "4px 4px 8px 8px",
    "& .MuiOutlinedInput-input": {
      borderBottom: "3px solid blue",
      borderRadius: "4px 4px 8px 8px",
    },
    "& .MuiInputBase-input": {
      padding: "12px",
    },
  },
  blackBorderBottom: {
    borderBottom: "2.5px solid black",
    borderRadius: "4px 4px 8px 8px",
    padding: "6px",
    fontSize: 12,
  },
  blackBorderBottomPayment: {
    padding: "5px !important",
  },
  imageSize: {
    height: "80px",
    width: "80px",
  },
  checkBoxStyle1: {
    marginTop: "-3px",
    marginLeft: "5px",
  },
  textcolor: {
    color: "blue",
  },
  textprop: {
    color: "black",
  },
  IconSizeStyle1: {
    height: "30px",
    width: "30px",
    marginTop: "-85px",
    marginLeft: "315px",
    "&:hover": {
      background: "none",
    },
  },
  autoCompleteFont: {
    fontSize: 13,
  },
  adminBorderBottom: {
    "& .MuiOutlinedInput-input": {
      borderBottom: "3px solid blue",
      borderRadius: "4px 4px 8px 8px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px 4px 8px 8px",
    },
  },
  clientBorderBottom: {
    "& .MuiAutocomplete-inputRoot": {
      borderBottom: "2.5px solid blue",
      borderRadius: "4px 4px 8px 8px",
    },
  },
  valueContainer: {
    "& > :not(:last-child)": {
      marginRight: "5px",
    },
    "& > *": {
      marginBottom: "5px",
    },
  },
  boxBorder: {
    border: "2px solid gray",
    padding: "2px 8px",
    borderRadius: "5px",
    width: "100%",
    marginTop: "12px",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
  },
  textBoxStyle2: {
    fontSize: 10,
    "& .MuiChip-labelSmall": {
      fontSize: 10,
    },
  },
  ClientcheckBoxStyle: {
    padding: "0px",
  },
  CheckBoxBorder: {
    borderTop: "1px solid gray",
  },
  formLabel: {
    fontWeight: "bold",
    color: "green",
    fontSize: 12,
    marginTop: "5px",
  },
  boxview4: {
    display: "block",
    height: "4.5cm",
    borderRadius: "10px",
    borderColor: "black",
    marginTop: "10px",
    "&:hover": {
      color: "green",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
    },
  },
  firstlastTextfield: {
    borderRadius: "4px 4px 8px 8px",
    marginTop: "6px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px 4px 8px 8px",
    },
  },
  regexText: {
    color: "green",
    marginLeft: "5px",
    fontWeight: "bold",
    fontSize: "11px",
    width: "321px",
    wordBreak: "break-all",
  },
  fileNote: {
    color: "black",
    marginLeft: "15px",
    fontSize: 12,
  },
  boxviewStyle: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    "&:hover": {
      color: "green",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
    },
  },
  userboxviewStyle: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    padding: "8px",
    "&:hover": {
      color: "green",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
    },
  },
  boxviewStyle1: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    marginBottom: "5px",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      "& .MuiTypography-root:first-child": {
        color: "green",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  boxviewStyle2: {
    display: "block",
    borderRadius: "10px",
    borderColor: "black",
    marginBottom: "5px",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
      transform: "scale(1)",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
      borderRadius: "5px",
      "& .MuiTypography-root:first-child": {
        color: "green",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  dialogbox: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
  },
  titleStyle: {
    fontWeight: "bold",
    marginTop: "5px",
    color: "white",
  },
  Image: {
    height: 60,
    width: 60,
  },
  textfield1: {
    textAlign: "center",
    color: "black",
    fontSize: "14px",
  },
  dialogaction: {
    gap: "3%",
    marginTop: "10px",
  },
  regexStyle: {
    width: "321px",
    marginLeft: "10px",
    marginTop: "-2px",
    padding: "5px",
    height: "25px",
    border: "1px solid gray",
    borderRadius: "5px",
  },
  regexText1: {
    color: "blue",
    marginTop: "5px",
    marginLeft: "15px",
    fontWeight: "bold",
    fontSize: "11px",
    width: "321px",
    wordBreak: "break-all",
  },
  yesButton1: {
    background: "green",
    color: "white",
    borderRadius: 20,
    fontSize: 10,
    marginBottom: "5px",
    "&:hover": {
      background: "green",
    },
  },
  noButton1: {
    background: "red",
    borderRadius: 20,
    display: "flex",
    fontSize: 10,
    marginBottom: "5px",
    "&:hover": {
      background: "red",
    },
  },
  fontSmall: {
    fontSize: 12,
    padding: "3px 10px",
  },
  permission1: {
    marginLeft: "5px",
    marginTop: "7px",
  },
  dialogePaper: {
    minWidth: "700px",
  },
  firstnameInputSpacing: {
    marginRight: "5px",
  },
  lastnameInputSpacing: {
    marginLeft: "5px",
  },
  dialogeInvitePaper: {
    minWidth: "300px",
  },
  clientheader: {
    fontSize: 14,
    textAlign: "center",
  },
  selectboxpadding: {
    paddingTop: 0,
  },
  clientcheckbox: {
    color: "#1bc42c",
    width: 50,
    height: 22,
  },
  clientlistSpacing: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  ErrorMessageStyle: {
    color: "red",
    marginTop: "5px",
    fontSize: 12,
  },
  ErrorMessageStyle1: {
    color: "red",
    marginTop: "5px",
    fontSize: 16,
  },
  gridstyle: {
    width: "100%",
    border: "2px #215176 solid",
    marginTop: "18px",
    borderRadius: 5,
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
  },
  selectallbutton: {
    marginTop: "25px",
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
  },
  clientlist: {
    textAlign: "left",
    fontSize: 14,
  },
  removeallbutton: {
    fontSize: 12,
    margin: "0px 5px",
    fontWeight: "bold",
    borderRadius: 20,
    marginTop: "20px",
    width: "8.5vw",
  },
  paper: {
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
    borderTop: "2px #215176 solid",
    minHeight: "380px",
    maxHeight: "380px",
  },
  reportList: {
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
    borderTop: "2px #215176 solid",
    minHeight: "auto",
    maxHeight: "240px",
  },
  titleheaders: {
    fontWeight: "bold",
    marginTop: "5px",
    color: "Blue",
    fontSize: 18,
  },
  sendNotification: {
    width: "235px",
    height: "35px",
    alignItems: "right",
    textAlign: "right",
    borderRadius: 50,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: "35px",
    marginTop: "5px",
  },
  circlarBarStyle: {
    color: "white",
    height: "20px",
    width: "20px",
    marginLeft: "5px",
  },
  searchIcon: {
    borderRadius: 50,
    background: "white",
    width: "100%",
    height: "33px",
    textAlign: "left",
    display: "flex",
    border: "2px #215176 solid",
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    fontSize: 14,
    textIndent: "10px",
    marginTop: "5px",
  },
  permissionCheckbox: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#007FFF",
      padding: "4px 9px",
    },
  },
  paperScroll: {
    "& .MuiDialog-paperScrollPaper": { maxheight: "calc(100% - 23px)" },
  },
  dialogeReportShare: {
    minWidth: "1350px",
  },
  permittedFiled: {
    width: "430px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // color: '#2164cd',
    color: "black",
    borderRadius: "5px",
    marginTop: "5px",
    marginLeft: "0px",
  },
  sidebar: {
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    borderRight: "1px solid gray",
    padding: "10px 10px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  sidebar1: {
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    borderRight: "1px solid gray",
    padding: "20px 20px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  reportNameStyle: {
    fontSize: 16,
    color: "#0068ff",
    padding: "5px 0px",
    textAlign: "center",
  },
  boxBorder1: {
    width: "100%",
    borderRadius: 5,
    padding: "0px",
  },
  shareBtn: {
    background: "green",
    color: "white",
    fontSize: 12,
    right: "20px",
    borderRadius: 20,
    "&:hover": {
      background: "green",
    },
  },
  titleheader1: {
    fontWeight: "bold",
    color: "white",
    fontSize: 16,
  },
  ReportScroll: {
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
    minHeight: "auto",
    maxHeight: "455px",
  },
  ReportScrollCreate: {
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
    minHeight: "400px",
    maxHeight: "400px",
  },
  boxEmailStyle: {
    width: "100%",
    borderRadius: 5,
    padding: "0px 0px 0px 10px",
    marginTop: "10px",
    borderTop: "4px solid #3bb78f",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  },
  boxEmailStyle1: {
    width: "20%",
    borderRadius: 5,
    padding: "0px 0px 0px 10px",
    marginTop: "10px",
    borderTop: "4px solid #3bb78f",
    marginBottom: "-40px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  },
  boxEmailStyle2: {
    width: "25%",
    borderRadius: 5,
    padding: "3px 0px 3px 0px",
    borderTop: "4px solid #3bb78f",
    marginBottom: "-40px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    marginLeft: "9px",
    marginTop: "-3px",
  },
  paddingCheckbox: {
    padding: "5px 5px 5px 0px",
  },
  paddingCheckbox1: {
    padding: "5px 5px 5px 10px",
    color: "#1bc42c",
  },
  activityName: {
    color: "blue",
    marginTop: "5px",
  },
  autoCompleteStyle: {
    width: "100%",
    marginTop: "5px",
  },
  permittedChecked: {
    backgroundColor: "#ebf2ff",
    color: "#2164cd",
    borderRadius: "5px",
    marginTop: "5px",
    marginLeft: "0px",
    width: "430px",
  },
  buttonDisplay: {
    display: "flex",
    justifyContent: "flex-end",
  },
  controlStyle: {
    width: "100%",
    marginTop: "5px",
  },
  sendMail: {
    marginTop: "0px",
    padding: 3,
  },
  prosUserTextStyle: {
    color: "blue",
    fontSize: "15px",
  },
  prosTextStyle: {
    width: "100%",
    marginTop: "3px",
  },
  ApprovalPenddingStyle: {
    fontSize: 11,
    borderRadius: 20,
    gap: "5%",
    background: "green",
    "&:hover": {
      background: "green",
    },
  },
  menuButton: {
    marginTop: "5px",
    marginBottom: "5px",
    height: "40px",
    backgroundColor: "white",
    cursor: "default",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  menuButton1: {
    width: "120px",
    height: "40px",
  },
  dialog: {
    "& .MuiDialog-paper": {
      overflowY: "hidden",
    },
  },
  toolbarStyle: {
    backgroundColor: "white",
    minHeight: "40px",
  },
  appBar: {
    position: "relative",
    overflowY: "hidden",
    minHeight: "40px",
    borderBottom: "3px solid #0072E5",
  },
  title: {
    flex: 1,
    color: "#0072E5",
    textAlign: "center",
    fontSize: 18,
    marginRight: "100px",
  },
  rootTab: {
    flexGrow: 1,
    padding: "10px 10px 0px 10px",
  },
  tabs: {
    marginRight: "2px",
    width: "15%",
    boxShadow: "1px 3px 1px #9E9E9E",
    background: "#2B3467",
    color: "white",
    borderRadius: 5,
    fontSize: 12,
  },
  tabpanel: {
    "& .MuiBox-root": {
      padding: "0px 17px 0px 17px",
    },
  },
  smallFont: {
    fontSize: 12,
  },
  dropDownStyle1: {
    width: "95%",
    borderRadius: 5,
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
    border: "2px solid #215176 !important",
    backgroundColor: "white",
    height: "38px",
    fontSize: 12,
    "& .MuiInputBase-root": {
      fontSize: "13px",
    },
  },
  outlinedInputStyle: {
    fontSize: 13,
    color: "black",
    padding: "6px 30px 6px 10px !important",
    "&$focused $notchedOutline": {
      color: "white",
      border: "none",
    },
  },
  clientStyle: {
    color: "blue",
    marginTop: "5px",
    fontSize: "17px",
    marginLeft: "5px",
  },
  circularStyle: {
    height: "25px",
    width: "25px",
  },
  approveBtn: {
    backgroundColor: "green",
    color: "white",
  },
  rejectBtn: {
    backgroundColor: "red",
    color: "white",
  },
  closeIcon: {
    color: "#000",
    fontSize: 22,
  },
  notApprovedBtn: {
    borderRadius: 20,
    fontSize: 11,
    background: "#215176",
    "&:hover": {
      background: "#215176",
    },
  },
  approvedBtn: {
    borderRadius: 20,
    fontSize: 11,
    background: "green",
    "&:hover": {
      background: "green",
    },
  },
  notApprovedBtn1: {
    borderRadius: 20,
    fontSize: 10,
    color: "white",
    background: "#215176",
    "&:hover": {
      background: "#215176",
      color: "white",
    },
  },
  updateEmail: {
    borderRadius: 20,
    fontSize: 11,
    color: "white",
    background: "green",
    "&:hover": {
      background: "green",
      color: "white",
    },
  },
  profileGrid: {
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    borderRadius: "5px",
    display: "flex",
    marginTop: "10px",
  },
  onboardClient: {
    backgroundColor: "rgba(118, 53, 220, 0.08)",
    color: "rgb(32,10,105)",
    borderRadius: "5px",
    padding: "10px",
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    padding: "3px 15px",
  },
  tablebody: {
    maxHeight: 500,
    minHeight: 500,
    marginTop: "5px",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 85px)",
      minHeight: "calc( 100vh - 85px)",
    },
  },
  tablebody1: {
    maxHeight: 500,
    minHeight: 500,
    marginTop: "5px",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 120px)",
      minHeight: "calc( 100vh - 120px)",
    },
  },

  tableCellFontSize: {
    fontSize: "13px",
  },
  tableCellDescription: {
    fontSize: "13px",
    width: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  closeIconButton: {
    color: "#000",
    fontSize: 22,
  },
  TextHighlight: {
    color: "black",
    // fontWeight: 'bold',
    background: "yellow",
    fontSize: "16px",
  },
  searchBox: {
    marginTop: "2px",
    borderRadius: 30,
    border: "2px solid #215176",
    height: "35px",
    textIndent: "10px",
    width: "100%",
    textAlign: "center",
    boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
  },
  outlinedInputs: {
    fontSize: 15,
    color: "black",
    marginTop: "-12px",
    "&$focused $notchedOutline": {
      color: "white",
      border: "0px",
    },
  },
  notchedOutline: {
    color: "white",
    border: "0px",
  },
  TextNormal: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
  },
  Header: {
    marginLeft: "5px",
    fontWeight: "bold",
  },
  dialogboxOverride: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
    border: "1px solid #0D324D",
    borderRadius: 15,
  },
  viewTemplateStyle: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
  },
  tablebodyStyle: {
    maxHeight: 200,
    minHeight: 200,
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 300px)",
      minHeight: "calc( 100vh - 300px)",
    },
  },
  gridDisplay: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  organizationGrid: {
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    borderRadius: "5px",
    display: "flex",
    marginTop: "10px",
    cursor: "pointer",
    "&:hover": {
      background:
        "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
      color: "rgb(0, 75, 80)",
    },
  },
  textStyle: {
    color: "blue",
    marginTop: "5px",
  },
  newEmailAddressStyle: {
    width: "100%",
    marginTop: "8px",
  },
  chipStyle: {
    backgroundColor: "#3cf4e6",
    border: "1px solid #bf8877",
    color: "black",
    fontWeight: "bold",
  },
  chipStyle1: {
    backgroundColor: "#f2f5b1",
    border: "1px solid #bf8877",
    color: "black",
    fontWeight: "bold",
  },
  chipStyle2: {
    backgroundColor: "#e1a9f6",
    border: "1px solid #bf8877",
    color: "black",
    fontWeight: "bold",
  },
  chipStyle3: {
    backgroundColor: "#f9abe7",
    border: "1px solid #bf8877",
    color: "black",
    fontWeight: "bold",
  },
  chipStyle4: {
    backgroundColor: "#b5e5ff",
    border: "1px solid #bf8877",
    color: "black",
    fontWeight: "bold",
  },
  tooltipText1: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
  },
  colorcodeTemplate: {
    padding: "0px",
    color: "#f2666a",
    marginRight: "5px",
    marginTop: "2px",
    "& .MuiIconButton-root": {
      padding: "0px !important",
    },
  },
  create: {
    float: "right",
    marginTop: "0.8cm",
    marginRight: "15px",
    borderRadius: "50px",
    color: "white",
    fontSize: 12,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  close1: {
    float: "right",
    marginTop: "0.8cm",
    // marginBottom: '0.3cm',
    marginRight: "5px",
    borderRadius: "50px",
    fontSize: 12,
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  loadingDiv: {
    display: 'flex',
    marginLeft: '10px'
  },
  spinnerStyle: {
    marginTop: '17px',
    paddingLeft: '5px'
  }
}));
export { useStyles };
