import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import YouTube from '@u-wave/react-youtube';
import React from 'react';
import LightTooltip from "../GlobalStyles/LightTooltip";
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "100px",
    position: "relative"
  },
  box: {
    display: "flex",
    marginTop: "-50px"
  },
  centerBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  root: {
    width: 250,
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  }
}))

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const VideoPlayer: React.FC<{ video: string, title: string }> = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box component="span" m={1} //margin
        className={`${classes.centerBox} ${classes.box}`} >
        <LightTooltip title="Help Video">
          <IconButton aria-label="help_video" onClick={handleClickOpen}>
            <LiveHelpIcon style={{ fontSize: 26 }} />
          </IconButton>
        </LightTooltip>
      </Box>
      <Dialog onClose={handleClose} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} open={open}>
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose} >
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            {props.title}
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <div className="row">
            <div className="col s4">
              <div className="col s8 center-align">
                <YouTube
                  video={props.video}
                  width={930}
                  height={480}
                  autoplay
                  controls={true}
                  showRelatedVideos={false}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default VideoPlayer