
import { Box, IconButton, makeStyles, Typography, Backdrop } from '@material-ui/core';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { Scrollbars } from 'react-custom-scrollbars';
import useStyles from '../GlobalStyles/AccountDetailCss';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import Snackbar from '@material-ui/core/Snackbar';
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
// Models
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const Submit: React.FC<{ clientId: string, dbtNo: string, onSubmit: () => void, placedAccounts: IPlacedAccount[], BlockAwaitAccess: boolean }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [undoSubmit, setundoSubmit] = React.useState(false);

    const undoAdjustment = (adjustmentAmount: number, adjustmentType: string) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNo,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": adjustmentType
            }
            await usePost<any>("Account/UndoAdjustment", request);
            await useFetch<any>("Account/UndoPaymentAmount");
            props.onSubmit();
            setundoSubmit(false);
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setundoSubmit(false);
    };

    if (props.placedAccounts.find(x => x.prpcl_Pay_Amt > 0 || x.prcpl_Adj_Amt != 0 || x.fee_Pay_Amt > 0 || x.fee_Adj_Amt != 0)) {
        return (
            <Box className={classes.rootSubmit} margin={2} >
                <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin={80}
                    autoHeightMax={80}
                    style={{ width: "100%" }}>
                    <div className={classes.submitDiv}>
                        <Typography variant="overline" display="block" className={classes.textSpan}>
                            Pending...
                        </Typography>
                        {props.placedAccounts.reverse().map(p => {
                            return (
                                <span className={classes.textSpan2}>
                                    {Math.abs(p.prpcl_Pay_Amt) > 0 ? <div>Principal Payment: {p.prpcl_Pay_Amt} <IconButton id="R_Undo_PrincipalPayment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.prpcl_Pay_Amt, "Principal Payment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                    {Math.abs(p.prcpl_Adj_Amt) > 0 ? <div>Principal Adjustment: {p.prcpl_Adj_Amt}<IconButton id="R_Undo_PrincipalAdjustment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.prcpl_Adj_Amt, "Principal Adjustment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                    {Math.abs(p.fee_Pay_Amt) > 0 ? <div>Late Fee Payment: {p.fee_Pay_Amt}<IconButton id="R_Undo_FeePayment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Pay_Amt, "Fee Payment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                    {Math.abs(p.fee_Adj_Amt) > 0 ? <div>Late Fee Adjustment: {p.fee_Adj_Amt}<IconButton id="R_Undo_FeeAdjustment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Adj_Amt, "Fee Adjustment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                </span>
                            );
                        })}
                    </div>
                </Scrollbars>
                <Snackbar className="snackBarStyle" open={undoSubmit} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        Undo Transaction Successfully!
                    </Alert>
                </Snackbar>
            </Box>
        )
    }
    else {
        return (null);
    }
}

export default Submit