import {
    Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormControlLabel, Grid, IconButton, Snackbar, TextField, Typography, ExpansionPanel, ExpansionPanelDetails,
    Select,
} from '@material-ui/core';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BorderColorIcon from '@material-ui/icons/BorderColor';

// Models
import _ from 'lodash';
import moment from 'moment';
import { Link } from "react-router-dom";
import { useStyles } from './AG_TemplateCss';
import { usePost } from '../../../utils/apiHelper';
import EditAgentTreeView from './EditAgentTreeView';
import Caution from '../../../images/ADF/complain.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { useGlobalState } from '../../../store/GlobalStore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { AllowOnlyAlphabetsNumbers } from '../../../constants/Constant';
import CompactExpansionPanelSummary from '../../GlobalStyles/ExpansionPanelSummary';
import { ActivityList } from '../../../models/AgentInquiry/IAgentActivityListCount';
import { IAgentTemplateList } from "../../../models/AgentTemplate/IAgentTemplateList";
import { IClientDetails } from "../../../models/AgentTemplate/IClientTemplateDetails";
import { IActivityGroupName } from '../../../models/AgentTemplate/IAgentCustomizeControl';
import { IActivitGet, IFteActivityTemplate } from '../../../models/AgentTemplate/IActivityGet';

const EditTemplate: React.FC<{ listEmailActivityJson: ActivityList[], listAdminActivityJson: ActivityList[], listCaseCreatedActivityJson: ActivityList[], masterFlag: number, row: IAgentTemplateList, activityGroupName: IActivityGroupName[], onUpdate: () => void }> = (props) => {
    const classes = useStyles();
    const { masterFlag, row } = props;
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [nameError, setnameError] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [updateTemplate, setUpdateTemplate] = useState(false);
    const [templateName, setTemplateName] = useState<string>("");
    const [defaultMethod, setDefaultMethod] = useState<number>(0);
    const [isFteTemplate, setIsFteTemplate] = useState<boolean>(false);
    const [clientDetails, setClientDetails] = useState<IClientDetails[]>([]);
    const [defaultMethodValue, setDefaultMethodValue] = useState<string>("");
    const [emailActivitiesWithFlag, setEmailActivitiesWithFlag] = useState<any[]>([]);
    const [adminActivitiesWithFlag, setAdminActivitiesWithFlag] = useState<any[]>([]);
    const [caseCreatedActivitiesWithFlag, setCaseCreatedActivitiesWithFlag] = useState<any[]>([]);
    const [currentActivity, setCurrentActivity] = useState([{ activityFieldId: 195 }, { activityFieldId: 196 }, { activityFieldId: 187 }]);
    const activityGroupNameWithNone = [{ activityGroupId: 0, activityGroupName: "Select Default Communication Method" }, ...props?.activityGroupName];

    var EditTemplate: IAgentTemplateList = {
        activityTemplateId: row?.activityTemplateId,
        activityTemplateName: row?.activityTemplateName,
        userRole: 0
    }
    const [localState, setLocalState] = useState(EditTemplate);

    const handleCloseDialog = () => {
        setConfirmation(false);
        setLocalState(EditTemplate);
        setTemplateName("");
        setCurrentActivity([{ activityFieldId: 195 }, { activityFieldId: 196 }, { activityFieldId: 187 }]);
        setOpen(false);
    }

    const handleCloseDialogBox = () => {
        setConfirmation(false);
    }

    const GetActivityValues = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityTemplateId": props?.row?.activityTemplateId
            }
            await usePost<{ agentActivitiesResults: IActivitGet[], fteResult: IFteActivityTemplate }>("AgentUser/GetActivitiesByTemplateId", request).then((r) => {
                setDefaultMethod(r?.data?.fteResult[0]?.defaultMethod);
                activityGroupNameWithNone?.forEach((a) => {
                    if (r?.data?.fteResult[0]?.defaultMethod === a?.activityGroupId) {
                        setDefaultMethodValue(a?.activityGroupName);
                    }
                })
                let currentActivities = _.cloneDeep(currentActivity);
                // Add flag to each activity based on whether it matches the activity IDs in matchingActivityList
                const adminActivitiesWithFlag = addIsEnable(props?.listAdminActivityJson);
                const caseCreatedActivitiesWithFlag = addIsEnable(props?.listCaseCreatedActivityJson);
                const emailActivitiesWithFlag = addIsEnable(props?.listEmailActivityJson);

                // Function to recursively add isEnable field to activities
                function addIsEnable(activities) {
                    // Create a new array to hold the modified activities
                    const modifiedActivities = activities.map(activity => {
                        // Check if any of the child activities (if they exist) has a match in agentActivitiesResults
                        const flag = activity.Activities ?
                            activity.Activities.some(childActivity =>
                                r?.data?.agentActivitiesResults?.findIndex((y) => y.activityFieldId === childActivity.ActivityMapId) > -1
                            ) :
                            r?.data?.agentActivitiesResults?.findIndex((y) => y.activityFieldId === activity.ActivityMapId) > -1;

                        // Set isEnable based on the flag
                        const modifiedActivity = { ...activity, isEnable: flag };
                        // Recursively add isEnable to child activities
                        if (activity.Activities) {
                            modifiedActivity.Activities = addIsEnable(activity.Activities);
                        }
                        if (flag) {
                            let activityList = {
                                activityFieldId: activity.ActivityMapId,
                            };
                            currentActivities.push(activityList);
                        }
                        return modifiedActivity;
                    });

                    return modifiedActivities;
                }
                setEmailActivitiesWithFlag(emailActivitiesWithFlag);
                setAdminActivitiesWithFlag(adminActivitiesWithFlag);
                setCaseCreatedActivitiesWithFlag(caseCreatedActivitiesWithFlag);
                setCurrentActivity(currentActivities);
                setTemplateName(r?.data?.fteResult[0]?.activityTemplateName);
                setIsFteTemplate(r?.data?.fteResult[0]?.isFteTemplate);
                setShowProgressBar(false);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    // Function to update isEnable flag based on checkedActivityId and enabledActivities
    function updateIsEnable(activitiesData, checkedActivityId, checked) {
        // Loop through activities to update isEnable flag
        activitiesData.forEach(activity => {
            // Check if the activity ID matches the checkedActivityId
            if (activity.ActivityMapId === checkedActivityId) {
                // Update isEnable based on whether the activity ID is in enabledActivities
                activity.isEnable = checked;
            }
            // Recursively update isEnable flag for child activities
            if (activity.Activities) {
                updateIsEnable(activity.Activities, checkedActivityId, checked);
            }
        });
        return activitiesData;
    }

    const handleChange = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLocalState(EditTemplate);
        setTemplateName("");
        setCurrentActivity([{ activityFieldId: 195 }, { activityFieldId: 196 }, { activityFieldId: 187 }]);
    };

    const HandleTempNameVal = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(AllowOnlyAlphabetsNumbers)) {
            setnameError("");
            setIsValid(false);
            setTemplateName(newValue);
        } else {
            setnameError("please enter characters and numbers only");
            setIsValid(true);
        }
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateTemplate(false);
    };

    const handleActivityChecked = (e, checked, parentActivityId) => {
        let newActivityList = [...currentActivity];
        const value = Number(e.target.value);
        const emailActivitiesWithFlag1 = updateIsEnable(emailActivitiesWithFlag, parseInt(e.target.value), checked);
        const adminActivitiesWithFlag1 = updateIsEnable(adminActivitiesWithFlag, parseInt(e.target.value), checked);
        const caseCreatedActivitiesWithFlag1 = updateIsEnable(caseCreatedActivitiesWithFlag, parseInt(e.target.value), checked);

        setEmailActivitiesWithFlag(emailActivitiesWithFlag1);
        setAdminActivitiesWithFlag(adminActivitiesWithFlag1);
        setCaseCreatedActivitiesWithFlag(caseCreatedActivitiesWithFlag1);

        if (checked) {
            if (newActivityList.findIndex(x => x.activityFieldId === value) === -1) {
                newActivityList.push({ activityFieldId: value });
            }
        } else {
            const index = newActivityList.findIndex(x => x.activityFieldId === value);
            if (index !== -1) {
                newActivityList.splice(index, 1);
            }
        }

        // Add root activity ID (parentActivityId) and its ancestors recursively
        if (parentActivityId && !newActivityList.some(item => item.activityFieldId === parentActivityId)) {
            newActivityList.push({ activityFieldId: parentActivityId });
        }
        setCurrentActivity(newActivityList);
    }

    const handleSaveTempalte = () => {
        (async () => {
            let request = {
                "templateId": props?.row?.activityTemplateId,
                "templateName": templateName,
                "isOverRide": 0,
                "modifiedBy": state?.userAccessContext?.id,
                "modifiedDate": moment(new Date()),
                "activityMapingDetail": currentActivity,
                "isFteTemplate": isFteTemplate,
                "defaultMethod": defaultMethod
            }
            await usePost("AgentUser/ModifyAgentTemplate", request);
            handleClose();
            props?.onUpdate();
        })()
    }

    // To get the total number of Client Code with Phase 
    const handleOpenDialog = () => {
        (async () => {
            let request = {
                "activityTemplateId": props?.row?.activityTemplateId,
                "clientDetails": [{ "clientCode": "ARMSOL", "phaseCode": "PREC" }]
            }
            await usePost<{ agentClientDetails: IClientDetails[], totalCount: number }>('AgentUser/GetMappedClientCodeDetail', request).then((r) => {
                setClientDetails(r?.data?.agentClientDetails);
            }).finally(() => {
                setConfirmation(true);
            });
        })()
    }

    const handleOverRiseAndSaveTempalte = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "templateId": props?.row?.activityTemplateId,
                "templateName": templateName,
                "isOverRide": 1,
                "modifiedBy": state?.userAccessContext?.id,
                "modifiedDate": moment(new Date()),
                "activityMapingDetail": currentActivity,
                "isFteTemplate": isFteTemplate,
                "defaultMethod": defaultMethod
            }
            await usePost("AgentUser/ModifyAgentTemplate", request).then(() => {
                props?.onUpdate();
                setUpdateTemplate(true);
            }).finally(() => {
                setShowProgressBar(false);
                handleCloseDialog();
            });
        })()
    }

    const handleFteChecked = (event) => {
        setIsFteTemplate(event.target.checked);
    }

    const handleEditGroupNameChange = (event) => {
        let name = event.target.name;
        activityGroupNameWithNone?.forEach((a) => {
            if (event.target.value === a?.activityGroupName) {
                setDefaultMethod(a?.activityGroupId);
                setDefaultMethodValue(a?.activityGroupName);
            }
        })
    }

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<BorderColorIcon />} onClick={() => { handleClickOpen(); GetActivityValues(); }} variant="contained" color="primary" className={classes.btnManTemp1} >
                Edit
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogePaper }} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        AGENT ACTIVITY TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={6} >
                            <TextField id="CNT_Name"
                                variant="outlined"
                                error={!!nameError}
                                helperText={nameError}
                                fullWidth margin="dense"
                                label="Edit Template Name"
                                inputProps={{ maxLength: 250 }}
                                size="small" name="activityTemplateName"
                                value={templateName}
                                disabled={masterFlag !== 0 ? true : false}
                                onChange={(e) => { handleChange(e); HandleTempNameVal(e); }} />
                        </Grid>
                        <Grid item xs={3} sm={3} >
                            <FormControl size="small" variant="outlined" style={{ paddingTop: '8px', paddingLeft: '10px' }} /* className={classes.activityDropDownStyle} */>
                                <Select native
                                    value={defaultMethodValue}
                                    onChange={(e) => { handleEditGroupNameChange(e); }}
                                    name="activityGroupName" variant="outlined"
                                    inputProps={{
                                        name: 'activityGroupName',
                                        id: 'outlined-age-native-simple',
                                    }}>
                                    {activityGroupNameWithNone?.map((r) => {
                                        return (
                                            <option value={r?.activityGroupName}>{r?.activityGroupName}</option>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
                            <FormControl component="fieldset" style={{ paddingTop: '5px' }} >
                                <FormControlLabel
                                    id="AG_emailreceived"
                                    control={<Checkbox size="small" name="FTETemplate" />}
                                    labelPlacement="end"
                                    label="FTE Template"
                                    checked={isFteTemplate}
                                    className={classes.FTEChecked}
                                    onChange={(e) => { handleFteChecked(e); }}
                                />
                            </FormControl>
                            <IconButton className={`${"zoom-in-box"}`} style={{ padding: '0px' }}>
                                <LightTooltip title="Selection of 'FTE Template' will track the Agent Users against their assignment and removal.">
                                    <InfoIcon />
                                </LightTooltip>
                            </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogTitle id="responsive-dialog-title" className={classes.textAlginEnd}>
                                <span className={classes.textStyle}><b>CUSTOMIZE ACTIVITIES</b></span>
                            </DialogTitle>
                            <Grid container spacing={0} style={{ padding: '8px 16px' }}>
                                <Grid container spacing={1} style={{ padding: '0px 10px', minHeight: '10px' }}>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Email
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <EditAgentTreeView data={emailActivitiesWithFlag} onSaveActivity={handleActivityChecked} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Admin
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <EditAgentTreeView data={adminActivitiesWithFlag} onSaveActivity={handleActivityChecked} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Case Created/ Escalation
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <EditAgentTreeView data={caseCreatedActivitiesWithFlag} onSaveActivity={handleActivityChecked} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.edittemplate} startIcon={<DoneAllIcon />}
                        disabled={!templateName?.length || isValid || !currentActivity?.length}
                        variant="contained" autoFocus color="primary" onClick={() => { handleOpenDialog() }}>
                        Override & Save Changes
                    </Button>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.edittemplate} startIcon={<DoneIcon />}
                        disabled={!templateName?.length || isValid || !currentActivity?.length}
                        variant="contained" autoFocus color="primary" onClick={() => { handleSaveTempalte(); }}>
                        Save Changes
                    </Button>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.noButton1} startIcon={<CancelIcon />} onClick={handleClose} variant="contained" autoFocus color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition} open={confirmation}
                className={classes.dialogboxOverride}
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        OVERRIDE CHANGES
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogTitle className={classes.titleHeaderOverride}>
                        <img src={Caution} alt="Block_Email" className={classes.warningIcon} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure to override this new template that affects <b>{clientDetails.length}</b> Clients?
                    </Typography>
                    <div className={classes.textfield2}>
                        To view affected client codes &nbsp;
                        <Link className="linkStyle"
                            to={{
                                pathname: '/agenttemplate',
                                search: props?.row?.activityTemplateName,
                                state: {
                                    currentActivity: currentActivity,
                                    itemID: props?.row?.activityTemplateId,
                                    modifiedBy: state?.userAccessContext?.id
                                }
                            }}>
                            <b> Click here </b></Link></div>
                </DialogContent>
                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { handleOverRiseAndSaveTempalte(); setConfirmation(false); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleCloseDialogBox(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Snackbar className="snackBarStyle" open={updateTemplate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Template Updated Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default EditTemplate