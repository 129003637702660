import { Backdrop, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { IAgentActivities } from '../../../models/AgentTemplate/IAgentActivities';
import { useFetch } from '../../../utils/apiHelper';
import EditTemplate from '../../GlobalSettings/GsettingAgentActivity/EditTemplate';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import ManageGroupDetails from '../ManageGroup/ManageGroupDetails';
import { useStyles } from './AgentActivityCSS';
import CreateNewTemplate from './CreateNewTemplate';
import { PuffLoader } from 'react-spinners';

function AgentActivity() {
    const classes = useStyles();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [listActivity, setListActivity] = useState<IAgentActivities[]>([]);

    useEffect(() => {
        getActivityList();
    }, [])

    const getActivityList = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IAgentActivities[]>("AgentUser/GetAgentActivities").then((r) => {
                setListActivity(r?.data);
            });
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    function EnhancedTableHead(props) {
        const {
            classes,
        } = props;

        const headCells = [
            { id: "activityName", disablePadding: false, label: "ACTIVITY NAME", sortable: true },
            { id: "activityDescription", disablePadding: false, label: "ACTIVITY DESCRIPTION", sortable: true },
            { id: "displayOrder", disablePadding: false, label: "DISPLAY ORDER", sortable: true },
            { id: "Actions", disablePadding: false, label: "ACTIONS", sortable: true },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} id="CPO_TableHeader"
                            className={classes.TableCellStyle}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Grid container spacing={0} className={classes.boxStyle}>
            <Grid xs={8} >
                <Typography variant="h6" style={{ textAlign: 'left' }}>
                    <b>MANAGE AGENT ACTIVITIES</b>
                </Typography>
            </Grid>
            <Grid xs={2}>
                <ManageGroupDetails />
            </Grid>
            <Grid xs={2}>
                <CreateNewTemplate onSave={getActivityList} orderCount={listActivity?.length} ActivityList={listActivity} />
            </Grid>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>

            <Grid xs={12} component={Paper} style={{ marginTop: '5px' }}>
                <TableContainer component={Paper} className={` ${classes.tablebodyActivity} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Table aria-label="customized table" size="small" stickyHeader >
                        <EnhancedTableHead classes={classes} />
                        <TableBody>
                            {listActivity.map((row) => (
                                <StyledTableRow hover>
                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                        {row?.activityName}
                                    </TableCell>
                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                        {row?.activityDescription}
                                    </TableCell>
                                    <TableCell align="left" className={classes.DisplayOrderStyle}>
                                        {row?.displayOrder}
                                    </TableCell>
                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                        <EditTemplate row={row} onSave={getActivityList} ActivityList={listActivity} />
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

    )
}

export default AgentActivity
