import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Snackbar, Backdrop } from '@material-ui/core';
import { IGlobalMessage } from '../../../models/GlobalMessage/IGlobalMessage';
import { useDelete } from '../../../utils/apiHelper';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import PuffLoader from "react-spinners/PuffLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    formControl: {
      minwidth: 300,
      position: "absolute",
    },
    DialogBottom: {
      marginRight: '10px'
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      fontSize: 12,
      display: 'flex',
      margin: theme.spacing(0.5),
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      fontSize: 12,
      display: 'flex',
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    HeaderStyle: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      fontSize: 18
    },
    IconStyle: {
      color: 'red',
      fontSize: 33
    },
    MessageStyle: {
      color: "black",
      fontSize: 17
    }
  })
);


const DeleteNotification: React.FC<{ Messages: IGlobalMessage, onDelete: () => void }> = (props) => {
  const { Messages } = props;
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [isCreate, setIsCreate] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = React.useState(false);

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCreate(false);
  };
  const ErrorMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowProgressBar(false);
  };

  const DeleteMessage = () => {
    setShowProgressBar(true);
    useDelete('DeleteGlobalNotification?Id=' + Messages.id).then((r) => {
      setIsCreate(true);
      props.onDelete();
    }).catch(() => {
      setIsError(true);
    }).finally(() => {
      setShowProgressBar(false);
    });
  }

  return (
    <React.Fragment>
      <div>
        <Button id="GM_DeleteIcon" aria-label="delete" onClick={handleClickOpen} style={{ borderRadius: 50 }}>
          <DeleteForeverIcon className={classes.IconStyle} />
        </Button>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth={'xs'}
          Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 15, width: 580 } }}>
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
              DELETE NOTIFICATION
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
              Are you sure, you want to delete this Notification?
            </Typography>
          </DialogContent>
          <DialogActions className={classes.DialogBottom}>
            <Button id="GM_Ok_btn" size="small" variant="contained" onClick={() => { handleClose(); DeleteMessage(); }} className={classes.yesButton}>
              Yes
            </Button>
            <Button id="GM_Cancel_btn" size="small" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
          Notification Deleted Successfully.
        </Alert>
      </Snackbar>
      <Snackbar className="snackBarStyle" open={isError} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={ErrorMessage}>
        <Alert onClose={ErrorMessage} severity="error" className="alertStyle">
          Notification Not Deleted .
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default DeleteNotification