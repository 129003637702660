import {
  Box, ExpansionPanel, ExpansionPanelDetails, Grid, IconButton,
  InputAdornment, TableContainer, TablePagination, TextField
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IFaq } from '../models/Faq/IFaq';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch } from '../utils/apiHelper';
import SearchIcon from "@material-ui/icons/Search";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../components/Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading1: {
      fontSize: theme.typography.pxToRem(18),
      color: "#4C4CDB"
    },
    div: {
      flexGrow: 1,
      backgroundColor: 'white'
    },
    div1: {
      flexGrow: 1,
      backgroundColor: 'white'
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "#E2E8EF",
        borderTop: 'none',
      },
      borderRadius: 30,
      padding: 2,
      marginTop: "10px",
      boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    grid: {
      padding: '5px',
      textAlign: 'center',
      width: '100%'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
      fontSize: '16px',
    },
    TextNormal: {
      color: '#215176',
      fontSize: 14,
      fontWeight: 'bold'
    },
    TextNormal1: {
      color: 'white',
      fontSize: 14,
      fontWeight: 'bold'
    },
    expandicon: {
      backgroundColor: '#215176',
      color: 'white',
      borderRadius: 30
    },
    accordionDetails: {
      borderTop: '2px solid rgba(6, 24, 44, 0.4)'
    },
    searchBox: {
      marginTop: '2px',
      borderRadius: 30,
      border: '2px solid #215176',
      height: '35px',
      textIndent: '10px',
      width: '80%',
      textAlign: 'center',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    gridStyle: {
      marginTop: '5px',
      minHeight: 670,
      padding: '5px',
    },
    label: {
      '&$focused': {
        color: 'white',
        border: '0px'
      },
    },
    outlinedInput: {
      fontSize: 15,
      color: "black",
      marginTop: '-12px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline: {
      color: 'white',
      border: '0px'
    },
    ContentStyle: {
      fontSize: 13,
      border: '1px solid #215176',
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
      boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    ErrorMessageStyle: {
      color: "red",
      marginTop: "10px"
    },
    caption: {
      padding: 8,
      fontSize: 14
    },
    pagination: {
      backgroundColor: '#F5F5F5',
      border: '2px solid #ECECEC',
      width: '100%',
      borderRadius: '5px',
      flexDirection: 'row'
    },
    tableBody: {
      padding: '0px 5px',
      maxHeight: 700,
      minHeight: 700,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 210px)',
        minHeight: 'calc( 100vh - 210px)',
      }
    }
  }),
);

const summaryStyles = {
  root: {
    minHeight: 35,
    marginTop: 10,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    border: "1px solid #215176",
    boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    "&$expanded": {
      minHeight: 30,
      color: "#fffff",
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      fontWeight: "bold",
      backgroundColor: "#215176",
    }
  },
  content: {
    margin: "4px 0",
    "&$expanded": {
      margin: "4px 0"
    }
  },
  expandIcon: {
    padding: 5
  },
  expanded: {}
};
const CompactExpansionPanelSummary = withStyles(summaryStyles)(
  ExpansionPanelSummary
);

const SimpleAccordion = () => {
  const classes = useStyles();
  const [Faq, setFaq] = useState<IFaq[]>([]);
  const { dispatch } = useGlobalState();
  const [searchText, setSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [expanded, setExpanded] = React.useState('panel_-1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (Faq.length <= 0) {
      getFaqListAsync();
    }
  }, []);

  async function getFaqListAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setShowProgressBar(true);
    await useFetch<IFaq[]>("Faq/SearchFaq?SearchParam=" + searchText?.trimLeft().trimRight()).then((searchFaq) => {
      setFaq(searchFaq.data);
    }).finally(() => {
      setShowProgressBar(false);
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const SearchFaq = (e) => {
    setSearchText(e);
    setPage(0);
  }
  function clearSearchValues() {
    setSearchText("");
    getFaqListAsyncClear();
    setShowSearchIcon(true);
  }

  async function getFaqListAsyncClear() {
    dispatch({ type: GlobalStateAction.Busy });
    setShowProgressBar(true);
    await useFetch<IFaq[]>("Faq/SearchFaq?SearchParam=" + "").then((searchFaq) => {
      setFaq(searchFaq.data);
    }).finally(() => {
      setShowProgressBar(false);
    });
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid xs={12} item >
            <TextField value={searchText} variant="outlined"
              type='text' placeholder="Search (question, answer)"
              className={classes.searchBox}
              onChange={e => SearchFaq(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText != "") {
                  getFaqListAsync(); setShowSearchIcon(false);
                } else if (event.key === 'Enter' && searchText === "") {
                  getFaqListAsync(); setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxlength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { getFaqListAsync(); setShowSearchIcon(false); }} style={{ marginLeft: 15 }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton onClick={e => { clearSearchValues(); }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid xs={12} item style={{ marginTop: '10px' }}>
            <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
              {Faq.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                (row, index) => {
                  return (
                    <ExpansionPanel style={{ borderRadius: 20 }} expanded={expanded === `panel_${index}`} onChange={handleChange(`panel_${index}`)}>
                      <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                        <Typography align="left" variant="subtitle1" className={`${searchText === "" && expanded != `panel_${index}` ? classes.TextNormal : searchText === "" && expanded === `panel_${index}` ? classes.TextNormal1 : row.question.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                          {row.question}
                        </Typography>
                      </CompactExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.ContentStyle}>
                        <Typography align="left" variant="body2" >
                          {row.answer}
                        </Typography>
                      </ExpansionPanelDetails >
                    </ExpansionPanel>
                  )
                })
              }
              {!ProgressBar && !Faq.length ? <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                No records to display...
              </Typography> : null}
            </TableContainer>
            <Box m={0} display="flex" className={classes.pagination}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    id="UpLog_TablePagination"
                    rowsPerPageOptions={[15, 20, 50]}
                    component="div"
                    count={Faq.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    classes={{
                      caption: classes.caption
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment >
  );
}

export default SimpleAccordion