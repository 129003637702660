import {
    Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    IconButton, Typography, useMediaQuery
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as constant from '../../constants/Constant';
import Warning from '../../images/agentUser/deleteWarning.svg';
import { IAccStatus } from '../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { IDebtor } from '../../models/Reports/IDebtor';
import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../utils/apiHelper';
import useStyles from '../GlobalStyles/AccountDetailCss';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { InsertTransactionLog } from '../TransactionLogs/InsertTransactionLog';
import CloseAccount from './CloseAccount';
import CloseAndRemove from './CloseAndRemove';
import DocumentUpload from './ReportDocumentUpload';
import Submit from "./ReportSubmit";

const ReportAccountDetails: React.FC<{ debtors: IDebtor, letterDetails: IClosedLetterDetails[], onEroll: () => void, isEnable: IGetRolledPlacement, onHideAll: () => void, BlockAwaitUsers: boolean, GetDebtorLetterDetails: ILetterDetails[], GetAccStatus: IAccStatus, OnGetDetails: () => void }> = (props) => {
    const { debtors, isEnable, BlockAwaitUsers, GetAccStatus } = props;
    const theme = useTheme();
    const classes = useStyles();
    const falseObject = {
        "DocUpload": false,
        "CloseRemove": false,
        "CloseAcc": false,
        "SubmitValue": false
    }
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [object, setObject] = useState(falseObject);
    const canRollAccount = state.userAccessContext?.rollAccounts;
    const canUpdatePayment = state.userAccessContext?.paymentUpdates;
    const [EarlyRollRolled, setEarlyRollRolled] = useState(props?.isEnable?.rolled);
    const [LateFeeDue, setLateFeeDue] = useState<number>(debtors.fee);
    const [updatedLateAmount, setUpdatedLateAmount] = useState(debtors.fee);
    const referralDate = moment(debtors.referralDate).format(state.GlobalUtils?.settingValue);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(debtors.principalDue);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(debtors.principalDue);
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(updatedPrincipalAmount + updatedLateAmount);
    const phoneNumber = debtors?.phoneNumber;
    const canMakePayment = state?.userAccessContext?.paymentUpdates;
    const TotalBalance = principalPaymentDue + LateFeeDue;
    const handleDisable = isEnable?.rolled;

    const [principalAdjustmentAmount, setPrincipalAdjustmentAmount] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>('');
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [lateAdjustmentAmount, setLateAdjustmentAmount] = useState<any>('');
    const [latePaymentAmount, setLatePaymentAmount] = useState<any>('');
    const [inputVal, setInputVal] = useState<any>('');
    const [service, setService] = useState('');

    const [undoEarlyRoll, setUndoEarlyRoll] = useState(false);
    const [checkFullAmount, setCheckFullAmount] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [ConfirmDialog, setConfirmDialog] = useState(false);
    const [earlyRoll, setEarlyRoll] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [paymentVal, setPaymentVal] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [disable, setDisable] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [earlyButtonLoading, setEarlyButtonLoading] = useState(false);
    const [noPaymentMadeCheck, setNoPaymentMadeCheck] = useState<boolean>(false);
    const [documentNotAvailable, setDocumentNotAvailable] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.agencyID}`).then((PlacedAccounts) => {
                setPlacedAccounts(PlacedAccounts.data);
            });
        })()
    }, [])

    const handleGetStatus = () => {
        props.OnGetDetails();
    }

    const handleRemovedStatus = () => {
        props.onEroll();
    }

    const handleConfirmDialogCLose = () => {
        setConfirmDialog(false);
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setButtonLoading(false);
    }

    const handleCheckFullAmountDialog = () => {
        setPaymentVal(true);
        setCheckFullAmount(false);
        setButtonLoading(false);
    }

    function emptyCheck() {
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setEarlyRoll(false);
        setButtonLoading(false);
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmit(false);
        setEarlyRoll(false);
    };

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt?.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setDisable(true);
        setPrincipalPaymentAmount(recursiveRemove(value, debtors.principalDue));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtors.principalDue ? value : principalAdjustmentAmount != 0 || lateAdjustmentAmount != 0 || latePaymentAmount != 0);
    };

    const handleFlatfeeValueChange = (value: string): void => {
        setDisable(true);
        setLatePaymentAmount(recursiveRemove(value, debtors.fee));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtors.fee ? value : lateAdjustmentAmount != 0 || principalAdjustmentAmount != 0 || principalPaymentAmount != 0);
    };

    const PricipalAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setPrincipalAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : principalPaymentAmount != 0 || latePaymentAmount != 0 || lateAdjustmentAmount != 0);
    };

    const LateAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setLateAdjustmentAmount(recursiveRemove(value, debtors.fee));
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : latePaymentAmount != 0 || principalPaymentAmount != 0 || principalAdjustmentAmount != 0);
    };

    const handleCheckboxChange = (event) => {
        setBtnDisabled(event.target.checked);
        setNoPaymentMadeCheck(event.target.checked);
    }

    const handleChange = (event) => {
        setBtnDisabled(event.target.checked);
        setDocumentNotAvailable(event.target.checked);
    }

    useEffect(() => {
        let principal_amount_due = debtors.principalDue;
        for (let account of placedAccounts) {
            if (account.prcpl_Adj_Amt && account.prpcl_Pay_Amt === 0) {
                principal_amount_due = ((principal_amount_due) + (account.prpcl_Pay_Amt + account.prcpl_Adj_Amt));
            }
            else if (account.prcpl_Adj_Amt > 0 && account.prpcl_Pay_Amt > 0) {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
            else {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
        }
        let late_fee_due = debtors.fee;
        for (let account of placedAccounts) {
            if (account.fee_Adj_Amt && account.fee_Pay_Amt === 0) {
                late_fee_due = ((late_fee_due) + (account.fee_Pay_Amt + account.fee_Adj_Amt));
            }
            else if (account.fee_Adj_Amt > 0 && account.fee_Pay_Amt > 0) {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
            else {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
        setLateFeeDue(Number(late_fee_due.toFixed(2)));
    }, [placedAccounts])

    useEffect(() => {
        //Principal Amount
        let Adjustment = Number(Number(principalAdjustmentAmount)?.toFixed(2));
        let principal = Number((principalPaymentDue - principalPaymentAmount)?.toFixed(2));

        //LateFee
        let LateAdjustment = Number(Number(lateAdjustmentAmount)?.toFixed(2));
        let LateFee = Number((LateFeeDue - latePaymentAmount)?.toFixed(2));

        setUpdatedPrincipalAmount(principal + Adjustment);
        setUpdatedLateAmount(LateFee - LateAdjustment);
    }, [LateFeeDue, principalPaymentDue, principalPaymentAmount, principalAdjustmentAmount, latePaymentAmount, lateAdjustmentAmount])

    useEffect(() => {
        /* Updated Total Amount */
        setUpdatedTotalAmount(Number((updatedPrincipalAmount + updatedLateAmount).toFixed(2)));
        (updatedPrincipalAmount < 0.00 || updatedLateAmount < 0.00) ? setIsValid(false) : setIsValid(true);

    }, [updatedPrincipalAmount, updatedLateAmount])

    useEffect(() => {
        if (debtors.phase === constant.CONT) {
            setService('Contingency');
        }
        if (debtors.phase === constant.PREC) {
            setService('Flat Fee');
        }
        else if (debtors.phase === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    async function CheckPreviousPayment(dbt_NO: string) {
        setButtonLoading(true);
        // Check if the full account balance is adjusted to zero without payment.
        if (updatedTotalAmount === 0.00 && ((principalPaymentAmount === "0.00" || principalPaymentAmount === "") && (latePaymentAmount === "" || latePaymentAmount === "0.00"))) {
            setCheckFullAmount(true);
        } else {
            setCheckFullAmount(false);
            // Check if payment matches previous pending payment.
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${dbt_NO}`).then((PlacedAccounts) => {
                if (PlacedAccounts.data.length === 0 || noPaymentMadeCheck ? true : false) {
                    submitPayment();
                } else {
                    setConfirmDialog(true);
                }
            });
        }
    }

    const submitPayment = () => {
        (async () => {
            if (!btnDisabled) {
                let request = {
                    "dbt_No": debtors.agencyID,
                    "prcpl_Pay_Amt": (principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount)),
                    "prcpl_Adjst_Amt": (principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount)),
                    "fee_Pay_Amt": (latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount)),
                    "fee_Adjst_Amt": (lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount))),
                    "user_Id": UserName
                };
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Payment", operationText: `Payment to Principal: $${principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount)},Payment to Late Fee: $${latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount)},Adjustment to Principal: $${principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount)},Adjustment to Late Fee: $${lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount))}`
                };
                feedOperation.push(operations);

                await usePost<any>('Account/SubmitPayment', request);
                setDisable(true);
                setButtonLoading(false);
                GetPayment();
                InsertTransactionLog(state?.userAccessContext?.id, debtors?.agencyID, feedOperation);
            }
            else if (!documentNotAvailable) {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: 'No Payment Received'
                };
                feedOperation.push(operations);

                InsertTransactionLog(state?.userAccessContext?.id, debtors?.agencyID, feedOperation);
                let request = {
                    "dbtNumber": debtors.agencyID,
                    "close_acct_note": null,
                    "close_acct_bal": null,
                    "close_acct_reason": "No Payment Received",
                    "user_Id": UserName,
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                await axios.post(`${constant.NoPaymentMade}/${debtors.agencyID}?`);
                setBtnDisabled(false);
            }
            else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: 'Documents not Available'
                };
                feedOperation.push(operations);

                InsertTransactionLog(state?.userAccessContext?.id, debtors?.agencyID, feedOperation);
                let request = {
                    "dbtNumber": debtors.agencyID,
                    "close_acct_note": null,
                    "close_acct_bal": null,
                    "close_acct_reason": "Documents not Available",
                    "user_Id": UserName,
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                setBtnDisabled(false);
            }
            hidenotificationForAllUsers();
            props.onHideAll();
            setPaymentVal(false);
        })().finally(() => {
            setButtonLoading(false);
            setNoPaymentMadeCheck(false);
            setSubmit(true);
        });
    }

    const GetPayment = () => {
        (async () => {
            try {
                dispatch({ type: GlobalStateAction.Busy });
                await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.agencyID}`).then((PlacedAccounts) => {
                    setPlacedAccounts(PlacedAccounts.data);
                }).finally(() => {
                    emptyCheck();
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const EarlyRoll = () => {
        setEarlyButtonLoading(true);
        (async () => {
            let request = {
                "dbt_No": debtors.agencyID,
                "dbt_Client": debtors.client,
                "user_Id": UserName
            };
            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Early Roll", operationText: "Early Roll"
            };
            feedOperation.push(operations);

            await usePost<IPlacedAccountContract[]>('RolledPlacement', request);
            setEarlyRollRolled(true);
            setEarlyRoll(false);
            props.onEroll();
            setshowUndoButton(!showUndoButton);
            InsertTransactionLog(state?.userAccessContext?.id, debtors?.agencyID, feedOperation);
        })().finally(() => {
            setEarlyButtonLoading(false);
        })
    }

    const undoRoll = () => {
        setEarlyButtonLoading(true);
        (async () => {
            await useDelete(`RolledPlacement?DBTNumber=${debtors.agencyID}`).then((r) => {
                props.onEroll();
                setshowUndoButton(!showUndoButton);
                if (r.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: r.statusText });
                }
            });
            setEarlyRollRolled(false);
            setUndoEarlyRoll(false);
            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Rolled Account", operationText: "Manually rolled debtor undone"
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.userAccessContext?.id, debtors?.agencyID, feedOperation);
        })().finally(() => {
            setEarlyButtonLoading(false);
        })
    }

    const hideDocNotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.agencyID,
                    "typeId": 6,
                    "userRole": 1
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.agencyID,
                    "typeId": 5,
                    "userRole": 1
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root} alignItems='flex-start'>
                <Grid item className={classes.displayFlex} xs={9}>
                    <Grid item xs={'auto'} className={classes.gridFlex}>
                        <Typography variant="h6" component="h6" gutterBottom className={classes.typographyTitleStyle}><b>Current Amount Due </b></Typography>
                        <Grid item xs={12} className={classes.gridFlex} direction='column' >
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle} ><b>Principal</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${principalPaymentDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Late Fee</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${LateFeeDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Total</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={`${classes.currentamountStyle} ${classes.currentamountStyleColor}`}><b>${TotalBalance.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Payment Amount</b>
                        </Typography>
                        <CurrencyInput
                            id="R_PrincipalPaymentAmount"
                            placeholder="$ Principal Payment Amount"
                            className={classes.inputField}
                            value={principalPaymentAmount}
                            allowNegativeValue={false}
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={handleOnValueChange}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            onKeyPress={preventMinus}
                            decimalScale={2}
                            maxLength={13}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading}
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            id="R_LatePaymentAmount"
                            allowNegativeValue={false}
                            value={latePaymentAmount}
                            placeholder="$ Late Fee Payment Amount"
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={handleFlatfeeValueChange}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            onKeyPress={preventMinus}
                            decimalScale={2}
                            maxLength={13}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading}
                        />
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Adjustment Amount</b>
                        </Typography>
                        <CurrencyInput
                            className={classes.inputField}
                            id="R_principalAdjustmentAmount"
                            value={principalAdjustmentAmount}
                            placeholder="$ Principal Adjustment Amount"
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={PricipalAdjValueChange}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            decimalScale={2}
                            maxLength={13}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading}
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            id="R_lateAdjustmentAmount"
                            value={lateAdjustmentAmount}
                            placeholder="$ Late Fee Adjustment Amount"
                            prefix='- $'
                            decimalsLimit={2}
                            onKeyPress={preventMinus}
                            onValueChange={LateAdjValueChange}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            decimalScale={2}
                            maxLength={13}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading}
                        />
                    </Grid>
                    <Grid item xs={2} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}><b>Updated Amount Due</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b> ${updatedPrincipalAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedLateAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedTotalAmount.toFixed(2)}</b></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                    <React.Fragment>
                        {debtors.dbT_Status === constant.AwaitingPayment338 || debtors.dbT_Status === constant.AwaitingPayment324 ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={btnDisabled}
                                        size="small"
                                        onChange={handleCheckboxChange}
                                        name="checkedB"
                                        color="primary"
                                        disabled={!state.userAccessContext?.paymentUpdates}
                                    />
                                }
                                label="No Payment Made" />
                            : debtors.dbT_Status === constant.AwaitingDocs326 ?
                                <FormControlLabel
                                    label="Documents not Available"
                                    control={<Checkbox
                                        disabled={BlockAwaitUsers}
                                        onChange={handleChange} />}
                                />
                                : null
                        }
                        {debtors.isActive !== true && !constant.Phases?.includes(debtors.phase) && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false) ?
                            <React.Fragment>
                                <Button id="Inquiry_RollButton" variant="contained" color="primary" size="small"
                                    startIcon={<ReplayIcon />}
                                    onClick={() => { setEarlyRoll(true); EarlyRoll(); props.onEroll(); }}
                                    disabled={!canRollAccount || debtors.phase === constant.CONT || handleDisable || BlockAwaitUsers}
                                    className={classes.buttonStyle1} >
                                    {!canRollAccount || handleDisable || EarlyRollRolled ? "Rolled" : "Roll"}
                                </Button>
                                {(EarlyRollRolled) && (
                                    <IconButton id="Inquiry_UndoEarlyRoll" color="primary" aria-label="add to shopping cart"
                                        size="small"
                                        onClick={() => {
                                            setClicked(!clicked);
                                            setUndoEarlyRoll(true);
                                            undoRoll();
                                            setEarlyRollRolled(false);
                                        }}
                                        disabled={BlockAwaitUsers || props?.isEnable?.status_Id === 3}>
                                        <ReplayIcon />
                                    </IconButton>
                                )}
                            </React.Fragment>
                            : null}
                        <Typography component="h6" gutterBottom className={classes.typographyFontStyle}><b>Phone Number : {phoneNumber}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.typographyStyleColor}>{service}</Typography>
                        <Typography variant="subtitle1" gutterBottom className={`${classes.typographyStyleColor} ${classes.typographyFontStyle}`}><b>{props.debtors.status.replace('|', " ")}</b></Typography>
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Date: {referralDate !== 'Invalid date' ? referralDate : ""}</b></Typography>
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Amount: ${props.debtors.referralAmt.toFixed(2)}</b></Typography>
                    </React.Fragment>
                </Grid>
                <Grid item xs={12} >
                    <Box className={`${classes.displayFlex} ${classes.btnBoxSpacing}`}>
                        <Box className={classes.btnSpacing} width="16%" >
                            {btnDisabled ?
                                <Button id="R_Submit_btn" size="small" type="submit" variant="contained" color="primary"
                                    disabled={!btnDisabled || BlockAwaitUsers}
                                    startIcon={<CheckCircleIcon />}
                                    onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(props.debtors.agencyID); setButtonLoading(true) }}
                                    className={`${classes.button} ${classes.buttonBgStyle}`}
                                    style={{ background: "#009900" }}>
                                    Submit
                                    {buttonLoading === true ? <CircularProgress size={20} className={classes.circleStyle} style={{ color: 'white' }} /> : null}
                                </Button>
                                :
                                <Button id="R_Submit_btn" size="small" type="submit" variant="contained" color="primary"
                                    disabled={!inputVal || !disable || !canUpdatePayment || !isValid || BlockAwaitUsers}
                                    startIcon={<CheckCircleIcon />}
                                    onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(props.debtors.agencyID); setButtonLoading(true) }}
                                    className={`${classes.button} ${classes.buttonBgStyle}`}
                                    style={{ background: "#009900" }}>
                                    Submit
                                    {buttonLoading === true ? <CircularProgress size={20} className={classes.circleStyle} style={{ color: 'white' }} /> : null}
                                </Button>
                            }
                        </Box>
                        <Box className={classes.btnSpacing} width="17%">
                            <CloseAccount dbtNumber={debtors.agencyID} clientId={debtors.client} Disable={isEnable} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} closeSts={btnDisabled} BlockAwaitAccess={BlockAwaitUsers} onUpdate={handleGetStatus} closeReason={placedAccounts[0]?.close_Acct_Reason} closedDetails={GetAccStatus?.isClosed} undoStatus={placedAccounts[0]?.status_Id} />
                        </Box>
                        {(!constant.Phases?.includes(debtors.phase) && isEnable?.hasLetter > 0 && debtors.isActive === true && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false)) ?
                            <Box className={classes.btnSpacing} width="17%">
                                <Button id="Inquiry_EarlyRoll" variant="contained" color="primary" size="small"
                                    startIcon={<ReplayIcon />}
                                    className={`${classes.button} ${classes.btnRollSpacing}`}
                                    onClick={() => { setClicked(!clicked); setshowUndoButton(!showUndoButton); setEarlyRoll(true); EarlyRoll() }}
                                    disabled={!canRollAccount || props?.isEnable?.rolled || BlockAwaitUsers || GetAccStatus?.isCloseRemove || GetAccStatus?.isClosed || props?.isEnable?.removed || props?.isEnable?.status_Id === 3}>
                                    {props?.isEnable?.rolled ? "Rolled" : "Early Roll"}
                                    {earlyButtonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
                                </Button>
                                {(props?.isEnable?.rolled) && (
                                    <IconButton id="Inquiry_UndoEarlyRoll" color="primary" aria-label="add to shopping cart"
                                        size="small"
                                        onClick={() => {
                                            setClicked(!clicked);
                                            setUndoEarlyRoll(true);
                                            undoRoll();
                                            setEarlyRollRolled(false);
                                        }}
                                        disabled={BlockAwaitUsers || props?.isEnable?.status_Id === 3}>
                                        <ReplayIcon />
                                    </IconButton>
                                )}
                            </Box>
                            :
                            null
                        }
                        {!constant.Phases?.includes(debtors.phase) ?
                            <Box className={classes.btnSpacing} width="17%">
                                <CloseAndRemove dbtNumber={debtors.agencyID} clientId={debtors.client} Disable={isEnable} checkPhase={debtors.phase} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} closeSts={btnDisabled} BlockAwaitAccess={BlockAwaitUsers} onUpdate={handleGetStatus} closeReason={placedAccounts[0]?.close_Acct_Reason} closeRemDetails={GetAccStatus?.isCloseRemove} onGetUpdate={handleRemovedStatus} />
                            </Box> : null
                        }

                        <Box className={classes.btnSpacing} width="16%">
                            <DocumentUpload clientId={props.debtors.client} letterDetails={props.letterDetails} agencyId={props.debtors.agencyID} accountId={props.debtors.clntAcct1} onHideNotification={hideDocNotificationForAllUsers} BlockAwaitAccess={BlockAwaitUsers} GetDebtorLetterDetails={props.GetDebtorLetterDetails} dbt_status={debtors.status} />
                        </Box>
                        <Box >
                            <span className={classes.spanErrorSpacing}>
                                {!isValid ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter an amount that is less than the amount due.
                                    </Typography> : null}
                                {paymentVal ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter any payment amounts in the payment boxes.
                                    </Typography> : null}
                            </span>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Submit clientId={props.debtors.clntAcct1} dbtNo={props.debtors.agencyID} placedAccounts={placedAccounts} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    <Collapse in={object.SubmitValue} timeout="auto">
                    </Collapse>
                </Grid>
            </Grid>

            <Dialog TransitionComponent={Transition} fullScreen={fullScreen}
                open={ConfirmDialog} keepMounted
                className={classes.dialogboxPaper}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleConfirmDialogCLose}>
                    <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTitle className={classes.titleHeaderOverride}>
                        <img src={Warning} alt="Block_Email" className={classes.ImageWarning} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        One or more of your payments/adjustments matches the value of a pending update.
                        Are you sure, you want to add this additional update to the account?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" variant="contained" startIcon={<CheckCircleIcon />} color="primary" autoFocus
                        onClick={() => { setConfirmDialog(false); submitPayment(); }} className={classes.confirmbtn} >
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus
                        className={classes.cancelbtn} onClick={handleConfirmDialogCLose} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                classes={{ paper: classes.dialogePaper }}
                fullScreen={fullScreen}
                open={checkFullAmount}
                aria-labelledby="responsive-dialog-title">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCheckFullAmountDialog}>
                    <Typography variant="h5" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" className={classes.dialogContent} gutterBottom>
                            The account balance is being changed to $0.00.  Was a payment made?<br /><br />
                            If Yes, click OK.<br />
                            If No, Cancel.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" variant="contained" onClick={handleCheckFullAmountDialog} className={classes.yesButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={() => { setCheckFullAmount(false); submitPayment(); }} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={submit} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Transaction Submitted Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={earlyRoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account Rolled Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={undoEarlyRoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Undo Rolled Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}
export default ReportAccountDetails