import { useMsal } from '@azure/msal-react';
import { Box, Grid, TablePagination, TableSortLabel } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import { IReportsList } from '../../models/Reports/ReportsList';
import { IAdminGetClients } from '../../models/UploadLog/AdminGetClients';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import AckReportView from '../AckReport/AckReportView';
import { useStyles } from '../common/ReportListCss';
import AckAdditionalReportView from './AckAdditionalReportView';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        fontSize: 12,
        padding: '2px 5px',
        textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

const WebviewReports = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const { state } = useGlobalState();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(" ");
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const [getClient, setGetClientsList] = useState([]);
    const [ReportsList, setReportsList] = useState<IReportsList[]>([]);

    const [ProgressBar, setshowProgressBar] = useState(false);
    const { accounts } = useMsal();
    const [showAckReport, setShowAckReport] = useState(false);
    const [showAdditionalAckReport, setShowAdditionalAckReport] = useState(false);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        getReportsAsync();
    }, []);

    async function getReportsAsync() {
        setshowProgressBar(true);
        if (state.userAccessContext?.id !== undefined) {
            let request = {
                "userId": state.userAccessContext?.id
            }
            usePost<IReportsList[]>("ReportGetByUser", request).then((reportList) => {
                setReportsList(reportList.data);
                setShowAckReport(true);
                setShowAdditionalAckReport(true);
            }).finally(() => {
                setshowProgressBar(false);
            })
        } else {
            let requestAccount = { "userName": accounts[0]?.username };
            usePost<any>("User/GetUserByEmail", requestAccount).then((userAcc) => {
                let request = {
                    "userId": state.userAccessContext?.id
                }
                usePost<IReportsList[]>("ReportGetByUser", request).then((reportList) => {
                    setReportsList(reportList.data);
                    setShowAckReport(true);
                    setShowAdditionalAckReport(true);
                })
            }).finally(() => {
                setshowProgressBar(false);
            })
        }
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "NAME", sortable: true },
        { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
        { id: "action", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
        { id: "share", numeric: false, disablePadding: false, label: "SHARE", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false }
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <StyledTableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
        );
    }

    useEffect(() => {
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
            else if (state?.userAccessContext?.role === 1) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
            else if (state?.userAccessContext?.role === 0) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
        })()
    }, [])

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>

                    <Box component="span" m={0} width="100%" >
                        <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                            <b> ACKNOWLEDGEMENT REPORTS </b>
                        </Typography>
                    </Box>

                    <Grid component={Paper} xs={12} sm={12} lg={12} >
                        <TableContainer component={Paper} className={`${classes.tableBodyAckReport} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {showAckReport ? <AckReportView clients={getClient} /> : null}
                                    {showAdditionalAckReport ? <AckAdditionalReportView clients={getClient} /> : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid component={Paper} container spacing={0}>
                    <Grid item xs={6} sm={8}>
                        <div className={classes.footer}>
                            <Footer />
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TablePagination
                            id="Report_TablePagination"
                            rowsPerPageOptions={[15, 25, 50]}
                            component="div"
                            count={ReportsList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </div >
        </React.Fragment >
    )
}

export default WebviewReports

