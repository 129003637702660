import { Typography } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../GlobalStyles/TableStyledCell';
import EditPerformance from './EditPerformance';
import useStyles from './ReportStyleCSS';
import ViewReport from './ViewReport';
import ReportShare from './ReportShare';
import DeleteReport from './DeleteReport';
import { IPerformanceReportsResult } from '../../models/Reports/ReportsList';
import React from 'react';
import moment from 'moment';
import { useGlobalState } from '../../store/GlobalStore';

const PerformanceReport: React.FC<{ performanceReportsResult: IPerformanceReportsResult, onSave: () => void, }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const { performanceReportsResult } = props;

    return (
        <React.Fragment>
            <StyledTableRow>
                <StyledTableCell>
                    <Typography gutterBottom className={classes.textStyle}>
                        {performanceReportsResult?.performanceReportName}
                    </Typography>
                </StyledTableCell>
                <StyledTableCell>
                    {moment(performanceReportsResult?.createdDate)?.format(state.GlobalUtils?.settingValue)}
                </StyledTableCell>
                <StyledTableCell style={{ display: 'flex' }}>
                    <ViewReport row={performanceReportsResult} />
                    <EditPerformance row={performanceReportsResult} onSave={props.onSave} />
                </StyledTableCell>
                <StyledTableCell>
                    <ReportShare row={performanceReportsResult} />
                </StyledTableCell>
                <StyledTableCell>
                    <DeleteReport />
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    )
}

export default PerformanceReport