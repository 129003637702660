import {
    Backdrop,
    Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl,
    FormControlLabel, IconButton, List, ListItem, ListItemIcon, ListItemText, TableContainer, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import PersonIcon from '@material-ui/icons/Person';
import ShareIcon from '@material-ui/icons/Share';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
// Componants
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import { PuffLoader } from 'react-spinners';
import { IPerformanceReportDetailsResult, IPerformanceReportsResults, IPreReportIdList, IPreReportUserList } from '../../models/PerformanceReport/IPerformanceReportsResults';
import { IPerformanceReportsResult } from '../../models/Reports/ReportsList';
import { IClientCodeName } from '../../models/SendNotification/IClientCodeName';
import { IUserForClient } from '../../models/SendNotification/IUserForClient';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from './ReportStyleCSS';

const ReportShare: React.FC<{ row: IPerformanceReportsResult }> = (props) => {
    const classes = useStyles();
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [performanceReportsResult, setPerformanceReportsResult] = useState<IPerformanceReportsResults[]>([]);
    const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
    const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
    const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
    const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
    const [SelectedReportsList, setSelectedReportsList] = useState<IPerformanceReportsResults[]>([]);
    const [subscribedUsersList, setSubscribedUsersList] = useState<any>([]);
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [SearchTerm, setSearchTerm] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
        getPerformanceReportsList();
        getAllClientCodeList();
    };

    const handleClose = () => {
        setOpen(false);
        setClientCodes([]);
        setSelectedUsers([]);
        setUsersForClient([]);
        setSelectedClientCodes([]);
        setSelectedClient("");
        setSearchText("");
        setSearchTerm("");
    }

    const getPerformanceReportsList = () => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IPerformanceReportsResults[]>('GetPerformanceReports').then((r) => {
                setPerformanceReportsResult(r?.data);
                getSubscribedUsersResult(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const getSubscribedUsersResult = (values: IPerformanceReportsResults[]) => {
        (async () => {
            setshowProgressBar(true);
            let reportsIdList = [];
            values?.map((x) => {
                let reportsDetail: IPreReportIdList = { performanceReportId: x?.performanceReportId }
                reportsIdList.push(reportsDetail);
            });
            let request = {
                "preReportsIdMapDetails": reportsIdList,
            }
            await usePost<{ reportDetailsResultContract: IPerformanceReportDetailsResult[] }>('GetSubscribedUsersList', request).then((r) => {
                setSubscribedUsersList(r?.data?.reportDetailsResultContract);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const handleClientCodeToggle = (client: IClientCodeName) => () => {
        let newSelectedClientCodes = [...SelectedClientCodes];

        if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
            newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
        } else {
            newSelectedClientCodes.push(client);
        }

        let clientcodes = "";
        for (var checkClientCode in newSelectedClientCodes) {
            clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
            clientcodes += ',';
        }
        setSelectedClientCodes(newSelectedClientCodes);
        getUsersForClient(clientcodes);
        setSelectedClient(clientcodes);
        setshowProgressBar(true);
    }

    const getUsersForClient = (clientCodes) => {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "client_code": clientCodes
            }
            await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClientList) => {
                setUsersForClient(GetUsersForClientList.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const getAllClientCodeList = () => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IClientCodeName[]>('GetClientCodeList').then((GetClientCodeList) => {
                setClientCodes(GetClientCodeList.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const getClientUsersAsync = () => {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "client_code": selectedClient,
                "name": SearchTerm?.trimLeft().trimRight()
            }
            await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
                setUsersForClient(GetUsersForClient.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const handleUserToggle = (user: IUserForClient) => () => {
        let newSelectedUsers = [...SelectedUsers];
        if (newSelectedUsers.some(u => u.id == user.id)) {
            newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
        } else {
            newSelectedUsers.push(user);
        }
        setSelectedUsers(newSelectedUsers);
    }

    const GetClientLists = () => {
        setshowProgressBar(true);
        (async () => {
            await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText.trim()}`).then((GetClientSearchList) => {
                setClientCodes(GetClientSearchList.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const handleAllRight = () => {
        setshowProgressBar(true);
        let clientcodes = "";
        for (var checkClientCode in ClientCodes) {
            clientcodes += ClientCodes[checkClientCode].clientCode;
            clientcodes += ',';
        }
        getUsersForClient(clientcodes);
        setSelectedClientCodes(ClientCodes);
    };

    const handleAllLeftClient = () => {
        let clientcodes = "";
        getUsersForClient(clientcodes);
        setSelectedClientCodes([]);
        setshowProgressBar(false);
    }

    const handleCheckedRight = () => {
        setSelectedUsers(UsersForClient);
        setshowProgressBar(false);
    };

    const handleAllLeft = () => {
        setSelectedUsers([]);
        setshowProgressBar(false);
    };

    const clientCodeList = () => (
        <React.Fragment>
            <Typography variant="button" display="block" color="secondary" className={classes.clientheader} >
                <b>CLIENTS</b>
            </Typography>
            <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
                <List dense className={classes.selectboxpadding} component="div" role="list">
                    {ClientCodes.slice(0, 150).map((client, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                                <ListItemIcon>
                                    <Checkbox
                                        size="small" style={{ color: "#1bc42c" }}
                                        className={classes.clientcheckbox}
                                        id="SA_ClientCode_Checkbox"
                                        checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                                    <Typography variant="body2" className={classes.clientlist} >
                                        <b>{client.clientCode}</b>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                    {searchText && !ClientCodes.length ?
                        <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                            No Clients to display..
                        </Typography> : null}
                    <ListItem />
                </List>
            </div>
        </React.Fragment>
    );

    const usersList = () => (
        <React.Fragment>
            <Typography variant="button" display="block" className={classes.clientheader} color="secondary">
                <b>USERS</b>
            </Typography>
            <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
                <List dense className={classes.selectboxpadding} component="div" role="list">
                    {UsersForClient.map((user, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                                <ListItemIcon>
                                    <Checkbox
                                        size="small" style={{ color: "#1bc42c" }}
                                        className={classes.clientcheckbox}
                                        id="NF_Users_Checkbox"
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        checked={SelectedUsers.some(u => u.id == user.id)}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                                    <Typography variant="body2" className={classes.clientlist}>
                                        <b>{user.firstName}{user.lastName}</b>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                    {SearchTerm && !UsersForClient.length ?
                        <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                            No Users to display..
                        </Typography> : null}
                    <ListItem />
                </List>
            </div>
        </React.Fragment>
    );

    const selectedUsersList = () => (
        <React.Fragment>
            <Typography variant="button" display="block" className={classes.clientheader} color="secondary" >
                <b>SELECTED USERS LISTS</b>
            </Typography>
            <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
                <List dense className={classes.selectboxpadding} component="div" role="list">
                    {SelectedUsers.map(({ firstName, lastName }, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem">
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                                    <Typography variant="body2" className={classes.clientlist}>
                                        <b>{firstName}{lastName}</b>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </div>
        </React.Fragment>
    );

    return (
        <div>
            <IconButton id="RS_Share" color="primary" className={classes.shareButtonStyle}
                aria-label="Share Icon" onClick={handleClickOpen}>
                <ShareIcon style={{ fontSize: 22 }} />
            </IconButton>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open} onClose={handleClose}
                classes={{ paper: classes.dialogeReportShare }}
                aria-labelledby="responsive-dialog-title" >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        REPORT DISTRIBUTION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <div style={{ padding: '10px 0px 0px' }}>
                        <Box display="flex" >
                            <Box width="20%" className={classes.sidebar}>
                                <Typography variant="h6" gutterBottom className={classes.reportNameStyle}>
                                    <b> Performance Report List</b>
                                </Typography>
                                <Box width="100%" display="flex" flexDirection="column" className={classes.boxStyle1}>
                                    {performanceReportsResult.map((row) => {
                                        return (
                                            <FormControl>
                                                <FormControlLabel
                                                    control={<Checkbox size='small' name="UserList" className={classes.paddingCheckbox1} />}
                                                    label={<span className={classes.inputFontSize1} title={row?.performanceReportName}>
                                                        {row?.performanceReportName}
                                                    </span>}
                                                    className={classes.permittedFiled} />
                                            </FormControl>
                                        )
                                    })}
                                </Box>
                            </Box>

                            <Box width="80%" p={3} className={classes.sidebar1}>
                                <Typography variant="h6" gutterBottom className={classes.reportNameStyle2}>
                                    <b>Current Report Name: {row?.performanceReportName} </b>
                                </Typography>
                                <Box display="flex">
                                    <Box width="23%" >
                                        <input value={searchText}
                                            type='text' placeholder="Search Clients"
                                            className={classes.searchIcon}
                                            onChange={e => setSearchText(e.target.value)}
                                            onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                                            maxLength={255}
                                        />
                                        <div className={classes.gridstyle}>
                                            {clientCodeList()}
                                        </div>
                                    </Box>
                                    <Box width="15%" display="flex" justifyContent="center" alignItems="center" >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button
                                                id="NF_SelectAll_btn"
                                                className={classes.selectallbutton}
                                                variant="contained" color="primary"
                                                size="small" aria-label="move all right"
                                                onClick={handleAllRight}
                                                startIcon={<GroupAddIcon />} >
                                                Select All
                                            </Button>
                                            <Button
                                                id="NF_RemoveAllClient_btn"
                                                className={classes.selectallbutton}
                                                variant="contained" color="primary"
                                                size="small" onClick={handleAllLeftClient}
                                                disabled={!SelectedClientCodes.length}
                                                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon />} >
                                                Remove All
                                            </Button>
                                        </div>
                                    </Box>
                                    <Box width="24%" >
                                        <input value={SearchTerm}
                                            type='text' placeholder="Search Users"
                                            className={classes.searchIcon}
                                            onChange={e => setSearchTerm(e.target.value)}
                                            onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                                            maxLength={255}
                                        />
                                        <div className={classes.gridstyle}>
                                            {usersList()}
                                        </div>
                                    </Box>
                                    <Box width="15%" display="flex" justifyContent="center" alignItems="center" >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button id="NF_AddAll_btn" variant="contained" color="primary"
                                                className={classes.selectallbutton} size="small"
                                                onClick={handleCheckedRight} aria-label="move selected right"
                                                startIcon={<GroupAddIcon />}>
                                                Add All
                                            </Button>
                                            <Button
                                                id="NF_RemoveAll_btn" variant="contained"
                                                color="primary" className={classes.selectallbutton}
                                                size="small" onClick={handleAllLeft}
                                                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon />}>
                                                Remove All
                                            </Button>
                                        </div>
                                    </Box>
                                    <Box width="23%" >
                                        <div className={classes.gridstyle} style={{ marginTop: '48px' }}>
                                            {selectedUsersList()}
                                        </div>
                                    </Box>
                                </Box>

                                <Typography variant="h6" gutterBottom className={classes.reportNameStyle1}>
                                    <b>Reports shared users list </b>
                                </Typography>

                                <Box display="flex">
                                    <Box width="100%" display="flex" flexDirection="row" className={classes.boxStyle}>
                                        <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                            {subscribedUsersList.map((row) => {
                                                return (
                                                    <FormControl>
                                                        <FormControlLabel
                                                            control={<Checkbox size='small' name="UserList" defaultChecked />}
                                                            label={row?.username}
                                                            className={classes.permittedFiled1}
                                                        />
                                                    </FormControl>
                                                )
                                            })}
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button id="CPO_OK_btn" size="small" color="primary" className={classes.shareBtn} autoFocus startIcon={<ShareIcon />}>
                        Share Report
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelBtn} startIcon={<CancelIcon />} autoFocus onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ReportShare