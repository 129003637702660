import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Radio, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import * as constant from "../../constants/Constant";
import useStyles from './ReportStyleCSS';
// Icon
import CancelIcon from '@material-ui/icons/Cancel';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
// Componants
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import { IReportControlDetailsResult, IReportsValuesContract } from '../../models/PerformanceReport/IPerformanceReportsResults';
import { IPerformanceReportsResult } from '../../models/Reports/ReportsList';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const ViewReport: React.FC<{ row: IPerformanceReportsResult }> = (props) => {
    const classes = useStyles();
    const { row } = props;
    const { state } = useGlobalState();
    let history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [reportType, setReportType] = useState<number>(0);
    const [reportControlList, setReportControlList] = useState<IReportControlDetailsResult[]>([]);
    const [ReportDetailsList, setReportDetailsList] = useState<IReportsValuesContract[]>([]);
    const [StartDate, setStartDate] = useState<any>(new Date().setDate(new Date().getDate() - 1));
    const [EndDate, setEndDate] = useState<any>(new Date());
    const [ControlValue, setControlValue] = useState<string>("");

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    let dropDownValue;
    let optionItems;

    const handleClickOpen = () => {
        getReportsControlValues();
    };

    const getReportsControlValues = () => {
        (async () => {
            let request = {
                "performanceReportId": row?.performanceReportId
            }
            await usePost<{ reportControlResults: IReportControlDetailsResult[], reportsValuesContract: IReportsValuesContract[] }>("GetPerformanceReportDetails", request).then((ReportResult) => {
                setReportDetailsList(ReportResult?.data?.reportsValuesContract);
                setReportControlList(ReportResult?.data?.reportControlResults);
            })
        })().finally(() => {
            setOpen(true);
        })
    }

    const handleReportGenerate = () => {
        history.push('/performancereportbuilder', {
            controlValue: ControlValue, startDate: StartDate, endDate: EndDate, reportName: row?.performanceReportName, procedureName: row?.procedureName
        });
    }


    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
    };

    const handleValueChange = (event) => {
        setControlValue(event.target.value);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button className={classes.ButtonStyle} size="small" startIcon={<CreditScoreIcon />} variant="contained" color="primary" onClick={handleClickOpen}>
                View
            </Button>

            <Dialog PaperProps={{ style: { borderRadius: 10 } }} open={open} classes={{ paper: classes.dialogePaper }} onClose={handleClose}
                TransitionComponent={Transition} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        VIEW PERFORMANCE REPORT
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <TableContainer className={`${classes.ViewReport} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table aria-label="customized table">
                            <TableBody >
                                {reportControlList.map((r) => {
                                    return (
                                        <TableRow>
                                            <TableCell width="35%" className={classes.controlPadding}>
                                                <Typography variant="h6" style={{ color: 'blue', fontSize: '14px' }}>
                                                    <b>{r?.label?.toUpperCase()}</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell width="65%" className={classes.controlPadding}>
                                                {r?.controlName == constant?.textBox ?
                                                    <TextField id="CNT_Name"
                                                        variant="outlined"
                                                        autoComplete='off'
                                                        fullWidth margin="dense"
                                                        inputProps={{ maxLength: 250 }}
                                                        label={r?.label}
                                                        className={classes.controlDisplay}
                                                        onChange={handleValueChange}
                                                        size="small" name="activityDescription"
                                                    />
                                                    : r?.controlName == constant?.dropDownList ?
                                                        <FormControl size="small" variant="outlined" className={classes.controlDisplay}>
                                                            <InputLabel htmlFor="outlined-age-native-simple">{r?.label}</InputLabel>
                                                            <Select native
                                                                label={`${r?.label}`}
                                                                autoComplete='false'
                                                                inputProps={{
                                                                    name: 'role',
                                                                    id: 'outlined-age-native-simple',
                                                                }}>
                                                                {dropDownValue = (r?.controlValues)?.split("|")}
                                                                {optionItems = dropDownValue?.map((r) => {
                                                                    return (
                                                                        <option value={r}>{r}</option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                        : r?.controlName == constant?.checkBox ?
                                                            <>
                                                                {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                                                    return (
                                                                        <div className={classes.formControl}>
                                                                            <Checkbox name={r?.label} disabled style={{ padding: '2px 0px' }} />
                                                                            <span className={classes.controlName} >{result}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            : r?.controlName == constant?.radioButton ?
                                                                <>
                                                                    {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                                                        return (
                                                                            <div className={classes.formControl}>
                                                                                <Radio name={r?.label} disabled style={{ padding: '2px 0px' }} />
                                                                                <span className={classes.controlName} >{result}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                                : r?.controlName == constant?.datePicker && r?.label === constant.startDate ?
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <FormControl className={classes.formControl}>
                                                                            <KeyboardDatePicker
                                                                                inputVariant="outlined" variant="inline" size="small"
                                                                                format={DateFormat}
                                                                                className={classes.controlDisplay}
                                                                                disableToolbar
                                                                                autoOk={true}
                                                                                margin="normal"
                                                                                id={`${r?.label}`}
                                                                                value={StartDate}
                                                                                onChange={handleStartDateChange}
                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'change date',
                                                                                }}
                                                                                disableFuture={true}
                                                                            />
                                                                        </FormControl>
                                                                    </MuiPickersUtilsProvider>
                                                                    : r?.controlName == constant?.datePicker && r?.label === constant.endDate ?
                                                                        < MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <FormControl className={classes.formControl}>
                                                                                <KeyboardDatePicker
                                                                                    inputVariant="outlined" variant="inline" size="small"
                                                                                    format={DateFormat}
                                                                                    className={classes.controlDisplay}
                                                                                    disableToolbar
                                                                                    autoOk={true}
                                                                                    margin="normal"
                                                                                    id={`${r?.label}`}
                                                                                    value={EndDate}
                                                                                    onChange={handleEndDateChange}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                    }}
                                                                                    disableFuture={true}
                                                                                />
                                                                            </FormControl>
                                                                        </MuiPickersUtilsProvider>
                                                                        : r?.controlName == constant?.textArea ?
                                                                            <TextField id="CNT_Name"
                                                                                variant="outlined"
                                                                                autoComplete='off'
                                                                                multiline rows={4}
                                                                                fullWidth margin="dense"
                                                                                inputProps={{ maxLength: 250 }}
                                                                                label={r?.label}
                                                                                size="small" name="activityDescription"
                                                                            />
                                                                            : null}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions >
                    <Button id="CPO_OK_btn" size="small" color="primary" startIcon={<ViewTimelineIcon />} variant="contained" className={classes.viewBtn} onClick={handleReportGenerate}>
                        Generate Report
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" startIcon={<CancelIcon />} variant="contained" className={classes.cancelBtn} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ViewReport