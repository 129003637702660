import {
  Button, Dialog, DialogActions, DialogContent, IconButton, Snackbar, Typography, makeStyles, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import React, { useState } from 'react';
import { IRequestedReportDetails } from '../../../models/Reports/IReportStatusList';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { CircularProgress } from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios, { AxiosResponse } from 'axios';

const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 13,
    color: "blue",
  },
  yesbutton: {
    backgroundColor: 'green',
    fontSize: 12,
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    fontSize: 12,
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: '5px',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  downloadIconStyle: {
    color: "#a1a1a1",
    fontSize: 26,
    marginLeft: '15px'
  },
  downloadIconStyle1: {
    color: "green",
    fontSize: 26,
    marginLeft: '15px'
  },
  fabProgressPrint: {
    color: 'green'[500],
    position: 'absolute',
    top: '-4px',
    left: 9.5,
    zIndex: 1
  }
}))

const DownloadReportRequested: React.FC<{ row: IRequestedReportDetails }> = (props) => {
  const classes = useStyles();
  const { row } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [loading, setLoading] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  function handleDownloadFile() {
    setOpen(false);
    DownloadPdfFileAsync();
  };

  const removeAfterUnderscore = (filename: string): string => {
    const lastUnderscoreIndex = filename?.lastIndexOf('_');
    const extensionIndex = filename?.lastIndexOf('.');
    if (lastUnderscoreIndex !== -1 && lastUnderscoreIndex < extensionIndex) {
      return filename.substring(0, lastUnderscoreIndex) + filename?.substring(extensionIndex);
    }
    return filename;
  };

  const DownloadPdfFileAsync = async () => {
    setLoading(true);
    try {
      let newFileName = removeAfterUnderscore(row?.fileName);
      let request = {
        blobFileName: row?.blobUri,
        reportName: newFileName?.split(".")[0]
      };

      await usePost<any>("File/SplitLargeFileAndCreateZipAsync", request).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'split_files.zip'); // or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error('Error downloading the file', error);
    } finally {
      setLoading(false);
    }
  };

  // function base64ToArrayBuffer(base64: string) {
  //   const binaryString = window.atob(base64);
  //   const bytes = new Uint8Array(binaryString.length);
  //   return bytes.map((byte, i) => binaryString.charCodeAt(i));
  // }

  // function createAndDownloadBlobFile(body, filename) {
  //   const blob = new Blob([body]);
  //   const fileName = `${filename}`;
  //   const link = document.createElement('a');
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute('href', url);
  //     link.setAttribute('download', fileName);
  //     link.style.visibility = 'hidden';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }

  return (
    <React.Fragment>
      <IconButton size='small' style={{ padding: 0 }} onClick={handleClickOpen} disabled={row?.isRequested === 1 || row?.isRequested === 2} className={row?.isRequested !== 3 ? classes.downloadIconStyle : classes.downloadIconStyle1}>
        <CloudDownloadIcon fontSize='medium' className={row?.isRequested !== 3 ? classes.downloadIconStyle : classes.downloadIconStyle1} />
        {loading && <CircularProgress size={35} className={classes.fabProgressPrint} />}
      </IconButton>
      <Dialog maxWidth={'sm'} PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen} open={open}
        onClose={(_) => { handleClose1(); }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" style={{ color: "black", fontSize: 18 }} gutterBottom>
            Are you sure, you want to Download this file ?
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button variant="contained" size="small" onClick={(_) => { handleDownloadFile(); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose1(); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
          File does not exists!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DownloadReportRequested