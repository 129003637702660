import React from "react";
import {
  Button, Dialog, DialogContent, Grid, TextField, Typography, FormControl, Paper, Select, Backdrop, Card, DialogActions, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead,
} from "@material-ui/core";
import { useState } from "react";
import { useStyles } from "./GlobalTemplateCSS";
import { DialogTitleHeader } from "../../GlobalStyles/DialogStyle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetch, usePost } from "../../../utils/apiHelper";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// Icons
import CancelIcon from "@material-ui/icons/Cancel";
// Componants
import { Transition } from "../../GlobalStyles/DialogBoxTransition";
import { DialogTitleDelete } from "../../GlobalStyles/DialogStyle";

//Models
import * as constant from "../../../constants/Constant";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { IGlobalTemplate, IMappingDetails } from "../../../models/Placements/IPlacementMapping";
import { IMappingTemplate, IGlobalMappingEdit, ITableRow, IGlobalMappingTemplateList } from "../../../models/Placements/IMappingTemplate";
import { ITemplateList } from "../../../models/Admin/ClientPlacements/ITemplateList";
import { IGetBlobFolders } from "../../../models/Files/IGetBlobFolders";
import _ from "lodash";
import { IMappingEdit } from "../../../models/Placements/IMappingCreate";
import { TableRow } from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const headCells = [
  { id: "label_Name", numeric: false, disablePadding: false, label: "FIELDS NAME", sortable: true, fontSize: "12px" },
  { id: "control_Name", numeric: false, disablePadding: false, label: "ORDINAl POSITION", sortable: true, fontSize: "12px" },
  { id: "date_field", numeric: false, disablePadding: false, label: "SOURCE DATE FORMAT", sortable: true, fontSize: "12px" },
  { id: "action", numeric: false, disablePadding: false, label: "ACTION", sortable: true, fontSize: "12px" },
];

function EnhancedTableHead(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={classes.rowhead}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EditMappingTemplate: React.FC<{ CloudContainer: IGetBlobFolders[]; row: IGlobalMappingTemplateList; onUpdate: () => void; TemplateList: ITemplateList[] }> = (props) => {
  const { row, onUpdate, TemplateList } = props;
  const classes = useStyles();
  const [fieldNameList, setFieldNameList] = useState<IGlobalTemplate[]>([]);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [tablerows, setTableRows] = useState<ITableRow[]>([]);
  const [selectTemplatedName, setSelectTemplatedName] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [childFieldNameList, setChildFieldNameList] = useState([]);

  const [localState, setLocalState] = useState(row);

  const handleClose = () => {
    setLocalState(row);
    setOpen(false);
    setTableRows([]);
  };

  const handleClickOpen = () => {
    setLocalState(props?.row);
    setOpen(true);
    fetchData();
  };

  const fetchData = async () => {
    setShowProgressBar(true);
    try {
      let newTableValues = [];
      let rowId = 0;
      const result = await useFetch(`ClientPlacement/GetMappingTemplates?placement_map_id=${row?.placement_map_id}`);
      if (Array.isArray(result?.data)) {
        result?.data?.forEach((r) => {
          let newValues: ITableRow = {
            rowId: rowId,
            isDateVisible: shouldDisplayDateDropdown(r?.field_name),
            source_ordinal_position: r?.source_ordinal_position,
            display_name: r?.display_name,
            date_format: r?.date_format,
            field_name: r?.field_name,
            data_type: r?.data_type,
          };
          newTableValues = [...newTableValues, newValues];
          rowId = rowId + 1;
        });
        setTableRows(newTableValues);

        // Get the Template fields detail based on template id 
        TemplateList?.forEach((r) => {
          if (r?.name === row?.map_TemplateName) {
            GetFieldNamesByTemplateId(r?.placement_template_id, newTableValues);
          }
        })
        setShowProgressBar(false);
      }
    } catch (error) {
      console.error("Error while fetching the data");
    }
  };

  let myString;
  let datedropDownValue;
  let optionItems;

  const handleChangeDateFormat = (e, rowId: any) => {
    let name = e.target.name;
    const dateFormatValue = e.target.value;
    let finalDateFormat;
    if (dateFormatValue === "DD/MM/YYYY" || dateFormatValue === "DDMMYYYY" || dateFormatValue === "DD-MM-YYYY") {
      finalDateFormat = "DD/MM/YYYY";
    } else if (dateFormatValue === "MM/DD/YYYY" || dateFormatValue === "MMDDYYYY" || dateFormatValue === "MM-DD-YYYY") {
      finalDateFormat = "MM/DD/YYYY";
    } else if (dateFormatValue === "YYYY/MM/DD" || dateFormatValue === "YYYYMMDD" || dateFormatValue === "YYYY-MM-DD") {
      finalDateFormat = "YYYY/MM/DD";
    } else if (dateFormatValue === "YYYY/DD/MM" || dateFormatValue === "YYYYDDMM" || dateFormatValue === "YYYY-DD-MM") {
      finalDateFormat = "YYYY/DD/MM";
    }
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
    tablerows?.forEach((a) => {
      if (a?.rowId === rowId && a?.field_name === e.target.name) {
        let newTableRowValue: ITableRow = {
          rowId: a?.rowId,
          isDateVisible: a?.isDateVisible,
          source_ordinal_position: a?.source_ordinal_position,
          display_name: a?.display_name,
          date_format: finalDateFormat,
          field_name: a?.field_name,
          data_type: a?.data_type,
        };
        tablerows[rowId] = newTableRowValue;
      }
    });
  };

  const onContainerSelect = (event) => {
    let name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
  };

  const getIgnoreTemplateFields = () => {
    const ignoreTemplateField: IGlobalTemplate = {
      data_type: "varchar",
      display_name: "--Ignore Field--",
      field_length: -1,
      field_name: "-1",
      is_nullable: true,
    };
    return ignoreTemplateField;
  };

  const GetFieldNamesByTemplateId = async (templateId: any, currentTableRowList: ITableRow[]) => {
    const getTemplate = await useFetch<IGlobalTemplate[]>(`ClientPlacement/GetTemplateById?placement_template_id=${templateId}`);

    let newFieldNames = [getIgnoreTemplateFields(), ...getTemplate.data];
    // Find the selected field names for the specific template
    if (currentTableRowList) {
      const updatedListA = newFieldNames.map((itemA) => {
        const matchingItemB = currentTableRowList.find(
          (itemB) => itemB.field_name === itemA.field_name
        );
        if (matchingItemB) {
          return { ...itemA, isDisabled: true };
        }
        return itemA;
      });
      setChildFieldNameList(updatedListA);
    }

    // Remove the field names from the placement field names list which is taken based on the placement template id 
    let currentDropdownList = _.cloneDeep(getTemplate.data);
    if (currentTableRowList && getTemplate?.data) {
      currentDropdownList = getTemplate.data.filter((r) =>
        !currentTableRowList.some((result) => r.field_name === result.field_name)
      );
    }

    let updatedFieldNames = [getIgnoreTemplateFields(), ...currentDropdownList];
    setFieldNameList(updatedFieldNames);
  };


  // Save the Updated templates to the database
  const handleEdit = (e) => {
    let mappingDetails = [];
    setShowProgressBar(true);

    let editMappingTemplate: IGlobalMappingEdit = {
      placement_map_id: localState?.placement_map_id,
      templateName: localState?.map_name,
      client_code: localState?.client_code,
      map_TemplateName: localState?.map_TemplateName,
      file_spec: localState?.file_spec,
    };

    tablerows?.map((row, index) => {
      if (row?.field_name != "-1") {
        let newMappingList: IMappingDetails = {
          field_name: row?.field_name,
          ordinal_position: index,
          date_format: row?.date_format,
        };
        mappingDetails.push(newMappingList);
      }
    });

    let request: IMappingEdit = {
      editMappingTemplate: _.cloneDeep(editMappingTemplate),
      mappingDetails: _.cloneDeep(mappingDetails),
    };
    (async () => {
      await usePost<IMappingTemplate>("Placements/EditMappingGlobalTemplate", request);
    })().finally(() => {
      handleClose();
      onUpdate();
      setOpen(false);
      fetchData();
      setShowProgressBar(false);
    });
  };

  const handleTemplateNameChange = (event) => {
    let name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
  };

  let dropDownValue;

  const handleSubControlClose = () => {
    setdeleteConfirm(false);
    setOpen(false);
  };

  const shouldDisplayDateDropdown = (selectedFieldName) => {
    const dateFieldNames = [
      "delinquency_date",
      "Itemization_Date",
      "birth_date",
      "last_payment_date",
      "service_date",
      "Codebtor_BirthDate",
    ];
    return dateFieldNames.includes(selectedFieldName);
  };

  const handleDropdownChange = (event, selected) => {
    let value = selected;
    setSelectedDropdownValue(value);
    let currentList = _.cloneDeep(tablerows);
    currentList?.forEach((a) => {
      if (a?.display_name === a?.display_name && a?.field_name === event.target.value) {
        let newTableRowValue: ITableRow = {
          rowId: a?.rowId,
          isDateVisible: a?.isDateVisible,
          source_ordinal_position: a?.source_ordinal_position,
          display_name: event.target.value,
          date_format: a?.date_format,
          field_name: a?.field_name,
          data_type: a?.data_type,
        };
        tablerows[a?.display_name] = newTableRowValue;
        currentList.push(newTableRowValue);
        setTableRows(currentList);
      }
    });
  };

  const handleAddButtonClick = (selectedDropdownValue) => {
    // to take the copy of the Table Row list
    let selectedDropDownListCopy = _.cloneDeep(tablerows) || [];
    if (selectedDropdownValue) {
      let nextPosition = 1;
      // To update the source ordinal position
      if (selectedDropDownListCopy && selectedDropDownListCopy.length > 0) {
        let maxPosition = Math.max(
          ...selectedDropDownListCopy.map(
            (item) => item.source_ordinal_position
          )
        );
        nextPosition = maxPosition + 1;
      }
      // Bind the values for New Table Row values
      let FieldList: ITableRow = {
        rowId: selectedDropDownListCopy?.length,
        source_ordinal_position: nextPosition,
        field_name: selectedDropdownValue?.field_name,
        display_name: selectedDropdownValue?.display_name,
        data_type: selectedDropdownValue?.data_type,
        isDateVisible: shouldDisplayDateDropdown(selectedDropdownValue?.field_name),
        date_format: null,
      };
      // Push the new selected values to the New drop down list value
      selectedDropDownListCopy.push(FieldList);
      // set the values to the Table rows list state
      setTableRows(selectedDropDownListCopy);
    }

    // Remove the Selected dropdown values from the Dropdown List
    let currentDropdownList = _.cloneDeep(fieldNameList);
    if (selectedDropdownValue) {
      currentDropdownList = currentDropdownList.filter((x, index) => index === 0 || x?.field_name !== selectedDropdownValue?.field_name);
      setFieldNameList(currentDropdownList);
      setSelectedDropdownValue("");
    }

    // This logic is to Get the list of fileds which is added by the user
    if (tablerows) {
      if (Array.isArray(childFieldNameList)) {
        const updatedListA = childFieldNameList.map((itemA) => {
          const matchingItemB = selectedDropDownListCopy.find(
            (itemB) => itemB.field_name === itemA.field_name
          );
          if (matchingItemB) {
            return { ...itemA, isDisabled: true };
          }
          return itemA;
        });
        setChildFieldNameList(updatedListA);
      } else {
        console.error("childFieldNameList is not an array");
      }
    }
  };

  const handleTemplateName = (event, selected) => {
    let name = event.target.name;
    let placementId = event.target.selectedOptions[0]?.id;
    setSelectTemplatedName(selected?.name);
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
    if (!tablerows?.length) {
      GetFieldNamesDetails(placementId);
    }
  };

  const GetFieldNamesDetails = (templateId: number) => {
    (async () => {
      const getTemplate = await useFetch<IGlobalTemplate[]>(`ClientPlacement/GetTemplateById?placement_template_id=${templateId}`);
      // Add the Ignore Field in the first 
      let updatedFieldNames = [getIgnoreTemplateFields(), ...getTemplate.data];
      setFieldNameList(updatedFieldNames);
      // copy dropdown list values to the local variable. this is for selected dropdown list values
      let newDropDownList = _.cloneDeep(childFieldNameList);
      // Set the default values for dropdown list 
      updatedFieldNames?.forEach((result) => {
        let newChildFieldNameList = {
          field_name: result?.field_name,
          display_name: result?.display_name,
          data_type: result?.data_type,
          field_length: result?.field_length,
          is_nullable: result?.is_nullable,
          isDisabled: false
        }
        // Push the each new rows to the local variable
        newDropDownList.push(newChildFieldNameList);
      });
      // set the newly added values to the Child Dropdown list 
      setChildFieldNameList(newDropDownList);
    })()
  };

  const handleRemoveRow = (rowId: number) => {
    const deletedRow = tablerows.find((row) => row?.rowId === rowId);
    if (deletedRow && deletedRow.display_name !== "--Ignore Field--") {
      setFieldNameList((prevOptions) => {
        const ignoreFieldOption = prevOptions.filter((option) => option.display_name !== deletedRow.display_name);
        if (deletedRow.display_name !== "--Ignore Field--" && deletedRow?.field_name?.length) {
          let newList: IGlobalTemplate = {
            field_name: deletedRow?.field_name,
            display_name: deletedRow?.display_name,
            data_type: deletedRow?.data_type,
            field_length: 0,
            is_nullable: deletedRow?.isDateVisible,
          };
          const updatedOptions = [...prevOptions, newList].sort((a, b) => {
            if (a.display_name && b.display_name) {
              return a.display_name.localeCompare(b.display_name);
            } else if (a.display_name) {
              return -1; // Place items with defined display_name before undefined ones
            } else if (b.display_name) {
              return 1; // Place items with defined display_name after undefined ones
            } else {
              return 0; // Keep the order unchanged for items with undefined display_name
            }
          });
          setFieldNameList(updatedOptions);
        } else {
          return ignoreFieldOption;
        }
      });
    }
    let updatedRow = tablerows.filter((row) => rowId !== row?.rowId);
    setTableRows(updatedRow);
  };

  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      tablerows,
      result.source.index,
      result.destination.index
    );
    let rowIds = "";
    for (var i = 0; i < items.length; i++) {
      rowIds += items[i].rowId.toString();
      rowIds += ",";
    }
    setTableRows(items);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const dynamicControl = () => (
    <React.Fragment>
      <div className={classes.flexDispaly}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography className={classes.activityName}>
              <b>Choose Field Names</b>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={fieldNameList}
              getOptionLabel={(option) => option.display_name}
              style={{ width: "100%" }}
              onChange={(event, selected) => handleDropdownChange(event, selected)}
              value={selectedDropdownValue}
              renderInput={(params) => (
                <TextField {...params} placeholder="Field Names" variant="outlined" style={{ padding: "2px" }} />
              )}
            />
          </Grid>
          <Grid item xs={2} className={classes.GridButtonStyle}>
            <Button variant="contained" color="primary" size="small"
              style={{ borderRadius: "20px" }} startIcon={<AddCircleIcon />}
              onClick={() => { handleAddButtonClick(selectedDropdownValue); }} >
              ADD
            </Button>
          </Grid >
        </Grid >
        <DragDropContext onDragEnd={(result) => { handleDrag(result) }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps}
                ref={provided.innerRef}>
                <TableContainer component={Paper} className={`${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
                  <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                  </Backdrop>
                  <Table aria-label="customized table" size="small" stickyHeader>
                    <EnhancedTableHead classes={classes} />
                    <TableBody style={{ border: '1px solid gray' }}>
                      {tablerows.map((row, index) => (
                        <Draggable key={row.rowId} draggableId={row.rowId.toString()} index={index}>
                          {(provided, snapshot) => (
                            <TableRow key={row?.rowId} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.tableRowHover} >
                              <TableCell align="left"> {row?.display_name} </TableCell>
                              <TableCell align="left"> {index} </TableCell>
                              <TableCell align="left">
                                {row.isDateVisible ? (
                                  <FormControl size="small" variant="outlined" >
                                    <Select native name={`${row?.field_name}`} value={row?.date_format}
                                      inputProps={{ id: "outlined-age-native-simple" }}
                                      onChange={(e) => handleChangeDateFormat(e, row?.rowId,)} >
                                      {(myString = constant?.DateFormat.join(","))}
                                      {(dropDownValue = myString.split(","))}
                                      {(optionItems = dropDownValue?.map((result) => {
                                        return (<option value={result}>{result}</option>);
                                      }))
                                      }
                                    </Select>
                                  </FormControl>
                                ) : null}
                              </TableCell>
                              <TableCell align="left">
                                <IconButton style={{ padding: "3px" }} onClick={() => handleRemoveRow(row.rowId)}>
                                  <HighlightOffIcon style={{ color: "red" }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>)}
          </Droppable>
        </DragDropContext>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Button variant="contained" size="small" onClick={handleClickOpen} className={classes.btnManTemp1}>
        Edit Template
      </Button>

      <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.customizeDialogePaper }}
        onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitleHeader id="alert-dialog-title" onClose={handleClose}>
          <Typography variant="h6">
            <b>EDIT MAPPING TEMPLATE</b>
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={8} sm={6}>
              <Typography className={classes.activityName}>
                <b>Global Template Name</b>
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6}>
              <Typography className={classes.activityName}>
                <b>Folder Name</b>
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                id="outlined-basic"
                label="Template Name"
                size="small"
                name="map_name"
                onChange={(e) => { handleTemplateNameChange(e); }}
                value={localState?.map_name}
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8} sm={6}>
              <FormControl size="small" variant="outlined" className={classes.dropdownStyle}>
                <Select native name="file_spec" variant="outlined" value={localState?.file_spec}
                  onChange={onContainerSelect} inputProps={{ name: "file_spec", id: "outlined-age-native-simple" }} >
                  {props.CloudContainer?.map((r) => {
                    return (
                      <option value={r?.folder_Name}>{r?.folder_Name}</option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className={classes.activityName}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '20%' }}>
                  <b>Choose Template</b>
                </div>
                <div style={{ width: '80%' }}>
                  <Typography style={{ color: 'red' }}>**Note** Template can not be changed until all the mapping is removed</Typography>
                </div>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl size="small" variant="outlined" className={classes.dropdownStyle} disabled={!!tablerows?.length}>
              <Select native style={{ width: "100%" }} value={localState?.map_TemplateName}
                onChange={(event, selected) => handleTemplateName(event, selected)}
                name="map_TemplateName" variant="outlined"
                inputProps={{ name: "map_TemplateName" }} >
                {TemplateList?.map((r) => {
                  return <option value={r?.name} id={r?.placement_template_id.toString()}>{r?.name}</option>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: "10px 0px 0px" }}>
              <Card className={classes.gridflex1}>
                <div className={classes.customizeHeader1}>
                  <Typography variant="subtitle1">
                    <b>CUSTOMIZE CONTROL ACTIVITY</b>
                  </Typography>
                </div>
                <div>{dynamicControl()}</div>
              </Card >
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={(e) => { handleEdit(e); }} className={classes.yesButton}
            disabled={!localState?.map_name?.length || !localState?.file_spec?.length || !localState?.map_TemplateName?.length || !tablerows?.length}
            size="small" startIcon={<DoneAllIcon />} >
            Save Template
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose} size="small" autoFocus style={{ borderRadius: "20px", backgroundColor: "red" }} startIcon={<CancelIcon />} >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog PaperProps={{ style: { borderRadius: 10, backgroundColor: "white" } }} open={deleteConfirm}
        maxWidth={"xs"} onClose={handleSubControlClose} TransitionComponent={Transition} aria-labelledby="responsive-dialog-title" >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleSubControlClose} >
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DELETE TEMPLATE
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <Typography variant="h6" className={classes.titleConfirm} gutterBottom >
            Are you sure, you want to delete this control?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button id="CPO_OK_btn" size="small" onClick={() => { setdeleteConfirm(false); }} color="primary" className={classes.yesButton} autoFocus>
            Yes
          </Button>
          <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} autoFocus onClick={() => { setdeleteConfirm(false); }} variant="contained" >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditMappingTemplate;
