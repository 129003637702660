import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Paper, Radio, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PuffLoader } from 'react-spinners';
// Icons
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
// Componants
import { useDelete, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { useStyles } from './AgentActivityCSS';
//Models
import React, { useEffect, useState } from 'react';
import * as constant from "../../../constants/Constant";
import { ICustomizeControls, ICustomizeControlReports } from '../../../models/AgentTemplate/IAgentCustomizeControl';
import _ from 'lodash';

const headCells = [
    { id: "label_Name", numeric: false, disablePadding: false, label: "LABEL NAME", sortable: true, fontSize: '12px' },
    { id: "control_Name", numeric: false, disablePadding: false, label: "CONTROL VIEW", sortable: true, fontSize: '12px' },
    { id: "control_Values", numeric: false, disablePadding: false, label: "CONTROL VALUES", sortable: true, fontSize: '12px' },
    { id: "regex_Format", numeric: false, disablePadding: false, label: "REGEX FORMAT", sortable: true, fontSize: '12px' },
    { id: "is_Required", numeric: false, disablePadding: false, label: "IS REQUIRED", sortable: true, fontSize: '12px' },
    { id: "max_Legth", numeric: false, disablePadding: false, label: "MAX LENGTH", sortable: true, fontSize: '12px' },
    { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS", sortable: true, fontSize: '12px' },
];

function EnhancedTableHead(props) {
    const {
        classes,
    } = props;
    return (
        <TableHead>
            <TableRow >
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        className={classes.rowhead}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const CustomizeControl: React.FC<{ activityId: number, actionType: number }> = (props) => {
    const classes = useStyles();
    const { activityId, actionType } = props;
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [controlRegex, setControlRegex] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [deleteConfirm, setdeleteConfirm] = useState(false);
    const [controlValues, setControlValues] = useState(false);
    const [selectdropdown, setSelectDropdown] = useState(false);
    const [controlMaxLegth, setControlMaxLegth] = useState(false);

    const [errors, setErrors] = useState<string>("");
    const [controlName, setControlName] = useState<string>("");
    const [Sourceerrors, setSourceErrors] = useState<string>("");
    const [selectedDate, handleDateChange] = useState(new Date());
    const [controlIdValue, setControlIdValue] = useState<number>(0);
    const [MaxLengthError, setMaxLengthError] = useState<string>("");
    const [localState, setLocalState] = useState<ICustomizeControls>();
    const [selectdropdownList, setSelectDropdownList] = useState<string>("");
    const [DynamicCustomizeControl, setDynamicCustomizeControl] = useState<ICustomizeControls[]>([]);
    const [preControlReports, setpreControlReports] = useState<ICustomizeControlReports[]>([]);
    const [controlReports, setControlReports] = useState([]);
    const [isEnable, setIsEnable] = useState<string>("");

    let dropDownValue;

    const handleSelectDropdownValue = (e) => {
        setSelectDropdown(true);
        setSelectDropdownList(e.target.value);
        setLocalState({
            ...localState,
            "controlValues": e.target.value
        });
        console.log(localState?.controlValues);
    }

    const handleClearSelectedValues = (event) => {
        setSelectDropdownList("");
        setLocalState({
            ...localState,
            "controlValues": isEnable
        });
        setSelectDropdown(false);
    }

    const handleChange = (e) => {
        let name = e.target.name;
        if (name === constant.maxLength && e.target.value === "") {
            setLocalState({
                ...localState,
                [name]: "0"
            });
        } else {
            setLocalState({
                ...localState,
                [name]: e.target.value
            });
        }
    }
    const handleChecked = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    const handleClose = () => {
        setOpen(false);
        setControlValues(false);
        setControlMaxLegth(false);
        setFullScreen(false);
        setMaxLengthError(null);
        setSourceErrors(null);
        setErrors(null);
        setSelectDropdown(false);
    };

    const handleSubControlClose = () => {
        setdeleteConfirm(false);
        setOpen(false);
        setControlMaxLegth(false);
        setControlRegex(false);
        setControlValues(false);
        setSelectDropdown(false);
    }

    const reset = () => {
        setdeleteConfirm(false);
        setOpen(false);
        setControlMaxLegth(false);
        setControlRegex(false);
        setControlValues(false);
        setControlMaxLegth(false);
        setFullScreen(false);
        setMaxLengthError(null);
        setSourceErrors(null);
        setErrors(null);
    }

    const HandleLabelVal = (e) => {
        const newValue = e.target.value;
        let name = e.target.name;
        if (!newValue.match(constant.customizeLabelRegex)) {
            setErrors("");
            setIsValid(false);
            setLocalState({
                ...localState,
                [name]: newValue
            });
        } else {
            setErrors("please enter characters and numbers only");
            setIsValid(true);
        }
    };

    const HandleMaxLength = (e) => {
        const newValue = e.target.value;
        let name = e.target.name;
        if (!newValue.match(constant.customizeMaxLengthRegex)) {
            setMaxLengthError("");
            setIsValid(false);
            if (name === constant.maxLength && e.target.value === "") {
                setLocalState({
                    ...localState,
                    [name]: "0"
                });
            } else {
                setLocalState({
                    ...localState,
                    [name]: e.target.value
                });
            }
        } else {
            setMaxLengthError("Please Enter Numbers only Without Space");
            setIsValid(true);
        }
    };

    const HandleSourceValue = (e) => {
        const newValue = e.target.value;
        let name = e.target.name;
        if (!newValue.match(constant.customizeControlValueRegex)) {
            setSourceErrors("");
            setIsValid(false);
            setLocalState({
                ...localState,
                [name]: newValue
            });
        }
        else {
            setSourceErrors("Please enter characters and pipe symbol without Space");
            setIsValid(true);
        }
    };

    const handleEditOpen = (controlId: number, controlName: string, actionType: number) => {
        setControlName(controlName);
        if (controlName === constant.textBox) {
            setControlMaxLegth(true);
            setControlRegex(true);
            getDynamicControlList(controlId);
        } else if (controlName === constant.dropDownList || controlName === constant.checkBox || controlName === constant.radioButton) {
            setControlValues(true);
            getDynamicControlList(controlId);
        } else if (controlName === constant.textArea) {
            setControlMaxLegth(true);
            getDynamicControlList(controlId);
        } else if (controlName === constant.datePicker) {
            setControlValues(false);
            getDynamicControlList(controlId);
        }
        else {
            getDynamicControlList(controlId);
        }
    }

    const handleOpen = () => {
        setOpen(true);
        setLocalState(null);
    };

    const handleSave = () => {
        setOpen(false);
        createAgentActivity();
    };

    useEffect(() => {
        getActivityList();
    }, [])

    const getActivityList = () => {
        (async () => {
            let request = {
                "activityId": activityId,
                "controlId": 0,
                "actionType": actionType
            }
            await usePost<ICustomizeControls[]>("AgentUser/GetCustomizeControlById", request).then((r) => {
                setDynamicCustomizeControl(r?.data);
                getPreClientCodeList(r?.data?.length, r?.data);
            })
        })().finally(() => {
        })
    }

    const getPreClientCodeList = (lenghtValue: number, controlValues: ICustomizeControls[]) => {
        for (var i = 0; i < lenghtValue; i++) {
            dropDownList.findIndex((x) => {
                if (x.title == controlValues[i].labelName) {
                    let request = {
                        "controlType": x.id,
                    }
                    usePost<{ dropdownControlResult: ICustomizeControlReports[] }>("GetPerformanceReportMasterData", request).then((r) => {
                        setpreControlReports(r?.data['dropdownControlResult']);
                        let currentReport = _.cloneDeep(controlReports);
                        r?.data?.dropdownControlResult.forEach((data) => {
                            let controlReport = {
                                listId: data?.listId,
                                listName: data?.listName,
                                controlType: x?.title
                            }
                            currentReport.push(controlReport);
                        })
                        setControlReports([...new Set(controlReports?.concat(currentReport?.map((r) => r)))]);
                    })
                }
            })
        }
    }

    const createAgentActivity = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityId": activityId,
                "labelName": localState?.labelName,
                "controlName": controlName.length ? controlName : localState?.controlName,
                "controlValues": localState?.controlValues,
                "regex": localState?.regex,
                "maxLength": localState?.maxLengthValue,
                "isRequired": localState?.isRequired,
                "controlId": localState?.controlId,
                "isActive": true,
                "actionType": actionType
            }
            await usePost("AgentUser/CreateCustomizeControl", request);
        })().finally(() => {
            getActivityList();
            setShowProgressBar(false);
            reset();
        })
    }

    const getDynamicControlList = (controlId: number) => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityId": 0,
                "controlId": controlId,
                "actionType": actionType
            }
            await usePost<ICustomizeControls>("AgentUser/GetCustomizeControlById", request).then((r) => {
                let newControlValues: ICustomizeControls = {
                    controlId: r?.data[0]?.controlId,
                    activityId: r?.data[0]?.activityId,
                    labelName: r?.data[0]?.labelName,
                    controlName: r?.data[0]?.controlName,
                    controlValues: r?.data[0]?.controlValues,
                    regex: r?.data[0]?.regex,
                    isRequired: r?.data[0]?.isRequired,
                    maxLengthValue: r?.data[0]?.maxLengthValue,
                    actionType: r?.data[0]?.actionType
                }
                setIsEnable(r?.data[0]?.controlValues);
                setLocalState(newControlValues);
                setSelectDropdownList(r?.data[0]?.controlValues);
            })
        })().finally(() => {
            setOpen(true);
            setShowProgressBar(false);
        })
    }

    const deleteCustomizeControl = (controlId: number) => {
        setShowProgressBar(true);
        (async () => {
            await useDelete<ICustomizeControls>("AgentUser/DeleteCustomizeControlById?controlId=" + controlId);
        })().finally(() => {
            getActivityList();
            setShowProgressBar(false);
        })
    }

    const dropDownList = [
        { id: '', title: '' },
        { id: 1, title: 'Client Code' },
        { id: 2, title: 'State' }
    ]

    const dynamicControl = () => (
        <React.Fragment>
            <div className={classes.flexDispaly}>
                <Box width="15%" className={classes.sidebar}>
                    <div>
                        <Typography variant="subtitle1" className={classes.controlHead}>
                            <b>CONTROL TYPE</b>
                        </Typography>
                        <div className={classes.flexControl} >
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlName(constant.textBox); setControlMaxLegth(true); setControlRegex(true); }}>
                                Text Box
                            </Button>
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlValues(true); setControlName(constant.dropDownList); }}>
                                Drop Down
                            </Button>
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlValues(true); setControlName(constant.checkBox); }}>
                                Check box
                            </Button>
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlValues(true); setControlName(constant.radioButton); }}>
                                Radio Button
                            </Button>
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlName(constant.datePicker); }}>
                                Date Picker
                            </Button>
                            <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.customizeButton} variant="contained" autoFocus color="primary" onClick={() => { handleOpen(); setControlName(constant.textArea); setControlMaxLegth(true); }}>
                                Text Area
                            </Button>
                        </div>
                    </div>
                </Box>
                <Box width="85%" className={`${classes.mainmenu}`}>
                    <TableContainer component={Paper} className={`${activityId === 0 ? classes.fullLayout1 : fullScreen ? classes.fullLayout : classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table aria-label="customized table" size="small" stickyHeader>
                            <EnhancedTableHead classes={classes} />
                            <TableBody>
                                {DynamicCustomizeControl.map((r) => {
                                    return (
                                        <TableRow>
                                            <TableCell width="13%" className={classes.controlPadding}>{r?.labelName}</TableCell>
                                            <TableCell width="35%" className={classes.controlPadding}>
                                                {r?.controlName == constant?.textBox ?
                                                    <TextField id="CNT_Name"
                                                        variant="outlined"
                                                        autoComplete='off'
                                                        fullWidth margin="dense"
                                                        // inputProps={{ maxLength: 250 }}
                                                        label={r?.labelName}
                                                        size="small" name="activityDescription"
                                                    />
                                                    : r?.controlName == constant?.dropDownList ?
                                                        <FormControl size="small" variant="outlined" className={classes.activityDropDownStyle}>
                                                            <InputLabel htmlFor="outlined-age-native-simple">{r?.labelName}</InputLabel>
                                                            <Select native
                                                                label={`${r?.labelName}`}
                                                                autoComplete='false'
                                                                // placeholder="---Select Report Types---"
                                                                inputProps={{
                                                                    name: 'role',
                                                                    id: 'outlined-age-native-simple',
                                                                }}>
                                                                {r?.controlValues == constant.clientCode || r?.controlValues == constant.state ?
                                                                    controlReports?.map((x) => {
                                                                        if (x?.controlType === r?.controlValues) {
                                                                            return (
                                                                                <option value={x?.listId}>{x?.listId}</option>
                                                                            )
                                                                        }
                                                                    })
                                                                    :
                                                                    <>
                                                                        {dropDownValue = (r?.controlValues)?.split("|")}
                                                                        {dropDownValue?.map((x) => {
                                                                            return (
                                                                                <option value={x}>{x}</option>
                                                                            )
                                                                        })}
                                                                    </>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        : r?.controlName == constant?.checkBox ?
                                                            (r?.controlValues)?.split("|")?.map((result) => {
                                                                return (
                                                                    <div className={classes.formControl}>
                                                                        <Checkbox name={r?.labelName} disabled style={{ padding: '2px 0px' }} />
                                                                        <span className={classes.controlName} >{result}</span>
                                                                    </div>
                                                                )
                                                            })
                                                            : r?.controlName == constant?.radioButton ?
                                                                (r?.controlValues)?.split("|")?.map((result) => {
                                                                    return (
                                                                        <div className={classes.formControl}>
                                                                            <Radio name={r?.labelName} disabled style={{ padding: '2px 0px' }} />
                                                                            <span className={classes.controlName} >{result}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                                : r?.controlName == constant?.datePicker ?
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            autoOk size='small'
                                                                            variant="inline"
                                                                            autoComplete='off'
                                                                            inputVariant="outlined"
                                                                            label={r?.labelName}
                                                                            format="MM/dd/yyyy"
                                                                            value={selectedDate}
                                                                            className={classes.activityDropDownStyle}
                                                                            InputAdornmentProps={{ position: "end" }}
                                                                            onChange={date => handleDateChange(date)}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    : r?.controlName == constant?.textArea ?
                                                                        <TextField id="CNT_Name"
                                                                            variant="outlined"
                                                                            autoComplete='off'
                                                                            multiline rows={4}
                                                                            fullWidth margin="dense"
                                                                            // inputProps={{ maxLength: 250 }}
                                                                            label={r?.labelName}
                                                                            className={classes.activityDropDownStyle}
                                                                            size="small" name="activityDescription"
                                                                        />
                                                                        : null}
                                            </TableCell>
                                            <TableCell className={classes.controlPadding}>
                                                <Typography variant="body1" className={classes.controlStyle}>
                                                    {r?.controlValues}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.controlPadding}>
                                                <Typography variant="body1" className={classes.regexStyle}>
                                                    {r?.regex}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.controlPadding}>
                                                {r?.isRequired ?
                                                    <Checkbox
                                                        defaultChecked
                                                        disabled
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell className={classes.controlPadding}>
                                                {r?.maxLengthValue}
                                            </TableCell>
                                            <TableCell className={classes.controlPadding}>
                                                <div className={classes.actionStyle}>
                                                    <IconButton style={{ padding: '3px' }} onClick={() => { handleEditOpen(r?.controlId, r?.controlName, r?.actionType) }}>
                                                        <EditIcon style={{ color: 'green' }} />
                                                    </IconButton>
                                                    <IconButton style={{ padding: '3px' }} onClick={() => { setdeleteConfirm(true); setControlIdValue(r?.controlId) }}>
                                                        <HighlightOffIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </React.Fragment >
    );

    return (
        <div style={{ padding: '10px 0px 0px' }}>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Card className={classes.gridflex}>
                <div className={classes.customizeHeader}>
                    <Box display="flex">
                        <Box width="97%">
                            <Typography variant="subtitle1" >
                                <b>CUSTOMIZE CONTROL ACTIVITY</b>
                            </Typography>
                        </Box>
                        <Box width="3%">
                            <LightTooltip title="Click here to view fullscreen">
                                <IconButton style={{ padding: '3px' }} onClick={() => { setFullScreen(true); }}>
                                    <CloseFullscreenIcon style={{ color: 'white' }} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </Box>
                </div>

                <div>
                    {dynamicControl()}
                </div>

                <Dialog open={fullScreen} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogePaperFullScreen }} TransitionComponent={Transition}
                    onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            CUSTOMIZE CONTROL ACTIVITY
                        </Typography>
                    </DialogTitleHeader>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <div>
                        {dynamicControl()}
                    </div>
                </Dialog>
            </Card >

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogePaper }} TransitionComponent={Transition}
                onClose={handleSubControlClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleSubControlClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        {controlName === constant.textBox ?
                            <b>TEXTBOX CONTROL ACTIVITY</b>
                            : controlName === constant.dropDownList ?
                                <b>DROPDOWN CONTROL ACTIVITY</b>
                                : controlName === constant.checkBox ?
                                    <b>CHECKBOX CONTROL ACTIVITY</b>
                                    : controlName === constant.radioButton ?
                                        <b>RADIOBUTTON CONTROL ACTIVITY</b>
                                        : controlName === constant.datePicker ?
                                            <b>DATEPICKER CONTROL ACTIVITY</b>
                                            : controlName === constant.textArea ?
                                                <b>TEXTAREA CONTROL ACTIVITY</b>
                                                : null
                        }
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} >
                            <Typography variant="body2" className={classes.activityName}>
                                <b>Label Name</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <TextField id="CNT_Name"
                                variant="outlined"
                                fullWidth margin="dense"
                                autoComplete='off'
                                // inputProps={{ maxLength: 250 }}
                                label="" helperText={errors}
                                error={!!errors}
                                value={localState?.labelName}
                                size="small" name="labelName"
                                style={{ width: "100%" }}
                                onChange={(e) => { handleChange(e); HandleLabelVal(e); }}
                                InputProps={{
                                    classes: {
                                        root: classes.outlinedInput
                                    }
                                }} />
                        </Grid>

                        {controlValues ?
                            <>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="body2" className={classes.activityName}>
                                        <b>Control Values ( Note : Seprate the values with pipe " | " Symbol )</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <TextField id="CNT_Name"
                                        variant="outlined"
                                        autoComplete='off'
                                        fullWidth margin="dense"
                                        // inputProps={{ maxLength: 250 }}
                                        value={localState?.controlValues}
                                        label="" helperText={Sourceerrors}
                                        error={!!Sourceerrors}
                                        disabled={dropDownList.some(result => result?.title === localState?.controlValues)}
                                        size="small" name="controlValues"
                                        style={{ width: "100%" }}
                                        onChange={(e) => { handleChange(e); HandleSourceValue(e); }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="body2" style={{ textAlign: 'center' }} >
                                        <b>OR</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <FormControl size="small" variant="outlined" className={classes.activityDropDownStyle}>
                                        <InputLabel htmlFor="filled-age-native-simple">--Select Control Values--</InputLabel>
                                        <Select native
                                            value={selectdropdownList}
                                            label="--Select Control Values--"
                                            onChange={(e) => { handleSelectDropdownValue(e); }}
                                            name="controlValues" variant="outlined"
                                            style={{ width: "100%" }}
                                            endAdornment={<IconButton onClick={(event) => { handleClearSelectedValues(event); }} style={{ visibility: selectdropdown ? "visible" : "hidden" }} className={classes.IconPadding}><ClearIcon /></IconButton>}
                                            inputProps={{
                                                name: 'controlValues',
                                                id: 'outlined-age-native-simple',
                                            }}>
                                            {dropDownList.map((r) => {
                                                return (
                                                    <>
                                                        <option value={r?.title} >{r?.title}</option>
                                                    </>
                                                )
                                            })
                                            }
                                        </Select>
                                    </FormControl>

                                </Grid>
                            </>
                            : null
                        }

                        {controlMaxLegth ?
                            <>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="body2" className={classes.activityName} >
                                        <b>Maximum Length</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <TextField id="CNT_Name"
                                        variant="outlined"
                                        autoComplete='off'
                                        fullWidth margin="dense"
                                        inputProps={{ maxLength: 5 }}
                                        label="" helperText={MaxLengthError}
                                        error={!!MaxLengthError}
                                        value={localState?.maxLengthValue}
                                        size="small" name="maxLengthValue"
                                        style={{ width: "100%" }}
                                        onChange={(e) => { handleChange(e); HandleMaxLength(e) }}
                                    />
                                </Grid>
                            </>
                            : null
                        }

                        {controlRegex ?
                            <>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="body2" className={classes.activityName} >
                                        <b>Regex Pattern</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <TextField id="CNT_Name"
                                        name="regex"
                                        variant="outlined"
                                        fullWidth margin="dense"
                                        autoComplete='off'
                                        // inputProps={{ maxLength: 250 }}
                                        label="" size="small"
                                        value={localState?.regex}
                                        style={{ width: "100%" }}
                                        onChange={(e) => { handleChange(e); }}
                                    />
                                </Grid>
                            </>
                            :
                            null
                        }

                        <Grid item xs={6} sm={6} >
                            <FormControlLabel
                                control={<Checkbox onChange={handleChecked} checked={localState?.isRequired} name="isRequired" style={{ marginTop: '5px' }} />}
                                label="Required"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.createtemplate} onClick={() => { handleSave(); setOpen(false); }} disabled={!localState?.labelName || isValid} startIcon={<SaveIcon />} variant="contained" autoFocus color="primary">
                        {localState?.controlId ?
                            <span>Update</span>
                            :
                            <span>Save</span>
                        }
                    </Button>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.cancelbtn} startIcon={<CancelIcon />}
                        onClick={() => { handleSubControlClose(); }} variant="contained" autoFocus color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >

            <Dialog
                PaperProps={{ style: { borderRadius: 10, backgroundColor: 'white' } }}
                open={deleteConfirm}
                maxWidth={'xs'}
                onClose={handleSubControlClose}
                TransitionComponent={Transition}
                aria-labelledby="responsive-dialog-title" >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleSubControlClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DELETE TEMPLATE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                        Are you sure, you want to delete this control?
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="CPO_OK_btn" size="small" onClick={() => { setdeleteConfirm(false); deleteCustomizeControl(controlIdValue) }} color="primary" className={classes.yesButton} autoFocus>
                        Yes
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} autoFocus
                        onClick={() => { setdeleteConfirm(false); }} variant="contained">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default CustomizeControl
