export const msalConfig = {

  auth: {
    authority: 'https://login.microsoftonline.com/533a72b0-15d7-4efd-9921-2c7d9041fb4a',
    clientId: 'd97aab01-7739-466b-8973-c79c85335e9b', // DEV
    // clientId: '5a745384-bf13-4b5a-b5a1-644193dedec7', // UAT
    redirectUri: window.document.location.origin,
    postLogoutRedirectUri: window.document.location.origin,
    scopes: ['api://677338f9-a166-4aac-823a-3121e96e2fc7/access_as_user'], // DEV
    // scopes: ['api://676c3f61-a5a3-4311-adac-7d6707811fac/access_as_user'], // UAT
  },
  system: {
    iframeHashTimeout: 10000,
  },
}

export const loginRequest = {
  scopes: msalConfig.auth.scopes,
}