import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography, Checkbox, FormControl, FormControlLabel, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { useStyles } from './ProspectUserCss';
import orgMail from '../../images/Authendication/org-mail.svg';
import orgPhone from '../../images/Authendication/org-phone.svg';
import orgName from '../../images/Authendication/org-name.svg';
import { PuffLoader } from 'react-spinners';

const ViewOrganizationDetails: React.FC<{ userRow: IProspectUsersDetails }> = (props) => {
    const classes = useStyles();
    const { userRow } = props;
    const [open, setOpen] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [organizationDetails, setOrganizationDetails] = useState<IProspectUsersDetails[]>([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    return (
        <div>
            <Button variant='contained' size="small" onClick={() => { handleOpen() }}
                className={classes.notApprovedBtn1} startIcon={<PersonIcon />}>
                Organization Details
            </Button>

            <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 10 } }} open={open} onClose={handleClose}
                aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'xs'} >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        VIEW ORGANIZATION DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '25px 30px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgName} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Organization Name</b></Typography>
                                {userRow?.organizationName?.length ?
                                    < Typography variant='body2'>
                                        {userRow?.organizationName}
                                    </Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgMail} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <div style={{ display: 'flex' }}>
                                    <Typography variant='subtitle1'><b>Organization Admin Email</b></Typography>
                                </div>

                                {userRow?.organizationAdminEmailId?.length ?
                                    <Typography variant='body2'>
                                        {userRow?.organizationAdminEmailId}
                                    </Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgPhone} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Organization Phone Number</b></Typography>
                                {userRow?.organizationPhoneNumber?.length ?
                                    <Typography variant='body2'>{userRow?.organizationPhoneNumber}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={userPhone} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>User Phone Number</b></Typography>
                                {userRow?.prospectPhoneNumber?.length ?
                                    <Typography variant='body2'>{userRow?.prospectPhoneNumber}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ViewOrganizationDetails