
import {
    Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, Grid,
    IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography
} from '@material-ui/core';
import { Select } from '@mui/material';
import React, { useState } from 'react'
import { PuffLoader } from 'react-spinners';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { useStyles } from '../ManageReports/ManageReportsCss';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { ICustomizeControls, IProcedureName } from '../../../models/AgentTemplate/IAgentCustomizeControl';
import { IPerformanceReportsResults, IReportControlDetailsResult, IReportsValuesContract } from '../../../models/PerformanceReport/IPerformanceReportsResults';

const EditReport: React.FC<{ row: IPerformanceReportsResults }> = (props) => {
    const { row } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [localState, setLocalState] = useState(row);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [procName, setProcName] = useState<IProcedureName[]>([]);
    const [reportsDynamicList, setReportsDynamicList] = useState([]);
    const [reportControlsDetail, setReportControlsDetail] = useState<any>([]);
    const [reportsDetailList, setReportsDetailList] = useState<IReportsValuesContract[]>([]);
    const [reportMasterControlList, setReportMasterControlList] = useState<ICustomizeControls[]>([]);

    const handleSubmit = () => {

    }

    const handleClose1 = () => {
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        getPerformanceReport();
        getMetaDataList();
        getControlList();
    }

    const handleClickDelete = (event) => {
        let newValue = event.currentTarget.id;
        let newControlValues = reportsDynamicList.filter((c) => {
            return !reportControlsDetail.find((r) => {
                return c.controlId == newValue
            })
        });
        setReportsDynamicList(newControlValues);
    }

    const getPerformanceReport = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "performanceReportId": row?.performanceReportId
            }
            await usePost<{ reportControlResults: IReportControlDetailsResult[], reportsValuesContract: IReportsValuesContract[] }>("GetPerformanceReportDetails", request).then((ReportResult) => {
                setReportControlsDetail(ReportResult?.data?.reportControlResults);
                setReportsDetailList(ReportResult?.data?.reportsValuesContract);
                setReportsDynamicList(ReportResult?.data?.reportControlResults);
            })
        })().finally(() => {
            setOpen(true);
            setShowProgressBar(false);
        })
    }

    const getMetaDataList = () => {
        (async () => {
            await useFetch<IProcedureName[]>("GetPerformanceMetaDataFilter").then((result) => {
                setProcName(result?.data);
            });
        })()
    }

    const getControlList = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityId": 0,
                "controlId": 0,
                "actionType": 2
            }
            await usePost<ICustomizeControls[]>("AgentUser/GetCustomizeControlById", request).then((r) => {
                setReportMasterControlList(r.data);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const handleClick = (event) => {
        let name = event.target.name;
        procName?.forEach((a) => {
            if (event.target.value === a?.procedureDisplayName) {
                setLocalState({
                    ...localState,
                    [name]: event.target.value
                });
            }
        })
    }

    const handleReportNameChange = (event) => {
        setLocalState({
            ...localState,
            [event.target.name]: event.target.value
        });
    }

    const handleRemoveControl = (event) => {
        let newValue = event.currentTarget.id;
        var NewControlList = reportsDynamicList.filter((c) => {
            return !reportControlsDetail.find((r) => {
                return c.controlId == newValue
            })
        });
        setReportsDynamicList(NewControlList);
    }

    const handleControlClick = (event) => {
        let NewControlUpdate = [];
        let ControlUpdate = {
            controlId: event.currentTarget.id,
            controlName: event.currentTarget.name,
            label: event.currentTarget.value,
        }
        NewControlUpdate.push(ControlUpdate);  // through the array stored the id,name and label from list

        //Filter the duplicate values from the report control list
        var NewFilterControlList = NewControlUpdate.filter((c) => {
            return !reportsDynamicList.find((r) => {
                return c.controlId == r.controlId
            })
        });
        setReportsDynamicList([...new Set(reportsDynamicList?.concat(NewFilterControlList?.map((r) => r)))]);
    }

    const attributeName = [
        { id: 1, label: 'Start Date' },
        { id: 2, label: 'End Date' },
        { id: 3, label: 'Client Code' },
        { id: 4, label: 'Client Id' },
        { id: 5, label: 'Aging Buckets' },
        { id: 5, label: 'State' },
        { id: 5, label: 'Report Name5 Name5' },
    ];

    return (
        <div>
            <Button variant="contained" startIcon={<BorderColorIcon />} size="small" className={classes.btnManTemp1} onClick={handleOpen}>
                Edit
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogePaper1 }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        <b>EDIT REPORT</b>
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <DialogContentText>
                        <div>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.reportHeader}>
                                        <b>Report Name</b>
                                    </Typography>
                                    <TextField id="outlined-basic"
                                        label="Enter report name"
                                        variant="outlined"
                                        size="small"
                                        name="performanceReportName"
                                        onChange={handleReportNameChange}
                                        value={localState?.performanceReportName}
                                        className={classes.controlSpace1} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.reportHeader}>
                                        <b>Procedure Name</b>
                                    </Typography>
                                    <FormControl size="small" variant="outlined" className={classes.activityDropDownStyle}>
                                        <Select native size="small"
                                            value={localState?.procedureDisplayName}
                                            onChange={(e) => { handleClick(e); }}
                                            name="procedureName"
                                            variant="outlined"
                                            inputProps={{
                                                name: 'procedureName',
                                                id: 'Procedure',
                                            }}>
                                            {procName.map((r) => {
                                                return (
                                                    <option value={r?.procedureDisplayName}>{r?.procedureDisplayName}</option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography className={classes.reportHeader}>
                                        <b>Controls</b>
                                    </Typography>
                                    <TableContainer component={Paper} className={`${classes.gridflex1} ${"scrollbox"} ${"on-scrollbar"}`}>
                                        <Table>
                                            <TableBody>
                                                {reportMasterControlList?.map((r) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell width="50%" className={classes.controlPadding} style={{ padding: '6.6px' }}>
                                                                <Button name={`${r?.controlName}`}
                                                                    id={`${r?.controlId}`}
                                                                    value={`${r?.labelName}`}
                                                                    size="small" variant="contained" autoFocus
                                                                    color="primary" style={{ width: '225px' }}
                                                                    onClick={handleControlClick}>
                                                                    {r?.labelName}
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                    <Typography className={classes.reportHeader}>
                                        <b>Reasons</b>
                                    </Typography>
                                    <TableContainer component={Paper} className={`${classes.gridflex} ${"scrollbox"} ${"on-scrollbar"}`}>
                                        <Table>
                                            <TableBody>
                                                {reportsDynamicList?.map((r) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell width="50%" className={classes.controlPadding}>
                                                                <TextField
                                                                    id={r?.controlId}
                                                                    name={r?.controlName}
                                                                    label={r?.label}
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    className={classes.controlDisplay}
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                            <TableCell width="1%" className={classes.controlPadding}>
                                                                <IconButton size="small" color="primary"
                                                                    id={`${r?.controlId}`}
                                                                    onClick={handleRemoveControl} >
                                                                    <CancelIcon style={{ color: 'red' }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} >
                                    <Typography className={classes.reportHeader}>
                                        <b>Attribute Name</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box className={`${classes.sidebar} ${classes.controlSpace3}`} >
                                        {attributeName.map((r) => {
                                            return (
                                                <FormControlLabel
                                                    control={<Checkbox name="checkedA" />}
                                                    label={r.label}
                                                    style={{ width: "230px" }}
                                                />
                                            )
                                        })}

                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button size="small" id="DU_ok_btn" variant="contained" startIcon={<DoneAllIcon />} className={classes.yesButton} style={{ marginRight: '10px' }} onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button size="small" id="DU_Cancel_btn" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose1} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )

}

export default EditReport
