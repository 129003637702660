import {
    AppBar, Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel,
    Grid, IconButton, Radio, RadioGroup, TableContainer, Toolbar, Typography, Snackbar, useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { ARMLogo } from '../../../constants/Constant';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import norecord from '../../../images/WarningImage/nofiles.svg';
import pdfIcon from '../../../images/WarningImage/pdficon.svg';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Models
import { IFileGet } from "../../../models/Roll/IFileGet";
import { useGlobalState } from '../../../store/GlobalStore';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { IProspectUsersDetails } from '../../../models/accesscontrol/ProspectUsersDetails';
// Components
import PuffLoader from "react-spinners/PuffLoader";
import { DropzoneArea } from 'material-ui-dropzone';
import "@react-pdf-viewer/core/lib/styles/index.css";
import useStyles from '../../GlobalStyles/DocumentCss';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { INewBusinessBranchDetails } from '../../../models/NBClientInformation/INBBranchClientInformation';

const DocumentUpload: React.FC<{ userRow: IProspectUsersDetails, onUpdate: () => void }> = (props) => {
    const { userRow, onUpdate } = props
    const theme = useTheme();
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const base = ("data:application/pdf;base64,");
    const [openDocs, setOpenDocs] = useState(false);
    const [serviceURL, setServiceURL] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [fileExists, setFileExists] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [storeFileID, setStoreFileID] = useState<string>("");
    const [undoUpload, setUndoUpload] = useState<boolean>(false);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [saveDialog, setSaveDialog] = useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = useState<string>("");
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [FileDisplay, setFileDisplay] = useState<IFileGet[]>([]);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [localState, setLocalState] = useState(userRow?.isMultiBranch);
    const [updatePermission, setUpdatePermission] = useState<boolean>(false);
    const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
    const [branchDeleteConfirmation, setBranchDeleteConfirmation] = useState<boolean>(false);

    const [isValidDocument, setIsValidDocument] = useState<boolean>(false);

    const handleAlertClose = () => {
        setAlertOpen(false);
        setBranchDeleteConfirmation(false);
        setConfirmDialog(false);
        setLocalState(userRow?.isMultiBranch);
        setBtnDisable(false);
        setIsValidDocument(false);
    };

    const handleClickOpen = () => {
        setLocalState(props?.userRow?.isMultiBranch);
        getFileDisplayAsync();
        setOpen(true);
    };

    const handleCloseDocs = () => {
        setOpenDocs(false);
    };

    const handleClose = () => {
        if (userRow?.isMultiBranch === null && localState !== null) {
            setSaveDialog(true);
        } else {
            setOpen(false);
            setLocalState(userRow?.isMultiBranch);
        }
        setBtnDisable(false);
    };

    const handleConfirmationDialogClose = () => {
        setSaveDialog(false);
        setOpen(false);
        setLocalState(userRow?.isMultiBranch);
        setBtnDisable(false);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setBtnDisable(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFileExists(false);
        setUndoUpload(false);
        setUpdatePermission(false);
    };

    const onSubmit = (selectedFile: File) => {
        if (selectedFile) {
            setshowProgressBar(true);
            if (selectedFile?.type === 'application/pdf' && selectedFile?.size > 0) {
                (async () => {
                    const formData = new FormData();
                    formData.append("multiFiles", selectedFile);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    formData.append("prospectUserName", userRow?.prospectEmailAddress);
                    formData.append("propspectUserId", userRow?.prospectUserId.toString());
                    await usePost<any>("File/ContractFileInsert", formData).finally(() => {
                        setshowProgressBar(false);
                        onUpdate();
                    });
                    getFileDisplayAsync();
                })().finally(() => {
                })
            }
            else if (selectedFile?.size <= 0) {
                setAlertOpen(true);
                setIsValidDocument(true);
                setshowProgressBar(false);
            } else {
                setIsValidDocument(false);
                setshowProgressBar(false);
                setAlertOpen(true);
            }
        }
    }

    async function getFileDisplayAsync() {
        setshowProgressBar(true);
        await useFetch<IFileGet[]>("File/GetContractFileDetails?propspectUsesrId=" + userRow?.prospectUserId).then((GetFileDetails) => {
            setFileDisplay(GetFileDetails.data);
        }).finally(() => {
            setshowProgressBar(false);
        });
    }

    function getPDFFileAsync(blobUri: string) {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "blobUri": blobUri
            }
            await usePost<any>("File/GetBlobFileContent", request).then((r) => {
                let url = URL.createObjectURL(base64toBlob(base + r?.data));
                setServiceURL(url);
                setOpenDocs((r?.status === 404 || r?.status === 400) ? false : true);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();

        const base64toBlob = (data: string) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length);
            const bytes = window.atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
            return new Blob([out], { type: 'application/pdf' });
        };
    }

    async function FileDeleteAsync(file_Id: string) {
        setDialogOpen(false);
        setshowProgressBar(true);
        await useDelete<any>(`File/ContractFileDelete?fileId=${file_Id}&propspectUsesrId=${userRow?.prospectUserId}`).finally(() => {
            getFileDisplayAsync();
            setshowProgressBar(false);
            onUpdate();
            setUndoUpload(true);
        });
    }

    async function DownloadFileAsync(blobUri: string, fileName: string) {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "blobUri": blobUri
            }
            await usePost<any>("File/GetBlobFileContent", request).then((r) => {
                const arrayBuffer = base64ToArrayBuffer(r.data);
                createAndDownloadBlobFile(arrayBuffer, fileName);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleFileId = (fileID: string) => {
        setStoreFileID(fileID);
        setDialogOpen(true);
    };

    const handleEnabledChange = (event) => {
        if (event?.target?.name === "multiLocation") {
            setLocalState(true);
        } else if (event?.target?.name === "singleLocation") {
            setLocalState(false);
        } else if (event?.target?.name === "none") {
            setLocalState(null);
        }
        setBtnDisable(true);
    };

    const handleDeleteBranchDetails = () => {
        (async () => {
            useDelete("Client/DeleteBranchDetailsBasedOnUserName?userId=" + userRow?.prospectUserId).then(() => {
            }).finally(() => {
                setBranchDeleteConfirmation(false);
                handleSave();
            });
        })().finally(() => {
        })
    }

    const handleInsertSubmit = () => {
        if (localState === null) {
            setDialogMessage("Do you want to deactivate the location details for this organization (" + userRow?.organizationName + ")?");
            setConfirmDialog(true);
        } else if (userRow?.isMultiBranch === null && localState === null) {
            setConfirmationDialog(true);
        } else if (userRow?.isMultiBranch === localState) {
            handleSave();
        } else if (userRow?.isMultiBranch !== null && localState === true) {
            setDialogMessage("Do you want to change Single location to Multi location ?");
            setConfirmDialog(true);
        } else if (userRow?.isMultiBranch === null && (localState === true || localState === false)) {
            handleSave();
        } else if (localState === false) {
            (async () => {
                let request = {
                    "userId": userRow?.prospectUserId,
                    "emailAddress": userRow?.prospectEmailAddress,
                    "branchId": null
                }
                await usePost<{ branchInformation: INewBusinessBranchDetails[] }>("Client/GetBranchDetailsBasedUser", request).then((result) => {
                    if (result?.data?.branchInformation?.length) {
                        setBranchDeleteConfirmation(true);
                    } else if (userRow?.isMultiBranch === true) {
                        setDialogMessage("Do you want to change Multi location to Single location ?");
                        setConfirmDialog(true);
                    } else if (userRow?.isMultiBranch === localState) {
                        handleSave();
                    } else {
                        setDialogMessage("Do you want to change Single location to Multi location ?");
                        setConfirmDialog(true);
                    }
                })
            })().finally(() => {
            })
        }
    };

    const handleSave = () => {
        setshowProgressBar(true);
        setOpen(false);
        setConfirmDialog(false);
        let request = {
            "prospectUserId": userRow?.prospectUserId,
            "isMultiBranch": localState,
            "userId": state?.userAccessContext?.id
        }
        usePost<any>("User/InsertMultiBranchDetails", request).then((r) => {
            if (r?.status === 200 || r?.status === 202) {
                // call back function to update the details
                onUpdate();
                // Send the email when multi / single location confirmed 
                ConfirmMultiLocationEmail();
                setUpdatePermission(true);
            }
        }).finally(() => {
            onUpdate();
            setBtnDisable(false);
        })
    }

    const ConfirmMultiLocationEmail = () => {
        //Email will trigger when multi / single location confirmed 
        let sendEmail = {
            "userName": userRow?.prospectEmailAddress
        }
        usePost<any>("ConfirmMultiLocationEmail", sendEmail);
    }

    return (
        <React.Fragment>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        {isValidDocument ?
                            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                                The document upload failed due to an empty or corrupt file.
                            </Typography>
                            :
                            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                                Invalid File Type. Please select a PDF file.
                            </Typography>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button size="small" id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); }} className={classes.yesButton}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {userRow?.isNewOrginization ?
                <Button id="I_DocumentUpload" variant="contained" color="primary" size="small" disabled={userRow?.isPermissionUpdated || !userRow?.isActive}
                    className={userRow?.isDocumentUploaded ? classes.documentApproved : classes.documentNotApproved}
                    startIcon={userRow?.isDocumentUploaded ? <DoneAllIcon /> : <FileUploadIcon />}
                    onClick={(_) => { handleClickOpen(); }} >
                    {userRow?.isDocumentUploaded ?
                        <span>CONTRACT UPLOADED</span>
                        :
                        <span>UPLOAD CONTRACT</span>
                    }
                </Button>
                :
                null
            }

            {openDocs ?
                <Dialog fullScreen className={classes.dialog} open={openDocs} onClose={handleCloseDocs} TransitionComponent={Transition}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolbarStyle}>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                                <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                            </IconButton>
                            <Typography variant="h6" className={classes.title} >
                                <b>DOCUMENT VIEWER</b>
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={() => setOpenDocs(false)} aria-label="close" style={{ padding: '5px' }}>
                                <CloseIcon style={{ color: '#000', fontSize: 22 }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                        <Viewer fileUrl={serviceURL} plugins={[defaultLayoutPluginInstance]} />
                    </Worker>
                </Dialog> : null
            }

            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                maxWidth={'md'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' align='justify' className={classes.Note}>
                                <span><b>Note : </b></span>The uploaded document will be saved automatically.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DropzoneArea
                                onChange={(files) => onSubmit(files[0])}
                                acceptedFiles={['application/pdf']}
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                dropzoneText="Drag and Drop an PDF file here or Browse"
                                dropzoneClass={classes.DropzoneArea}
                                dropzoneParagraphClass={classes.DropzoneAreaText}
                            />
                        </Grid>
                        <Grid item xs={FileDisplay.length ? 8 : 12}>
                            <TableContainer className={`${classes.container} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Typography variant="h6" className={classes.UploadStyle} ><b>UPLOADED DOCUMENTS</b></Typography>
                                {FileDisplay.map((f) => {
                                    let enable = f.file_Name.split('.').pop() === 'pdf' ? true : f.file_Name.split('.').pop() === 'PDF' ? true : false
                                    return (
                                        <React.Fragment>
                                            <Grid container spacing={0} >
                                                <Grid item xs={1} className={classes.flexDisplay}>
                                                    <img src={pdfIcon} width="20px" height="20px" style={{ marginTop: '5px' }} />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant="subtitle2" className={classes.typoStyle}>
                                                        {f.file_Name} - {moment(f.upload_Date).format(state.GlobalUtils?.settingValue)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} className={classes.flexDisplay}>
                                                    <IconButton disabled={!enable} aria-label="delete" onClick={handleClickOpen} className={classes.Iconbutton}>
                                                        <LightTooltip title="Preview File">
                                                            <VisibilityIcon onClick={() => { getPDFFileAsync(f.blobUri); }}
                                                                className={!enable ? classes.NonVisible : classes.Visible} />
                                                        </LightTooltip>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={1} className={classes.flexDisplay}>
                                                    <IconButton aria-label="delete" className={classes.Iconbutton}>
                                                        <LightTooltip title="Download File">
                                                            <CloudDownloadIcon onClick={() => { DownloadFileAsync(f.blobUri, f.file_Name); }} className={classes.iconStyle} />
                                                        </LightTooltip>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={1} className={classes.flexDisplay}>
                                                    <IconButton color="primary" aria-label="upload picture" onClick={() => { handleFileId(f?.file_Id); }}
                                                        className={classes.Iconbutton} >
                                                        <LightTooltip title="Delete File">
                                                            <CloseIcon style={{ fontSize: 20, color: 'red' }} />
                                                        </LightTooltip>
                                                    </IconButton>
                                                </Grid>

                                                <Dialog
                                                    PaperProps={{ style: { borderRadius: 15 } }}
                                                    open={isDialogOpen}
                                                    onClose={handleCloseDialog}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
                                                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                                                            File Delete
                                                        </Typography>
                                                    </DialogTitleHeader>
                                                    <DialogContent >
                                                        <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                                                            Are you sure, you want to Delete this File?
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button id="NRB_ok_btn" size="small" variant="contained" color="primary" className={classes.yesbutton} onClick={() => { FileDeleteAsync(storeFileID); }}>
                                                            Yes
                                                        </Button>
                                                        <Button id="NRB_cancel_btn" size="small" onClick={handleCloseDialog} className={classes.nobutton} color="primary">
                                                            No
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })}

                                {!FileDisplay.length ?
                                    <div>
                                        <div className={classes.noRecordsColor}>
                                            <img src={norecord} alt="Icon for no document to display" width="80px" height="70px" />
                                        </div>
                                        <Typography variant='h6' className={classes.noFiles}>
                                            No Documents to display..
                                        </Typography>
                                    </div>
                                    : null}
                            </TableContainer>
                        </Grid>

                        {FileDisplay.length ?
                            <Grid item xs={4}>
                                <TableContainer className={`${classes.container} `}>
                                    <Typography variant="h6" className={classes.UploadStyle} ><b>MULTI LOCATION DETAILS</b></Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup onChange={handleEnabledChange}>
                                            <FormControlLabel
                                                name="multiLocation"
                                                checked={localState === true}
                                                control={<Radio />}
                                                label="Multi Location"
                                            />
                                            <FormControlLabel
                                                name="singleLocation"
                                                checked={localState === false}
                                                control={<Radio />}
                                                label="Single Location"
                                            />
                                            <FormControlLabel
                                                name="none"
                                                checked={localState === null}
                                                control={<Radio />}
                                                label="None"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </TableContainer>
                            </Grid>
                            : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {FileDisplay.length ?
                        <Button id="I_D_Close_btn" variant="outlined" size="small" startIcon={<DoneAllIcon />} className={classes.yesbutton} disabled={!btnDisable}
                            onClick={() => { handleInsertSubmit(); }}>
                            Submit
                        </Button>
                        : null}
                    <Button id="I_D_Close_btn" variant="outlined" size="small" onClick={handleClose} startIcon={<CancelIcon />} className={classes.cancel} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
            <Snackbar className="snackbarStyle" open={undoUpload} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    File Deleted Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackbarStyle" open={updatePermission} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Permission are updated Successfully!
                </Alert>
            </Snackbar>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={branchDeleteConfirmation} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle1} gutterBottom>
                            Multi location is already activated for this organization ({userRow?.organizationName}), would you like to change into Single Location ?
                            <br />This will delete the existing branch details are you sure to proceed?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleDeleteBranchDetails(); }} className={classes.yesbutton}>
                        Yes
                    </Button>
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setBranchDeleteConfirmation(false); setLocalState(userRow?.isMultiBranch); setBtnDisable(false); }} className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={saveDialog} onClose={() => { handleConfirmationDialogClose(); }} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={() => { handleConfirmationDialogClose(); }}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle1} gutterBottom>
                            Single or multi Location is selected would you like to save or cancel?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleSave(); setSaveDialog(false); }} className={classes.yesbutton}>
                        Save
                    </Button>
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleConfirmationDialogClose(); }} className={classes.noButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={confirmationDialog} onClose={(e) => { setConfirmationDialog(false); setBtnDisable(false); }} maxWidth={'xs'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={() => { setConfirmationDialog(false); setBtnDisable(false); }}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle1} gutterBottom>
                            Single or multi Location selection is pending!
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setConfirmationDialog(false); setBtnDisable(false); }} className={classes.noButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={confirmDialog} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle1} gutterBottom>
                            {dialogMessage}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleSave(); }} className={classes.yesbutton}>
                        Yes
                    </Button>
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleAlertClose(); }} className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )

}

export default DocumentUpload