import * as React from 'react'
import { IReportsList } from '../../models/Reports/ReportsList';
import { makeStyles,  Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteReport from './DeleteReport';
import ReportShare from './ReportShare';
import moment from 'moment';
import { useGlobalState } from '../../store/GlobalStore';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportField } from '../../models/Reports/IReportField';
import EditReport from "./EditReport";
import UnSubscribeReport from "./UnSubscribeReport";
import { StyledTableCell } from '../GlobalStyles/TableStyledCell';
import { StyledTableRow } from '../GlobalStyles/TableStyledCell';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'black',
        fontSize: 13
    }
}))

const ReportsList: React.FC<{
    row: IReportsList, selected: boolean, onClick: (id: string) => void, onCreate: () => void,
    onDelete: () => void, clients?: any, states?: any, statuses?: any, phases?: any, isValid?: boolean,
    operators?: IReportOperator[], fieldLists?: IReportField[]
}> = (props) => {
    const classes = useStyles()
    const { row, clients, states, statuses, phases, isValid, operators, fieldLists } = props;
    const { state } = useGlobalState();
    let path = `/reportsBuilder/${row?.id?.toString()}/${row?.name?.toString()}`;
    const createdDate = moment(row.date_created).format(state.GlobalUtils?.settingValue);
    const [childValue, setChildValue] = useState('');

    const handleChildValue = (value) => {
        // Update parent's state with the received value from the child
        setChildValue(value);
      };
    

    return (
        <React.Fragment>
            <StyledTableRow >
                <StyledTableCell onClick={(_) => props.onClick(row.id)} style={{ width: 550, }}>
                    <Typography className={classes.textStyle} component={Link} to={path}>{row.name}</Typography>
                </StyledTableCell>

                <StyledTableCell onClick={(_) => props.onClick(row.id)}  >
                    <Typography className={classes.textStyle} component={Link} to={path}>{createdDate}</Typography>
                </StyledTableCell>

                <StyledTableCell >
                    <EditReport getReports={props.onCreate} reportId={row.id} reportRow={row}
                        clients={clients} states={states} statuses={statuses} phases={phases} isValid={isValid}
                        operators={operators} fieldLists={fieldLists} />
                </StyledTableCell>

                <StyledTableCell >
                    <DeleteReport reportRow={row} onDelete={props.onDelete} />
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    )
}

export default ReportsList