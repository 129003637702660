import * as React from 'react';
import { IRollbackNotificationList } from '../../../models/NotificationRollBack/IRollbackNotificationList';
import { Button, createStyles, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import { useState } from 'react';
import { usePost } from '../../../utils/apiHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootHidden: {
            display: "none"
        },
        button: {
            borderRadius: "20px",
            marginLeft: '-15px',
            fontSize: 12,
            backgroundColor: '#007FFF'
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        messageStyle: {
            color: "black",
        }
    })
);

const NotificationTable: React.FC<{ row: IRollbackNotificationList, onRollBack: () => void }> = (props) => {
    const { row } = props;
    const classes = useRowStyles();
    const { dispatch } = useGlobalState();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [hide, sethide] = useState(row.permenant_Hidden);
    const [open, setOpen] = useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function openConfirmDialog() {
        setDialogOpen(true);
    }

    const RollBack = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "userNotificationId": row.userNotificationId,
                };
                await usePost<IRollbackNotificationList[]>('RollbackNotification', request)
                props.onRollBack();
                sethide(true);
                setOpen(true);
            }
            catch (ex) {
            }
            finally {
                setDialogOpen(false);
            }
        })()
    }

    return (
        <React.Fragment>
            <Button id="NRB_Rollback_btn" startIcon={<RotateRightIcon />} size="small" color="primary" variant="contained" onClick={openConfirmDialog}
                disabled={row?.permenant_Hidden} className={classes.button} >
                Rollback
            </Button>
            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                open={isDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        ROLLBACK NOTIFICATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="h6" className={classes.messageStyle} gutterBottom>
                            Are you sure, you want to rollback this notification?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" id="NRB_ok_btn" variant="contained" color="primary" className={classes.yesbutton} onClick={() => { RollBack(); props.onRollBack(); }}>
                        Yes
                    </Button>
                    <Button size="small" id="NRB_cancel_btn" onClick={handleCloseDialog} className={classes.nobutton} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Message RollBacked Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default NotificationTable