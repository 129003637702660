import {
    Backdrop, Box, Button, Chip, Grid, IconButton, InputAdornment, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../../components/Footer/Footer';
import managetemplate from '../../../images/template.svg';
import { useStyles } from './AG_TemplateCss';
import Template from "./Template";
// models
import * as constant from '../../../constants/Constant';
import { IActivityContract } from '../../../models/AgentTemplate/IActivityContract';
import { IAgentTemplate } from "../../../models/AgentTemplate/IAgentTemplate";
import { ITemplatesList } from "../../../models/AgentTemplate/ITemplatesList";
import { useFetch, usePost } from "../../../utils/apiHelper";
import LightTooltip from '../../GlobalStyles/LightTooltip';
import StyledTableRow from "../../GlobalStyles/StyledTableRow";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: "clientName", disablePadding: false, label: "CLIENT NAME", sortable: true },
        { id: "clientCode", disablePadding: false, label: "CLIENT ID", sortable: true },
        { id: "firstParty", disablePadding: false, label: "1ST PARTY", sortable: true },
        { id: "flatFee", disablePadding: false, label: "FLAT FEE", sortable: true },
        { id: "contingency", disablePadding: false, label: "CONTINGENCY", sortable: true },
        { id: "", disablePadding: false, label: "ACTIONS", sortable: false },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id} id="AT_TableHeader"
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.TableCellStyle}
                    >
                        <TableSortLabel
                            id="AT_TableSortlable"
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const AgentTemplate = () => {

    const classes = useStyles();
    const location = useLocation<any>();
    const [selectedName, setSelectedName] = useState(null);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    const [ProgressBar, setShowProgressBar] = useState(true);
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("clientName");
    const [searchText, setSearchText] = useState<string>("");
    const [overRideValue, setOverRideValue] = useState<string>("");

    const [templateList, setTemplateList] = useState<ITemplatesList[]>([]);
    const [agentTemplate, setAgentTemplate] = useState<IAgentTemplate[]>([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handlePageChange = (event: unknown, page: number) => {
        setPageNumber(page);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };

    const getAgentActivityTemplate = () => {
        let defaultClientTemplate: ITemplatesList = {
            activityTemplateId: 0,
            activityTemplateName: 'Select Template',
        };
        return defaultClientTemplate;
    }

    useEffect(() => {
        getAgentClientCodeListAsync();
    }, [rowsPerPage, pageNumber]);

    useEffect(() => {
        (async () => {
            await useFetch<ITemplatesList[]>("AgentUser/GetAgentTemplates").then((listAllTemplate) => {
                listAllTemplate?.data.unshift(getAgentActivityTemplate());
                setTemplateList(listAllTemplate?.data);
            });
        })()
    }, []);

    async function getAgentClientCodeListAsync() {
        setShowProgressBar(true);
        let request = {
            "searchText": searchText.trim(),
            "rowsPerPage": rowsPerPage,
            "recordNumber": (pageNumber * rowsPerPage) + 1,
            "agentTemplateName": !searchText.trim() && location.search && !selectedName ? location.search.substring(1) : !searchText.trim() ? selectedName : null
        }
        await usePost<{ clientTemplates: IAgentTemplate[], totalCount: number }>('AgentUser/GetClientActivityTemplate', request).then((r) => {
            setAgentTemplate(r?.data['clientTemplates']);
            setTotalRecords(r?.data?.totalCount);
            setOverRideValue(location.search);
        }).finally(() => {
            setShowProgressBar(false);
            handleClearValues();
        });
    }

    async function ClearAgentClientCodeListAsync() {
        setShowProgressBar(true);
        let request = {
            "searchText": "",
            "rowsPerPage": rowsPerPage,
            "recordNumber": (pageNumber * rowsPerPage) + 1,
            "agentTemplateName": null
        }
        await usePost<{ clientTemplates: IAgentTemplate[], totalCount: number }>('AgentUser/GetClientActivityTemplate', request).then((r) => {
            setAgentTemplate(r?.data['clientTemplates']);
            setTotalRecords(r?.data?.totalCount);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    const handleClearValues = () => {
        location.search = '';
    }

    const handleOverRiseAndSaveTempalte = () => {
        setShowProgressBar(true);
        (async () => {
            let activityRequest = [];
            location?.state?.currentActivity?.map((x) => {
                if (x?.isEnable) {
                    let ActivityDetail: IActivityContract = { activityFieldId: x?.activityMapId }
                    activityRequest.push(ActivityDetail);
                }
            });
            let request = {
                "templateId": location?.state?.itemID,
                "templateName": overRideValue.substring(1),
                "isOverRide": 1,
                "modifiedBy": location?.state?.modifiedBy,
                "modifiedDate": moment(new Date()),
                "activityMapingDetail": activityRequest
            }
            await usePost("AgentUser/ModifyAgentTemplate", request);
        })().finally(() => {
            setShowProgressBar(false);
            handleClearValues();
        });
    }

    function clearSearchValues() {
        setSearchText("");
        setPageNumber(0);
        ClearAgentClientCodeListAsync();
        setShowSearchIcon(true);
    }

    function handleSearchValues() {
        setSelectedName(null);
        setPageNumber(0);
        setShowSearchIcon(false);
        getAgentClientCodeListAsync();
    }

    const handleChange = (e, selectedID) => {
        selectedID.activityTemplateId === 0 ? setSelectedName(null) : setSelectedName(selectedID.activityTemplateName);
    };

    const handleFilter = () => {
        setPageNumber(0);
        getAgentClientCodeListAsync();
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Box component="span" m={0} width="20%">
                        <Typography variant="h6" gutterBottom className={`${classes.titleHead} ${"headertitle"}`}>
                            <b> MANAGE ACTIVITY TEMPLATE </b>
                        </Typography>
                    </Box>
                    <Box component="span" m={0} width={location.search ? "33%" : "38%"}>
                        <TextField variant="outlined"
                            className={classes.searchBox}
                            size="small" value={searchText}
                            type='text' placeholder="Search Client ID and Client Name"
                            onChange={e => setSearchText(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && searchText != "") {
                                    handleSearchValues();
                                } else if (event.key === 'Enter' && searchText === "") {
                                    clearSearchValues();
                                }
                            }}
                            inputProps={{ maxlength: 256 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton onClick={e => { handleSearchValues(); }}>
                                                <SearchIcon />
                                            </IconButton>
                                            :
                                            <IconButton onClick={e => { clearSearchValues(); }}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box component="span" m={0} width={location.search ? "17%" : "20%"} >
                        <Autocomplete size="small"
                            id="client-template"
                            onChange={handleChange}
                            className={classes.selecttemplate1}
                            options={templateList} disableClearable={true}
                            getOptionLabel={(option) => option.activityTemplateName}
                            classes={{ paper: classes.paper1, option: classes.autoCompleteFont }}
                            getOptionSelected={(option, value) => option.activityTemplateId === value.activityTemplateId}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span className={`${option.activityTemplateName == 'Global Template - First Party' || option.activityTemplateName == 'Global Template - Flat Fee' || option.activityTemplateName == 'Global Template - Contingency' ? classes.globalTemplatedropDown : null}`}>
                                        {option.activityTemplateName}

                                        {option.activityTemplateName == 'Global Template - First Party' || option.activityTemplateName == 'Global Template - Flat Fee' || option.activityTemplateName == 'Global Template - Contingency' ?
                                            <StarIcon className={classes.starIcon} style={{ fontSize: 17 }} />
                                            : null}
                                    </span>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Select Template"
                                    size="small" margin="normal" variant="outlined"
                                    inputProps={{
                                        ...params.inputProps, disableUnderline: true,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                        style: { fontSize: 13 },
                                        classes: {
                                            root: classes.outlinedInput1
                                        }
                                    }}
                                />
                            )} />
                    </Box>
                    <Box component="span" width={location.search ? "30%" : "22%"} className={classes.filterGrid}>
                        <Box>
                            {location.search ?
                                <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.overRideButtonStyle} startIcon={<DoneAllIcon />}
                                    variant="contained" autoFocus color="primary" onClick={() => { handleOverRiseAndSaveTempalte() }}>
                                    Override
                                </Button>
                                : null
                            }
                        </Box>
                        <Box>
                            <Button size="small" id="UpLog_Filter_Button" className={classes.btnManTempFilter} variant="contained" color="primary"
                                onClick={e => { handleFilter(); }}
                                startIcon={<FilterAltIcon />} >
                                Filter
                            </Button>
                        </Box>
                        <Box>
                            <Button id="MN_Temp_Button" size="small" startIcon={<img src={managetemplate} alt="Icon for Manage Template" width="18px" height="18px" />}
                                component={Link} to="/managetemplate" variant="contained" color="primary" className={classes.btnManTemp}>
                                Manage Templates
                            </Button>
                        </Box>
                        <Box>
                            <IconButton className={`${classes.colorcodeTemplate} ${"zoom-in-box"}`}>
                                <LightTooltip title={
                                    <React.Fragment>
                                        <List component="nav" className={classes.tooltipPadding}>
                                            <span className={classes.tooltipText1} >Color Code For Template</span>
                                            <ListItem button className={classes.tooltipPadding}>
                                                <Brightness1Icon className={classes.readonlyIcon} />
                                                <span className={classes.tooltipText} >Read Only</span>
                                            </ListItem>
                                            <ListItem button className={classes.tooltipPadding}>
                                                <Brightness1Icon className={classes.unsubscribeIcon} />
                                                <span className={classes.tooltipText} >Unsubscribed</span>
                                            </ListItem>
                                            <ListItem button className={classes.tooltipPadding}>
                                                <Brightness1Icon className={classes.globalTemplateIcon} />
                                                <span className={classes.tooltipText} >Global Template</span>
                                            </ListItem>
                                            <ListItem button className={classes.tooltipPadding}>
                                                <Brightness1Icon className={classes.NormalTemplateIcon} />
                                                <span className={classes.tooltipText} >Customize Template</span>
                                            </ListItem>
                                        </List>
                                    </React.Fragment>
                                }>
                                    <InfoIcon />
                                </LightTooltip>
                            </IconButton>
                        </Box>
                    </Box>
                    <Grid component={Paper} xs={12} sm={12} lg={12} style={{ marginTop: '5px' }}>
                        <TableContainer component={Paper} className={` ${classes.activityBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" size="small" stickyHeader >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(agentTemplate, getComparator(order, orderBy)).map(
                                        (row) => {
                                            return (
                                                <StyledTableRow hover >
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span >
                                                            {row?.clientName}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${searchText?.toUpperCase() === "" ? classes.TextNormal : row?.clientCode?.includes(searchText?.toUpperCase()) ?
                                                            classes.TextHighlight : classes.TextNormal} `}>
                                                            {row?.clientCode}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <Chip size="small" label={row?.firstParty}
                                                            className={`${location.search?.substring(1) === row?.firstParty ? classes.filterStyle : location.search ? classes.filterStyle1 : row?.firstParty === constant.unSubscribed ? classes.unsubscribeButton : row?.isFirstPartyReadOnly ? classes.readonlyTemplate : row?.firstParty === constant.defaultTempFirstParty ? classes.globalTemplatebutton : classes.NormalTemplatebutton}`} />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <Chip size="small" label={row?.flatFee}
                                                            className={`${location.search?.substring(1) === row?.flatFee ? classes.filterStyle : location.search ? classes.filterStyle1 : row?.flatFee === constant.unSubscribed ? classes.unsubscribeButton : row?.isFlatFeeReadOnly ? classes.readonlyTemplate : row?.flatFee === constant.defaultTempFlatFee ? classes.globalTemplatebutton : classes.NormalTemplatebutton}`} />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <Chip size="small" label={row?.contingency}
                                                            className={`${location.search?.substring(1) === row?.contingency ? classes.filterStyle : location.search ? classes.filterStyle1 : row?.contingency === constant.unSubscribed ? classes.unsubscribeButton : row?.isContingencyReadOnly ? classes.readonlyTemplate : row?.contingency === constant.defaultTempContingency ? classes.globalTemplatebutton : classes.NormalTemplatebutton}`} />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <Template onSave={getAgentClientCodeListAsync} templateList={templateList} agentTemplate={row} />
                                                    </TableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                </TableBody >
                            </Table >
                            {!ProgressBar && !agentTemplate.length && totalRecords <= 0 ?
                                <Typography gutterBottom className={classes.ErrorMessageStyle}>
                                    No records to display..
                                </Typography> : null}
                        </TableContainer >
                        <Grid container spacing={0} >
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        rowsPerPageOptions={[15, 25, 50]}
                                        component="div"
                                        count={totalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid >
                </Grid >
            </div >
        </React.Fragment >
    )
}

export default AgentTemplate
