import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import { Card, CardHeader, Box, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  card: {
    borderRadius: 25,
    height: '9cm',
    boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 70vh - 210px)',
      minHeight: 'calc( 70vh - 210px)',
    },
  },
});

class LineChart extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    let resize = this?.props?.resize;
    const avgAge = this.props?.chartData.map((avg) => { return (avg.avgAge) });
    const TargetAge = this.props?.chartData.map((tar) => { return (tar.targetAge) });
    const MonthAge = this.props?.chartData.map((m) => { return (moment(m.monthReferred).format('MMMYY')) });

    const CHART_DATA = [
      {
        name: 'Ideal Age',
        type: 'line',
        data: TargetAge
      },
      {
        name: 'Your Average Age',
        type: 'line',
        data: avgAge
      }
    ];

    const chartOptions = {
      stroke: { width: [3.5, 3.5], curve: 'smooth' },
      plotOptions: { bar: { columnWidth: '15%', borderRadius: 4 } },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ['#ff0066', '#000000']
        },
      },
      chart: {
        type: 'line',
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#D2691E',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
              curve: 'smooth'
            }
          }
        },
        toolbar: {
          show: true,
          offsetY: -30,
          offsetX: -2,
          export: {
            csv: {
              filename: 'Age-History',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: 'Age-History',
            },
            png: {
              filename: 'Age-History',
            }
          },
          autoSelected: 'zoom'
        },
      },
      fill: {
        type: ['gradient' / 'solid' / 'pattern' / 'image', 'solid'],
        gradient: {
          shade: 'dark',
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        }
      },
      labels: MonthAge,
      xaxis: {
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          }
        }
      }
    };
    return (
      <React.Fragment>
        <Card className={classes.card}>
          {/* <CardHeader title={<span style={{ fontSize: 20 }}>3rd Party Flat Fee 6 Month Account Age History</span>} style={{ height: '55px' }} /> */}
          <CardHeader title={<Typography variant='subtitle2' style={{ fontSize: '17px' }}><b>3rd Party Flat Fee 6 Month Account Age History</b></Typography>} style={{ height: '40px'}} />
          {this.props.loading ? (
            <Skeleton variant="rectangular" width="100%" animation="pulse" style={{ background: "#d5d5d7", display: 'block' }}>
              <div style={{ paddingTop: '40%' }} />
            </Skeleton>
          ) :
            <Box sx={{ p: 1, pb: 1 }} >
              <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={resize === 0 ? 240 : resize >= 960 ? 370 : resize < 715 ? 190 : 240} />
            </Box>
          }
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LineChart);