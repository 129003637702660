import {
    Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText,
    FormControlLabel, Grid, IconButton, Snackbar, Typography, useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as constant from '../../../constants/Constant';
import { IAgentActivityListCount, IAgentUpdatedEmail } from '../../../models/AgentInquiry/IAgentActivityListCount';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { IAgentTemplatePermission } from '../../../models/AgentInquiry/IAgentTemplatePermission';
import { IAccStatus } from '../../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from '../../../models/Inquiry/IPlacedAccountContract';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import { dateMasking } from '../../../utils/dateMasking';
import useStyles from '../../GlobalStyles/AccountDetailCss';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { InsertTransactionLog } from '../../TransactionLogs/InsertTransactionLog';
import CloseAccount from "./CloseAccount";
import CloseAndRemove from "./CloseAndRemove";
import DocumentUpload from './DocumentUpload';
import Activity from './ManageActivity';
import Submit from "./Submit";

const AccountDetails: React.FC<{ debtors: IAgentDebtor, disablee: IGetRolledPlacement, letterDetails: IClosedLetterDetails[], onEroll: () => void, isEnable: IGetRolledPlacement, onHideAll: () => void, BlockAwaitUsers: boolean, GetDebtorLetterDetails: ILetterDetails[], GetAccStatus: IAccStatus, OnGetDetails: () => void, templateAccess: IAgentTemplatePermission }> = (props) => {
    const { debtors, isEnable, BlockAwaitUsers, GetAccStatus, templateAccess } = props;
    const theme = useTheme();
    const classes = useStyles();
    const falseObject = {
        "DocUpload": false,
        "CloseRemove": false,
        "CloseAcc": false,
        "SubmitValue": false
    }
    const { state, dispatch } = useGlobalState();
    const UserName = state.AgentUserAccessContext?.agentUserId;
    const readOnlyAccess = state?.AgentUserAccessContext?.readOnlyAccess;
    const [object, setObject] = useState(falseObject);
    const canRollAccount = state.AgentUserAccessContext?.rollAccounts;
    const canUpdatePayment = state?.AgentUserAccessContext?.paymentUpdates;
    const [EarlyRollRolled, setEarlyRollRolled] = useState(isEnable?.rolled);
    const [LateFeeDue, setLateFeeDue] = useState<number>(debtors.dbT_MISC1_DUE);
    const [updatedLateAmount, setUpdatedLateAmount] = useState(debtors.dbT_MISC1_DUE);
    const referralDate = moment(debtors.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(debtors.dbT_PRINCIPAL_DUE);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(debtors.dbT_PRINCIPAL_DUE);
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(updatedPrincipalAmount + updatedLateAmount);
    const phoneNumber = debtors?.deM_PHONE1;
    const canMakePayment = state.AgentUserAccessContext?.paymentUpdates;
    const TotalBalance = principalPaymentDue + LateFeeDue;
    const handleDisable = isEnable?.rolled;
    const [principalAdjustmentAmount, setPrincipalAdjustmentAmount] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>('');
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [lateAdjustmentAmount, setLateAdjustmentAmount] = useState<any>('');
    const [latePaymentAmount, setLatePaymentAmount] = useState<any>('');
    const [inputVal, setInputVal] = useState<any>('');
    const [service, setService] = useState('');

    const [undoEarlyRoll, setUndoEarlyRoll] = React.useState(false);
    const [checkFullAmount, setCheckFullAmount] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [ConfirmDialog, setConfirmDialog] = useState(false);
    const [earlyRoll, setEarlyRoll] = React.useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [paymentVal, setPaymentVal] = useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [clicked, setClicked] = useState(false);
    const [disable, setDisable] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [earlyButtonLoading, setEarlyButtonLoading] = useState(false);
    const [activityCount, setActivityCount] = useState<IAgentActivityListCount[]>([]);
    const [updatedEmailName, setupdatedEmailName] = useState<IAgentUpdatedEmail[]>([]);
    const [documentNotAvailable, setDocumentNotAvailable] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.dbT_NO}`).then((PlacedAccounts) => {
                setPlacedAccounts(PlacedAccounts.data);
            });
        })()
    }, [])

    // API for getting the Activity Count
    useEffect(() => {
        getActivityCount();
    }, [])

    const getActivityCount = () => {
        (async () => {
            let request = { "dbtNo": debtors.dbT_NO };
            await usePost<{ agentActivityHistories: IAgentActivityListCount[], agentEmails: IAgentUpdatedEmail[] }>("AgentUser/GetInvoiceActivitiesCount", request).then((r) => {
                setActivityCount(r?.data['agentActivityHistories']);
                setupdatedEmailName(r?.data['agentEmails']);
            })
        })()
    }

    const handleGetStatus = () => {
        props.OnGetDetails();
    }

    const handleRemovedStatus = () => {
        props.onEroll();
    }

    const handleConfirmDialogCLose = () => {
        setConfirmDialog(false);
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setButtonLoading(false);
    }

    const handleCheckFullAmountDialog = () => {
        setPaymentVal(true);
        setCheckFullAmount(false);
        setButtonLoading(false);
    }

    function emptyCheck() {
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setEarlyRoll(false);
        setButtonLoading(false);
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleChange = (event) => {
        setBtnDisabled(event.target.checked);
        setDocumentNotAvailable(event.target.checked);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmit(false);
        setEarlyRoll(false);
    };

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setDisable(true);
        setPrincipalPaymentAmount(recursiveRemove(value, debtors.dbT_PRINCIPAL_DUE));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtors.dbT_PRINCIPAL_DUE ? value : principalAdjustmentAmount != 0 || lateAdjustmentAmount != 0 || latePaymentAmount != 0);
    };

    const handleFlatfeeValueChange = (value: string): void => {
        setDisable(true);
        setLatePaymentAmount(recursiveRemove(value, debtors.dbT_MISC1_DUE));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtors.dbT_MISC1_DUE ? value : lateAdjustmentAmount != 0 || principalAdjustmentAmount != 0 || principalPaymentAmount != 0);
    };

    const PricipalAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setPrincipalAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : principalPaymentAmount != 0 || latePaymentAmount != 0 || lateAdjustmentAmount != 0);
    };

    const LateAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setLateAdjustmentAmount(recursiveRemove(value, debtors.dbT_MISC1_DUE));
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : latePaymentAmount != 0 || principalPaymentAmount != 0 || principalAdjustmentAmount != 0);
    };

    const handleCheckboxChange = (event) => {
        setBtnDisabled(event.target.checked);
    }

    useEffect(() => {
        let principal_amount_due = debtors.dbT_PRINCIPAL_DUE;
        for (let account of placedAccounts) {
            if (account.prcpl_Adj_Amt && account.prpcl_Pay_Amt === 0) {
                principal_amount_due = ((principal_amount_due) + (account.prpcl_Pay_Amt + account.prcpl_Adj_Amt));
            }
            else if (account.prcpl_Adj_Amt > 0 && account.prpcl_Pay_Amt > 0) {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
            else {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
        }
        let late_fee_due = debtors.dbT_MISC1_DUE;
        for (let account of placedAccounts) {
            if (account.fee_Adj_Amt && account.fee_Pay_Amt === 0) {
                late_fee_due = ((late_fee_due) + (account.fee_Pay_Amt + account.fee_Adj_Amt));
            }
            else if (account.fee_Adj_Amt > 0 && account.fee_Pay_Amt > 0) {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
            else {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
        setLateFeeDue(Number(late_fee_due.toFixed(2)));
    }, [placedAccounts])

    useEffect(() => {
        //Principal Amount
        let Adjustment = Number(Number(principalAdjustmentAmount)?.toFixed(2));
        let principal = Number((principalPaymentDue - principalPaymentAmount)?.toFixed(2));

        //LateFee
        let LateAdjustment = Number(Number(lateAdjustmentAmount)?.toFixed(2));
        let LateFee = Number((LateFeeDue - latePaymentAmount)?.toFixed(2));

        setUpdatedPrincipalAmount(principal + Adjustment);
        setUpdatedLateAmount(LateFee - LateAdjustment);
    }, [LateFeeDue, principalPaymentDue, principalPaymentAmount, principalAdjustmentAmount, latePaymentAmount, lateAdjustmentAmount])

    useEffect(() => {
        /* Updated Total Amount */
        setUpdatedTotalAmount(Number((updatedPrincipalAmount + updatedLateAmount).toFixed(2)));
        (updatedPrincipalAmount < 0.00 || updatedLateAmount < 0.00) ? setIsValid(false) : setIsValid(true);

    }, [updatedPrincipalAmount, updatedLateAmount])

    useEffect(() => {
        if (debtors.dbT_COMPANY === constant.CONT) {
            setService('Contingency');
        }
        if (debtors.dbT_COMPANY === constant.PREC) {
            setService('Flat Fee');
        }
        else if (debtors.dbT_COMPANY === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    async function CheckPreviousPayment(dbt_NO: string) {
        dispatch({ type: GlobalStateAction.Busy });
        setButtonLoading(true);
        try {
            // Check if the full account balance is adjusted to zero without payment.
            if (updatedTotalAmount === 0.00 && ((principalPaymentAmount === "0.00" || principalPaymentAmount === "") && (latePaymentAmount === "" || latePaymentAmount === "0.00"))) {
                setCheckFullAmount(true);
            } else {
                setCheckFullAmount(false);
                // Check if payment matches previous pending payment.
                await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${dbt_NO}`).then((PlacedAccounts) => {
                    if (PlacedAccounts.data.length === 0 ? true : false) {
                        submitPayment();
                    } else {
                        setConfirmDialog(true);
                    }
                });
            }
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    const submitPayment = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (!btnDisabled) {
                    let request = {
                        "dbt_No": debtors.dbT_NO,
                        "prcpl_Pay_Amt": (principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount)),
                        "prcpl_Adjst_Amt": (principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount)),
                        "fee_Pay_Amt": (latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount)),
                        "fee_Adjst_Amt": (lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount))),
                        "user_Id": UserName
                    };
                    let feedOperation = [];
                    let operations: IInsertTransactionLog = {
                        operationName: "Payment", operationText: `Payment to Principal: $${principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount)},Payment to Late Fee: $${latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount)},Adjustment to Principal: $${principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount)},Adjustment to Late Fee: $${lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount))}`
                    };
                    feedOperation.push(operations);

                    await usePost<any>('Account/SubmitPayment', request);
                    setDisable(true);
                    setButtonLoading(false);
                    setSubmit(true);
                    GetPayment();
                    InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtors?.dbT_NO, feedOperation);
                }
                else if (!documentNotAvailable) {
                    let feedOperation = [];
                    let operations: IInsertTransactionLog = {
                        operationName: "Close Account", operationText: 'No Payment Received'
                    };
                    feedOperation.push(operations);

                    InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtors?.dbT_NO, feedOperation);
                    let request = {
                        "dbtNumber": debtors.dbT_NO,
                        "close_acct_note": null,
                        "close_acct_bal": null,
                        "close_acct_reason": "No Payment Received",
                        "user_Id": UserName,
                    };
                    await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                    await axios.post(`${constant.NoPaymentMade}/${debtors.dbT_NO}?`);
                    setBtnDisabled(false);
                }
                else {
                    let feedOperation = [];
                    let operations: IInsertTransactionLog = {
                        operationName: "Close Account", operationText: 'Documents not Available'
                    };
                    feedOperation.push(operations);

                    let request = {
                        "dbtNumber": debtors.dbT_NO,
                        "close_acct_note": null,
                        "close_acct_bal": null,
                        "close_acct_reason": "Documents not Available",
                        "user_Id": UserName,
                    };
                    await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                    InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtors.dbT_NO, feedOperation);
                    setBtnDisabled(false);
                    setButtonLoading(false);
                    setDocumentNotAvailable(false);
                }
                hidenotificationForAllUsers();
                props.onHideAll();
                setPaymentVal(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const GetPayment = () => {
        (async () => {
            try {
                dispatch({ type: GlobalStateAction.Busy });
                await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.dbT_NO}`).then((PlacedAccounts) => {
                    setPlacedAccounts(PlacedAccounts.data);
                }).finally(() => {
                    emptyCheck();
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const EarlyRoll = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "dbt_No": debtors.dbT_NO,
                    "dbt_Client": debtors.dbT_CLIENT,
                    "user_Id": UserName
                };
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Early Roll", operationText: "Early Roll"
                };
                feedOperation.push(operations);

                await usePost<IPlacedAccountContract[]>('RolledPlacement', request);
                setEarlyRollRolled(true);
                setEarlyRoll(false);
                props.onEroll();
                setshowUndoButton(!showUndoButton);
                InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtors?.dbT_NO, feedOperation);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
                setEarlyButtonLoading(false);
            }
        })()
    }

    const undoRoll = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                await useDelete(`RolledPlacement?DBTNumber=${debtors.dbT_NO}`).then((r) => {
                    props.onEroll();
                    setshowUndoButton(!showUndoButton);
                    if (r.status == 400) {
                        dispatch({ type: GlobalStateAction.Error, error: r.statusText });
                    }
                });
                setEarlyRollRolled(false);
                setUndoEarlyRoll(false);
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Undo Rolled Account", operationText: "Manually rolled debtor undone"
                };
                feedOperation.push(operations);

                InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtors?.dbT_NO, feedOperation);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const hideDocNotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.dbT_NO,
                    "typeId": 6,
                    "userRole": 1
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.dbT_NO,
                    "typeId": 5,
                    "userRole": 1
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root} alignItems='flex-start'>
                <Grid item className={classes.displayFlex} xs={9}>
                    <Grid item xs={'auto'} className={classes.gridFlex}>
                        <Typography variant="h6" component="h6" gutterBottom className={classes.typographyTitleStyle}><b>Current Amount Due </b></Typography>
                        <Grid item xs={12} className={classes.gridFlex} direction='column' >
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle} ><b>Principal</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${principalPaymentDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Late Fee</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${LateFeeDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Total</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={`${classes.currentamountStyle} ${classes.currentamountStyleColor}`}><b>${TotalBalance.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Payment Amount</b>
                        </Typography>
                        <CurrencyInput
                            className={classes.inputField}
                            id="Inquiry_PrincipalPaymentAmount"
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            placeholder="$ Principal Payment Amount"
                            value={principalPaymentAmount}
                            allowNegativeValue={false}
                            maxLength={13}
                            prefix='$'
                            decimalsLimit={2}
                            decimalScale={2}
                            onValueChange={handleOnValueChange}
                            onKeyPress={preventMinus}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading || templateAccess?.readOnly || readOnlyAccess}
                            autoComplete='off'
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            id="Inquiry_latePaymentAmount"
                            value={latePaymentAmount}
                            allowNegativeValue={false}
                            placeholder="$ Late Fee Payment Amount"
                            maxLength={13}
                            prefix='$'
                            decimalsLimit={2}
                            decimalScale={2}
                            onValueChange={handleFlatfeeValueChange}
                            onKeyPress={preventMinus}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading || templateAccess?.readOnly || readOnlyAccess}
                            autoComplete='off'
                        />
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Adjustment Amount</b>
                        </Typography>
                        <CurrencyInput
                            className={classes.inputField}
                            id="Inquiry_principalAdjustmentAmount"
                            value={principalAdjustmentAmount}
                            placeholder="$ Principal Adjustment Amount"
                            maxLength={13}
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={PricipalAdjValueChange}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading || templateAccess?.readOnly || readOnlyAccess}
                            autoComplete='off'
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            id="Inquiry_lateAdjustmentAmount"
                            value={lateAdjustmentAmount}
                            placeholder="$ Late Fee Adjustment Amount"
                            maxLength={13}
                            prefix='- $'
                            decimalsLimit={2}
                            onKeyPress={preventMinus}
                            onValueChange={LateAdjValueChange}
                            disabled={btnDisabled || BlockAwaitUsers || !canMakePayment || buttonLoading || templateAccess?.readOnly || readOnlyAccess}
                            autoComplete='off'
                        />
                    </Grid>
                    <Grid item xs={2} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}><b>Updated Amount Due</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b> ${updatedPrincipalAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedLateAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedTotalAmount.toFixed(2)}</b></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                    <React.Fragment>
                        {debtors.dbT_Status === constant.AwaitingPayment338 || debtors.dbT_Status === constant.AwaitingPayment324 ?
                            <FormControlLabel
                                control={<Checkbox size="small"
                                    checked={btnDisabled}
                                    onChange={handleCheckboxChange}
                                    name="checkedB" color="primary"
                                    disabled={!state.userAccessContext?.paymentUpdates || BlockAwaitUsers || readOnlyAccess || templateAccess?.readOnly} />}
                                label="No Payment Made" />
                            : debtors.dbT_Status === constant.AwaitingDocs326 ?
                                <FormControlLabel
                                    label="Documents not Available"
                                    control={<Checkbox
                                        disabled={BlockAwaitUsers}
                                        checked={btnDisabled}
                                        onChange={handleChange} />}
                                />
                                : null
                        }
                        {debtors.isactive !== true && !constant.Phases?.includes(debtors?.dbT_COMPANY) && props?.isEnable?.roll && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false) ?
                            <React.Fragment>
                                <Button id="Inquiry_RollButton" variant="contained" color="primary" size="small"
                                    startIcon={<ReplayIcon />}
                                    onClick={() => { setEarlyRoll(true); EarlyRoll(); props.onEroll(); }}
                                    disabled={!canRollAccount || debtors.dbT_COMPANY === constant.CONT || handleDisable || BlockAwaitUsers || templateAccess?.readOnly || readOnlyAccess}
                                    className={classes.buttonStyle1} >
                                    {!canRollAccount || handleDisable || EarlyRollRolled ? "Rolled" : "Roll"}
                                </Button>
                                {(EarlyRollRolled) && (
                                    <IconButton id="Inquiry_UndoEarlyRoll" color="primary" aria-label="add to shopping cart"
                                        style={{ marginTop: '3px' }}
                                        size="small"
                                        onClick={() => {
                                            setClicked(!clicked);
                                            setUndoEarlyRoll(true);
                                            undoRoll();
                                            setEarlyRollRolled(false);
                                        }}
                                        disabled={BlockAwaitUsers || props?.isEnable?.status_Id === 3}>
                                        <ReplayIcon style={{ fontSize: 20 }} />
                                    </IconButton>
                                )}
                            </React.Fragment>
                            : null
                        }
                        <Typography component="h6" gutterBottom className={classes.typographyFontStyle}><b>Phone Number : {phoneNumber}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.typographyStyleColor}>{service}</Typography>
                        <Typography variant="subtitle1" gutterBottom className={`${classes.typographyStyleColor} ${classes.typographyFontStyle}`}><b>{debtors.stS_DESC.replace('|', " ")}</b></Typography>
                        {debtors?.deM_DOB?.length > 0 ? <Typography variant="subtitle1" align="left" gutterBottom className={`${classes.typographyStyleColor} ${classes.typographyFontStyle}`}><b>DOB: {debtors?.deM_DOB === null ? "" : dateMasking(debtors?.deM_DOB)}</b></Typography> : null}
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Date: {referralDate !== 'Invalid date' ? referralDate : ""}</b></Typography>
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Amount: ${debtors.dbT_REFERRAL_AMT.toFixed(2)}</b></Typography>
                    </React.Fragment >
                </Grid >
                <Grid item xs={12} >
                    <Box className={`${classes.displayFlex} ${classes.btnBoxSpacing}`}>
                        <Box className={classes.btnSpacing} width="16%" >
                            {btnDisabled ?
                                <Button id="Inquiry_SubmitButton" variant="contained" color="primary" size="small"
                                    startIcon={<CheckCircleIcon />}
                                    disabled={!btnDisabled || BlockAwaitUsers || readOnlyAccess}
                                    onClick={(_) => { CheckPreviousPayment(debtors.dbT_NO); }}
                                    className={`${classes.button} ${classes.buttonBgStyle}`}
                                    style={{ background: "#009900" }}>
                                    Submit
                                    {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} style={{ color: 'white' }} /> : null}
                                </Button>
                                :
                                <Button id="Inquiry_SubmitButton" variant="contained" color="primary" size="small"
                                    startIcon={<CheckCircleIcon />}
                                    disabled={!inputVal || !disable || !canUpdatePayment || !isValid || BlockAwaitUsers || readOnlyAccess}
                                    onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(debtors.dbT_NO); }}
                                    className={`${classes.button} ${classes.buttonBgStyle}`}
                                    style={{ background: "#009900" }}>
                                    Submit
                                    {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} style={{ color: 'white' }} /> : null}
                                </Button>
                            }
                        </Box>
                        <Box className={classes.btnSpacing} width="17%">
                            <CloseAccount dbtNumber={debtors.dbT_NO} clientId={debtors.dbT_CLIENT} Disable={isEnable} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} closeSts={btnDisabled} BlockAwaitAccess={BlockAwaitUsers} onUpdate={handleGetStatus} closeReason={placedAccounts[0]?.close_Acct_Reason} closedDetails={GetAccStatus?.isClosed} templateAccess={props?.templateAccess} undoStatus={placedAccounts[0]?.status_Id} />
                        </Box>

                        {isEnable !== undefined ?
                            (!constant.Phases?.includes(debtors?.dbT_COMPANY) && isEnable?.hasLetter > 0 && props?.isEnable?.earlyRoll && debtors.isactive === true && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false)) ?
                                <Box className={classes.btnSpacing} width="17%">
                                    <Button id="Inquiry_EarlyRoll" variant="contained" color="primary" size="small"
                                        startIcon={<ReplayIcon />}
                                        className={`${classes.button} ${classes.btnRollSpacing}`}
                                        onClick={() => { setClicked(!clicked); setshowUndoButton(!showUndoButton); setEarlyRoll(true); EarlyRoll(); setEarlyButtonLoading(true) }}
                                        disabled={!canRollAccount || EarlyRollRolled || BlockAwaitUsers || GetAccStatus?.isCloseRemove || GetAccStatus?.isClosed || props?.isEnable?.removed || props?.isEnable?.status_Id === 3 || templateAccess?.readOnly || readOnlyAccess}>
                                        {EarlyRollRolled ? "Rolled" : "Early Roll"}
                                        {earlyButtonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
                                    </Button>
                                    {(EarlyRollRolled) && (
                                        <IconButton id="Inquiry_UndoEarlyRoll" color="primary" aria-label="add to shopping cart"
                                            size="small"
                                            onClick={() => {
                                                setClicked(!clicked);
                                                setUndoEarlyRoll(true);
                                                undoRoll();
                                                setEarlyRollRolled(false);
                                            }}
                                            disabled={BlockAwaitUsers || props?.isEnable?.status_Id === 3}>
                                            <ReplayIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                :
                                null
                            :
                            (debtors.isactive === true && !constant.Phases?.includes(debtors?.dbT_COMPANY)) ?
                                <Box className={classes.btnSpacing} width="17%">
                                    <Button id="Inquiry_EarlyRoll" variant="contained" color="primary" size="small"
                                        startIcon={<ReplayIcon />}
                                        className={`${classes.button} ${classes.btnRollSpacing}`}
                                        onClick={() => { setClicked(!clicked); setshowUndoButton(!showUndoButton); setEarlyRoll(true); EarlyRoll(); setEarlyButtonLoading(true) }}
                                        disabled={!canRollAccount || EarlyRollRolled || BlockAwaitUsers || GetAccStatus?.isCloseRemove || GetAccStatus?.isClosed || props?.isEnable?.removed || props?.isEnable?.status_Id === 3 || templateAccess?.readOnly || readOnlyAccess}>
                                        {EarlyRollRolled ? "Rolled" : "Early Roll"}
                                        {earlyButtonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
                                    </Button>
                                    {(EarlyRollRolled) && (
                                        <IconButton id="Inquiry_UndoEarlyRoll" color="primary" aria-label="add to shopping cart"
                                            size="small"
                                            onClick={() => {
                                                setClicked(!clicked);
                                                setUndoEarlyRoll(true);
                                                undoRoll();
                                                setEarlyRollRolled(false);
                                            }}
                                            disabled={BlockAwaitUsers || props?.isEnable?.status_Id === 3 || readOnlyAccess}>
                                            <ReplayIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                : null
                        }

                        {!constant?.Phases?.includes(debtors?.dbT_COMPANY) ?
                            <Box className={classes.btnSpacing} width="17%">
                                <CloseAndRemove dbtNumber={debtors.dbT_NO} clientId={debtors.dbT_CLIENT} Disable={isEnable} checkPhase={debtors.dbT_COMPANY} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} closeSts={btnDisabled} BlockAwaitAccess={BlockAwaitUsers} onUpdate={handleGetStatus} closeReason={placedAccounts[0]?.close_Acct_Reason} closeRemDetails={GetAccStatus?.isCloseRemove} onGetUpdate={handleRemovedStatus} templateAccess={props?.templateAccess} />
                            </Box>
                            : null
                        }
                        <Box className={classes.btnSpacing} width="16%">
                            <DocumentUpload clientId={debtors.dbT_CLIENT} letterDetails={props.letterDetails} agencyId={debtors.dbT_NO} accountId={debtors.dbT_CLNT_ACNT1} onHideNotification={hideDocNotificationForAllUsers} BlockAwaitAccess={BlockAwaitUsers} GetDebtorLetterDetails={props.GetDebtorLetterDetails} dbt_status={debtors.dbT_Status} templateAccess={props?.templateAccess} />
                        </Box>
                        <Box className={classes.btnSpacing} width="16%">
                            <Activity onRefresh={getActivityCount} debtor={props?.debtors} isReadOnly={templateAccess?.readOnly || readOnlyAccess} activityCount={activityCount} updatedEmail={updatedEmailName} />
                        </Box>
                        <Box >
                            <span className={classes.spanErrorSpacing}>
                                {!isValid ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter an amount that is less than the amount due.
                                    </Typography> : null}
                                {paymentVal ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter any payment amounts in the payment boxes.
                                    </Typography> : null}
                            </span>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Submit clientId={debtors.dbT_CLNT_ACNT1} dbtNo={debtors.dbT_NO} placedAccounts={placedAccounts} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    <Collapse in={object.SubmitValue} timeout="auto">
                    </Collapse>
                </Grid >
            </Grid >

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                classes={{ paper: classes.dialogePaper }}
                fullScreen={fullScreen}
                open={ConfirmDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleConfirmDialogCLose}>
                    <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.dialogContent} gutterBottom>
                            One or more of your payments/adjustments matches the value of a pending update.
                            Are you sure, you want to add this additional update to the account?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" size="small" variant="contained" onClick={() => { setConfirmDialog(false); submitPayment(); }} className={classes.yesButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButton} onClick={handleConfirmDialogCLose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                classes={{ paper: classes.dialogePaper }}
                fullScreen={fullScreen}
                open={checkFullAmount}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCheckFullAmountDialog}>
                    <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.dialogContent} gutterBottom>
                        The account balance is being changed to $0.00.  Was a payment made?<br /><br />
                        If Yes, click OK.<br />
                        If No, Cancel.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" size="small" variant="contained" onClick={handleCheckFullAmountDialog} className={classes.yesButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButton} onClick={() => { setCheckFullAmount(false); submitPayment(); }} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className={classes.snackbarStyle} open={submit} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Transaction Submitted Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={earlyRoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account Rolled Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={undoEarlyRoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Undo Rolled Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}
export default AccountDetails