import VisibilityIcon from '@material-ui/icons/Visibility';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { AppBar, Backdrop, Dialog, IconButton, makeStyles, Slide, Snackbar, Toolbar, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { ARMLogo } from '../../constants/Constant';
import { IGetDocumentUploadLogData } from '../../models/DocumentUploadLog/GetDocumentUploadLog';
import { IDocumentDownloadFileResult } from '../../models/UploadLog/IDocumentDownloadFileResult';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    overflowY: 'hidden',
    minHeight: '40px',
    borderBottom: '2px solid #0072E5'
  },
  title: {
    flex: 1,
    color: '#0072E5',
    textAlign: 'center',
    fontSize: 18,
    marginRight: '100px'
  },
  iconvisible: {
    float: 'left',
    marginLeft: '15px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  div: {
    flexGrow: 1,
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  IconStyle: {
    fontSize: 20,
    color: '#AEAEAE'
  },
  VisiblityIconStyle: {
    fontSize: 20,
    color: '#1E90FF'
  },
  menuButton: {
    marginTop: '5px',
    marginBottom: '5px',
    height: '40px',
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    }
  },
  menuButton1: {
    width: '120px',
    height: '40px'
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden'
    }
  },
  toolbarStyle: {
    backgroundColor: 'white',
    minHeight: '40px'
  }
}));

const DocumentPDFViewer: React.FC<{ row: IGetDocumentUploadLogData, enable: boolean }> = (props) => {
  const base = ("data:application/pdf;base64,");
  const { state, dispatch } = useGlobalState();
  const [serviceURL, setServiceURL] = useState('');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [ProgressBar, setshowProgressBar] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleClickOpen = () => {
    setshowProgressBar(true);
    getPDFFileAsync();
  };

  const handleClose = () => {
    setOpen(false);
  }
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };

  function getPDFFileAsync() {
    (async () => {
      await useFetch<IDocumentDownloadFileResult>(`DocumentUploadLog/DocumentFileDownload?fileId=${props?.row?.file_id}`).then((r) => {
        setServiceURL(URL.createObjectURL(base64toBlob(base + r?.data?.fileContent)));
        setshowProgressBar(false);
        setOpen((r?.status === 404 || r?.status === 400) ? false : true);
      }).catch(() => {
        setFileExists(true);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length);
    const bytes = window.atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <IconButton disabled={!props.enable} aria-label="delete" onClick={handleClickOpen} className={classes.iconvisible} style={{ borderRadius: 50 }}>
          <VisibilityIcon fontSize="large" className={!props.enable ? classes.IconStyle : classes.VisiblityIconStyle} />
        </IconButton>
        {open ?
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
            <AppBar position="fixed" className={classes.appBar} >
              <Toolbar className={classes.toolbarStyle}>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                  <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  <b>DOCUMENT VIEWER</b>
                </Typography>
                <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                  <CloseIcon style={{ color: '#000', fontSize: 22 }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
              <Viewer fileUrl={serviceURL}
                defaultScale={1.50} plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </Dialog>
          : null}
        <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            File does not exists!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment >
  );
};

export default DocumentPDFViewer;