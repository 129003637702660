import {
    Button, Dialog, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import React from 'react';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// Models
import { useHistory } from 'react-router-dom';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import useStyles from './AgentUserCss';


const ImporsonateAgentUser: React.FC<{ email: string }> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { email } = props;
    const { dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImpersonate = () => {
        (async () => {
            let request = { "emailAddress": email }
            await usePost<any>("AgentUser/GetAgentUserByEmail", request).then((r) => {
                dispatch({ type: GlobalStateAction.AgentUserAccessContext, AgentUserAccessContext: r?.data['agentUserDetails'][0] });
                dispatch({ type: GlobalStateAction.AgentClientDetails, AgentClientDetails: r?.data['agentUserClientPermissions'] });
                dispatch({ type: GlobalStateAction.AgentUserNotificationClientCodeDetails, AgentUserNotificationClientCodeDetails: r?.data['notificationClientDetails'] });
                if(r?.data['agentUserDetails'][0]?.disputeValidation || r?.data['agentUserDetails'][0]?.paymentVerification || r?.data['agentUserDetails'][0]?.backupDocument){
                    history.push('/agentdashboard');
                  }else{
                    history.push('/agentinquiry');
                  }
            }).finally(() => {
                
            });
        })()
    }

    return (
        <React.Fragment>
            <div>
                <Button size="small" id="InviteUser_btn" startIcon={<SupervisedUserCircleIcon />} onClick={handleClickOpen} variant="contained"
                    color="primary" style={{ borderRadius: 20 }} className={classes.button}>
                    Impersonate
                </Button>

                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    open={open}
                    maxWidth={'sm'}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby="responsive-dialog-title" >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            IMPORSONATE
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                            Are you sure, you want to Impersonate {email}.  ?
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button id="CPO_OK_btn" size="small" color="primary" className={classes.yesButton1} autoFocus startIcon={<CheckCircleOutlineIcon />} onClick={() => { handleImpersonate(); }}>
                            Yes
                        </Button>
                        <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton1} autoFocus onClick={handleClose}
                            variant="contained" startIcon={<CancelIcon />}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}

export default ImporsonateAgentUser