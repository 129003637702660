import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    gridPadding: {
        padding: '5px'
    },
    tablebody: {
        maxHeight: 700,
        minHeight: 700,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 218px)',
            minHeight: 'calc( 100vh - 218px)',
        },
    },
    expandedTablebody: {
        maxHeight: 205,
        minHeight: 205,
        marginBottom: '10px',
    },
    noRecordsStyle: {
        color: "red",
        marginTop: "5px",
        fontSize: 18
    },
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        border: '1px solid gray',
        minHeight: '35px'
    },
    tableHeadRadius: {
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px'
    },
    titleColor: {
        color: "blue",
        fontWeight: 'bold',
    },
    wrapper: {
        position: 'relative'
    },
    backbutton: {
        borderRadius: 20,
        fontSize: 11,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginRight: '10px'
    },
    printStyle: {
        borderRadius: 30,
        marginTop: '5px',
        padding: '2px 2px'
    },
    downloadIconStyle: {
        color: "#a1a1a1",
        fontSize: 29,
        marginTop: '5px',
        marginLeft: '10px'
    },
    downloadIconStyle1: {
        color: "#2377E4",
        fontSize: 29,
        marginTop: '5px',
        marginLeft: '1%'
    },
    fabProgressPrint: {
        color: green[500],
        position: 'absolute',
        top: 3,
        left: 8,
        zIndex: 1,
    },
    footerBoxStyle: {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    },
    buttonalign1: {
        borderRadius: 50
    },
    tableHeaderStyle: {
        background: "#007FFF",
        color: "white",
        padding: '5px 0px 5px 5px !important',
        fontSize: '11px'
    },
    ContentStyle: {
        fontSize: 13,
        border: '1px solid #215176',
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    expandicon: {
        backgroundColor: '#215176',
        color: 'white',
        borderRadius: 30
    },
    accordionDetails: {
        borderTop: '2px solid rgba(6, 24, 44, 0.4)'
    },
    TextNormal: {
        color: '#215176',
        fontSize: 14,
        fontWeight: 'bold'
    },
    TextNormal1: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold'
    },
    tablefont: {
        fontSize: 12,
        padding: '3px 0px 3px 5px !important'
    },
    tableCellColor: {
        color: "white",
        background: "#007FFF",
        padding: '2px 10px',
        fontSize: '11px'
    },
    tableHeaderStyle1: {
        flexWrap: 'wrap',
        padding: 3,
        border: '1px solid #f0efef',
        background: "#E7EFF9",
    },
    customTable: {
        padding: "0px 0px", // <-- arbitrary value
        borderBottom:'none',
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 0px" // <-- arbitrary value
        }
    },
    root1: {
        fontSize: 11,
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none"
    },
    rowExpand: {
        display: 'flex',
        borderRadius: 10,
        maxHeight: '205px',
        minHeight: '205px',
        minWidth: "100%",
        maxWidth: "100%",
        '& .MuiBox-root': {
            padding: '0px 15px 1px 15px'
        }
    },
    tab1: {
        marginTop: "4px",
        marginLeft: "10px"
    },
    buttons: {
        borderRadius: 20,
        fontSize: "11px",
        background: "#007FFF",
        color: "white",
        alignContent: "flex-start",
        justify: "flex-start",
        paddingLeft: 8,
        marginTop: "8px",
        marginLeft: "30px",
    },
    ClearFilterButtonStyle: {
        borderRadius: 20,
        fontSize: "11px",
        background: "#007FFF",
        color: "white",
        marginLeft: "10px",
        paddingLeft: 8,
        marginTop: "8px",
    },
    IconStyle: {
        height: "15px",
        width: "15px",
        marginLeft: "2px",
    },
    paper1: {
        border: "2px solid black",
    },
    autoCompleteFont: {
        fontSize: 12,
    },
    searchTextStyle: {
        padding: "10px !important",
    },
    usertablecellStyle: {
        padding: "3px 12px",
    },
    usertablecellStyle2: {
        padding: "3px 12px 3px 0px",
    },
    outlinedInput1: {
        fontSize: 14,
        color: "black",
        paddingLeft: "5px",
        "&$focused $notchedOutline": {
            color: "white",
            border: "none",
        },
    },
    GreenColor: {
        color: "green",
    },
    searchBar: {
        boxShadow: "0px 1px 4px 1px rgba(103, 128, 159, 1)",
        borderRadius: 5,
        width: "100%",
        margin: "4px 0px",
        fontSize: 14,
        textIndent: "10px",
        marginBottom: "5px",
    },
    domainBorder: {
        borderRadius: "50px",
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 12,
        padding: '3px 12px'
    },
}))

export default useStyles