import React from 'react';
import { AppBar, makeStyles, Toolbar, Grid } from '@material-ui/core';
import Authorize from '../images/header/header-logo.svg';
import { SignOutButton } from './Authentication/SignOutButton';
import * as constant from "../constants/Constant";
import { Typography } from '@mui/material';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        margin: '10px 0px'
    },
    image: {
        width: '60vw',
        height: '50vh',
        alignItems: "center",
    },
    MessageStyle: {
        fontSize: '21px',
        color: '#0136a1',
        marginTop: '5px'
    }
});

export const LogoutSignin: React.FC<{ userName: string }> = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="static" style={{ backgroundColor: 'White' }}>
                <Toolbar>
                    <Grid container spacing={3}>
                        <Grid item xs={2} style={{ color: 'black' }}>
                            <img alt="ARM_Solutions Logo" width="160px" height="55px" src={constant.ARMLogo} />
                        </Grid>
                        <Grid item xs={8} style={{ color: 'black', marginTop: '18px' }}>
                            <Typography align='center'>
                                Logged in as : <span style={{ color: 'blue' }}><b>{props?.userName}</b></span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SignOutButton />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container spacing={0} >
                    <div className={classes.root} >
                        <div >
                            <img src={Authorize} alt="401_Authorize" width="auto" height="auto" className={classes.image} />
                            <br />
                            <div className={classes.MessageStyle}>
                                We are sorry you are experiencing difficulties, please report this error to <b style={{ color: 'blue' }}>"{constant.serviceEmailID}"</b>.
                            </div>
                        </div>
                        <div >
                        </div>
                    </div >
                </Grid>
            </div>
        </React.Fragment>
    );
};

