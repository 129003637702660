import React, { useEffect, useState } from 'react';
import {
  Backdrop, Box, Button, createStyles, Grid, makeStyles, Paper, Table,
  TableContainer, TableHead, TableRow, Theme, withStyles, TableBody, TableCell, LinearProgressProps, LinearProgress
} from '@material-ui/core';
import moment from 'moment';
import * as _ from 'lodash';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
//Icons
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Models
import { useHistory } from 'react-router-dom';
import { usePost, useFetch } from "../../../utils/apiHelper";
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { GlobalStateAction, useGlobalState } from "../../../store/GlobalStore";
import { IAccountNumberList, IStateAndZipCodeList, IZipCodeResult, IStateResult, IPhonenumber, IClientCodeDetail, ISpecialNameList, IItemizationResults, IItemizationDateAndState } from '../../../models/Placements/IPlacementRejectRules';
import * as constant from '../../../constants/Constant';
import Papa from 'papaparse';

const useStyles = makeStyles((theme) => ({
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    marginTop: '4px',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    padding: '3px',
    maxWidth: "98.9vw",
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    },
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginTop: "1cm",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 11,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
    float: 'right',
    marginRight: 10
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  div: {
    flexGrow: 1
  },
  progressBar: {
    height: '6px'
  },
  title: {
    alignContent: "center",
    marginTop: '10px',
    // float: 'right'
  },
  buttonbox: {
    float: 'right'
  },
  tableHead: {
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableRow: {
    border: '1.8px solid #364F6B',
    padding: '8px',
    textIndent: '8px',
    fontSize: 11
  },
  tableRow1: {
    color: "white",
    textAlign: 'left',
    padding: '0px',
    textIndent: '10px',
    fontSize: 12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tablebody: {
    display: 'flex',
    marginBottom: '10px',
    backgroundColor: 'whitesmoke',
    maxHeight: 600,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 208px)',
    },
  },
  textWrap: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "70%",
    overflow: 'hidden',
    marginTop: '8px',
    '&:hover': {
      overflow: 'visible',
      transition: '0.10s',
    },
  },
  LabelStyle: {
    float: 'left',
    marginTop: '25px',
    marginLeft: '90px'
  },
  countMsg: {
    alignContent: "left",
    display: 'flex',
    marginLeft: '10px',
    marginTop: '10px',
    color: '#945a56',
    fontWeight: 900,
    fontSize: 16
  },
  LinearBar: {
    '&.MuiLinearProgress-root': {
      height: '10px'
    },
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
      width: 600
    }
  }),
)(TableCell);

const PreviewData = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [FinalPlacementList, setFinalPlacementList] = useState<IPlacementAccount[]>(props.location.state.placementAccountList);
  let placementAccountList: IPlacementAccount[] = props.location.state.placementAccountList;
  let selectedHeaderList: string[] = props.location.state.selectedHeaderList;
  let templateList: ITemplateField[] = props.location.state.templateList;
  let stateCodes: string[] = props.location.state.stateCodes;
  let phaseNumber: number = props.location.state.phaseNumber;
  let tablerows: number[] = props.location.state.tablerows;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;
  const UserId = state.userAccessContext?.id;
  let IsCSVUpload = false;
  const [accountNumberList, setAccountNumberList] = useState<IAccountNumberList[]>([]);
  const [accountNumberResult, SetAccountNumberResult] = useState<IAccountNumberList[]>([]);
  const [stateAndZipCodeResult, setStateAndZipCodeResult] = useState<IStateAndZipCodeList[]>([]);
  const [stateCode, setState] = useState<IStateResult[]>([]);
  const [zip, setZip] = useState<IZipCodeResult[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<IPhonenumber[]>([]);
  const [rejectDupicateAcResult, setRejectDupicateAcResult] = useState<IAccountNumberList[]>([]);
  const [rejectNvNmStatusCode, setRejectNvNmStatusCode] = useState<IClientCodeDetail[]>([]);
  let PlacementRejectedAccountsList = [];

  const [progress, setProgress] = useState(10);
  const [isAccountSubmited, setIsAccountSubmited] = useState<Boolean>(false);

  const [specialNameList, setSpecialNameList] = useState<ISpecialNameList[]>([]);
  const [itemizationDateStatueDate, setItemizationDateStatueDate] = useState<IItemizationResults[]>([]);

  // State to store the specific client code is Exempt or not 
  const [isClientCodeExempt, setIsClientCodeExempt] = useState(0);

  const handleBackClick = () => {
    history.push('/fieldnames', {
      placementAccountList: placementAccountList, tablerows: tablerows, clientCode: clientCode, multiCode: multiCode,
      phaseNumber: phaseNumber, templateId: templateId, selectedHeaderList: selectedHeaderList, stateCodes: stateCodes
    });
  };

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "";
    }
  }

  // Read all the Account numbers based on the user selection  
  useEffect(() => {
    let newAccountNumberList = [];
    placementAccountList?.forEach((result) => {
      let currentAccountNumber: IAccountNumberList = {
        accountNumber: result?.account_no
      }
      newAccountNumberList?.push(currentAccountNumber);
    })
    setAccountNumberList(newAccountNumberList);
    handleGetValidAccountsForRuleFive(newAccountNumberList);
  }, [])

  // Call the function to get the valid state and zip from the DB
  useEffect(() => {
    checkValidStateAndZipCode();
  }, [stateAndZipCodeResult])

  // Call the function to get the valid account number from the DB
  useEffect(() => {
    checkValidAccountNumbers();
  }, [accountNumberList])

  // Function to get the Valid account numbers from the Database
  const checkValidAccountNumbers = () => {
    (async () => {
      let request = {
        "accountDetailContracts": accountNumberList
      }
      await usePost<{ accountResultContracts: IAccountNumberList[] }>('Placements/GetExistingAccountNumber', request).then((result) => {
        SetAccountNumberResult(result?.data["accountResultContracts"]);
      })
    })().finally(() => {

    })
  }

  // Read all the State and Zip code numbers based on the user selection  
  useEffect(() => {
    let newStateAndZipList = [];
    placementAccountList?.forEach((result) => {
      let currentStateAndZipCode: IStateAndZipCodeList = {
        state: result?.state,
        zipCode: result?.zip
      }
      newStateAndZipList?.push(currentStateAndZipCode);
    })
    setStateAndZipCodeResult(newStateAndZipList);
  }, [])

  // Function to get valid State and Zip code
  const checkValidStateAndZipCode = () => {
    (async () => {
      let request = {
        "stateAndZipCodeLists": stateAndZipCodeResult
      }
      await usePost<{ stateCodeResult: IStateResult[], zipCodeResult: IZipCodeResult[] }>('Placements/GetStateandZipValidation', request).then((result) => {
        setState(result?.data["stateCodeResult"]);
        setZip(result?.data["zipCodeResult"]);
      })
    })().finally(() => {
    })
  }

  useEffect(() => {
    let finalPlacementList = _.cloneDeep(placementAccountList);
    finalPlacementList.forEach(x => {
      x.delinquency_date !== null && x.delinquency_date !== "" ? x.delinquency_date = (moment(x.delinquency_date)).format('MM/DD/YYYY') : null
      x.Itemization_Date !== null && x.Itemization_Date !== "" ? x.Itemization_Date = (moment(x.Itemization_Date)).format('MM/DD/YYYY') : null
      x.Codebtor_BirthDate !== null && x.Codebtor_BirthDate !== "" ? x.Codebtor_BirthDate = (moment(x.Codebtor_BirthDate)).format('MM/DD/YYYY') : null
      x.last_payment_date !== null && x.last_payment_date !== "" ? x.last_payment_date = (moment(x.last_payment_date)).format('MM/DD/YYYY') : null
      x.service_date !== null && x.service_date !== "" ? x.service_date = (moment(x.service_date)).format('MM/DD/YYYY') : null
      x.birth_date !== null && x.birth_date !== "" ? x.birth_date = (moment(x.birth_date)).format('MM/DD/YYYY') : null
    });
    setFinalPlacementList(finalPlacementList);
  }, [])

  // This useEffect is used to check if the Client code is Exempt or not 
  useEffect(() => {
    (async () => {
      let request = {
        "clientCode": clientCode,
        "phaseCode": getPhaseString(phaseNumber)
      }
      await usePost<any>("Placements/ValidateClientCodeIsExempted", request).then((result) => {
        setIsClientCodeExempt(result?.data?.isClientCodeIsExempted);
      });
    })()
  }, []);

  // This function to Get the Valid accounts number based on the Placement Reject Rule Five
  const handleGetValidAccountsForRuleFive = (accountNumberList: IAccountNumberList[]) => {
    let clientCodeList: IClientCodeDetail[] = [{ dbT_CLIENT: clientCode }];
    (async () => {
      let request = {
        "clientCodeDetails": clientCodeList,
        "rejectAccountNumber": accountNumberList
      }
      await usePost<{ accountResultContracts: IAccountNumberList[] }>("Placements/RejectDupicateAccountNumber", request).then((result) => {
        setRejectDupicateAcResult(result?.data?.accountResultContracts);
      })
    })().finally(() => {
    })
  }

  // Get the Itemization Date
  useEffect(() => {
    let newItemizationDate = [];
    placementAccountList?.forEach((result) => {
      let currentStateAndItemizationDate: IItemizationDateAndState = {
        state: result?.state,
        itemizationDate: result?.Itemization_Date
      }
      newItemizationDate?.push(currentStateAndItemizationDate);
    })
    ItemizationDateAndStateValidation(newItemizationDate);
  }, [])

  const ItemizationDateAndStateValidation = (data: IItemizationDateAndState[]) => {
    (async () => {
      let request = {
        "rejectItemization": data
      }
      await usePost<{ itemizationResults: IItemizationResults[] }>('Placements/RejectInvalidItemizationDate', request).then((result) => {
        setItemizationDateStatueDate(result?.data["itemizationResults"]);
      })
    })().finally(() => {
    })
  }

  // This useEffect is used to get the client code based on medical(02) 
  useEffect(() => {
    (async () => {
      await useFetch<IClientCodeDetail[]>("Placements/RejectNvNmStateCode").then((result) => {
        setRejectNvNmStatusCode(result?.data);
      });

      await useFetch<IPhonenumber[]>("Placements/GetPhoneNumberValidation").then((result) => {
        setPhoneNumber(result?.data);
      })

      await useFetch<ISpecialNameList[]>("Placements/GetSpecialNameCompliancesList").then((result) => {
        setSpecialNameList(result?.data);
      })

    })()
  }, []);

  const splitConditions = /[-/\s]+/;
  // Get the date values based on the user identified date format
  function findValidDateFormat(dateString) {
    let month;
    let day;
    let year;
    let finalYear = 2000;
    const dateParts = dateString?.trim()?.split(splitConditions);
    // Extract day, month, and year from the input string
    day = dateParts[0];
    month = dateParts[1];
    year = dateParts[2];
    if (year?.length === 2) {
      year = finalYear + Number(dateParts[2]);
    }
    return `${year}/${month}/${day}`;
  }

  // Rule - 01 - Function to validate CFPB Balance is matching or not
  function calculateCFPBBalance(indexValue: number, currentAccountData: any) {
    let result;
    let cfpbFees;
    let cfpbCredit;
    let cfpbPayment;
    let cfpbInterest;
    let cfpbBalanceAsOf;
    let cfpbPaymentCredit;
    let totalBalance = placementAccountList[indexValue]?.total_balance;

    if (totalBalance?.toString()?.includes("$")) {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    } else {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    }

    // Check if the Balance_owed_on_itemization_date field has $
    if (placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.includes("$")) {
      cfpbBalanceAsOf = Number(placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbBalanceAsOf = Number(placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Interest_added_since_itemization_date field has $
    if (placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbInterest = Number(placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbInterest = Number(placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Fees_added_since_itemization_date field has $
    if (placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbFees = Number(placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbFees = Number(placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }

    // check if the "Credits applied since itemization date" and "Payments applied since itemization date" columns are selected
    if (selectedHeaderList?.findIndex(x => x == "Payments_Credits_applied_since_itemization_date")) {
      // Check if the Payments_Credits_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPaymentCredit = Number(placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPaymentCredit = Number(placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    } else if (selectedHeaderList?.findIndex(x => x == "Payments_applied_since_itemization_date") && selectedHeaderList?.findIndex(x => x == "Credits_applied_since_itemization_date")) {
      // Check if the Payments_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPayment = Number(placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPayment = Number(placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
      // Check if the Credits_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbCredit = Number(placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbCredit = Number(placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    }

    // Calculate the CFPB Balance and validate the Total Balance and the Calculation is matching or not 
    if (selectedHeaderList?.findIndex(x => x == "Payments_Credits_applied_since_itemization_date")) {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - cfpbPaymentCredit;
    } else {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - (cfpbPayment + cfpbCredit);
    }
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (result === totalBalance ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB balance not match'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return newResult;
  }

  // Rule - 02 - Function to validate "Existing account in Dispute, BK or Active"
  function OmitAccountNumberBasedOnRuleTwo(account: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing account in Dispute, BK or Active'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }

  // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
  function OmitAccountNumberBasedOnRuleFour(dateValue: any, currentAccountData: any) {
    let itemizationDate = findValidDateFormat(dateValue);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === itemizationDate);
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB itemization date invalid'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return !itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === itemizationDate);
  }

  // Rule - 5 - Removed duplicate account no with same client code and created date
  function RemoveDuplicateAccountNumber(accountNumber: any, currentAccountData: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
    // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Dups-all fields matching'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Take the each account number from PlacementAccountList and validate that account numbers with accounts numbers from DB 
    return !rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
  }

  // Rule - 6 - Remove the Accounts with Special name reject due to compliances
  function RemoveAccountsWithSpecialName(indexValue: number, currentAccountData: any) {
    let newResult;
    let finalFullName;
    let fullName = placementAccountList[indexValue]?.first_name + " " + placementAccountList[indexValue]?.last_name;
    // Check if the first name and last name is selected or full name is selected  
    if (selectedHeaderList?.findIndex(x => x == "full_name") && placementAccountList[indexValue]?.full_name?.length > 1) {
      // Filter the accounts for Special name reject due to compliances ---> specialNameList
      newResult = specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.full_name);
      // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
      if (newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
    } else if (selectedHeaderList?.findIndex(x => x == "first_name") && selectedHeaderList?.findIndex(x => x == "last_name") && fullName?.length > 1) {
      // Filter the accounts for Special name reject due to compliances ---> specialNameList
      newResult = specialNameList?.some(result => result.specialName === fullName);
      // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
      if (newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
    }

    if (selectedHeaderList?.findIndex(x => x == "full_name") && placementAccountList[indexValue]?.full_name?.length > 1) {
      finalFullName = placementAccountList[indexValue]?.full_name;
    } else if (selectedHeaderList?.findIndex(x => x == "first_name") && selectedHeaderList?.findIndex(x => x == "last_name") && fullName?.length > 1) {
      finalFullName = placementAccountList[indexValue]?.first_name + "" + placementAccountList[indexValue]?.last_name;
    }
    return !specialNameList?.some(result => result.specialName === finalFullName);
  }

  // Rule - 07 - DOB is a minor --> Funtion is used to find the DOB is minor or not 
  function OmitAccountsBasedOnRuleSeven(dateValue: any, currentAccountData: any) {
    let currentDateValue = findValidDateFormat(dateValue);
    const today = new Date();
    const birthDate = new Date(currentDateValue);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (age >= 18 ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'DOB is a minor'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return age;
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter State based on the status code
  function OmitStateBasedOnRuleEight(result: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = stateCode?.some(r => r?.stateCode === result);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !stateCode?.some(r => r?.stateCode === result);
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter Zip Code based on the status code
  function OmitZipCodeBasedOnRuleEight(result: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = zip?.some(r => r.zipCode === result);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !zip?.some(r => r.zipCode === result);
  }

  // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
  function OmitAccountNumberBasedOnRuleNine(deliquencyDate: any, fieldName: any, currentAccountData: any) {
    let deliqDate = findValidDateFormat(deliquencyDate);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (deliqDate === null || deliqDate === '1899/12/30' ? false : true);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid or missing Last Charge Date'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (deliqDate === null || deliqDate === '1899/12/30' ? false : true);
  }

  // Rule - 10 - Function to validate "Existing account in Dispute, BK or Active"
  function RejectAccountNumberBasedOnRuleTen(account: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing account in Dispute, BK or Active'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }


  // Rule Number 11 - Phone number validation 
  function RemovePhoneNumberBasedOnRuleEleven(rowPhoneNumber: any, currentAccountData: any) {
    const phoneValue = (rowPhoneNumber || "")?.replace(constant?.phoneRegexVal, "")?.slice(0, 7);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = phoneNumber?.some(result => result?.phoneNo === phoneValue);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Exception:Kinder DNC PhoneNo Removed'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return !phoneNumber?.some(result => result?.phoneNo === phoneValue);
  }

  // Rule - 12 - Exception:Age of the account is older than 5 years. --> Reject the account which is older then 5
  function OmitAccountsBasedOnRuleTwelve(dateString: any, currentAccountData: any) {
    let currentDateValue = findValidDateFormat(dateString);
    const currentDate = new Date();
    const recordDate = new Date(currentDateValue); // Assuming 'date' is the date field in each record
    const differenceInYears = currentDate.getFullYear() - recordDate.getFullYear();

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (differenceInYears <= 5 ? true : false)
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Exception: Age of the account is older than 5 years.'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (differenceInYears <= 5 ? true : false);
  }

  // Rule - 14 - "NB but referral amount is less than PIF threshold" - Take each total number from placmentAccountList and validate the total due less than 5
  function RemovefilteredTotalBalance(balance: any, currentAccountData: any) {
    balance = Number(balance?.toString()?.replace(/\$|,/g, ""));

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (balance >= 5 ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'New Business but referral amount is less than PIF threshold'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (balance >= 5 ? true : false);
  }

  // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
  function OmitAccountNumberBasedOnRuleFifteen(balance_owed_on_itemization: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = balance_owed_on_itemization <= 0 ? false : true;
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB Balance As Of Itemization Date <= 0'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return balance_owed_on_itemization <= 0 ? false : true;
  }

  // Rule - 16 -  "Medical Accts in NV and NM" - Take each state from placmentAccountList and validate whether state code conatine "NV" and "NM"
  function RemoveNvNmStateCode(rowIndex: any, currentAccountData: any) {
    if (placementAccountList[rowIndex]?.state?.toUpperCase() === constant?.StateCodeNM || placementAccountList[rowIndex]?.state?.toUpperCase() === constant?.StateCodeNV) {
      // Store the Rejected accounts list into to another state with rejected reason
      let newResult = rejectNvNmStatusCode?.some(result => placementAccountList[rowIndex]?.client_code === result?.dbT_CLIENT || clientCode === result?.dbT_CLIENT);

      if (newResult) {
        // Check if the account is not already in the rejected list
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);

        if (!exist) {
          // Add the account to the rejected list with a reason
          const newData = { ...currentAccountData };
          newData['Rejected Reason'] = 'Medical Accts in NV and NM';
          PlacementRejectedAccountsList?.push(newData);
        }

        return !rejectNvNmStatusCode?.some(result => placementAccountList[rowIndex]?.client_code === result?.dbT_CLIENT || clientCode != result?.dbT_CLIENT);
      }
    }
  }

  async function handleSubmit() {
    setIsAccountSubmited(true);
    handleAccountValidate();
    let finalPlacementList = _.cloneDeep(placementAccountList);
    finalPlacementList.map(x => {
      x.client_code = clientCode;
      x.delinquency_date !== null && x.delinquency_date !== "" ? x.delinquency_date = moment(x.delinquency_date).format('YYYY-MM-DD') : null
      x.Itemization_Date !== null && x.Itemization_Date !== "" ? x.Itemization_Date = moment(x.Itemization_Date).format('YYYY-MM-DD') : null
      x.Codebtor_BirthDate !== null && x.Codebtor_BirthDate !== "" ? x.Codebtor_BirthDate = moment(x.Codebtor_BirthDate).format('YYYY-MM-DD') : null
      x.last_payment_date !== null && x.last_payment_date !== "" ? x.last_payment_date = moment(x.last_payment_date).format('YYYY-MM-DD') : null
      x.service_date !== null && x.service_date !== "" ? x.service_date = moment(x.service_date).format('YYYY-MM-DD') : null
      x.birth_date !== null && x.birth_date !== "" ? x.birth_date = moment(x.birth_date).format('YYYY-MM-DD') : null
    });

    //Rule - 1 - Ignore the accounts which is " CFPB balance not match " --> Based on Placement Reject Rules
    if (isClientCodeExempt === 0) {
      // Validating the placement rule - CFPB balance not match - Remove the invalid accounts
      if (selectedHeaderList.findIndex(x => x == "total_balance") > -1) {
        finalPlacementList = finalPlacementList?.filter((item, rowIndex) => calculateCFPBBalance(rowIndex, placementAccountList[rowIndex]));
        placementAccountList = placementAccountList.filter((item, rowIndex) => calculateCFPBBalance(rowIndex, placementAccountList[rowIndex]));
      }
    }

    // Rule - 2 - Ignore the accounts which is "Existing in Dispute, BK or Active" --> Based on Placement Reject Rules
    if (selectedHeaderList.findIndex(x => x == "account_no") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleTwo(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleTwo(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
    if (isClientCodeExempt === 0) {
      if (selectedHeaderList.findIndex(x => x == "Itemization_Date") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFour(placementAccountList[rowIndex]?.Itemization_Date, placementAccountList[rowIndex]));
        placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFour(placementAccountList[rowIndex]?.Itemization_Date, placementAccountList[rowIndex]));
      }
    }

    // Rule - 5 - Removed duplicate account no with same client code and created date  --> Pending 
    if (selectedHeaderList?.findIndex(x => x = "account_no") > -1) {
      finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveDuplicateAccountNumber(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemoveDuplicateAccountNumber(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 6 -  "Special name reject due to compliances"
    if ((selectedHeaderList?.findIndex(x => x = "full_name") > -1) || (selectedHeaderList.findIndex(x => x == "first_name") > -1 && selectedHeaderList.findIndex(x => x == "last_name") > -1)) {
      finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveAccountsWithSpecialName(rowIndex, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemoveAccountsWithSpecialName(rowIndex, placementAccountList[rowIndex]));
    }

    // Rule - 7 -  "DOB is a minor" --> Reject the Account which is Minor
    if (selectedHeaderList.findIndex(x => x == "birth_date") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountsBasedOnRuleSeven(placementAccountList[rowIndex]?.birth_date, placementAccountList[rowIndex]) >= 18);
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountsBasedOnRuleSeven(placementAccountList[rowIndex]?.birth_date, placementAccountList[rowIndex]) >= 18);
    }

    // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules --> Pending 
    if (selectedHeaderList.findIndex(x => x == "state") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitStateBasedOnRuleEight(placementAccountList[rowIndex]?.state, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitStateBasedOnRuleEight(placementAccountList[rowIndex]?.state, placementAccountList[rowIndex]));
    }
    // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules --> Pending
    if (selectedHeaderList.findIndex(x => x == "zip") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitZipCodeBasedOnRuleEight(placementAccountList[rowIndex]?.zip, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitZipCodeBasedOnRuleEight(placementAccountList[rowIndex]?.zip, placementAccountList[rowIndex]));
    }

    // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
    if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleNine(placementAccountList[rowIndex]?.delinquency_date, constant.delinquency_date, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleNine(placementAccountList[rowIndex]?.delinquency_date, constant.delinquency_date, placementAccountList[rowIndex]));
    }

    // Rule - 10 -  "Check client account no by numeric value. Same AccountNo active or BK"
    if (selectedHeaderList.findIndex(x => x == "account_no") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => RejectAccountNumberBasedOnRuleTen(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RejectAccountNumberBasedOnRuleTen(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 11 -  "Exception:Kinder DNC PhoneNo Removed"
    if (selectedHeaderList?.findIndex(x => x = "phone_no") > -1) {
      finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
    }

    // Rule - 12 - "Exception : Age of the account is older than 5 years"
    if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1) {
      finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountsBasedOnRuleTwelve(placementAccountList[rowIndex]?.delinquency_date, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountsBasedOnRuleTwelve(placementAccountList[rowIndex]?.delinquency_date, placementAccountList[rowIndex]));
    }


    // Rule - 14 - "NB but referral amount is less than PIF threshold" - Remove duplicate account when total due less than late fee or total due greater than late fee.
    if (selectedHeaderList?.findIndex(x => x = "total_balance") > -1) {
      finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemovefilteredTotalBalance(placementAccountList[rowIndex]?.total_balance, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemovefilteredTotalBalance(placementAccountList[rowIndex]?.total_balance, placementAccountList[rowIndex]));
    }

    // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
    if (isClientCodeExempt === 0) {
      if (selectedHeaderList.findIndex(x => x == "Balance_owed_on_itemization_date") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFifteen(placementAccountList[rowIndex]?.Balance_owed_on_itemization_date, placementAccountList[rowIndex]));
        placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFifteen(placementAccountList[rowIndex]?.Balance_owed_on_itemization_date, placementAccountList[rowIndex]));
      }
    }

    // Rule - 16 -  "Medical Accts in NV and NM" - Reject if the address State = NV or NM and the CLT_CLASS = '02' (medical)
    if (selectedHeaderList?.findIndex(x => x = "state") > -1) {
      finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveNvNmStateCode(rowIndex, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList?.filter((item, rowIndex) => RemoveNvNmStateCode(rowIndex, placementAccountList[rowIndex]));
    }

    const columnsToRemove = ['user_id', 'modifiedby'];

    const csvUrl = PlacementRejectedAccountsList?.length != 0 ? convertListToCSV(PlacementRejectedAccountsList, columnsToRemove) : null;

    if (+phaseNumber != 4) {
      finalPlacementList.map(x => { x.phase = getPhaseString(phaseNumber); });
    }
    try {
      InsertManualUploadLog();
      await usePost(`Placements/ClientUpload`, finalPlacementList).then((clientUpload) => {
        if (clientUpload.status !== 202) {
          dispatch({ type: GlobalStateAction.Error, error: clientUpload.statusText });
        }
        history.push(`/finish`, {
          IsCSVUpload: IsCSVUpload, PlacementFaildRecords: csvUrl,
          placementAccountList: placementAccountList, PlacementRejectedAccountsList: PlacementRejectedAccountsList
        });
      });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Idle });
      dispatch({ type: GlobalStateAction.Error, error: ex });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  };

  // Genarate the Rejected accounts based on the placement reject rule
  function convertListToCSV(list, columnsToRemove) {
    // Extract column names from the first object
    const columnNames = Object?.keys(list[0]);

    // Filter out column names with null values and specified columns
    const nonNullColumns = columnNames?.filter(
      columnName =>
        list?.some(item => item[columnName] !== null) &&
        !columnsToRemove.includes(columnName)
    );

    // Filter the list objects to include only non-null columns
    const filteredList = list.map(item =>
      nonNullColumns.reduce((obj, columnName) => {
        obj[columnName] = item[columnName];
        return obj;
      }, {})
    );

    const csvData = Papa.unparse(filteredList);
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    // Returns the CSV records 
    return csvUrl;
  }

  const InsertManualUploadLog = () => {
    (async () => {
      try {
        let request = {
          "file_name": "",
          "fileSize": null,
          "uploadMethod": "1",
          "rid": "",
          "userid": UserId.toString(),
          "clientId": clientCode === "-2" ? "MULC" : clientCode,
          "phase": getPhaseString(phaseNumber) === undefined ? "MULP" : getPhaseString(phaseNumber),
          "bloburi": "",
          "containerName": "",
          "subFolderId": 0
        };
        dispatch({ type: GlobalStateAction.Busy });
        await usePost<any>("Placements/InsertclientuploadlogData", request);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Idle });
        dispatch({ type: GlobalStateAction.Error, error: ex });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const handleAccountValidate = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <React.Fragment>
        <Typography variant='body2' align='left' style={{ fontSize: '13px' }}>
          <b>Validating Rules</b>
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="buffer" {...props} className={classes.LinearBar} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary" style={{ color: 'green' }}>
              <b>{`${Math.round(props.value,)}%`}</b>
            </Typography>
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={0}>
          <Container fixed className={classes.container} component="div">
            <Grid container spacing={0}>
              <Grid item xs={2} >
                <Typography variant="h5" color="primary" gutterBottom className={classes.title} >
                  <b> 3. PREVIEW DATA </b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" gutterBottom className={classes.countMsg} >
                  <b>Total no.of Accounts : </b> <span style={{ color: "green", fontWeight: 900 }}>{tablerows?.length}</span>
                </Typography>
              </Grid>

              <Grid item xs={5}>
                {isAccountSubmited ?
                  <Box m={0} display="flex">
                    <Box m={0} width="75%">
                      <LinearProgressWithLabel value={progress} />
                    </Box>
                  </Box>
                  : null
                }
              </Grid>
              <Grid item xs={3}>
                <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="PD_Submit_btn" size="small" className={classes.buttons} variant="contained" endIcon={<ArrowForwardIosIcon />}
                    color="primary" onClick={handleSubmit}>
                    Submit Accounts
                  </Button>
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="PD_Back_btn" size="small" className={classes.buttons} variant="contained" startIcon={<ArrowBackIosIcon />}
                    color="primary" onClick={handleBackClick}>
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: '0px 8px' }}>
              <TableContainer component={Paper} className={classes.tablebody}>
                <Table size="medium" stickyHeader>
                  <TableHead className={classes.tableHead} >
                    <TableRow key='table-header'>
                      {templateList.map((row) => {
                        return (row.display_name ? (
                          <StyledTableCell className={classes.tableRow1} key={row.display_name + 'header'} >
                            <span className={classes.textWrap}>
                              {row.display_name}
                            </span>
                          </StyledTableCell>
                        ) : null)
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tablerows.map((r) => {
                      return (
                        <TableRow key={r}>
                          {templateList.map((row) => {
                            return (row.display_name ? (
                              <StyledTableCell key={row.display_name + r} className={classes.tableRow}>
                                {eval('FinalPlacementList[' + (r - 1) + '].' + row.field_name)}
                              </StyledTableCell>
                            ) : null)
                          })}
                        </TableRow>)
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Container>
        </Grid>
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default PreviewData