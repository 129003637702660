import { IAPIError } from './../models/IApiError';
import { AxiosResponse } from 'axios';
import { ErrorConstants } from '../constants/ErrorConstants';
import { instance } from './axisoInstance';
import { loginRequest } from '../auth/authConfig';
import { msalInstance } from '../index';


async function getToken(): Promise<string> {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });
  return response.accessToken;
}

export function parseApiResponse<T>(
  apiCall: Promise<AxiosResponse<any>>,
  resolve: (value: AxiosResponse<T> | PromiseLike<AxiosResponse<T>>) => void,
  reject: any
) {
  apiCall
    .then((res) => {
      return res
    })
    .then((body) => {
      if (body.status == 200 || body.status == 202) {
        resolve(body)
      } else {
        const unhandledError: IAPIError = {
          code: ErrorConstants.UnhandledError.Code,
          message: ErrorConstants.UnhandledError.Message,
        }
        reject('error')
      }
    })
    .catch((err) => {
      reject(err)
    })
}

export async function
  useFetch<T>(
  requestUrl: string,
  userToken: string = ''
): Promise<AxiosResponse<T>> {
 
  var promise = instance.get(requestUrl, {
    headers: {
      Authorization: 'Bearer ' + await getToken()
    },
  })

  return new Promise<AxiosResponse<T>>((resolve1, reject1) => {
    parseApiResponse(promise, resolve1, reject1)
  })
}

export async function usePost<T>(
  requestUrl: string,
  requestData: any,
  userToken: string = ''
): Promise<AxiosResponse<T>> {
  let promise = instance.post(requestUrl, requestData, {
    headers: {
      Authorization: 'Bearer ' + await getToken(),
    },
  })

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject)
  })
}

export async function useDelete<T>(
  requestUrl: string,
  userToken: string = '',
): Promise<AxiosResponse<T>> {
 
  let promise = instance.delete(requestUrl, {
    headers: {
      Authorization: 'Bearer ' + await getToken(),
    },
  })

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject)
  })
}