import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Routes, { IRoute } from '../Routes'
import PrivateRoute from './PrivateRoute'
import { useGlobalState } from '../store/GlobalStore'
import { Grid } from '@material-ui/core'

const Content = () => {
    return (
        <React.Fragment>
            <Grid className="content">
                <Switch>
                    {Routes.map((route: IRoute) => {
                        if (route.isPrivate) {
                            return (
                                <PrivateRoute exact path={route.path} key={route.path} component={route.component}>
                                </PrivateRoute>
                            )
                        }
                        return (
                            <Route exact path={route.path} key={route.path} component={route.component}>
                            </Route>
                        )
                    })}
                </Switch>
            </Grid >
        </React.Fragment >
    )
}

export default Content