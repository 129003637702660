import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, ListItem, Container,
  ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, CssBaseline,
  TableRow, TextField, useMediaQuery, useTheme, Backdrop, Typography, IconButton, FormControlLabel, Select, InputLabel, FormControl
} from '@material-ui/core';
import * as _ from 'lodash';
import moment from 'moment';
// Icons
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@material-ui/core/Checkbox';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// Component
import SaveTemplate from '../SaveTemplate';
import { useStyles } from './CSVPlacementCss';
import DeleteTemplate from './DeleteTemplate';
import { Autocomplete } from '@material-ui/lab';
import PuffLoader from "react-spinners/PuffLoader";
import React, { useEffect, useState } from 'react';
import { useFetch, usePost } from '../../../utils/apiHelper';
import CurrencyInput from 'react-currency-input-field';
import Footer from '../../../components/Footer/Footer';
import * as constant from '../../../constants/Constant';
// Models
import { formats } from '../../../constants/Constant';
import { IUwfDetails } from '../../../models/Placements/IUwfDetails';
import LightTooltip from "../../../components/GlobalStyles/LightTooltip";
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { IClientCodeMap } from '../../../models/Placements/IClientCodeMapped';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { IMappingTemplate } from '../../../models/Placements/IMappingTemplate';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { IPlacementMapping } from '../../../models/Placements/IPlacementMapping';
import { IValidationError } from '../../../models/Placements/ICSVValidationError';
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../../../components/GlobalStyles/DialogStyle';
import { IDateFormatDetails, IErrorList, IFixedDateFormat } from '../../../models/Placements/IFixedDateFormat';
import { IAccountNumberList, IClientCodeDetail, IItemizationDateAndState, IItemizationResults, IPhonenumber, ISpecialNameList, IStateAndZipCodeList, IStateResult, IZipCodeResult } from '../../../models/Placements/IPlacementRejectRules';
import Papa from 'papaparse';

function CSVFieldnames(props) {
  const theme = useTheme();
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();

  const [placementValidationList, setPlacementValidationList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [placementAccountCSVData, setPlacementAccountCSVData] = useState<string[][]>(props.location?.state?.placementAccountCSVData ? props.location?.state?.placementAccountCSVData : []);
  const [placementAccountList, setPlacementAccountList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [placementAccountCSV, setPlacementAccountCSV] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountCSV ? props.location?.state?.placementAccountCSV : []);
  const [clientCodeMapList, setClientCodeMapList] = useState<IClientCodeMap[]>(props.location?.state?.clientCodeMappingList ? props.location?.state?.clientCodeMappingList : []);
  const [selectedHeaderList, setSelectedHeaderList] = useState<string[]>(props.location?.state?.selectedHeaderList ? props.location?.state?.selectedHeaderList : []);
  const [SelectedClients, setSelectedClient] = useState(props?.location?.state?.selectedClients ? props?.location?.state?.selectedClients : []);
  const [selectedHeaderDateFormatList, setSelectedHeaderDateFormatList] = useState<string[]>([]);
  const [filteredTemplateList, setFilteredTemplateList] = useState<ITemplateField[]>([]);
  const [mappingTemplateList, setMappingTemplateList] = useState<IMappingTemplate[]>([]);
  const [mappedRequireFields, setMappedRequireFields] = useState<ITemplateField[]>([]);
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
  const [requireFields, setRequireFields] = useState<ITemplateField[]>([]);
  const [mappingList, setMappingList] = useState<IMappingTemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateField[]>([]);
  const [GetUwfCltData, setGetUwfCltData] = useState<IUwfDetails[]>();

  const [isPreview, setIsPreview] = useState(props.location?.state?.isPreview ? props.location?.state?.isPreview : false);
  const [futureVal, setFutureVal] = useState<boolean>(props.location?.state?.futureVal ? props.location?.state?.futureVal : false);
  const [mBalanceChecked, setMBalanceChecked] = useState(isPreview === false ? false : props.location?.state?.mBalanceChecked);
  const [ignoreRowNumber, setIgnoreRowNumber] = useState<any>(isPreview === false ? 1 : props.location?.state?.ignoreRows);
  const [getStates, setGetStates] = useState(props.location?.state?.stateCodes ? props.location?.state?.stateCodes : []);
  const [feeChecked, setFeeChecked] = useState(isPreview === false ? false : props.location?.state?.feeChecked);
  const [mBalanceValue, setMBalanceValue] = useState<any>(props.location?.state?.mBalanceValue);
  const [feeValue, setFeeValue] = useState<any>(props.location?.state?.feeValue);
  let clientList: IClientList[] = props?.location?.state?.clientList;
  let phaseNumber: number = props.location.state.phaseNumber;
  let uploadedFile: any = props.location.state.uploadedFile;
  let clientCode: string = props?.location?.state?.clientCode;
  let FileId: number = props?.location?.state?.FileId;
  let fileName: string = props?.location?.state?.fileName;
  let fileSize: string = props?.location?.state?.fileSize;
  let blobUri: string = props?.location?.state?.blobUri;

  const [checkCont, setCheckCont] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkCont);
  const [check1STP, setCheck1STP] = useState<boolean>(isPreview === false ? false : props.location?.state?.check1STP);
  const [checkPrec, setCheckPrec] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkPrec);
  const FeeObject = { field_name: 'fees', display_name: 'Fees (*)', data_type: 'number', is_nullable: false };
  const [isAutoMapping, setIsAutoMapping] = useState<boolean>(false);
  const [clientCodeVal, setClientCodeVal] = useState<boolean>(false);

  const [DeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [ignoreRows, setIgnoreRows] = useState<boolean>(true);
  const [phaseVal, setPhaseVal] = useState<boolean>(false);
  const [savingEnable, setSavingEnable] = useState(false);
  const [feeSelected, setFeeSelected] = useState(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [enable, setEnable] = useState(false);
  const [valid, setValid] = useState(false);
  const [fixDateValueOpen, setfixDateValueOpen] = useState(false);
  const [dateFormatConfirmOpen, setDateFormatConfirmOpen] = useState(false);

  const [mappedClientCodeValue, setMappedClientCodeValue] = useState<string>(isPreview === false ? "" : props.location?.state?.mappedClientCodeValue);
  const [mappingValue1STP, setMappingValue1STP] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValue1STP);
  const [mappingValuePrec, setMappingValuePrec] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValuePrec);
  const [mappingValueCont, setMappingValueCont] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValueCont);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [clientCodeMsg, setClientCodeMsg] = useState<string>("");
  const [selectedField, setSelectedField] = useState<string>("");
  const [GetClientCode, setGetClientCode] = useState("");
  const [indexValue, setIndexValue] = useState<number>();
  const [ProgressBar, setShowProgressBar] = useState(false);

  const [phaseMsg, setPhaseMsg] = useState<string>("");
  const [mapped, setMapped] = useState<number>();
  const { state, dispatch } = useGlobalState();
  const UserName = state.userAccessContext?.id;
  const [templateId, setTemplateId] = useState<number>(0);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);

  const [finalDateFormat, setFinalDateFormat] = useState<IFixedDateFormat[]>([]);
  const [lastSelectionFormat, setLastSelectionFormat] = useState<string>("");
  const [localState, setLocalState] = useState<IDateFormatDetails>(props?.location?.state?.finalDateFormat ? props?.location?.state?.finalDateFormat : []);
  const [formatErrorList, setFormatErrorList] = useState([]);
  const [showDateIssueMessage, setShowDateIssueMessage] = useState<boolean>(false);
  const [isDateFormatIsSaved, setIsDateFormatIsSaved] = useState<boolean>(false);

  const [isMappingTemplateSelected, setIsMappingTemplateSelected] = useState<boolean>(props?.location?.state?.isMappingTemplateSelected ? props?.location?.state?.isMappingTemplateSelected : false);

  const [newDelinquencyDate, setNewDelinquencyDate] = useState<string>("");
  const [newItemization_Date, setNewItemization_Date] = useState<string>("");
  let globalTemplateId: number = 0;
  let globalTemplateName: string = "";
  const [placementTemplateId, setPlacementTemplateId] = useState<number>(0);

  const [isAccountSubmited, setIsAccountSubmited] = useState<Boolean>(false);
  const [accountNumberList, setAccountNumberList] = useState<IAccountNumberList[]>([]);
  const [accountNumberResult, SetAccountNumberResult] = useState<IAccountNumberList[]>([]);
  const [itemizationDateStatueDate, setItemizationDateStatueDate] = useState<IItemizationResults[]>([]);
  const [rejectDupicateAcResult, setRejectDupicateAcResult] = useState<IAccountNumberList[]>([]);
  const [rejectNvNmStatusCode, setRejectNvNmStatusCode] = useState<IClientCodeDetail[]>([]);
  const [stateAndZipCodeResult, setStateAndZipCodeResult] = useState<IStateAndZipCodeList[]>([]);
  const [stateCode, setState] = useState<IStateResult[]>([]);
  const [zip, setZip] = useState<IZipCodeResult[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<IPhonenumber[]>([]);
  const [specialNameList, setSpecialNameList] = useState<ISpecialNameList[]>([])
  const [csvUrl, setCSVUrl] = useState('');
  // State to store the specific client code is Exempt or not 
  const [isClientCodeExempt, setIsClientCodeExempt] = useState(0);
  let PlacementRejectedAccountsList = [];
  const [phase1, setPhase1] = useState<string>('');

  const GetPlacementDateFormat = () => {
    (async () => {
      await useFetch<any>(`Placements/GetPlacementDateFormat?clientCode=${clientCode === "-2" ? null : clientCode}`).then((result) => {
        let dateFormatValue = result?.data?.placementDateFormat;
        let newDateFormatValue = "";
        // Build localState to handle the date format based on the template level date format
        if (dateFormatValue === "DD/MM/YYYY" || dateFormatValue === "DDMMYYYY" || dateFormatValue === "DD-MM-YYYY") {
          newDateFormatValue = "DD/MM/YYYY";
        } else if (dateFormatValue === "MM/DD/YYYY" || dateFormatValue === "MMDDYYYY" || dateFormatValue === "MM-DD-YYYY") {
          newDateFormatValue = "MM/DD/YYYY";
        } else if (dateFormatValue === "YYYY/MM/DD" || dateFormatValue === "YYYYMMDD" || dateFormatValue === "YYYY-MM-DD") {
          newDateFormatValue = "YYYY/MM/DD";
        } else if (dateFormatValue === "YYYY/DD/MM" || dateFormatValue === "YYYYDDMM" || dateFormatValue === "YYYY-DD-MM") {
          newDateFormatValue = "YYYY/DD/MM";
        }
        let validateDateFormat: IDateFormatDetails = {
          delinquency_date: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          birth_date: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          last_payment_date: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          service_date: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          Codebtor_BirthDate: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          Itemization_Date: result?.data?.placementDateFormat?.length ? newDateFormatValue : "",
          Delinquency_Itemization_Date: result?.data?.placementDateFormat?.length ? newDateFormatValue : ""
        }
        setLocalState(validateDateFormat);
      });
    })()
  }

  // call the function to get the Date format for the template
  useEffect(() => {
    handleGetGlobalTemplate();
    props?.location?.state?.finalDateFormat ? null : GetPlacementDateFormat();
  }, []);

  const handleGetGlobalTemplate = () => {
    (async () => {
      await useFetch<any>(`Placements/GetGlobalAllienceTemplateId?folderName=${state?.userAccessContext?.ftpFolder}`).then((result) => {
        globalTemplateId = result?.data?.globalTemplateId;
        globalTemplateName = result?.data?.globalTemplateName;
        setPlacementTemplateId(result?.data?.globalTemplateId);
      });
    })()
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleValidateDateFormat = () => {
    let dateFormatErrorList = _.cloneDeep(formatErrorList);
    let newHeaderList = _.cloneDeep(selectedHeaderList);
    let newPlacementAccountList = _.cloneDeep(placementAccountList);
    newHeaderList = newHeaderList.filter((x) => x != undefined);
    let indexNumber = Number(ignoreRowNumber);
    let delinquency_date = null;
    let Itemization_Date = null;
    newPlacementAccountList = newPlacementAccountList?.slice(indexNumber);
    for (const result of newPlacementAccountList) {
      if (result?.delinquency_date?.trim()?.length > 0) {
        delinquency_date = result?.delinquency_date.trim();
        break;
      }
    }
    for (const result of newPlacementAccountList) {
      if (result?.Itemization_Date?.trim()?.length > 0) {
        Itemization_Date = result?.Itemization_Date.trim();
        break;
      }
    }
    let service_date = placementAccountList[indexNumber]?.service_date?.trim();
    let last_payment_date = placementAccountList[indexNumber]?.last_payment_date?.trim();
    let birth_date = placementAccountList[indexNumber]?.birth_date?.trim();

    // validate the each records based on the source date format choosen by the user or from the template
    newHeaderList?.forEach((result) => {
      if (result?.includes(constant?.delinquency_date)) {
        if (localState?.delinquency_date === "DD/MM/YYYY") {
          if (!moment(delinquency_date, constant?.dayMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.delinquency_date,
              columnName: constant.delinquency_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.delinquency_date === "YYYY/MM/DD") {
          if (!moment(delinquency_date, constant?.yearMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.delinquency_date,
              columnName: constant.delinquency_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.delinquency_date === "YYYY/DD/MM") {
          if (!moment(delinquency_date, constant?.yearDateFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.delinquency_date,
              columnName: constant.delinquency_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.delinquency_date === "MM/DD/YYYY") {
          if (!moment(delinquency_date, constant?.monthDayFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.delinquency_date,
              columnName: constant.delinquency_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        }
      } else if (result?.includes(constant?.Itemization_Date)) {
        if (localState?.Itemization_Date === "DD/MM/YYYY") {
          if (!moment(Itemization_Date, constant?.dayMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.Itemization_Date,
              columnName: constant.Itemization_Date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.Itemization_Date === "YYYY/MM/DD") {
          if (!moment(Itemization_Date, constant?.yearMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.Itemization_Date,
              columnName: constant.Itemization_Date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.Itemization_Date === "YYYY/DD/MM") {
          if (!moment(Itemization_Date, constant?.yearDateFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.Itemization_Date,
              columnName: constant.Itemization_Date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.Itemization_Date === "MM/DD/YYYY") {
          if (!moment(Itemization_Date, constant?.monthDayFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.Itemization_Date,
              columnName: constant.Itemization_Date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        }
      } else if (result?.includes(constant?.service_date) && (service_date?.length > 1)) {
        if (localState?.service_date === "DD/MM/YYYY") {
          if (!moment(service_date, constant?.dayMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.service_date,
              columnName: constant.service_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.service_date === "YYYY/MM/DD") {
          if (!moment(service_date, constant?.yearMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.service_date,
              columnName: constant.service_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.service_date === "YYYY/DD/MM") {
          if (!moment(service_date, constant?.yearDateFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.service_date,
              columnName: constant.service_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.service_date === "MM/DD/YYYY") {
          if (!moment(service_date, constant?.monthDayFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.service_date,
              columnName: constant.service_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        }
      } else if (result?.includes(constant?.last_payment_date) && (last_payment_date?.length > 1)) {
        if (localState?.last_payment_date === "DD/MM/YYYY") {
          if (!moment(last_payment_date, constant?.dayMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.last_payment_date,
              columnName: constant.last_payment_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.last_payment_date === "YYYY/MM/DD") {
          if (!moment(last_payment_date, constant?.yearMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.last_payment_date,
              columnName: constant.last_payment_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.last_payment_date === "YYYY/DD/MM") {
          if (!moment(last_payment_date, constant?.yearDateFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.last_payment_date,
              columnName: constant.last_payment_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.last_payment_date === "MM/DD/YYYY") {
          if (!moment(last_payment_date, constant?.monthDayFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.last_payment_date,
              columnName: constant.last_payment_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        }
      } else if (result?.includes(constant?.birth_date) && (birth_date?.length > 1)) {
        if (localState?.birth_date === "DD/MM/YYYY") {
          if (!moment(birth_date, constant?.dayMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.birth_date,
              columnName: constant.birth_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.birth_date === "YYYY/MM/DD") {
          if (!moment(birth_date, constant?.yearMonthFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.birth_date,
              columnName: constant.birth_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.birth_date === "YYYY/DD/MM") {
          if (!moment(birth_date, constant?.yearDateFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.birth_date,
              columnName: constant.birth_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        } else if (localState?.birth_date === "MM/DD/YYYY") {
          if (!moment(birth_date, constant?.monthDayFormat, true).isValid()) {
            let errorList: IErrorList = {
              sourceDateFormat: localState?.birth_date,
              columnName: constant.birth_date,
              errorMessage: constant?.dateFormatErrorMessage
            }
            dateFormatErrorList.push(errorList);
            setFormatErrorList(dateFormatErrorList);
          }
        }
      }
    })
    return dateFormatErrorList;
  }

  // this is the function to validate whether the date format is exist for Save mapping template

  const handleCheckDateFormatExist = () => {
    let newHeaderList = _.cloneDeep(selectedHeaderList);
    newHeaderList = newHeaderList.filter((x) => x != undefined);
    let dateFormatExist: boolean = false;

    // validate the each records based on the source date format choosen by the user or from the template
    newHeaderList?.forEach((result) => {
      if (result?.includes(constant?.delinquency_date)) {
        if (localState?.delinquency_date === null) {
          dateFormatExist = true;
        }
      } else if (result?.includes(constant?.Itemization_Date)) {
        if (localState?.Itemization_Date === null) {
          dateFormatExist = true;
        }
      } else if (result?.includes(constant?.service_date)) {
        if (localState?.service_date === null) {
          dateFormatExist = true;
        }
      } else if (result?.includes(constant?.last_payment_date)) {
        if (localState?.last_payment_date === null) {
          dateFormatExist = true;
        }
      } else if (result?.includes(constant?.birth_date)) {
        if (localState?.birth_date === null) {
          dateFormatExist = true;
        }
      }
    })
    return dateFormatExist;
  }

  let dateFormatErrorValues: IErrorList[];
  let checkDateFormatExist: boolean;
  const handleSubmit = (e) => {
    setIsSubmit(true);
    dateFormatErrorValues = handleValidateDateFormat();
    checkDateFormatExist = handleCheckDateFormatExist();

    // find the Error list in the mapping 
    const undefinedFields = Object.keys(placementAccountList[0]).filter(key => placementAccountList[0][key] === undefined || placementAccountList[0][key] === "0.00");
    const fieldsWithErrors = validationErrors.map(error => error.field);
    const undefinedFieldsWithErrors = undefinedFields.filter(field => fieldsWithErrors.includes(field));

    if (!localState?.delinquency_date?.length && !localState?.Itemization_Date?.length && !localState?.last_payment_date?.length && !localState?.service_date?.length && !localState?.birth_date?.length) {
      setOpenErrorMessage(true);
    } else if (checkDateFormatExist) {
      setOpenErrorMessage(true);
    } else if (savingEnable === true && !undefinedFieldsWithErrors?.length) {
      let dateLength = 8;
      let zipLength = 4;
      let filteredHeaderList = _.cloneDeep(selectedHeaderList);
      let filteredCSVData = _.cloneDeep(placementAccountCSVData);
      let filteredPlacementAccountList = _.cloneDeep(placementAccountList);
      let filteredPlacementAccountListCSV = _.cloneDeep(placementAccountList);

      if (!dateFormatErrorValues?.length) {
        // Remove unmapped columns from csv data
        filteredCSVData.map((row, rowIndex) => {
          filteredCSVData[rowIndex] = filteredCSVData[rowIndex].filter((x, index) => filteredHeaderList[index] != undefined);
        });
        filteredHeaderList = filteredHeaderList.filter((x) => x != undefined);

        // Remove ignored rows from csv data
        if (ignoreRowNumber > 0) {
          filteredCSVData.splice(0, ignoreRowNumber);
          filteredPlacementAccountList.splice(0, ignoreRowNumber);
        }

        // Remove Invalid Phase Rows
        if (filteredHeaderList.findIndex(x => x == "phase") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => phase(filteredPlacementAccountList[rowIndex].phase));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phase(filteredPlacementAccountList[rowIndex].phase));
        }

        // Remove Invalid client code Rows
        if (filteredHeaderList.findIndex(x => x == "client_code") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => client_code(filteredPlacementAccountList[rowIndex].client_code));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => client_code(filteredPlacementAccountList[rowIndex].client_code));
        }

        // Remove Invalid phone number Rows
        if (filteredHeaderList.findIndex(x => x == "phone_no") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.phone_no));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex]?.phone_no));
        }

        // Remove Invalid Codebtor Phone Number Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_Phone1") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex].CoDebtor_Phone1));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => phone_no(filteredPlacementAccountList[rowIndex].CoDebtor_Phone1));
        }

        // Remove Invalid date Rows
        if (filteredHeaderList.findIndex(x => x == "delinquency_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].delinquency_date, localState?.delinquency_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].delinquency_date, localState?.delinquency_date));
        }

        // Remove Invalid date Rows --Delinquency_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Itemization_Date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.Itemization_Date, localState?.Itemization_Date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex]?.Itemization_Date, localState?.Itemization_Date));
        }

        // Remove Invalid date Rows --Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Delinquency_Itemization_Date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].delinquency_date, localState?.delinquency_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].delinquency_date, localState?.delinquency_date));
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].Itemization_Date, localState?.Itemization_Date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => FutureDateVal(filteredPlacementAccountList[rowIndex].Itemization_Date, localState?.Itemization_Date));
        }

        // Remove Invalid date Rows --last_payment_date
        if (filteredHeaderList.findIndex(x => x == "last_payment_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex].last_payment_date, localState?.last_payment_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex].last_payment_date, localState?.last_payment_date));
        }

        // Remove Invalid date Rows --service_date
        if (filteredHeaderList.findIndex(x => x == "service_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex].service_date, localState?.service_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateValidation(filteredPlacementAccountList[rowIndex].service_date, localState?.service_date));
        }

        // Remove Invalid date Rows --birth_date
        if (filteredHeaderList.findIndex(x => x == "birth_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => DateVal(filteredPlacementAccountList[rowIndex].birth_date, localState?.birth_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => DateVal(filteredPlacementAccountList[rowIndex].birth_date, localState?.birth_date));
        }

        // Remove Invalid balance Rows
        if (filteredHeaderList.findIndex(x => x == "total_balance") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => total_balance(filteredPlacementAccountList[rowIndex].total_balance));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => total_balance(filteredPlacementAccountList[rowIndex].total_balance));
        }

        // Remove Invalid date Rows --Payments_Applied_Since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Payments_applied_since_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Payments_applied_since_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Payments_applied_since_itemization_date));
        }

        // Remove Invalid date Rows --Credits_Applied_since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Credits_applied_since_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Credits_applied_since_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Credits_applied_since_itemization_date));
        }

        // Remove Invalid date Rows --balance_owed_on_itemization
        if (filteredHeaderList.findIndex(x => x == "Balance_owed_on_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Balance_owed_on_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Balance_owed_on_itemization_date));
        }

        // Remove Invalid date Rows --fees_added_since_itemization
        if (filteredHeaderList.findIndex(x => x == "Fees_added_since_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Fees_added_since_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Fees_added_since_itemization_date));
        }

        // Remove Invalid date Rows --interest_added_since_itemization
        if (filteredHeaderList.findIndex(x => x == "Interest_added_since_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Interest_added_since_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Interest_added_since_itemization_date));
        }

        //Remove Invalid date Rows --Payments_Credit_Applied_Since_Itemization_Date
        if (filteredHeaderList.findIndex(x => x == "Payments_Credits_applied_since_itemization_date") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Payments_Credits_applied_since_itemization_date));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].Payments_Credits_applied_since_itemization_date));
        }

        // Remove Invalid Fee balance Rows
        if (filteredHeaderList.findIndex(x => x == "fees") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].fees));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => currencyVal(filteredPlacementAccountList[rowIndex].fees));
        }

        // Remove Invalid zip Rows
        if (filteredHeaderList.findIndex(x => x == "zip") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].zip));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].zip));
        }

        // Remove Invalid state Rows
        if (filteredHeaderList.findIndex(x => x == "state") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => StateVal(filteredPlacementAccountList[rowIndex].state));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => StateVal(filteredPlacementAccountList[rowIndex].state));
        }

        // Remove Invalid account number Rows
        if (filteredHeaderList.findIndex(x => x == "account_no") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => validateAccountNumber(filteredPlacementAccountList[rowIndex].account_no));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => validateAccountNumber(filteredPlacementAccountList[rowIndex].account_no));
        }

        // Remove Invalid secondary_zip Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_zip") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].secondary_zip));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].secondary_zip));
        }

        // Remove Invalid Codebtor_Zip Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Zip") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].Codebtor_Zip));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => zipVal(filteredPlacementAccountList[rowIndex].Codebtor_Zip));
        }

        // Remove first_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "first_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].first_name, "first_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].first_name, "first_name"));
        }

        // Remove last_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "last_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].last_name, "last_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].last_name, "last_name"));
        }

        // Remove full_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "full_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].full_name, "full_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].full_name, "full_name"));
        }

        // Remove address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "address1") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address1, "address1"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address1, "address1"));
        }

        // Remove address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "address2") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address2, "address2"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].address2, "address2"));
        }

        // Remove city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "city") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].city, "city"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].city, "city"));
        }

        // Remove secondary_address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_address1") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address1, "secondary_address1"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address1, "secondary_address1"));
        }

        // Remove secondary_address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "secondary_address2") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address2, "secondary_address2"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].secondary_address2, "secondary_address2"));
        }

        // Remove citysecondary Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "citysecondary") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].citysecondary, "citysecondary"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].citysecondary, "citysecondary"));
        }

        // Remove email Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "email") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].email, "email"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].email, "email"));
        }

        // Remove drivers_license Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "drivers_license") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].drivers_license, "drivers_license"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].drivers_license, "drivers_license"));
        }

        // Remove tax_id Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "tax_id") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].tax_id, "tax_id"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].tax_id, "tax_id"));
        }
        // Remove guarantor_first_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_first_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_first_name, "guarantor_first_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_first_name, "guarantor_first_name"));
        }

        // Remove guarantor_last_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_last_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_last_name, "guarantor_last_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_last_name, "guarantor_last_name"));
        }

        // Remove guarantor_full_name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "guarantor_full_name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_full_name, "guarantor_full_name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].guarantor_full_name, "guarantor_full_name"));
        }

        // Remove comment Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "comment") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].comment, "comment"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].comment, "comment"));
        }

        // Remove account_type Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "account_type") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].account_type, "account_type"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].account_type, "account_type"));
        }

        // Remove other Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "other") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].other, "other"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].other, "other"));
        }
        // Remove location Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "location") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].location, "location"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].location, "location"));
        }

        // Remove codebtor_Full_Name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "codebtor_Full_Name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Full_Name, "codebtor_Full_Name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Full_Name, "codebtor_Full_Name"));
        }

        // Remove codebtor_Street_Address1 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "codebtor_Street_Address1") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Street_Address1, "codebtor_Street_Address1"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Street_Address1, "codebtor_Street_Address1"));
        }

        // Remove codebtor_Street_Address2 Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "codebtor_Street_Address2") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Street_Address2, "codebtor_Street_Address2"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].codebtor_Street_Address2, "codebtor_Street_Address2"));
        }

        // Remove Codebtor_City Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_City") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_City, "Codebtor_City"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_City, "Codebtor_City"));
        }

        // Remove Codebtor_Tax_ID Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "Codebtor_Tax_ID") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Tax_ID, "Codebtor_Tax_ID"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].Codebtor_Tax_ID, "Codebtor_Tax_ID"));
        }

        // Remove CoDebtor_First_Name Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_First_Name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_First_Name, "CoDebtor_First_Name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_First_Name, "CoDebtor_First_Name"));
        }

        // Remove city Invalid Rows
        if (filteredHeaderList.findIndex(x => x == "CoDebtor_Last_Name") > -1) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_Last_Name, "CoDebtor_Last_Name"));
          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => handleMaxLengthValidation(filteredPlacementAccountList[rowIndex].CoDebtor_Last_Name, "CoDebtor_Last_Name"));
        }

        //Remove Invalid Balance Rows
        if (mBalanceValue && +mBalanceValue > 0) {
          filteredCSVData = filteredCSVData.filter((item, rowIndex) =>
            filteredPlacementAccountList[rowIndex]?.total_balance >= parseFloat(mBalanceValue));

          filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) =>
            filteredPlacementAccountList[rowIndex].total_balance >= parseFloat(mBalanceValue));
        }

        // Remove ignored columns from csv data
        filteredCSVData.map((row, rowIndex) => {
          filteredCSVData[rowIndex] = filteredCSVData[rowIndex]?.filter((x, index) => filteredHeaderList[index] != "-1");
        });
        filteredHeaderList = filteredHeaderList.filter((x) => x != "-1");

        //Add FeeValue
        if (feeValue && +feeValue > 0) {
          if (filteredHeaderList.findIndex(x => x == "fees") == -1) { filteredHeaderList.push("fees"); }
          if (!filteredHeaderList.findIndex(x => x !== "fees")) { templateList.push(FeeObject); }
          filteredPlacementAccountList.map(x => { x.fees = feeValue; });
          filteredCSVData.map(x => { x?.push(feeValue); });
        }

        filteredCSVData.map((x, rowIndex) => {
          if ((filteredPlacementAccountList[rowIndex].delinquency_date?.length === dateLength || filteredPlacementAccountList[rowIndex].delinquency_date?.length === 20 || filteredPlacementAccountList[rowIndex].delinquency_date?.length === 17) && !filteredPlacementAccountList[rowIndex].delinquency_date?.includes('-') && !filteredPlacementAccountList[rowIndex].delinquency_date?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].delinquency_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].delinquency_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].delinquency_date?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].delinquency_date = dateValue;
          }
          if ((filteredPlacementAccountList[rowIndex].Itemization_Date?.length === dateLength || filteredPlacementAccountList[rowIndex].Itemization_Date?.length === 20 || filteredPlacementAccountList[rowIndex].Itemization_Date?.length === 17) && !filteredPlacementAccountList[rowIndex].Itemization_Date?.includes('-') && !filteredPlacementAccountList[rowIndex].Itemization_Date?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].Itemization_Date?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].Itemization_Date = dateValue;
          }
          if ((filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === dateLength || filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === 20 || filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.length === 17) && !filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.includes('-') && !filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].Codebtor_BirthDate?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].Codebtor_BirthDate = dateValue;
          }
          if ((filteredPlacementAccountList[rowIndex].last_payment_date?.length === dateLength || filteredPlacementAccountList[rowIndex].last_payment_date?.length === 20 || filteredPlacementAccountList[rowIndex].last_payment_date?.length === 17) && !filteredPlacementAccountList[rowIndex].last_payment_date?.includes('-') && !filteredPlacementAccountList[rowIndex].last_payment_date?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].last_payment_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].last_payment_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].last_payment_date?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].last_payment_date = dateValue;
          }
          if ((filteredPlacementAccountList[rowIndex].service_date?.length === dateLength || filteredPlacementAccountList[rowIndex].service_date?.length === 20 || filteredPlacementAccountList[rowIndex].service_date?.length === 17) && !filteredPlacementAccountList[rowIndex].service_date?.includes('-') && !filteredPlacementAccountList[rowIndex].service_date?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].service_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].service_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].service_date?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].service_date = dateValue;
          }
          if ((filteredPlacementAccountList[rowIndex].birth_date?.length === dateLength || filteredPlacementAccountList[rowIndex].birth_date?.length === 20 || filteredPlacementAccountList[rowIndex].birth_date?.length === 17) && !filteredPlacementAccountList[rowIndex].birth_date?.includes('-') && !filteredPlacementAccountList[rowIndex].birth_date?.includes('/')) {
            let dateValue = filteredPlacementAccountList[rowIndex].birth_date?.substring(0, 4) + '/' + filteredPlacementAccountList[rowIndex].birth_date?.substring(6, 2)?.slice(2) + '/' + filteredPlacementAccountList[rowIndex].birth_date?.substring(8, 4)?.slice(2);
            filteredPlacementAccountList[rowIndex].birth_date = dateValue;
          }
          if (filteredPlacementAccountList[rowIndex].zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].zip?.includes('-')) {
            let zipValue = ('0' + filteredPlacementAccountList[rowIndex].zip);
            filteredPlacementAccountList[rowIndex].zip = zipValue;
          }
          if (filteredPlacementAccountList[rowIndex].secondary_zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].secondary_zip?.includes('-')) {
            let zipValue = ('0' + filteredPlacementAccountList[rowIndex].secondary_zip);
            filteredPlacementAccountList[rowIndex].secondary_zip = zipValue;
          }
          if (filteredPlacementAccountList[rowIndex].Codebtor_Zip?.length === zipLength && !filteredPlacementAccountList[rowIndex].Codebtor_Zip?.includes('-')) {
            let zipValue = ('0' + filteredPlacementAccountList[rowIndex].Codebtor_Zip);
            filteredPlacementAccountList[rowIndex].Codebtor_Zip = zipValue;
          }
        });

        handleValidatePlacementRules(filteredCSVData, filteredPlacementAccountList, filteredPlacementAccountListCSV);
      } else {
        setShowDateIssueMessage(true);
      }
    } else {
      setAlertOpen(true);
    }
  };

  // --------------------------------------------  Get the Data for Placement Reject Logic --------------------------------------------

  useEffect(() => {
    if (phaseNumber === 1) {
      setPhase1(constant.FIRSTSTP);
    } else if (phaseNumber === 2) {
      setPhase1(constant.PREC);
    } else if (phaseNumber === 3) {
      setPhase1(constant.CONT);
    } else {
      setPhase1("MULP");
    }
  }, [phaseNumber]);

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "MULP";
    }
  }

  // This useEffect is used to check if the Client code is Exempt or not 
  useEffect(() => {
    (async () => {
      let request = {
        "clientCode": clientCode,
        "phaseCode": getPhaseString(phaseNumber)
      }
      await usePost<any>("Placements/ValidateClientCodeIsExempted", request).then((result) => {
        setIsClientCodeExempt(result?.data?.isClientCodeExempt);
      });
    })()
  }, []);

  // This useEffect is used to check if the Client code is Exempt or not 
  useEffect(() => {
    (async () => {
      let request = {
        "clientCode": clientCode,
        "phaseCode": getPhaseString(phaseNumber)
      }
      await usePost<any>("Placements/ValidateClientCodeIsExempted", request).then((result) => {
        setIsClientCodeExempt(result?.data?.isClientCodeIsExempted);
      });
    })()
  }, []);

  // Read all the Account numbers based on the user selection  
  useEffect(() => {
    let newAccountNumberList = [];
    let newClientCode = [];
    placementAccountList?.forEach((result) => {
      let currentAccountNumber: IAccountNumberList = {
        accountNumber: result?.account_no
      }
      newAccountNumberList?.push(currentAccountNumber);
    })

    // Check if the client code is Multi client or not if multi client choose the selected client code 
    if (clientCode !== "-2") {
      let currentClientCode: IClientCodeDetail = {
        dbT_CLIENT: clientCode
      }
      newClientCode?.push(currentClientCode);
    } else {
      // If the Selected Client code is Multi client code choose the Added client code from the clientCodeMappingList List 
      clientCodeMapList?.forEach((result) => {
        const selectedClientCode = result?.client_code?.split('-');
        let currentClientCode: IClientCodeDetail = {
          dbT_CLIENT: selectedClientCode[0]?.trim()
        }
        newClientCode?.push(currentClientCode);
      })
    }
    setAccountNumberList(newAccountNumberList);
    handleGetValidAccountsForRuleFive(newClientCode, newAccountNumberList);
  }, [])

  // Call the function to get the valid account number from the DB
  useEffect(() => {
    checkValidAccountNumbers();
  }, [accountNumberList])

  // This function to Get the Valid accounts number based on the Placement Reject Rule Five
  const handleGetValidAccountsForRuleFive = (filteredClientCodeList: IClientCodeDetail[], accountNumberList: IAccountNumberList[]) => {
    (async () => {
      let request = {
        "clientCodeDetails": filteredClientCodeList,
        "rejectAccountNumber": accountNumberList
      }
      await usePost<{ accountResultContracts: IAccountNumberList[] }>("Placements/RejectDupicateAccountNumber", request).then((result) => {
        setRejectDupicateAcResult(result?.data?.accountResultContracts);
      })
    })().finally(() => {
    })
  }

  // Function to get the Valid account numbers from the Database
  const checkValidAccountNumbers = () => {
    (async () => {
      let request = {
        "accountDetailContracts": accountNumberList
      }
      await usePost<{ accountResultContracts: IAccountNumberList[] }>('Placements/GetExistingAccountNumber', request).then((result) => {
        SetAccountNumberResult(result?.data["accountResultContracts"]);
      })
    })().finally(() => {
    })
  }

  // Call the function to get the valid state and zip from the DB
  useEffect(() => {
    checkValidStateAndZipCode();
  }, [stateAndZipCodeResult])

  // Read all the State and Zip code numbers based on the user selection  
  useEffect(() => {
    let newStateList = [];
    placementAccountList?.forEach((result) => {
      let currentStateAndZipCode: IStateAndZipCodeList = {
        state: result?.state,
        zipCode: result?.zip
      }
      newStateList?.push(currentStateAndZipCode);
    })
    setStateAndZipCodeResult(newStateList);
  }, [])

  // This useEffect is used to get the client code based on medical(02) 
  useEffect(() => {
    (async () => {
      await useFetch<IClientCodeDetail[]>("Placements/RejectNvNmStateCode").then((result) => {
        setRejectNvNmStatusCode(result?.data);
      });

      await useFetch<IPhonenumber[]>("Placements/GetPhoneNumberValidation").then((result) => {
        setPhoneNumber(result?.data);
      })

      await useFetch<ISpecialNameList[]>("Placements/GetSpecialNameCompliancesList").then((result) => {
        setSpecialNameList(result?.data);
      })

    })()
  }, []);

  // Get the Itemization Date
  useEffect(() => {
    let newItemizationDate = [];
    placementAccountList?.forEach((result) => {
      let currentStateAndItemizationDate: IItemizationDateAndState = {
        state: result?.state,
        itemizationDate: result?.Itemization_Date
      }
      newItemizationDate?.push(currentStateAndItemizationDate);
    })
    ItemizationDateAndStateValidation(newItemizationDate);
  }, [])

  const ItemizationDateAndStateValidation = (data: IItemizationDateAndState[]) => {
    (async () => {
      let request = {
        "rejectItemization": data
      }
      await usePost<{ itemizationResults: IItemizationResults[] }>('Placements/RejectInvalidItemizationDate', request).then((result) => {
        setItemizationDateStatueDate(result?.data["itemizationResults"]);
      })
    })().finally(() => {
    })
  }

  // Function to get valid State and Zip code
  const checkValidStateAndZipCode = () => {
    (async () => {
      let request = {
        "stateAndZipCodeLists": stateAndZipCodeResult
      }
      await usePost<{ stateCodeResult: IStateResult[], zipCodeResult: IZipCodeResult[] }>('Placements/GetStateandZipValidation', request).then((result) => {
        setState(result?.data["stateCodeResult"]);
        setZip(result?.data["zipCodeResult"]);
      })
    })().finally(() => {
    })
  }

  // --------------------------------------------   Validate Placement Reject Logic ------------------------------------

  // Rule - 01 - Function to validate CFPB Balance is matching or not
  function calculateCFPBBalance(indexValue: number, currentAccountData: any, filteredHeaderList: string[]) {
    let result;
    let cfpbFees;
    let cfpbCredit;
    let cfpbPayment;
    let cfpbInterest;
    let cfpbBalanceAsOf;
    let cfpbPaymentCredit;
    let totalBalance = placementAccountList[indexValue]?.total_balance;

    if (totalBalance?.toString()?.includes("$")) {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    } else {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    }

    // Check if the Balance_owed_on_itemization_date field has $
    if (placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.includes("$")) {
      cfpbBalanceAsOf = Number(placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbBalanceAsOf = Number(placementAccountList[indexValue]?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Interest_added_since_itemization_date field has $
    if (placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbInterest = Number(placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbInterest = Number(placementAccountList[indexValue]?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Fees_added_since_itemization_date field has $
    if (placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbFees = Number(placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbFees = Number(placementAccountList[indexValue]?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }

    // check if the "Credits applied since itemization date" and "Payments applied since itemization date" columns are selected
    if (filteredHeaderList?.findIndex(x => x === "Payments_Credits_applied_since_itemization_date") != -1) {
      // Check if the Payments_Credits_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPaymentCredit = Number(placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPaymentCredit = Number(placementAccountList[indexValue]?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    } else if ((filteredHeaderList?.findIndex(x => x == "Payments_applied_since_itemization_date") != -1) && (filteredHeaderList?.findIndex(x => x == "Credits_applied_since_itemization_date") != -1)) {
      // Check if the Payments_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPayment = Number(placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPayment = Number(placementAccountList[indexValue]?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
      // Check if the Credits_applied_since_itemization_date field has $
      if (placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbCredit = Number(placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbCredit = Number(placementAccountList[indexValue]?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    }

    // Calculate the CFPB Balance and validate the Total Balance and the Calculation is matching or not 
    if (filteredHeaderList?.findIndex(x => x == "Payments_Credits_applied_since_itemization_date") != -1) {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - cfpbPaymentCredit;
    } else {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - (cfpbPayment + cfpbCredit);
    }
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (result === totalBalance ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB balance not match'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return newResult;
  }

  // Rule - 02 - Function to validate "Existing account in Dispute, BK or Active"
  function OmitAccountNumberBasedOnRuleTwo(account: any, currentAccountData: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing account in Dispute, BK or Active'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }

  // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
  // need to have method to find the valid date format
  function OmitAccountNumberBasedOnRuleFour(dateValue: any, currentAccountData: any) {
    let itemizationDate = convertDateFormat(dateValue, constant.Itemization_Date);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === itemizationDate);
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB itemization date invalid'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return !itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === itemizationDate);
  }

  // Rule - 5 - Removed duplicate account no with same client code and created date
  function RemoveDuplicateAccountNumber(accountNumber: any, currentAccountData: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
    // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Dups-all fields matching'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Take the each account number from PlacementAccountList and validate that account numbers with accounts numbers from DB 
    return !rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
  }

  // Rule - 6 - Remove the Accounts with Special name reject due to compliances
  function RemoveAccountsWithSpecialName(indexValue: number, currentAccountData: any, filteredHeaderList: string[]) {
    let newResult;
    let finalFullName;
    let fullName = placementAccountList[indexValue]?.first_name + " " + placementAccountList[indexValue]?.last_name;
    // Check if the first name and last name is selected or full name is selected  
    if (filteredHeaderList?.findIndex(x => x == "full_name") && placementAccountList[indexValue]?.full_name?.length > 1) {
      // Filter the accounts for Special name reject due to compliances ---> specialNameList
      newResult = specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.full_name);
      // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
      if (newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
    }
    if (specialNameList?.some(result => result.specialName === fullName)) {
      if (filteredHeaderList?.findIndex(x => x == "first_name") && filteredHeaderList?.findIndex(x => x == "last_name") && fullName?.length > 1) {
        // Filter the accounts for Special name reject due to compliances ---> specialNameList
        newResult = specialNameList?.some(result => result.specialName === fullName);
        // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
        if (newResult) {
          const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
          const newData = { ...currentAccountData };
          newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
          if (!exist) {
            PlacementRejectedAccountsList?.push(newData);
          }
        }
      }
    }
    if (filteredHeaderList?.findIndex(x => x == "first_name") && placementAccountList[indexValue]?.first_name?.length > 1) {
      // Filter the accounts for Special name reject due to compliances ---> specialNameList
      newResult = specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.first_name);
      // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
      if (newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
    }

    if (filteredHeaderList?.findIndex(x => x == "last_name") && placementAccountList[indexValue]?.last_name?.length > 1) {
      // Filter the accounts for Special name reject due to compliances ---> specialNameList
      newResult = specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.last_name);
      // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
      if (newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Special name reject due to compliances'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
    }

    if (filteredHeaderList?.findIndex(x => x == "full_name") && placementAccountList[indexValue]?.full_name?.length > 1) {
      finalFullName = placementAccountList[indexValue]?.full_name;
    }
    if (specialNameList?.some(result => result.specialName === fullName)) {
      if (filteredHeaderList?.findIndex(x => x == "first_name") && filteredHeaderList?.findIndex(x => x == "last_name") && fullName?.length > 1) {
        finalFullName = placementAccountList[indexValue]?.first_name + "" + placementAccountList[indexValue]?.last_name;
      }
    }
    else if (specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.first_name)) {
      if (filteredHeaderList?.findIndex(x => x == "first_name") && placementAccountList[indexValue]?.first_name?.length > 1) {
        finalFullName = placementAccountList[indexValue]?.first_name;
      }
    }
    else if (specialNameList?.some(result => result.specialName === placementAccountList[indexValue]?.last_name)) {
      if (filteredHeaderList?.findIndex(x => x == "last_name") && placementAccountList[indexValue]?.last_name?.length > 1) {
        finalFullName = placementAccountList[indexValue]?.last_name;
      }
    } else {
      if (filteredHeaderList?.findIndex(x => x == "first_name") && filteredHeaderList?.findIndex(x => x == "last_name") && fullName?.length > 1) {
        finalFullName = placementAccountList[indexValue]?.first_name + "" + placementAccountList[indexValue]?.last_name;
      }
    }

    return !specialNameList?.some(result => result.specialName === finalFullName);
  }

  // Rule - 07 - DOB is a minor --> Funtion is used to find the DOB is minor or not 
  function OmitAccountsBasedOnRuleSeven(dateValue: any, currentAccountData: any) {
    let currentDateValue = convertDateFormat(dateValue, 'birth_date');
    const today = new Date();
    if (dateValue) {
      const birthDate = new Date(currentDateValue);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // Store the Rejected accounts list into to another state with rejected reason
      let newResult = (age >= 18 ? true : false);
      if (!newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'DOB is a minor'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
      return age >= 18 ? true : false;
    }
    return true;
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter State based on the status code
  function OmitStateBasedOnRuleEight(result: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = stateCode?.some(r => r?.stateCode === result);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !stateCode?.some(r => r?.stateCode === result);
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter Zip Code based on the status code
  function OmitZipCodeBasedOnRuleEight(result: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = zip?.some(r => r.zipCode === result);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !zip?.some(r => r.zipCode === result);
  }

  // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
  function OmitAccountNumberBasedOnRuleNine(deliquencyDate: any, fieldName: any, currentAccountData: any) {
    let deliqDate = convertDateFormat(deliquencyDate, fieldName);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (deliqDate === null || deliqDate === '1899/12/30' ? false : true);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid or missing Last Charge Date'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (deliqDate === null || deliqDate === '1899/12/30' ? false : true);
  }

  // Rule - 10 - Function to validate "Existing account in Dispute, BK or Active"
  function RejectAccountNumberBasedOnRuleTen(account: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing account in Dispute, BK or Active'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }


  // Rule Number 11 - Phone number validation 
  function RemovePhoneNumberBasedOnRuleEleven(rowPhoneNumber: any, currentAccountData: any) {
    const phoneValue = (rowPhoneNumber || "")?.replace(constant?.phoneRegexVal, "")?.slice(0, 7);

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = phoneNumber?.some(result => result?.phoneNo === phoneValue);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Exception:Kinder DNC PhoneNo Removed'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return !phoneNumber?.some(result => result?.phoneNo === phoneValue);
  }

  // Validation Pending
  // Rule - 12 - Exception:Age of the account is older than 5 years. --> Reject the account which is older then 5
  function OmitAccountsBasedOnRuleTwelve(dateString: any, currentAccountData: any) {
    let currentDateValue = convertDateFormat(dateString, constant.delinquency_date);
    const currentDate = new Date();
    const recordDate = new Date(currentDateValue); // Assuming 'date' is the date field in each record
    const differenceInYears = currentDate.getFullYear() - recordDate.getFullYear();

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (differenceInYears <= 5 ? true : false)
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Exception: Age of the account is older than 5 years.'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (differenceInYears <= 5 ? true : false);
  }

  // Rule - 14 - "NB but referral amount is less than PIF threshold" - Take each total number from placmentAccountList and validate the total due less than 5
  function RemovefilteredTotalBalance(balance: any, currentAccountData: any) {
    balance = Number(balance?.toString()?.replace(/\$|,/g, ""));

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (balance >= 5 ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'New Business but referral amount is less than PIF threshold'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return (balance >= 5 ? true : false);
  }

  // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
  function OmitAccountNumberBasedOnRuleFifteen(balance_owed_on_itemization: any, currentAccountData: any) {

    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = balance_owed_on_itemization <= 0 ? false : true;
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB Balance As Of Itemization Date <= 0'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }

    return balance_owed_on_itemization <= 0 ? false : true;
  }

  // Rule - 16 -  "Medical Accts in NV and NM" - Take each state from placmentAccountList and validate whether state code conatine "NV" and "NM"
  function RemoveNvNmStateCode(rowIndex: any, currentAccountData: any) {
    if (placementAccountList[rowIndex]?.state?.toUpperCase() === constant?.StateCodeNM || placementAccountList[rowIndex]?.state?.toUpperCase() === constant?.StateCodeNV) {
      // Check if the account should be rejected based on rejectNvNmStatusCode
      let newResult = rejectNvNmStatusCode?.some(result => placementAccountList[rowIndex]?.client_code === result?.dbT_CLIENT || clientCode === result?.dbT_CLIENT);

      if (newResult) {
        // Check if the account is not already in the rejected list
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);

        if (!exist) {
          // Add the account to the rejected list with a reason
          const newData = { ...currentAccountData };
          newData['Rejected Reason'] = 'Medical Accts in NV and NM';
          PlacementRejectedAccountsList?.push(newData);
        }
        return !rejectNvNmStatusCode?.some(result => placementAccountList[rowIndex]?.client_code === result?.dbT_CLIENT || clientCode != result?.dbT_CLIENT);
      }
    }
    return true;
  }

  // ---------------------------------- End ------------------------------------

  // Genarate the Rejected accounts based on the placement reject rule
  // function convertListToCSV(list, columnsToRemove) {
  //   // Extract column names from the first object
  //   const columnNames = Object?.keys(list[0]);

  //   // Filter out column names with null values and specified columns
  //   const nonNullColumns = columnNames?.filter(columnName => list?.some(item => item[columnName] !== null) && !columnsToRemove.includes(columnName));

  //   // Filter the list objects to include only non-null columns
  //   const filteredList = list.map(item =>
  //     nonNullColumns.reduce((obj, columnName) => {
  //       obj[columnName] = item[columnName];
  //       return obj;
  //     }, {})
  //   );

  //   const csvData = Papa.unparse(filteredList);
  //   const csvBlob = new Blob([csvData], { type: 'text/csv' });
  //   const csvUrl = URL.createObjectURL(csvBlob);
  //   // Returns the CSV records 
  //   return csvUrl;
  // }

  function convertListToCSV(list, columnsToRemove) {
    const originalColumnNames = Object.keys(list[0]);
    const alwaysIncludeColumn = 'Rejected Reason';
    const fullColumnSet = selectedHeaderList.map((column, index) =>
      column === '-1' ? `ignore_field_${index}` : column
    );
    const nonNullColumns = originalColumnNames.filter(
      columnName =>
        list.some(item => item[columnName] !== null) &&
        !columnsToRemove.includes(columnName)
    );
    const extendedColumns = [];
    for (let i = 0; i < fullColumnSet.length; i++) {
      if (fullColumnSet[i].startsWith('ignore_field')) {
        extendedColumns.push(fullColumnSet[i]);
      } else if (nonNullColumns.includes(fullColumnSet[i])) {
        extendedColumns.push(fullColumnSet[i]);
      }
    }
    if (originalColumnNames.includes(alwaysIncludeColumn) && !extendedColumns.includes(alwaysIncludeColumn)) {
      extendedColumns.push(alwaysIncludeColumn);
    }
    const filteredList = list.map(item =>
      extendedColumns.reduce((obj, columnName) => {
        obj[columnName] = columnName.startsWith('ignore_field') ? null : item[columnName];
        return obj;
      }, {})
    );
    const csvData = Papa.unparse(filteredList);
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    return csvUrl;
  }

  // Function to Convert the Date into "YYYY/DD/MM"
  function convertDateFormat(dateString, dateColumnName) {
    let finalDateValue = "";
    if (dateColumnName === constant.delinquency_date) {
      finalDateValue = findValidDateFormat(dateString, localState?.delinquency_date);
    }
    if (dateColumnName === constant.Itemization_Date) {
      finalDateValue = findValidDateFormat(dateString, localState?.Itemization_Date);
    }
    if (dateColumnName === constant.service_date) {
      finalDateValue = findValidDateFormat(dateString, localState?.service_date);
    }
    if (dateColumnName === constant.birth_date) {
      finalDateValue = findValidDateFormat(dateString, localState?.birth_date);
    }
    if (dateColumnName === constant.Codebtor_BirthDate) {
      finalDateValue = findValidDateFormat(dateString, localState?.Codebtor_BirthDate);
    }
    // Return the date in YYYY/MM/DD format
    return finalDateValue;
  }

  const splitConditions = /[-/\s]+/;
  // Get the date values based on the user identified date format
  function findValidDateFormat(dateString, dateFormatValues) {
    let month;
    let day;
    let year;
    let finalYear = 2000;
    const dateParts = dateString?.trim()?.split(splitConditions);
    if (dateFormatValues === "MM/DD/YYYY") {
      // Extract month, day, and year from the input string
      month = dateParts[0];
      day = dateParts[1];
      year = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[2]);
      }
    }
    if (dateFormatValues === "DD/MM/YYYY") {
      // Extract day, month, and year from the input string
      day = dateParts[0];
      month = dateParts[1];
      year = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[2]);
      }
    }
    if (dateFormatValues === "YYYY/MM/DD") {
      // Extract year, month, and day from the input string
      year = dateParts[0];
      month = dateParts[1];
      day = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[0]);
      }
    }
    if (dateFormatValues === "YYYY/DD/MM") {
      // Extract year, day and month from the input string
      year = dateParts[0];
      day = dateParts[1];
      month = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[0]);
      }
    }
    return `${year}/${month}/${day}`;
  }

  async function handleValidatePlacementRules(filteredCSVData: string[][], placementAccountList: IPlacementAccount[], filteredPlacementAccountListCSV: IPlacementAccount[]) {
    let filteredHeaderList = _.cloneDeep(selectedHeaderList);
    // Remove unmapped columns from csv data
    filteredCSVData.map((row, rowIndex) => {
      filteredCSVData[rowIndex] = filteredCSVData[rowIndex].filter((x, index) => filteredHeaderList[index] != undefined);
    });
    filteredHeaderList = filteredHeaderList.filter((x) => x != undefined);

    setIsAccountSubmited(true);

    //Rule - 1 - Ignore the accounts which is " CFPB balance not match " --> Based on Placement Reject Rules
    if (isClientCodeExempt === 0 || isClientCodeExempt === undefined && phase1 != constant.FIRSTSTP) {
      // Validating the placement rule - CFPB balance not match - Remove the invalid accounts
      if (filteredHeaderList.findIndex(x => x == "total_balance") > -1) {
        filteredCSVData = filteredCSVData?.filter((item, rowIndex) => calculateCFPBBalance(rowIndex, placementAccountList[rowIndex], filteredHeaderList));
        placementAccountList = placementAccountList.filter((item, rowIndex) => calculateCFPBBalance(rowIndex, placementAccountList[rowIndex], filteredHeaderList));
      }
    }

    // Rule - 2 - Ignore the accounts which is "Existing in Dispute, BK or Active" --> Based on Placement Reject Rules
    if (filteredHeaderList.findIndex(x => x == "account_no") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleTwo(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleTwo(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
    if (isClientCodeExempt === 0) {
      if (filteredHeaderList.findIndex(x => x == "Itemization_Date") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFour(placementAccountList[rowIndex]?.Itemization_Date, placementAccountList[rowIndex]));
        placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFour(placementAccountList[rowIndex]?.Itemization_Date, placementAccountList[rowIndex]));
      }
    }

    // Rule - 5 - Removed duplicate account no with same client code and created date  --> Pending 
    if (filteredHeaderList?.findIndex(x => x = "account_no") > -1) {
      filteredCSVData = filteredCSVData?.filter((item, rowIndex) => RemoveDuplicateAccountNumber(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemoveDuplicateAccountNumber(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 6 -  "Special name reject due to compliances"
    if ((filteredHeaderList?.findIndex(x => x = "full_name") > -1) || (filteredHeaderList.findIndex(x => x == "first_name") > -1 && filteredHeaderList.findIndex(x => x == "last_name") > -1)) {
      filteredCSVData = filteredCSVData?.filter((item, rowIndex) => RemoveAccountsWithSpecialName(rowIndex, placementAccountList[rowIndex], filteredHeaderList));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemoveAccountsWithSpecialName(rowIndex, placementAccountList[rowIndex], filteredHeaderList));
    }

    // Rule - 7 -  "DOB is a minor" --> Reject the Account which is Minor
    if (filteredHeaderList.findIndex(x => x == "birth_date") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountsBasedOnRuleSeven(placementAccountList[rowIndex]?.birth_date, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountsBasedOnRuleSeven(placementAccountList[rowIndex]?.birth_date, placementAccountList[rowIndex]));
    }

    // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules --> Pending 
    if (filteredHeaderList.findIndex(x => x == "state") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitStateBasedOnRuleEight(placementAccountList[rowIndex]?.state, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitStateBasedOnRuleEight(placementAccountList[rowIndex]?.state, placementAccountList[rowIndex]));
    }
    // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules --> Pending
    if (filteredHeaderList.findIndex(x => x == "zip") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitZipCodeBasedOnRuleEight(placementAccountList[rowIndex]?.zip, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitZipCodeBasedOnRuleEight(placementAccountList[rowIndex]?.zip, placementAccountList[rowIndex]));
    }

    // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
    if (filteredHeaderList.findIndex(x => x == "delinquency_date") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleNine(placementAccountList[rowIndex]?.delinquency_date, constant.delinquency_date, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleNine(placementAccountList[rowIndex]?.delinquency_date, constant.delinquency_date, placementAccountList[rowIndex]));
    }

    // Rule - 10 -  "Check client account no by numeric value. Same AccountNo active or BK"
    if (filteredHeaderList.findIndex(x => x == "account_no") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => RejectAccountNumberBasedOnRuleTen(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RejectAccountNumberBasedOnRuleTen(placementAccountList[rowIndex]?.account_no, placementAccountList[rowIndex]));
    }

    // Rule - 11 -  "Exception:Kinder DNC PhoneNo Removed"
    if (filteredHeaderList?.findIndex(x => x = "phone_no") > -1) {
      filteredCSVData = filteredCSVData?.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
    }

    // Rule - 12 - "Exception : Age of the account is older than 5 years"
    if (filteredHeaderList.findIndex(x => x == "delinquency_date") > -1) {
      filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountsBasedOnRuleTwelve(placementAccountList[rowIndex]?.delinquency_date, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountsBasedOnRuleTwelve(placementAccountList[rowIndex]?.delinquency_date, placementAccountList[rowIndex]));
    }


    // Rule - 14 - "NB but referral amount is less than PIF threshold" - Remove duplicate account when total due less than late fee or total due greater than late fee.
    if (filteredHeaderList?.findIndex(x => x = "total_balance") > -1) {
      filteredCSVData = filteredCSVData?.filter((item, rowIndex) => RemovefilteredTotalBalance(placementAccountList[rowIndex]?.total_balance, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList.filter((item, rowIndex) => RemovefilteredTotalBalance(placementAccountList[rowIndex]?.total_balance, placementAccountList[rowIndex]));
    }

    // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
    if (isClientCodeExempt === 0) {
      if (filteredHeaderList.findIndex(x => x == "Balance_owed_on_itemization_date") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFifteen(placementAccountList[rowIndex]?.Balance_owed_on_itemization_date, placementAccountList[rowIndex]));
        placementAccountList = placementAccountList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFifteen(placementAccountList[rowIndex]?.Balance_owed_on_itemization_date, placementAccountList[rowIndex]));
      }
    }

    // Rule - 16 -  "Medical Accts in NV and NM" - Reject if the address State = NV or NM and the CLT_CLASS = '02' (medical)
    if (filteredHeaderList?.findIndex(x => x = "state") > -1) {
      filteredCSVData = filteredCSVData?.filter((item, rowIndex) => RemoveNvNmStateCode(rowIndex, placementAccountList[rowIndex]));
      placementAccountList = placementAccountList?.filter((item, rowIndex) => RemoveNvNmStateCode(rowIndex, placementAccountList[rowIndex]));
    }

    const columnsToRemove = ['user_id', 'modifiedby'];

    const csvUrl = PlacementRejectedAccountsList?.length != 0 ? convertListToCSV(PlacementRejectedAccountsList, columnsToRemove) : null;
    setCSVUrl(csvUrl);

    // Push the final and valid data to Perview Page 
    history.push('\csvpreviewdata', {
      clientCode: clientCode, phaseNumber: phaseNumber, placementAccountList: placementAccountList, templateList: templateList, selectedClients: SelectedClients, placementList: placementAccountList,
      placementAccountCSVData: placementAccountCSVData, selectedHeaderList: selectedHeaderList, ignoreRows: ignoreRowNumber, placementAccountCSV: (isPreview === false ? filteredPlacementAccountListCSV : placementAccountCSV),
      filteredCSVData: filteredCSVData, filteredHeaderList: filteredHeaderList, clientList: clientList, mBalanceValue: (mBalanceValue), mappingValueCont: mappingValueCont, futureVal: futureVal,
      mBalanceChecked: (mBalanceChecked), feeChecked: feeChecked, feeValue: feeValue, mappingValuePrec: mappingValuePrec, mappingValue1STP: mappingValue1STP, uploadedFile: uploadedFile,
      checkCont: checkCont, check1STP: check1STP, checkPrec: checkPrec, clientCodeMappingList: clientCodeMapList, mappedClientCodeValue: mappedClientCodeValue, stateCodes: getStates,
      FileId: FileId, fileName: fileName, fileSize: fileSize, blobUri: blobUri, finalDateFormat: localState, isMappingTemplateSelected: isMappingTemplateSelected, placementMappingAccountList: placementAccountList,
      RuleRejectedAccounts: csvUrl, TotalRecordRejected: PlacementRejectedAccountsList?.length
    });

  };

  const handleDateErrorClose = () => {
    setShowDateIssueMessage(false);
    setFormatErrorList([]);
  }

  useEffect(() => {
    (async () => {
      await useFetch<IMappingTemplate[]>(`Placements/MappingList?user_id=${UserName}`).then((mappingList) => {
        mappingList.data.unshift(getManualTemplateFields());
        { state?.userAccessContext?.ftpFolder !== null && state?.userAccessContext?.ftpFolder !== "" && state?.userAccessContext?.ftpFolder !== undefined ? mappingList.data.unshift(getFTPTemplateFields()) : null }
        setMappingTemplateList(mappingList.data);
        setMappingList(mappingList.data);
      });
    })()
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await useFetch<{ uwfclT_FLD1: IUwfDetails[] }>(`Placements/GetClientItemizationDate?client_Code=${clientCode === "-2" ? null : clientCode}`).then((GetUWFDetails) => {
          setGetUwfCltData(GetUWFDetails.data[0].uwfclT_FLD1);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [GetUwfCltData])

  useEffect(() => {
    (async () => {
      if (placementAccountCSVData?.length > 0 && isPreview === false) {
        let headerList: string[] = [];
        let placementAccounts: IPlacementAccount[] = [];
        placementAccountCSVData.map(() => {
          placementAccounts.push(getEmptyPlacementAccount());
          headerList.push("-1");
        });
        setSelectedHeaderList(headerList);
        setPlacementAccountList(placementAccounts);
        setIsSubmit(true);
      } else if (isPreview === true) {
        setSelectedHeaderList(selectedHeaderList);
        setPlacementAccountList(placementAccountCSV);
        setIsSubmit(true);
      }
    })()
  }, [placementAccountCSVData]);

  useEffect(() => {
    let mappedHeaderList = _.cloneDeep(selectedHeaderList);
    let requiredHeaderList = _.cloneDeep(mappedRequireFields);
    mappedHeaderList.map(header => {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== header);
    });
    if (selectedHeaderList?.includes("first_name") || selectedHeaderList?.includes("last_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "full_name");
    } if (selectedHeaderList?.includes("full_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "first_name" && x.field_name !== "last_name");
    }
    if (selectedHeaderList?.includes("Payments_Credits_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Credits_applied_since_itemization_date" && x.field_name !== "Payments_applied_since_itemization_date");
    } if (selectedHeaderList?.includes("Credits_applied_since_itemization_date") && selectedHeaderList?.includes("Payments_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Payments_Credits_applied_since_itemization_date");
    } if (feeChecked == true && feeValue?.toString()?.length > 0 && requiredHeaderList.filter(x => x.field_name == "fees")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "fees");
    }
    setRequireFields(requiredHeaderList);
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappingList, mappedRequireFields, feeChecked, feeValue])

  useEffect(() => {
    (async () => {
      if (templateList?.length <= 0) {
        try {
          dispatch({ type: GlobalStateAction.Busy });
          await useFetch<ITemplateField[]>(`Placements/TemplateExclude?clientCode=${clientCode === "-2" ? null : clientCode}`).then((template) => {
            setRequireFields(template.data.filter(x => x.is_nullable == false));
            setMappedRequireFields(template.data.filter(x => x.is_nullable == false));
            if (+phaseNumber == 4) {
              const phaseObject = { data_type: 'string', display_name: 'Phase(*)', field_name: 'phase', is_nullable: false };
              template.data.push(phaseObject);
              template.data.map(x => {
                if (x.field_name == "phase") { x.is_required = true; x.is_nullable = false; }
              });
            }
            if (clientCode == "-2") {
              const ClientCodeObject = { data_type: 'string', display_name: 'Client Code(*)', field_name: 'client_code', is_nullable: false };
              template.data.push(ClientCodeObject);
              template.data.map(x => {
                if (x.field_name == "client_code") { x.is_required = true; x.is_nullable = false; }
              });
            }
            template.data.unshift(getIgnoreTemplateFields());
            setTemplateList(template.data);
            setFilteredTemplateList(template.data);
            if (isPreview === true) {
              let currentTemplateList = _.cloneDeep(template.data);
              selectedHeaderList.map(header => {
                currentTemplateList = currentTemplateList.filter(x => x.field_name != header
                  || x.field_name == "-1");
              });
              setFilteredTemplateList(currentTemplateList);
            }
          });
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Idle });
          dispatch({ type: GlobalStateAction.Error, error: ex });
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle });
        }
      }
    })()
  }, [templateList])

  useEffect(() => {
    if (!requireFields?.length && phaseVal == false && clientCodeVal == false) {
      setSavingEnable(true);
      setShowProgressBar(false);
    } else {
      setSavingEnable(false);
    }
    if (selectedHeaderList?.includes("fees")) {
      setFeeSelected(true);
      setFeeChecked(false);
      handleFeeValueChange('');
    } else {
      setFeeSelected(false);
    }
  }, [selectedHeaderList, requireFields, isSubmit, isAutoMapping, clientCodeVal, phaseVal]);

  useEffect(() => {
    if (!selectedHeaderList?.includes("phase") && +phaseNumber == 4) {
      setPhaseMsg("Phase(*)");
      setPhaseVal(true);
    } else if (selectedHeaderList?.includes("phase") && phaseNumber == 4
      && !mappingValue1STP?.length && !mappingValuePrec?.length && !mappingValueCont?.length) {
      setPhaseMsg("Phase(*)");
      setPhaseVal(true);
    } else if (selectedHeaderList?.includes("phase") && phaseNumber == 4
      && (mappingValue1STP?.length || mappingValuePrec?.length || mappingValueCont?.length)) {
      setPhaseVal(false);
    } else if (!selectedHeaderList?.includes("phase") && +phaseNumber !== 4) {
      setPhaseVal(false);
    }
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappingValue1STP, mappingValuePrec,
    mappingValueCont, check1STP, checkCont, checkPrec]);

  useEffect(() => {
    if (!selectedHeaderList?.includes("client_code") && clientCode == "-2") {
      setClientCodeMsg("Client Code(*),");
      setClientCodeVal(true);
    } else if (selectedHeaderList?.includes("client_code") && clientCode == "-2"
      && !mappedClientCodeValue?.length) {
      setClientCodeMsg("Client Code(*),");
      setClientCodeVal(true);
    } else if (selectedHeaderList?.includes("client_code") && clientCode == "-2"
      && mappedClientCodeValue?.length) {
      setClientCodeVal(false);
    } else if (!selectedHeaderList?.includes("client_code") && clientCode !== "-2") {
      setClientCodeVal(false);
    }
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappedClientCodeValue]);

  useEffect(() => {
    let validationList = _.cloneDeep(placementValidationList);
    if (ignoreRowNumber > 0) { validationList.splice(0, ignoreRowNumber); };
    if (selectedHeaderList?.includes("first_name") || selectedHeaderList?.includes("last_name")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      let nameVal = new RegExp(/^[a-zA-Z0-9. ]*$/);
      validationList.map((x, index) => {
        if ((!nameVal.test(x?.first_name))
          || (((x?.first_name === null || x?.first_name === "") || (x?.last_name === null || x?.last_name === "")) && (x?.full_name === null || x?.full_name === ""))) {
          let FirstNameValidation: IValidationError = {
            errorNumber: 1, field: "first_name", errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1 ? "Last Name, First Name OR Business Name (*)" : "")
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 1) <= -1) {
            currentValidationerrorList.push(FirstNameValidation);
            setValidationErrors(currentValidationerrorList);
          };
        }
        else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 1) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 1);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("last_name") || selectedHeaderList?.includes("first_name")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      let nameVal = new RegExp(/^[a-zA-Z0-9. ]*$/);
      validationList.map((x, index) => {
        if ((!nameVal.test(x?.last_name))
          || (((x?.last_name === null || x?.last_name === "") || (x?.first_name === null || x?.first_name === "")) && (x?.full_name === null || x?.full_name === ""))) {
          let LastNameValidation: IValidationError = {
            errorNumber: 2, field: "last_name", errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 1) <= -1 ? "Last Name, First Name OR Business Name (*)" : "")
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1) {
            currentValidationerrorList.push(LastNameValidation);
            setValidationErrors(currentValidationerrorList);
          };
        }
        else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 2) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 2);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("full_name") || selectedHeaderList?.includes("last_name") || selectedHeaderList?.includes("first_name")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (((x?.first_name === "" || x?.first_name === null) && (x?.full_name === null))
          || ((x?.last_name === "" || x?.last_name === null) && (x?.full_name === null))
          || ((x?.first_name === null || x?.first_name === "") && (x?.last_name === null || x?.last_name === "") && (x?.full_name === null))
        ) {
          let FullNameValidation: IValidationError = {
            errorNumber: 3, field: "full_name", errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 1) && currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1 ? "Last Name, First Name OR Business Name (*)" : "")
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1) {
            currentValidationerrorList.push(FullNameValidation);
            setValidationErrors(currentValidationerrorList);
          };
        }
        else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 3) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 3);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("phone_no")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.phone_no?.trim()?.length > 0 && !constant.phoneVal?.test(x?.phone_no)) {
          let phoneValidation: IValidationError = {
            errorNumber: 4, field: "phone_no", errorMessage: "Phone number must be 10 digits only"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 4) <= -1) {
            currentValidationerrorList.push(phoneValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 4) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 4);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("zip")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let zipValue = x?.zip?.trim();
        if (zipValue?.length === 4 && !zipValue?.includes('-')) { zipValue = ('0' + x?.zip); }
        if (zipValue?.length > 0 && !constant.zipReg.test(zipValue)) {
          let zipValidation: IValidationError = {
            errorNumber: 5, field: "zip", errorMessage: "Zip code must be either 5 or 9 digits"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 5) <= -1) {
            currentValidationerrorList.push(zipValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 5) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 5);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("secondary_zip")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let zipValue = x?.secondary_zip?.trim();
        if (zipValue?.length === 4 && !zipValue?.includes('-')) { zipValue = ('0' + x?.secondary_zip); }
        if (zipValue?.length > 0 && !constant.zipReg.test(zipValue)) {
          let secondaryZipValidation: IValidationError = {
            errorNumber: 6, field: "secondary_zip", errorMessage: "Column secondary_zip can contain data with a length greater than 10"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 6) <= -1) {
            currentValidationerrorList.push(secondaryZipValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 6) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 6);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Codebtor_Zip")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let zipValue = x?.Codebtor_Zip?.trim();
        if (zipValue?.length === 4 && !zipValue?.includes('-')) { zipValue = ('0' + x?.Codebtor_Zip); }
        if (zipValue?.length > 0 && !constant.zipReg.test(zipValue)) {
          let Codebtor_ZipValidation: IValidationError = {
            errorNumber: 30, field: "Codebtor_Zip", errorMessage: "Codebtor Zip code must be either 5 or 9 digits only"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 30) <= -1) {
            currentValidationerrorList.push(Codebtor_ZipValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 30) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 30);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("state")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.state?.trim()?.length > 0 && !getStates?.includes(x?.state?.trim()?.toUpperCase())) {
          let stateValidation: IValidationError = {
            errorNumber: 7, field: "state", errorMessage: "Please specify a valid 2-character state code"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 7) <= -1) {
            currentValidationerrorList.push(stateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 7) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 7);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("secondary_state")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.secondary_state?.trim()?.length > 0 && !getStates?.includes(x?.secondary_state?.trim()?.toUpperCase())) {
          let secondaryStateValidation: IValidationError = {
            errorNumber: 8, field: "secondary_state", errorMessage: "Column secondary_state can not contain data with a length greater than 2"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 8) <= -1) {
            currentValidationerrorList.push(secondaryStateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 8) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 8);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("total_balance")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.total_balance?.toString()?.trim();
        if (balance?.length > 0 && !constant.totalBalanceReg.test(balance)) {
          let balanceValidation: IValidationError = {
            errorNumber: 9, field: "total_balance", errorMessage: "Total balance must be Number"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 9) <= -1) {
            currentValidationerrorList.push(balanceValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 9) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 9);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Payments_applied_since_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Payments_applied_since_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let paymentDateValidation: IValidationError = {
            errorNumber: 10, field: "Payments_Applied_Since_Itemization_Date",
            errorMessage: 'Payments Applied Since Itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 10) <= -1) {
            currentValidationerrorList.push(paymentDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 10) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 10);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Credits_applied_since_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Credits_applied_since_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let creditsDateValidation: IValidationError = {
            errorNumber: 11, field: "Credits_Applied_since_Itemization_Date",
            errorMessage: 'Credits Applied since Itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 11) <= -1) {
            currentValidationerrorList.push(creditsDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 11) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 11);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Balance_owed_on_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Balance_owed_on_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let balanceDateValidation: IValidationError = {
            errorNumber: 12, field: "balance_owed_on_itemization",
            errorMessage: 'Balance itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 12) <= -1) {
            currentValidationerrorList.push(balanceDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 12) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 12);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Fees_added_since_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Fees_added_since_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let feesDateValidation: IValidationError = {
            errorNumber: 13, field: "fees_added_since_itemization",
            errorMessage: 'Fees Itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 13) <= -1) {
            currentValidationerrorList.push(feesDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 13) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 13);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Interest_added_since_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Interest_added_since_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let interestDateValidation: IValidationError = {
            errorNumber: 14, field: "interest_added_since_itemization",
            errorMessage: 'Interest Itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 14) <= -1) {
            currentValidationerrorList.push(interestDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 14) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 14);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Payments_Credits_applied_since_itemization_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.Payments_Credits_applied_since_itemization_date?.toString()?.trim();
        if (balance?.length > 0 && !constant.currencyReg.test(balance)) {
          let interestDateValidation: IValidationError = {
            errorNumber: 15, field: "Payments_Credits_applied_since_itemization_date",
            errorMessage: 'Payments Credits Applied Since Itemization Date must be Number'
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 15) <= -1) {
            currentValidationerrorList.push(interestDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 15) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 15);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("fees")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let balance = x.fees?.toString()?.trim();
        if (balance?.length > 0 && !constant.totalBalanceReg.test(balance)) {
          let balanceValidation: IValidationError = {
            errorNumber: 16, field: "fees", errorMessage: "Fee balance must be Number"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 16) <= -1) {
            currentValidationerrorList.push(balanceValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 16) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 16);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("delinquency_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.delinquency_date?.trim();
        if (x?.delinquency_date?.length === 8 && !x?.delinquency_date?.includes('-') && !x?.delinquency_date?.includes('/')) {
          dateValue = x?.delinquency_date?.substring(0, 4) + '/' + x?.delinquency_date?.substring(6, 2)?.slice(2) + '/' + x?.delinquency_date?.substring(6);
        }
        if (dateValue?.length >= 0 && !moment(dateValue, formats, true).isValid()) {
          let delinquencyValidation: IValidationError = {
            errorNumber: 17, field: "delinquency_date",
            errorMessage: 'Delinquency Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 17) <= -1) {
            currentValidationerrorList.push(delinquencyValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 17) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 17);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Itemization_Date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.Itemization_Date?.trim();
        if (x?.Itemization_Date?.length === 8 && !x?.Itemization_Date?.includes('-') && !x?.Itemization_Date?.includes('/')) {
          dateValue = x?.Itemization_Date?.substring(0, 4) + '/' + x?.Itemization_Date?.substring(6, 2).slice(2) + '/' + x?.Itemization_Date?.substring(6);
        }
        if (dateValue?.length >= 0 && !moment(dateValue, formats, true).isValid()) {
          let itemizationDateValidation: IValidationError = {
            errorNumber: 18, field: "Itemization_Date",
            errorMessage: 'Itemization Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 18) <= -1) {
            currentValidationerrorList.push(itemizationDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 18) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 18);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Delinquency_Itemization_Date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let itemizationVal = x?.Itemization_Date?.trim();
        if (x?.Itemization_Date?.length === 8 && !x?.Itemization_Date?.includes('-') && !x?.Itemization_Date?.includes('/')) {
          itemizationVal = x?.Itemization_Date?.substring(0, 4) + '/' + x?.Itemization_Date?.substring(6, 2)?.slice(2) + '/' + x?.Itemization_Date?.substring(6);
        }
        let delinquencyVal = x?.delinquency_date?.trim();
        if (x?.delinquency_date?.length === 8 && !x?.delinquency_date?.includes('-') && !x?.delinquency_date?.includes('/')) {
          delinquencyVal = x?.delinquency_date?.substring(0, 4) + '/' + x?.delinquency_date?.substring(6, 2).slice(2) + '/' + x?.delinquency_date?.substring(6);
        }
        if ((itemizationVal?.length >= 0 || delinquencyVal?.length > 0) && !moment(delinquencyVal, formats, true).isValid() && !moment(itemizationVal, formats, true).isValid()) {
          let DelinquencyItemizationDate: IValidationError = {
            errorNumber: 19, field: "Delinquency_Itemization_Date",
            errorMessage: 'Delinquency Itemization Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 19) <= -1) {
            currentValidationerrorList.push(DelinquencyItemizationDate);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 19) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 19);
            setValidationErrors(errorList);
          };
        };
      });
    };

    if (selectedHeaderList?.includes("last_payment_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.last_payment_date?.trim();
        if (x?.last_payment_date?.length === 8 && !x?.last_payment_date?.includes('-') && !x?.last_payment_date?.includes('/')) {
          dateValue = x?.last_payment_date?.substring(0, 4) + '/' + x?.last_payment_date?.substring(6, 2)?.slice(2) + '/' + x?.last_payment_date?.substring(6);
        }
        if (dateValue?.length >= 0 && !moment(dateValue, formats, true).isValid()) {
          let lastPaymentDateValidation: IValidationError = {
            errorNumber: 20, field: "last_payment_date",
            errorMessage: 'Last Payment Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 20) <= -1) {
            currentValidationerrorList.push(lastPaymentDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 20) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 20);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("service_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.service_date?.trim();
        if (x?.service_date?.length === 8 && !x?.service_date?.includes('-') && !x?.service_date?.includes('/')) {
          dateValue = x?.service_date?.substring(0, 4) + '/' + x?.service_date?.substring(6, 2)?.slice(2) + '/' + x?.service_date?.substring(6);
        }
        if (dateValue?.length >= 0 && !moment(dateValue, formats, true).isValid()) {
          let serviceDateValidation: IValidationError = {
            errorNumber: 21, field: "service_date",
            errorMessage: 'Service Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 21) <= -1) {
            currentValidationerrorList.push(serviceDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 21) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 21);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("birth_date")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.birth_date?.trimLeft()?.trimRight();
        if (x?.birth_date?.length === 8 && !x?.birth_date?.includes('-') && !x?.birth_date?.includes('/')) {
          dateValue = x?.birth_date?.substring(0, 4) + '/' + x?.birth_date?.substring(6, 2)?.slice(2) + '/' + x?.birth_date?.substring(6);
        }

        if (validationList?.some(v => v.birth_date?.trimLeft()?.trimRight() === "" || v.birth_date?.trimLeft()?.trimRight() === null) || !moment(dateValue, formats, true).isValid()) {
          let serviceDateValidation: IValidationError = {
            errorNumber: 22, field: "birth_date",
            errorMessage: 'Birth Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 22) <= -1) {
            currentValidationerrorList.push(serviceDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 22) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 22);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Codebtor_BirthDate")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        let dateValue = x?.Codebtor_BirthDate?.trim();
        if (x?.Codebtor_BirthDate?.length === 8 && !x?.Codebtor_BirthDate?.includes('-') && !x?.Codebtor_BirthDate?.includes('/')) {
          dateValue = x?.Codebtor_BirthDate?.substring(0, 4) + '/' + x?.Codebtor_BirthDate?.substring(6, 2)?.slice(2) + '/' + x?.Codebtor_BirthDate?.substring(6);
        }
        if (dateValue?.length > 0 && !moment(dateValue, formats, true).isValid()) {
          let serviceDateValidation: IValidationError = {
            errorNumber: 23, field: "Codebtor_BirthDate",
            errorMessage: 'Birth Date format should be like "MM/DD/YYYY" '
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1) {
            currentValidationerrorList.push(serviceDateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 23) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 23);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("CoDebtor_Phone1")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.CoDebtor_Phone1?.trim()?.length > 0 && !constant.phoneVal?.test(x?.CoDebtor_Phone1)) {
          let phoneValidation: IValidationError = {
            errorNumber: 24, field: "CoDebtor_Phone1", errorMessage: "Codebtor Phone number must be 10 digits only"
          }; if (currentValidationerrorList.findIndex(x => x.errorNumber == 24) <= -1) {
            currentValidationerrorList.push(phoneValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 24) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 24);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("Codebtor_State")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.Codebtor_State?.trim()?.length > 0 && !getStates?.includes(x?.Codebtor_State?.trim()?.toUpperCase())) {
          let stateValidation: IValidationError = {
            errorNumber: 26, field: "Codebtor_State", errorMessage: "Please specify a valid 2-character Codebtor State code"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 26) <= -1) {
            currentValidationerrorList.push(stateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 26) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 26);
            setValidationErrors(errorList);
          };
        };
      });
    }

    if (selectedHeaderList?.includes("account_no")) {
      let currentValidationerrorList = _.cloneDeep(validationErrors);
      validationList.map((x, index) => {
        if (x?.account_no?.length > 0 && !constant.accountNumberRegex.test(x?.account_no)) {
          let stateValidation: IValidationError = {
            errorNumber: 31, field: "account_no", errorMessage: "Account number should be alphanumeric values"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 31) <= -1) {
            currentValidationerrorList.push(stateValidation);
            setValidationErrors(currentValidationerrorList);
          };
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 31) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 31);
            setValidationErrors(errorList);
          };
        };
      });
    }

  }, [selectedHeaderList, isSubmit, isAutoMapping, ignoreRowNumber]);

  function handleIgnoreRowsCheck(event) {
    setIgnoreRows(event.target.checked);
    setIgnoreRowNumber(0);
  }

  function handleIgnoreRowNumberChange(value: string) {
    setIgnoreRowNumber(value === '' ? 0 : value || '');
  }

  function handle1STPCheck(event) {
    setCheck1STP(event.target.checked);
    setMappingValue1STP("");
    set1STPValues("");
  }

  function handleMappingValue1STPChange(event) {
    let value1STP = event.target.value;
    set1STPValues(value1STP);
    setMappingValue1STP(value1STP);
  }

  function handlePrecCheck(event) {
    setCheckPrec(event.target.checked);
    setMappingValuePrec("");
    setPrecValues("");
  }

  function handleMappingValuePrecChange(event) {
    let valuePrec = event.target.value;
    setPrecValues(valuePrec);
    setMappingValuePrec(valuePrec);
  }

  function handleMappingValueContChange(event) {
    let valueCont = event.target.value;
    setContValues(valueCont);
    setMappingValueCont(valueCont);
  }

  function handleContCheck(event) {
    setCheckCont(event.target.checked);
    setMappingValueCont("");
    setContValues("");
  }

  const setPrecValues = (valuePrec: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      currPlacementAccountList.map((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (valuePrec == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.PREC) {
          pa.phase = "";
        } if (valuePrec == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.PREC) {
          pa.phase = phaseLiteral;
        } else if (valuePrec && phaseLiteral == valuePrec && pa.phase != constant.PREC) {
          pa.phase = constant.PREC;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const setContValues = (valueCont: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      currPlacementAccountList.map((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (valueCont == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.CONT) {
          pa.phase = "";
        }
        if (valueCont == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.CONT) {
          pa.phase = phaseLiteral;
        } else if (valueCont && phaseLiteral == valueCont && pa.phase != constant.CONT) {
          pa.phase = constant.CONT;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const set1STPValues = (value1STP: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      currPlacementAccountList.map((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (value1STP == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.FIRSTSTP) {
          pa.phase = "";
        }
        if (value1STP == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.FIRSTSTP) {
          pa.phase = phaseLiteral;
        } else if (value1STP && phaseLiteral == value1STP && pa.phase != constant.FIRSTSTP) {
          pa.phase = constant.FIRSTSTP;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const getManualTemplateFields = () => {
    let ManualTemplateFields: IMappingTemplate = {
      balance: 0,
      client_code: 'Mapping',
      fees: 0,
      map_name: 'Manual',
      placement_map_id: -1,
      rows_to_ignore: 1,
    };
    return ManualTemplateFields;
  }

  const getFTPTemplateFields = () => {
    let ManualTemplateFields: IMappingTemplate = {
      balance: 0,
      client_code: "Mapping",
      fees: 0,
      map_name: globalTemplateName,
      placement_map_id: globalTemplateId,
      rows_to_ignore: 1,
    };
    return ManualTemplateFields;
  }

  const handleChange = (index, event, selected) => {
    event.preventDefault();
    handleHeaderChange(index, (selected?.field_name != undefined ? selected.field_name : "-1"), (selected?.data_type != undefined ? selected.data_type : "-1"));
  }

  const handleHeaderChange = (index: number, field_name: string, data_type: string) => {
    let headerList: string[] = _.cloneDeep(selectedHeaderList);
    let headerValues = headerList[index];
    headerList[index] = field_name;
    setSelectedHeaderList(headerList);
    let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
    if (field_name != "-1" && field_name != "phase" && field_name != "client_code" && data_type != "money" && field_name !== "Delinquency_Itemization_Date") {
      // Remove old mappings for the specific field_name
      const fieldNameToRemove = headerValues; // Field name to remove the mapping for old mapping

      // Make the old mappings values into NULL when the user override the same field mappings
      currPlacementAccountList.forEach((account) => {
        account[fieldNameToRemove] = null;
      });

      placementAccountCSVData.map((element, rowIndex) => {
        eval('currPlacementAccountList[' + rowIndex + '].' + field_name + ' = placementAccountCSVData[' + rowIndex + '][' + index + ']');
      });

      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name == "Delinquency_Itemization_Date") {
      placementAccountCSVData.map((element, rowIndex) => {
        currPlacementAccountList[rowIndex].delinquency_date = placementAccountCSVData[rowIndex][index];
        currPlacementAccountList[rowIndex].Itemization_Date = placementAccountCSVData[rowIndex][index];
      });
      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name == "phase") {
      placementAccountCSVData.map((element, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][index];
        currPlacementAccountList[rowIndex].phase = getPhase(phaseLiteral);
      });
      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name == "client_code") {
      placementAccountCSVData.map((element, rowIndex) => {
        let clientCodeLiteral = placementAccountCSVData[rowIndex][index];
        currPlacementAccountList[rowIndex].client_code = getClientCode(clientCodeLiteral);
      });
      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
      if (mappedClientCodeValue?.length) {
        let clientCodeMappingList = _.cloneDeep(clientCodeMapList);
        let clientCodeIndex = headerList.findIndex((x) => x == "client_code");
        setClientCodeInPlacementAccountList(clientCodeMappingList, clientCodeIndex);
      }
    }
    else if (data_type == "money") {
      placementAccountCSVData.map((element, rowIndex) => {
        let balance = placementAccountCSVData[rowIndex][index];
        if (balance == null || balance == "" || balance.trim() == "$-" || balance.trim() == "$") {
          eval('currPlacementAccountList[' + rowIndex + '].' + field_name + ' = "0.00"');
        } else {
          eval('currPlacementAccountList[' + rowIndex + '].' + field_name + ' = balance');
        }
      });
      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name === "-1") {
      // Remove old mappings for the specific field_name
      const fieldNameToRemove = headerValues; // Field name to remove the mapping for old mapping

      // Make the old mappings values into NULL when the user override the same field mappings
      currPlacementAccountList.forEach((account) => {
        account[fieldNameToRemove] = null;
      });

      setPlacementAccountList(currPlacementAccountList);
      setPlacementValidationList(currPlacementAccountList);
    }
    let currentTemplateList = _.cloneDeep(templateList);
    headerList.map(header => {
      currentTemplateList = currentTemplateList.filter(x => x.field_name != header
        || x.field_name == "-1");
    });
    setFilteredTemplateList(currentTemplateList);
    setMappingTemplateVisibility(headerList);
    setSelectedField(field_name == "-1" ? selectedField : field_name);
  };

  function mapTemplateToHeaderList(placementMappingList: IPlacementMapping[], currentMappingList: IClientCodeMap[]) {
    let headerList: string[] = _.cloneDeep(selectedHeaderList);
    let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
    let updatedValidationErrors: IValidationError[] = [...validationErrors];
    placementMappingList.map(x => {
      headerList[x.source_ordinal_position] = x.field_name;
      const mapping = mappedRequireFields.find(mapping => mapping.field_name === x.field_name);
      if (mapping || x?.field_name === "delinquency_date") {
        const columnValues = placementAccountCSVData.map(row => row[x.source_ordinal_position]);
        if (columnValues.every(value => value === null || value === undefined)) {
          updatedValidationErrors.push({
            errorNumber: 123,
            field: x.field_name,
            errorMessage: x?.display_name + " Required Column not mapped"
          });
        }
      }
      if (x.field_name != "-1" && x.field_name != "phase" && x.field_name != "client_code" && x.data_type != "money" && x.field_name !== "Delinquency_Itemization_Date") {
        placementAccountCSVData.map((element, rowIndex) => {
          eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = placementAccountCSVData[' + rowIndex + '][' + x.source_ordinal_position + ']');
        });
      } else if (x.field_name == "Delinquency_Itemization_Date") {
        placementAccountCSVData.map((element, rowIndex) => {
          currPlacementAccountList[rowIndex].delinquency_date = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          currPlacementAccountList[rowIndex].Itemization_Date = placementAccountCSVData[rowIndex][x.source_ordinal_position];
        });
        setPlacementAccountList(currPlacementAccountList);
        setPlacementValidationList(currPlacementAccountList);
      } else if (x.field_name == "phase") {
        placementAccountCSVData.map((element, rowIndex) => {
          let phaseLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          currPlacementAccountList[rowIndex].phase = getPhase(phaseLiteral);
        });
      } else if (x.field_name == "client_code") {
        placementAccountCSVData.map((element, rowIndex) => {
          let clientCodeLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          currPlacementAccountList[rowIndex].client_code = getAutoClientCode(clientCodeLiteral, currentMappingList);
        });
      } else if (x.data_type == "money") {
        placementAccountCSVData.map((element, rowIndex) => {
          let balance = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          if (balance == null || balance == "" || balance.trim() == "$-" || balance.trim() == "$") {
            eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = "0.00"');
          } else {
            eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = balance');
          }
        });
        setPlacementAccountList(currPlacementAccountList);
        setPlacementValidationList(currPlacementAccountList);
      }

      if (x.field_name === constant.delinquency_date || x.field_name === constant.Itemization_Date || x.field_name === constant.last_payment_date || x.field_name === constant.birth_date ||
        x.field_name === constant.service_date || x.field_name === constant.Codebtor_BirthDate) {
        setLocalState((prevState) => ({
          ...prevState,
          [x.field_name]: x.date_format,
        }));
      }
      setSelectedField(x.field_name);
    });
    setValidationErrors(updatedValidationErrors);
    setPlacementAccountList(currPlacementAccountList);
    setPlacementValidationList(currPlacementAccountList);
    setSelectedHeaderList(headerList);
    let currentTemplateList = _.cloneDeep(templateList);
    headerList.map(header => {
      currentTemplateList = currentTemplateList.filter(x => x.field_name != header
        || x.field_name == "-1");
    });
    setFilteredTemplateList(currentTemplateList);
    setMappingTemplateVisibility(headerList);
  }

  // function mapTemplateToHeaderList(placementMappingList: IPlacementMapping[], currentMappingList: IClientCodeMap[]) {
  //   let headerList: string[] = _.cloneDeep(selectedHeaderList);
  //   let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
  //   placementMappingList.map(x => {
  //     headerList[x.source_ordinal_position] = x.field_name;
  //     if (x.field_name != "-1" && x.field_name != "phase" && x.field_name != "client_code" && x.data_type != "money" && x.field_name !== "Delinquency_Itemization_Date") {
  //       placementAccountCSVData.map((element, rowIndex) => {
  //         eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = placementAccountCSVData[' + rowIndex + '][' + x.source_ordinal_position + ']');
  //       });
  //     } else if (x.field_name == "Delinquency_Itemization_Date") {
  //       placementAccountCSVData.map((element, rowIndex) => {
  //         currPlacementAccountList[rowIndex].delinquency_date = placementAccountCSVData[rowIndex][x.source_ordinal_position];
  //         currPlacementAccountList[rowIndex].Itemization_Date = placementAccountCSVData[rowIndex][x.source_ordinal_position];
  //       });
  //       setPlacementAccountList(currPlacementAccountList);
  //       setPlacementValidationList(currPlacementAccountList);
  //     } else if (x.field_name == "phase") {
  //       placementAccountCSVData.map((element, rowIndex) => {
  //         let phaseLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
  //         currPlacementAccountList[rowIndex].phase = getPhase(phaseLiteral);
  //       });
  //     } else if (x.field_name == "client_code") {
  //       placementAccountCSVData.map((element, rowIndex) => {
  //         let clientCodeLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
  //         currPlacementAccountList[rowIndex].client_code = getAutoClientCode(clientCodeLiteral, currentMappingList);
  //       });
  //     } else if (x.data_type == "money") {
  //       placementAccountCSVData.map((element, rowIndex) => {
  //         let balance = placementAccountCSVData[rowIndex][x.source_ordinal_position];
  //         if (balance == null || balance == "" || balance.trim() == "$-" || balance.trim() == "$") {
  //           eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = "0.00"');
  //         } else {
  //           eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = balance');
  //         }
  //       });
  //       setPlacementAccountList(currPlacementAccountList);
  //       setPlacementValidationList(currPlacementAccountList);
  //     }

  //     if (x.field_name === constant.delinquency_date || x.field_name === constant.Itemization_Date || x.field_name === constant.last_payment_date || x.field_name === constant.birth_date ||
  //       x.field_name === constant.service_date || x.field_name === constant.Codebtor_BirthDate) {
  //       setLocalState((prevState) => ({
  //         ...prevState,
  //         [x.field_name]: x.date_format,
  //       }));
  //     }
  //     setSelectedField(x.field_name);
  //   });
  //   setPlacementAccountList(currPlacementAccountList);
  //   setPlacementValidationList(currPlacementAccountList);
  //   setSelectedHeaderList(headerList);
  //   let currentTemplateList = _.cloneDeep(templateList);
  //   headerList.map(header => {
  //     currentTemplateList = currentTemplateList.filter(x => x.field_name != header
  //       || x.field_name == "-1");
  //   });
  //   setFilteredTemplateList(currentTemplateList);
  //   setMappingTemplateVisibility(headerList);
  // }

  const setMappingTemplateVisibility = (headerList: string[]) => {
    if (headerList.findIndex(x => x && x != "-1") > -1) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }

  function getPhase(phaseLiteral: string): string {
    if (phaseLiteral && mappingValue1STP && phaseLiteral == mappingValue1STP) {
      return constant.FIRSTSTP;
    }
    if (phaseLiteral && mappingValueCont && phaseLiteral == mappingValueCont) {
      return constant.CONT;
    }
    if (phaseLiteral && mappingValuePrec && phaseLiteral == mappingValuePrec) {
      return constant.PREC;
    }
    if (phaseLiteral == constant.FIRSTSTP || phaseLiteral == constant.CONT || phaseLiteral == constant.PREC) {
      return phaseLiteral;
    }
    return "";
  }

  function getClientCode(clientCodeLiteral: string): string {
    if (clientCodeMapList && clientCodeMapList.findIndex(x => x.client_indicators == clientCodeLiteral) > -1) {
      return clientCodeMapList.find(x => x.client_indicators == clientCodeLiteral).client_indicators;
    } else if (clientList && clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
      return clientList.find(x => x.clT_CODE == clientCodeLiteral).clT_CODE;
    }
    return "";
  }

  function getAutoClientCode(clientCodeLiteral: string, currentClientList: IClientCodeMap[]): string {
    if (currentClientList && currentClientList.findIndex(x => x.client_indicators?.toString() == clientCodeLiteral?.toString()) > -1) {
      let clientCode = currentClientList.find(x => x.client_indicators == clientCodeLiteral).client_code;
      return clientCode?.substring(0, 6);
    } else if (clientList && clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
      return clientList.find(x => x.clT_CODE == clientCodeLiteral).clT_CODE;
    }
    return "";
  }

  const handleMBalanceCheck = (event) => {
    setMBalanceChecked(event.target.checked);
    handleMBalanceChange('');
  };

  const handleFeeCheck = (event) => {
    setFeeChecked(event.target.checked);
    handleFeeValueChange('');
  };

  const handleFeeValueChange = (value: string): void => {
    setFeeValue(value === '0' || '' ? '' : value || '');
  };

  const handleMBalanceChange = (value: string): void => {
    setMBalanceValue(value === '0' || '' ? '' : value || '');
  };

  const getEmptyPlacementAccount = (): IPlacementAccount => {
    let placementAccount: IPlacementAccount = {
      phaseNumber: null,
      phase: null,
      client_code: null,
      account_no: null,
      first_name: null,
      last_name: null,
      full_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_no: null,
      total_balance: null,
      delinquency_date: null,
      secondary_address1: null,
      secondary_address2: null,
      citysecondary: null,
      secondary_state: null,
      secondary_zip: null,
      secondary_phone_no: null,
      email: null,
      drivers_license: null,
      tax_id: null,
      birth_date: null,
      past_due_balance: null,
      current_balance: null,
      equipment_charges: null,
      contract_charges: null,
      fees: null,
      last_payment_date: null,
      guarantor_first_name: null,
      guarantor_last_name: null,
      guarantor_full_name: null,
      comment: null,
      account_type: null,
      other: null,
      user_id: state.userAccessContext?.id,
      modifiedby: state.userAccessContext?.userName,
      file_name: null,
      contact_name: null,
      service_date: null,
      location: null,
      codebtor_Full_Name: null,
      codebtor_Street_Address1: null,
      codebtor_Street_Address2: null,
      Codebtor_City: null,
      Codebtor_State: null,
      Codebtor_Zip: null,
      Codebtor_BirthDate: null,
      Codebtor_Tax_ID: null,
      CoDebtor_First_Name: null,
      CoDebtor_Last_Name: null,
      CoDebtor_Phone1: null,
      CoDebtor_Phone2: null,
      Payments_applied_since_itemization_date: null,
      Credits_applied_since_itemization_date: null,
      Itemization_Date: null,
      Balance_owed_on_itemization_date: null,
      Fees_added_since_itemization_date: null,
      Interest_added_since_itemization_date: null,
      Payments_Credits_applied_since_itemization_date: null
    }
    return placementAccount
  }

  const getMappingTemplate = () => {
    (async () => {
      await useFetch<IMappingTemplate[]>(`Placements/MappingList?user_id=${UserName}`).then((mapping) => {
        mapping.data.unshift(getManualTemplateFields());
        { state?.userAccessContext?.ftpFolder !== null && state?.userAccessContext?.ftpFolder !== "" && state?.userAccessContext?.ftpFolder !== undefined ? mapping.data.unshift(getFTPTemplateFields()) : null }
        setMappingList(mapping.data);
        setMappingTemplateList(mapping.data);
      });
    })()
  }

  async function handleTemplateChange(event, selected) {
    setShowProgressBar(true);
    if (selected?.placement_map_id == -1 || selected?.placement_map_id == undefined) {
      setIsMappingTemplateSelected(false);
      GetPlacementDateFormat();
      resetAutoMappingTemplate();
      setShowProgressBar(false);
    } else {
      setIsMappingTemplateSelected(true);
      let currentMappingList = _.cloneDeep(clientCodeMapList);
      await useFetch<IClientCodeMap[]>(`ClientPlacement/GetMapTemplate?placement_map_id=${selected?.placement_map_id}`).then((r) => {
        r?.data?.map((client) => {
          let newClientCodeMapping: IClientCodeMap = {
            placement_map_id: client.placement_map_id, client_code: client.client_code,
            client_indicators: client.client_indicators
          };
          currentMappingList.push(newClientCodeMapping);
        });
        setMappedClientCodeValue(r?.data[0]?.client_indicators);
      });
      setClientCodeMapList(currentMappingList);
      await useFetch<IPlacementMapping[]>(`Placements/MappingGet?placement_map_id=${selected?.placement_map_id}`).then((m) => {
        mapTemplateToHeaderList(m.data, currentMappingList);
      }).finally(() => {
        setShowProgressBar(false);
      })
      handleTempChange(selected);
      setTemplateId(selected?.placement_map_id);
    }
  };

  async function handleTempChange(selected) {
    let currentMappingList = _.cloneDeep(mappingTemplateList);
    currentMappingList = currentMappingList.filter(x => x.placement_map_id != selected?.placement_map_id);
    setMappingList(currentMappingList);
    setFeeChecked(selected?.fees > 0 ? true : false);
    setMBalanceChecked(selected?.balance > 0 ? true : false);
    setFeeValue(selected?.fees === null ? feeValue : selected?.fees);
    setMBalanceValue(selected?.balance === null ? mBalanceValue : selected?.balance);
    setValid(selected?.placement_map_id ? true : false);
    setMapped(selected?.placement_map_id);
    setIsAutoMapping(true);
  };

  const resetAutoMappingTemplate = () => {
    let headerList: string[] = [];
    let placementAccounts: IPlacementAccount[] = [];
    placementAccountCSVData.map((x) => {
      placementAccounts.push(getEmptyPlacementAccount());
      headerList.push("-1");
    });
    let currentTemplateList = _.cloneDeep(templateList);
    headerList.map(header => {
      currentTemplateList = currentTemplateList.filter(x => x.field_name != header);
    });
    setFilteredTemplateList(currentTemplateList);
    setMappingList(mappingTemplateList);
    setSelectedHeaderList(headerList);
    setPlacementValidationList(placementAccounts);
    setPlacementAccountList(placementAccounts);
    setValidationErrors([]);
    setClientCodeMapList([]);
    setFeeChecked(false);
    setMBalanceChecked(false);
    setValid(false);
    setFeeValue('');
    setMBalanceValue('');
  }

  const getIgnoreTemplateFields = () => {
    let ignoreTemplateField: ITemplateField = {
      field_name: "-1",
      display_name: "--Ignore Field--",
      data_type: "varchar",
      is_nullable: true,
      is_visible: true,
      is_required: false,
      field_length: -1,
    };
    return ignoreTemplateField;
  }

  function ignoredCellClassName(rowIndex: number): string {
    if (!state.busy && rowIndex < ignoreRowNumber) {
      return classes.ignoredRow;
    }
  }

  function phase(phaseString: string): boolean {
    var phaseStringValue = phaseString.trim();
    return phaseStringValue == constant.PREC || phaseStringValue == constant.CONT
      || phaseStringValue == constant.FIRSTSTP;
  }

  function total_balance(balance: any): boolean {
    var totalBalanceValue = balance?.toString()?.trim();
    return (constant.totalBalanceReg.test(totalBalanceValue) ? true : false);
  }

  function phone_no(phone: string): boolean {
    var PhoneValue;
    if (phone !== null || phone !== "") {
      PhoneValue = phone?.replace(constant.phoneRegexVal, "");
    }
    PhoneValue = PhoneValue?.trim();
    return (constant.phoneVal.test(PhoneValue) ? true : false);
  }

  function client_code(clientCodeString: string): boolean {
    var ClientCodeValue = clientCodeString?.trim();
    return clientList.findIndex(x => x.clT_CODE == ClientCodeValue) > -1;
  }

  function currencyVal(balance: any): boolean {
    var balanceValue = balance?.toString()?.trim();
    return (constant.currencyReg?.test(balanceValue) ? true : false);
  }

  function FutureDateVal(dateString: string, dateFieldName: string): boolean {
    let dateValue = dateString?.trimLeft()?.trimRight();
    var last4 = "";
    let yearValue;
    var newDateFormat = "";
    const splitConditions = /[-/\s]+/;
    if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
      dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
    }
    if (dateString?.length === 8 && !dateString?.includes('-') && !dateString?.includes('/')) {
      last4 = dateValue?.substring(0, 4);
      yearValue = Number(last4);
    } else {
      if (dateValue != undefined) {
        const dateParts = dateValue?.split(splitConditions);
        if (dateFieldName === "YYYY/MM/DD" || dateFieldName === "YYYY/DD/MM") {
          last4 = dateParts[0];
          yearValue = Number(last4);
        } else {
          last4 = dateParts[2];
          yearValue = Number(last4);
          newDateFormat = dateParts[0] + "-" + dateParts[1] + "-" + dateParts[2]
        }
      }
    }
    let givenDate;
    let currentDate;
    if (dateFieldName === "DD/MM/YYYY") {
      const [day, month, year] = newDateFormat?.split(splitConditions); // get the day month and year based on the file format
      currentDate = new Date(); // gets the current date
      givenDate = new Date(`${year}-${month}-${day}`); // replace with the given date you want to check
    }

    var SubtractedDate = Number(moment(new Date()).subtract(100, 'year')?.format('YYYY'));
    let invalidDate: boolean;
    if (last4?.length === 2) {
      invalidDate = true;
    } else {
      invalidDate = ((yearValue < SubtractedDate) ? false : true);
    }
    let result: any;
    // validate the each records based on the source date format choosen by the user or from the template
    if (dateFieldName === "DD/MM/YYYY") {
      result = ((moment(dateValue, constant?.dayMonthFormat, true).isValid()) && !((givenDate > currentDate)) ? true : false);
    } else if (dateFieldName === "YYYY/MM/DD") {
      result = ((moment(dateValue, constant?.yearMonthFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    } else if (dateFieldName === "YYYY/DD/MM") {
      result = ((moment(dateValue, constant?.yearDateFormat, true).isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    } else if (dateFieldName === "MM/DD/YYYY") {
      result = ((moment(dateValue, constant?.monthDayFormat, true).isValid()) && (!moment(newDateFormat)?.isAfter(new Date())) ? true : false);
    }
    if (result) { setFutureVal(result && !futureVal ? true : result ? true : false); };
    return (invalidDate && result ? true : false);
  }

  function DateValidation(dateString: string, dateFieldName: string): boolean {
    let dateValue = dateString?.trimLeft()?.trimRight();
    var last4 = "";
    let yearValue;
    var newDateFormat = "";
    if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
      dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
    }
    if (dateString?.length === 8 && !dateString?.includes('-') && !dateString?.includes('/')) {
      last4 = dateValue?.substring(0, 4);
      yearValue = Number(last4);
    } else {
      const splitConditions = /[-/\s]+/;
      const dateParts = dateValue.split(splitConditions);
      if (dateFieldName === "YYYY/MM/DD" || dateFieldName === "YYYY/DD/MM") {
        last4 = dateParts[0];
        yearValue = Number(last4);
      } else {
        last4 = dateParts[2];
        yearValue = Number(last4);
        newDateFormat = dateParts[0] + "/" + dateParts[1] + "/" + dateParts[2]
      }
    }
    var SubtractedDate = Number(moment(new Date())?.subtract(100, 'year')?.format('YYYY'));
    let invalidDate = ((yearValue < SubtractedDate) ? false : true);

    let result: any;
    // validate the each records based on the source date format choosen by the user or from the template
    if (dateFieldName === "DD/MM/YYYY") {
      result = ((moment(dateValue, constant?.dayMonthFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    } else if (dateFieldName === "YYYY/MM/DD") {
      result = ((moment(dateValue, constant?.yearMonthFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    } else if (dateFieldName === "YYYY/DD/MM") {
      result = ((moment(dateValue, constant?.yearDateFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    } else if (dateFieldName === "MM/DD/YYYY") {
      result = ((moment(dateValue, constant?.monthDayFormat, true)?.isValid()) && (!moment(dateValue)?.isAfter(new Date())) ? true : false);
    }
    if (result) { setFutureVal(result && !futureVal ? true : result ? true : false); };
    return ((dateValue === "" || dateValue === null) ? true : invalidDate && result ? true : false);
  }

  function DateVal(dateString: string, dateFieldName: string): boolean {
    let dateValue = dateString?.trimLeft()?.trimRight();
    if ((dateString?.length === 8 || dateString?.length === 20 || dateString?.length === 17) && !dateString?.includes('-') && !dateString?.includes('/')) {
      dateValue = dateString?.substring(0, 4) + '/' + dateString?.substring(6, 2)?.slice(2) + '/' + dateString?.substring(8, 4)?.slice(2);
    }

    let result: any;
    // validate the each records based on the source date format choosen by the user or from the template
    if (dateFieldName === "DD/MM/YYYY") {
      result = (moment(dateValue, constant?.dayMonthFormat, true)?.isValid()) ? true : false
    } else if (dateFieldName === "YYYY/MM/DD") {
      result = (moment(dateValue, constant?.yearMonthFormat, true)?.isValid()) ? true : false
    } else if (dateFieldName === "YYYY/DD/MM") {
      result = (moment(dateValue, constant?.yearDateFormat, true)?.isValid()) ? true : false
    } else if (dateFieldName === "MM/DD/YYYY") {
      result = (moment(dateValue, constant?.monthDayFormat, true)?.isValid()) ? true : false
    }
    return ((dateValue === "" || dateValue === null) ? true : result);
  }

  function zipVal(zipCode: string): boolean {
    var zipValue = zipCode?.trim();
    if (zipValue?.length === 4 && !zipValue?.includes('-')) { zipCode = ('0' + zipValue); };
    return (constant.zipVal?.test(zipValue) ? true : false);
  }

  function StateVal(StateCode: string): boolean {
    var StateValue = StateCode?.trim();
    return (constant.StateReg?.test(StateValue) ? true : false);
  }

  function validateAccountNumber(accountNumber: string): boolean {
    var accountNumberValue = accountNumber?.trim();
    return (constant.accountNumberRegex?.test(accountNumberValue) ? true : false);
  }

  function handleMaxLengthValidation(currentData: string, currentHeaderValue: string): boolean {
    if (currentData?.trim()?.length > 0) {
      for (const a of templateList ?? []) {
        if (a?.field_name === currentHeaderValue) {
          const regexPattern = `^(.{0,${a?.field_length}})$`;
          const regex = new RegExp(regexPattern);
          return regex?.test(currentData);
        }
      }
      return false;
    } else {
      return true;
    }
  }

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientcode} >
        <b>Client Code</b>
      </Typography>
      <Divider className={classes.DividerStyles} />
      <Paper className={classes.paper}>
        <div className={classes.ListWrap}>
          {clientList.map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem"
                onClick={handleClientCodeToggle(client)}
                className={`${classes.item} ${"scrollbox"} ${"on-scrollbar"}`} >
                <Checkbox
                  size="small" style={{ color: "#00e676" }}
                  id="SA_ClientCode_Checkbox"
                  checked={SelectedClients.some(c => c.clT_CODE == client.clT_CODE)}
                  tabIndex={-1} disableRipple
                  inputProps={{ 'aria-labelledby': labelId }} />
                <ListItemText id={labelId}>
                  <span style={{ textAlign: 'left', fontSize: 13 }}>
                    {`${client.clT_CODE} - ${client.clT_NAME_1}`}
                  </span>
                </ListItemText>
              </ListItem>
            );
          })}
        </div>
      </Paper>
    </React.Fragment >
  );

  const selectedclientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientcode} >
        <b>Selected Client Code</b>
      </Typography>
      <Divider className={classes.DividerStyles} />
      <Paper className={classes.paper}>
        {clientCodeMapList.map((row, rowIndex) => (
          <Box display="flex" style={{ flexDirection: 'row', height: '40px' }}>
            <Box p={0} bgcolor="white" width='69%' className={classes.ClientBoxStyle}>
              <span className={classes.textWrap}>
                {row.client_code}
              </span>
            </Box>
            <Box p={1} bgcolor="white" width='31%'>
              <TextField
                size="small" style={{ width: '75px' }}
                value={row.client_indicators}
                onChange={(event) => handleClientCodeLiteralChange(rowIndex, event)}
                inputProps={{ style: { padding: 4 } }}
                className={`${classes.inputField}`}
                variant="outlined"
              />
              <IconButton id="GM_DeleteIcon" aria-label="delete" style={{ marginTop: '-11px' }}
                onClick={() => { setDeleteDialogOpen(true); setIndexValue(rowIndex); setGetClientCode(row.client_code); }}>
                <DeleteForeverIcon style={{ color: 'red', fontSize: 23 }} fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        ))}
        {clientCodeMapList?.length <= 0 ?
          <>
            <IconButton id="ClientCode" >
              <GroupAddIcon style={{ color: '#364F6B', fontSize: 40 }} />
            </IconButton>
            <Typography style={{ color: 'green', fontSize: 16 }}>
              Kindly Select Client Code to Map.
            </Typography>
          </>
          : (null)}
      </Paper>
    </React.Fragment >
  );

  const phaseList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientcode} >
        <b>PHASE</b>
      </Typography>
      <Divider className={classes.DividerStyles} />
      <Paper className={classes.paper}>
        <Box p={0.2} bgcolor="white" style={{ display: "flex", flexDirection: 'row' }}>
          <FormControlLabel
            control={<Checkbox size="small" style={{ color: "#00e676", marginLeft: '10px' }} checked={checkPrec} onChange={handlePrecCheck} name="checkedIgnoreRows" />}
            label={<span style={{ fontSize: 13 }}>PREC</span>} />
          <TextField
            value={mappingValuePrec}
            disabled={!checkPrec} style={{ marginTop: '7px' }}
            onChange={handleMappingValuePrecChange}
            className={`${(mappingValuePrec === "" && checkPrec) ? classes.PhaseValTextfieldColor : classes.phaseInputField} ${classes.noPadding}`}
            inputProps={{ style: { padding: 4 } }}
            variant="outlined"
          />
        </Box>
        <Box p={0.2} bgcolor="white" style={{ display: "flex", flexDirection: 'row' }}>
          <FormControlLabel
            control={<Checkbox size="small" style={{ color: "#00e676", marginLeft: '10px' }} checked={checkCont} onChange={handleContCheck} name="checkedIgnoreRows" />}
            label={<span style={{ fontSize: 13 }}>CONT </span>} />
          <TextField
            value={mappingValueCont}
            disabled={!checkCont} style={{ marginTop: '7px' }}
            onChange={handleMappingValueContChange}
            className={`${(mappingValueCont === "" && checkCont) ? classes.PhaseValTextfieldColor : classes.phaseInputField} ${classes.noPadding}`}
            inputProps={{ style: { padding: 4 } }}
            variant="outlined"
          />
        </Box>
        <Box p={0.2} bgcolor="white" style={{ display: "flex", flexDirection: 'row' }}>
          <FormControlLabel
            control={<Checkbox size="small" style={{ color: "#00e676", marginLeft: '10px' }} checked={check1STP} onChange={handle1STPCheck} name="checkedIgnoreRows" />}
            label={<span style={{ fontSize: 13 }}> 1STP </span>} />
          <TextField
            value={mappingValue1STP}
            disabled={!check1STP}
            style={{ marginTop: '7px' }}
            onChange={handleMappingValue1STPChange}
            className={`${(mappingValue1STP === "" && check1STP) ? classes.PhaseValTextfieldColor : classes.phaseInputField} ${classes.noPadding}`}
            inputProps={{ style: { padding: 4 } }}
            variant="outlined"
          />
        </Box>
      </Paper>
    </React.Fragment >
  );

  const handleClientCodeToggle = (clientList: IClientList) => () => {
    let newSelectedClientCodes = [...SelectedClients];
    if (newSelectedClientCodes.some(c => c.clT_CODE == clientList.clT_CODE)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clT_CODE != clientList.clT_CODE);
    } else {
      newSelectedClientCodes.push(clientList);
    }
    setSelectedClient(newSelectedClientCodes);
  }

  function handleClientCodeSubmit() {
    let currentMappingList = _.cloneDeep(clientCodeMapList);
    SelectedClients?.map((client) => {
      let newClientCodeMapping: IClientCodeMap = {
        placement_map_id: client?.placement_map_id,
        client_code: (`${client?.clT_CODE}  -  ${client?.clT_NAME_1}`),
        client_indicators: ""
      };
      currentMappingList.push(newClientCodeMapping);
    });
    setClientCodeMapList(currentMappingList);
    setSelectedClient([]);
  }

  function handleClientCodeDelete(rowIndex: number) {
    let currentClientCodeMappingList = _.cloneDeep(clientCodeMapList);
    currentClientCodeMappingList = currentClientCodeMappingList.filter((x, index) => index != rowIndex);
    setClientCodeMapList(currentClientCodeMappingList);
    setClientCodeInPlacementAccountList(currentClientCodeMappingList);
  }

  function handleClientCodeLiteralChange(rowIndex, event) {
    let currentClientCodeMappingList = _.cloneDeep(clientCodeMapList);
    currentClientCodeMappingList[rowIndex].client_indicators = event.target.value;
    setMappedClientCodeValue(event.target.value);
    setClientCodeInPlacementAccountList(currentClientCodeMappingList);
    setClientCodeMapList(currentClientCodeMappingList);
  }

  const setClientCodeInPlacementAccountList = (currentClientCodeMappingList: IClientCodeMap[], clientIndex?: any) => {
    let clientCodeIndex = selectedHeaderList.findIndex((x) => x == "client_code");
    if (clientCodeIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      currPlacementAccountList.map((pa, rowIndex) => {
        let clientCodeLiteral = placementAccountCSVData[rowIndex][clientCodeIndex];
        if (currentClientCodeMappingList.map(x => x.client_indicators)?.includes(clientCodeLiteral)) {
          let clientCode = currentClientCodeMappingList.find(x => x.client_indicators == clientCodeLiteral).client_code;
          pa.client_code = clientCode.substring(0, 6);
        } else if (clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
          pa.client_code = clientCodeLiteral;
        } else {
          pa.client_code = "";
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
    else if (clientIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      currPlacementAccountList.map((pa, rowIndex) => {
        let clientCodeLiteral = placementAccountCSVData[rowIndex][clientIndex];
        if (currentClientCodeMappingList.map(x => x.client_indicators)?.includes(clientCodeLiteral)) {
          let clientCode = currentClientCodeMappingList.find(x => x.client_indicators == clientCodeLiteral).client_code;
          pa.client_code = clientCode.substring(0, 6);
        } else if (clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
          pa.client_code = clientCodeLiteral;
        } else {
          pa.client_code = "";
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const handleFormatDate = () => {
    let newPlacementAccountList = _.cloneDeep(placementAccountList);
    let newDateFormatList: string[] = [];
    templateList?.forEach((a) => {
      if (a?.field_name === "delinquency_date" || a?.field_name === "Itemization_Date" || a?.field_name === "Delinquency_Itemization_Date"
        || a?.field_name === "last_payment_date" || a?.field_name === "service_date" || a?.field_name === "birth_date" || a?.field_name === "Codebtor_BirthDate") {
        newDateFormatList.push(a?.field_name);
      }
      setSelectedHeaderDateFormatList(newDateFormatList);
    })
    newPlacementAccountList = newPlacementAccountList?.slice(Number(ignoreRowNumber));
    for (const result of newPlacementAccountList) {
      if (result?.delinquency_date?.trim()?.length > 0) {
        setNewDelinquencyDate(result?.delinquency_date);
        break;
      }
    }
    for (const result of newPlacementAccountList) {
      if (result?.Itemization_Date?.trim()?.length > 0) {
        setNewItemization_Date(result?.Itemization_Date);
        break;
      }
    }
    setfixDateValueOpen(true);
  }

  const handleCloseDialog = () => {
    setfixDateValueOpen(false);
    setDateFormatConfirmOpen(false);
    setSelectedHeaderDateFormatList([]);
    isDateFormatIsSaved ? null : isMappingTemplateSelected ? null : GetPlacementDateFormat()
  }

  const handleCloseDialogWindow = () => {
    setFinalDateFormat([]);
    setDateFormatConfirmOpen(false);
  }

  const handleChangeDateFormat = (event) => {
    const name = event.target.name;
    const dateFormatValue = event.target.value;
    if (dateFormatValue === "DD/MM/YYYY" || dateFormatValue === "DDMMYYYY" || dateFormatValue === "DD-MM-YYYY") {
      setLocalState({
        ...localState,
        [name]: "DD/MM/YYYY",
      });
    } else if (dateFormatValue === "MM/DD/YYYY" || dateFormatValue === "MMDDYYYY" || dateFormatValue === "MM-DD-YYYY") {
      setLocalState({
        ...localState,
        [name]: "MM/DD/YYYY",
      });
    } else if (dateFormatValue === "YYYY/MM/DD" || dateFormatValue === "YYYYMMDD" || dateFormatValue === "YYYY-MM-DD") {
      setLocalState({
        ...localState,
        [name]: "YYYY/MM/DD",
      });
    } else if (dateFormatValue === "YYYY/DD/MM" || dateFormatValue === "YYYYDDMM" || dateFormatValue === "YYYY-DD-MM") {
      setLocalState({
        ...localState,
        [name]: "YYYY/DD/MM",
      });
    }
  }

  // Applay the Last selection date format for all the date columns
  const handleSetLastSelectionDateFormat = () => {
    let selectedDateFormat: IFixedDateFormat[] = _.cloneDeep(finalDateFormat);
    selectedHeaderDateFormatList?.map((dateFormat) => {
      if (dateFormat === constant?.delinquency_date) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.delinquency_date,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      } else if (dateFormat === constant?.birth_date) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.birth_date,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      } else if (dateFormat === constant?.last_payment_date) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.last_payment_date,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      } else if (dateFormat === constant?.service_date) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.service_date,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      } else if (dateFormat === constant?.Codebtor_BirthDate) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.Codebtor_BirthDate,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      } else if (dateFormat === constant?.Itemization_Date) {
        let fixedDate: IFixedDateFormat = {
          fieldName: constant?.Itemization_Date,
          dateFormat: lastSelectionFormat
        }
        selectedDateFormat.push(fixedDate);
        setFinalDateFormat(selectedDateFormat);
      }
    })
  }

  const handleConfirmDateFormat = () => {
    setfixDateValueOpen(false);
    setDateFormatConfirmOpen(false);
    setIsDateFormatIsSaved(true);
  }

  return (
    <React.Fragment >
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CSV UPLOAD
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
            Please provide all the necessary data.
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setAlertOpen(false); setErrorStyle(true) }} className={classes.yesButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.div} >
        <Backdrop className={classes.backdrop} open={state.busy || ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />

        <Container fixed className={classes.container} component="div" >
          <span className={`${classes.title} ${"headertitle"}`}>
            <b> 2. CHOOSE FIELD NAMES </b>
          </span>
          <div className={`${classes.solidBorder}`}>
            <div className={`${classes.actions}`}>
              <Box display="flex" className={classes.accountItemization}>
                <Box width="18%" flexShrink={0} p={0} style={{ float: 'left' }} >
                  <Autocomplete
                    size="small"
                    id="template-select"
                    options={mappingList}
                    autoHighlight={true}
                    blurOnSelect={true}
                    clearOnBlur={true}
                    className={classes.dropdown1}
                    onChange={handleTemplateChange}
                    classes={{ input: classes.smallFont1, paper: classes.paper1 }}
                    getOptionLabel={(option) => option.map_name + `--` + option.client_code}
                    getOptionSelected={(option, value) => option.placement_map_id === value.placement_map_id}
                    renderOption={(option) => (
                      <React.Fragment>
                        {
                          <div className={classes.templateStyle}>
                            <span
                              className={`${option.placement_map_id === placementTemplateId ? classes.colorStyle : null}`} >
                              {option.map_name + `--` + option.client_code}
                            </span>
                            <span>
                              {
                                option.placement_map_id === placementTemplateId ?
                                  <StarIcon className={classes.starIcon} style={{ fontSize: 17 }} />
                                  : null
                              }
                            </span>

                          </div>
                        }
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params} placeholder="Save Templates"
                        className={classes.selectdropdown1} variant="outlined"
                        inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                      />
                    )}
                  />
                </Box>
                <Box flexShrink={0} width="4%" p={0} >
                  <DeleteTemplate ResetAutoMap={resetAutoMappingTemplate} templateId={templateId} GetMappingTemplate={getMappingTemplate} mapped={mapped} validAutoMap={valid} onDelete={getMappingTemplate} globalTemplateId={placementTemplateId} />
                </Box>
                <Box p={0} flexShrink={0} width="14%">
                  <FormControlLabel style={{ marginTop: "7px", marginLeft: '10px' }}
                    control={<Checkbox size="small" checked={mBalanceChecked} onChange={handleMBalanceCheck}
                      name="checkedB" />}
                    label="Minimum Balance :"
                  />
                </Box>
                <Box p={0} flexShrink={0} width="5%">
                  <CurrencyInput
                    maxLength={13}
                    prefix='$'
                    value={mBalanceValue}
                    disabled={!mBalanceChecked}
                    className={`${(mBalanceValue === "" && mBalanceChecked) ? classes.ValTextfieldColor : classes.inputField1}`}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={handleMBalanceChange}
                  />
                </Box>
                <Box p={0} flexShrink={0} width="10%">
                  <FormControlLabel
                    className={classes.ignoretop}
                    control={<Checkbox size="small" checked={ignoreRows} onChange={handleIgnoreRowsCheck} name="checkedIgnoreRows" />}
                    label="Ignore Top"
                  />
                </Box>
                <Box p={0} width='5%' flexShrink={0} >
                  <CurrencyInput
                    maxLength={5}
                    value={ignoreRowNumber}
                    disabled={!ignoreRows}
                    className={classes.inputField1}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    style={{ textAlign: "center" }}
                    decimalsLimit={0}
                    onValueChange={handleIgnoreRowNumberChange}
                  />
                </Box>
                <Box p={0} width='2%' flexShrink={0} bgcolor="white" >
                  <LightTooltip title="Ignore (if first row contain headers)">
                    <InfoOutlinedIcon className={classes.infoicon} />
                  </LightTooltip>
                </Box>
                <Box p={0} paddingTop={1} paddingLeft={1.5} width='16%' flexShrink={0} bgcolor="white" >
                  <SaveTemplate fees={feeValue} balance={mBalanceValue}
                    clientCode={clientCode} checkEnable={enable} selectedHeaderList={selectedHeaderList} savingTempEnable={savingEnable}
                    ignoreRowNumber={ignoreRowNumber} clientList={clientList} onTemplateSave={getMappingTemplate} clientCodeMap={clientCodeMapList} finalDateFormat={localState} />
                </Box>
                <Box p={0} width='12%' flexShrink={0} bgcolor="white">
                  <Button id="PLC_Next_btn" className={classes.Datebtn} size="small" startIcon={<DateRangeIcon />}
                    variant="contained" type='submit' onClick={() => { handleFormatDate(); }} color="primary">
                    FIX DATE VALUE
                  </Button>
                </Box>
                <Box p={0} width='7%' flexShrink={0} bgcolor="white">
                  <Button id="PLC_Back_btn" className={classes.buttons} size="small" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                    variant="contained" color="primary" component={Link} to="/placement">
                    Back
                  </Button>
                </Box>
                <Box p={0} width='7%' flexShrink={0} bgcolor="white">
                  <Button id="PLC_Next_btn" className={classes.Nextbtn} size="small" endIcon={<DoubleArrowIcon />}
                    variant="contained" type='submit' onClick={handleSubmit} color="primary">
                    Next
                  </Button>
                </Box>
              </Box>
              <Box display="flex" bgcolor="white" className={classes.accountItemization} >
                <Box p={0} width='80%' flexShrink={0} bgcolor="white">
                  {(GetUwfCltData !== undefined) && (clientCode != "-2" && phaseNumber != 4) ?
                    <Box width='100%' display="flex" p={1} bgcolor="white" className={`${classes.solidBorder}`} style={{ height: '35px', alignItems: 'center', justifyContent: 'center' }}>
                      <span className={classes.InfoStyle} >
                        Your account itemization date is: <span className={classes.uwfStyle}>{GetUwfCltData}</span>. If you need to change this, please contact clientservices@armsolutions.com.
                      </span>
                    </Box>
                    :
                    null
                  }
                </Box>
                <Box p={0} width='20%' flexShrink={0} bgcolor="white">
                  {clientCode != "-2" && phaseNumber != 4 ?
                    <Typography variant="h6" gutterBottom className={classes.countMsg} >
                      <b>Total no.of Accounts : </b> <span style={{ color: "green", marginTop: '5px' }}>{(placementAccountCSVData?.length - ignoreRowNumber) < 0 ? 0 : (placementAccountCSVData?.length - ignoreRowNumber)}</span>
                    </Typography>
                    : null}
                </Box>
              </Box>
            </div>

            {clientCode == "-2" ?
              <Grid container style={{ flexDirection: 'row', paddingLeft: '5px' }} spacing={1}>
                <Grid item xs={5} >
                  <Box style={{ borderRadius: '5px' }}>
                    {clientCodeList()}
                  </Box>
                </Grid>
                <Grid item style={{ width: '8.2%' }}>
                  <Button
                    id="CSV_Add_btn" variant="contained" size="small"
                    color="primary" aria-label="move selected right"
                    onClick={handleClientCodeSubmit}
                    style={{ borderRadius: '20px', marginTop: '25px' }} startIcon={<GroupAddIcon />}>
                    Add
                  </Button>
                  <Typography variant="h6" style={{ marginTop: '15px' }} className={classes.countMsg}>
                    <b>Total no.of Accounts</b>
                  </Typography>
                  <span style={{ color: 'green', fontWeight: 900 }}>
                    {(placementAccountCSVData.length - ignoreRowNumber) < 0 ? 0 : (placementAccountCSVData?.length - ignoreRowNumber)}
                  </span>
                </Grid>
                <Grid item xs={clientCode == "-2" && +phaseNumber == 4 ? 4 : 6}>
                  <Box style={{ borderRadius: '5px' }}>
                    {selectedclientCodeList()}
                  </Box>
                </Grid>
                {+phaseNumber == 4 ?
                  <Grid item xs={2}>
                    {phaseList()}
                  </Grid>
                  : null}
              </Grid>
              : null}

            {+phaseNumber == 4 && clientCode == "-2" ? null
              : clientCode == "-2" && phaseNumber != 4 ? null
                : clientCode != "-2" && phaseNumber != 4 ? null
                  : <Grid item xs={12} >
                    <Box p={1} flexShrink={0} bgcolor="white" style={{ flexDirection: 'row', display: 'flex' }}>
                      {(GetUwfCltData !== undefined) ?
                        <Box width='49%' display="flex" p={1} bgcolor="white" className={`${classes.solidBorder}`} >
                          <span className={classes.InfoStyle}>
                            Your account itemization date is: <span className={classes.uwfStyle}>{GetUwfCltData}</span>. If you want to change this, please contact clientservices@armsolutions.com.
                          </span>
                        </Box>
                        :
                        null
                      }
                      <Box width='15%' display="flex" bgcolor="white" justifyContent="center" alignItems="center">
                        <Typography variant="h6" gutterBottom className={classes.countMsg} style={{ marginTop: '10px' }}>
                          <b>Total no.of Accounts : </b> <span style={{ color: "green", fontWeight: 900 }}>{(placementAccountCSVData?.length - ignoreRowNumber) < 0 ? 0 : (placementAccountCSVData?.length - ignoreRowNumber)}</span>
                        </Typography>
                      </Box>
                      <Box width='37%' display="flex" p={0} bgcolor="white" className={`${classes.solidBorder}`} >
                        <Box p={0} flexShrink={0} bgcolor="white">
                          <FormControlLabel
                            className={classes.phase}
                            control={<Checkbox size="small" style={{ color: "#00e676" }} checked={checkPrec} onChange={handlePrecCheck} name="checkedIgnoreRows" />}
                            label={<span style={{ fontSize: 13 }}>PREC </span>} />
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white" style={{ width: "75px", marginTop: "6px" }}>
                          <TextField
                            value={mappingValuePrec}
                            disabled={!checkPrec}
                            onChange={handleMappingValuePrecChange}
                            className={`${(mappingValuePrec === "" && checkPrec) ? classes.PhaseValTextfieldColor2 : classes.phaseInputField} ${classes.noPadding}`}
                            inputProps={{ style: { padding: 4.7 } }}
                            variant="outlined"
                          />
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white">
                          <FormControlLabel
                            className={classes.phase}
                            control={<Checkbox size="small" style={{ color: "#00e676" }} checked={checkCont} onChange={handleContCheck}
                              name="checkedIgnoreRows" />}
                            label={<span style={{ fontSize: 13 }}>CONT </span>} />
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white" style={{ width: "75px", marginTop: "6px" }}>
                          <TextField
                            value={mappingValueCont}
                            disabled={!checkCont}
                            onChange={handleMappingValueContChange}
                            className={`${(mappingValueCont === "" && checkCont) ? classes.PhaseValTextfieldColor2 : classes.phaseInputField} ${classes.noPadding}`}
                            inputProps={{ style: { padding: 4.7 } }}
                            variant="outlined"
                          />
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white">
                          <FormControlLabel
                            className={classes.phase}
                            control={<Checkbox size="small" style={{ color: "#00e676" }} checked={check1STP} onChange={handle1STPCheck} name="checkedIgnoreRows" />}
                            label={<span style={{
                              fontSize: 13
                            }}>1STP </span>} />
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white" style={{ width: "75px", marginTop: "6px" }}>
                          <TextField
                            value={mappingValue1STP}
                            disabled={!check1STP}
                            onChange={handleMappingValue1STPChange}
                            className={`${(mappingValue1STP === "" && check1STP) ? classes.PhaseValTextfieldColor2 : classes.phaseInputField} ${classes.noPadding}`}
                            inputProps={{ style: { padding: 4.7 } }}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
            }
          </div>
          <div style={{ height: '5.5vh' }}>
            {isSubmit === true && savingEnable === false && !validationErrors.length ?
              <Typography variant="body1" gutterBottom className={`${errorStyle === false ? classes.error_message : classes.error_message1}`}>
                <b style={{ textDecoration: 'underline' }}>Note</b> : The following fields are required:&nbsp;
                {requireFields.map((field) => (<> &nbsp;{`${field.display_name}`},  </>))}
                {clientCodeVal ? clientCodeMsg : null} &nbsp;{phaseVal ? phaseMsg : null}
              </Typography> : null}
            <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 13 }}>
              {validationErrors.map((field) => (<> &nbsp;{`${field.errorMessage}`},  </>))}
            </Typography>
          </div>
          <TableContainer component={Paper} className={`${clientCode == "-2" && phaseNumber == 4 ? classes.container3 : clientCode == "-2" && phaseNumber !== 4 ? classes.container3 : +phaseNumber == 4 && clientCode !== "-2" ? classes.container2 : classes.container1}`} >
            <Table aria-label="collapsible table" size="small" className={classes.tableLayout}>
              <TableHead  >
                <TableRow className={classes.tablehead}>
                  {placementAccountCSVData[0]?.map((headCell, index) => (
                    <TableCell className={classes.TableCellStyle} key={"head" + index}>
                      <Autocomplete
                        size="small"
                        id={"header-field" + index}
                        className={classes.selectfield}
                        autoHighlight
                        options={filteredTemplateList}
                        onChange={(event, selected) => handleChange(index, event, selected)}
                        getOptionLabel={(option) => option.display_name.trim()}
                        getOptionSelected={(option, value) => option.field_name !== value.field_name}
                        value={templateList.findIndex(x => x.field_name == selectedHeaderList[index]) == -1 ? {
                          field_name: "-1",
                          display_name: "--Ignore Field--",
                          data_type: "varchar",
                          is_nullable: true,
                          is_visible: true,
                          is_required: false,
                          field_length: -1,
                        } : templateList.find(x => x.field_name == selectedHeaderList[index])}
                        renderOption={(options) => (
                          <span className={`${options.is_required || !options.is_nullable ? classes.RequiredStyle : classes.NonRequiredStyle}`}>
                            {options.display_name.trim()}
                          </span>
                        )}
                        renderInput={(params) => (
                          < TextField {...params}
                            variant="outlined"
                            placeholder="--Ignore Field--"
                            className={`${validationErrors.find(x => x.field == selectedHeaderList[index]) ? classes.selectdropdownError : classes.selectdropdown}`}
                            multiline rowsMax={4}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )} classes={{ input: classes.smallFont, paper: classes.paper1 }} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ padding: "20%" }}>
                {placementAccountCSVData.map((row, rowIndex) => (
                  <TableRow key={"row" + rowIndex} className={classes.tableBody}>
                    {placementAccountCSVData[rowIndex].map((cell, cellIndex) => (
                      <TableCell className={`${classes.excelCell} ${classes.tableBody1} ${ignoredCellClassName(rowIndex)}`}
                        align="center" key={"cell" + rowIndex + "_" + cellIndex}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <Footer />

        <Dialog TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 10 } }}
          maxWidth={'sm'}
          fullScreen={fullScreen}
          open={DeleteDialogOpen}
          onClose={() => { setDeleteDialogOpen(false); }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={() => { setDeleteDialogOpen(false); }}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              DELETE MAPPING
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" gutterBottom className={classes.deletetemp}>
                Are you sure, you want to remove Client Code {GetClientCode} ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button size="small" id="IM_ok_btn" variant="contained" className={classes.yesbutton} onClick={() => { handleClientCodeDelete(indexValue); setDeleteDialogOpen(false); }}
              color="primary" autoFocus>
              Yes
            </Button>
            <Button size="small" id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton} onClick={() => { setDeleteDialogOpen(false); }} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 10 } }}
          maxWidth={'md'}
          fullScreen={fullScreen}
          open={fixDateValueOpen}
          onClose={() => { handleCloseDialog(); }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={() => { handleCloseDialog(); }}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              FIX DATE FORMAT
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" gutterBottom className={classes.datefiledName}>
                    <b>MAPPED FIELDS</b>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" gutterBottom className={classes.datefiledName}>
                    <b>IDENTIFIED VALUES</b>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" gutterBottom className={classes.datefiledName}>
                    <b>SOURCE DATE FORMAT</b>
                  </Typography>
                </Grid>

                {/*  <Grid item xs={12} sm={3} >
                  <FormControlLabel
                    control={<Checkbox name="applyall" onClick={handleSetLastSelectionDateFormat} />}
                    label={<span><b>Apply Last Selection</b></span>}
                    style={{ float: 'right' }}
                  />
                </Grid> */}
              </Grid>
              {selectedHeaderDateFormatList?.map((r) => {
                let myString;
                let dropDownValue;
                let optionItems;
                return (
                  <Grid container spacing={1} className={`${classes.paperDate} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="body1" gutterBottom className={classes.dateList}>
                        {r}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography variant="body1" gutterBottom className={classes.dateList}>
                        {r === constant?.delinquency_date ? newDelinquencyDate : r === constant.birth_date ? placementAccountList[Number(ignoreRowNumber)]?.birth_date :
                          r === constant?.last_payment_date ? placementAccountList[Number(ignoreRowNumber)]?.last_payment_date : r === constant?.service_date ? placementAccountList[Number(ignoreRowNumber)]?.service_date :
                            r === constant?.Codebtor_BirthDate ? placementAccountList[Number(ignoreRowNumber)]?.Codebtor_BirthDate : r === constant?.Itemization_Date ? newItemization_Date :
                              constant?.errorMessage}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} >
                      <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
                        <InputLabel htmlFor="outlined-age-native-simple">Select Source Date Format</InputLabel>
                        <Select native
                          name={`${r}`}
                          label="Select Source Date Format"
                          inputProps={{
                            id: 'outlined-age-native-simple',
                          }}
                          value={r === constant?.delinquency_date ? localState?.delinquency_date : r === constant?.Codebtor_BirthDate ? localState?.Codebtor_BirthDate
                            : r === constant?.service_date ? localState?.service_date : r === constant?.last_payment_date ? localState?.last_payment_date
                              : r === constant?.birth_date ? localState?.birth_date : r === constant?.Itemization_Date ? localState?.Itemization_Date : null}
                          onChange={handleChangeDateFormat}>
                          {myString = constant?.DateFormat.join(",")}
                          {dropDownValue = (myString).split(",")}
                          {optionItems = dropDownValue?.map((result) => {
                            return (
                              <option value={result}>{result}</option>
                            )
                          })
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="line-break" style={{ borderTop: '1px dashed rgba(145, 158, 171, 0.24)' }}>
            <Button size="small" id="IM_ok_btn" variant="contained" className={classes.yesbutton} onClick={() => { setDateFormatConfirmOpen(true); }}
              startIcon={<DoneAllIcon />}
              color="primary" autoFocus>
              APPLY
            </Button>
            <Button size="small" id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton}
              onClick={() => { handleCloseDialog(); }} color="primary" startIcon={<CancelIcon />}>
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 10 } }}
          maxWidth={'sm'}
          fullScreen={fullScreen}
          open={dateFormatConfirmOpen}
          onClose={() => { handleCloseDialogWindow(); }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={() => { handleCloseDialogWindow(); }}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              DATE CONFIRMATION
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" gutterBottom className={classes.dateTextStyle}>
                The target format will be applied on the selected column, Are you sure?
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.dateTextStyle1}>
                <b>Caution :</b> Source format will be removed, you can reupload any time.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button size="small" id="IM_ok_btn" variant="contained" className={classes.yesbutton} onClick={() => { handleConfirmDateFormat() }}
              color="primary" autoFocus startIcon={<DoneAllIcon />}>
              Yes
            </Button>
            <Button size="small" id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton} onClick={() => { handleCloseDialogWindow(); }} color="primary" startIcon={<CancelIcon />}>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen} open={openErrorMessage} onClose={(e) => { setOpenErrorMessage(false); }} maxWidth={'sm'}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
          <DialogTitleDelete id="responsive-dialog-title" onClose={() => { setOpenErrorMessage(false); }}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              CSV UPLOAD
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
              Please select source date format at the template level or select the date from <span className={classes.textColorStyle}><b>FIX DATE VALUE</b></span> button.
            </Typography>
          </DialogContent>
          <DialogActions >
            <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setOpenErrorMessage(false); }} className={classes.yesButton}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen} open={showDateIssueMessage} onClose={(e) => { handleDateErrorClose() }} maxWidth={'xs'}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
          <DialogTitleDelete id="responsive-dialog-title" onClose={() => { handleDateErrorClose() }}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              CSV DATE FORMAT WARNING
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
              {constant?.dateFormatErrorMessage}
            </Typography>
          </DialogContent>
          <DialogActions >
            <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { handleDateErrorClose() }} className={classes.yesButton}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CSVFieldnames)