import {
    Box, Button, Dialog, DialogContent, FormControl, Grid, makeStyles, Select, TextField, createStyles, Theme,
    Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import PuffLoader from "react-spinners/PuffLoader";
import Backdrop from '@mui/material/Backdrop/Backdrop';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
//Icons
import CreateIcon from '@mui/icons-material/Create';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
//Models
import { IReportsList } from '../../models/Reports/ReportsList';
import { IClientPhase } from '../../models/Placements/IClientPhase';
// Componants
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            width: "100%",
            marginTop: "5px",
            marginLeft: '5px',
            maxHeight: 500,
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        reportname: {
            margin: 8,
            width: '100%',
            borderBottom: '2px solid blue',
            borderRadius: '10px',
            boxShadow: '0px 0px 2px 0px rgba(103, 128, 159, 1)',
            marginTop: '25px'
        },
        checkboxText: {
            marginLeft: '3px',
            marginTop: '5px'
        },
        createReport: {
            borderRadius: '20px',
            marginBottom: "0.5cm",
            float: 'right',
            marginLeft: '25px'
        },
        filterbutton: {
            borderRadius: '20px',
            float: 'right',
            marginBottom: "0.5cm"
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        yesButton: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        MessageStyle: {
            marginTop: "15px",
            color: "black",
        },
        title: {
            alignContent: "left",
            color: "blue",
            marginTop: '0.7cm'
        },
        dropdown: {
            marginTop: '20px'
        },
        placementTitle: {
            color: 'blue',
            marginTop: '15px',
        },
        formControl: {
            minWidth: 600,
            marginRight: theme.spacing(1),
            alignItems: "left"
        },
        textField: {
            width: 400,
            backgroundColor: 'white',
            border: '2px solid blue',
            borderRadius: 5,
            float: 'left'
        },
        dropdownClient: {
            width: 400,
            float: 'left',
            backgroundColor: 'white',
            border: '2px solid blue',
            borderRadius: 5
        },
        paper1: {
            border: "2px solid black",
        },
        NoAccess: {
            color: 'red'
        },
        operatorBoxGap1: {
            gap: '50px',
            marginTop: '8px'
        },
        operatorBoxGap2: {
            gap: '72px',
            marginTop: '8px'
        },
        operatorBoxGap3: {
            gap: '72px',
            marginTop: '8px'
        },
        operatorBoxGap4: {
            gap: '100px',
            marginTop: '8px'
        },
        autoCompleteFont: {
            fontSize: 13,
        },
    })
);

const CreateAckReport: React.FC<{ reportRow?: IReportsList, clients?: any, getReports?: () => void }> = (props) => {
    const theme = useTheme();
    const classes = useRowStyles();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ReportName, setReportName] = useState("");
    const { state, dispatch } = useGlobalState();

    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");

    const [SelectedClientCode, setSelectedClientCode] = useState<any>([]);
    const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);

    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");

    async function createReportAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "reportName": ReportName,
                "client_code": SelectedClientCode,
                "userId": state.userAccessContext?.id,
                "startDate": moment(StartDate).format("YYYY-MM-DD"),
                "endDate": moment(EndDate).format("YYYY-MM-DD"),
                "phaseId": selectedPhase,
                "default_report": false
            }
            await usePost<any>("ACKReportCreate", request).then(() => {
                handleClose();
                props.getReports();
            }).finally(() => {
                setShowProgressBar(false);
            });
        } catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setReportName("");
        setStartDate(null);
        setEndDate(null);
        setOpen(false);
        setSelectedPhase(-1);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleReportNameChange = (event) => {
        setReportName(event.target.value);
    }

    const HandleReportNameVal = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(/[~`!@#^&*()+=%<>?.,:;{}|\\$'"]/)) {
            setError("");
            setReportName(newValue);
        } else {
            setError("please enter characters and numbers only");
        }
    };

    function onClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone?.client_code);
        (async () => {
            await useFetch<{ placementPhaseResults: IClientPhase[] }>(`Placements/GetPlacementPhase?clt_code=${valueone?.client_code}`).then((clientPhase) => {
                setClientPhase(clientPhase?.data?.['placementPhaseResults']);
            });
        })()
    }

    const handlePhaseChange = (event) => {
        setSelectedPhase(parseInt(event.target.value));
    };

    return (
        <React.Fragment>
            <div>
                <Button style={{ borderRadius: 20 }}
                    variant="contained" startIcon={<NoteAddIcon />} color="primary" onClick={handleClickOpen}>
                    Create Report
                </Button>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen} open={open} PaperProps={{ style: { borderRadius: 15 } }}
                    aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'} >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                            CREATE REPORT
                        </Typography>
                    </DialogTitleHeader>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <DialogContent>
                        <Grid item xs={12}>
                            <TextField id="CR_Name"
                                className={classes.reportname}
                                onChange={(e) => { handleReportNameChange(e); HandleReportNameVal(e); }}
                                placeholder="Enter Report Name" fullWidth margin="normal" variant="outlined"
                                value={ReportName}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 20 }
                                }} required helperText={error} error={!!error} inputProps={{ maxlength: 100 }} />
                            <div className={classes.actions}>
                                <Box display="flex" className={classes.operatorBoxGap1}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE START DATE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant='outlined'
                                                id="date"
                                                type="date"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={StartDate}
                                                onChange={(e) => { setStartDate(e.target.value); }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap2}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE END DATE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant='outlined'
                                                id="date"
                                                type="date"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => { setEndDate(e.target.value); }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap3}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE CLIENT CODE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <Autocomplete
                                                id="clientCode-select"
                                                options={props.clients}
                                                className={classes.dropdownClient}
                                                classes={{ paper: classes.paper1 }}
                                                autoHighlight
                                                onChange={onClientCodeSelect} disableClearable={true}
                                                getOptionLabel={(option) => option?.client_code + "--" + option?.client_name}
                                                getOptionSelected={(option, value) => option?.client_code === value?.client_code}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        {option?.client_code + "--" + option?.client_name}
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select client"
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap4}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE PHASE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl id="Select_Phase" variant="outlined" className={classes.formControl} >
                                            <Select native value={selectedPhase} onChange={handlePhaseChange} className={classes.dropdownClient}>
                                                <option value={-1}>Select Phase</option>
                                                {clientPhase.map((phase) => {
                                                    return (
                                                        <>
                                                            {phase?.istParty ? <option value={1}>1st Party</option> : <option value={-1} className={classes.NoAccess}>1st Party</option>}
                                                            {phase?.isFlatFee ? <option value={2}>Flat Fee</option> : <option value={-1} className={classes.NoAccess}>Flat Fee</option>}
                                                            {phase?.isContingency ? <option value={3}>Contingency</option> : <option value={-1} className={classes.NoAccess}>Contingency</option>}
                                                        </>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </div>
                            <Box p={2} flexShrink={0} bgcolor="white">
                                <Button autoFocus color="primary" variant="contained" disabled={!ReportName || StartDate === "" || EndDate === "" || SelectedClientCode?.length < 0 || selectedPhase === -1}
                                    onClick={() => { createReportAsync(); }} startIcon={<CreateIcon />} className={classes.createReport}>
                                    Create Report
                                </Button>
                            </Box>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        </React.Fragment >
    );
}

export default CreateAckReport