import {
  Backdrop, Button, createStyles, Dialog, DialogActions, DialogContent, makeStyles,
  Snackbar, Typography, useMediaQuery, Theme, useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// Componants
import { useDelete } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleheader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      padding: '2px',
      right: '15px',
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      padding: '2px',
      display: 'flex',
      right: '10px',
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    textfield: {
      marginTop: "15px",
      color: "black"
    },
    deleteIcon: {
      color: 'red',
      fontSize: 24
    },
    deleteBtnRadius: {
      borderRadius: 30,
      fontSize: 11
    }
  })
);

const DeleteUser: React.FC<{ userRow: IUserInfo, onDelete: () => void, checkSuperAdmin: boolean }> = (props) => {
  const { userRow } = props
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useGlobalState();
  const [DeleteUser, setDeleteUser] = useState(false);
  const classes = useRowStyles();
  const [ProgressBar, setShowProgressBar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setShowProgressBar(true);
    useDelete("User/DeleteUser?userId=" + userRow.id).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
      }
      props.onDelete();
      setShowProgressBar(false);
      setDeleteUser(true);
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteUser(false);
  };

  return (
    <React.Fragment>
      <div>
        <Button size="small" id="U_DeleteIcon" aria-label="delete" className={classes.deleteBtnRadius} onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
          <DeleteForeverIcon className={classes.deleteIcon} />
        </Button>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'xs'}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" className={classes.titleheader}>
              DELETE USER
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" className={classes.textfield} gutterBottom>
              Are you sure, you want to delete this user ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} onClick={(e) => { handleOk(); }} className={classes.yesButton}>
              Yes
            </Button>
            <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={DeleteUser} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            User has been Deleted Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default DeleteUser