import {
  Box,
  Button, Grid, IconButton, InputAdornment, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import * as constant from '../../constants/Constant';
import { IClientCodeConfirmation } from '../../models/client/IClientCodeConfirmation';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import StyledCell from "../../components/GlobalStyles/StyledCell";
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    marginTop: '2px',
    borderRadius: "30px",
    border: '2px solid #215176',
    height: '38px',
    textIndent: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    width: '100%'
  },
  grid: {
    padding: '8px'
  },
  div: {
    flexGrow: 1
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  TextHighlight: {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: "yellow",
    fontSize: '16px',
  },
  TextNormal: {
    color: 'black',
    fontSize: 13
  },
  tableBody: {
    maxHeight: 495,
    minHeight: 495,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 215px)',
      minHeight: 'calc( 100vh - 215px)',
    },
  },
  confirmText: {
    marginLeft: '10px'
  },
  confirmButton: {
    borderRadius: 20,
    background: "#008000",
    marginLeft: '15px',
    color: "white",
    '&:hover': {
      backgroundColor: "#008000"
    }
  },
  confirmButton1: {
    borderRadius: 20,
    background: "#FF6666",
    marginLeft: '15px',
    color: "white",
    '&:hover': {
      backgroundColor: '#FF6666'
    }
  },
  nobutton: {
    background: "red",
    marginRight: "10px",
    borderRadius: 30,
    color: "white",
    '&:hover': {
      backgroundColor: 'red'
    }
  },
  yesbutton: {
    background: "green",
    marginRight: "10px",
    borderRadius: 30,
    color: "white",
    '&:hover': {
      backgroundColor: 'green'
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  unlockbutton: {
    display: 'flex',
    width: '120px',
    fontSize: 12,
    borderRadius: '20px'
  },
  IconStyle: {
    display: 'flex',
    position: 'absolute',
    marginLeft: '-10px',
    fontSize: 13,
    maxHeight: 20,
    minHeight: 20,
  },
  label: {
    '&$focused': {
      color: 'white',
      border: '0px'
    },
  },
  focused: {},
  outlinedInput: {
    fontSize: 15,
    color: "black",
    marginTop: '-10px',
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    border: 'none',
  },
  textStyle: {
    marginTop: '5px',
    marginLeft: '10px',
    float: 'left',
    fontSize: 22
  },
  norecord: {
    color: "red",
    marginTop: "10px"
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: 13,
    padding: '3px 15px'
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '0px 15px'
  },
}))

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      height: '1cm',
    },
  }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [
    { id: "clientCode", disablePadding: false, label: "CLIENT CODE", sortable: true },
    { id: "clientName", disablePadding: false, label: "CLIENT NAME", sortable: true },
    { id: "phase", disablePadding: false, label: "PHASE", sortable: true },
    { id: "confirmedBy", disablePadding: false, label: "CONFIRMED BY", sortable: true },
    { id: "confirmedDate", disablePadding: false, label: "CONFIRMED DATE", sortable: true },
    { id: "", disablePadding: false, label: "ACTION", sortable: false },
  ]
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.TableCellStyle}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ClientInformation = () => {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [confirmsuccessdialog, setConfirmSuccessDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(" ");
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [getRow, setGetRow] = useState<IClientCodeConfirmation>();
  const [clientConfirmation, setClientInformationTrigger] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  const [clientSearch, setClientSearch] = useState(false);
  const [GetClients, setGetClients] = useState<IClientCodeConfirmation[]>([]);
  const [totalRecordCount, settotalrecordCount] = useState(0);
  const [confirmedCountone, setConfirmedCountone] = useState(0);
  const [notconfirmedCountone, setNotconfirmedCountone] = useState(0);
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setClientInformationTrigger(false);
  };

  const handleRequestSort = (event, property) => {
    setShowProgressBar(true)
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setShowProgressBar(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setShowProgressBar(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProgressBar(true);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleCloseSuccessDialog() {
    setConfirmSuccessDialog(false);
  }
  function openUnlockDialog() {
    setConfirmSuccessDialog(true);
  }

  useEffect(() => {
    GetClientInformation();
  }, [rowsPerPage, page])

  async function GetClientInformation() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      let request = {
        "user_Id": state.userAccessContext?.id,
        "isAdmin": true,
        "rowsPerPage": rowsPerPage,
        "searchParameters": searchClient.trim() ?? "",
        "startingRecordNumber": (page * rowsPerPage) + 1
      }
      const response = await usePost<IClientCodeConfirmation[]>("Client/SearchClientConfirmation", request);
      setGetClients(response.data["clientCodeConfirmations"]);
      settotalrecordCount(response.data["count"]);
      setConfirmedCountone(response.data["clientConfirmationTypes"][0].typeCount);
      setNotconfirmedCountone(response.data["clientConfirmationTypes"][1].typeCount);
      setClientSearch(true);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
      setShowProgressBar(false);
    }
  }

  async function SearchClientInformation() {
    dispatch({ type: GlobalStateAction.Busy })
    setShowProgressBar(true);
    try {
      let request = {
        "user_Id": state.userAccessContext?.id,
        "isAdmin": true,
        "rowsPerPage": rowsPerPage,
        "searchParameters": searchClient?.trimLeft().trimRight() ?? "",
        "startingRecordNumber": (page * rowsPerPage) + 1
      }
      const response = await usePost<IClientCodeConfirmation[]>("Client/SearchClientConfirmation", request);
      setGetClients(response.data["clientCodeConfirmations"]);
      settotalrecordCount(response.data["count"]);
      setConfirmedCountone(response.data["clientConfirmationTypes"][0].typeCount);
      setNotconfirmedCountone(response.data["clientConfirmationTypes"][1].typeCount);
      setClientSearch(true);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
      setShowProgressBar(false);
      setPage(0);
    }
  }

  async function SearchClientInformationClear() {
    dispatch({ type: GlobalStateAction.Busy })
    setShowProgressBar(true);
    try {
      let request = {
        "user_Id": state.userAccessContext?.id,
        "isAdmin": true,
        "rowsPerPage": rowsPerPage,
        "searchParameters": "",
        "startingRecordNumber": (page * rowsPerPage) + 1
      }
      const response = await usePost<IClientCodeConfirmation[]>("Client/SearchClientConfirmation", request);
      setGetClients(response.data["clientCodeConfirmations"]);
      settotalrecordCount(response.data["count"]);
      setConfirmedCountone(response.data["clientConfirmationTypes"][0].typeCount);
      setNotconfirmedCountone(response.data["clientConfirmationTypes"][1].typeCount);
      setShowSearchIcon(true);
      setClientSearch(true);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
      setShowProgressBar(false);
      setPage(0);
    }
  }

  async function unlockClient(event, row: IClientCodeConfirmation) {
    if (row != null) {
      row.isConfirmed = "No";
      const response = await usePost<{ clientCodeConfirmation: IClientCodeConfirmation }>("Client/ClientStatusConfirmation", row)
      if (response.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText });
      } else {
        setClientInformationTrigger(true);
        setConfirmSuccessDialog(false);
      }
    }
  }

  function clearSearchValues() {
    setSearchClient("");
    SearchClientInformationClear();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={0} className={classes.grid} wrap="wrap">
          <Grid item xs={6} sm={2}>
            <Typography variant="h6" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}  >
              <b> CLIENT INFORMATION </b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} >
            <TextField value={searchClient} variant="outlined"
              type='text' placeholder="Search (client code ,client name)"
              className={classes.searchBar}
              onChange={e => setSearchClient(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchClient != "") {
                  SearchClientInformation(); setShowSearchIcon(false);
                } else if (event.key === 'Enter' && searchClient === "") {
                  SearchClientInformation(); setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxlength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { SearchClientInformation(); setShowSearchIcon(false); }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton onClick={e => { clearSearchValues(); }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} >
            <Box alignItems="flex-start" display="flex" justifyContent="flex-start">
              <Box display="flex" m={1}>
                <Typography variant="subtitle1" gutterBottom className={classes.confirmText}>
                  <b> CONFIRMED: </b>
                </Typography>
              </Box>
              <Box display="flex" m={1}>
                <Button id="Cinfo_ConfCount" size="small" className={classes.confirmButton}
                  variant="contained" startIcon={<NotificationsActiveIcon />} >
                  {confirmedCountone}
                </Button>
              </Box>
              <Box display="flex" m={1}>
                <Typography variant="subtitle1" gutterBottom className={classes.confirmText}>
                  <b> NOT CONFIRMED : </b>
                </Typography>
              </Box>
              <Box display="flex" m={1}>
                <Button id="Cinto_NotConfCount" size="small" className={classes.confirmButton1}
                  variant="contained" startIcon={<NotificationsActiveIcon />} >
                  {notconfirmedCountone}
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table size="small" stickyHeader aria-label="sticky table" >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody >
                    {stableSort(GetClients, getComparator(order, orderBy)).map(
                      (row) => {
                        let confirmedDate = moment(row.confirm_Date).format(state.GlobalUtils?.settingValue);
                        let phase = (row.phase == constant.PREC ? "Flat Fee" : row.phase == constant.CONT ? "Contingency" : "1st Party");
                        return (
                          <StyledTableRow style={{ padding: 20 }} >
                            <StyledCell align="left" className={classes.TableCellStyle1}>
                              <span className={`${searchClient.toUpperCase() === "" ? classes.TextNormal : row.clientCode.includes(searchClient.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.clientCode}
                              </span>
                            </StyledCell>
                            <StyledCell align="left" className={classes.TableCellStyle1}>
                              <span className={`${searchClient === "" ? classes.TextNormal : row.clientName.includes(searchClient) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.clientName}
                              </span>
                            </StyledCell>
                            <StyledCell align="left" className={classes.TableCellStyle1}>
                              {phase}
                            </StyledCell>
                            <StyledCell align="left" className={classes.TableCellStyle1}>
                              {row.confirmed_By}
                            </StyledCell>
                            <StyledCell align="left" className={classes.TableCellStyle1}>
                              {confirmedDate === 'Invalid date' ? null : confirmedDate}
                            </StyledCell>
                            <StyledCell align="center" className={classes.TableCellStyle1}>
                              <Button className={classes.unlockbutton} size="small" style={{ display: row.isConfirmed === "Yes" ? "block" : "none" }}
                                variant="contained" color="primary" onClick={(e) => { setGetRow(row); openUnlockDialog(); }}>
                                <LockOpenIcon className={classes.IconStyle} />&nbsp;&nbsp;&nbsp; Unlock
                              </Button>
                            </StyledCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
                {clientSearch && !GetClients.length && !state.busy ?
                  <Typography variant="h6" gutterBottom className={classes.norecord}>
                    No records to display..
                  </Typography>
                  : null}
              </TableContainer>
              <Box p={0} display="flex">
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">
                    <TablePagination
                      id="Cinfo_TablePagination"
                      rowsPerPageOptions={[10, 15, 25]}
                      component="div"
                      count={totalRecordCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 15 } }} open={confirmsuccessdialog} onClose={handleCloseSuccessDialog}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseSuccessDialog}>
              <Typography variant="h6" gutterBottom className={classes.titleheader}>
                CLIENT INFORMATION
              </Typography>
            </DialogTitleHeader>
            <DialogContent>
              <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                Are you sure, you want to Unlock client Information?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button id="Conf_Okay_btn" size="small" className={classes.yesbutton} onClick={(e) => { unlockClient(e, getRow) }} autoFocus>
                Yes
              </Button>
              <Button id="Conf_Cancel_btn" size="small" onClick={handleCloseSuccessDialog} className={classes.nobutton}>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar open={clientConfirmation} className="snackBarStyle" anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
              Client Unlocked Successfully.
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </React.Fragment >
  )
}

export default ClientInformation
