import {
    Avatar, Backdrop, Button, CircularProgress, FormControl, Grid, Hidden, IconButton, InputLabel, makeStyles, Paper, Select, Snackbar, Table, Theme,
    TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, Box
} from '@material-ui/core';
import moment from 'moment';
import { CSVLink } from "react-csv";
import DateFnsUtils from '@date-io/date-fns';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import clearIcon from '../images/buttonIcons/clearIcon.svg';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
// Componants
import Footer from '../components/Footer/Footer';
import StyledCell from "../components/GlobalStyles/StyledCell";
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import AvatarTooltip from '../components/GlobalStyles/AvatarTooltip';
import DocumentPDFViewer from './DocumentUploadLog/DocumentPDFViewer';
import StyledTableRow from '../components/GlobalStyles/StyledTableRow';
import DownloadDocumentLogFile from ".././pages/DocumentUploadLog/DownloadDocumentLogFile";
//Imges for Icon
import download from "../images/files/download01.svg";
import FileAdmin from '../images/users/FileAdmin.svg';
import SuperAdmin from '../images/users/SuperAdmin.svg';
import GlobalAdmin from '../images/users/ClientAdmin.svg';
import ClientAdmin from '../images/users/GlobalAdmin.svg';
import RegularUser from '../images/users/RegularUser.svg';
import AgentUser from '../images/users/AgentUser.svg';
// Models
import { useFetch, usePost } from '../utils/apiHelper';
import { IGetUsers } from "../models/UploadLog/GetUsersList";
import { IAdminGetClients } from "../models/UploadLog/AdminGetClients";
import { useGlobalState } from '../store/GlobalStore';
import { UserRole } from '../models/accesscontrol/ClientUserAccessContext';
import { IGetDocumentUploadLogData } from '../models/DocumentUploadLog/GetDocumentUploadLog';
import { IGetDocumentUploadLogCSVData } from '../models/DocumentUploadLog/IGetDocumentUploadLogCSVData';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "2cm",
        alignItems: "center"
    },
    titleHead: {
        marginLeft: '5px'
    },
    buttons: {
        borderRadius: 20,
        fontSize: '11px',
        background: "#007FFF",
        color: "white",
        alignContent: 'flex-start',
        justify: "flex-start",
        paddingLeft: 8,
        marginTop: '17px'
    },
    ClearFilterButtonStyle: {
        borderRadius: 20,
        fontSize: '11px',
        background: "#007FFF",
        color: "white",
        marginLeft: '10px',
        paddingLeft: 8,
        marginTop: '17px'
    },
    textField: {
        width: 240,
        alignContent: 'flex-start',
        justify: "flex-start",
        marginTop: '14px',
        '& .MuiFormControl-marginNormal': {
            marginTop: '10px'
        }
    },
    textField1: {
        width: 220,
        alignContent: 'flex-start',
        justify: "flex-start",
        display: 'flex',
        marginTop: '-5px',
    },
    userdropdown: {
        width: 240,
        alignContent: 'flex-start',
        justify: "flex-start",
        display: 'flex',
        marginTop: '-4px',
    },
    table: {
        minWidth: 700
    },
    grid: {
        padding: '8px',
    },
    tablebody: {
        maxHeight: 470,
        minHeight: 470,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 270px)',
            minHeight: 'calc( 100vh - 270px)',
        },
    },
    root: {
        flexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    progressBar: {
        height: '6px'
    },
    Title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    IconStyle: {
        height: '15px',
        width: '15px',
        marginLeft: '2px'
    },
    paper1: {
        border: "2px solid black",
    },
    downloadIconStyle: {
        padding: '0px',
        marginLeft: '20px'
    },
    ErrorMessageStyle: {
        color: 'red',
        marginLeft: '57px',
        fontSize: '16px'
    },
    gridheader: {
        marginTop: '2px',
        borderRadius: '10px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))',
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '4px 10px'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '0px 10px'
    },
    dropdownStyle: {
        marginLeft: "-50px",
        marginTop: "8px",
        width: 240,
    },
    errorMessage: {
        color: "red",
        marginTop: "10px"
    },
    clientAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    globalAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    regularUser: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    fileAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid gray',
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
    },
    autoCompleteFont: {
        fontSize: 13,
    },
    smallFont: {
        fontSize: 13,
        padding: '2px 5px'
    },
    downloadgrid: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const usePopoverStyles = makeStyles({
    paper: {
        border: '2px solid black'
    }
});

const DocumentUploadlog = () => {
    const classes = useStyles();
    const popoverClasses = usePopoverStyles();
    const { state } = useGlobalState();

    const [csvData, setCSVData] = useState<IGetDocumentUploadLogCSVData[]>([]);
    const [ListLog, setListLog] = useState<IGetDocumentUploadLogData[]>([]);
    const [clientList, setclientList] = useState<IAdminGetClients[]>([]);
    const [activeUsers, setactiveUsers] = useState<IGetUsers[]>([]);;

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());

    const [SelectActiveUser, setSelectActiveUser] = useState<any>([]);
    const [SelectClients, setSelectClients] = useState<any>([]);

    const [orderBy, setOrderBy] = useState(" ");
    const [order, setOrder] = useState("asc");

    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(true);
    const [selectedUser, setSelectedUser] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [open, setOpen] = useState(false);
    const [mapped, setMapped] = useState(false);

    const usersLoading = activeUsers.length === 0;
    const clientsLoading = clientList.length === 0;
    const UserName = state.userAccessContext?.id;
    const ClientCode = state.userAccessContext?.clientCodes.join(",");

    const [totalRecords, setTotalRecords] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [page, setPage] = useState(0);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const headers = [
        {
            label: 'File Name',
            key: 'fileName'
        },
        {
            label: 'Date',
            key: 'date'
        },
        {
            label: 'Time',
            key: 'time'
        },
        {
            label: 'Debtor Id',
            key: 'debtorId'
        },
        {
            label: 'User Name',
            key: 'userName'
        },
        {
            label: 'Client Id',
            key: 'clientId'
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearFilter(false);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setShowProgressBar(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Get the Users List Based on users Role
    useEffect(() => {
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IGetUsers[]>("UploadLog/UserGet").then((GetUsersList) => {
                    setactiveUsers(GetUsersList?.data);
                });
            }
            else if (state?.userAccessContext?.role === 1) {
                await useFetch<IGetUsers[]>(`UploadLog/Getuserforclientuploadlog?userid=${UserName}`).then((GetUserForClient) => {
                    setactiveUsers(GetUserForClient?.data);
                });
            }
            else if (state?.userAccessContext?.role === 0) {
                await useFetch<IGetUsers[]>(`UploadLog/Getuserforclientuploadlog?userId=${UserName}`).then((GetUserForClient) => {
                    setactiveUsers(GetUserForClient?.data);
                });
            }
        })()
    }, [])

    // Get the Client Code Based on Users Role
    useEffect(() => {
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                    setclientList(AdminGetClients?.data);
                });
            }
            else if (state?.userAccessContext?.role === 1) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                    setclientList(AdminGetClients?.data);
                });
            }
            else if (state?.userAccessContext?.role === 0) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                    setclientList(AdminGetClients?.data);
                });
            }
        })()
    }, [])

    // Default Call to List the Documents Uploaded Log
    useEffect(() => {
        if (state.userAccessContext.role === 0) {
            getRegularUserUploadLogListAsync();
        } else if (state.userAccessContext.role === 1) {
            getClientAdminUploadLogListAsync();
        } else {
            getUploadLogListAsync();
        }
    }, [rowsPerPage, page])

    const HandleFilter = () => {
        setPage(0);
        setOpen(true);
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                getUploadLogListAsync();
            } else if (state?.userAccessContext?.role === 1) {
                getClientAdminUploadLogListAsync();
            } else {
                getRegularUserUploadLogListAsync();
            }
        })()
    }

    // This call to get the Document upload list for Super Admin and global Admin
    async function getUploadLogListAsync() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
            "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
            "userid": (selectedUser ? (SelectActiveUser === null ? '' : SelectActiveUser.id) : ''),
            "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients.client_code) : ''),
            "usertype": (PortalUser.role === 10 ? null : PortalUser.role),
            "isclientadmin": (state?.userAccessContext?.role === 3 ? 3 : 2),
            "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser?.id) : ''),
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };
        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    // This call to get the Document upload list for Client Admin
    async function getClientAdminUploadLogListAsync() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : !selectedFirstDate && StartDate ? new Date() : ''),
            "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : !selectedEndDate && EndDate ? new Date() : ''),
            "userid": (state.userAccessContext.id),
            "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients?.client_code) : ClientCode),
            "usertype": (PortalUser.role === 10 ? null : PortalUser.role),
            "isclientadmin": 1,
            "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser?.user_id) : ""),
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };
        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    // This call to get the Document upload list for Regular User
    async function getRegularUserUploadLogListAsync() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": (selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : !selectedFirstDate && StartDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
            "enddate": (selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : !selectedEndDate && EndDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
            "userid": state?.userAccessContext?.id,
            "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients?.client_code) : ClientCode),
            "usertype": (PortalUser.role === 10 ? null : PortalUser.role),
            "isclientadmin": 0,
            "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser?.user_id) : ""),
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };
        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    const handleClearFilter = () => {
        setPage(0);
        setClearFilter(true);
        setSelectActiveUser([]);
        setSelectClients([]);
        setSelectedFirstDate(false);
        setSelectedEndDate(false);
        setStartDate(null);
        setEndDate(null);
        if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
            PortalUser.role = 10;
            clearUploadLogListAsync();
        } else if (state?.userAccessContext?.role === 1) {
            ClearUploadLogListAsyncClientAdmin();
        } else {
            ClearUploadLogListAsyncClientUser();
        }
    }

    async function clearUploadLogListAsync() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": '',
            "enddate": '',
            "userid": '',
            "clientid": '',
            "usertype": (PortalUser.role === 10 ? null : PortalUser.role),
            "isclientadmin": (state?.userAccessContext?.role === 3 ? 3 : 2),
            "filteruserid": '',
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };

        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
            setSelectedUser(false);
            setSelectedClient(false);
            PortalUser.role = 10
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    async function ClearUploadLogListAsyncClientAdmin() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": '',
            "enddate": '',
            "userid": state?.userAccessContext?.id,
            "clientid": ClientCode,
            "usertype": null,
            "isclientadmin": 1,
            "filteruserid": '',
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };

        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
            setSelectedUser(false);
            setSelectedClient(false);
            PortalUser.role = 10
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    async function ClearUploadLogListAsyncClientUser() {
        setShowProgressBar(true);
        let request = {
            "rowsPerPage": rowsPerPage,
            "startdate": '',
            "enddate": '',
            "userid": state?.userAccessContext?.id,
            "clientid": ClientCode,
            "usertype": null,
            "isclientadmin": 0,
            "filteruserid": '',
            "startingRecordNumber": (page * rowsPerPage) + 1,
        };

        await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
            setListLog(GetDocUploadLog.data["documentUploadLog"]);
            setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
            setTotalRecords(GetDocUploadLog.data?.qty);
            setSelectedUser(false);
            setSelectedClient(false);
            PortalUser.role = 10
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    // Export Document for Global Admin and Super Admin
    useEffect(() => {
        if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
            getAdminDocumentlog();
        } else if (state.userAccessContext.role === 1) {
            getClientAdminDocumentlog();
        } else if (state.userAccessContext.role === 0) {
            getRegularUserDocumentlog();
        }

    }, [totalRecords])


    async function getAdminDocumentlog() {
        (async () => {
            if (totalRecords !== undefined && totalRecords > 0) {
                let request = {
                    "rowsPerPage": totalRecords,
                    "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
                    "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
                    "userid": (selectedUser ? (SelectActiveUser === null ? null : SelectActiveUser.id) : null),
                    "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients.client_code) : null),
                    "userType": null
                };
                setShowProgressBar(true);
                await usePost<{ documentUploadLog: IGetDocumentUploadLogCSVData[] }>("DocumentUploadLog/ExportDocumentUploadLog", request).then((GetDocUploadLog) => {
                    setCSVData(GetDocUploadLog.data["documentUploadLog"]);
                }).finally(() => {
                    setShowProgressBar(false);
                });
            }
        })()
    }

    // Export Document for Client Admin
    async function getClientAdminDocumentlog() {
        (async () => {
            if (totalRecords !== undefined && totalRecords > 0) {
                let request = {
                    "rowsPerPage": totalRecords,
                    "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
                    "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
                    "userid": (selectedUser ? (SelectActiveUser === null ? null : SelectActiveUser.id) : null),
                    "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients.client_code) : null),
                    "userType": 1
                };
                setShowProgressBar(true);
                await usePost<{ documentUploadLog: IGetDocumentUploadLogCSVData[] }>("DocumentUploadLog/ExportDocumentUploadLog", request).then((GetDocUploadLog) => {
                    setCSVData(GetDocUploadLog.data["documentUploadLog"]);
                }).finally(() => {
                    setShowProgressBar(false);
                });
            }
        })()
    }

    // Export Document for Regular User
    async function getRegularUserDocumentlog() {
        (async () => {
            if (totalRecords !== undefined && totalRecords > 0) {
                let request = {
                    "rowsPerPage": totalRecords,
                    "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
                    "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
                    "userid": (selectedUser ? (SelectActiveUser === null ? null : SelectActiveUser.id) : null),
                    "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients.client_code) : ClientCode),
                    "userType": 0
                };
                setShowProgressBar(true);
                await usePost<{ documentUploadLog: IGetDocumentUploadLogCSVData[] }>("DocumentUploadLog/ExportDocumentUploadLog", request).then((GetDocUploadLog) => {
                    setCSVData(GetDocUploadLog.data["documentUploadLog"]);
                }).finally(() => {
                    setShowProgressBar(false);
                });
            }
        })()
    }


    // Display Users List based on the User Role
    function onUsersSelect(event, value) {
        setSelectActiveUser(value);
        setSelectedUser(true);
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value?.id).then((GetUsers) => {
                    setactiveUsers(GetUsers?.data);
                });
            }
            else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
                await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + value.user_id).then((GetUserForClient) => {
                    setactiveUsers(GetUserForClient?.data);
                });
            }
        })()
    }

    // Display Client Code based on the User Role
    function onClientsSelect(event, value) {
        setSelectedClient(true);
        setSelectClients(value);
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((GetAdminClientCodes) => {
                    setclientList(GetAdminClientCodes.data);
                });
            }
            else if (state?.userAccessContext?.role === 1 || state?.userAccessContext?.role === 0) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((GetUsersClientCodes) => {
                    setclientList(GetUsersClientCodes?.data);
                });
            }
        })()
    }

    var Users: IGetDocumentUploadLogData = {
        file_id: "",
        file_name: "",
        upload_date: "",
        rid: "",
        clientId: "",
        userid: null,
        fullusername: "",
        global_admin: null,
        bloburi: "",
        role: UserRole.SelectRole
    }

    const [PortalUser, setPortalUser] = useState(Users);

    const handleNotificationTypeChange = (event) => {
        const name = event.target.name;
        if (event.target.value === "10") {
            setPortalUser({
                ...PortalUser,
                [name]: 10
            });
        } else {
            setPortalUser({
                ...PortalUser,
                [name]: event.target.value
            });
        }
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "", disablePadding: false, label: "", sortable: false, },
            { id: "fullusername", disablePadding: false, label: "USER", sortable: true },
            { id: "role", disablePadding: false, label: "USER TYPE", sortable: true },
            { id: "clientId", disablePadding: false, label: "CLIENT ID", sortable: true },
            { id: "rid", disablePadding: false, label: "DEBTOR ID", sortable: true },
            { id: "upload_date", disablePadding: false, label: "DATE", sortable: true },
            { id: "upload_date", disablePadding: false, label: "TIME", sortable: true },
            { id: "file_id", disablePadding: false, label: "FILE", sortable: true },
            { id: "file_id", disablePadding: false, label: "PREVIEW", sortable: true }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            id="DocUpLog_TableHeader"
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellStyle}>
                            <TableSortLabel
                                id="DocUpLog_TableHeader_SortLable"
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>

                <Grid container alignItems="stretch" className={classes.grid}>
                    <Grid item xs={5} sm={5} md={3} className={classes.Title} >
                        <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleHead}`}>
                            <b> DOCUMENT UPLOAD LOG </b>
                        </Typography>
                    </Grid>

                    <Grid item xs={5} sm={5} md={7} className={classes.Title}>
                        {StartDate > EndDate ? <Typography variant='h6' className={classes.ErrorMessageStyle}>Invalid End Date.</Typography> : ""}
                    </Grid>

                    <Grid item xs={5} sm={5} md={2} className={classes.downloadgrid}>
                        <LightTooltip title="Download Log Files">
                            <IconButton id="DocUpLog_DownloadCSV_Button" aria-label="delete" disabled={!csvData.length || mapped} className={classes.downloadIconStyle}>
                                <CSVLink
                                    data={csvData}
                                    headers={headers}
                                    filename={"DocumentLogFiles.csv"}
                                    className="btn btn-primary"
                                    disabled={!ListLog.length || mapped}
                                    target="_blank">
                                    <img src={download} alt="Icon for Download" width="30px" height="30px" style={!csvData.length ? { color: "#a1a1a1" } : { color: "blue" }} />
                                </CSVLink>
                            </IconButton>
                        </LightTooltip>
                    </Grid>
                    <Grid container xs={12} spacing={0} justify='space-evenly' className={classes.gridheader}>
                        <Grid item xs={6} sm={5} md={3} lg={2} justify="flex-start">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    size="small"
                                    label="Start Date"
                                    PopoverProps={{ classes: popoverClasses }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={DateFormat}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    id="DocUpLog_StartDate"
                                    value={StartDate}
                                    onChange={handleFirstDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={5} md={3} lg={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    size="small"
                                    label="End Date"
                                    PopoverProps={{ classes: popoverClasses }}
                                    disableToolbar
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    format={DateFormat}
                                    className={classes.textField}
                                    margin="normal"
                                    id="DocUpLog_EndDate"
                                    value={EndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {state?.userAccessContext.role === 0 ? null :
                            <Grid item xs={6} sm={5} md={3} lg={2}>
                                <Autocomplete
                                    size="small"
                                    id="DocUpLog_SelectUser_DropDown"
                                    autoComplete autoHighlight
                                    className={classes.userdropdown}
                                    classes={{
                                        paper: classes.paper1,
                                        option: classes.autoCompleteFont
                                    }}
                                    options={activeUsers}
                                    getOptionLabel={(option) => option.fullname}
                                    value={SelectActiveUser}
                                    onChange={onUsersSelect}
                                    loading={usersLoading}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Users" margin="normal" variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {usersLoading ? <CircularProgress size={25} color="inherit" /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }} />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={5} md={3} lg={2} >
                            <Autocomplete
                                size="small"
                                id="DocUpLog_SelectClientCode_DropDown"
                                autoComplete autoHighlight
                                classes={{
                                    paper: classes.paper1,
                                    option: classes.autoCompleteFont
                                }}
                                className={classes.userdropdown}
                                options={clientList}
                                getOptionLabel={(option) => option.client_code}
                                value={SelectClients}
                                onChange={onClientsSelect}
                                loading={clientsLoading}
                                renderInput={(params) => (
                                    <TextField {...params} label="Clients" margin="normal" variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {clientsLoading ? <CircularProgress size={25} color="inherit" /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }} />
                                )} />
                        </Grid>
                        <Grid item xs={6} sm={5} md={3} lg={2} >
                            <FormControl variant="outlined" size="small" className={classes.textField1}>
                                <InputLabel id="demo-simple-select-label" variant="standard" className={classes.dropdownStyle}> User Type</InputLabel>
                                <Select native label="User Type" style={{ marginTop: '17px' }}
                                    value={PortalUser.role} onChange={handleNotificationTypeChange}
                                    inputProps={{
                                        name: 'role',
                                        id: 'DocUpLog_SelectRole_DropDown',
                                    }}>
                                    <option value={10} className={classes.smallFont} >--Select User Type--</option>
                                    <option value={0} className={classes.smallFont}>Regular User</option>
                                    <option value={1} className={classes.smallFont}>Client Admin</option>
                                    <option value={2} className={classes.smallFont}>Global Admin</option>
                                    <option value={3} className={classes.smallFont}>Super Admin</option>
                                    <option value={5} className={classes.smallFont}>Agent User</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={6} sm={5} md={3} lg={2} >
                                <Button id="DocUpLog_Filter_Button" size="small" className={classes.buttons} startIcon={<FilterAltIcon />} onClick={() => { HandleFilter(); }} variant="contained" color="primary" disabled={StartDate > EndDate} >
                                    Filter
                                </Button>
                                <Button id="UpLog_Filter_Button" size="small" className={classes.ClearFilterButtonStyle} startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}
                                    onClick={() => { handleClearFilter(); }} variant="contained" color="primary" >
                                    Clear Filter
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={4} sm={5} md={3} lg={2} alignContent='flex-start'>
                                <AvatarTooltip title="Filter">
                                    <IconButton id="DocUpLog_Filter_IconButton" aria-label="Filter" onClick={HandleFilter} style={{ color: 'blue' }} disabled={StartDate > EndDate} >
                                        <FilterListIcon />
                                    </IconButton>
                                </AvatarTooltip>
                                <AvatarTooltip title="Clear Filter">
                                    <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { setClearFilter(true); }} style={{ color: 'blue' }} >
                                        <ClearRoundedIcon />
                                    </IconButton>
                                </AvatarTooltip>
                            </Grid>
                            <Grid item xs={2} sm={6} md={1} alignContent='flex-start'>
                                <IconButton id="DocUpLog_DownloadCSV_IconButton_MB" aria-label="CSVDownload" disabled={!csvData.length && mapped === true}>
                                    <CSVLink
                                        data={csvData}
                                        headers={headers}
                                        filename={"DocumentLogFiles.csv"}
                                        className="btn btn-primary"
                                        disabled={!ListLog.length && mapped === true}
                                        target="_blank">
                                        <img src={download} alt="Icon for Download" width="30px" height="30px" style={!csvData.length ? { color: "#a1a1a1" } : { color: "blue" }} /* fontSize="large" */ />
                                    </CSVLink>
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`} >
                                <Table id="DocUpLogData" aria-label="customized table" size="small" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort} />
                                    <TableBody>
                                        {stableSort(ListLog, getComparator(order, orderBy)).map(
                                            (row) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                                                            {row.global_admin === 3 ?
                                                                <AvatarTooltip title="Super Admin">
                                                                    <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                                                </AvatarTooltip>
                                                                : row.global_admin === 2 ?
                                                                    <AvatarTooltip title="Global Admin">
                                                                        <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                                                    </AvatarTooltip>
                                                                    : row.global_admin === 1 ?
                                                                        <AvatarTooltip title="Client Admin">
                                                                            <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                                                        </AvatarTooltip>
                                                                        : row.global_admin === 0 ?
                                                                            <AvatarTooltip title="Regular User">
                                                                                <Avatar className={classes.regularUser} src={RegularUser}></Avatar>
                                                                            </AvatarTooltip>
                                                                            : row.global_admin === 5 ?
                                                                            <AvatarTooltip title="Agent User">
                                                                              <Avatar className={classes.regularUser} src={AgentUser}></Avatar>
                                                                            </AvatarTooltip>
                                                                            : <AvatarTooltip title="File Admin">
                                                                                <Avatar className={classes.fileAdmin} src={FileAdmin}></Avatar>
                                                                            </AvatarTooltip>
                                                            }
                                                        </StyledCell>
                                                        <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                                                            {row.fullusername}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            {(row.global_admin === 0) ? "Regular User" : (row.global_admin === 1) ? "Client Admin" : (row.global_admin === 2) ? "Global Admin" : (row.global_admin === 3) ? "Super Admin" : " Agent User"}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            {row.clientId}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            {row.rid}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            {moment(row.upload_date).format(state.GlobalUtils?.settingValue)}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            {moment(row.upload_date).format('HH:mm:ss')}
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            <DownloadDocumentLogFile row={row} />
                                                        </StyledCell>

                                                        <StyledCell className={classes.TableCellStyle1}>
                                                            <DocumentPDFViewer row={row} enable={row?.file_name?.split('.')?.pop() === 'pdf' ? true : row?.file_name?.split('.')?.pop() === 'PDF' ? true : false} />
                                                        </StyledCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {mapped === true ?
                                    < Typography variant="h6" gutterBottom className={classes.errorMessage}>
                                        No records to display..
                                    </Typography>
                                    : null}
                            </TableContainer>
                            <Grid container spacing={0}>
                                <Box m={0} width="65%">
                                    <div className="footer">
                                        <Footer />
                                    </div>
                                </Box>
                                <Box m={0} width="35%">
                                    <div className="pagination">
                                        <TablePagination
                                            id="DocUpLog_Pagination"
                                            rowsPerPageOptions={[15, 25, 50]}
                                            component="div"
                                            count={totalRecords === undefined ? 0 : totalRecords}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div >

            <Snackbar className="snackBarStyle" open={open} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Filter Successfully Triggered!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={clearFilter} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert className="alertStyle" onClose={handleClose} severity="success">
                    Filter Cleared Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default DocumentUploadlog