import { Checkbox, CheckboxProps, Collapse, makeStyles, TableCell, TableRow, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React from 'react';
import { IWebViewFiles } from '../../models/Files/IWebViewFiles';
import FileAuditLog from './FileAuditLog';

const useRowStyles = makeStyles({
    AuditTableStyle: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    root: {
        fontSize: 20,
        padding: '2px',
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
        paddingRight: '0px',
    },
    rootHidden: {
        display: "none"
    },
    tableStyle: {
        border: '2px solid gray',
        borderRadius: '5px',
        '&.MuiTableCell-sizeSmall': {
            padding:'4px'
        }
    }
});

const FileLogRequest: React.FC<{ row: IWebViewFiles, selected: boolean, onClick: (fileId: number) => void, categoryId: number }> = (props) => {
    const { row } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={props.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={6} className={classes.tableStyle}>
                    <Collapse in={props?.selected} timeout="auto" unmountOnExit >
                        <FileAuditLog getFileId={row?.fileId} categoryId={props?.categoryId} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

export default FileLogRequest