import {
    Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel, Toolbar
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import AckExpandableList from '../components/AckReport/AckExpandableList';
import CreateAckReport from "../components/AckReport/CreateAckReport";
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import { IAckList } from '../models/IAckReport/IAckList';
import { IAdminGetClients } from '../models/UploadLog/AdminGetClients';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import Footer from '../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    box: {
        display: "flex",
        marginLeft: "-10px",
        marginTop: '-0.2cm'
    },
    box1: {
        display: "flex",
        marginTop: "33px",
        marginLeft: "100px"
    },
    grid: {
        padding: '10px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tableBody: {
        maxHeight: 495,
        minHeight: 495,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
    div: {
        flexGrow: 1,
        padding: 8
    },
    ToolBarroot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid gray'
    },
    paper: {
        width: '100%'
    },
    videoPlayerStye: {
        marginTop: '50px'
    },
    footerBoxStyle: {
        color: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        gap: '25%'
    },
    textdisplay: {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    footer: {
        marginTop: '15px',
        float: 'right',
        marginRight: '250px'
    },
    noRecordsStyle: {
        color: "red",
        marginTop: "10px"
    },
}))

const AckReportList = () => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [selected, setSelected] = React.useState<string>();
    const [ReportsList, setReportsList] = useState<IAckList[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(" ");
    const video = 'GEWp0isvFNg';
    const title = 'A.R.M. Webview: Using Reports';
    const [Clients, setClientsList] = useState<IAdminGetClients[]>([]);;
    const [ProgressBar, setshowProgressBar] = useState(true);

    useEffect(() => {
        getReportsAsync();
    }, []);

    async function getReportsAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            let request = {
                "userId": state.userAccessContext?.id,
                "isAdmin": state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ? true : false
            };
            await usePost<any>("GetAckReportsByUser", request).then((reportList) => {
                setReportsList(reportList.data);
            });
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data);
                    });
                }
                else if (state.userAccessContext.role === 1) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data);
                    });
                }
                else if (state.userAccessContext.role === 0) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data);
                    });
                }
                setshowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId)
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const EnhancedTableToolbar = () => {
        const classes = useStyles();

        return (
            <Toolbar className={classes.ToolBarroot}>
                <Box display="flex" alignItems="flex-start" justifyContent="flex-start" width="50%">
                    <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
                        <b> ACKNOWLEDGEMENT REPORT </b>
                    </Typography>
                </Box>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" width="46%">
                    <CreateAckReport getReports={getReportsAsync} clients={Clients} />
                </Box>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" width="4%" className={classes.videoPlayerStye}>
                    <VideoPlayer video={video} title={title} />
                </Box>
            </Toolbar>
        );
    };

    const headCells = [
        { id: "reportName", numeric: false, disablePadding: false, label: "NAME", sortable: true },
        { id: "createdDate", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
        { id: "client_code", numeric: false, disablePadding: false, label: "CLIENT ID", sortable: false },
        { id: "phaseName", numeric: false, disablePadding: false, label: "PHASE", sortable: false },
        { id: "edit", numeric: false, disablePadding: false, label: "EDIT", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ background: "#007FFF", color: "white", fontSize: "120%" }} >
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar />
                    <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table stickyHeader size="small" aria-label="customized table" >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {stableSort(ReportsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                                    (row) => {
                                        return (
                                            <AckExpandableList onClick={handleRowClick} onDelete={getReportsAsync} onCreate={getReportsAsync} row={row} clients={Clients} />
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {!ReportsList.length && !ProgressBar ?
                            <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                No Reports to display...
                            </Typography>
                            : null
                        }
                    </TableContainer>
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={9}>
                            <div className={classes.footer}>
                                <Footer />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TablePagination
                                id="Report_TablePagination"
                                rowsPerPageOptions={[15, 25, 50]}
                                component="div"
                                count={ReportsList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </React.Fragment >
    )
}

export default AckReportList