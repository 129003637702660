import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import StyledCell from "../../../components/GlobalStyles/StyledCell";
import clearIcon from '../../../images/buttonIcons/clearIcon.svg';
import { IFunctionDetails } from '../../../models/GlobalUtil/IFunctionDetails';
import { IFunctionLogDetails } from '../../../models/GlobalUtil/IFunctionLogDetails';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 380,
  },
  userStyle: {
    width: '350px',
    marginTop: '-6px',
  },
  buttons: {
    borderRadius: 20,
    marginRight: theme.spacing(4),
    marginTop: '15px',
    fontSize: 12
  },
  buttons1: {
    borderRadius: 20,
    marginTop: '15px',
    fontSize: 12
  },
  textField: {
    marginTop: "10px",
    width: 350
  },
  grid: {
    padding: '8px'
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  filter: {
    marginTop: '20px'
  },
  root: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'red'
  },
  Title1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '10px',
    color: "blue"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 480,
    minHeight: 480,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 225px)',
      minHeight: 'calc( 100vh - 225px)',
    },
  },
  IconStyle: {
    width: '16px',
    marginTop: '-5px',
  },
  paper1: {
    border: "2px solid black",
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: 12,
    padding: '3px 15px'
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '7px 15px'
  },
  TableCellHeaderStyle: {
    background: "#007FFF",
    color: "white"
  },
  ErrorMessageStyle: {
    color: "red",
    marginTop: "10px",
    textAlign: 'center'
  },
  TableRowStyle: {
    height: 45
  },
  FontStyle: {
    fontSize: 13
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '70px'
  },
  norecord: {
    color: "red",
    marginTop: "10px"
  },
  titleheader1: {
    marginLeft: '10px',
    marginTop: '5px'
  },
}))

const usePopoverStyles = makeStyles({
  paper: {
    border: '2px solid black'
  }
});

const AzFunctionLogs = () => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { state, dispatch } = useGlobalState();
  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const [azfunctionLogsHistory, setAzFunctionLogsHistory] = useState<IFunctionLogDetails[]>([]);
  const [functionDetails, setFunctionDetails] = useState<IFunctionDetails[]>([]);
  const [SelectPipeline, setSelectPipeline] = useState<any>();

  const [StartDate, setStartDateFrom] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);

  const [orderBy, setOrderBy] = React.useState(" ");
  const [order, setOrder] = React.useState("asc");

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [mapped, setMapped] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(false);
  const functionLoading = functionDetails.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setClearFilter(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setshowProgressBar(true);
    setPage(newPage);
  };

  const handleFirstDateChange = (date: Date | null) => {
    setStartDateFrom(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setshowProgressBar(true);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        await useFetch<IFunctionDetails[]>("GlobalSettings/GetAZFunctionDetails").then((GetPipelineOptions) => {
          setFunctionDetails(GetPipelineOptions.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const onPipelineSelect = (event, selected) => {
    setPage(0);
    if (selected) {
      const selectedValue = functionDetails.find(x => x.functionName === selected.functionName);
      setSelectPipeline(selectedValue.functionName);
      setSelectedPipeline(true);
    }
    else {
      setSelectPipeline({ notificationName: "", functionName: "" });
    }
  };

  useEffect(() => {
    getAzFunctionAsync();
  }, [page, rowsPerPage, order, orderBy])

  const handleFilter = () => {
    getAzFunctionAsync();
    setOpen(true);
  }

  async function getAzFunctionAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    let request = {
      "runDateFrom": (selectedFirstDate && StartDate === null ? null : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
      "runDateTo": (selectedEndDate && EndDate === null ? null : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
      "functionName": (selectedPipeline ? (SelectPipeline.functionName === "" ? null : SelectPipeline) : null),
      "startRecordNumber": (page * rowsPerPage) + 1,
      "rowperpage": rowsPerPage,
    };
    await usePost<{ azFuncLogs: IFunctionLogDetails[], qty: number }>("GlobalSettings/GetAzFunctionsLogs", request).then((GetAzFunctionsLogsList) => {
      setAzFunctionLogsHistory(GetAzFunctionsLogsList.data["azFuncLogs"]);
      setTotalRecords(GetAzFunctionsLogsList.data.qty);
      setMapped(!GetAzFunctionsLogsList.data["azFuncLogs"].length ? true : false);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  async function ClearAzFunctionAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    let request = {
      "runDateFrom": null,
      "runDateTo": null,
      "functionName": null,
      "startRecordNumber": (page * rowsPerPage) + 1,
      "rowperpage": rowsPerPage,
    };
    await usePost<{ azFuncLogs: IFunctionLogDetails[], qty: number }>("GlobalSettings/GetAzFunctionsLogs", request).then((GetAzFunctionsLogsList) => {
      if (GetAzFunctionsLogsList.status == 200) {
        setClearFilter(true);
        setshowProgressBar(false);
      }
      setAzFunctionLogsHistory(GetAzFunctionsLogsList.data["azFuncLogs"]);
      setTotalRecords(GetAzFunctionsLogsList.data.qty);
      setMapped(!GetAzFunctionsLogsList.data["azFuncLogs"].length ? true : false);
    }).finally(() => {
      resetValues();
      setshowProgressBar(false);
    });
  }

  function resetValues() {
    setSelectedFirstDate(false);
    setSelectedEndDate(false);
    setSelectedPipeline(false);
    setStartDateFrom(null);
    setEndDate(null);
    setPage(0);
    setSelectPipeline([]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells = [
    { id: "azurefunctionName", numeric: false, disablePadding: false, label: "AZURE NAME" },
    { id: "functionName", numeric: false, disablePadding: false, label: "FUNCTION NAME", sortable: true },
    { id: "triggerTime", numeric: false, disablePadding: false, label: "TRIGGER DATE TIME", sortable: true, },
    { id: "triggerType", numeric: false, disablePadding: false, label: "TRIGGER TYPE", sortable: true },
    { id: "notificationName", numeric: false, disablePadding: false, label: "NOTIFICATION NAME", sortable: true },
  ];

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>

        <Grid container className={classes.grid} >
          <Grid item xs={12} >
            {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>End date should be greater than the Start date!</Typography> : ""}
          </Grid>

          <Grid container className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={4} sm={3} md={3} lg={2} className={classes.Title1} >
              <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleheader1}`}>
                <b> AZURE FUNCTION LOGS</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    size="small"
                    disableToolbar
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_StartDate"
                    value={StartDate}
                    onChange={handleFirstDateChange}
                    disableFuture={true}
                    PopoverProps={{ classes: popoverClasses }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    size="small"
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="End Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_EndDate"
                    value={EndDate}
                    onChange={handleEndDateChange}
                    inputVariant="outlined"
                    PopoverProps={{ classes: popoverClasses }}
                    disableFuture={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete size="small"
                  id="UpHis_SelectUsers"
                  autoComplete autoHighlight
                  classes={{
                    paper: classes.paper1,
                    option: classes.FontStyle
                  }}
                  options={functionDetails}
                  getOptionLabel={(option) => option.functionName}
                  value={SelectPipeline}
                  onChange={onPipelineSelect}
                  className={classes.userStyle}
                  loading={functionLoading}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span> {option.functionName}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="FunctionName" margin="normal" variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {functionLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={2} justify='space-evenly'>
                <Button id="UpHis_Filter_btn" size="small" className={classes.buttons}
                  onClick={() => { handleFilter(); }} variant="contained" color="primary" startIcon={<FilterAltIcon />} disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" size="small" className={classes.buttons1} startIcon={<img src={clearIcon} alt="Icon for clear filter" className={classes.IconStyle} />}
                  onClick={() => { ClearAzFunctionAsync(); setshowProgressBar(true); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item xs={4} sm={6} md={4} lg={2} alignContent='flex-start'>
                <IconButton id="UpHis_FilterIcon_btn" size="small" aria-label="Filter" style={{ color: 'blue' }} disabled={StartDate > EndDate}>
                  <FilterListIcon style={{ fontSize: 30 }} />
                </IconButton>
                <IconButton id="UpLog_Filter_IconButton" size="small" aria-label="Filter" onClick={() => { ClearAzFunctionAsync(); }} style={{ color: 'blue' }}>
                  <ClearRoundedIcon style={{ fontSize: 30 }} />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item component={Paper} xs={12} >
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(azfunctionLogsHistory, getComparator(order, orderBy)).map(
                      (row) => {
                        return (
                          <TableRow >
                            <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                              {row.azurefunctionName}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.functionName}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {moment(row.triggerTime).format(state.GlobalUtils?.settingValue)}
                              {moment(row.triggerTime).format(' HH:mm:ss')}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.triggerType}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.notificationName}
                            </StyledCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {mapped === true ?
                  < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography>
                  : null}
              </TableContainer>
              <Grid container spacing={0}>
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">
                    <TablePagination
                      id="UpHis_TablePagination"
                      rowsPerPageOptions={[15, 25, 50]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar className="snackBarStyle"
          open={open} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Successfully Triggered!
          </Alert>
        </Snackbar>
        <Snackbar className="snackBarStyle"
          open={clearFilter} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Cleared Successfully !
          </Alert>
        </Snackbar>

      </div>
    </React.Fragment >
  )
}

export default AzFunctionLogs