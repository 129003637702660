import { Backdrop, Button, Collapse, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as moment from "moment";
import { extendMoment } from "moment-range";
import * as React from 'react';
import { useEffect, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import roll from '../../images/buttonIcons/roll.svg';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { IRollPlacement } from '../../models/Inquiry/IRollPlacement';
import { IDebtor } from '../../models/Roll/IDebtor';
import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../utils/apiHelper';
import { InsertTransactionLog } from '../TransactionLogs/InsertTransactionLog';
import RollClientDetails from './RollClientDetail';

const useRowStyles = makeStyles((theme) => ({
    root: {
        fontSize: '10px',
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none",
        fontSize: '10px',
        textOverflow: 'ellipsis'
    },
    button: {
        fontSize: 12,
        borderRadius: 20,
        width: 80,
        padding: '2px'
    },
    TextHighlight: {
        color: 'black',
        fontWeight: 'bold',
        background: "yellow",
        fontSize: 10.5
    },
    TextNormal: {
        color: 'black',
        fontSize: 10.5
    },
    removeStyle: {
        color: 'red',
        fontSize: '12px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tableCellStyle: {
        padding: '0px 2px 0px 2px'
    },
    customTable: {
        padding: "0px 5px", // <-- arbitrary value
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 5px" // <-- arbitrary value
        }
    },
    replyIcon: {
        padding: '2px 2px',
        marginLeft: '5px',
    },
    removedButton: {
        fontSize: 12,
        borderRadius: 20,
        width: 100,
        padding: '2px'
    }
}))

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}
export interface RollMessageState extends SnackbarOrigin {
    open: boolean;
}

const RollExpandableRow: React.FC<{ row: IDebtor, selected: boolean, onClick: (accountId: string) => void, onDelete: () => void, onRollRemove: () => void, onUndoCloseRemove: () => void, onEroll: () => void, selectedDBTNumber: string, searchWord: string, GetCount: () => void }> = (props) => {
    const { row, searchWord } = props;
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();
    const [clicked, setClicked] = useState(false);
    const [textColor, setTextColor] = useState(row.removed ? '#FF0000' : 'black');
    const [isDelete, setIsDelete] = useState(false);
    const [textLine, setTextLine] = useState(row.removed ? 'line-through' : 'none');
    const [BtnColor, setBtnColor] = useState(row.removed ? '#FF0000' : 'primary');
    const [Message, setMessage] = useState(false);
    const UserName = state.userAccessContext?.id;
    const StatusDate = moment.utc(row.dbT_STATUS_DATE).format(state.GlobalUtils?.settingValue);
    const { range } = extendMoment(moment);
    const start = row.dbT_REFERRAL_DATE;
    const end = Date.now();
    const timeRange = range(moment.utc(start), moment.utc(end));
    const RefferalDate = timeRange.diff('days');
    const [RollUndo, setRollUndo] = useState(false);
    const [RemoveUndo, setRemoveUndo] = useState(false);
    const [isActiveStatus, setGetActiveStatus] = useState(false);
    const prevCount = usePrevious(props.selectedDBTNumber);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClose = () => {
        setMessage(false);
    };

    const handleChangeTextColor = () => {
        setTextColor(textColor === 'black' ? '#FF0000' : 'black');
        setTextLine(textLine === 'none' ? 'line-through' : 'none');
        setBtnColor(BtnColor === 'primary' ? '#FF0000' : 'primary');
    }

    const [AdvanceToContengency, setAdvanceToContengency] = useState(row.rolled);
    const [RollRemoved, setRollRemoved] = useState(row.removed);

    function OnClickAdvanceToContengency() {
        setIsDelete(!isDelete);
        Roll();
    }

    function OnClickRowDelete() {
        handleChangeTextColor();
        setIsDelete(!isDelete);
        setClicked(!clicked);
        RollDeleteRow();
    }

    const Roll = () => {
        (async () => {
            setShowProgressBar(true);
            let request = {
                "dbt_No": row.dbT_NO,
                "dbt_Client": row.dbT_CLIENT,
                "user_Id": UserName
            };

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Roll Account", operationText: "Debtor rolled manually"
            };
            feedOperation.push(operations);

            await usePost<IPlacedAccountContract[]>('RolledPlacement', request);
            props?.GetCount();
            setAdvanceToContengency(true);
            props.onEroll();
            InsertTransactionLog(state?.userAccessContext?.id, row.dbT_NO, feedOperation);
            setMessage(true);
            setShowProgressBar(false);
        })()
    }

    const RollDeleteRow = () => {
        (async () => {
            setShowProgressBar(true);

            let request = {
                "dbt_No": row.dbT_NO,
                "dbt_Client": row.dbT_CLIENT,
                "user_Id": UserName
            };
            await usePost<IPlacedAccountContract>('RollDeleteRow', request);
            props.GetCount();
            setRollRemoved(true);
            setTextColor('#FF0000');
            setTextLine('line-through');
            setBtnColor('#FF0000');
            props.onRollRemove();
            setShowProgressBar(false);

        })()
    }

    const undoRoll = () => {
        (async () => {
            await useDelete('RolledPlacement?DBTNumber=' + row.dbT_NO);
            props.GetCount();
            props.onDelete();
            setAdvanceToContengency(false);
            props.onDelete();
            setShowProgressBar(false);
            setRollUndo(true);

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Rolled Account", operationText: "Manually rolled debtor undone"
            };
            feedOperation.push(operations);

            InsertTransactionLog(state?.userAccessContext?.id, row.dbT_NO, feedOperation);
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            let request = { "dbtNumber": row.dbT_NO };
            await usePost<IPlacedAccountContract>('Account/UndoCloseRemove', request);
            props.GetCount();
            setTextLine('none');
            setTextColor('black');
            setRollRemoved(false);
            props.onUndoCloseRemove();
        })()
    }



    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRollUndo(false);
        setRemoveUndo(false);
        setMessage(false);
    };

    const setYieldLock = () => {
        (async () => {
            await useFetch<any>(`InquiryYieldLock?debtorNumber=${row.dbT_NO}&userId=${state.userAccessContext?.id.toString()}`).then((YieldLock) => {
                setGetActiveStatus(YieldLock.data);
            });
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(row.dbT_NO);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [props.selectedDBTNumber])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": state.userAccessContext?.id
                }
                await usePost("InquiryReleaseLock", YieldRelease);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const EarlyRoll = () => {
        (async () => {
            let request = {
                "dbt_No": row.dbT_NO,
                "dbt_Client": row.dbT_CLIENT,
                "user_Id": UserName
            };

            await usePost<IRollPlacement[]>('RolledPlacement', request);
            props.onEroll();
            setAdvanceToContengency(true);

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Early Roll", operationText: "Early Roll"
            };
            feedOperation.push(operations);

            InsertTransactionLog(state?.userAccessContext?.id, row.dbT_NO, feedOperation);
        })()
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar className="snackBarStyle" open={Message} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    You will receive an acknowledgement report for your rolled accounts within 24-48- hours.
                </Alert>
            </Snackbar>
            <TableRow hover key={row.dbT_NO} onClick={(_) => { (row.dbT_NO !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                <TableCell component="td" scope="row" onClick={(_) => { props.onClick(row.dbT_NO); }} style={{ color: textColor, textDecorationLine: textLine }} align='left' className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord.toLocaleLowerCase() === "" ? classes.TextNormal : row.dbT_CLIENT.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_CLIENT}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='left' className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row?.clT_NAME_1 === null ? row?.clT_NAME_1 : row?.clT_NAME_1.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row?.clT_NAME_1}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='left' className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row?.clT_NAME_2 === null ? row?.clT_NAME_2 : row?.clT_NAME_2.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row?.clT_NAME_2}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_NO.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NO}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_CLNT_ACNT1.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_CLNT_ACNT1}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord.toLocaleLowerCase() === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord.toLocaleLowerCase() === "" ? classes.TextNormal : row.dbT_NAME.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NAME}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.deM_PHONE1?.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row?.deM_PHONE1 == undefined ? '' : row.deM_PHONE1}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} align="center" style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : RefferalDate.toString() === searchWord ? classes.TextHighlight : classes.TextNormal}`}>
                        {RefferalDate} days
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} align="center" style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_REFERRAL_AMT.toFixed(2) === searchWord ? classes.TextHighlight : classes.TextNormal}`}>
                        ${row.dbT_REFERRAL_AMT.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_PRINCIPAL_DUE.toFixed(2) === searchWord ? classes.TextHighlight : classes.TextNormal}`}>
                        ${row.dbT_PRINCIPAL_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} className={classes.tableCellStyle}>
                    <span className={`${searchWord.toLocaleLowerCase() === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.stS_DESC.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.stS_DESC.replace('|', " ")}
                    </span>
                </TableCell >
                <TableCell component="td" onClick={(_) => props.onClick(row.dbT_NO)} align='left' style={{ color: textColor, textDecorationLine: textLine, marginLeft: '2px' }} className={classes.tableCellStyle}>
                    <span className={`${searchWord === "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord != "" && RollRemoved || row?.isCloseRemove ? classes.removeStyle : searchWord === "" ? classes.TextNormal : StatusDate === searchWord ? classes.TextHighlight : classes.TextNormal}`}>
                        {StatusDate}
                    </span>
                </TableCell >
                <TableCell className={classes.tableCellStyle} component="td" align='left' style={{ flexDirection: 'row' }}>
                    <Button startIcon={<img src={roll} alt="Roll account icon for button" width="15px" height="15px" />} id="Roll_btn"
                        size="small" variant="contained" color="primary" className={RollRemoved ? classes.removedButton : classes.button}
                        onClick={() => { OnClickAdvanceToContengency(); }} disabled={(AdvanceToContengency || row.rolled) || RollRemoved}>
                        {RollRemoved ? "Removed" : (AdvanceToContengency || row.rolled) ? "Rolled" : "Roll"}</Button>
                    {((AdvanceToContengency || row.rolled) && !RollRemoved) && (
                        <IconButton id="Roll_Undo_btn" color="primary" aria-label="add to shopping cart"
                            className={classes.replyIcon}
                            onClick={(_) => {
                                setIsDelete(!isDelete);
                                undoRoll();
                                setAdvanceToContengency(false);
                            }}
                        >
                            <ReplayIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    )}
                    {(RollRemoved) && (
                        <IconButton id="RollUndoRemove_btn" color="primary" aria-label="add to shopping cart"
                            className={classes.replyIcon}
                            onClick={(_) => {
                                setIsDelete(!isDelete);
                                setTextLine('none');
                                setTextColor('black');
                                undoRoll();
                                setRollRemoved(false);
                                setRemoveUndo(true);
                            }}
                        >
                            <ReplayIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell className={classes.tableCellStyle} component="td"> <Button id="RollRemove_btn" onClick={(_) => { OnClickRowDelete(); }} disabled={RollRemoved || (AdvanceToContengency || row.rolled)} >
                    <DeleteForeverIcon style={{ color: 'red', fontSize: 25 }} />
                </Button></TableCell>
            </TableRow >
            <TableRow className={props.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={15} classes={{ root: classes.customTable }}>
                    <Collapse in={props.selected} timeout="auto" unmountOnExit >
                        <RollClientDetails DebtorDetails={row} onRollRemove={RollDeleteRow} onUndoCloseRemove={undoCloseandRemove} onEroll={EarlyRoll} onDelete={undoRoll} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                    </Collapse>
                </TableCell>
            </TableRow>

            <Snackbar className="snackBarStyle" open={RollUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Rolled Account has been undone successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={RemoveUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Removed Account has been undone successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default RollExpandableRow