import {
  Box,
  Button, Dialog, DialogActions, DialogContent, IconButton, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// Models
import useStyles from './AgentUserCss';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import { Transition } from '../GlobalStyles/DialogBoxTransition';

const DeleteTemplate: React.FC<{ id: number, email: string, onDelete: () => void }> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DeleteUser = () => {
    (async () => {
      let request = {
        "agentUserId": props?.id
      }
      usePost("AgentUser/DeleteAgentUser", request).then(() => {
        props?.onDelete();
      }).finally(() => {
        setOpen(false);
      });
    })()
  }

  return (
    <React.Fragment>
      <div>
         <IconButton id="CPO_Delete_btn" aria-label="delete" className={classes.deleteIconButton} onClick={handleClickOpen}>
            <DeleteForeverIcon className={classes.deleteIcon} />
         </IconButton>

        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          open={open}
          classes={{ paper: classes.deletePaperDialogue }}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              DELETE USER
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <WarningIcon style={{ color: 'red' }} />
              <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                Are you sure, you want to delete {props?.email} user ?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions >
            <Button id="CPO_OK_btn" size="small" color="primary" className={classes.yesButton1} autoFocus startIcon={<CheckCircleOutlineIcon />} onClick={() => { DeleteUser(); }}>
              Yes
            </Button>
            <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton1} autoFocus onClick={handleClose}
              variant="contained" startIcon={<CancelIcon />}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default DeleteTemplate