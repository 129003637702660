import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: 8
    },
    root: {
        display: 'flex',
        marginTop: '10px'
    },
    submitBtn: {
        background: "green",
        color: 'white',
        fontSize: 12,
        right: '20px',
        width: '90px',
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    shareBtn: {
        background: "green",
        color: 'white',
        fontSize: 12,
        right: '20px',
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    cancelBtn: {
        background: "red",
        color: 'white',
        fontSize: 12,
        right: '20px',
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    createreport: {
        fontSize: 11,
        borderRadius: 20,
        marginTop: '10px',
        marginRight: '15px'
    },
    editreport: {
        fontSize: 11,
        borderRadius: 20,
        color: 'white',
        backgroundColor: 'green'
    },
    activityName: {
        color: 'blue',
        marginTop: '5px'
    },
    activityName1: {
        color: 'blue',
        marginTop: '5px',
        textAlign: 'center'
    },
    nameStyle: {
        width: '100%'
    },
    formControl: {
        width: '100%',
        marginTop: '8px'
    },
    boxBorder: {
        padding: '5px 8px',
        marginTop: '20px',
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
    },
    gridflex: {
        padding: '5px 5px',
        borderRadius: '5px',
        marginTop: '5px',
        borderTop: '4px solid #3bb78f',
        overflowX: 'hidden',
        maxHeight: 580,
        minHeight: 580,
        borderBottom: '1px solid gray',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 500px)',
            minHeight: 'calc( 100vh - 500px)',
        },
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    ViewReport: {
        padding: '5px 0px',
        marginTop: '5px',
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh -420px)',
            minHeight: 'calc( 100vh - 420px)',
        },
    },
    dynamicStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    controlName: {
        marginTop: '4px',
        marginLeft: '4px'
    },
    controlPadding: {
        padding: '0px 5px',
        borderBottom: '0px solid white !important',
        '&.MuiTableCell-root': {
            borderBottom: '0px solid white !important',
        }
    },
    controlDisplay: {
        width: '100%',
        marginTop: '5px',
        marginBottom: '7px',
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
    },
    textStyle: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'black',
        fontSize: 13,
        marginTop: '2px'
    },
    ButtonStyle: {
        borderRadius: 20,
        fontSize: 11,
        marginTop: '5px',
        marginBottom: '3px',
        backgroundColor: 'green',
        "&:hover": {
            backgroundColor: 'green'
        }
    },
    ButtonStyle1: {
        borderRadius: 20,
        fontSize: 11,
        marginLeft: '10px',
        marginTop: '5px',
        marginBottom: '3px',
        backgroundColor: 'green',
        "&:hover": {
            backgroundColor: 'green'
        }
    },
    dialogePaper: {
        minWidth: "700px"
    },
    viewBtn: {
        background: "green",
        color: 'white',
        fontSize: 12,
        right: '20px',
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    shareButtonStyle: {
        padding: '5px'
    },
    reportNameStyle: {
        fontSize: 16,
        color: '#0068ff',
        padding: '5px 0px',
        textAlign: 'center'
    },
    reportNameStyle1: {
        fontSize: 16,
        color: '#0068ff',
        padding: '5px 0px',
        marginTop: '10px'
    },
    reportNameStyle2: {
        fontSize: 16,
        color: 'green',
        padding: '5px 0px',
        marginTop: '2px'
    },
    searchIcon: {
        borderRadius: 50,
        background: "white",
        width: "100%",
        height: '30px',
        border: '2px #215176 solid',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        fontSize: 12,
        textIndent: '10px',
    },
    gridstyle: {
        width: '100%',
        border: '2px #215176 solid',
        marginTop: "18px",
        borderRadius: 5,
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    boxStyle: {
        width: '100%',
        border: '2px #215176 solid',
        borderRadius: 5,
        padding: '0px 0px 0px 10px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    boxStyle1: {
        width: '100%',
        borderRadius: 5,
        padding: '0px 0px 0px 10px',
    },
    clientheader: {
        fontSize: 13,
        textAlign: 'center',
    },
    paper: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        borderTop: '2px #215176 solid',
        minHeight: '220px',
        maxHeight: '220px',
    },
    selectboxpadding: {
        paddingTop: 0,
    },
    clientcheckbox: {
        color: "#1bc42c",
        height: 22
    },
    clientlistSpacing: {
        marginBottom: '0px',
        marginTop: '0px',
    },
    clientlist: {
        textAlign: 'left',
        fontSize: 14,
    },
    selectallbutton: {
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
        width: "120px",
        marginTop: '15px'
    },
    flexDisplay: {
        display: 'flex',
        flexDirection: 'row'
    },
    tableBody: {
        minHeight: 'auto',
        maxHeight: 200,
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 585px)',
            minHeight: 'calc( 100vh - 585px)',
        },
    },
    permittedFiled: {
        width: '230px',
        fontSize: 12
    },
    permittedFiled1: {
        color: 'black',
        width: '250px',
        textOverflow: 'ellipsis'
    },
    sidebar: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRight: '1px solid gray',
        padding: '10px 10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
    },
    sidebar1: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRight: '1px solid gray',
        padding: '7px 20px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    dialogeReportShare: {
        minWidth: "1350px"
    },
    ErrorMessageStyle: {
        color: "red",
        marginTop: '5px',
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paddingCheckbox1: {
        padding: '5px 5px 5px 10px',
    },
    inputFontSize1: {
        fontSize: '15px',
        whiteSpace: 'nowrap',
        width: '200px',
        overflow: 'hidden',
        color: 'black',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        marginTop: '7px',
        marginLeft: '2px'
    },
    backButton: {
        borderRadius: 20,
        marginTop: '10px',
        marginRight: '15px',
        fontSize: '11px'
    }
}));

export default useStyles;