import React, { useEffect, useState } from 'react';
import HistoryIcon from "@mui/icons-material/History";
import {
  Dialog, DialogContent, IconButton, AppBar, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableSortLabel, Typography, Toolbar, Backdrop, Link, Box, Button
} from "@material-ui/core";
import { useStyles } from "./ManagementCSS";
import { Transition } from "../../GlobalStyles/DialogBoxTransition";
import { IGetAPIKeyHistory } from "../../../models/GLobalSettings/IGetKeyValueDetails";
import { usePost } from "../../../utils/apiHelper";
import moment from "moment";
import { useGlobalState } from "../../../store/GlobalStore";
import { ARMLogo } from "../../../constants/Constant";
import CloseIcon from "@material-ui/icons/Close";
import { PuffLoader } from "react-spinners";
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ViewAPIKeyHistory: React.FC<{ apiKeyId: number }> = (props) => {
  const { apiKeyId } = props;
  const { state } = useGlobalState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [historyDetails, setHistoryDetails] = useState<IGetAPIKeyHistory[]>([]);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [oldClientCodes, setOldClientCodesList] = useState([]);
  const [newClientCodes, setNewClientCodesList] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    GetAPIKeyHistoryDetails();
  };

  const handleCloseDialogBox = () => {
    setDialogOpen(false);
  }

  const GetAPIKeyHistoryDetails = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        apiKeyId: apiKeyId
      }
      await usePost<{ apiKeyHistoryDetails: IGetAPIKeyHistory[] }>("GlobalSettings/GetAPIKeyHistory", request).then((result) => {
        setHistoryDetails(result?.data["apiKeyHistoryDetails"]);
      }).finally(() => {
        setShowProgressBar(false);
      })
    })();
  };

  const handleClose = () => {
    setOpen(false);
    setOldClientCodesList(null);
    setNewClientCodesList(null);
  };

  const handleReadClientCodeDetails = (oldClientCode: string, newClientCode: string) => {
    let currentOldClientCodes = oldClientCode.split(',');
    let currentNewClientCodes = newClientCode.split(',');
    setOldClientCodesList(currentOldClientCodes);
    setNewClientCodesList(currentNewClientCodes);
  }

  useEffect(() => {
    if (oldClientCodes?.length || newClientCodes?.length) {
      setDialogOpen(true);
    }
  }, [oldClientCodes, newClientCodes])

  function EnhancedTable(props) {
    const { classes } = props;

    const headCells = [
      { id: "oldClientCode", numeric: false, disablePadding: false, label: "OLD CLIENT CODE", sortable: true },
      { id: "newClientCode", numeric: false, disablePadding: false, label: "NEW CLIENT CODE", sortable: true },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id} id="CPO_TableHeader"
              className={classes.TableCellStyle}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "actionName",
        disablePadding: false,
        label: "ACTION NAME",
        sortable: false,
      },
      {
        id: "actionTakenBy",
        disablePadding: false,
        label: "ACTION TAKEN BY",
        sortable: false,
      },
      {
        id: "actionTakenDate",
        disablePadding: false,
        label: "ACTION TAKEN DATE",
        sortable: false,
      },
      {
        id: "viewClientCodeList",
        disablePadding: false,
        label: "CLIENT CODE LIST",
        sortable: false,
      },
      // {
      //   id: "newClientCode",
      //   disablePadding: false,
      //   label: "NEW CLIENT CODE",
      //   sortable: false,
      // },
      {
        id: "emaiSentTo",
        disablePadding: false,
        label: "EMIAL SENT TO",
        sortable: false,
      },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div>
      <IconButton style={{ padding: "5px" }} onClick={handleClickOpen}>
        <HistoryIcon style={{ color: "#B30987" }} />
      </IconButton>
      <Dialog
        open={open}
        fullScreen
        className={classes.dialog}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarStyle}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <img
                alt="ARM_Solutions"
                className={classes.menuButton1}
                src={ARMLogo}
              />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <b>
                API KEY MANAGEMENT HISTORY -- ({historyDetails[0]?.clientCode}) --
                ({moment(historyDetails[0]?.startDate)?.format(state?.GlobalUtils?.settingValue)}) to
                ({moment(historyDetails[0]?.expiryDate)?.format(state?.GlobalUtils?.settingValue)})
              </b>
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
              style={{ padding: "5px" }}
            >
              <CloseIcon className={classes.closeIconButton} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TableContainer
            component={Paper}
            className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}
          >
            <Table size="small" aria-label="customized table" stickyHeader>
              <EnhancedTableHead classes={classes} />
              {historyDetails?.map((row) => (
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.TableCellStyle1}>{row?.actionName}</TableCell>
                    <TableCell className={classes.TableCellStyle1}>{row?.userName}</TableCell>
                    <TableCell className={classes.TableCellStyle1}>
                      {moment(row?.apiKeyCreatedDate)?.format(state?.GlobalUtils?.settingValue)}
                    </TableCell>
                    <TableCell className={classes.TableCellStyle1}>
                      {row?.oldClientCodeList?.length || row?.newClientCodeList?.length ?
                        <Link style={{ cursor: 'pointer' }} onClick={() => { handleReadClientCodeDetails(row?.oldClientCodeList, row?.newClientCodeList); }}> View Client Code </Link>
                        : null
                      }
                    </TableCell>
                    <TableCell className={classes.TableCellStyle1}>{row?.emailSentTo}</TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
            {!historyDetails?.length && !ProgressBar ? (
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                className={classes.ErrorMessageStyle1}
              >
                No history to display..
              </Typography>
            ) : null}
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Dialog TransitionComponent={Transition} open={dialogOpen}
        className={classes.dialogboxOverride}
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CLIENT CODE LIST
          </Typography>
        </DialogTitleHeader>
        <DialogContent >
          <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table aria-label="customized table" size="small" stickyHeader>
              <EnhancedTable classes={classes} />
              <TableBody>
                {[...Array(Math.max(oldClientCodes.length, newClientCodes.length)).keys()].map((index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {index < oldClientCodes.length ? oldClientCodes[index] : ''}
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {index < newClientCodes.length ? newClientCodes[index] : ''}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Box display="flex" alignItems="right" justifyContent="right" className={classes.dialogaction}>
          <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { setDialogOpen(false); }}>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </div >
  );
};

export default ViewAPIKeyHistory;
