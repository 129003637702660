import { Backdrop, Theme, createStyles, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { usePost } from "../utils/apiHelper";
import { useGlobalState } from '../store/GlobalStore';
import { Link } from 'react-router-dom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { PuffLoader } from "react-spinners";
import Footer from "../components/Footer/Footer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rectangleButton: {
      background:
        "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rectangleButton1: {
      background:
        "linear-gradient(310deg, rgb(0, 121, 145), rgb(120, 255, 214))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rectangleButton2: {
      background: "linear-gradient(310deg, rgb(14, 210, 247), rgb(178, 254, 250))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    linkStyle: {
      textDecoration: 'none',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dashboardContainer: {
      minHeight: 'calc(100vh - 130px)',
      maxHeight: 'calc(100vh - 130px)'
    }
  })
);

const AgentDashboard = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const [paymentCount, setPaymentCount] = useState<number>(0);
  const [backupCount, setBackupCount] = useState<number>(0);
  const [disputeCount, setDisputeCount] = useState<number>(0);
  const [ProgressBar, setProgressBar] = useState(false);

  useEffect(() => {
    (async () => {
      setProgressBar(true);
      let request = {
        "agentUserId": state?.AgentUserAccessContext?.agentUserId,
        "clientDetails": state?.AgentUserNotificationClientCodeDetails
      };
      await usePost<any>("Account/GetAgentDashboardCount", request).then((r) => {
        setPaymentCount(r?.data?.paymentCount?.paymentVerification);
        setBackupCount(r?.data?.backupCount?.backupDocumentation);
        setDisputeCount(r?.data?.disputeCount?.disputeValidation);
      })
    })().finally(() => {
      setProgressBar(false);
    })
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} padding={1} className={classes.dashboardContainer}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        {state?.AgentUserAccessContext?.paymentVerification ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box display={"flex"} borderRadius={5} p={3} mt={0} marginLeft={0} boxShadow={5} >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Payment Verification Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {paymentCount}
                  </Typography>
                </Box>
                <Box p={0} width={"20%"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                  <Box p={1} className={classes.rectangleButton}>
                    <LocalAtmIcon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
          : null}
        {state?.AgentUserAccessContext?.disputeValidation ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box display={"flex"} borderRadius={5} p={3} mt={0} marginLeft={0} boxShadow={5} >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Dispute Validation Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {disputeCount}
                  </Typography>
                </Box>
                <Box p={0} width={"20%"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                  <Box p={1} className={classes.rectangleButton1}>
                    <Diversity3Icon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
          : null}
        {state?.AgentUserAccessContext?.backupDocument ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box display={"flex"} borderRadius={5} p={3} mt={0} marginLeft={0} boxShadow={5} >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Backup Notification Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {backupCount}
                  </Typography>
                </Box>
                <Box p={0} width={"20%"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                  <Box p={1} className={classes.rectangleButton2}>
                    <ReceiptLongIcon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
          : null}
      </Grid>
      <Box m={0} width={'100%'} >
        <Footer />
      </Box>
    </Box>
  );
};

export default AgentDashboard;
