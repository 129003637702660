import React, { useEffect, useState } from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

//Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbars } from 'react-custom-scrollbars';

//Models
import { Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, InputAdornment, ListItem, ListItemIcon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import { CSVReader } from 'react-papaparse';
import { useHistory } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import { CsvFile, TxtFile } from '../../constants/Constant';
import { IClientList } from '../../models/Admin/ClientPlacements/IClientList';
import { IFTPFiles } from '../../models/Files/IFTPFiles';
import { IAdminGetClients } from '../../models/UploadLog/AdminGetClients';
import DeleteFtpFiles from './DeleteFtpFiles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4),
            display: 'flex',
            position: 'relative'
        },
        paper: {
            backgroundColor: 'white',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
        },
        alignFlexStart: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '10px',
            marginLeft: '10px',
            color: 'blue',
            fontSize: 22
        },
        alignFlexEnd: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        div: {
            flexGrow: 1,
        },
        button: {
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(1)
        },
        innerbox: {
            marginTop: '60px'
        },
        cloud_btn: {
            borderRadius: 5,
            paddingTop: '6px',
            paddingBottom: '6px',
            marginTop: '0.8cm',
            marginBottom: '0.8cm',
            fontSize: 13,
            fontWeight: 600,
            marginLeft: '1cm'
        },
        csvreader: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        delete: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(0.5),
            '&:hover': {
                background: "red",
            }
        },
        yesButton: {
            background: "green",
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(0.5),
            '&:hover': {
                background: "green",
            }
        },
        tableBoxShadow: {
            maxHeight: 420,
            float: 'left',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            borderRadius: '10px',
            border: '1px solid gray'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff'
        },
        searchBox: {
            marginTop: '2px',
            borderRadius: 30,
            border: '2px solid #215176',
            height: '42px',
            textIndent: '10px',
            width: '100%',
            textAlign: 'center',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        },
        TextHighlight: {
            color: 'black',
            background: "yellow",
            fontSize: '14px'
        },
        TextNormal: {
            color: 'black',
            fontSize: 13
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        },
        headerStyle: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        label: {
            '&$focused': {
                color: 'white',
                border: '0px'
            },
        },
        outlinedInput: {
            fontSize: 15,
            color: "black",
            '&$focused $notchedOutline': {
                color: 'white',
                border: '0px'
            },
        },
        notchedOutline: {
            color: 'white',
            border: '0px'
        },
        IconSizeStyle: {
            height: '30px',
            width: '30px'
        },
        GridStyle: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            padding: '5px'
        },
        headercellStyle: {
            background: "#007FFF",
            color: "white",
            textAlign: 'left',
            fontSize: 13
        },
        tableHeader: {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        },
        shadowStyle: {
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
        },
        noRecordStyle: {
            color: "red",
            textAlign: 'center',
            fontSize: '14px'
        }
    }),
);

const FtpFiles: React.FC<{ onRemoteFileUpload: (data) => void, getPhase: string, getClients: string, onCloudUpload: (data) => void }> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    let history = useHistory();
    const { state, dispatch } = useGlobalState();

    const [placementClientList, setPlacementClientList] = useState<IClientList[]>([]);
    const [ListFtpFiles, setListFtpFiles] = useState<IFTPFiles[]>([]);
    const [selectedBlob, setSelectedBlob] = useState<IFTPFiles[]>([]);

    const [orderBy, setOrderBy] = useState<string>("dateModified");
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchFiles, setSearchFiles] = useState("");
    const [order, setOrder] = useState<any>('desc');
    const [phase, setPhase] = useState(0);

    const [triggerSearch, setTriggerSearch] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [openCloud, setOpenCloud] = React.useState(false);

    const handleClickOpenCloud = () => {
        setOpenCloud(true);
    };

    const handleCloseCloud = () => {
        setOpenCloud(false);
        setshowProgressBar(false);
        setSelectedBlob([]);
    };

    useEffect(() => {
        (async () => {
            let request = {
                "searchText": searchFiles ?? "",
                "userId": state.userAccessContext?.id
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<IFTPFiles[]>("Placements/GetFtpFilesbyUser", request).then((GetAllFtp) => {
                setListFtpFiles(GetAllFtp.data.filter(r => r.fileName.includes(CsvFile) || r.fileName.includes(TxtFile)));
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, [triggerSearch])


    const GetFtpFiles = () => {
        (async () => {
            let request = {
                "searchText": "",
                "userId": state.userAccessContext?.id
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<IFTPFiles[]>("Placements/GetFtpFilesbyUser", request).then((GetAllFtp) => {
                setListFtpFiles(GetAllFtp.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    const handleBlobsToggle = (ListBlobs: IFTPFiles) => () => {
        let newSelectedBlob = [...selectedBlob].filter(r => r == r[""]);
        if (newSelectedBlob.some(u => u.fileId == ListBlobs.fileId)) {
            newSelectedBlob = newSelectedBlob.filter(u => u.fileId != ListBlobs.fileId);
        } else {
            newSelectedBlob.push(ListBlobs);
        }
        setSelectedBlob(newSelectedBlob);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "", numeric: false, disablePadding: false, label: "SELECT", sortable: false },
            { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
            { id: "dateModified", numeric: false, disablePadding: false, label: "DATE MODIFIED", sortable: false },
            { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "DELETE", sortable: false }
        ];

        return (
            <TableHead style={{ borderTopLeftRadius: '10px' }}>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.headercellStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)} >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            body: {
                fontSize: 13,
                borderBottom: 'unset',
                textAlign: 'left'
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
            },
        }),
    )(TableRow);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const ListBlob = () => (
        <React.Fragment>
            <TableContainer component={Paper} className={`${classes.tableBoxShadow} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table size="small" stickyHeader aria-label="sticky tabler">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        <Backdrop className={classes.backdrop} open={ProgressBar}>
                            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        {stableSort(ListFtpFiles, getComparator(order, orderBy)).map((row, index) => {
                            return (
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <ListItem key={index} role="listitem" onClick={handleBlobsToggle(row)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    id="FV_Checkbox"
                                                    checked={selectedBlob.some(c => c.fileId == row.fileId)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row">
                                        <span className={`${searchFiles === "" ? classes.TextNormal : row.fileName.toLocaleLowerCase().includes(searchFiles?.trimLeft().trimRight()) ? classes.TextHighlight : classes.TextNormal}`}>
                                            {row.fileName}
                                        </span>
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        {moment(row.dateModified).format(state.GlobalUtils?.settingValue)}
                                    </StyledTableCell>

                                    <StyledTableCell scope="row">
                                        <span>
                                            {row.fileSize}
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell scope="row">
                                        <DeleteFtpFiles fileId={row.fileId} onDelete={GetFtpFiles} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })
                        }
                        <ListItem />
                    </TableBody>
                </Table>
                {ListFtpFiles?.length === 0 ?
                    <Typography gutterBottom className={classes.noRecordStyle}>
                        No Files to display..
                    </Typography>
                    : null}
            </TableContainer>
        </React.Fragment>
    );

    const HandleParseRemoteFile = () => {
        (async () => {
            let request = {
                "blobUri": selectedBlob[0]?.bloburi
            };
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<any>("File/GetBlobFileContent", request).then((GetFileContent) => {
                const arrayBuffer = base64ToArrayBuffer(GetFileContent.data);
                GetBlobFileContent(arrayBuffer);
            });
            props.onCloudUpload(selectedBlob[0].fileName);
        })()
    }

    useEffect(() => {
        if (props.getPhase === "1STP") {
            setPhase(1);
        }
        else if (props.getPhase === "PREC") {
            setPhase(2);
        }
        else if (props.getPhase === "CONT") {
            setPhase(3);
        } else {
            setPhase(4);
        }
    }, [props.getPhase])

    useEffect(() => {
        (async () => {
            await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state?.userAccessContext?.id}`).then((r) => {
                let currentClientList: IClientList[] = [];
                r.data.forEach(x => {
                    let client: IClientList = { clT_CODE: x.client_code, clT_NAME_1: x.client_name };
                    currentClientList.push(client);
                });
                setPlacementClientList(currentClientList);
            });
        })()
    }, [])

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString?.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function GetBlobFileContent(body) {
        let filteredClientList = _.cloneDeep(placementClientList);
        filteredClientList = filteredClientList.filter(x => x.clT_CODE != "-2");
        const blob = new Blob([body]);
        const link = document.createElement('a');

        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.style.visibility = 'hidden';
            Papa.parse(url, {
                download: true,
                skipEmptyLines: true,
                comments: "{",
                complete: function (parsedOutput) {
                    props.onRemoteFileUpload(parsedOutput.data);
                    history?.push('/csvfieldnames',
                        {
                            FileId: selectedBlob[0]?.fileId, clientCode: props?.getClients, phaseNumber: phase, clientList: filteredClientList,
                            fileName: selectedBlob[0]?.fileName, fileSize: selectedBlob[0]?.fileSize, blobUri: selectedBlob[0]?.bloburi
                        });
                }
            });
        }
    }

    const initiateSearch = () => {
        setTriggerSearch(!triggerSearch);
    }

    return (
        <div>
            {state.userAccessContext?.ftpFolder?.length > 0 ?
                <Button size="small" variant="contained" color="primary" startIcon={<CloudUploadIcon style={{ fontSize: 25 }} />}
                    onClick={handleClickOpenCloud} className={classes.cloud_btn}>
                    CLOUD
                </Button>
                :
                null
            }
            <Dialog
                fullScreen={fullScreen}
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'md'}
                open={openCloud}
                aria-labelledby="responsive-dialog-title">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseCloud}>
                    <Typography variant="h6" gutterBottom className={classes.headerStyle}>
                        CLOUD FILE UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.GridStyle}>
                            <TextField size="small" value={searchFiles} variant="outlined"
                                type='text' placeholder="Search Files"
                                className={classes.searchBox}
                                onChange={e => setSearchFiles(e.target.value.trim())}
                                inputProps={{ maxLength: 255 }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && searchFiles != "") {
                                        initiateSearch(); setShowSearchIcon(false);
                                    } else if (event.key === 'Enter' && searchFiles === "") {
                                        initiateSearch(); setShowSearchIcon(true);
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.outlinedInput,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showSearchIcon === true ?
                                                <IconButton onClick={e => { initiateSearch(); setShowSearchIcon(false); }}>
                                                    <SearchIcon />
                                                </IconButton>
                                                :
                                                <IconButton onClick={e => { GetFtpFiles(); }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Scrollbars autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={10}
                                autoHeight
                                autoHeightMin={50}
                                autoHeightMax={420}
                            >
                                {ListBlob()}
                            </Scrollbars>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CSVReader
                        onFileLoad={GetBlobFileContent}
                        noClick
                        noDrag
                    >
                        {({ file }) => (
                            <React.Fragment>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={HandleParseRemoteFile}
                                    startIcon={<CloudUploadIcon />}
                                    disabled={!selectedBlob?.length}
                                >
                                    Upload
                                </Button>
                            </React.Fragment>
                        )}
                    </CSVReader>
                    <Button size="small" onClick={handleCloseCloud} startIcon={<CancelIcon />} variant="contained" color="primary" autoFocus className={classes.button} style={{ backgroundColor: 'red' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FtpFiles