import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import React from 'react';
import { IPerformanceReportsValuesResult } from '../../models/PerformanceReport/IPerformanceReportsValuesResult';
import { useGlobalState } from '../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '8px'
        },
        tablefont: {
            color: "black",
            fontSize: 12,
            padding: '10px 5px !important'
        }
    })
);

const PerformanceBuilder: React.FC<{ row: IPerformanceReportsValuesResult }> = (props) => {
    const classes = useRowStyles();
    const { row } = props;
    const { state } = useGlobalState();

    return (
        <React.Fragment>
            <TableRow >
                <TableCell className={classes.tablefont}>{row?.dbT_CLIENT}</TableCell>
                <TableCell className={classes.tablefont}>{row?.clt_Name_1}</TableCell>
                <TableCell className={classes.tablefont}>{row?.plcMonth}</TableCell>
                <TableCell className={classes.tablefont}>{row?.plcYYMM}</TableCell>
                <TableCell className={classes.tablefont}>${row?.placedQty?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.placedAmt?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.avgBal?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.weightedAge?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.newAmt?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.bkAmt?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.activeAmt?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.skipAmt?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.totalWeightedAvgAge?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.totalAvgBal?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>{row?.collectionPct}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default PerformanceBuilder;
