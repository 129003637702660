import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel,
  Paper, Radio, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Snackbar
} from '@material-ui/core';
// Icon 
import DateFnsUtils from '@date-io/date-fns';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// Componant
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useState } from 'react';
import * as constant from "../../constants/Constant";
import { ICustomizeControls, IProcedureName } from '../../models/AgentTemplate/IAgentCustomizeControl';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from './ReportStyleCSS';
import _ from 'lodash';
import { IPreReportControlIdList } from '../../models/PerformanceReport/IPerformanceReportsResults';
import { AllowAlphabetsNumbers } from '../../constants/Constant';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';

const CreatePerformance: React.FC<{ onSave: () => void }> = (props) => {
  const { state } = useGlobalState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reportType, setReportType] = useState<number>(0);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [reportContorlList, setReportControlList] = useState<ICustomizeControls[]>([]);
  const [reportDynamicList, setReportDynamicList] = useState([]);
  const [isReportCreated, setIsReportCreated] = useState<boolean>(false);
  const [performanceReportName, setPerformanceReportName] = useState<string>("");
  const [reportNameError, setReportNameError] = useState<string>("");
  const [metadata, setMetaData] = useState<IProcedureName[]>([]);
  const [ReportProcedureId, setReportProcedureId] = useState<number>(0);

  let dropDownValue;
  let optionItems;

  const handleClickOpen = () => {
    setOpen(true);
    getControlList();
    getMetaDataList();
  };

  const CloseMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsReportCreated(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    setOpen(false);
    setPerformanceReportName("");
    setReportDynamicList([]);
  };

  const handleChange = (event, selected) => {
    setReportType(parseInt(event.target.value));
  }


  const handleControlClick = (event) => {
    let NewReportDynamicList = _.cloneDeep(reportDynamicList);
    let controlReport = {
      controlId: event.currentTarget.id,
      controlName: event.currentTarget.name,
      labelName: event.currentTarget.value,
    }
    NewReportDynamicList.push(controlReport);

    // Filter the duplicate values from the Report control list 
    var NewControlList = NewReportDynamicList.filter((c) => {
      return !reportDynamicList.find((r) => {
        return c.controlId == r.controlId
      })
    });
    setReportDynamicList([...new Set(reportDynamicList?.concat(NewControlList?.map((r) => r)))]);
  }

  const handleRemoveControl = (event) => {
    setReportDynamicList(reportDynamicList.filter((v) => v?.controlId !== event.currentTarget.id));
  }

  const handleDropDownChange = (event) => {
    setReportProcedureId(parseInt(event.target.value));
  };

  const getControlList = () => {
    (async () => {
      let request = {
        "activityId": 0,
        "controlId": 0,
        "actionType": 2
      }
      await usePost<ICustomizeControls[]>("AgentUser/GetCustomizeControlById", request).then((r) => {
        setReportControlList(r.data);
      })
    })()
  }
  const getMetaDataList = () => {
    (async () => {
      await useFetch<IProcedureName[]>("GetPerformanceMetaDataFilter").then((result) => {
        setMetaData(result?.data);
      });
    })()
  }

  const handleReportName = (event) => {
    var performanceReport = event.target.value;
    if (!performanceReport.match(AllowAlphabetsNumbers)) {
      setReportNameError("");
      setPerformanceReportName(performanceReport); // only set when successful
    } else if (performanceReport === "") {
      setReportNameError("Reports Name is Required");
    } else {
      setReportNameError("Please enter characters only");
    }
  }

  const handleCreatePerformanceReport = () => {
    let reportsControlList = [];
    reportDynamicList?.map((x) => {
      let controlDetail: IPreReportControlIdList = { controlId: x?.controlId }
      reportsControlList.push(controlDetail);
    });
    (async () => {
      let request = {
        "performanceReportName": performanceReportName,
        "createdDate": new Date(),
        "modifiedDate": new Date(),
        "createdBy": state?.userAccessContext?.id,
        "metaDataType": 0,
        "chartType": 0,
        "isActive": true,
        "email": false,
        "emailFrequency": 0,
        "emailRecurrence": 0,
        "preControlMapDetails": reportsControlList,
        "reportProcedureId": ReportProcedureId
      }
      await usePost<any>("CreatePerformanceReport", request).then(() => {
        setReportDynamicList([]);
        setIsReportCreated(true);
      });
    })().finally(() => {
      props?.onSave();
      handleClose();
    })
  }

  return (
    <div>
      <Button variant='contained' color="primary" size="small" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} className={classes.backButton}  >
        Back
      </Button>
      {state?.userAccessContext?.performanceReport ?
        <Button size="small" style={{ fontSize: 11 }} className={classes.createreport}
          variant="contained" startIcon={<AssessmentIcon />} color="primary" onClick={handleClickOpen}>
          Performance Report
        </Button>
        : null
      }

      <Dialog open={open} keepMounted TransitionComponent={Transition} maxWidth={'md'} PaperProps={{ style: { borderRadius: 10 } }} onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CREATE PERFORMANCE REPORT
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography variant='subtitle1' className={classes.activityName}>
                <b>Report Name</b>
              </Typography>
              <TextField id="CNT_Name"
                variant="outlined"
                fullWidth margin="dense"
                autoComplete="off"
                error={!!reportNameError}
                helperText={reportNameError}
                className={classes.nameStyle}
                inputProps={{ maxLength: 250 }}
                label="Enter report name"
                size="small" name="activityDescription"
                onChange={handleReportName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='subtitle1' className={classes.activityName}>
                <b>Report Type</b>
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"> Report Type</InputLabel>
                <Select native onChange={handleChange} label="Select Report Type" >
                  <option value={10}>Performance Report</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='subtitle1' className={classes.activityName}>
                <b>Report-Meta data Type</b>
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select native
                  onChange={(e) => { handleDropDownChange(e); }}
                >
                  {metadata.map((r) => {
                    return (
                      <option value={r?.id}>{r?.procedureDisplayName}</option>
                    )
                  })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='subtitle1' className={classes.activityName}>
                <b>Chart Type</b>
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"> Report Type</InputLabel>
                <Select native onChange={handleChange} label="Select Report Type" >
                  <option value={10}>Chart Type 1</option>
                  <option value={20}>Chart Type 2</option>
                  <option value={30}>Chart Type 3</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='subtitle1' className={classes.activityName1}>
                <b>Dynamic Controls</b>
              </Typography>
              <TableContainer component={Paper} className={`${classes.gridflex} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table">
                  <TableBody >
                    {reportContorlList?.map((r) => {
                      return (
                        <TableRow>
                          <TableCell width="90%" className={classes.controlPadding} style={{ padding: '10.6px' }}>
                            <Button name={`${r?.controlName}`}
                              id={`${r?.controlId}`}
                              value={`${r?.labelName}`}
                              size="small" variant="contained" autoFocus
                              color="primary" style={{ width: '225px' }}
                              onClick={handleControlClick}>
                              {r?.labelName}
                            </Button>
                          </TableCell>

                          <TableCell width="10%" className={classes.controlPadding}>
                            <IconButton size="small" color="primary"
                              name={`${r?.controlName}`}
                              id={`${r?.controlId}`}
                              value={`${r?.labelName}`}
                              onClick={handleControlClick}>
                              <ArrowForwardIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Selected Controls appear here*/}
            <Grid item xs={12} sm={8} >
              <Typography variant='subtitle1' className={classes.activityName1}>
                <b>Selected Controls</b>
              </Typography>
              <TableContainer component={Paper} className={`${classes.gridflex} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table">
                  <TableBody >
                    {reportDynamicList.map((r) => {
                      return (
                        <TableRow>
                          <TableCell width="93%" className={classes.controlPadding}>
                            {r?.controlName == constant?.textBox ?
                              <TextField id="CNT_Name"
                                variant="outlined"
                                autoComplete='off'
                                fullWidth margin="dense"
                                label={r?.labelName}
                                className={classes.controlDisplay}
                                size="small" name="activityDescription"
                              />
                              : r?.controlName == constant?.dropDownList ?
                                <FormControl size="small" variant="outlined" className={classes.controlDisplay}>
                                  <InputLabel htmlFor="outlined-age-native-simple">{r?.labelName}</InputLabel>
                                  <Select native
                                    label={`${r?.labelName}`}
                                    autoComplete='false'
                                    inputProps={{
                                      name: 'role',
                                      id: 'outlined-age-native-simple',
                                    }}>
                                    {dropDownValue = (r?.controlValues)?.split("|")}
                                    {optionItems = dropDownValue?.map((r) => {
                                      return (
                                        <option value={r}>{r}</option>
                                      )
                                    })}
                                  </Select>
                                </FormControl>
                                : r?.controlName == constant?.checkBox ?
                                  <>
                                    {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                      return (
                                        <div className={classes.formControl}>
                                          <Checkbox name={r?.labelName} disabled style={{ padding: '2px 0px' }} />
                                          <span className={classes.controlName} >{result}</span>
                                        </div>
                                      )
                                    })}
                                  </>
                                  : r?.controlName == constant?.radioButton ?
                                    <>
                                      {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                        return (
                                          <div className={classes.formControl}>
                                            <Radio name={r?.labelName} disabled style={{ padding: '2px 0px' }} />
                                            <span className={classes.controlName} >{result}</span>
                                          </div>
                                        )
                                      })}
                                    </>
                                    : r?.controlName == constant?.datePicker ?
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          autoOk size='small' variant="inline"
                                          autoComplete='off' inputVariant="outlined"
                                          label={r?.labelName} className={classes.controlDisplay}
                                          format="MM/dd/yyyy" value={selectedDate}
                                          onChange={date => handleDateChange(date)}
                                          InputAdornmentProps={{ position: "end" }} />
                                      </MuiPickersUtilsProvider>
                                      : r?.controlName == constant?.textArea ?
                                        <TextField id="CNT_Name"
                                          variant="outlined"
                                          autoComplete='off'
                                          multiline rows={4}
                                          fullWidth margin="dense"
                                          inputProps={{ maxLength: r?.maxLengthValue }}
                                          label={r?.labelName}
                                          size="small" name="activityDescription"
                                        />
                                        : null}
                          </TableCell>
                          <TableCell width="7%" className={classes.controlPadding}>
                            <IconButton size="small" color="primary"
                              id={`${r?.controlId}`}
                              onClick={handleRemoveControl} >
                              <CancelIcon style={{ color: 'red' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="CPO_OK_btn" size="small" color="primary" startIcon={<CheckCircleIcon />}
            disabled={!performanceReportName?.length || !reportDynamicList?.length || ReportProcedureId === 0}
            className={classes.submitBtn} autoFocus onClick={handleCreatePerformanceReport}>
            Submit
          </Button>
          <Button id="CPO_Cancel_btn" size="small" color="primary" startIcon={<CancelIcon />} className={classes.cancelBtn} autoFocus onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog >
      <Snackbar className="snackBarStyle" open={isReportCreated} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={CloseMessage}>
        <Alert onClose={CloseMessage} severity="success" className="alertStyle">
          Performance Report Created Successfully.
        </Alert>
      </Snackbar>
    </div >
  )
}

export default CreatePerformance