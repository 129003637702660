import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import './App.css';
import { loginRequest } from './auth/authConfig';
import Content from "./components/content";
import Header from "./components/Header";
import * as constant from "./constants/Constant";
import { AccessContext } from "./models/accesscontrol/AccessContext";
import { IWidgetInfo } from "./models/accesscontrol/IWidgetInfo";
import { IProspectUsersDetails } from "./models/accesscontrol/ProspectUsersDetails";
import { WidgetMap } from "./models/accesscontrol/WidgetMap";
import { ProfileData } from "./pages/Authentication/ProfileData";
import { ProspectClientInformation } from "./pages/Authentication/ProspectClientInformation";
import Login from "./pages/Login";
import { LogoutSignin } from "./pages/LogoutSignin";
import MultiLocationDetails from "./pages/Settings/MultiLocation/MultiLocationDetails";
import { GlobalStateAction, useGlobalState } from "./store/GlobalStore";
import { useFetch, usePost } from './utils/apiHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: '50px',
      width: '100%',
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word'
    },
    image: {
      width: '80vw',
      height: '60vh',
      alignItems: "center",
    },
    MessageStyle: {
      marginTop: '25px',
      fontSize: '32px'
    }
  }),
);

function App() {
  const { dispatch } = useGlobalState();
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isValidUser, setValidateUser] = useState<boolean>(null);
  const [isDisable, setIsDisable] = useState(false);
  const [valUser, setValUser] = useState(false);
  const [newUserDetails, setNewUserDetails] = useState<IProspectUsersDetails>();
  const classes = useStyles();
  let history = useHistory();
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    setIsDisable(inProgress == 'login' || inProgress == 'handleRedirect' || inProgress == 'startup')
  }, [inProgress]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      let account = accounts[0];
      instance.ssoSilent({ ...loginRequest, loginHint: account.username }).then(a => {
        dispatch({
          type: GlobalStateAction.LoggedIn, user: {
            id: account.homeAccountId ?? "",
            name: account.username ?? "",
            profileImage: "./images/header-logo.png",
            email: account.username ?? "",
            userToken: a.accessToken,
            homeAccountId: account.homeAccountId ?? "",
            localAccountId: account.localAccountId ?? "",
            objectId: a.uniqueId
          }
        });

        (async () => {
          setUserName(a?.account?.username);
          let request = { "email": a.account.username }
          let newRequestValue = { "userName": a.account.username }
          await usePost<IProspectUsersDetails>("User/GetNewUserDetails", newRequestValue).then(async (r) => {
            setNewUserDetails(r?.data);
            await usePost<any>("AgentUser/CheckAgentUser", request).then(async (r) => {
              setValUser(r?.data[0]?.isAgentUser);
              if (!r?.data[0]?.isAgentUser) {
                let UserValidate = { "userName": a.account.username, "objectId": a.uniqueId }
                await usePost<any>("User/ValidateUserInvitation", UserValidate).then(async (userInvitation) => {
                  setValidateUser(userInvitation?.data);
                  if (userInvitation.data === true) {
                    let requestAccount = { "userName": a?.account?.username };
                    await usePost<AccessContext>("User/GetUserInfoByEmail", requestAccount).then(async (userAcc) => {
                      await useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userAcc?.data?.id).then((clientsWidgets) => {
                        dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
                        if (userAcc?.data?.role == 0 || userAcc?.data?.role == 1) {
                          for (let index = 1; index <= WidgetMap.size; index++) {
                            const value = WidgetMap.get(index);
                            if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                              userAcc.data[value] = true;
                            }
                            dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
                          };
                        };
                      });

                      if (window.document.location.href === constant?.reportSubRouteKey || window.document.location.href === constant?.reportSubRoute) {
                        history.push('/report');
                      } else if ((userAcc?.data.role !== 2 && userAcc?.data?.role !== 3) && (window.document.location.href === constant?.rollSubRouteKey || window.document.location.href === constant?.rollSubRoute) && (userAcc?.data?.rollAccounts === true)) {
                        history.push('/roll');
                      } else if (userAcc?.data?.role === 2 || userAcc?.data?.role === 3) {
                        history.push('/admin');
                      } else if ((userAcc?.data?.role === 0 || userAcc?.data?.role === 1) && userAcc?.data?.totalUserCount === 0) {
                        history.push('/dashboard');
                      } else if ((userAcc?.data?.role === 0 || userAcc?.data?.role === 1) && userAcc?.data?.totalUserCount > 0) {
                        history.push('/prospectUsersList');
                      }
                      else {
                        history.push('/ManageClientFolderAccess')
                      }

                      if (window.location.origin !== constant?.LocalHost) {
                        let request = {
                          "userName": a.account.username,
                          "loginDateTime": new Date(),
                          "userId": userAcc?.data?.id,
                          "userTypeId": 1
                        }
                        await usePost<any>("UserLoginHistory/InsertIAMLoginHistory", request);
                      }
                    });
                  }
                }).catch(() => {
                  setValidateUser(false);
                });
              }
              else {
                if (r?.data[0]?.isAgentInvited) {
                  setValidateUser(true);
                  let request = { "emailAddress": a.account.username }
                  await usePost<any>("AgentUser/GetAgentUserByEmail", request).then(async (r) => {
                    dispatch({ type: GlobalStateAction.AgentUserAccessContext, AgentUserAccessContext: r?.data['agentUserDetails'][0] });
                    dispatch({ type: GlobalStateAction.AgentClientDetails, AgentClientDetails: r?.data['agentUserClientPermissions'] }); 
                    dispatch({ type: GlobalStateAction.AgentUserNotificationClientCodeDetails, AgentUserNotificationClientCodeDetails: r?.data['notificationClientDetails'] });        
                    if(r?.data['agentUserDetails'][0]?.disputeValidation || r?.data['agentUserDetails'][0]?.paymentVerification || r?.data['agentUserDetails'][0]?.backupDocument){
                      history.push('/agentdashboard');
                    }else{
                      history.push('/agentinquiry');
                    }
                    if (window.location.origin !== constant?.LocalHost) {
                      let requestLogin = {
                        "userName": a.account.username,
                        "loginDateTime": new Date(),
                        "userId": r?.data['agentUserDetails'][0]?.agentUserId,
                        "userTypeId": 2
                      }
                      await usePost<any>("UserLoginHistory/InsertAgentUserLoginHistory", requestLogin);
                    }
                  });
                }
                else {
                  setValidateUser(false);
                }
              }
            });
          })
        })()
      });
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    if (isValidUser == null) {
      return (<Login isLoading={isDisable} isAuth={true} isAuthenticated={isAuthenticated} />)
    }
    else if (newUserDetails[0]?.isUserActivated === false) {
      return (<ProspectClientInformation userDetails={newUserDetails[0]} isPasswordProvided={false} />)
    }
    else if (newUserDetails[0]?.isNewOrginization && newUserDetails[0]?.isUserActivated && !newUserDetails[0]?.isClientInfoConfirmed && (newUserDetails[0]?.isMultiBranch != null || newUserDetails[0]?.isMultiBranch != undefined)) {
      return (<MultiLocationDetails userDetails={newUserDetails[0]} />)
    }
    else if (newUserDetails[0]?.isWebviewUser && newUserDetails[0]?.isNewOrginization && !newUserDetails[0]?.isUserActivated && !newUserDetails[0]?.isAdminApproved) {
      return (<ProspectClientInformation userDetails={newUserDetails[0]} isPasswordProvided={false} />)
    }
    else if (newUserDetails[0]?.isWebviewUser && !newUserDetails[0]?.isNewOrginization && newUserDetails[0]?.isUserActivated && !newUserDetails[0]?.isAdminApproved) {
      return (<ProspectClientInformation userDetails={newUserDetails[0]} isPasswordProvided={false} />)
    }
    else if (newUserDetails[0]?.isUserActivated && newUserDetails[0]?.isClientInfoConfirmed) {
      return (<ProfileData userDetails={newUserDetails[0]} isConfirmed={false} />)
    }
    else if (newUserDetails[0]?.isWebviewUser && !newUserDetails[0]?.isNewOrginization && newUserDetails[0]?.isUserActivated) {
      return (<ProfileData userDetails={newUserDetails[0]} isConfirmed={false} />)
    } else if (newUserDetails[0]?.isMultiBranch === null || newUserDetails[0]?.isMultiBranch === undefined && newUserDetails[0]?.prospectEmailAddress?.length) {
      return (<ProfileData userDetails={newUserDetails[0]} isConfirmed={false} />)
    }
    else if (isValidUser === false) {
      return (
        <LogoutSignin userName={userName} />
      )
    }
    else if (isValidUser == true) {
      return (
        <Grid container className="App">
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Content />
          </Grid>
        </Grid>)
    }
  }
  else {
    return (<Login isLoading={isDisable} />)
  }
}

export default App