import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
            marginBottom: "0%",
            width: '100%',
            marginTop: '15px'
        },
    },
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '40px',
        borderBottom: '2px solid #0072E5'
    },
    title: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'right',
        fontSize: 18,
        [theme.breakpoints.up('xs')]: {
            fontSize: '16px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginRight: '10px',
        },
    },
    title1: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'right',
        fontSize: 18,
        marginTop: '10px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '16px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginRight: '10px',
        },
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '40px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '40px'
    },
    sidebar: {
        minWidth: "14rem",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        height: "calc(100vh - 52px)",
        borderLeft: '10px solid White',
        borderRight: '10px solid White',
        [theme.breakpoints.up('xs')]: {
            maxWidth: "13rem",
            height: 'auto'
        },
    },
    sidebarItem: {
        width: '100%',
        height: '60px',
        listStyleType: 'none',
        margin: '0%',
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    activeStyle: {
        backgroundColor: '#243d50'
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "4.5rem",
        height: "calc(100vh - 52px)",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderLeft: '7px solid White',
        borderRight: '10px solid White',
        [theme.breakpoints.up('xs')]: {
            height: 'auto'
        },
    },
    flexDispaly: {
        display: 'flex',
    },
    sidebarHeader: {
        display: 'flex',
        padding: '5px 5px',
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
    },
    textDisplay: {
        flex: '75%',
        paddingLeft: '10px',
        display: 'flex',
        textAlign: 'left',
        fontSize: 14,
        justifyContent: 'left',
        whiteSpace: 'nowrap',
        width: '50px',
        overFlow: 'hidden',
        textOverflow: 'ellipsis'
    },
    textpropertyOut: {
        color: 'black',
        cursor: 'pointer',
        transitionDelay: '0.2s',
        fontSize: '15px !important',
        padding: '10px',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '13px !important',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px !important',
        },
    },
    transformImage: {
        transform: 'rotateY(180deg)'
    },
    textProperty: {
        color: 'black',
        cursor: 'pointer',
        transitionDelay: '0.2s',
        textAlign: 'center',
        fontSize: '16px !important',
        padding: '10px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '13px !important',
        },
    },
    activeSidebar: {
        backgroundColor: '#243d50',
        textAlign: 'center',
        borderRadius: '5px',
        fontStyle: 'bold',
        padding: '15px'
    },
    activeSidebarText: {
        color: '#00ab55'
    },
    arrowIcon: {
        color: 'black',
        fontSize: '20px'
    },
    iconSpacing: {
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 150px)',
            minHeight: 'calc( 100vh - 150px)',
        },
    },
    buttonDisplay: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right'
        },
        [theme.breakpoints.up('xl')]: {
            textAlign: 'right'
        }
    },
    buttonDisplay1: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex'
        }
    },
    addLocation: {
        marginTop: '10px',
    },
    cofirmBtn: {
        marginRight: '15px',
        marginTop: '10px',
        backgroundColor: "green",
        color: "white",
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    userboxviewStyle: {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.4)',
    },
    grid: {
        flexGrow: 1,
    },
    colPadding: {
        padding: '10px 5px',
        '& .MuiTypography-body1': {
            fontSize: '13px',
        },
        '& .MuiInputLabel-root': {
            fontSize: '13px',
        },
        '& .MuiOutlinedInput-input': {
            fontSize: '13px',
        }
    },
    userStyle: {
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        '& .MuiIconButton-root': {
            padding: '0px 8px'
        }
    },
    firstlastTextfield: {
        borderRadius: '4px 4px 8px 8px',
        marginTop: '6px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 4px 8px 8px',
        }
    },
    firstnameInputSpacing: {
        marginRight: '5px',
    },
    container: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 500,
        minHeight: 420,
        padding: '0px 15px',
        [theme.breakpoints.up("lg")]: {
            maxHeight: "calc( 100vh - 250px)",
            minHeight: "calc( 100vh - 250px)",
        },
        [theme.breakpoints.up("sm")]: {
            maxHeight: "calc( 100vh - 280px)",
            minHeight: "calc( 100vh - 280px)",
        }
    },
    textfieldStyle: {
        width: "95%",
        fontSize: 13,
        marginTop: "10px",
        marginLeft: '6px',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
        },
        [theme.breakpoints.up('xs')]: {
            width: "100%",
        },
        [theme.breakpoints.up('sm')]: {
            width: "100%",
        },
        [theme.breakpoints.up('md')]: {
            width: "96%",
        },
    },
    textfieldColor: {
        width: "100%",
        backgroundColor: "transparent",
        color: "#0072E5",
        marginTop: "8px",
        marginLeft: '10px',
        fontSize: '15px',
        fontWeight: 'bold'
    },
    blueBorderBottom: {
        fontSize: 14,
        borderRadius: '6px 6px 6px 6px',
        '& .MuiOutlinedInput-input': {
            borderRadius: '6px 6px 6px 6px',
        },
        '& .MuiInputBase-input': {
            padding: '11px',
        },
    },
    noBorder: {
        border: "2px solid #215176",
        fontWeight: "bold"
    },
    saveBtn: {
        marginTop: '10px',
        marginRight: '15px',
        backgroundColor: "green",
        color: "white",
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    cancelBtn: {
        marginRight: '10px',
        backgroundColor: "red",
        color: "white",
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    pageLayout: {
        padding: '0px 10px 0px 30px',
        marginTop: '5px',
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 78px)',
            minHeight: 'calc( 100vh - 78px)',
        },
        '@media (min-width: 1180px) and (max-width: 1267px)': {
            minWidth: '120%',
        }
    },
    headStyle: {
        backgroundColor: "#215176",
        color: 'white',
        padding: '5px',
        textAlign: 'center',
        fontSize: '14px',
        borderRadius: '5px'
    },
    yesButton: {
        background: "green",
        color: 'white',
        borderRadius: 20,
        fontSize: 12,
        marginLeft: '30%',
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        borderRadius: 20,
        fontSize: 12,
        marginLeft: '19px',
        '&:hover': {
            background: "red",
        }
    },
    HeaderStyle: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    MessageStyle: {
        color: "black",
        fontSize: 17
    },
    DialogBottom: {
        marginRight: '10px'
    },
    paddingStyle: {
        padding: '10px',
        marginTop: '10px',
        marginBottom: '5px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginTop: '8px'
    },
    navText: {
        color: '#ffffff',
        textAlign: 'center',
        padding: '15px',
        marginTop: '10px',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    approveBtn: {
        backgroundColor: 'green',
        color: 'white',
        marginRight: '15px',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    rejectBtn: {
        backgroundColor: 'red',
        color: 'white',
        marginRight: '15px',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    closeIcon: {
        color: '#000',
        fontSize: 22,
        marginTop: '5px'
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    documentNotApproved: {
        borderRadius: 20,
        fontSize: 10,
        background: "#215176",
        '&:hover': {
            background: "#215176",
        }
    },
    documentApproved: {
        borderRadius: 20,
        fontSize: 10,
        background: "green",
        '&:hover': {
            background: "green",
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 16
    },
    fontColor: {
        color: 'black'
    },
    sendButton: {
        marginTop: '10px',
        marginRight: '20px',
    },
    clientButton: {
        marginTop: '10px',
        marginRight: '10px',
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            background: "green",
        }
    },
    onboardClient: {
        backgroundColor: 'rgba(118, 53, 220, 0.08)',
        color: 'rgb(32,10,105)',
        borderRadius: '5px',
        padding: '10px'
    },
    textfield: {
        color: "black",
        fontSize: '14px'
    },
    listStyle: {
        paddingLeft: '0%',
        margin: '0%'
    },
    informationIconStyle: {
        color: "red",
        marginBottom: '-10px',
        marginRight: "10px"
    },
    phoneNumberExt: {
        width: "25%",
        fontSize: 13,
        marginTop: "10px",
        marginLeft: '6px',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
        },
        [theme.breakpoints.up('xs')]: {
            width: "25%",
        },
        [theme.breakpoints.up('sm')]: {
            width: "25%",
        },
        [theme.breakpoints.up('md')]: {
            width: "25%",
        },
    },
    phoneNumberWarning: {
        width: "65%",
        fontSize: 13,
        marginTop: "10px",
        marginLeft: '6px',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
        },
        '& .MuiFormHelperText-root': {
            color: '#f44336 !important'
        },
        [theme.breakpoints.up('xs')]: {
            width: "65%",
        },
        [theme.breakpoints.up('sm')]: {
            width: "65%",
        },
        [theme.breakpoints.up('md')]: {
            width: "65%",
        },
    },
    phoneNumberField: {
        width: "65%",
        fontSize: 13,
        marginTop: "10px",
        marginLeft: '6px',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
        },
        [theme.breakpoints.up('xs')]: {
            width: "65%",
        },
        [theme.breakpoints.up('sm')]: {
            width: "65%",
        },
        [theme.breakpoints.up('md')]: {
            width: "65%",
        },
    },
}));
export { useStyles }