import {
  Backdrop, Card, Grid, makeStyles, Dialog, DialogActions, Box,
  DialogContent, TextField, Typography, Snackbar, Button
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
// Icons
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
// Models
import PuffLoader from "react-spinners/PuffLoader";
import { usePost } from '../../../utils/apiHelper';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { IGlobalMessage } from "../../../models/GlobalMessage/IGlobalMessage";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        display: "flex",
      },
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 260,
      fontWeight: 'bold'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    titleHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      fontSize: 18
    },
    card: {
      width: '300px',
      height: '230px',
      padding: '3px',
      marginLeft: "60px",
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    Notificationbtn: {
      borderRadius: 20,
      marginRight: "10px",
      fontSize: 12
    },
    createNotification: {
      borderRadius: '20px',
      marginRight: '28px',
      marginTop: '10px',
      fontSize: 12
    },
    notificationName: {
      minWidth: 370,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '10px',
      fontSize: 13
    },
    notificationMessage: {
      minWidth: 370,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '10px',
      marginTop: '20px',
      fontSize: 13
    },
    ErrorMessageStyle: {
      marginTop: '5px',
      fontSize: 13,
      color: 'red',
      textAlign: 'center'
    },
    dialogePaper: {
      minWidth: "780px"
    },
    smallFont: {
      fontSize: 13
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateNotification: React.FC<{ onSave: () => void }> = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const classes = useRowStyles()
  const { dispatch } = useGlobalState()
  const [MessageName, setMessageName] = useState<string>("");
  const [Message, setMessage] = useState<string>("");
  const [StartDate, setStartDate] = useState<any>();
  const [EndDate, setEndDate] = useState<any>();
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidDate, setValidDate] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);

  let dateTime = new Date(Date.now());

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCreate(false);
  };
  const ErrorMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    emptyCheck();
  };

  function emptyCheck() {
    setMessageName(null);
    setMessage(null);
    setStartDate(null);
    setEndDate(null);
    setIsValid(false);
    setValidDate(false);
    setError(null);
  }

  useEffect(() => {
    if (StartDate === EndDate) {
      setValidDate(true);
    }
    else if (EndDate < StartDate) {
      setValidDate(true);
    }
    else {
      setValidDate(false);
    }
  }, [StartDate, EndDate])

  const createNotification = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      let request = {
        "name": MessageName,
        "message": Message,
        "start_date": moment(StartDate).format('YYYY-MM-DD'),
        "end_date": EndDate,
        "date_created": dateTime
      };
      await usePost<IGlobalMessage>('CreateGlobalMessage', request).then((CreateGlobalMessage) => {
        props.onSave();
        setIsCreate(true);
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })();
  }

  const HandleMessageVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setMessageName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <Button size="small" id="GM_CreateNotification" startIcon={<NotificationAddIcon />} className={classes.Notificationbtn} variant="contained" color="primary" onClick={handleClickOpen}>
          Create Notification
        </Button>
      </Box>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Dialog open={open} onClose={(_) => { handleClose(); }}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogePaper }}
        TransitionComponent={Transition}
        PaperProps={{ style: { borderRadius: 15 } }}
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" gutterBottom className={classes.titleHeader}>
            CREATE NEW NOTIFICATION
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid xs={12} sm={6} md={6} lg={6} style={{ marginTop: '15px' }}>
              <form noValidate autoComplete="off"  >
                <TextField id="GM_Notification_Name" size="small" label="Notification Name" variant="outlined" value={MessageName}
                  onChange={(e) => { setMessageName(e.target.value); HandleMessageVal(e); }} className={classes.notificationName}
                  helperText={error} inputProps={{ maxlength: 100 }}
                  error={!!error}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput
                    }
                  }} />
              </form>
              <TextField id="GM_Message" size="small" label="Message" variant="outlined" value={Message} onChange={(e) => setMessage(e.target.value)}
                className={classes.notificationMessage} multiline rows={9} inputProps={{ maxlength: 512 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput
                  }
                }} />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6} style={{ marginTop: '15px' }}>
              <Card className={classes.card} >
                <h3 className={classes.textField}>From:</h3>
                <TextField
                  id="GM_Startdate"
                  size="small"
                  label="Start Date"
                  variant="outlined"
                  type="date"
                  value={StartDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className={classes.textField}
                  inputProps={{ max: "9999-12-31" }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <h3 className={classes.textField}>To:</h3>
                <TextField
                  id="GM_Enddate"
                  variant="outlined"
                  size="small"
                  label="End Date"
                  type="date"
                  value={EndDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ max: "9999-12-31" }}
                />
                {isValidDate && StartDate && EndDate ?
                  <Typography variant="subtitle2" className={classes.ErrorMessageStyle}>
                    End date should be greater than the Start date
                  </Typography>
                  : null
                }
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions >
          <Button size="small" id="GM_Create_btn" startIcon={<NotificationAddIcon />} autoFocus onClick={(_) => { createNotification(); handleClose(); }} color="primary" variant="contained" disabled={!Message || !MessageName || !StartDate || !EndDate || isValid || isValidDate}
            className={classes.createNotification}>
            Create Notification
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
          Notification Created Successfully.
        </Alert>
      </Snackbar>
      <Snackbar className="snackBarStyle" open={isError} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={ErrorMessage}>
        <Alert onClose={ErrorMessage} severity="error" className="alertStyle">
          Notification Not Created.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default CreateNotification