import {
    Backdrop, Box, Chip, CircularProgress, ClickAwayListener, DialogActions, DialogContentText, Button, Checkbox,
    Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Snackbar,
    TextField, Typography, FormGroup, InputLabel, Select, useMediaQuery, useTheme, Paper
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { Scrollbars } from 'react-custom-scrollbars';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
// Icons
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from "@material-ui/icons/Search";
import aproval from '../../images/users/approval.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Componants
import { useStyles } from './ProspectUserCss';
import LightTooltip from '../GlobalStyles/LightTooltip';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
// Models
import { usePost } from '../../utils/apiHelper';
import * as constant from "../../constants/Constant";
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { WidgetMap } from '../../models/accesscontrol/WidgetMap';
import { IGetBlobFolders } from '../../models/Files/IGetBlobFolders';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { AccessContext } from '../../models/accesscontrol/AccessContext';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { IAckClientInfo } from '../../models/IAckReport/IAckClientInfo';
import { EmailRegex } from '../../constants/Constant';


const EditProspectUser: React.FC<{ userRow: IProspectUsersDetails, onSave: () => void, CloudContainer: IGetBlobFolders[], PaymentContainer: IGetBlobFolders[], AckContainer: IGetBlobFolders[] }> = (props) => {
    const { state, dispatch } = useGlobalState();
    const classes = useStyles();
    const theme = useTheme();
    const userRow = props?.userRow;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    var newUser: AccessContext = new AccessContext();
    const [localState, setState] = React.useState(newUser);

    const [firstnameerror, setFirstNameError] = useState("");
    const [Lastnameerror, setLastNameError] = useState("");
    const [message, setMessage] = useState<string>('');
    const [Emailerror, setEmailError] = useState("");
    const [cloudfileError, setCloudfileError] = useState("");

    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [confirmedClients, setConfirmedClients] = useState([]);

    const [showSendEmailAttachment, setshowSendEmailAttachment] = useState(false);
    const [showFilePermisson, setShowFilePermission] = useState(false);
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [createUser, setCreateUser] = React.useState(false);
    const [ProgressBar, setShowProgressBar] = useState(true);
    const [isDisableFN, setIsDisableFN] = useState(false);
    const [isDisableLN, setIsDisableLN] = useState(false);
    const [showAdfLogs, setShowAdfLogs] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isValid, setIsValid] = useState(false);
    const [cloudFileFolderOpen, setCloudFileFolderOpen] = useState(false);
    const [paymentFileFolder, setPaymentFileFolder] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [searchClientCode, setSearchClientCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [fId, setFID] = useState(-1);
    const [fileContains, setFileContains] = useState("");
    const [getFileContains, setGetFileContains] = useState("");
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showWarningMessage, setShowWarningMessage] = useState<string>('');

    const handleAlertClose = () => {
        setAlertOpen(false);
        setCloudFileFolderOpen(false);
        setPaymentFileFolder(false);
        setShowWarning(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCreateUser(false);
        setIsValid(false);
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const handleSetUserDetails = (e) => {
        setState({ ...localState, firstName: userRow?.prospectGivenName, lastName: userRow?.prospectLastName, userName: userRow?.prospectEmailAddress, isProspectUser: true, isUserValidated: true });
    }

    const handleClickOpen = () => {
        if (userRow?.isNewOrginization && (!userRow?.isClientOnboarded && !userRow?.isClientSetup && !userRow?.isDocumentUploaded)) {
            setShowWarningMessage("Please Confirm the following details, Verify Client & Upload Contract & Client Setup ");
            setShowWarning(true);
        } else if (userRow?.isNewOrginization && !userRow?.isClientOnboarded) {
            setShowWarningMessage("Client Verification is not Completed, Please Verify the Client Information.");
            setShowWarning(true);
        } else if (userRow?.isNewOrginization && !userRow?.isClientSetup) {
            setShowWarningMessage("Client Setup is not completed, Please Setup the Client Information.");
            setShowWarning(true);
        } else if (userRow?.isNewOrginization && !userRow?.isDocumentUploaded) {
            setShowWarningMessage("Contract File(s) have not been uploaded for this client, Please upload and confirm.");
            setShowWarning(true);
        } else {
            (async () => {
                setConfirmedClients([]);
                setLoading(true);
                setShowProgressBar(true);
                setOpen(true);
                let request = {
                    "userId": state?.userAccessContext?.id,
                    "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                    "isAdmin": true
                }
                await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                    setConfirmedClients(r.data);
                    setLoading(false);
                }).finally(() => {
                    setShowProgressBar(false);
                    setCheckAll(false);
                });
            })()
        }
    };

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
                setSearchClientCode("");
            });
        })()
    };

    const handleClose = () => {
        setOpen(false);
        setState(newUser);
        resetValues();
        setCheckAll(false);
        setSearchClientCode("");
        setClientCodeOpen(false);
        setCloudfileError("");
        setFileContains("");
    };

    useEffect(() => {
        if (localState.flatFeeAcRem === false
            && localState.acWaitingtoMove === false
            && localState.daysSincePlacmentofAc === false
            && localState.placementHistoryofMonthAc === false
            && localState.ageImpactofonAcResolution === false
            && localState.unReadNotifications === false) {
            setState({
                ...localState, flatFeeAcRem: true,
                daysSincePlacmentofAc: true,
                acWaitingtoMove: true,
                placementHistoryofMonthAc: true,
                ageImpactofonAcResolution: true,
                unReadNotifications: true
            });
        }
    }, [localState]);

    const handleCreate = () => {
        (async () => {
            let selectedWidgets: number[] = [];
            for (let index = 1; index <= WidgetMap.size; index++) {
                var value = WidgetMap.get(index);
                if (value && localState[value]) {
                    selectedWidgets.push(index);
                }
            }
            localState.clientCodes = selectedClients.map(r => r.client_code);
            let finalExpression = "";
            if (!constant?.valComma.test(getFileContains)) {
                finalExpression = fileContains?.replace("µ", getFileContains?.toUpperCase());
            } else {
                finalExpression = fileContains?.replace("µ", getFileContains?.toUpperCase()?.replaceAll(',', '|'));
            }
            localState.ftpRegex = finalExpression;
            if (selectedClients.length <= 0) {
                setIsValid(true);
            } else {
                setShowProgressBar(true);
                await usePost<{ userInfo: AccessContext, totalRecordCount: number }>("User/SaveUser", localState).then(async (userList) => {
                    if (userList?.status == 400) {
                        setShowProgressBar(false);
                        dispatch({ type: GlobalStateAction.Error, error: userList.statusText })
                    }
                    else {
                        let request = {
                            "prospectUserId": userRow?.prospectUserId,
                            "userId": state.userAccessContext?.id
                        }
                        await usePost<any>("User/InsertUserCreateHistory", request);
                        const data = {
                            'userId': userList.data,
                            'widgets': selectedWidgets.join(',')
                        };
                        await usePost("User/AddWidgetsForClient", data).then((ClientsWidget) => {
                            if (ClientsWidget?.status == 400) {
                                setShowProgressBar(false);
                                setAlertOpen(true);
                                setMessage(ClientsWidget.statusText);
                                dispatch({ type: GlobalStateAction.Error, error: ClientsWidget.statusText })
                            }
                            else if (ClientsWidget?.status == 200) {
                                props.onSave();
                                setOpen(false);
                                setCreateUser(true);
                                setShowProgressBar(false);
                                setCreateUser(true);
                                resetValues();
                            }
                        });
                    }
                }).catch((err) => {
                    if (err.response?.status == 400) {
                        setAlertOpen(true);
                        setMessage(err.response.data);
                        setShowProgressBar(false);
                        dispatch({ type: GlobalStateAction.Error, error: err.statusText })
                    }
                }).finally(() => {
                    SendEmailtoAuthorizedUsers();
                    setShowProgressBar(false);
                });
            }
        })()
    };

    const SendEmailtoAuthorizedUsers = () => {
        //Email will trigger when a user is confirmed
        let sendEmail = {
            "userName": localState?.userName,
            "firstName": userRow?.prospectGivenName,
            "lastName": userRow?.prospectLastName
        }
        usePost<any>("SendEmailtoAuthorizedUsers", sendEmail);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.value,
        });
    };

    const handleChecked = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    const handleChangeShowAdfLogs = (event) => {
        setShowAdfLogs(event.target.checked);
    }

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;

        if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setFirstNameError("");
            setIsDisableFN(false);
        } else if (FirstNameVal === "") {
            setFirstNameError("First Name is Required");
            setIsDisableFN(true);
        } else {
            setFirstNameError("please enter characters only");
            setIsDisableFN(true);
        }
    };

    const HandleLastNameVal = (e) => {
        const LastNameVal = e.target.value;

        if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setLastNameError("");
            setIsDisableLN(false);
        } else if (LastNameVal === "") {
            setLastNameError("Last Name is Required");
            setIsDisableLN(true);
        }
        else {
            setLastNameError("please enter characters only");
            setIsDisableLN(true);
        }
    };

    const validateEmail = (e) => {
        const email = e.target.value;
        if (EmailRegex.test(email)) {
            setEmailError("");
            setIsDisableEmail(false);
        } else {
            setEmailError("please enter valid mail address");
            setIsDisableEmail(true);
        }
    };

    const validateCloudfile = (e) => {
        const fileText = e.target.value;
        if (constant?.FileContainRegex.test(fileText)) {
            setCloudfileError("");
        } else if (fileText !== "") {
            setCloudfileError("File Contains must be characters,numbers and without space");
        } else {
            setCloudfileError("");
        }
    };

    useEffect(() => {
        if (localState?.ftpFolder === "Pest Pac") {
            setFileContains(props?.CloudContainer[1]?.fileContains);
        } else if (localState?.ftpFolder === "Pest Routes") {
            setFileContains(props?.CloudContainer[2]?.fileContains);
        } else if (localState?.ftpFolder === "Service Pro") {
            setFileContains(props?.CloudContainer[3]?.fileContains);
        } else if (localState?.ftpFolder === "Rccbi") {
            setFileContains(props?.CloudContainer[5]?.fileContains);
        } else if (localState?.ftpFolder === "Blue Cow") {
            setFileContains(props?.CloudContainer[4]?.fileContains);
        }
    }, [localState?.ftpFolder])

    async function onContainerSelect(event, value) {
        setFileContains(value?.fileContains);
        setFID(value?.folder_Id);
        setState({ ...localState, ftpFolder: value?.folder_Name });
    }

    async function onPaymentFileSelect(event, value) {
        setState({ ...localState, paymentFileFolder: value["containerName"] });
    }

    const handleshowSendEmailToAdmin = (event) => {
        setshowSendEmailAttachment(event.target.checked);
        if (showSendEmailAttachment === false) {
            setState({ ...localState, sendEmailtoAdmin: false });
        }
    }

    const handleClientSelect = (event, selected) => {
        setSelectedClients(selected);
        if (selected?.length <= 0) {
            setCheckAll(false);
        }
    }

    const resetValues = () => {
        localState.role = 0;
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
        setSelectedClients([]);
        setConfirmedClients([]);
        setState(newUser);
        setCloudfileError("");
        setFileContains("");
    }

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            var NewClientList = confirmedClients.filter((c) => {
                return !selectedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = selectedClients.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients(NewClientList);
            setClientCodeOpen(false);
        }
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const onDelete = (clientcode) => () => {
        setSelectedClients((value) => value.filter((v) => v.client_code !== clientcode));
    };

    return (
        <React.Fragment>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        CREATE USER
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setAlertOpen(false); setOpen(true); }} className={classes.yesCrtEtButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Button size="small" id="EU_Edit" startIcon={<img src={aproval} width="20px" />}
                variant="contained" color="primary" disabled={userRow?.isPermissionUpdated || !userRow?.isActive} onClick={(e) => { handleClickOpen(); handleSetUserDetails(e) }}
                className={userRow?.isPermissionUpdated ? classes.approvedBtn : classes.notApprovedBtn} >
                {userRow?.isPermissionUpdated ?
                    <span>USER AUTHORIZED</span>
                    :
                    <span>SET PERMISSIONS</span>
                }
            </Button>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15, maxHeight: 'calc(100% - 7px)' } }}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleHeader}>
                        AUTHORIZE SINGED UP USER
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent >
                    <Grid container spacing={0} className={`${classes.grid} create-modal`}>
                        <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
                            <Box className={classes.userboxviewStyle}>
                                <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle}`}>{"USER PROFILE"}</Typography>
                                <form className={`${classes.root} ${classes.formFlex}`} noValidate autoComplete="off" >
                                    <TextField id="U_CU_FirstName" size="small" required label="First Name" variant="outlined" name="firstName" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                                        helperText={firstnameerror} error={!!firstnameerror} inputProps={{ maxlength: 25 }}
                                        value={localState?.firstName}
                                        className={`${classes.firstlastTextfield} ${classes.firstnameInputSpacing}`}
                                        autoComplete='off'
                                        InputProps={{
                                            classes: {
                                                root: classes.blueBorderBottom,
                                            },
                                        }}
                                    />
                                    <TextField id="U_CU_LastName" size="small" required label="Last Name" variant="outlined" name="lastName"
                                        onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror}
                                        error={!!Lastnameerror} inputProps={{ maxlength: 25 }}
                                        value={localState?.lastName}
                                        className={`${classes.firstlastTextfield} ${classes.lastnameInputSpacing}`}
                                        autoComplete='off'
                                        InputProps={{
                                            classes: {
                                                root: classes.blueBorderBottom,
                                            },
                                        }}
                                    />
                                </form>
                                <div>
                                    <form className={classes.root} noValidate autoComplete="off" >
                                        <TextField id="U_CU_Email" size="small" required label="Email" variant="outlined" name="userName"
                                            onChange={(e) => { handleChange(e); validateEmail(e) }} helperText={Emailerror}
                                            error={!!Emailerror} inputProps={{ maxlength: 50 }}
                                            value={localState?.userName}
                                            disabled
                                            className={classes.textFieldBorder}
                                            autoComplete='off'
                                            InputProps={{
                                                classes: {
                                                    root: classes.blueBorderBottom,
                                                },
                                            }} />
                                    </form>
                                </div>
                                <form className={classes.root} noValidate autoComplete="off" >
                                    <FormControl size="small" variant="outlined" className={`${classes.formControlCtEt} ${classes.adminBorderBottom}`} >
                                        <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                                        <Select native
                                            id="U_CU_adminLevel"
                                            onChange={handleChange}
                                            value={localState.role}
                                            label="Admin Level"
                                            inputProps={{
                                                name: 'role',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                            <option value={0}>Regular User</option>
                                            <option value={1}>Client Admin</option>
                                        </Select>
                                    </FormControl>
                                </form>
                            </Box>
                            <Box className={classes.userboxviewStyle}>
                                <div>
                                    <Box className={classes.LabelBoxStyle}>
                                        <FormLabel className={classes.formLabel}> Selected Client Code : {selectedClients.length}</FormLabel>
                                    </Box>
                                </div>
                                <div>
                                    {selectedClients?.length > 0 ?
                                        <Paper className={classes.boxBorder}>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                                autoHeightMin="auto"
                                                autoHeightMax={80}
                                                style={{ width: '100%' }}>
                                                <div className={`${classes.valueContainer}`} >
                                                    {selectedClients.map((v) => (
                                                        <Chip key={v.client_code} size="small" color="secondary" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                                    ))}
                                                </div>
                                            </Scrollbars>
                                        </Paper>
                                        :
                                        null
                                    }
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <Box>
                                            <Autocomplete
                                                multiple size="small"
                                                loading={loading}
                                                filterOptions={(options, state) => options}
                                                className={classes.clientCodeBox}
                                                autoComplete={false}
                                                id="checkboxes-tags-demo"
                                                options={confirmedClients}
                                                classes={{ paper: classes.paper2 }}
                                                disableClearable
                                                value={selectedClients}
                                                open={ClientCodeOpen}
                                                renderTags={() => null}
                                                onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                                onOpen={() => {
                                                    setClientCodeOpen(true);
                                                }}
                                                onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                    if (reason === "toggleInput") {
                                                        setClientCodeOpen(false);
                                                    }
                                                }}
                                                getOptionDisabled={(option) => option.client_code}
                                                getOptionLabel={(option) => option.client_code}
                                                getOptionSelected={(option, value) => option.client_code == value.client_code}
                                                renderOption={(option, { selected }) => (
                                                    <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '5px' }}>
                                                        {option?.client_code + "--" + option?.client_name}
                                                    </span>
                                                )}
                                                ListboxProps={
                                                    {
                                                        style: {
                                                            maxHeight: '160px'
                                                        }
                                                    }
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth size="small"
                                                        value={searchClientCode} variant="outlined"
                                                        type='text' placeholder="Search Clients"
                                                        onChange={(e) => { setSearchClientCode(e.target.value); }}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') { handleSearchClient(event); handleClickOpen(); setClientCodeOpen(true); }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="primary" size={15} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={(_) => { handleClickOpen(); setClientCodeOpen(true); }}>
                                                                            <SearchIcon className={classes.IconSizeStyle} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                    <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                        <Box className={classes.ClientcheckBoxStyle} width="60%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                                checked={checkAll}
                                                                                onChange={checkAllChange}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>Select All</span>
                                                                        </Box>
                                                                        <Box className={classes.ClientcheckBoxStyle} width="40%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                                checked={deselectClient}
                                                                                onChange={(e) => { DeselectAllChange(e); }}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>Deselect All</span>
                                                                        </Box >
                                                                    </Box >
                                                                </React.Fragment >
                                                            ),
                                                        }}
                                                        className={classes.textBoxStyle}
                                                    />
                                                )}
                                            />
                                        </Box >
                                    </ClickAwayListener >
                                </div >
                            </Box>
                            {
                                state.userAccessContext?.role === 3 && localState.role == 3 ?
                                    <React.Fragment>
                                        <Box className={`${classes.boxview}`}>
                                            <Typography variant="h6" gutterBottom className={classes.dialogHeaderStyle}>{"ADMIN PERMISSIONS"}</Typography>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <Box display="flex">
                                                        <Box m={0}>
                                                            {state.userAccessContext?.role === 3 ?
                                                                <FormControlLabel className={classes.checkBoxStyle1}
                                                                    id="U_CU_IsGlobalSettings"
                                                                    control={<Checkbox onChange={(e) => { handleChecked(e); handleChangeShowAdfLogs(e); }} name="isGlobalSettings" />}
                                                                    label="Global Settings"
                                                                />
                                                                : null
                                                            }
                                                            {showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings && localState.role == 3 ?
                                                                <React.Fragment>
                                                                    <FormControlLabel className={classes.checkBoxStyle1}
                                                                        id="U_CU_IsAdfLogs"
                                                                        control={<Checkbox onChange={handleChecked} name="isAdfLogs" />}
                                                                        label="ADF Logs"
                                                                    />
                                                                </React.Fragment>
                                                                :
                                                                null
                                                            }
                                                        </Box>
                                                        <Box m={0}>
                                                            {showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings && localState.role == 3 ?
                                                                <React.Fragment>
                                                                    <FormControlLabel className={classes.checkBoxStyle1}
                                                                        id="U_CU_IsAzureFunc"
                                                                        control={<Checkbox onChange={handleChecked} name="isAzureFunc" />}
                                                                        label="Azure Functions"
                                                                    />
                                                                    <FormControlLabel className={classes.checkBoxStyle1}
                                                                        id="U_CU_IsUserLoginStatus"
                                                                        control={<Checkbox onChange={handleChecked} name="isUserLoginStatus" />}
                                                                        label="User Login Status"
                                                                    />
                                                                </React.Fragment>
                                                                :
                                                                null
                                                            }
                                                        </Box>
                                                    </Box>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </React.Fragment>
                                    : null
                            }
                            {
                                showFilePermisson ?
                                    <React.Fragment>
                                        <Box className={classes.boxview2}>
                                            <Typography variant="h6" gutterBottom className={classes.dialogHeaderStyle}>{"FILE PERMISSIONS"}</Typography>
                                            <FormControl component="fieldset" >
                                                <FormGroup>
                                                    <Box display="flex">
                                                        <Box m={0} width="50%">
                                                            <FormControlLabel className={classes.checkBoxStyle}
                                                                id="U_CU_UploadFile"
                                                                control={<Checkbox size="small" onChange={handleChecked} name="uploadfile" />}
                                                                label="Upload"
                                                            />
                                                            <FormControlLabel className={classes.checkBoxStyle}
                                                                id="U_CU_Download"
                                                                control={<Checkbox size="small" onChange={handleChecked} name="downloadfile" />}
                                                                label="Download"
                                                            />
                                                        </Box>
                                                        <Box m={0} width="50%">
                                                            <FormControlLabel className={classes.checkBoxStyle}
                                                                id="U_CU_Create" disabled={(localState.role != 3)}
                                                                control={<Checkbox size="small" onChange={handleChecked} name="createfolder" />}
                                                                label="Create Folder"
                                                            />
                                                        </Box>
                                                    </Box>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </React.Fragment>
                                    : null
                            }
                        </Grid >
                        <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
                            <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"FOLDER PERMISSIONS"}</Typography>
                            <Box className={`${fileContains?.length > 0 ? classes.boxviewStyle : classes.boxview}`}>
                                <Typography variant="subtitle1" gutterBottom className={classes.FileFolderStyle}>
                                    Cloud File Folder
                                    <LightTooltip title="View Regex for Cloud File Folder">
                                        <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => { setCloudFileFolderOpen(true) }}>
                                            <InfoIcon style={{ fontSize: 18 }} />
                                        </IconButton>
                                    </LightTooltip>
                                </Typography>
                                <Autocomplete
                                    id="U_CU_Cloud_Dropdown"
                                    className={classes.dropdownStyle}
                                    classes={{
                                        paper: classes.paper1,
                                        option: classes.autoCompleteFont
                                    }}
                                    disableClearable={true}
                                    autoComplete autoHighlight defaultValue={props?.CloudContainer[0]}
                                    options={props.CloudContainer} onChange={onContainerSelect} loading={true}
                                    getOptionLabel={(option) => option.folder_Name}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label="Select Cloud Folder" margin="normal" variant="outlined" className={classes.cloudfolderStyle}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                                classes: {
                                                    root: classes.blackBorderBottom,
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {fId === -1 ? null : fId === 4 ? <Typography variant="body2" className={classes.regexText} style={{ marginLeft: '10px' }}> Sample Format : &nbsp;{constant?.exampeTwo} </Typography> : <Typography variant="body2" className={classes.regexText} style={{ marginLeft: '10px' }}>  Sample Format : {constant?.exampleOne} </Typography>}
                                <TextField id="outlined-basic" label="Colud File Contains" size="small" variant="outlined" name="ftpFilesContains" onChange={(e) => { handleChange(e); validateCloudfile(e); setGetFileContains(e.target.value); }}
                                    helperText={cloudfileError} error={!!cloudfileError} className={classes.fileContainsStyle} disabled={fId === -1 ? true : false}
                                    InputProps={{
                                        classes: {
                                            root: classes.blueBorderBottom,
                                        },
                                    }} />
                                <Typography variant="body2" className={classes.fileNote}><b> Note :  Please enter the files contains with comma separated values.</b></Typography>
                            </Box>
                            <Box className={classes.boxview4}>
                                <Typography variant="subtitle1" gutterBottom className={classes.FileFolderStyle}>
                                    Payment File Folder
                                    <LightTooltip title="View Regex for  Payment File Folder">
                                        <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => { setPaymentFileFolder(true) }}>
                                            <InfoIcon style={{ fontSize: 18 }} />
                                        </IconButton>
                                    </LightTooltip>
                                </Typography>
                                <Autocomplete
                                    id="U_CU_Payment_Dropdown"
                                    className={classes.dropdownStyle}
                                    classes={{
                                        paper: classes.paper1,
                                        option: classes.autoCompleteFont
                                    }}
                                    disableClearable={true}
                                    autoComplete autoHighlight defaultValue={props?.PaymentContainer[0]}
                                    options={props.PaymentContainer} onChange={onPaymentFileSelect} loading={true}
                                    getOptionLabel={(option) => option.folder_Name}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Payment Folder" size="small" margin="normal" variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                                classes: {
                                                    root: `${classes.blackBorderBottom} ${classes.blackBorderBottomPayment} `,
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <TextField id="outlined-basic" size="small" label="Payment File Contains" name="paymentRegex" variant="outlined" onChange={(e) => { handleChange(e); }} className={classes.paymentFileContainsStyle}
                                    InputProps={{
                                        classes: {
                                            root: classes.blueBorderBottom,
                                        },
                                    }} />
                            </Box>
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"AGENT USER PERMISSIONS"} </Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup >
                                        <FormControlLabel
                                            id="U_CU_FileAccess"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentCreateUser" />}
                                            disabled={(localState?.role < 2) || localState.role == null} label="Create Agent User"
                                        />
                                        <FormControlLabel
                                            id="U_CU_FileAccess"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentEditReport" />}
                                            disabled={localState?.role < 2 || localState.role == null} label="Edit Agent Report"
                                        />
                                        <FormControlLabel
                                            id="U_CU_FileAccess"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentReadOnlyAccess" />}
                                            disabled={localState?.role < 2 || localState.role == null} label="Agent Read Only Access"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"PAYMENT PERMISSIONS"} </Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup >
                                        <FormControlLabel
                                            id="U_CU_paymentUpdates"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="paymentUpdates" />}
                                            label="Payment Updates"
                                        />
                                        <FormControlLabel
                                            className={classes.permission}
                                            id="U_CU_closingAccounts"
                                            control={<Checkbox size="small" onChange={handleChecked} name="closingAccounts" />}
                                            label="Close Accounts"
                                        />
                                        <FormControlLabel
                                            id="U_CU_rollAccounts"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="rollAccounts" />}
                                            label="Roll Accounts"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"PLACEMENT PERMISSIONS"} </Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup >
                                        <FormControlLabel
                                            id="U_CU_placeAccounts"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="placeAccounts" />}
                                            label="Place Accounts"
                                        />
                                        <FormControlLabel
                                            id="U_CU_uploadUnmappedFile"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="uploadUnmappedFile" />}
                                            label="Upload Unmapped File"
                                        />
                                        <FormControlLabel
                                            id="U_CU_multiplePlacementAccess"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="multiplePlacementAccess" />}
                                            label="Multiphase Placement Access"
                                        />
                                        <FormControlLabel
                                            id="U_CU_multiClientCodePlacementAccess"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="multiClientCodePlacementAccess" disabled={localState.role == null || localState.role == 0 || localState.role == 4} />}
                                            label="Multi-Client Code Placement Access"
                                        />
                                    </FormGroup >
                                </FormControl>
                            </Box>
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"OTHER PERMISSIONS"} </Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup >
                                        <FormControlLabel
                                            id="U_CU_editReports"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="editReports" />}
                                            label="Edit Reports"
                                        />
                                        <FormControlLabel
                                            id="U_CU_createNewUsers"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="createNewUsers" />}
                                            label="Create New Users"
                                        />
                                        <FormControlLabel
                                            id="U_CU_sendEmailOnAttachements"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); handleshowSendEmailToAdmin(e); }} name="sendEmailOnAttachements" />}
                                            label="Send Email on attachments"
                                        />
                                        <FormControlLabel
                                            id="U_CU_sendEmailtoAdmin"
                                            className={`${classes.permission}`}
                                            control={<Checkbox size="small" onChange={handleChecked} name="sendEmailtoAdmin" disabled={!showSendEmailAttachment} />}
                                            label="Send Email To Admin"
                                        />
                                        <FormControlLabel
                                            id="U_CU_clientConfirmation"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={handleChecked} name="clientConfirmation" />}
                                            label="Client Confirmation"
                                        />
                                        <FormControlLabel
                                            id="U_CU_PerformanceReport"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="performanceReport" />
                                            }
                                            label="Performance Report"
                                        />
                                        <FormControlLabel
                                            id="U_CU_AdditionalChargesReport"
                                            className={classes.permission}
                                            control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="additionalChargesReport" />
                                            }
                                            label="Additional Charges Acknowledgment Report"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"NOTIFICATIONS"}</Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_disputeValidationNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="disputeValidationNotification" />}
                                            label="Dispute Validation Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_paymentVerificationNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="paymentVerificationNotification" />}
                                            label="Payment Verification Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_fileIssuesNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="fileIssuesNotification" />}
                                            label="File Issues Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_backupDocumentRequestNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="backupDocumentRequestNotification" />}
                                            label="Backup Document Request Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_settlementRequestsNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="settlementRequestsNotification" />}
                                            label="Settlement Requests Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_generalNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="generalNotification" />}
                                            label="General Notifications"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_RollReportNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="rollReportNotification" />}
                                            label="Roll Report Notification"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_AckReportNotification"
                                            control={<Checkbox size="small" onChange={handleChecked} name="ackReportNotification" />}
                                            label="Acknowledgement Report Notification"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                            <Box className={classes.boxviewWidgetsNotifications}>
                                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"WIDGETS"}</Typography>
                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_flatFeeAcRem"
                                            control={<Checkbox size="small" onChange={handleChecked} name="flatFeeAcRem" checked={localState.flatFeeAcRem} />}
                                            label="Flat Fee A/C Rem"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_acWaitingtoMove"
                                            control={<Checkbox size="small" onChange={handleChecked} checked={localState.acWaitingtoMove} name="acWaitingtoMove" />}
                                            label="A/C waiting to move"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_daysSincePlacmentofAc"
                                            control={<Checkbox size="small" onChange={handleChecked} checked={localState.daysSincePlacmentofAc} name="daysSincePlacmentofAc" />}
                                            label="Days Since Placement of A/C"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_placementHistoryofMonthAc"
                                            control={<Checkbox size="small" onChange={handleChecked} checked={localState.placementHistoryofMonthAc} name="placementHistoryofMonthAc" />}
                                            label="Placement History of 6 Month A/C"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_ageImpactofonAcResolution"
                                            control={<Checkbox size="small" onChange={handleChecked} checked={localState.ageImpactofonAcResolution} name="ageImpactofonAcResolution" />}
                                            label="Age Impact of on A/C resolution"
                                        />
                                        <FormControlLabel className={classes.permission}
                                            id="U_CU_unReadNotifications"
                                            control={<Checkbox size="small" onChange={handleChecked} checked={localState.unReadNotifications} name="unReadNotifications" />}
                                            label="Unread Notifications"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid >
                    <DialogActions>
                        <Button id="U_CU_Submit_btn" startIcon={<CheckCircleIcon />} onClick={handleCreate} className={classes.submitbtn}
                            disabled={(!selectedClients.length || !localState.firstName || !localState.lastName || !localState.userName || isDisableFN || isDisableLN || isDisableEmail) || (!cloudfileError?.length ? false : true)}
                            color="primary" variant="contained">
                            Confirm
                        </Button>
                        <Button id="U_CU_Close_btn" startIcon={<CancelIcon />} onClick={handleClose} color="primary" variant="contained" className={classes.cancelbtn}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent >
            </Dialog >
            <Snackbar className="snackBarStyle" open={createUser} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    User {localState.firstName}{localState.lastName} is Created Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={isValid} anchorOrigin={{
                vertical: 'top', horizontal: 'center'
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Please select atleast one client code.
                </Alert>
            </Snackbar>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={cloudFileFolderOpen} onClose={handleAlertClose} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        CLOUD FILE CONTAINS EXAMPLE REGEX
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentTextStyle}>
                        <span className={classes.MessageStyle} >
                            <Box display="flex" className={classes.boxStyle1}>
                                <Box width="95%">
                                    1. Regex Format : (Client Code) AND (_1 or _2 or _3 or _4) <br />
                                    Example Regex : <b style={{ color: 'green' }}>[\w]*START_REPLACE[\w]*_(END_REPLACE) </b><br />
                                </Box>
                                <Box width="5%">
                                    <LightTooltip title="Click here to Validate Regex">
                                        <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => window.open(constant.validateRegex)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </LightTooltip>
                                </Box>
                            </Box>
                            <Box className={classes.boxStyle}>
                                2. Regex Format : (1STP_HYNEXT) or (CONT_HYNEXT) or (PREC_HYNEXT) <br />
                                Example Regex : <b style={{ color: 'green' }}>[\w]*_(END_REPLACE) </b><br />
                            </Box>
                            <Box className={classes.boxStyle}>
                                3. Regex Format : (Client Code1) OR (Client Code2) OR (Client Code3) OR (Client Code4) OR (Client Code5) OR (Client Code6) OR (Client Code7) OR (Client Code8) OR (Client Code9) AND ( _1 OR _2 OR _3 OR _4) <br />
                                Example Regex : <b style={{ color: 'green' }}>[\w]*(START_WORDS_SEP_BY_PIPE)[\w]*_(ONE_TO_NINE_SEP_BY_PIPE) </b><br />
                            </Box>
                            <Box className={classes.boxStyle}>
                                4. Regex Format : (GGP001 OR GGP003 OR GGP004 OR GGP006 OR GGP007 OR GGP010 OR GGP011 OR GGP012 OR GGP015) and (_1 or _2 or _3 or _4) <br />
                                Example Regex : <b style={{ color: 'green' }}>[\w]*(START_WORDS_SEP_BY_PIPE)[\w]*_(ONE_TO_NINE_SEP_BY_PIPE)  </b><br />
                            </Box>
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setCloudFileFolderOpen(false) }} className={classes.yesCrtEtButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={paymentFileFolder} onClose={handleAlertClose} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        PAYMENT FILE CONTAINS EXAMPLE REGEX
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentTextStyle}>
                        <span className={classes.MessageStyle} >
                            <Box display="flex" className={classes.boxStyle1}>
                                <Box width="70%">
                                    1. Regex Format : CLIENT1 or CLIENT2 or EXSSTE <br />
                                    Example Regex : <b style={{ color: 'green' }}>CLIENT1 or CLIENT2 or EXSSTE	[\w]*START_WORDS_SEP_BY_PIPE[\w]*	</b> <br />
                                </Box>
                                <Box width="20%">
                                    <LightTooltip title="Click here to Validate Regex">
                                        <IconButton style={{ marginLeft: '80px' }} color="secondary" aria-label="upload picture" component="span" onClick={() => window.open(constant.validateRegex)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </LightTooltip>
                                </Box>
                            </Box>
                            <Box className={classes.boxStyle}>
                                2. Regex Format : (STE999) or (STEHAR) or (shred) or (stericycle) <br />
                                Example Regex : <b style={{ color: 'green' }}>[\w]*START_WORDS_SEP_BY_PIPE[\w]* </b><br />
                            </Box>
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setPaymentFileFolder(false) }} className={classes.yesCrtEtButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={showWarning} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        CLIENT CONFORMATION WARNING
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.textfield} gutterBottom>
                        {showWarningMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleAlertClose} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default EditProspectUser