import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from 'react';
import useStyles from './ReportStyleCSS';

const DeleteReport = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <IconButton id="R_Delete_btn" aria-label="delete" disabled className={classes.shareButtonStyle}>
                <DeleteForeverIcon />
            </IconButton>
        </div>
    )
}

export default DeleteReport