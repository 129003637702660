import DateFnsUtils from '@date-io/date-fns';
import {
  Avatar, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Hidden, IconButton, makeStyles, Paper, Snackbar, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
// Componants
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import StyledCell from "../../components/GlobalStyles/StyledCell";
//Imges for Icon
import AgentUser from '../../images/users/AgentUser.svg';
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
// Models
import { IGetUpdateHistoryUserList } from '../../models/UpdateHistory/GetUpdateHistoryUserList';
import { IGetLoginHistory } from '../../models/UserLoginHistory/GetLoginHistory';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: '-5px'
  },
  buttons: {
    borderRadius: 20,
    marginTop: '14px',
    marginRight: '20px',
    fontSize: 12
  },
  button1: {
    borderRadius: 20,
    marginTop: '14px',
    fontSize: 12
  },
  textField: {
    width: '100%',
    fontSize: 12,
    marginTop: '10px',
    ['@media (min-width:1390px)']: {
      width: 230,
    },
    ['@media (max-width:1390px)']: {
      maxWidth: 'calc( 400px - 230px)',
      minWidth: 'calc( 400px - 230px)',
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '4px',
      marginBottom: '4px'
    }
  },
  grid: {
    padding: '8px'
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    filter: 'drop-shadow(0 -6mm 4mm gray))'
  },
  filter: {
    gap: '5%'
  },
  root: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  progressBar: {
    height: '6px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 500,
    minHeight: 500,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 220px)',
      minHeight: 'calc( 100vh - 220px)'
    },
  },
  IconStyle: {
    height: '16px',
    width: '16px'
  },
  paper1: {
    border: "2px solid black",
  },
  ErrorMessageStyle: {
    color: "red",
    textAlign: 'center',
    fontSize: 14
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '100px'
  },
  clientAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  globalAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  superAdminColor: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  regularUser: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  fileAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid gray',
  },
  titleheader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '12px'
  },
  smallFont: {
    fontSize: 13
  },
  outlinedInput: {
    fontSize: 14,
    color: "black",
    marginTop: '2px',
    padding: '.7px'
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
    padding: '3px 15px'
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '3px 15px'
  },
  select: {
    height: "5.5vh"
  },
  regularUserText: {
    backgroundColor: 'rgb(226 223 251)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#9589e8',
    fontWeight: 500,
  },
  superAdminText: {
    backgroundColor: 'rgba(255, 180, 0, 0.12)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: 'rgb(255, 180, 0)',
    fontWeight: 500,
  },
  globalAdminText: {
    backgroundColor: 'rgb(251 210 216)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#ff7186',
    fontWeight: 500,
  },
  clientAdminText: {
    backgroundColor: 'rgb(230, 247, 255)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: 'rgb(24, 144, 255)',
    fontWeight: 500,
  },
  fileAdminText: {
    backgroundColor: 'rgb(235 219 241)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#82658d ',
    fontWeight: 500,
  },
  agentUserText: {
    backgroundColor: 'rgba(86, 202, 0, 0.12)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#56ca00',
    fontWeight: 500,
  }
}))

const usePopoverStyles = makeStyles({
  paper: {
    border: '2px solid black'
  }
});

const UserLoginHistory = () => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { state, dispatch } = useGlobalState();

  const [SelectActiveUser, setSelectActiveUser] = useState<any>([]);
  const [loginHistory, setloginHistory] = useState<IGetLoginHistory[]>([]);
  const [clientuserList, setClientUserList] = useState<IGetUpdateHistoryUserList[]>([]);

  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [totalRecords, setTotalRecords] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);

  const [orderBy, setOrderBy] = React.useState("emailAddress");
  const [order, setOrder] = React.useState("desc");

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [ClearTrigger, setClearTrigger] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [mapped, setMapped] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState("");

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy"
    : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const handleFirstDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedFirstDate(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setClearTrigger(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  useEffect(() => {
    (async () => {
      await useFetch<IGetUpdateHistoryUserList[]>("User/GetWebViewUsersAsync").then((u) => {
        setClientUserList(u?.data);
      });
    })()
  }, [])

  function onUsersSelect(event, value) {
    setSelectedEmail(value?.emailAddress);
    setSelectActiveUser(value);
    setSelectedUser(true);
    setPage(0);
  }

  useEffect(() => {
    getUserLoginListAsync();
  }, [rowsPerPage, page, order, orderBy])

  function ClearUserLoginHistoryAsync() {
    (async () => {
      let request = {
        "startdate": null, "enddate": null, "emailAddress": null, "orderBy": null,
        "orderDirection": "desc", "rowsPerPage": rowsPerPage,
        "startingRecordNumber": (page * rowsPerPage) + 1
      };
      setShowProgressBar(true);
      await usePost<{ userLoginHistories: IGetLoginHistory[], qty: number }>("UserLoginHistory/GetLoginHistory", request).then((GetLoginHistory) => {
        setloginHistory(GetLoginHistory.data["userLoginHistories"]);
        setMapped(!GetLoginHistory.data["userLoginHistories"].length ? true : false);
        setTotalRecords(GetLoginHistory.data.qty);
        setClearTrigger(true);
      }).finally(() => {
        resetValues();
        setShowProgressBar(false);
        setStartDate(null);
        setEndDate(null);
      });
    })();
  }

  function resetValues() {
    setSelectedFirstDate(false);
    setSelectedEndDate(false);
    setSelectedUser(false);
    setSelectActiveUser([]);
    setPage(0);
  }

  const handleTrigger = () => {
    getUserLoginListAsync();
    setOpen(true);
  }

  async function getUserLoginListAsync() {
    let request = {
      "startDate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
      "endDate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
      "emailAddress": (selectedUser ? (SelectActiveUser === null ? '' : selectedEmail) : null),
      "orderBy": orderBy,
      "orderDirection": order,
      "rowsPerPage": rowsPerPage,
      "startingRecordNumber": (page * rowsPerPage) + 1
    };
    setShowProgressBar(true);
    await usePost<{ userLoginHistories: IGetLoginHistory[], qty: number }>("UserLoginHistory/GetLoginHistory", request).then((GetLoginHistory) => {
      setloginHistory(GetLoginHistory.data["userLoginHistories"]);
      setMapped(!GetLoginHistory.data["userLoginHistories"].length ? true : false);
      setTotalRecords(GetLoginHistory?.data?.qty);
    }).finally(() => {
      setShowProgressBar(false);
    });
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    } if (b[orderBy] > a[orderBy]) {
      return 1;
    } return 0;
  }

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "fullUserName", numeric: false, disablePadding: false, label: "USER", sortable: true },
    { id: "usertype", numeric: false, disablePadding: false, label: "USER TYPE", sortable: true },
    { id: "emailAddress", numeric: false, disablePadding: false, label: "EMAIL", sortable: true },
    { id: "loginTime", numeric: false, disablePadding: false, label: "LOGIN DATE", sortable: true },
    { id: "loginTime", numeric: false, disablePadding: false, label: "LOGIN TIME", sortable: true },
  ];
  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setShowProgressBar(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            {StartDate > EndDate ? <Typography variant='h6' className={classes.ErrorMessageStyle}>
              End date should be greater than the Start date!</Typography> : ""}
          </Grid>
          <Grid container className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleheader}`}>
                <b> USER LOGIN HISTORY</b>
              </Typography>
            </Grid>
            <Grid spacing={1} item xs={6} sm={6} md={4} lg={2} alignContent='flex-end'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small" disableToolbar
                  autoOk={true} variant="inline"
                  label="Start Date" format={DateFormat}
                  margin="normal" className={classes.textField}
                  id="USR_StartDate" value={StartDate}
                  onChange={handleFirstDateChange}
                  inputVariant="outlined" disableFuture={true}
                  PopoverProps={{ classes: popoverClasses }}
                  InputProps={{ classes: { root: classes.outlinedInput } }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small" disableToolbar
                  autoOk={true} variant="inline"
                  label="End Date" format={DateFormat}
                  margin="normal" className={classes.textField}
                  id="USR_EndDate" inputVariant="outlined"
                  value={EndDate} disableFuture={true}
                  onChange={handleEndDateChange}
                  PopoverProps={{ classes: popoverClasses }}
                  InputProps={{ classes: { root: classes.outlinedInput } }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete size="small"
                  id="USR_SelectUsers"
                  autoComplete autoHighlight
                  classes={{ paper: classes.paper1, option: classes.smallFont }}
                  options={clientuserList}
                  groupBy={(option) => option?.userGroupName}
                  getOptionLabel={(option) => option?.fullName}
                  value={SelectActiveUser}
                  onChange={onUsersSelect}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span style={option?.isActive === 1 ? { color: "green" } : { color: "#FA8072" }}> {option?.fullName}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Users" margin="normal" variant="outlined" style={{ fontSize: 12 }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!clientuserList.length ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Hidden mdDown>
              <Grid item xs={12} sm={6} md={4} lg={2} className={classes.filter}>
                <Button id="USR_Filter_btn" size="small" className={classes.buttons} onClick={() => { handleTrigger(); }} startIcon={<FilterAltIcon />} variant="contained" color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" size="small" className={classes.button1} startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}
                  onClick={() => { ClearUserLoginHistoryAsync(); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <IconButton id="USR_FilterIcon_btn" size="small" aria-label="Filter" onClick={() => { handleTrigger(); }} style={{ color: 'blue' }} className={classes.filter} disabled={StartDate > EndDate}>
                  <FilterListIcon style={{ fontSize: 35 }} />
                </IconButton>
                <IconButton id="UpLog_Filter_IconButton" size="small" aria-label="Filter" onClick={() => { ClearUserLoginHistoryAsync(); }}
                  style={{ color: 'blue' }} className={classes.filter}>
                  <ClearRoundedIcon style={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs={12} component={Paper}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes} order={order}
                    orderBy={orderBy} onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(loginHistory, getComparator(order, orderBy)).map((row) => {
                      return (
                        <TableRow hover>
                          <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                            <AvatarTooltip title={
                              row.userRole === "5" ? "Agent User"
                                : row.userRole === "3" ? "Super Admin"
                                  : row.userRole === "2" ? "Global Admin"
                                    : row.userRole === "1" ? "Client Admin"
                                      : row.userRole === "0" ? "Regular User"
                                        : "File Admin"}>
                              <Avatar className={classes.fileAdmin} src={
                                row.userRole === "5" ? AgentUser
                                  : row.userRole === "3" ? SuperAdmin
                                    : row.userRole === "2" ? GlobalAdmin
                                      : row.userRole === "1" ? ClientAdmin
                                        : row.userRole === "0" ? RegularUser
                                          : FileAdmin
                              }></Avatar>
                            </AvatarTooltip>
                          </StyledCell>
                          <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                            {row.fullUserName}
                          </StyledCell>

                          <StyledCell className={classes.TableCellStyle1}>
                            {
                              (row.userRole === "0") ? <span >Regular User</span>
                                : (row.userRole === "1") ? <span >Client Admin</span>
                                  : (row.userRole === "2") ? <span >Global Admin</span>
                                    : (row.userRole === "3") ? <span >Super Admin</span>
                                      : (row.userRole === "5") ? <span> Agent User</span>
                                        : <span className={classes.fileAdminText}> File Admin</span>
                            }
                          </StyledCell>

                          <StyledCell className={classes.TableCellStyle1}>
                            {row.emailAddress}
                          </StyledCell>

                          <StyledCell className={classes.TableCellStyle1}>
                            {moment(row.loginTime).format(state.GlobalUtils?.settingValue)}
                          </StyledCell>

                          <StyledCell className={classes.TableCellStyle1}>
                            {moment(row.loginTime).format('HH:mm:ss')}
                          </StyledCell>
                        </TableRow>
                      );
                    }
                    )}
                  </TableBody>
                </Table>
                {mapped === true ?
                  < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography>
                  : null}
              </TableContainer>
              <Grid container spacing={0}>
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">

                    <TablePagination
                      id="USR_TablePagination"
                      rowsPerPageOptions={[15, 25, 50]}
                      component="div"
                      count={totalRecords === undefined ? 0 : totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Successfully Triggered!
          </Alert>
        </Snackbar>
        <Snackbar open={ClearTrigger} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Cleared Successfully.
          </Alert>
        </Snackbar>

      </div>
    </React.Fragment >
  )
}

export default UserLoginHistory