import {
    Box, Button, Card, CardMedia, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid,
    IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel, TextField, Typography, useMediaQuery, useTheme, ExpansionPanel, ExpansionPanelDetails,
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import moment from 'moment';
import useStyles from './AgentUserCss';
// Icons
import Backdrop from '@material-ui/core/Backdrop';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PuffLoader from "react-spinners/PuffLoader";
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import Caution from '../../images/ADF/complain.png';

import customerSupport from '../../images/agentUser/customerservice.svg';
import filePermision from '../../images/agentUser/fileactivity.svg';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { IActivityDetails } from '../../models/AgentTemplate/IActivityDetails';
import { IClientTemplateId, IClientTemplateResult, IUserTemplateList } from '../../models/AgentUser/IAgentClientDetails';
import { IClientPhaseDetails } from '../../models/AgentUser/IClientPhaseDetails';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { ActivityList } from '../../models/AgentInquiry/IAgentActivityListCount';
import CompactExpansionPanelSummary from '../GlobalStyles/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAgentUserTreeView from './EditAgentUserTreeView';
import { Scrollbars } from 'react-custom-scrollbars';

const ShowallActivities = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const CreateActivity: React.FC<{ agentUserID: number, isAccess: boolean, phaseList: IClientPhaseDetails, isReadOnly: boolean }> = (props) => {

    /* API EndPoint */
    const GetAgentUserTemplateList = "AgentUser/GetAgentUserTemplateList";
    const GetAgentUserActivitiesById = "AgentUser/GetAgentUserActivitiesById";
    const ManageAgentActivityTemplates = "AgentUser/ManageAgentActivityTemplates";

    const { agentUserID, isAccess, phaseList, isReadOnly } = props;
    const { state } = useGlobalState();

    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [order, setOrder] = useState("desc");
    const [searchText, setSearchText] = useState("");
    const [orderBy, setOrderBy] = useState("clientCode");

    const [isSaved, setIsSaved] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [noActivity, setNoActivity] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [readOnlyAll, setReadOnlyAll] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [allActivity, setAllActivity] = useState(false);
    const [loadActivity, setLoadActivity] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [dialogFullView, setDialogFullView] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [templateName, setTemplateName] = useState<string>(null);
    const [selectedPhase, setSelectedPhase] = useState<string>(null);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(15);

    const [currentTemplateList, setCurrentTemplateList] = useState<IClientTemplateResult>();
    const [clientTemplateList, setClientTemplateList] = useState<IClientTemplateResult[]>([]);
    const [selectedTemplateList, setSelectedTemplateList] = useState<IUserTemplateList[]>([]);
    const [selectedTemplateReadOnly, setSelectedTemplateReadOnly] = useState<IClientTemplateResult[]>([]);

    const [listEmailActivityJson, setListEmailActivityJson] = useState<ActivityList[]>([]);
    const [listAdminActivityJson, setListAdminActivityJson] = useState<ActivityList[]>([]);
    const [listCaseCreatedActivityJson, setListCaseCreatedActivityJson] = useState<ActivityList[]>([]);

    const [emailActivitiesWithFlag, setEmailActivitiesWithFlag] = useState<any[]>([]);
    const [adminActivitiesWithFlag, setAdminActivitiesWithFlag] = useState<any[]>([]);
    const [caseCreatedActivitiesWithFlag, setCaseCreatedActivitiesWithFlag] = useState<any[]>([]);
    const [currentActivity, setCurrentActivity] = useState([{ activityFieldId: 195 }, { activityFieldId: 196 }, { activityFieldId: 187 }]);

    const handlePageChange = (event, page) => {
        setPageNumber(page);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordsPerPage(+event.target.value);
        setPageNumber(0);
    };

    const handleclickOpenFullView = () => {
        getClientTemplateDetails();
        setDialogFullView(true);
    }

    const handleOpenDialogBox = () => {
        setConfirmation(true);
    }

    const handleCloseDialogBox = () => {
        setConfirmation(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') { return; };
        setIsValid(false);
    };

    const getActivitiesByTemplateId = (templateList: IClientTemplateResult) => {
        (async () => {
            if (templateList?.activityTemplateId !== undefined) {
                let request = {
                    "activityTemplateId": templateList?.activityTemplateId,
                    "clientCode": templateList?.clientCode,
                    "phaseCode": templateList?.phaseCode
                };
                setLoadActivity(true);
                await usePost<{ agentActivitiesResults: IActivityDetails[], activityTemplateName: string }>(GetAgentUserActivitiesById, request).then((r) => {
                    let currentActivities = _.cloneDeep(currentActivity);
                    let uniqueActivitiesSet = new Set();
                    // Add flag to each activity based on whether it matches the activity IDs in matchingActivityList
                    const emailActivitiesWithFlag = addIsEnable(listEmailActivityJson);
                    const adminActivitiesWithFlag = addIsEnable(listAdminActivityJson);
                    const caseCreatedActivitiesWithFlag = addIsEnable(listCaseCreatedActivityJson);

                    // Filter out activities with isEnable flag set to true
                    const enabledAdminActivities = filterEnabledActivities(adminActivitiesWithFlag);
                    const enabledCaseCreatedActivities = filterEnabledActivities(caseCreatedActivitiesWithFlag);
                    const enabledEmailActivities = filterEnabledActivities(emailActivitiesWithFlag);

                    // Function to filter out enabled activities recursively
                    function filterEnabledActivities(activities) {
                        const filteredActivities = [];
                        activities.forEach(activity => {
                            if (activity.isEnable) {
                                filteredActivities.push(activity);
                            }

                            if (activity.Activities && activity.Activities.length > 0) {
                                filterEnabledActivities(activity.Activities);
                            }
                        });
                        return filteredActivities;
                    }

                    // Function to recursively add isEnable field to activities
                    function addIsEnable(activities) {
                        // Create a new array to hold the modified activities
                        const modifiedActivities = activities.map(activity => {
                            // Check if any of the child activities (if they exist) has a match in agentActivitiesResults
                            const flag = activity.Activities ?
                                activity.Activities.some(childActivity => r?.data?.agentActivitiesResults?.findIndex((y) => y.activityFieldId === childActivity.ActivityMapId) > -1)
                                : r?.data?.agentActivitiesResults?.findIndex((y) => y.activityFieldId === activity.ActivityMapId) > -1;
                            // Set isEnable based on the flag
                            const modifiedActivity = { ...activity, isEnable: flag };
                            // Recursively add isEnable to child activities
                            if (activity.Activities) {
                                modifiedActivity.Activities = addIsEnable(activity.Activities);
                            }
                            if (flag) {
                                let activityList = {
                                    activityFieldId: activity.ActivityMapId,
                                };
                                // Add the unique activity to the Set
                                uniqueActivitiesSet.add(JSON.stringify(activityList));
                            }
                            return modifiedActivity;
                        });
                        return modifiedActivities;
                    }
                    // Convert Set back to array of unique activities
                    currentActivities = Array.from(uniqueActivitiesSet).map((activity: any) => JSON.parse(activity));

                    setEmailActivitiesWithFlag(enabledEmailActivities);
                    setAdminActivitiesWithFlag(enabledAdminActivities);
                    setCaseCreatedActivitiesWithFlag(enabledCaseCreatedActivities);
                    setCurrentActivity(currentActivities);
                    setTemplateName(r?.data?.activityTemplateName);
                }).catch(() => {
                    setLoadActivity(false);
                }).finally(() => {
                    setLoadActivity(false);
                });
            }
        })()
    }

    const handleShowAllActivities = (e, checked) => {
        setAllActivity(checked);
        if (checked) {
            let currentActivities = _.cloneDeep(currentActivity);
            // Add flag to each activity based on whether it matches the activity IDs in matchingActivityList
            const adminActivitiesWithFlag = addIsEnable(listAdminActivityJson);
            const caseCreatedActivitiesWithFlag = addIsEnable(listCaseCreatedActivityJson);
            const emailActivitiesWithFlag = addIsEnable(listEmailActivityJson);

            // Function to recursively add isEnable field to activities
            function addIsEnable(activities) {
                // Create a new array to hold the modified activities
                const modifiedActivities = activities.map(activity => {
                    // Check if any of the child activities (if they exist) has a match in agentActivitiesResults
                    const flag = activity.Activities ?
                        activity.Activities.some(childActivity =>
                            currentActivities?.findIndex((y) => y.activityFieldId === childActivity.ActivityMapId) > -1
                        ) :
                        currentActivities?.findIndex((y) => y.activityFieldId === activity.ActivityMapId) > -1;
                    // Set isEnable based on the flag
                    const modifiedActivity = { ...activity, isEnable: flag };
                    // Recursively add isEnable to child activities
                    if (activity.Activities) {
                        modifiedActivity.Activities = addIsEnable(activity.Activities);
                    }
                    if (flag) {
                        let activityList = {
                            activityFieldId: activity.ActivityMapId,
                        };
                        currentActivities?.push(activityList);
                    }
                    return modifiedActivity;
                });
                return modifiedActivities;
            }
            setEmailActivitiesWithFlag(emailActivitiesWithFlag);
            setAdminActivitiesWithFlag(adminActivitiesWithFlag);
            setCaseCreatedActivitiesWithFlag(caseCreatedActivitiesWithFlag);
        } else if (!checked) {
            getActivitiesByTemplateId(currentTemplateList);
        }
        setLoadActivity(false);
    }

    const handleReadOnlyChecked = (event, templateList: IClientTemplateResult) => {
        setIsModified(true);
        //---------------
        let selectedTemplates = _.cloneDeep(selectedTemplateList);
        if (selectedTemplates.findIndex(x => x.clientCode == templateList.clientCode) <= -1) {
            let template = {
                clientCode: templateList.clientCode,
                phaseCode: templateList.phaseCode,
                readOnly: event.target.checked,
                activityTemplateId: templateList.activityTemplateId
            };
            selectedTemplates.push(template);
            setSelectedTemplateList(selectedTemplates);
        }
        //----
        let newClientTemplateList = _.cloneDeep(clientTemplateList);
        newClientTemplateList.map((r, i) => {
            if (templateList?.clientCode === r?.clientCode) {
                r.readOnly = event.target.checked
            };
        })
        setClientTemplateList(newClientTemplateList);
    }

    /* On select the Template fetch the template activities */
    const handleRowSelected = (event, templateList: IClientTemplateResult) => {
        //------------
        let selectedTemplates = _.cloneDeep(selectedTemplateList);
        if (event.target.checked && selectedTemplates.findIndex(x => x?.clientCode == templateList?.clientCode) <= -1) {
            let template = {
                clientCode: templateList.clientCode, phaseCode: templateList.phaseCode,
                readOnly: templateList.readOnly, activityTemplateId: templateList.activityTemplateId
            };
            selectedTemplates.push(template);
            setSelectedTemplateList(selectedTemplates);
        } else if (!event.target.checked && selectedTemplates.findIndex(x => x.clientCode === templateList?.clientCode) > -1) {
            setSelectedTemplateList(selectedTemplates.filter(x => x?.clientCode !== templateList?.clientCode));
        };
        //
        let newClientTemplateList = _.cloneDeep(clientTemplateList);
        newClientTemplateList.map((r, i) => {
            if (!event.target.checked && r?.clientCode == templateList?.clientCode) {
                r.readOnly = false
            }
        });
        setClientTemplateList(newClientTemplateList);
    }

    // Differentiate row select vs mouse click, always only one row is mouse selected
    const handleRowSelectOnMouseClicked = (templateList: IClientTemplateResult) => {
        setCurrentTemplateList(templateList);
        setAllActivity(false);
        getActivitiesByTemplateId(templateList);
    }

    const handleCloseFullView = () => {
        setDialogFullView(false);
        setClientTemplateList([]);
        setSelectedTemplateReadOnly([]);
        setSelectedTemplateList([]);
        setCurrentActivity([]);
        setLoadActivity(false);
        setLoadingTemplate(false);
        setIsSaved(false);
        setNoActivity(false);
        setIsModified(false);
        setSelectAll(false);
        setReadOnlyAll(null);
        setPageNumber(0);
        setAllActivity(false);
        setSearchText("");
    };

    // Simplified Review
    const handleActiveTabs = (phaseCode: string) => {
        let phaseNumber = 0;
        switch (phaseCode) {
            case "1STP": phaseNumber = 1; break;
            case "PREC": phaseNumber = 2; break;
            case "CONT": phaseNumber = 3; break;
        }
        setActiveTab(phaseNumber);
        setSelectedPhase(phaseCode);
    }

    // Simplifed Review
    const handlePhaseFilter = (phaseCode: string) => {
        setSelectedPhase(phaseCode);
        let phaseNumber = 0;
        switch (phaseCode) {
            case "1STP": phaseNumber = 1; break;
            case "PREC": phaseNumber = 2; break;
            case "CONT": phaseNumber = 3; break;
        }
        setActiveTab(phaseNumber);
        setPageNumber(0);
        setAllActivity(false);
    }

    /* Modifiy readOnly flag in selected template */
    /* START */
    const handleActivityChecked = (e, checked, parentActivityId) => {
        //================================================================================================================
        let newActivityList = [...currentActivity];
        const value = Number(e.target.value);
        const emailActivitiesWithFlag1 = updateIsEnable(emailActivitiesWithFlag, parseInt(e.target.value), checked);
        const adminActivitiesWithFlag1 = updateIsEnable(adminActivitiesWithFlag, parseInt(e.target.value), checked);
        const caseCreatedActivitiesWithFlag1 = updateIsEnable(caseCreatedActivitiesWithFlag, parseInt(e.target.value), checked);

        setEmailActivitiesWithFlag(emailActivitiesWithFlag1);
        setAdminActivitiesWithFlag(adminActivitiesWithFlag1);
        setCaseCreatedActivitiesWithFlag(caseCreatedActivitiesWithFlag1);

        if (checked) {
            if (newActivityList.findIndex(x => x.activityFieldId === value) === -1) {
                newActivityList.push({ activityFieldId: value });
            }
        } else {
            const index = newActivityList.findIndex(x => x.activityFieldId === value);
            if (index !== -1) {
                newActivityList.splice(index, 1);
            }
        }
        // Add root activity ID (parentActivityId) and its ancestors recursively
        if (parentActivityId && !newActivityList.some(item => item.activityFieldId === parentActivityId)) {
            newActivityList.push({ activityFieldId: parentActivityId });
        }
        setCurrentActivity(newActivityList);
        //=============================================================================================================================
        (async () => {
            setIsModified(true);
            //-----
            let selectedTemplates = _.cloneDeep(selectedTemplateList);
            if (selectedTemplates.findIndex(x => x?.clientCode == currentTemplateList?.clientCode) <= -1) {
                let template = {
                    clientCode: currentTemplateList?.clientCode, phaseCode: currentTemplateList?.phaseCode,
                    readOnly: currentTemplateList?.readOnly, activityTemplateId: currentTemplateList?.activityTemplateId
                };
                selectedTemplates.push(template);
                setSelectedTemplateList(selectedTemplates);
            };
        })()
    }
    /* END */

    // Function to update isEnable flag based on checkedActivityId and enabledActivities
    function updateIsEnable(activitiesData, checkedActivityId, checked) {
        // Loop through activities to update isEnable flag
        activitiesData.forEach(activity => {
            // Check if the activity ID matches the checkedActivityId
            if (activity.ActivityMapId === checkedActivityId) {
                // Update isEnable based on whether the activity ID is in enabledActivities
                activity.isEnable = checked;
            }
            // Recursively update isEnable flag for child activities
            if (activity.Activities) {
                updateIsEnable(activity.Activities, checkedActivityId, checked);
            }
        });
        return activitiesData;
    }

    /* Fetch default activities from master - when user wants to override a new 
    template for multiple clients - which leads to new template */
    useEffect(() => {
        (async () => {
            await useFetch<{ emailActivityList: string, adminActivityList: string, caseCreatedActivityList: string }>("AgentUser/GetAgentActivityList").then((r) => {
                setListEmailActivityJson(JSON.parse(r?.data?.emailActivityList));
                setListAdminActivityJson(JSON.parse(r?.data?.adminActivityList));
                setListCaseCreatedActivityJson(JSON.parse(r?.data?.caseCreatedActivityList));
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            getClientTemplateDetails();
        })()
    }, [recordsPerPage, pageNumber, selectedPhase, orderBy, order])

    /* Fetch the template details based on the Client Code selected by user */
    /* START */
    const getClientTemplateDetails = () => {
        (async () => {
            let request = {
                "agentUserId": agentUserID,
                "searchText": searchText?.trim() ?? "",
                "recordNumber": (pageNumber * recordsPerPage) + 1,
                "rowsPerPage": recordsPerPage,
                "phaseCode": selectedPhase,
                "orderBy": orderBy,
                "orderDirection": order
            };
            setLoading(true);
            setLoadingTemplate(true);
            await usePost<{ clientTemplateResults: IClientTemplateResult[], clientPhaseDetails: IClientPhaseDetails[], totalCount: number }>(GetAgentUserTemplateList, request).then((r) => {
                setClientTemplateList(r?.data?.clientTemplateResults);
                setCurrentTemplateList(r?.data?.clientTemplateResults[0]);
                handleActiveTabs(r?.data?.clientTemplateResults[0]?.phaseCode);
                getActivitiesByTemplateId(r?.data?.clientTemplateResults[0]);
                setTotalRecords(r?.data?.totalCount);
            }).catch(() => {
                setLoadingTemplate(false);
            }).finally(() => {
                setLoadingTemplate(false);
                setLoading(false);
            });
        })()
    }

    const ClearClientTemplateDetails = () => {
        (async () => {
            let request = {
                "agentUserId": agentUserID,
                "searchText": '',
                "recordNumber": (pageNumber * recordsPerPage) + 1,
                "rowsPerPage": recordsPerPage,
                "phaseCode": selectedPhase,
                "orderBy": orderBy,
                "orderDirection": order
            };
            setLoading(true);
            setLoadingTemplate(true);
            await usePost<{ clientTemplateResults: IClientTemplateResult[], clientPhaseDetails: IClientPhaseDetails[], totalCount: number }>(GetAgentUserTemplateList, request).then((r) => {
                setClientTemplateList(r?.data?.clientTemplateResults);
                handleActiveTabs(r?.data?.clientTemplateResults[0]?.phaseCode);
                getActivitiesByTemplateId(r?.data?.clientTemplateResults[0]);
                setTotalRecords(r?.data?.totalCount);
            }).catch(() => {
                setLoadingTemplate(false);
            }).finally(() => {
                setLoadingTemplate(false);
                setLoading(false);
            });
        })()
    }
    /* END */

    /* Sorting the Table */
    /* START */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPageNumber(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    /* END */

    /* Select all the Template by clicking header checkbox */
    /* START */
    const handleSelectAllReadOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReadOnlyAll(event.target.checked);
        setIsModified(true);
        if (event.target.checked) {
            let newSelectedTemplate = [];
            clientTemplateList.map((TemplateDetails) => newSelectedTemplate.push(
                {
                    activityTemplateId: TemplateDetails?.activityTemplateId,
                    activityTemplateName: TemplateDetails?.activityTemplateName,
                    clientCode: TemplateDetails?.clientCode,
                    clientName: TemplateDetails?.clientName,
                    phaseCode: TemplateDetails?.phaseCode,
                    readOnly: true
                }));
            setClientTemplateList(newSelectedTemplate);
            setSelectedTemplateReadOnly(newSelectedTemplate);
        }
        else if (!event.target.checked) {
            setReadOnlyAll(event.target.checked);
            let currentList = _.cloneDeep(selectedTemplateList);
            let newSelectedTemplate = [];
            clientTemplateList.map((TemplateDetails) => newSelectedTemplate.push(
                {
                    activityTemplateId: TemplateDetails?.activityTemplateId,
                    activityTemplateName: TemplateDetails?.activityTemplateName,
                    clientCode: TemplateDetails?.clientCode,
                    clientName: TemplateDetails?.clientName,
                    phaseCode: TemplateDetails?.phaseCode,
                    readOnly: event.target.checked
                }));
            if (currentList.findIndex(x => x?.clientCode == currentTemplateList?.clientCode) <= -1) {
                let templateList: IUserTemplateList = {
                    "clientCode": currentTemplateList?.clientCode,
                    "phaseCode": currentTemplateList?.phaseCode,
                    "readOnly": event.target.checked,
                    "activityTemplateId": currentTemplateList?.activityTemplateId
                };
                currentList.push(templateList);
            };
            setClientTemplateList(newSelectedTemplate);
            setSelectedTemplateList(currentList);
            setSelectedTemplateReadOnly([]);
        };
    };
    /* END */

    // Verified
    const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectAll(e.target.checked);
        setIsModified(true);
    };

    /* Save the Activity Details and Template Details */
    /* START */
    const handleSaveActivity = () => {
        setLoading(true);
        (async () => {
            if (isModified) {
                setIsSaved(true);
                let activityRequest = [];
                let newTemplateList = [];
                let newTemplateIdList = [];
                selectedTemplateList?.map((s) => {
                    /* Needs to remove the hidden flag */
                    let TemplateDetails = {
                        readOnly: s?.readOnly, hidden: false,
                        clientCode: s?.clientCode, phaseCode: s?.phaseCode
                    };
                    let TemplateId: IClientTemplateId = { agentUserTemplateId: s?.activityTemplateId };
                    newTemplateList.push(TemplateDetails);
                    newTemplateIdList.push(TemplateId);
                })
                /* TODO -- Needs to merge the templateId list in the agentUserTemplateDetails array*/
                let request = {
                    "createdBy": state?.userAccessContext?.id,
                    "agentUserId": agentUserID,
                    "selectAll": selectAll,
                    "readOnlyAll": readOnlyAll,
                    "phaseCode": selectedPhase,
                    "agentTemplateDetails": newTemplateIdList,
                    "modifiedDate": moment(new Date()).format("YYYY-MM-DD HH:MM:ss"),
                    "agentUserTemplateDetails": newTemplateList,
                    "activityMapingDetail": currentActivity,
                };

                await usePost(ManageAgentActivityTemplates, request).then(() => {
                    setSelectedTemplateList([]);
                    setSelectedTemplateReadOnly([]);
                    getClientTemplateDetails();

                }).catch(() => {
                    setIsSaved(false);
                    setIsModified(false);

                }).finally(() => {
                    setIsSaved(false);
                    setIsModified(false);
                    setIsValid(true);
                    setSelectAll(false);
                    setReadOnlyAll(null);
                    setLoading(false);
                });
            }
        })()
    }
    /* END */

    /* Display Header with sotring */
    /* START */
    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort, onSelectAllClick, onSelectAllReadOnly, numSelected, rowCount } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "clientCode", disablePadding: false, label: "CLIENT CODE", sortable: true },
            { id: "clientName", disablePadding: false, label: "CLIENT NAME", sortable: true },
        ];

        const headCellsPhase = [
            { id: "phaseCode", disablePadding: false, label: "PHASE", sortable: true }
        ];

        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.TableCellStyle1} padding="checkbox">
                        <Checkbox disabled={isReadOnly} checked={selectAll}
                            className={classes.checkboxColor} size="small"
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all Users' }}
                        />
                    </TableCell>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellStyle1}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell className={classes.TableCellStyle1} padding="checkbox">
                        <div className={classes.phaseGridStyle}>
                            <Checkbox disabled={isReadOnly}
                                size="small" className={classes.checkboxColor}
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllReadOnly}
                                inputProps={{ 'aria-label': 'select all Users' }}
                            />
                            READ ONLY
                        </div>
                    </TableCell>
                    {headCellsPhase.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellStyle1}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    /* END */

    return (
        <React.Fragment>
            <Card className={classes.cardDesign}>
                <CardMedia
                    component="img"
                    style={{ width: 100, padding: '5px', marginLeft: '3px' }}
                    image={filePermision}
                    alt="Activity Permission"
                />
                <Box className={classes.activityBox}>
                    <Typography variant="h6" style={{ marginLeft: '5px', fontSize: 17 }}>
                        Click here to customize the activity permissions.
                    </Typography>
                    <Box p={0} display="flex">
                        <Box m={0} >
                            <Button size="small" onClick={handleclickOpenFullView}
                                disabled={isAccess} color="primary" endIcon={<ArrowForwardIcon />}
                                className={classes.activitybtn1}>
                                Customize Activity
                            </Button>
                        </Box>
                        <Box m={0} style={{ marginLeft: '15px' }}>
                            {loadingTemplate ?
                                <div style={{ display: 'flex' }}>
                                    <div className='loader2' style={{ marginTop: '7px' }} />
                                    <span style={{ marginTop: '7px', fontSize: '14px', paddingLeft: '5px' }}>Loading...</span>
                                </div>
                                : null}
                        </Box>
                    </Box>
                </Box>
            </Card>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'xl'}
                // classes={{ paper: classes.dialogePaperActivity }}
                fullScreen={fullScreen}
                open={dialogFullView}
                aria-labelledby="responsive-dialog-title">

                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>

                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseFullView}>
                    <Typography variant="h6" className={classes.titleHeaderStyle}>
                        MANAGE ACTIVITY PERMISSIONS
                    </Typography>
                </DialogTitleHeader>

                <DialogContent >
                    <div style={{ padding: '10px 0px 0px' }}>
                        <Card className={classes.gridflex}>
                            <div className={classes.flexDispaly}>
                                <Box width="20%" className={classes.sidebar}>
                                    {phaseList?.isFirstParty ?
                                        <div className={`${classes.sidebarItem} ${activeTab == 1 ? classes.activeSidebar : null} ${"ripple"}`} onClick={() => { handlePhaseFilter("1STP"); }}>
                                            <PeopleAltIcon />
                                            <Typography variant='h6' className={`${classes.textpropertyOut} ${activeTab == 1 ? classes.activeSidebarText : null}`} >
                                                1ST PARTY
                                            </Typography>
                                        </div>
                                        : null}

                                    {phaseList?.isFlatFee ?
                                        <div className={`${classes.sidebarItem} ${activeTab == 2 ? classes.activeSidebar : null} ${"ripple"}`} onClick={() => { handlePhaseFilter("PREC"); }}>
                                            <PeopleAltIcon />
                                            <Typography variant='h6' className={`${classes.textpropertyOut} ${activeTab == 2 ? classes.activeSidebarText : null}`} >
                                                FLAT FEE
                                            </Typography>
                                        </div>
                                        : null}

                                    {phaseList?.isContingency ?
                                        <div className={`${classes.sidebarItem} ${activeTab == 3 ? classes.activeSidebar : null} ${"ripple"}`} onClick={() => { handlePhaseFilter("CONT"); }}>
                                            <PeopleAltIcon />
                                            <Typography variant='h6' className={`${classes.textpropertyOut} ${activeTab == 3 ? classes.activeSidebarText : null}`} >
                                                CONTINGENCY
                                            </Typography>
                                        </div>
                                        : null}

                                    <div className={classes.support}>
                                        <img alt="customerSupport" src={customerSupport} width="80px" height="80px" />
                                    </div>
                                </Box>
                                <Box width="30%" className={classes.mainmenu}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6} sm={12} className={classes.activityStyle}>
                                            <TextField
                                                fullWidth size="small" variant="outlined" type='text'
                                                value={searchText} className={classes.searchBox}
                                                placeholder="Search Users (First Name, Last Name or Email)"
                                                onChange={(e) => setSearchText(e.target.value)}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter' && searchText !== "") {
                                                        getClientTemplateDetails();
                                                        setPageNumber(0);
                                                        setShowSearchIcon(false);
                                                    } else if (event.key === 'Enter' && searchText === "") {
                                                        setPageNumber(0);
                                                        ClearClientTemplateDetails();
                                                        setShowSearchIcon(true);
                                                    }
                                                }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    classes: { root: classes.outlinedInput2, notchedOutline: classes.notchedOutline2, },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {showSearchIcon === true ?
                                                                <IconButton onClick={(_) => { setPageNumber(0); getClientTemplateDetails(); setShowSearchIcon(false); }}>
                                                                    <SearchIcon className={classes.IconSizeStyle} />
                                                                </IconButton>
                                                                :
                                                                <IconButton onClick={(_) => { setPageNumber(0); ClearClientTemplateDetails(); setShowSearchIcon(true); setSearchText(""); }}>
                                                                    <CloseIcon className={classes.IconSizeStyle} />
                                                                </IconButton>
                                                            }
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} style={{ padding: '5px 15px' }}>
                                            <TableContainer component={Paper} className={`${classes.tableBodyActivity} ${"scrollbox"} ${"on-scrollbar"}`}>
                                                <Table style={{ width: '100%' }} aria-label="customized table" stickyHeader>
                                                    <EnhancedTableHead
                                                        classes={classes}
                                                        numSelected={selectedTemplateReadOnly?.length}
                                                        numAllSelected={clientTemplateList?.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onSelectAllReadOnly={handleSelectAllReadOnly}
                                                        rowCount={clientTemplateList?.length}
                                                    />
                                                    <TableBody>
                                                        {clientTemplateList.map((r) => {
                                                            return (
                                                                <TableRow
                                                                    className={
                                                                        (r?.readOnly === true && currentTemplateList?.clientCode !== r?.clientCode) ? classes.readonlyTemplate
                                                                            : (currentTemplateList?.clientCode === r?.clientCode) ? classes.selectedTemplate
                                                                                : ((selectAll === true || r?.readOnly === false) && selectedTemplateList.findIndex((x) => x?.clientCode === r?.clientCode) >= 0) ? classes.selectedRow
                                                                                    : classes.unSelectedRow
                                                                    } >
                                                                    <TableCell className={classes.tablebodypadding}>
                                                                        <Checkbox disabled={isReadOnly || selectAll == true}
                                                                            size="small"
                                                                            checked={
                                                                                (selectAll === false && selectedTemplateList.findIndex((x) => x?.clientCode === r?.clientCode) >= 0) ? true
                                                                                    : (selectAll === true && selectedTemplateList.findIndex((x) => x?.clientCode === r?.clientCode) <= -1) ? true
                                                                                        : false}
                                                                            name="isSelected"
                                                                            style={{ padding: '0px' }}
                                                                            onClick={(e) => { handleRowSelected(e, r); }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablebodypadding}
                                                                        onClick={(e) => { handleRowSelectOnMouseClicked(r); }}>
                                                                        {r?.clientCode}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        onClick={(e) => { handleRowSelectOnMouseClicked(r); }}>
                                                                        {r?.clientName}
                                                                    </TableCell>

                                                                    <TableCell className={classes.tablebodypadding}>
                                                                        <Checkbox disabled={isReadOnly}
                                                                            size="small" name="readOnly"
                                                                            style={{ padding: '0px' }}
                                                                            checked={
                                                                                ((readOnlyAll === null || readOnlyAll === false) && selectedTemplateList.findIndex((x) => x?.clientCode === r?.clientCode) <= -1) ? r?.readOnly :
                                                                                    (readOnlyAll === true && selectedTemplateList.findIndex((x) => x?.clientCode === r?.clientCode) <= -1) ? true
                                                                                        : r?.readOnly}
                                                                            onChange={(e) => { handleReadOnlyChecked(e, r); }}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell className={classes.tablebodypadding}
                                                                        onClick={(e) => { handleRowSelectOnMouseClicked(r); }}>
                                                                        {r?.phaseCode}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                        }
                                                    </TableBody>
                                                </Table>
                                                {!clientTemplateList?.length ?
                                                    <Typography variant="h6" gutterBottom className={classes.norecord}>
                                                        No records to display...
                                                    </Typography>
                                                    : null}
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[15, 30, 50]}
                                                component="div"
                                                count={totalRecords}
                                                rowsPerPage={recordsPerPage}
                                                page={pageNumber}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box width="50%" className={
                                    currentTemplateList && currentTemplateList?.readOnly ? classes.readOnlyTabSelectedRow
                                        : selectedTemplateList ? classes.ManageTabSelectedRow
                                            : currentTemplateList?.readOnly ? classes.readOnlyTabSelectedRow
                                                : classes.ManageTab}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className={classes.divStyle}>
                                                <Typography variant="subtitle1" className={classes.manageTitle}>
                                                    <b>MANAGE PERMISSIONS</b>
                                                </Typography>
                                                {(selectAll || readOnlyAll === false || readOnlyAll === true) ? "(A)" : null}
                                                {currentTemplateList?.readOnly || isReadOnly ?
                                                    <AvatarTooltip title="Read Only">
                                                        <LockIcon style={{ marginTop: '5px' }} />
                                                    </AvatarTooltip>
                                                    : null}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={classes.TemplateNameStyle}>
                                                <Chip
                                                    size="small"
                                                    icon={<DescriptionIcon />}
                                                    label={<div style={{ fontSize: '12px' }}><b>{templateName}</b></div>}
                                                    clickable
                                                    style={{ color: 'black', border: '1px solid gray' }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!currentTemplateList?.readOnly || isReadOnly ?
                                                <div className={classes.switchPermission} >
                                                    <Typography variant="body2" gutterBottom className={classes.activityFont}>
                                                        <b>Show All Activities</b>
                                                    </Typography>
                                                    <FormControlLabel
                                                        labelPlacement="top"
                                                        label={""}
                                                        // label={<div style={{fontSize:'13px'}}><b>Show All Activities</b></div>}
                                                        checked={allActivity}
                                                        control={<ShowallActivities />}
                                                        onChange={(e, selected) => { setLoadActivity(true); handleShowAllActivities(e, selected); }}
                                                        style={{ marginRight: '0px' }}
                                                    />
                                                </div>
                                                : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.activityContainer}>
                                                {noActivity ?
                                                    <Typography variant="h6" gutterBottom className={classes.norecord}>
                                                        No permissions to display...
                                                    </Typography>
                                                    :
                                                    <React.Fragment>
                                                        <div className={`${!currentTemplateList?.readOnly || isReadOnly ? classes.showallCheckbox : null}`}>
                                                            {loadActivity ?
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <div className='loader2' style={{ marginTop: '5px' }} />
                                                                    <span style={{ marginTop: '7px', fontSize: '14px', paddingLeft: '5px' }}>
                                                                        Loading Activities...
                                                                    </span>
                                                                </div>
                                                                :
                                                                <>
                                                                    <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                                                        <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                                            <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                                                Email
                                                                            </Typography>
                                                                        </CompactExpansionPanelSummary>
                                                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                                            <EditAgentUserTreeView data={emailActivitiesWithFlag} currentTemplateList={currentTemplateList} isReadOnly={isReadOnly} onSaveActivity={handleActivityChecked} allActivity={allActivity} />
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>

                                                                    <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                                                        <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                                            <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                                                Admin
                                                                            </Typography>
                                                                        </CompactExpansionPanelSummary>
                                                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                                            <EditAgentUserTreeView data={adminActivitiesWithFlag} currentTemplateList={currentTemplateList} isReadOnly={isReadOnly} onSaveActivity={handleActivityChecked} allActivity={allActivity} />
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>

                                                                    <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                                                        <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                                            <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                                                Case Created/ Escalation
                                                                            </Typography>
                                                                        </CompactExpansionPanelSummary>
                                                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                                            <EditAgentUserTreeView data={caseCreatedActivitiesWithFlag} currentTemplateList={currentTemplateList} isReadOnly={isReadOnly} onSaveActivity={handleActivityChecked} allActivity={allActivity} />
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                </>
                                                                // currentActivity.map((r) => {
                                                                //     return (
                                                                //         <FormControlLabel
                                                                //             id="U_CU_remainingAccountCount"
                                                                //             control={<Checkbox size="small" />}
                                                                //             className={classes.checkboxStyle1}
                                                                //             value={r?.ActivityMapId}
                                                                //             // checked={r?.isEnable}
                                                                //             disabled={currentTemplateList?.readOnly || !currentTemplateList || isReadOnly}
                                                                //             onChange={(e, selected) => { handleActivityChecked(e, selected); }}
                                                                //             label={<span className={classes.inputFontSize1}>
                                                                //                 {/* {r.activityName} */} Email
                                                                //             </span>}
                                                                //         />
                                                                //     )
                                                                // })
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </Scrollbars>
                                        </Grid>
                                    </Grid>

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button size="small" startIcon={<DoneAllIcon />} variant="contained" style={{ marginTop: '10px' }} disabled={isReadOnly}
                                            color="primary" className={classes.activitybtn} onClick={() => { selectedTemplateList?.length > 1 ? handleOpenDialogBox() : handleSaveActivity(); }}>
                                            {selectedTemplateList?.length > 1 || readOnlyAll === true || readOnlyAll === false || selectAll === true ? "Apply All" : "Apply"}
                                        </Button>
                                        {isSaved ?
                                            <div className={classes.loadingDiv}>
                                                <div className='loader2' style={{ marginTop: '14px' }} />
                                                <span className={classes.loadingText}>Saving...</span>
                                            </div>
                                            : null}
                                    </div>
                                </Box>
                            </div>
                        </Card>
                    </div>
                </DialogContent>
                <DialogActions >
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus className={classes.noButton} onClick={(_) => { handleCloseFullView(); }} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog >

            <Dialog TransitionComponent={Transition} open={confirmation}
                className={classes.dialogboxOverride}
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        ACTIVITIES CUSTOMIZATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogTitle className={classes.titleHeaderOverride}>
                        <img src={Caution} alt="Block_Email" className={classes.warningIcon} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure to override the activities for the all the selected Clients?
                    </Typography>
                </DialogContent>
                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained"
                        onClick={() => { handleSaveActivity(); setConfirmation(false); }}
                        className={classes.yesButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained"
                        onClick={() => { handleCloseDialogBox(); }} autoFocus
                        className={classes.noButton} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isValid}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Template permissions applied Successfully!
                </Alert>
            </Snackbar>

        </React.Fragment >
    )
}

export default CreateActivity
