import {
  Backdrop, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid,
  IconButton, InputLabel, Paper, Select, TableContainer, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
// Icon
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
// Image icon
import browseFile from '../../../images/Placement/browsefile.svg';
import noPreviewFile from '../../../images/Placement/NoFile.svg';
import previewPDF from '../../../images/Placement/pdfView.svg';
import PDFPreview from '../../../images/Placement/pdfpreview.svg';
// Models
import { BeatLoader, PuffLoader } from 'react-spinners';
import { baseImage } from '../../../constants/Constant';
import { IManageTemplateLogo } from '../../../models/Admin/ClientPlacements/IManageTemplateLogo';
import { ITemplateCreate } from '../../../models/Admin/ClientPlacements/ITemplateCreate';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { base64toBlob } from '../../../utils/base64toBlob';
import DocumentPDFViewer from './DocumentPreview/DocumentPDFViewer';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ClientPlacementOptionsCss';
import * as constant from '../../../constants/Constant';

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const EditTemplate: React.FC<{ templateRow: ITemplateCreate, onUpdate: () => void }> = (props) => {
  const theme = useTheme();
  let fileUpload = useRef(null);
  const { templateRow } = props;
  const { state } = useGlobalState();
  const [open, setOpen] = useState(false);
  const [removeWarning, setRemoveWarning] = useState(false);
  const [removePdfTemplate, setRemovePdfTemplate] = useState(false);
  const [removeImageTemplate, setRemoveImageTemplate] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [TempName, setTempName] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [disable, setDisable] = useState(false);
  const [nameVal, setNameVal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imgUrl, setImgUrl] = useState<any>();
  const [imageLoader, setImageLoader] = useState<boolean>(true);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [selectedPDFFile, setselectedPDFFile] = useState<File>();
  const [selectedLogoFile, setselectedLogoFile] = useState<File>();
  const [filePdfError, setfilePdfError] = useState("");
  const [fileLogoError, setfileLogoError] = useState("");
  const [isPdfRemoved, setIsPdfRemoved] = useState(false);
  const [isLogoRemoved, setIsLogoRemoved] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [manageTemplateLogo, setManageTemplateLogo] = useState<IManageTemplateLogo>();

  var newTemplate: ITemplateCreate = {
    placement_template_id: templateRow.placement_template_id,
    name: templateRow.name,
    account_no: templateRow.account_no,
    account_noRequired: templateRow.account_noRequired,
    first_name: templateRow.first_name,
    first_nameRequired: templateRow.first_nameRequired,
    last_name: templateRow.last_name,
    last_nameRequired: templateRow.last_nameRequired,
    full_name: templateRow.full_name,
    full_nameRequired: templateRow.full_nameRequired,
    address1: templateRow.address1,
    address1Required: templateRow.address1Required,
    address2: templateRow.address2,
    address2Required: templateRow.address2Required,
    city: templateRow.city,
    cityRequired: templateRow.cityRequired,
    state: templateRow.state,
    stateRequired: templateRow.stateRequired,
    zip: templateRow.zip,
    zipRequired: templateRow.zipRequired,
    phone_no: templateRow.phone_no,
    phone_noRequired: templateRow.phone_noRequired,
    total_balance: templateRow.total_balance,
    total_balanceRequired: templateRow.total_balanceRequired,
    delinquency_date: templateRow.delinquency_date,
    delinquency_dateRequired: templateRow.delinquency_dateRequired,
    secondary_address1: templateRow.secondary_address1,
    secondary_address1Required: templateRow.secondary_address1Required,
    secondary_address2: templateRow.secondary_address2,
    secondary_address2Required: templateRow.secondary_address2Required,
    secondary_city: templateRow.secondary_city,
    secondary_cityRequired: templateRow.secondary_cityRequired,
    secondary_state: templateRow.secondary_state,
    secondary_stateRequired: templateRow.secondary_stateRequired,
    secondary_zip: templateRow.secondary_zip,
    secondary_zipRequired: templateRow.secondary_zipRequired,
    secondary_phone_no: templateRow.secondary_phone_no,
    secondary_phone_noRequired: templateRow.secondary_phone_noRequired,
    email: templateRow.email,
    emailRequired: templateRow.emailRequired,
    drivers_license: templateRow.drivers_license,
    drivers_licenseRequired: templateRow.drivers_licenseRequired,
    tax_id: templateRow.tax_id,
    tax_idRequired: templateRow.tax_idRequired,
    birth_date: templateRow.birth_date,
    birth_dateRequired: templateRow.birth_dateRequired,
    past_due_balance: templateRow.past_due_balance,
    past_due_balanceRequired: templateRow.past_due_balanceRequired,
    current_balance: templateRow.current_balance,
    current_balanceRequired: templateRow.current_balanceRequired,
    equipment_charges: templateRow.equipment_charges,
    equipment_chargesRequired: templateRow.equipment_chargesRequired,
    contract_charges: templateRow.contract_charges,
    contract_chargesRequired: templateRow.contract_chargesRequired,
    fees: templateRow.fees,
    feesRequired: templateRow.feesRequired,
    last_payment_date: templateRow.last_payment_date,
    last_payment_dateRequired: templateRow.last_payment_dateRequired,
    guarantor_first_name: templateRow.guarantor_first_name,
    guarantor_first_nameRequired: templateRow.guarantor_first_nameRequired,
    guarantor_last_name: templateRow.guarantor_last_name,
    guarantor_last_nameRequired: templateRow.guarantor_last_nameRequired,
    guarantor_full_name: templateRow.guarantor_full_name,
    guarantor_full_nameRequired: templateRow.guarantor_full_nameRequired,
    comment: templateRow.comment,
    commentRequired: templateRow.commentRequired,
    account_type: templateRow.account_type,
    account_typeRequired: templateRow.account_typeRequired,
    other: templateRow.other,
    otherRequired: templateRow.otherRequired,
    contact_name: templateRow.contact_name,
    contact_nameRequired: templateRow.contact_nameRequired,
    service_date: templateRow.service_date,
    service_dateRequired: templateRow.service_dateRequired,
    location: templateRow.location,
    locationRequired: templateRow.locationRequired,
    codebtor_Full_Name: templateRow.codebtor_Full_Name,
    codebtor_Full_NameRequired: templateRow.codebtor_Full_NameRequired,
    codebtor_Street_Address1: templateRow.codebtor_Street_Address1,
    codebtor_Street_Address1Required: templateRow.codebtor_Street_Address1Required,
    codebtor_Street_Address2: templateRow.codebtor_Street_Address2,
    codebtor_Street_Address2Required: templateRow.codebtor_Street_Address2Required,
    codebtor_City: templateRow.codebtor_City,
    codebtor_CityRequired: templateRow.codebtor_CityRequired,
    codebtor_State: templateRow.codebtor_State,
    codebtor_StateRequired: templateRow.codebtor_StateRequired,
    codebtor_Zip: templateRow.codebtor_Zip,
    codebtor_ZipRequired: templateRow.codebtor_ZipRequired,
    codebtor_BirthDate: templateRow.codebtor_BirthDate,
    codebtor_BirthDateRequired: templateRow.codebtor_BirthDateRequired,
    codebtor_Tax_ID: templateRow.codebtor_Tax_ID,
    codebtor_Tax_IDRequired: templateRow.codebtor_Tax_IDRequired,
    coDebtor_First_Name: templateRow.coDebtor_First_Name,
    coDebtor_First_NameRequired: templateRow.coDebtor_First_NameRequired,
    coDebtor_Last_Name: templateRow.coDebtor_Last_Name,
    coDebtor_Last_NameRequired: templateRow.coDebtor_Last_NameRequired,
    coDebtor_Phone1: templateRow.coDebtor_Phone1,
    coDebtor_Phone1Required: templateRow.coDebtor_Phone1Required,
    coDebtor_Phone2: templateRow.coDebtor_Phone2,
    coDebtor_Phone2Required: templateRow.coDebtor_Phone2Required,
    itemization_Date: templateRow.itemization_Date,
    itemization_DateRequired: templateRow.itemization_DateRequired,
    balance_owed_on_itemization_date: templateRow.balance_owed_on_itemization_date,
    balance_owed_on_itemization_dateRequired: templateRow.balance_owed_on_itemization_dateRequired,
    fees_added_since_itemization_date: templateRow.fees_added_since_itemization_date,
    fees_added_since_itemization_dateRequired: templateRow.fees_added_since_itemization_dateRequired,
    interest_added_since_itemization_date: templateRow.interest_added_since_itemization_date,
    interest_added_since_itemization_dateRequired: templateRow.interest_added_since_itemization_dateRequired,
    payments_applied_since_itemization_date: templateRow.payments_applied_since_itemization_date,
    payments_applied_since_itemization_dateRequired: templateRow.payments_applied_since_itemization_dateRequired,
    credits_applied_since_itemization_date: templateRow.credits_applied_since_itemization_date,
    credits_applied_since_itemization_dateRequired: templateRow.credits_applied_since_itemization_dateRequired,
    payments_Credits_applied_since_itemization_date: templateRow.payments_Credits_applied_since_itemization_date,
    payments_Credits_applied_since_itemization_dateRequired: templateRow.payments_Credits_applied_since_itemization_dateRequired,
    delinquency_Itemization_Date: templateRow.delinquency_Itemization_Date,
    delinquency_Itemization_DateRequired: templateRow.delinquency_Itemization_DateRequired,
    placementTemplateId: templateRow.placementTemplateId,
    fileName_PDF: templateRow.fileName_PDF,
    fileName_Img: templateRow.fileName_Img,
    imageURI: templateRow.imageURI,
    pdfURI: templateRow.pdfURI,
    canDelete: templateRow.canDelete,
    created_date: templateRow.created_date,
    modified_date: templateRow.modified_date,
    placementDateFormat: templateRow?.placementDateFormat

  }

  let myString;
  let dropDownValue;
  let optionItems;

  const handleClickOpen = () => {
    setOpen(true);
    getManageTemplateDetails();
    setShowProgressBar(true);
  };

  const getManageTemplateDetails = () => {
    (async () => {
      await useFetch<IManageTemplateLogo>("File/GetMultipleFile?PlacementTemplateId=" + templateRow?.placement_template_id).then((r) => {
        setManageTemplateLogo(r?.data);
        getImgDisplay(r?.data[0]?.imageUri);
      }).finally(() => {
      });
    })()
  }

  const getImgDisplay = async (blobUri: string) => {
    let request = {
      "pdfUri": blobUri
    }
    await usePost<any>("File/PDFDocumentFileDownload", request).then((r) => {
      setImageLoader(false);
      let url = URL.createObjectURL(base64toBlob(baseImage + r?.data));
      setImgUrl(url);
    })
  };

  const handleSave = () => {
    setOpen(false);
    setShowProgressBar(true);
    (async () => {
      await usePost("ClientPlacement/ModifyTemplate", localState).then(async (ModifyTemplate) => {
        if (ModifyTemplate?.data) {
          const formData = new FormData();
          formData.append("PlacementTemplateId", templateRow?.placement_template_id.toString());
          formData.append("Userid", state.userAccessContext?.id.toString());
          formData.append("multiFiles", selectedPDFFile ? selectedPDFFile : manageTemplateLogo?.fileName_Pdf);
          formData.append("multiFiles", selectedLogoFile ? selectedLogoFile : manageTemplateLogo?.fileName_Img);
          formData.append("PdfPath", localState?.fileName_PDF + 'μ' + localState?.pdfURI);
          formData.append("ImagePath", localState?.fileName_Img + 'μ' + localState?.imageURI);
          formData.append("isPdfRemoved", isPdfRemoved?.toString());
          formData.append("isLogoRemoved", isLogoRemoved?.toString());
          await usePost("File/UploadMultipleTemplateFiles", formData).finally(() => {
            props.onUpdate();
          });
        }
      }).finally(() => {
        handleClose();
        props.onUpdate();
      });
    })()
  };

  const handlePDFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const fileContent = event.target.files[0];
    if (fileContent.type === "application/pdf") {
      setselectedPDFFile(fileContent);
      setfilePdfError("");
      if (localState.fileName_PDF) {
        setIsPdfRemoved(true);
      }
    } else {
      setfilePdfError("Please upload only PDF Format");
      setselectedPDFFile(event.target.files = null);
    }
  }

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileContent = event.target.files[0];
    if ((fileContent?.type === "image/png" || fileContent?.type === "image/jpg" || fileContent?.type === "image/jpeg")) {
      setselectedLogoFile(fileContent);
      setfileLogoError("");
      if (localState.fileName_Img) {
        setIsLogoRemoved(true);
      }
    } else {
      setfileLogoError("Please upload only Image Format");
      setselectedLogoFile(event.target.files = null);
    }
  }

  const handleDeletePdf = (e) => {
    setselectedPDFFile(e.target.files = null);
    setManageTemplateLogo(null);
    setlocalstate({ ...localState, fileName_PDF: null, pdfURI: null });
    setIsPdfRemoved(true);
  };

  const handleDeleteLogo = (e) => {
    setselectedLogoFile(e.target.files = null);
    setManageTemplateLogo(null);
    setlocalstate({ ...localState, fileName_Img: null, imageURI: null });
    setIsLogoRemoved(true);
  }

  useEffect(() => {
    if (selectedLogoFile) {
      setImageUrl(URL.createObjectURL(selectedLogoFile));
    }
  }, [selectedLogoFile]);

  const HandleTempNameVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setTempName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (TempName.length > 50) {
      setError("Max length is 50")
      setIsValid(true);
    } else {
      setError("")
      setIsValid(false);
    }
  }, [TempName])

  const handleRemove = (e) => {
    if (removePdfTemplate) {
      handleDeletePdf(e);
    }
    if (removeImageTemplate) {
      handleDeleteLogo(e);
    }
  }

  const handleRemoveTemplate = (name: string) => {
    setRemoveWarning(true);
    if (name === "pdf") {
      setRemovePdfTemplate(true);
      setMessageTitle("REMOVE PLACEMENT PDF")
      setMessage("Are you sure, you want to Remove placement instruction PDF? ");
    }
    if (name === "logo") {
      setRemoveImageTemplate(true);
      setMessageTitle("REMOVE AGENT LOGO")
      setMessage("Are you sure, you want to Remove agent logo? ");
    }
  }

  const [localState, setlocalstate] = useState(newTemplate);

  const handleClose = () => {
    setOpen(false);
    setTempName("");
    setShowProgressBar(false);
    setlocalstate(templateRow);
    setfilePdfError("");
    setfileLogoError("");
    setRemovePdfTemplate(false);
    setRemoveImageTemplate(false);
    setselectedPDFFile(null);
    setselectedLogoFile(null);
  };

  const handleClose1 = () => {
    setRemoveWarning(false);
  }

  const handleChange = (event) => { setlocalstate({ ...localState, [event.target.name]: event.target.value }); };

  const handleChecked = (event) => { setlocalstate({ ...localState, [event.target.name]: event.target.checked }); };

  useEffect(() => {
    if ((localState.itemization_Date === false && localState.delinquency_date === true)
      || (localState.itemization_Date === true && localState.delinquency_date === false)
      || (localState.delinquency_date === false && localState.delinquency_Itemization_Date === false && localState.itemization_Date === false)
      || (localState.delinquency_date === true && localState.delinquency_Itemization_Date === true && localState.itemization_Date === true)) {
      setDisable(true);
    }
    else {
      setDisable(false);
    }
  }, [localState.delinquency_Itemization_Date, localState.delinquency_date, localState.itemization_Date])

  useEffect(() => {
    if ((localState.first_name === false && localState.last_name === false && localState.full_name === false)
      || ((localState.first_name === true && localState.last_name === false && localState.full_name === false) || (localState.first_name === false && localState.last_name === true && localState.full_name === false))) {
      setNameVal(true);
    } else {
      setNameVal(false);
    }
  }, [localState.first_name, localState.last_name, localState.full_name]);

  return (
    <React.Fragment>
      <div>
        <Button id="CPO_Edit" size="small" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen}
          className={classes.editbutton}>Edit</Button>
      </div>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div>
        <Dialog maxWidth={'xl'} keepMounted fullWidth={true} classes={{ paper: classes.dialogePaper }}
          PaperProps={{ style: { borderRadius: 15, maxHeight: 'calc(100% - 25px)' } }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              EDIT TEMPLATE
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container spacing={0} >
              <Grid item xs={6} className={classes.gridPadding}>
                <TextField id="ET_Name" label="Enter the template name" value={localState.name} name="name"
                  variant="outlined" fullWidth margin="dense" onChange={(e) => { handleChange(e); HandleTempNameVal(e); }} helperText={error} error={!!error} className={classes.cntNameBox} />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <FormControl size="small" variant="outlined" className={classes.cntNameBox1}>
                  <InputLabel htmlFor="outlined-age-native-simple">Select Source Date Format</InputLabel>
                  <Select native
                    name="placementDateFormat"
                    label="Select Source Date Format"
                    inputProps={{
                      id: 'outlined-age-native-simple',
                    }}
                    value={localState?.placementDateFormat}
                    onChange={handleChange}>
                    {myString = constant?.DateFormat.join(",")}
                    {dropDownValue = (myString).split(",")}
                    {optionItems = dropDownValue?.map((result) => {
                      return (
                        <option value={result}>{result}</option>
                      )
                    })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <span className={classes.errorMessage} >
                  {((localState.delinquency_date === true && localState.itemization_Date === true && localState.delinquency_Itemization_Date === true) || (localState.delinquency_date === false && localState.itemization_Date === false && localState.delinquency_Itemization_Date === false)) ? <span>Select either [Delinquency Date / Itemization Date] or [Date of Delinquency] and [Itemization Date] &nbsp;</span>
                    : localState.delinquency_date === true && localState.itemization_Date === false ? <span>Please Select [Itemization Date] &nbsp;</span>
                      : localState.delinquency_date === false && localState.itemization_Date === true ? <span>Please Select [Delinquency Date]&nbsp;</span> : null
                  }
                  {((localState.first_name === false && localState.last_name === true && localState.full_name === false) || (localState.first_name === true && localState.last_name === false && localState.full_name === false)) ? <span>Please Select First Name and Last Name &nbsp;</span>
                    : (localState.first_name === false && localState.last_name === false && localState.full_name === false) ? <span>Please Select either First Name and Last Name or Full Name&nbsp;</span> : null
                  }
                </span>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={6} sm={3} className={classes.gridDisplay}>
                <Paper className={classes.logoboxviewStyle}>
                  <img alt="browseFile" src={browseFile} width="30px" height="30px" />
                  <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                    <Button size="small" className={classes.browsefile} component="label" color="primary"
                      variant="contained" startIcon={<OpenInBrowserIcon />}>
                      Browse PDF
                      <input
                        id="CT_SelectFile"
                        onChange={handlePDFChange}
                        name="file"
                        ref={fileUpload}
                        hidden type="file"
                        accept="application/pdf"
                      />
                    </Button>
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3} className={classes.gridDisplay}>
                <Paper className={classes.logoboxviewStyle}>
                  {filePdfError ?
                    <>
                      <img src={noPreviewFile} width="40px" height="45px" />
                      <Typography variant='subtitle1' className={classes.previewNoFile}>
                        {filePdfError}
                      </Typography>
                    </>
                    :
                    selectedPDFFile ?
                      <>
                        <img src={previewPDF} width="40px" height="45px" />
                        <Chip label={selectedPDFFile.name} size="small" onDelete={(e) => { handleDeletePdf(e); }} className={classes.previewfile} />
                      </>
                      :
                      localState?.fileName_PDF?.length ?
                        <>
                          <img src={PDFPreview} height="50px" />
                          <div className={classes.gridDisplay}>
                            <DocumentPDFViewer row={templateRow} enable={localState?.fileName_PDF?.split('.')?.pop() === 'pdf' ? true : localState?.fileName_PDF?.split('.')?.pop() === 'PDF' ? true : false} />
                            <IconButton aria-label="delete" onClick={(e) => { handleRemoveTemplate("pdf"); }} className={classes.removeIcon}>
                              <CancelIcon style={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                        </>
                        :
                        <>
                          <img src={noPreviewFile} width="40px" height="45px" />
                          <Typography variant='subtitle1' className={classes.previewNoFile}>
                            Upload your Placement Instructions
                          </Typography>
                        </>
                  }
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3} className={classes.gridDisplay}>
                <Paper className={classes.logoboxviewStyle}>
                  <img alt="browseFile" src={browseFile} width="30px" height="30px" />
                  <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                    <Button size="small" className={classes.browsefile} component="label" color="primary"
                      variant="contained" startIcon={<OpenInBrowserIcon />}>
                      Browse Logo
                      <input
                        id="CT_SelectFile"
                        onChange={handleLogoChange}
                        name="file"
                        ref={fileUpload}
                        hidden type="file"
                        accept="image/jpeg,image/jpg,image/png"
                      />
                    </Button>
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3} className={classes.gridDisplay}>
                <Paper className={classes.logoboxviewStyle} >
                  {fileLogoError ?
                    <>
                      <img src={noPreviewFile} width="40px" height="45px" />
                      <Typography variant='subtitle1' className={classes.previewNoFile}>
                        {fileLogoError}
                      </Typography>
                    </>
                    :
                    selectedLogoFile ?
                      <>
                        {imageUrl ?
                          <>
                            <img src={imageUrl} alt={selectedLogoFile.name} height="45px" />
                            <Chip label={selectedLogoFile.name} size="small" onDelete={handleDeleteLogo} className={classes.previewfile} />
                          </>
                          : null
                        }
                      </>
                      :
                      localState?.fileName_Img?.length && imageLoader ?
                        <BeatLoader color="#090aeb" />
                        : localState?.fileName_Img?.length ?
                          <>
                            <img src={imgUrl} height="45px" alt="Display logo image" />
                            <IconButton aria-label="delete" onClick={(e) => { handleRemoveTemplate("logo"); }} style={{ padding: '2px' }}>
                              <CancelIcon style={{ fontSize: 20 }} />
                            </IconButton>
                          </>
                          :
                          <>
                            <img src={noPreviewFile} width="40px" height="40px" />
                            <Typography variant='subtitle1' className={classes.previewNoFile}>
                              Upload your Agency Logo
                            </Typography>
                          </>
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.gridDisplay}>
                <Box display="flex" width="32%">
                  <TableContainer component={Paper} className={`${classes.userboxviewStyle}`}>
                    <Box width="80%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox}`}>Permitted</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_Account_No"
                          className={`${localState.account_no ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.account_no = true} disabled={true} name="account_no" />}
                          labelPlacement="start"
                          label="AccountNumber"
                        />
                        <FormControlLabel
                          id="CNT_firstname"
                          className={`${localState.first_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.first_name ? localState.first_nameRequired = false : localState.first_name} name="first_name" />}
                          labelPlacement="start"
                          label="First Name"
                        />
                        <FormControlLabel
                          id="CNT_lastname"
                          className={`${localState.last_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.last_name ? localState.last_nameRequired = false : localState.last_name} name="last_name" />}
                          labelPlacement="start"
                          label="Last Name"
                        />
                        <FormControlLabel
                          id="CNT_fullname"
                          className={`${localState.full_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.full_name ? localState.full_nameRequired = false : localState.full_name} name="full_name" />}
                          labelPlacement="start"
                          label="Full Name/Business Name"
                        />
                        <FormControlLabel
                          id="CNT_streetAddress1"
                          className={`${localState.address1 ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.address1 = true} disabled={true} name="address1" />}
                          labelPlacement="start"
                          label="Street Address 1"
                        />
                        <FormControlLabel
                          id="CNT_streetAddress2"
                          className={`${localState.address2 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.address2 ? localState.address2Required = false : localState.address2} name="address2" />}
                          labelPlacement="start"
                          label="Street Address 2"
                        />
                        <FormControlLabel
                          id="CNT_city"
                          className={`${localState.city ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.city = true} disabled={true} name="city" />}
                          labelPlacement="start"
                          label="City"
                        />
                        <FormControlLabel
                          id="CNT_state"
                          className={`${localState.state ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.state = true} disabled={true} name="state" />}
                          labelPlacement="start"
                          label="State"
                        />
                        <FormControlLabel
                          id="CNT_zip"
                          className={`${localState.zip ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.zip = true} disabled={true} name="zip" />}
                          labelPlacement="start"
                          label="Zip"
                        />
                        <FormControlLabel
                          id="CNT_phoneNumber"
                          className={`${localState.phone_no ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.phone_no ? localState.phone_noRequired = false : localState.phone_no} name="phone_no" />}
                          labelPlacement="start"
                          label="Phone Number"
                        />
                        <FormControlLabel
                          id="CNT_totalBalance"
                          className={`${localState.total_balance ? classes.permittedFiled : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.total_balance = true} disabled={true} name="total_balance" />}
                          labelPlacement="start"
                          label="Total Balance"
                        />
                        <FormControlLabel
                          id="CNT_dateofDelinquency"
                          className={`${localState.delinquency_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.delinquency_date ? localState.delinquency_dateRequired = false : localState.delinquency_date} name="delinquency_date" />}
                          labelPlacement="start"
                          label="Date of Delinquency"
                        />
                        <FormControlLabel
                          id="CNT_streetAddress1Secondary"
                          className={`${localState.secondary_address1 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_address1 ? localState.secondary_address1Required = false : localState.secondary_address1} name="secondary_address1" />}
                          labelPlacement="start"
                          label="Street Address 1 (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_streetAddress2secondary"
                          className={`${localState.secondary_address2 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_address2 ? localState.secondary_address2Required = false : localState.secondary_address2} name="secondary_address2" />}
                          labelPlacement="start"
                          label="Street Address 2 (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_citysecondary"
                          className={`${localState.secondary_city ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_city ? localState.secondary_cityRequired = false : localState.secondary_city} name="secondary_city" />}
                          labelPlacement="start"
                          label="City (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_statesecondary"
                          className={`${localState.secondary_state ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_state ? localState.secondary_stateRequired = false : localState.secondary_state} name="secondary_state" />}
                          labelPlacement="start"
                          label="State (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_zipsecondary"
                          className={`${localState.secondary_zip ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_zip ? localState.secondary_zipRequired = false : localState.secondary_zip} name="secondary_zip" />}
                          labelPlacement="start"
                          label="Zip (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_phoneNumbersecondary"
                          className={`${localState.secondary_phone_no ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.secondary_phone_no ? localState.secondary_phone_noRequired = false : localState.secondary_phone_no} name="secondary_phone_no" />}
                          labelPlacement="start"
                          label="Phone Number (Secondary)"
                        />
                        <FormControlLabel
                          id="CNT_emailAddress"
                          className={`${localState.email ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.email ? localState.emailRequired = false : localState.email} name="email" />}
                          labelPlacement="start"
                          label="Email Address"
                        />
                      </FormGroup>
                    </Box>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="20%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox1}`}>Required</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_account_noRequired"
                          className={`${localState.account_noRequired ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.account_noRequired = true} name="account_noRequired" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_firstnameRequired"
                          className={`${localState.first_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.first_nameRequired} name="first_nameRequired" disabled={!localState.first_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_lastnameRequired"
                          className={`${localState.last_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.last_nameRequired} name="last_nameRequired" disabled={!localState.last_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_fullnameRequired"
                          className={`${localState.full_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.full_nameRequired} name="full_nameRequired" disabled={!localState.full_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_streetAddress1Required"
                          className={`${localState.address1Required ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.address1Required = true} name="address1Required" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_streetAddress2Required"
                          className={`${localState.address2Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.address2Required} name="address2Required" disabled={!localState.address2} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_cityRequired"
                          className={`${localState.cityRequired ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.cityRequired = true} name="cityRequired" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_stateRequired"
                          className={`${localState.stateRequired ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.stateRequired = true} name="stateRequired" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_zipRequired"
                          className={`${localState.zipRequired ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.zipRequired = true} name="zipRequired" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_phoneNumberRequired"
                          className={`${localState.phone_noRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.phone_noRequired} name="phone_noRequired" disabled={!localState.phone_no} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_totalBalanceRequired"
                          className={`${localState.total_balanceRequired ? classes.requiredFiled : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.total_balanceRequired = true} name="total_balanceRequired" disabled={true} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_dateofDelinquencyRequired"
                          className={`${localState.delinquency_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.delinquency_dateRequired} name="delinquency_dateRequired" disabled={!localState.delinquency_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_streetAddress1secondaryRequired"
                          className={`${localState.secondary_address1Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_address1Required} name="secondary_address1Required" disabled={!localState.secondary_address1} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_streetAddress2secondaryRequired"
                          className={`${localState.secondary_address2Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_address2Required} name="secondary_address2Required" disabled={!localState.secondary_address2} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_citysecondaryRequired"
                          className={`${localState.secondary_cityRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_cityRequired} name="secondary_cityRequired" disabled={!localState.secondary_city} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_statesecondaryRequired"
                          className={`${localState.secondary_stateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_stateRequired} name="secondary_stateRequired" disabled={!localState.secondary_state} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_zipsecondaryRequired"
                          className={`${localState.secondary_zipRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_zipRequired} name="secondary_zipRequired" disabled={!localState.secondary_zip} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_phoneNumbersecondaryRequired"
                          className={`${localState.secondary_phone_noRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.secondary_phone_noRequired} name="secondary_phone_noRequired" disabled={!localState.secondary_phone_no} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_emailAddressRequired"
                          className={`${localState.emailRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.emailRequired} name="emailRequired" disabled={!localState.email} />}
                          labelPlacement="start"
                          label=""
                        />
                      </FormGroup>
                    </Box>
                  </TableContainer>
                </Box>

                <Box display="flex" width="32%">
                  <TableContainer component={Paper} className={`${classes.userboxviewStyle}`}>
                    <Box width="80%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox}`}>Permitted</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_driverslicense"
                          className={`${localState.drivers_license ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.drivers_license ? localState.drivers_licenseRequired = false : localState.drivers_license} name="drivers_license" />}
                          labelPlacement="start"
                          label="Drivers License"
                        />
                        <FormControlLabel
                          id="CNT_ssTaxid"
                          className={`${localState.tax_id ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.tax_id ? localState.tax_idRequired = false : localState.tax_id} name="tax_id" />}
                          labelPlacement="start"
                          label="SS# / Tax ID"
                        />
                        <FormControlLabel
                          id="CNT_birthdate"
                          className={`${localState.birth_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.birth_date ? localState.birth_dateRequired = false : localState.birth_date} name="birth_date" />}
                          labelPlacement="start"
                          label="BirthDate"
                        />
                        <FormControlLabel
                          id="balancePastDuePortion"
                          className={`${localState.past_due_balance ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.past_due_balance ? localState.past_due_balanceRequired = false : localState.past_due_balance} name="past_due_balance" />}
                          labelPlacement="start"
                          label="Balance (Past Due Portion)"
                        />
                        <FormControlLabel
                          id="CNT_balanceCurrentPortion"
                          className={`${localState.current_balance ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.current_balance ? localState.current_balanceRequired = false : localState.current_balance} name="current_balance" />}
                          labelPlacement="start"
                          label="Balance (Current Portion)"
                        />
                        <FormControlLabel
                          id="CNT_equipmentcharges"
                          className={`${localState.equipment_charges ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.equipment_charges ? localState.equipment_chargesRequired = false : localState.equipment_charges} name="equipment_charges" />}
                          labelPlacement="start"
                          label="Equipment Charges"
                        />
                        <FormControlLabel
                          id="CNT_contractcharges"
                          className={`${localState.contract_charges ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.contract_charges ? localState.contract_chargesRequired = false : localState.contract_charges} name="contract_charges" />}
                          labelPlacement="start"
                          label="Contract Charges"
                        />
                        <FormControlLabel
                          id="CNT_lastpaymentdate"
                          className={`${localState.last_payment_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.last_payment_date ? localState.last_payment_dateRequired = false : localState.last_payment_date} name="last_payment_date" />}
                          labelPlacement="start"
                          label="Last Payment Date"
                        />
                        <FormControlLabel
                          id="CNT_guarantorfirstname"
                          className={`${localState.guarantor_first_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.guarantor_first_name ? localState.guarantor_first_nameRequired = false : localState.guarantor_first_name} name="guarantor_first_name" />}
                          labelPlacement="start"
                          label="Guarantor First Name"
                        />
                        <FormControlLabel
                          id="CNT_guarantorlastname"
                          className={`${localState.guarantor_last_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.guarantor_last_name ? localState.guarantor_last_nameRequired = false : localState.guarantor_last_name} name="guarantor_last_name" />}
                          labelPlacement="start"
                          label="Guarantor Last Name"
                        />
                        <FormControlLabel
                          id="CNT_guarantorfullname"
                          className={`${localState.guarantor_full_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.guarantor_full_name ? localState.guarantor_full_nameRequired = false : localState.guarantor_full_name} name="guarantor_full_name" />}
                          labelPlacement="start"
                          label="Guarantor Full Name"
                        />
                        <FormControlLabel
                          id="CNT_comment"
                          className={`${localState.comment ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.comment ? localState.commentRequired = false : localState.comment} name="comment" />}
                          labelPlacement="start"
                          label="Comment"
                        />
                        <FormControlLabel
                          id="CNT_accounttype"
                          className={`${localState.account_type ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.account_type ? localState.account_typeRequired = false : localState.account_type} name="account_type" />}
                          labelPlacement="start"
                          label="Account Type"
                        />
                        <FormControlLabel
                          id="CNT_other"
                          className={`${localState.other ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.other ? localState.otherRequired = false : localState.other} name="other" />}
                          labelPlacement="start"
                          label="Other"
                        />
                        <FormControlLabel
                          id="CNT_contact_name"
                          className={`${localState.contact_name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.contact_name ? localState.contact_nameRequired = false : localState.contact_name} name="contact_name" />}
                          labelPlacement="start"
                          label="Contact Name"
                        />
                        <FormControlLabel
                          id="CNT_service_date"
                          className={`${localState.service_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.service_date ? localState.service_dateRequired = false : localState.service_date} name="service_date" />}
                          labelPlacement="start"
                          label="Service Date"
                        />
                        <FormControlLabel
                          id="CNT_location"
                          className={`${localState.location ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.location ? localState.locationRequired = false : localState.location} name="location" />}
                          labelPlacement="start"
                          label="Location"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Full_Name"
                          className={`${localState.codebtor_Full_Name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_Full_Name ? localState.codebtor_Full_NameRequired = false : localState.codebtor_Full_Name} name="codebtor_Full_Name" />}
                          labelPlacement="start"
                          label="Codebtor Full Name"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Street_Address1"
                          className={`${localState.codebtor_Street_Address1 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_Street_Address1 ? localState.codebtor_Street_Address1Required = false : localState.codebtor_Street_Address1} name="codebtor_Street_Address1" />}
                          labelPlacement="start"
                          label="Codebtor Street Address 1"
                        />
                      </FormGroup>
                    </Box>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="20%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox1}`}>Required</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_driverslicenseRequired"
                          className={`${localState.drivers_licenseRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.drivers_licenseRequired} name="drivers_licenseRequired" disabled={!localState.drivers_license} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_ssTaxidRequired"
                          className={`${localState.tax_idRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.tax_idRequired} name="tax_idRequired" disabled={!localState.tax_id} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_birthdateRequired"
                          className={`${localState.birth_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.birth_dateRequired} name="birth_dateRequired" disabled={!localState.birth_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_balancePastDuePortionRequired"
                          className={`${localState.past_due_balanceRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.past_due_balanceRequired} name="past_due_balanceRequired" disabled={!localState.past_due_balance} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_balanceCurrentPortionRequired"
                          className={`${localState.current_balanceRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.current_balanceRequired} name="current_balanceRequired" disabled={!localState.current_balance} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_equipmentchargesRequired"
                          className={`${localState.equipment_chargesRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.equipment_chargesRequired} name="equipment_chargesRequired" disabled={!localState.equipment_charges} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_contractchargesRequired"
                          className={`${localState.contract_chargesRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.contract_chargesRequired} name="contract_chargesRequired" disabled={!localState.contract_charges} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_lastpaymentdateRequired"
                          className={`${localState.feesRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.last_payment_dateRequired} name="last_payment_dateRequired" disabled={!localState.last_payment_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_guarantorfirstnameRequired"
                          className={`${localState.guarantor_first_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.guarantor_first_nameRequired} name="guarantor_first_nameRequired" disabled={!localState.guarantor_first_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_guarantorlastnameRequired"
                          className={`${localState.guarantor_last_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.guarantor_last_nameRequired} name="guarantor_last_nameRequired" disabled={!localState.guarantor_last_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_guarantorfullnameRequired"
                          className={`${localState.guarantor_full_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.guarantor_full_nameRequired} name="guarantor_full_nameRequired" disabled={!localState.guarantor_full_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_commentRequired"
                          className={`${localState.commentRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.commentRequired} name="commentRequired" disabled={!localState.comment} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_accounttypeRequired"
                          className={`${localState.account_typeRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.account_typeRequired} name="account_typeRequired" disabled={!localState.account_type} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_otherRequired"
                          className={`${localState.otherRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.otherRequired} name="otherRequired" disabled={!localState.other} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_contact_nameRequired"
                          className={`${localState.contact_nameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.contact_nameRequired} name="contact_nameRequired" disabled={!localState.contact_name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_service_dateRequired"
                          className={`${localState.service_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.service_dateRequired} name="service_dateRequired" disabled={!localState.service_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_locationRequired"
                          className={`${localState.locationRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.locationRequired} name="locationRequired" disabled={!localState.location} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Full_NameRequired"
                          className={`${localState.codebtor_Full_NameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_Full_NameRequired} name="codebtor_Full_NameRequired" disabled={!localState.codebtor_Full_Name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Street_Address1Required"
                          className={`${localState.codebtor_Street_Address1Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_Street_Address1Required} name="codebtor_Street_Address1Required" disabled={!localState.codebtor_Street_Address1} />}
                          labelPlacement="start"
                          label=""
                        />
                      </FormGroup>
                    </Box>
                  </TableContainer>
                </Box>

                <Box display="flex" width="36%">
                  <TableContainer component={Paper} className={`${classes.userboxviewStyle1}`}>
                    <Box width="85%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox}`}>Permitted</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_codebtor_Street_Address2"
                          className={`${localState.codebtor_Street_Address2 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_Street_Address2 ? localState.codebtor_Street_Address2Required = false : localState.codebtor_Street_Address2} name="codebtor_Street_Address2" />}
                          labelPlacement="start"
                          label="Codebtor Street Address 2"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_City"
                          className={`${localState.codebtor_City ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_City ? localState.codebtor_CityRequired = false : localState.codebtor_City} name="codebtor_City" />}
                          labelPlacement="start"
                          label="Codebtor City"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_State"
                          className={`${localState.codebtor_State ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_State ? localState.codebtor_StateRequired = false : localState.codebtor_State} name="codebtor_State" />}
                          labelPlacement="start"
                          label="Codebtor State"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Zip"
                          className={`${localState.codebtor_Zip ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_Zip ? localState.codebtor_ZipRequired = false : localState.codebtor_Zip} name="codebtor_Zip" />}
                          labelPlacement="start"
                          label="Codebtor Zip"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_BirthDate"
                          className={`${localState.codebtor_BirthDate ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_BirthDate ? localState.codebtor_BirthDateRequired = false : localState.codebtor_BirthDate} name="codebtor_BirthDate" />}
                          labelPlacement="start"
                          label="Codebtor BirthDate"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Tax_ID"
                          className={`${localState.codebtor_Tax_ID ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.codebtor_Tax_ID ? localState.codebtor_Tax_IDRequired = false : localState.codebtor_Tax_ID} name="codebtor_Tax_ID" />}
                          labelPlacement="start"
                          label="Codebtor SS# / Tax ID"
                        />
                        <FormControlLabel
                          id="CNT_codebtor_First_Name"
                          className={`${localState.coDebtor_First_Name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.coDebtor_First_Name ? localState.coDebtor_First_NameRequired = false : localState.coDebtor_First_Name} name="coDebtor_First_Name" />}
                          labelPlacement="start"
                          label="CoDebtor First Name"
                        />
                        <FormControlLabel
                          id="CNT_coDebtor_Last_Name"
                          className={`${localState.coDebtor_Last_Name ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.coDebtor_Last_Name ? localState.coDebtor_Last_NameRequired = false : localState.coDebtor_Last_Name} name="coDebtor_Last_Name" />}
                          labelPlacement="start"
                          label="CoDebtor Last Name"
                        />
                        <FormControlLabel
                          id="CNT_coDebtor_Phone1"
                          className={`${localState.coDebtor_Phone1 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.coDebtor_Phone1 ? localState.coDebtor_Phone1Required = false : localState.coDebtor_Phone1} name="coDebtor_Phone1" />}
                          labelPlacement="start"
                          label="CoDebtor Phone1"
                        />
                        <FormControlLabel
                          id="CNT_coDebtor_Phone2"
                          className={`${localState.coDebtor_Phone2 ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.coDebtor_Phone2 ? localState.coDebtor_Phone2Required = false : localState.coDebtor_Phone2} name="coDebtor_Phone2" />}
                          labelPlacement="start"
                          label="CoDebtor Phone2"
                        />
                        <FormControlLabel
                          id="CNT_itemization_Date"
                          className={`${localState.itemization_Date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.itemization_Date ? localState.itemization_DateRequired = false : localState.itemization_Date} name="itemization_Date" />}
                          labelPlacement="start"
                          label="Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_balance_owed_on_itemization"
                          className={`${localState.balance_owed_on_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.balance_owed_on_itemization_date ? localState.balance_owed_on_itemization_dateRequired = false : localState.balance_owed_on_itemization_date} name="balance_owed_on_itemization_date" />}
                          labelPlacement="start"
                          label="Balance Owed On Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_fees_added_since_itemization"
                          className={`${localState.fees_added_since_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.fees_added_since_itemization_date ? localState.fees_added_since_itemization_dateRequired = false : localState.fees_added_since_itemization_date} name="fees_added_since_itemization_date" />}
                          labelPlacement="start"
                          label="Fees Added Since Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_interest_added_since_itemization"
                          className={`${localState.interest_added_since_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.interest_added_since_itemization_date ? localState.interest_added_since_itemization_dateRequired = false : localState.interest_added_since_itemization_date} name="interest_added_since_itemization_date" />}
                          labelPlacement="start"
                          label="Interest Added Since Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_payments_Applied_Since_Itemization_Date"
                          className={`${localState.payments_applied_since_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.payments_applied_since_itemization_date ? localState.payments_applied_since_itemization_dateRequired = false : localState.payments_applied_since_itemization_date} name="payments_applied_since_itemization_date" />}
                          labelPlacement="start"
                          label="Payments Applied Since Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_credits_Applied_Since_Itemization_Date"
                          className={`${localState.credits_applied_since_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.credits_applied_since_itemization_date ? localState.credits_applied_since_itemization_dateRequired = false : localState.credits_applied_since_itemization_date} name="credits_applied_since_itemization_date" />}
                          labelPlacement="start"
                          label="Credits Applied Since Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_delinquency_Itemization_Date"
                          className={`${localState.delinquency_Itemization_Date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.delinquency_Itemization_Date ? localState.delinquency_Itemization_DateRequired = false : localState.delinquency_Itemization_Date} name="delinquency_Itemization_Date" />}
                          labelPlacement="start"
                          label="Delinquency Date / Itemization Date"
                        />
                        <FormControlLabel
                          id="CNT_payments_Credits_applied_since_itemization_date"
                          className={`${localState.payments_Credits_applied_since_itemization_date ? classes.permittedChecked : classes.permittedFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={!localState.payments_Credits_applied_since_itemization_date ? localState.payments_Credits_applied_since_itemization_dateRequired = false : localState.payments_Credits_applied_since_itemization_date} name="payments_Credits_applied_since_itemization_date" />}
                          labelPlacement="start"
                          label="Payments Credits Applied Since Itemization Date"
                        />
                      </FormGroup>
                    </Box>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="15%">
                      <Typography variant='h6' gutterBottom className={`${classes.root} ${classes.userStyleTextBox1}`}>Required</Typography>
                      <FormGroup>
                        <FormControlLabel
                          id="CNT_codebtor_Street_Address2Required"
                          className={`${localState.codebtor_Street_Address2Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_Street_Address2Required} name="codebtor_Street_Address2Required" disabled={!localState.codebtor_Street_Address2} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_CityRequired"
                          className={`${localState.codebtor_CityRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_CityRequired} name="codebtor_CityRequired" disabled={!localState.codebtor_City} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_StateRequired"
                          className={`${localState.codebtor_StateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_StateRequired} name="codebtor_StateRequired" disabled={!localState.codebtor_State} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_ZipRequired"
                          className={`${localState.codebtor_ZipRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_ZipRequired} name="codebtor_ZipRequired" disabled={!localState.codebtor_Zip} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_BirthDateRequired"
                          className={`${localState.codebtor_BirthDateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_BirthDateRequired} name="codebtor_BirthDateRequired" disabled={!localState.codebtor_BirthDate} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Tax_IDRequired"
                          className={`${localState.codebtor_Tax_IDRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.codebtor_Tax_IDRequired} name="codebtor_Tax_IDRequired" disabled={!localState.codebtor_Tax_ID} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_First_NameRequired"
                          className={`${localState.coDebtor_First_NameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.coDebtor_First_NameRequired} name="coDebtor_First_NameRequired" disabled={!localState.coDebtor_First_Name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Last_NameRequired"
                          className={`${localState.coDebtor_Last_NameRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.coDebtor_Last_NameRequired} name="coDebtor_Last_NameRequired" disabled={!localState.coDebtor_Last_Name} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Phone1Required"
                          className={`${localState.coDebtor_Phone1Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.coDebtor_Phone1Required} name="coDebtor_Phone1Required" disabled={!localState.coDebtor_Phone1} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_codebtor_Phone2Required"
                          className={`${localState.coDebtor_Phone2Required ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.coDebtor_Phone2Required} name="coDebtor_Phone2Required" disabled={!localState.coDebtor_Phone2} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_itemization_DateRequired"
                          className={`${localState.itemization_DateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.itemization_DateRequired} name="itemization_DateRequired" disabled={!localState.itemization_Date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_balance_owed_on_itemizationRequired"
                          className={`${localState.balance_owed_on_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.balance_owed_on_itemization_dateRequired} name="balance_owed_on_itemization_dateRequired" disabled={!localState.balance_owed_on_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_fees_added_since_itemizationRequired"
                          className={`${localState.fees_added_since_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.fees_added_since_itemization_dateRequired} name="fees_added_since_itemization_dateRequired" disabled={!localState.fees_added_since_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_interest_added_since_itemizationRequired"
                          className={`${localState.interest_added_since_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.interest_added_since_itemization_dateRequired} name="interest_added_since_itemization_dateRequired" disabled={!localState.interest_added_since_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_payments_Applied_Since_Itemization_DateRequired"
                          className={`${localState.payments_applied_since_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.payments_applied_since_itemization_dateRequired} name="payments_applied_since_itemization_dateRequired" disabled={!localState.payments_applied_since_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_credits_Applied_Since_Itemization_DateRequired"
                          className={`${localState.credits_applied_since_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.credits_applied_since_itemization_dateRequired} name="credits_applied_since_itemization_dateRequired" disabled={!localState.credits_applied_since_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_delinquency_Itemization_DateRequired"
                          className={`${localState.delinquency_Itemization_DateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.delinquency_Itemization_DateRequired} name="delinquency_Itemization_DateRequired" disabled={!localState.delinquency_Itemization_Date} />}
                          labelPlacement="start"
                          label=""
                        />
                        <FormControlLabel
                          id="CNT_payments_Credits_applied_since_itemization_dateRequired"
                          className={`${localState.payments_Credits_applied_since_itemization_dateRequired ? classes.requiredChecked : classes.requiredFiled}`}
                          control={<Checkbox size="small" onChange={handleChecked} checked={localState.payments_Credits_applied_since_itemization_dateRequired} name="payments_Credits_applied_since_itemization_dateRequired" disabled={!localState.payments_Credits_applied_since_itemization_date} />}
                          labelPlacement="start"
                          label=""
                        />
                      </FormGroup>
                    </Box>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="ET_Update_btn" size="small" startIcon={<DoneAllIcon />} className={classes.updatebutton} variant="contained" autoFocus onClick={() => { setShowProgressBar(true); handleSave(); }} disabled={isValid || disable || nameVal} color="primary">
              Update Template
            </Button>
            <Button id="U_CU_Close_btn" startIcon={<CancelIcon />} variant="contained" onClick={handleClose} className={classes.canceltemplate}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          open={removeWarning}
          maxWidth={"sm"}
          onClose={(_) => { handleClose1(); }}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              {messageTitle}
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <div className={classes.messageDisplay}>
              <img src={noPreviewFile} width="60px" height="60px" />
              <Typography variant="h6" className={classes.titleMessage} gutterBottom>
                {message}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions >
            <Button id="CPO_OK_btn" size="small" onClick={(e) => { handleRemove(e); setRemoveWarning(false); }} color="primary" startIcon={<CheckCircleIcon />} className={classes.yesButton} autoFocus>
              Yes
            </Button>
            <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} startIcon={<CancelIcon />} autoFocus onClick={() => { setRemoveWarning(false); }} variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment >
  );
}

export default EditTemplate
