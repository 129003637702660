import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadCrumsStyle: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
            padding:'5px'
        },
        Activetab: {
            padding: '0px',
        },
        subActive: {
            borderBottom: '2px solid blue',
            padding: '0px',
            borderRadius: '0px'
        },
        subHidden: {
            padding: '0px',
            borderRadius: '0px'
        },
        FTPActive: {
            borderBottom: '2px solid blue',
            padding: '0px',
            borderRadius: '0px'
        },
        FTPHidden: {
            padding: '0px',
            borderRadius: '0px'
        },
        folderColorStyle: {
            color: "#FFC107"
        },
        buttonStyle: {
            marginLeft: '7px',
            marginTop: '3px',
            fontSize: 12,
        }
    })
);

const BreadCrumbs: React.FC<{ MainFolder: string, subFolder: string, FTPFolder: any }> = (props) => {
    const classes = useStyles();
    return (
        <div role="presentation" className={classes.breadCrumsStyle} >
            <Breadcrumbs aria-label="breadcrumb" separator={`${props?.subFolder?.length > 0 ? "/" : ""}`} >
                <Button className={classes.Activetab}>
                    {props?.MainFolder?.length > 0 ? <FolderOpenIcon fontSize="small" className={classes.folderColorStyle} /> : null}
                    <span className={classes.buttonStyle}>
                        {props?.MainFolder?.length > 0 ? props?.MainFolder?.toUpperCase() : null}
                    </span>
                </Button>
                {
                    props?.subFolder?.length > 0 ?
                        <Button className={(props?.subFolder?.length > 0 && (props?.FTPFolder?.length === undefined)) ? classes.subActive : classes.subHidden}>
                            {props?.subFolder?.length > 0 ? <FolderOpenIcon fontSize="small" className={classes.folderColorStyle} /> : null}
                            <span className={classes.buttonStyle}>
                                {props?.subFolder?.length > 0 ? props?.subFolder?.toUpperCase() : null}
                            </span>
                        </Button>
                        : null
                }
                {
                    props?.subFolder?.length > 0 ?
                        <Button className={(props?.FTPFolder?.length > 0) ? classes.FTPActive : classes.FTPHidden}>
                            {props?.FTPFolder?.length > 0 ? <FolderOpenIcon fontSize="small" className={classes.folderColorStyle} /> : null}
                            <span className={classes.buttonStyle}>
                                {props?.FTPFolder?.length > 0 ? props?.FTPFolder?.toUpperCase() : null}
                            </span>
                        </Button>
                        : null
                }
            </Breadcrumbs >
        </div >
    )
}

export default BreadCrumbs