import { useMsal } from '@azure/msal-react';
import { Box, Grid, IconButton, InputAdornment, TablePagination, TableSortLabel, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import { Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import CreatePerformance from '../../components/PerformanceReport/CreatePerformance';
import PerformanceReport from '../../components/PerformanceReport/PerformanceReport';
import { IPerformanceReportsResult } from '../../models/Reports/ReportsList';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { useStyles } from '../common/ReportListCss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        fontSize: 12,
        padding: '2px 5px',
        textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "REPORT NAME", sortable: true },
        { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
        { id: "action", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
        { id: "share", numeric: false, disablePadding: false, label: "SHARE", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false },
    ];

    return (
        <TableHead>
            <StyledTableRow>
                {headCells.map(headCell => (
                    <StyledTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            id="AT_TableSortlable"
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
}

const PerformanceReportView = () => {
    const classes = useStyles();
    const { accounts } = useMsal();
    const { state } = useGlobalState();
    const [order, setOrder] = useState("asc");
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [orderBy, setOrderBy] = useState("date_created");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");
    const [ProgressBar, setshowProgressBar] = useState<boolean>(false);
    const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);
    const [performanceReportResults, setPerformanceReportResults] = useState<IPerformanceReportsResult[]>([]);

    const handleChangePage = (event: unknown, page: number) => {
        setPageNumber(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };

    useEffect(() => {
        getReportsAsync();
    }, [rowsPerPage, pageNumber]);

    function ClearReportAsync() {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "userid": state.userAccessContext?.id,
                "searchText": '',
                "userType": state.userAccessContext?.role,
                "startingRecordNumber": (pageNumber * rowsPerPage) + 1,
                "rowsPerPage": rowsPerPage
            }
            usePost<{ performanceReportResults: IPerformanceReportsResult[], totalCount: number }>("PerformanceReportGetByUser", request).then((reportList) => {
                setPerformanceReportResults(reportList?.data?.performanceReportResults);
                setTotalCount(reportList?.data?.totalCount);
            }).finally(() => {
                setshowProgressBar(false);
                setSearchText("");
            })
        })();
    }

    async function getReportsAsync() {
        setshowProgressBar(true);
        if (state.userAccessContext?.id !== undefined) {
            let request = {
                "userid": state.userAccessContext?.id,
                "searchText": searchText,
                "userType": state.userAccessContext?.role,
                "startingRecordNumber": (pageNumber * rowsPerPage) + 1,
                "rowsPerPage": rowsPerPage
            }
            usePost<{ performanceReportResults: IPerformanceReportsResult[], totalCount: number }>("PerformanceReportGetByUser", request).then((reportList) => {
                setPerformanceReportResults(reportList?.data?.performanceReportResults);
                setTotalCount(reportList?.data?.totalCount);
            }).finally(() => {
                setshowProgressBar(false);
            })
        } else {
            let requestAccount = { "userName": accounts[0]?.username };
            usePost<any>("User/GetUserByEmail", requestAccount).then((userAcc) => {
                let request = {
                    "userid": state.userAccessContext?.id,
                    "searchText": searchText,
                    "userType": state.userAccessContext?.role,
                    "startingRecordNumber": (pageNumber * rowsPerPage) + 1,
                    "rowsPerPage": rowsPerPage
                }
                usePost<{ performanceReportResults: IPerformanceReportsResult[], totalCount: number }>("PerformanceReportGetByUser", request).then((reportList) => {
                    setPerformanceReportResults(reportList?.data?.performanceReportResults);
                    setTotalCount(reportList?.data?.totalCount);
                })
            }).finally(() => {
                setshowProgressBar(false);
            })
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Box component="span" m={0} width="25%" >
                        <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                            <b> PERFROMANCE REPORT </b>
                        </Typography>
                    </Box>

                    <Box component="span" m={0} width="55%" >
                        <TextField
                            fullWidth
                            size="small"
                            id="standard-bare"
                            variant="outlined"
                            value={searchText}
                            type='text' placeholder="Search Perfromance Reports"
                            className={classes.searchBox}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && searchText != "") {
                                    getReportsAsync(); setShowSearchIcon(false);
                                } else if (event.key === 'Enter' && searchText === "") {
                                    ClearReportAsync(); setShowSearchIcon(true);
                                }
                            }}
                            inputProps={{ maxlength: 255 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline1,
                                    input: classes.searchTextStyle,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton onClick={(_) => { getReportsAsync(); setShowSearchIcon(false); }}>
                                                <SearchIcon className={classes.IconSizeStyle} />
                                            </IconButton>
                                            :
                                            <IconButton onClick={(_) => { ClearReportAsync(); setShowSearchIcon(true); }}>
                                                <CloseIcon className={classes.IconSizeStyle} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box component="span" m={0} width="20%" justifyContent="flex-end" display="flex">
                        <CreatePerformance onSave={getReportsAsync} />
                    </Box>
                    <Grid component={Paper} xs={12} sm={12} lg={12} >
                        <TableContainer component={Paper} className={`${classes.tableBody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(performanceReportResults, getComparator(order, orderBy)).map(
                                        (row) => {
                                            return (
                                                <PerformanceReport performanceReportsResult={row} onSave={getReportsAsync} />
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid component={Paper} container spacing={0}>
                    <Box m={0} width="65%">
                        <div className="footer">
                            <Footer />
                        </div>
                    </Box>
                    <Box m={0} width="35%">
                        <div className="pagination">
                            <TablePagination
                                id="Report_TablePagination"
                                rowsPerPageOptions={[15, 25, 50]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={pageNumber}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Box>
                </Grid>
            </div >
        </React.Fragment >
    )
}

export default PerformanceReportView

