import {
  Backdrop, Card, Grid, makeStyles, Dialog, DialogActions,
  DialogContent, TextField, Typography, Snackbar, Button, useMediaQuery
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
// Models
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { IGlobalMessage } from '../../../models/GlobalMessage/IGlobalMessage';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        display: "flex",
      },
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: 260,
      fontWeight: 'bold'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    updateNotification: {
      borderRadius: '20px',
      marginBottom: '5px',
      marginTop: '5px',
      fontSize: 12,
      backgroundColor: 'green',
      '&:hover': {
        backgroundColor: 'green',
      }
    },
    titleHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      fontSize: 18
    },
    card: {
      width: '300px',
      height: '240px',
      padding: '3px',
      marginLeft: "60px",
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    notificationName: {
      minWidth: 370,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '10px',
      fontSize: 13
    },
    notificationMessage: {
      minWidth: 370,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '10px',
      marginTop: '20px',
      fontSize: 13
    },
    ErrorMessageStyle: {
      marginTop: '5px',
      fontSize: 13,
      color: 'red',
      textAlign: 'center'
    },
    editbutton: {
      borderRadius: 20,
      backgroundColor: 'green',
      fontSize: 12,
      '&:hover': {
        backgroundColor: 'green',
      }
    },
    dialogePaper: {
      minWidth: "780px"
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditNotification: React.FC<{ Messages: IGlobalMessage, onSave: (Messages: IGlobalMessage) => void }> = (props) => {
  const { Messages } = props;
  const { dispatch } = useGlobalState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useRowStyles();
  const [messageName, setMessageName] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidDate, setValidDate] = useState(true);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);

  var GlobalMessage: IGlobalMessage = {
    id: Messages.id,
    name: Messages.name,
    message: Messages.message,
    start_date: Messages?.start_date?.split('T')[0],
    end_date: Messages?.end_date?.split('T')[0],
    date_created: Messages.date_created
  }

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsUpdate(false);
    setIsError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    useFetch<IGlobalMessage>("GlobalMessageById?id=" + Messages.id).then((r) => {
      setLocalState(r.data);
    })
  };

  const Close = () => {
    setOpen(false);
    setError(null);
  }

  const [localState, setLocalState] = React.useState(GlobalMessage);
  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    if (localState.start_date === localState.end_date) {
      setValidDate(true);
    }
    else if (localState.end_date < localState.start_date) {
      setValidDate(true);
    }
    else {
      setValidDate(false);
    }
  }, [localState.start_date, localState.end_date])

  const handleClose = () => {
    (async () => {
      setShowProgressBar(true);
      setOpen(false);
      dispatch({ type: GlobalStateAction.Busy });
      usePost('UpdateGlobalMessage', localState).then((r) => {
        props.onSave(localState);
      }).catch(() => {
        setIsError(true);
      }).finally(() => {
        setShowProgressBar(false);
        setIsUpdate(true);
      });
    })();
  }

  const HandleMessageVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,;:{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setMessageName(newValue); // only set when successful
    } else {
      setIsValid(true);
      setError("please enter characters and numbers only");
    }
  };

  useEffect(() => {
    if (messageName.length > 50) {
      setError("Max length is 50");
      setIsValid(true);
    } else {
      setError("");
      setIsValid(false);
    }
  }, [messageName])

  return (
    <React.Fragment>
      <div>
        <Button size="small" id="GM_Edit" startIcon={<EditNotificationsIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.editbutton}>Edit</Button>
        <Snackbar className="snackBarStyle" open={isUpdate} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={UpdateMessage}>
          <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
            Notification Updated Successfully.
          </Alert>
        </Snackbar>
        <Snackbar className="snackBarStyle" open={isError} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={UpdateMessage}>
          <Alert onClose={UpdateMessage} severity="error" className="alertStyle">
            Notification Not Created .
          </Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={Close}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          classes={{ paper: classes.dialogePaper }}
          TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 15 } }}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={Close}>
            <Typography variant="h6" gutterBottom className={classes.titleHeader}>
              EDIT NOTIFICATION
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container style={{ marginTop: '15px' }}>
              <Grid xs={12} sm={6} md={6} lg={6} style={{ marginTop: '15px' }}>
                <TextField
                  size="small"
                  id="GM_E_NotificationName"
                  label="Notification Name"
                  variant="outlined"
                  name="name"
                  value={localState.name}
                  onChange={(e) => { handleChange(e); HandleMessageVal(e); }}
                  className={classes.notificationName}
                  helperText={error}
                  error={!!error}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput
                    }
                  }}
                />
                <TextField id="GM_E_Message" label="Message" variant="outlined" name="message" value={localState.message} onChange={handleChange}
                  className={classes.notificationMessage} multiline rows={9}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} md={6} lg={6} style={{ marginTop: '15px' }}>
                <Card className={classes.card}>
                  <h3 className={classes.textField}>From:</h3>
                  <TextField
                    size="small"
                    id="GM_E_Startdate"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    name="start_date"
                    value={localState?.start_date?.split('T')[0]}
                    onChange={handleChange}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        root: classes.outlinedInput
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: "9999-12-31" }}
                  />
                  <h3 className={classes.textField}>To:</h3>
                  <TextField
                    id="GM_E_Enddate"
                    label="End Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    name="end_date"
                    value={localState?.end_date?.split('T')[0]}
                    onChange={handleChange}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        root: classes.outlinedInput
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: "9999-12-31" }}
                  />
                  {isValidDate ?
                    <Typography variant="subtitle2" className={classes.ErrorMessageStyle}>
                      End date should be greater than the Start date
                    </Typography>
                    :
                    null
                  }
                </Card>
              </Grid>
            </Grid>
            <DialogActions>
              <Button id="GM_E_Update_btn" size="small" startIcon={<DoneAllIcon />} autoFocus onClick={(_) => { handleClose(); }}
                disabled={!localState || isValid || isValidDate || !localState.message || !localState.name} color="primary"
                variant="contained" className={classes.updateNotification}>
                Update Notification
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default EditNotification