import { createStyles, IconButton, makeStyles, TableCell, TableRow, TextField, Theme } from '@material-ui/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { IFilter } from '../../models/Reports/IFilter';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IReportField } from '../../models/Reports/IReportField';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportOperatorValue } from '../../models/Reports/IReportOperatorValue';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(8),
      width: '100%',
      marginTop: "14px"
    },
    placeholder: {
      width: 200
    },
    addColumn: {
      width: 220
    },
    reportStyle1: {
      marginTop: '0px'
    },
    dropDown: {
      width: '100%',
      borderBottom: '3px solid blue',
      boxShadow: '0px 0px 5px 0px rgba(103, 128, 159, 1)',
      borderRadius: 5,
      backgroundColor: 'white',
      height: 38,
      fontSize: 12,
      marginTop: '5px',
      marginBottom: '5px'
    },
    dropDown1: {
      width: '100%',
      borderBottom: '3px solid blue',
      boxShadow: '0px 0px 5px 0px rgba(103, 128, 159, 1)',
      borderRadius: 5,
      backgroundColor: 'white',
      height: 38,
      fontSize: 12,
      marginTop: '5px',
      marginBottom: '5px'
    },
    outlinedInput: {
      color: "black",
      height: 38,
      borderRadius: 5,
      paddingLeft: '10px',
      fontSize: 10,
      padding: '0px 30px 0px 10px !important',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    paper1: {
      fontSize: '14',
      border: "2px solid black",
    },
    autoCompleteFont: {
      fontSize: 14,
      padding: '0px 15px'
    },
    table: {
      flexWrap: 'wrap',
      padding: '0px',
      border: '1px solid #f0efef',
    },
    customTable: {
      padding: "0px 5px", // <-- arbitrary value
      "& .MuiTableCell-sizeSmall": {
        padding: "0px 5px" // <-- arbitrary value
      }
    },
    customTable1: {
      padding: "0px 5px", // <-- arbitrary value
      width: '100px',
      "& .MuiTableCell-sizeSmall": {
        padding: "0px 5px" // <-- arbitrary value
      }
    },
    indexStyle: {
      marginLeft: '30px',
      fontSize: 15,
      fontWeight: 'bold'
    }
  })
);

const FilterReport: React.FC<{
  fieldsList: IReportField[], OperatorsList: IReportOperator[], filter: IFilter, handleDeleteFilter: any,
  onChangeFilter: any, states: any[], statuses: any[], phases: any[], clients: any[]
}> = (props) => {
  const classes = useStyles();
  const { fieldsList, OperatorsList, handleDeleteFilter, onChangeFilter, filter, states, statuses, phases, clients } = props;
  const [ShowOperators, setShowOperators] = useState<IReportOperatorValue[]>([])
  const [FilterValues, setFilterValues] = useState([]);
  const [Filter, setFilter] = useState(filter);
  const [isDateValue, setisDateValue] = useState(false);
  const [IsSpace, setIsSpace] = useState('');
  const clientsLoading = clients.length === 0;
  const [Field, setField] = useState<IReportField>({ "id": null, "name": "string", "data_type": "string" });
  const [Operator, setOperator] = useState<IReportOperatorValue>({ "id": "string", "name": "", "sqlKeyword": "string" });
  const [FieldMaxLen, setFieldMaxLen] = useState<number>(0);
  const [FieldDateVal, setFieldDateVal] = useState("");
  const [Value, setValue] = useState<any>({ "id": filter === undefined ? "" : filter.value, "value": filter === undefined ? "" : filter.value });
  const preventSpecialKeys = ["~", "!", "#", "$", "%", "^", "&", "*", "(", ")", "+", "=", "{", "}", "'", ">", "<", "?", "|", ";", "`", "@"];
  const preventBackSlash = "Backslash";

  let dummValue = {
    "id": filter === undefined ? "" : filter.value,
    "name": filter === undefined ? "" : filter.value
  }

  useEffect(() => {
    onValueSelect(null, Value);
  }, [Value]);

  useEffect(() => {
    let newField = fieldsList.find(field => field.id == filter.field_id);
    setField(newField);
    if (newField && newField.id != null) {
      OnFieldsSelect(null, newField)
    }
  }, [fieldsList]);

  function OnFieldsSelect(event, value) {
    if (value?.name == "Client Code") {
      setValue(clients.find(client_code => client_code?.id == filter.value));
      setFilterValues(clients);
      setIsSpace(value.name);
    } else if (value?.name == "State") {
      setValue(states.find(states => states.id == filter.value));
      setFilterValues(states);
      setIsSpace(value.name);
    } else if (value?.name == "Status") {
      setValue(statuses.find(status => status.id == filter.value));
      setFilterValues(statuses);
      setIsSpace('empty');
    } else if (value?.name == "Phase") {
      setValue(phases.find(phase => phase.id == filter.value));
      setFilterValues(phases);
      setIsSpace(value.name);
    } else {
      setFilterValues([dummValue]);
      setValue(dummValue);
      setIsSpace('empty');
    }

    let operatorList;
    if (value == null) {
      return;
    }
    setField(value);
    setisDateValue(value.data_type == "date");
    for (var i = 0; i < OperatorsList.length; i++) {
      if (OperatorsList[i].operatorType == value.data_type) {
        operatorList = OperatorsList[i];
      }
    }
    setOperator(operatorList.operatorValues.find(operator => parseInt(operator.id) == Filter.operator_id));
    setShowOperators(operatorList.operatorValues);
    let newFilter = _.cloneDeep(Filter);
    newFilter.field_id = value?.id;
    setFilter(newFilter);
    onChangeFilter(Filter.index, newFilter);
    GetFieldLength(event, value);
  }

  function onOperatorSelect(event, value) {
    let newFilter = _.cloneDeep(Filter);
    newFilter.operator_id = value?.id;
    setOperator(value);
    setFilter(newFilter);
    onChangeFilter(newFilter.index, newFilter);
  }

  function onValueSelect(event, value) {
    let newFilter = _.cloneDeep(Filter);
    newFilter.value = value?.id == null ? value : value?.id;
    setFilter(newFilter);
    onChangeFilter(Filter.index, newFilter);
  }

  function onValueTextField(event, value) {
    let newFilter = _.cloneDeep(Filter);
    newFilter.value = (value?.id == null || value?.id == undefined) ? (value === null ? "" : value) : value?.id;
    setFilter(newFilter);
    onChangeFilter(Filter.index, newFilter);
  }

  function GetFieldLength(event, value) {
    let FieldLength = 0;
    //String Field
    if (value.name == 'Agency ID') { FieldLength = 9 }
    else if (value.name == 'Account ID') { FieldLength = 20 }
    else if (value.name == 'Name') { FieldLength = 50 }
    else if (value.name == 'Address') { FieldLength = 255 }
    else if (value.name == 'City') { FieldLength = 50 }
    else if (value.name == 'State') { FieldLength = 20 }
    else if (value.name == 'Zip') { FieldLength = 9 }
    //money Field
    else if (value.name == 'Principal Due') { FieldLength = 13 }
    else if (value.name == 'Total Paid') { FieldLength = 13 }
    else if (value.name == 'Total Due') { FieldLength = 13 }
    else if (value.name == 'Fee') { FieldLength = 13 }
    //Status Field
    else if (value.name == 'Status') { FieldLength = 40 }
    //Date Field
    else if (value.name == 'Status Change Date') { setFieldDateVal(moment(new Date()).format('YYYY-MM-DD')); }
    else if (value.name == 'Date') { setFieldDateVal(moment(new Date()).format('YYYY-MM-DD')); }
    else if (value.name == 'Referral Date') { setFieldDateVal(moment(new Date()).format('YYYY-MM-DD')); }
    //Client Code Field
    else if (value.name == 'Client Code') { FieldLength = 50 }
    //Phase Field
    else if (value.name == 'Phase') { FieldLength = 50 }
    setFieldMaxLen(FieldLength);
  }

  const preventSpecialCharacters = (e) => {
    if (preventSpecialKeys.includes(e.key)) {
      e.preventDefault();
    } else if (e.code === preventBackSlash) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.table} >
        <TableCell classes={{ root: classes.customTable1 }} align='left'>
          <span className={classes.indexStyle}>
            {filter.index + 1}
          </span>
        </TableCell>
        <TableCell classes={{ root: classes.customTable }} align='center'>
          <Autocomplete
            size="small" autoComplete autoHighlight disableClearable
            classes={{ paper: classes.paper1, option: classes.autoCompleteFont, }}
            options={fieldsList} getOptionLabel={(option) => option?.name}
            value={Field} onChange={(e, value) => { OnFieldsSelect(e, value) }}
            renderInput={(params) => (
              <TextField {...params} margin="normal" variant="outlined"
                className={classes.dropDown1} required
                inputProps={{
                  ...params.inputProps, disableUnderline: true,
                  maxlength: 10, style: { fontSize: 13 },
                  classes: { root: classes.outlinedInput }
                }}
              />
            )}
          />
        </TableCell>
        <TableCell classes={{ root: classes.customTable }} align='center'>
          <Autocomplete
            size="small" autoComplete autoHighlight
            classes={{ paper: classes.paper1 }}
            options={ShowOperators}
            getOptionLabel={(option) => option?.name}
            value={Operator} onChange={onOperatorSelect}
            key={ShowOperators.length > 0 ? ShowOperators[0].name?.toString() : "empty"}
            renderInput={(params) => (
              <TextField {...params} margin="normal" variant="outlined"
                className={classes.dropDown1}
                inputProps={{
                  ...params.inputProps, disableUnderline: true,
                  maxlength: 50, style: { fontSize: 13 },
                  classes: {
                    root: classes.outlinedInput,
                    option: classes.autoCompleteFont,
                  }
                }}
              />
            )}
          />
        </TableCell>
        <TableCell classes={{ root: classes.customTable }} align='center'>
          {IsSpace === "Client Code" ?
            <Autocomplete
              size="small" id="clientCode-select"
              className={classes.reportStyle1}
              classes={{ paper: classes.paper1 }}
              options={clients} autoHighlight
              loading={clientsLoading} value={Value}
              onChange={onValueSelect} clearOnBlur={false}
              getOptionLabel={(option) => option?.id + "--" + option?.name}
              getOptionSelected={(option, value) => option?.id === value?.id}
              renderOption={(option) => (
                <React.Fragment>
                  {option?.id + "--" + option?.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params} placeholder="Select client"
                  variant="outlined" className={classes.dropDown}
                  inputProps={{
                    ...params.inputProps, disableUnderline: true,
                    maxlength: FieldMaxLen,
                    autoComplete: 'new-password',// disable autocomplete and autofill
                    style: { fontSize: 13 },
                    classes: {
                      root: classes.outlinedInput,
                      option: classes.autoCompleteFont,
                    }
                  }}
                />
              )}
            />
            : IsSpace === "State" || IsSpace === "statuses" || IsSpace === "Phase" && IsSpace.length > 0 ?
              <Autocomplete
                size="small" id="clientCode-select"
                className={classes.reportStyle1}
                classes={{ paper: classes.paper1 }}
                options={IsSpace === "State" ? states : IsSpace === "Phase" ? phases : statuses}
                autoHighlight disableClearable value={Value}
                onChange={onValueSelect}
                getOptionLabel={(option) => option?.name}
                getOptionSelected={(option, value) => option?.id === value?.id}
                renderOption={(option) => (<React.Fragment>{option?.name}</React.Fragment>)}
                renderInput={(params) => (
                  <TextField
                    className={classes.dropDown}{...params}
                    label={IsSpace === "State" ? "Select State" : IsSpace === "Phase" ? "Select Phase" : "Select Account Status"}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps, disableUnderline: true,
                      maxlength: FieldMaxLen, style: { fontSize: 13 },
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      classes: {
                        root: classes.outlinedInput,
                        option: classes.autoCompleteFont,
                      }
                    }}
                  />
                )}
              />
              :
              <>
                <Autocomplete
                  size="small" id="FR_ValueSelect"
                  freeSolo autoComplete autoHighlight
                  options={FilterValues} onChange={onValueTextField}
                  onInputChange={onValueTextField} value={Value}
                  onKeyPress={(e) => { preventSpecialCharacters(e) }}
                  getOptionLabel={(option) => option?.name === undefined ? "" : option?.name}
                  key={FilterValues?.length > 0 ? FilterValues[0]?.name?.toString() : "empty"}
                  renderInput={(params) => (
                    <TextField {...params} type={isDateValue ? "date" : null} margin="normal" variant="outlined"
                      className={classes.dropDown}
                      inputProps={{
                        ...params.inputProps, disableUnderline: true,
                        maxlength: FieldMaxLen,
                        max: `${isDateValue ? FieldDateVal : null}`,
                        style: { fontSize: 13 },
                        classes: {
                          root: classes.outlinedInput,
                          option: classes.autoCompleteFont,
                        }
                      }}
                    />
                  )}
                />
              </>
          }
        </TableCell>
        <TableCell classes={{ root: classes.customTable }} align='center'>
          <IconButton id="FR_DeleteIcon" aria-label="delete" onClick={() => handleDeleteFilter(filter.index)}>
            <HighlightOffIcon fontSize="inherit"
              style={{ color: "red" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default FilterReport;