import {
  Backdrop, Box, Button, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, Container,
  TableCell, TableContainer, TableHead, TableRow, TextField, Theme, withStyles, Dialog, DialogActions, DialogContent, FormLabel
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import * as _ from 'lodash';
import moment from 'moment';
import { formats } from '../../../constants/Constant';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import { IValidationError } from '../../../models/common/IValidationError';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { DialogTitleDelete } from '../../../components/GlobalStyles/DialogStyle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { IUwfDetails } from '../../../models/Placements/IUwfDetails';
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';

const useStyles = makeStyles((theme) => ({
  tableLayout: {
    tableLayout: 'auto'
  },
  container: {
    maxWidth: "100vw",
    maxHeight: 'calc( 100vh - 130px)',
    minHeight: 'calc( 100vh - 130px)',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    padding: '5px'
  },
  buttons: {
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    float: 'right'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180,
    textAlign: 'center',
    maxHeight: '50px',
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '7px',
    color: 'black',
    '& .MuiInputBase-input': {
      fontSize: 13
    },
    '& .MuiInputBase-inputMarginDense': {
      padding: '6px',
    }
  },
  moneyField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    fontSize: 13,
    transition: '0.3s',
    borderRadius: '4px',
    textAlign: 'right',
    paddingRight: '10px',
    height: '30px',
    border: '1px solid #364F6B'
  },
  moneyFieldError: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    fontSize: 13,
    transition: '0.3s',
    borderRadius: '4px',
    textAlign: 'right',
    paddingRight: '10px',
    height: '35px',
    backgroundColor: 'red'
  },
  textFieldError: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 13,
    width: 200,
    textAlign: 'center',
    maxHeight: '50px',
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'red',
    paddingLeft: '7px',
  },
  textFieldRequired: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '10px',
    width: 200,
    textAlign: 'center',
    maxHeight: '50px',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '7px',
    border: '1px solid red'
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 12,
    textIndent: 5,
    width: 200,
    textAlign: 'center',
    maxHeight: '50px',
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '7px',
    outline: 'none',
    whiteSpace: "nowrap",
    '& .MuiInputBase-input': {
      fontSize: 13,
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: '6px',
    }
  },
  error: {
    color: 'red',
  },
  inputField: {
    height: '30px',
    fontSize: 14,
    borderRadius: '4px',
    transition: '0.3s',
    marginRight: "20px",
    width: "70px",
  },
  div: {
    flexGrow: 1,
    margin: "0px 10px",
    paddingTop: '4px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  errorColor:
  {
    color: 'red',
    fontSize: '18px'
  },
  title: {
    alignContent: "center",
    textAlign: 'center',
    justifyItems: 'center',
    marginTop: '0.2cm',
    float: 'right',
    fontSize: 18,
  },
  buttonbox: {
    float: 'right',
    marginRight: 20,
    padding: 0,
    backgroundColor: 'transparent'
  },
  tableHead: {
    alignContent: "left",
    textAlign: 'left',
    height: '2vh',
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
    padding: '0px 0px 0px 0px',
  },
  tableHead1: {
    height: '4vh',
    padding: '3px',
  },
  tableRow: {
    border: '1px solid #364F6B',
    alignContent: "center",
    justifyItems: 'center',
    padding: '0px',
  },
  tableRow1: {
    color: "white",
    textAlign: 'left',
    padding: '0px',
    textIndent: '10px',
    fontSize: 13
  },
  tablebody: {
    maxHeight: 'calc( 100vh - 260px)',
    display: 'flex',
    marginTop: '5px',
    backgroundColor: 'whitesmoke',
    width: '99%',
    marginLeft: '10px',
    marginBottom: 5
  },
  ignoredRow: {
    textDecoration: 'line-through',
    backgroundColor: 'red'
  },
  ignoredRowPassive: {
    textDecoration: 'line-through'
  },
  textWrap: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontSize: 13,
    overflow: 'hidden',
    marginTop: '8px',
    '&:hover': {
      overflow: 'visible',
      transition: '0.10s',
    }
  },
  deleteIconStyle: {
    marginLeft: "10px",
  },
  DividerStyles: {
    height: '2px',
    background: "black",
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white',
    fontSize: 18
  },
  yesButton: {
    background: "red",
    color: 'white',
    borderRadius: 20,
    right: '15px',
    fontSize: 12,
    '&:hover': {
      background: "red",
    }
  },
  MessageStyle: {
    color: "black",
    fontSize: 17
  },
  error_message: {
    color: "#02580e",
    fontSize: 13
  },
  error_message1: {
    color: "red",
    fontSize: 13
  },
  uwfStyle: {
    color: 'green',
    fontSize: 14,
    padding: '5px',
    fontWeight: 'bold'
  },
  InfoStyle: {
    fontSize: 14
  },
  uwfBoxStyle: {
    padding: '0px',
    marginTop: '2px',
    marginLeft: '5px',
    width: 610,
    borderRadius: '10px',
    position: 'absolute',
    border: '2px solid gray',
    backgroundColor: 'transparent',
  },
  messagegrid: {
    minHeight: '65px',
    maxHeight: '65px',
    alignItems: "center",
    justifyContent: "center",
    marginTop: '5px'
  },
  LabelStyle: {
    float: 'left',
    marginTop: '6px'
  }
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
      padding: '0px'
    },
    body: {
      fontSize: 12,
      width: 600
    },
  }),
)(TableCell);

function Fieldnames(props) {
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();
  const { handleSubmit } = useForm();

  const [placementAccountList, setPlacementAccountList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [selectedHeaderList, setSelectedHeaderList] = useState<string[]>(props.location?.state?.selectedHeaderList ? props.location?.state?.selectedHeaderList : []);
  const [tablerows, setTableRows] = useState<number[]>(props.location?.state?.tablerows ? props.location?.state?.tablerows : [1]);
  const [getStates, setGetStates] = useState(props.location?.state?.stateCodes ? props.location?.state?.stateCodes : []);
  const [mappedRequireFields, setMappedRequireFields] = useState<ITemplateField[]>([]);
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
  const [errorMessage, setErrorMessage] = useState<IValidationError[]>([]);
  const [requireFields, setRequireFields] = useState<ITemplateField[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateField[]>([]);
  const [GetUwfCltData, setGetUwfCltData] = useState<IUwfDetails[]>();

  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let phaseNumber: number = props.location.state.phaseNumber;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;

  const [numberOfRows, setNumberOfRows] = useState<any>(1);
  const [savingEnable, setSavingEnable] = useState(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  let zipVal = /^(?:$|(\d{5}[-\s]?(?:\d{4})?$))/;

  const handleFormSubmit = (e) => {
    setIsSubmit(true);
    if (!validationErrors.length && !requireFields.length) {
      history.push('\previewdata', {
        clientCode: clientCode, tablerows: tablerows, phaseNumber: phaseNumber, multiCode: multiCode,
        placementAccountList: placementAccountList, templateList: templateList, templateId: templateId,
        selectedHeaderList: selectedHeaderList, stateCodes: getStates
      });
    } else {
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    if (!requireFields.length) {
      setSavingEnable(true);
    } else {
      setSavingEnable(false);
    }
    let errorlist = _.cloneDeep(validationErrors);
    errorlist = errorlist.filter((x) => x.errorMessage !== null);
    setErrorMessage(errorlist);
  }, [requireFields, validationErrors, isSubmit]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    if (placementAccountList.length == 0) {
      let list: IPlacementAccount[] = [];
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      list.push(placementAccount);
      setPlacementAccountList(list);
      setIsSubmit(true);
    }
  }, [placementAccountList])

  useEffect(() => {
    (async () => {
      if (templateList.length <= 0) {
        try {
          dispatch({ type: GlobalStateAction.Busy })
          await useFetch<ITemplateField[]>(`Placements/TemplateExclude?clientCode=${clientCode === "-2" ? null : clientCode}`).then((template) => {
            setTemplateList(template.data);
            setRequireFields(template.data.filter(x => x.is_nullable == false));
            setMappedRequireFields(template.data.filter(x => x.is_nullable == false));
            setIsSubmit(true);
          });
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Idle });
          dispatch({ type: GlobalStateAction.Error, error: ex });
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle });
        }
      }
    })()
  }, [templateList])

  useEffect(() => {
    let mappedHeaderList = _.cloneDeep(selectedHeaderList);
    let requiredHeaderList = _.cloneDeep(mappedRequireFields);
    mappedHeaderList.map(header => {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== header);
    });
    if (selectedHeaderList.includes("first_name") && selectedHeaderList.includes("last_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "full_name");
    }
    if (selectedHeaderList.includes("full_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "first_name" && x.field_name !== "last_name");
    }
    if (selectedHeaderList.includes("Payments_applied_since_itemization_date") && selectedHeaderList.includes("Credits_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Payments_Credits_applied_since_itemization_date");
    }
    if (selectedHeaderList.includes("Payments_Credits_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Payments_applied_since_itemization_date" && x.field_name !== "Credits_applied_since_itemization_date");
    }
    setRequireFields(requiredHeaderList);
  }, [isSubmit, selectedHeaderList]);

  useEffect(() => {
    (async () => {
      if (selectedHeaderList.includes("first_name") || selectedHeaderList.includes("last_name")) {
        let nameVal = new RegExp(/^[a-zA-Z. ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!nameVal.exec(x?.first_name))
            || (((x?.first_name === null || x?.first_name?.length <= 0) || (x?.last_name === null || x?.last_name?.length <= 0)) && (x?.full_name === null || x?.full_name?.length <= 0))) {
            let firstNameValidation: IValidationError = {
              errorNumber: 2, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1
                && currentValidationerrorList.findIndex(x => x.errorNumber == 4) <= -1 ? "Last Name, First Name OR Business Name (*)" : null),
              field: "first_name", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 2 && x.index == index) <= -1 && x?.first_name !== null) {
              currentValidationerrorList.push(firstNameValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 2 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 2 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("last_name") || selectedHeaderList.includes("first_name")) {
        let nameVal = new RegExp(/^[a-zA-Z. ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!nameVal.exec(x?.last_name)) || (((x?.last_name === null || x?.last_name?.length <= 0) || (x?.first_name === null || x?.first_name?.length <= 0)) && (x?.full_name === null || x?.full_name?.length <= 0))) {
            let lastNameValidation: IValidationError = {
              errorNumber: 3, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1
                && currentValidationerrorList.findIndex(x => x.errorNumber == 4) <= -1 ? "Last Name, First Name OR Business Name (*)" : null),
              field: "last_name", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 3 && x.index == index) <= -1 && x?.last_name !== null) {
              currentValidationerrorList.push(lastNameValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 3 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 3 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("full_name") || selectedHeaderList.includes("last_name") || selectedHeaderList.includes("first_name")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (((x?.first_name?.length <= 0 || x?.first_name === null) && (x?.full_name?.length <= 0))
            || ((x?.last_name?.length <= 0 || x?.last_name === null) && (x?.full_name?.length <= 0))
            || ((x?.first_name === null || x?.first_name?.length <= 0) && (x?.last_name === null || x?.last_name?.length <= 0) && (x?.full_name === null || x?.full_name?.length <= 0))) {
            let fullNameValidation: IValidationError = {
              errorNumber: 4, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1
                && currentValidationerrorList.findIndex(x => x.errorNumber == 4) <= -1 ? "Last Name, First Name OR Business Name (*)" : null),
              field: "full_name", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 4 && x.index == index) <= -1) {
              currentValidationerrorList.push(fullNameValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 4 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 4 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("account_no")) {
        let accountVal = new RegExp(/^[a-z0-9]+$/i);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!accountVal.exec(x?.account_no)) || (mappedRequireFields.find(x => x.field_name === "account_no") && x?.account_no === "" || x?.account_no === null)) {
            let accountValidation: IValidationError = {
              errorNumber: 1, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 1) <= -1 ? "Account Number is required must be numbers and alphabets only" : null),
              field: "account_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 1 && x.index == index) <= -1) {
              currentValidationerrorList.push(accountValidation);
              setValidationErrors(currentValidationerrorList);
            }
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 1 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 1 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("city")) {
        let cityVal = new RegExp(/^[a-zA-Z ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!cityVal.exec(x?.city)) || (mappedRequireFields.find(x => x.field_name === "city") && x?.city === "" || x?.city === null)) {
            let cityValidation: IValidationError = {
              errorNumber: 5, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 5) <= -1 ? "City must be Characters only" : null),
              field: "city", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 5 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 5 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 5 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Codebtor_City")) {
        let cityVal = new RegExp(/^[a-zA-Z ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!cityVal.exec(x?.Codebtor_City)) || (mappedRequireFields.find(x => x.field_name === "Codebtor_City") && x?.Codebtor_City === "" || x?.Codebtor_City === null)) {
            let cityValidation: IValidationError = {
              errorNumber: 31, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 31) <= -1 ? "Codebtor City must be Characters only" : null),
              field: "Codebtor_City", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 31 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 31 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 31 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("fees")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "fees") && Number(x?.fees) <= 0 || x?.fees === null)) {
            let balanceValidation: IValidationError = {
              errorNumber: 6, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 6) <= -1 ? "Fee balance is required & must be greater than zero" : null),
              field: "fees", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 6 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceValidation);
              setValidationErrors(currentValidationerrorList);
            }
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 6 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 6 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("state")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates?.includes(x?.state?.toUpperCase())) {
            let stateValidation: IValidationError = {
              errorNumber: 7, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 7) <= -1 ? "State code must be valid and 2 digit only" : null),
              field: "state", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 7 && x.index == index) <= -1) {
              currentValidationerrorList.push(stateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 7 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 7 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("secondary_state")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates.includes(x?.secondary_state?.toUpperCase()) && (mappedRequireFields.find(x => x.field_name === "secondary_state"))) {
            let secondaryStateValidation: IValidationError = {
              errorNumber: 8, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 8) <= -1 ? "Secondary State code must be valid and 2 digit only" : null),
              field: "secondary_state", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 8 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryStateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 8 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 8 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Codebtor_State")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates.includes(x?.Codebtor_State?.toUpperCase()) && (mappedRequireFields.find(x => x.field_name === "Codebtor_State"))) {
            let coDebtorStateValidation: IValidationError = {
              errorNumber: 9, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 9) <= -1 ? "CoDebtor State code must be valid and 2 digit only" : null),
              field: "Codebtor_State", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 9 && x.index == index) <= -1) {
              currentValidationerrorList.push(coDebtorStateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 9 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 9 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.zip)) || (mappedRequireFields.find(x => x.field_name === "zip") && (x?.zip === null || x?.zip === ""))) {
            let zipValidation: IValidationError = {
              errorNumber: 10, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 10) <= -1 ? "Zip code must be either 5 or 9 digits only" : null),
              field: "zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 10 && x.index == index) <= -1) {
              currentValidationerrorList.push(zipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 10 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 10 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("secondary_zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.secondary_zip)) || (mappedRequireFields.find(x => x.field_name === "secondary_zip") && (x?.secondary_zip === null || x?.secondary_zip === ""))) {
            let secondaryZipValidation: IValidationError = {
              errorNumber: 11, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 11) <= -1 ? "Secondary Zip code must be either 5 or 9 digits only" : null),
              field: "secondary_zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 11 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryZipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 11 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 11 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("Codebtor_Zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.Codebtor_Zip)) || (mappedRequireFields.find(x => x.field_name === "codebtor_Zip") && (x?.Codebtor_Zip === null || x?.Codebtor_Zip === ""))) {
            let secondaryZipValidation: IValidationError = {
              errorNumber: 12, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 12) <= -1 ? "CoDebtor Zip code must be either 5 or 9 digits only" : null),
              field: "Codebtor_Zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 12 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryZipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 12 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 12 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("phone_no")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.phone_no)) || (mappedRequireFields.find(x => x.field_name === "phone_no") && x?.phone_no === "")) {
            let phoneValidation: IValidationError = {
              errorNumber: 13, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 13) <= -1 ? "Phone number must be number and 10 digits only" : null),
              field: "phone_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 13 && x.index == index) <= -1) {
              currentValidationerrorList.push(phoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 13 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 13 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("secondary_phone_no")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.secondary_phone_no)) || (mappedRequireFields.find(x => x.field_name === "secondary_phone_no") && (x?.secondary_phone_no === null || x?.secondary_phone_no === ""))) {
            let secondaryPhoneValidation: IValidationError = {
              errorNumber: 14, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 14) <= -1 ? "Secondary Phone number must be number and 10 digits only" : null),
              field: "secondary_phone_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 14 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryPhoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 14 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 14 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("CoDebtor_Phone1")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.CoDebtor_Phone1)) || (mappedRequireFields.find(x => x.field_name === "CoDebtor_Phone1") && x?.CoDebtor_Phone1 === "")) {
            let phoneValidation: IValidationError = {
              errorNumber: 15, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 15) <= -1 ? "Phone number must be number and 10 digits only" : null),
              field: "CoDebtor_Phone1", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 15 && x.index == index) <= -1) {
              currentValidationerrorList.push(phoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 15 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 15 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("CoDebtor_Phone2")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.CoDebtor_Phone2)) || (mappedRequireFields.find(x => x.field_name === "CoDebtor_Phone2") && x?.CoDebtor_Phone2 === "")) {
            let coDebtorPhoneValidation: IValidationError = {
              errorNumber: 16, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 16) <= -1 ? "CoDebtor Phone number2 must be number and 10 digits only" : null),
              field: "CoDebtor_Phone2", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 16 && x.index == index) <= -1) {
              currentValidationerrorList.push(coDebtorPhoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 16 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 16 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("total_balance")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "total_balance") && x?.total_balance === null)) {
            let balanceValidation: IValidationError = {
              errorNumber: 17, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 17) <= -1 ? "Total balance is required & must be greater than zero" : null),
              field: "total_balance", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 17 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 17 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 17 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Balance_owed_on_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Balance_owed_on_itemization_date") && x?.Balance_owed_on_itemization_date === null)) {
            let balanceDateValidation: IValidationError = {
              errorNumber: 20, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 20) <= -1 ? 'Balance owed on itemization date is required & can not be empty' : null),
              field: "Balance_owed_on_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 20 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 20 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 20 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Fees_added_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Fees_added_since_itemization_date") && x?.Fees_added_since_itemization_date === null)) {
            let feesDateValidation: IValidationError = {
              errorNumber: 21, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 21) <= -1 ? 'Fees added since itemization is required & can not be empty' : null),
              field: "Fees_added_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 21 && x.index == index) <= -1) {
              currentValidationerrorList.push(feesDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 21 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 21 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Interest_added_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Interest_added_since_itemization_date") && x?.Interest_added_since_itemization_date === null)) {
            let interestDateValidation: IValidationError = {
              errorNumber: 22, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 22) <= -1 ? 'Interest added since Itemization Date is required & can not be empty' : null),
              field: "Interest_added_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 22 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 22 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 22 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }
      if (selectedHeaderList.includes("Payments_applied_since_itemization_date") || selectedHeaderList.includes("Credits_applied_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((x?.Payments_Credits_applied_since_itemization_date === null || x?.Payments_Credits_applied_since_itemization_date === '0.00')
            && (x?.Payments_applied_since_itemization_date === null && Number(x?.Credits_applied_since_itemization_date) > 0)
            || (Number(x?.Payments_applied_since_itemization_date) > 0 && Number(x?.Credits_applied_since_itemization_date) === 0 && Number(x?.Payments_Credits_applied_since_itemization_date) > 0)
          ) {
            let paymentDateValidation: IValidationError = {
              errorNumber: 18, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 19) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Payments_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 18 && x.index == index) <= -1) {
              currentValidationerrorList.push(paymentDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 18 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 18 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Credits_applied_since_itemization_date") || (selectedHeaderList.includes("Payments_applied_since_itemization_date"))) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((x?.Payments_Credits_applied_since_itemization_date === null || x?.Payments_Credits_applied_since_itemization_date === '0.00')
            && (x?.Credits_applied_since_itemization_date === null && Number(x?.Payments_applied_since_itemization_date) > 0)
            || (Number(x?.Credits_applied_since_itemization_date) > 0 && Number(x?.Payments_applied_since_itemization_date) === 0 && Number(x?.Payments_Credits_applied_since_itemization_date) > 0)
          ) {
            let creditsDateValidation: IValidationError = {
              errorNumber: 19, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 18) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Credits_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 19 && x.index == index) <= -1) {
              currentValidationerrorList.push(creditsDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 19 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 19 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Payments_Credits_applied_since_itemization_date") || selectedHeaderList.includes("Credits_applied_since_itemization_date") || selectedHeaderList.includes("Payments_applied_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (
            (x?.Payments_Credits_applied_since_itemization_date == null && x?.Payments_applied_since_itemization_date == null && x?.Payments_Credits_applied_since_itemization_date === null)) {
            let interestDateValidation: IValidationError = {
              errorNumber: 23, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 18) <= -1
                && currentValidationerrorList.findIndex(x => x.errorNumber == 19) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Payments_Credits_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 23 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 23 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 23 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("delinquency_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.delinquency_date)?.isAfter(new Date())) || moment(x?.delinquency_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "delinquency_date") && (x?.delinquency_date === null || x?.delinquency_date === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 24, errorMessage: "Delinquency Date is required and can not be empty or Future date/Invalid date",
              field: "delinquency_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 24 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 24 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 24 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("Itemization_Date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.Itemization_Date)?.isAfter(new Date()) || moment(x?.Itemization_Date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "Itemization_Date") && (x?.Itemization_Date === null || x?.Itemization_Date === "")))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 25, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 25) <= -1 ? "Itemization Date is required and can not be empty or Future date/Invalid date" : null),
              field: "Itemization_Date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 25 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 25 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 25 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("birth_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.birth_date)?.isAfter(new Date())) || (mappedRequireFields.find(x => x.field_name === "birth_date") && (x?.birth_date === null || x?.birth_date === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 26, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 26) <= -1 ? "Birth Date is required and can not be empty or Future date/Invalid date" : null),
              field: "birth_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 26 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 26 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 26 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("Codebtor_BirthDate")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.Codebtor_BirthDate)?.isAfter(new Date())) || (mappedRequireFields.find(x => x.field_name === "Codebtor_BirthDate") && (x?.Codebtor_BirthDate === null || x?.Codebtor_BirthDate === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 27, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 27) <= -1 ? "Codebtor Birth Date is required and can not be empty or Future date/Invalid date" : null),
              field: "Codebtor_BirthDate", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 27 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 27 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 27 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("service_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "service_date") && (x?.service_date === null || x?.service_date === "" || moment(x?.service_date)?.isAfter(new Date())) || moment(x?.service_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY'))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 28, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 28) <= -1 ? "Service Date is required and can not be empty or Future date/Invalid date" : null),
              field: "service_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 28 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 28 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 28 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("last_payment_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.last_payment_date).isAfter(new Date()) || moment(x?.last_payment_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "last_payment_date") && (x?.last_payment_date === null || x?.last_payment_date === "")))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 29, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 29) <= -1 ? "Last Payment Date is required and can not be empty or Future date/Invalid date" : null),
              field: "last_payment_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 29 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 29 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 29 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("address1")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (((mappedRequireFields.find(x => x.field_name === "address1") && x?.address1 === "" || x?.address1 === null))) {
            let cityValidation: IValidationError = {
              errorNumber: 30, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 30) <= -1 ? "Street Address1 is required & cannot be empty" : null),
              field: "address1", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 30 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 30 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 30 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

    })()
  }, [placementAccountList, selectedHeaderList, validationErrors, isSubmit])


  const getEmptyPlacementAccount = (): IPlacementAccount => {
    let placementAccount: IPlacementAccount = {
      phaseNumber: null,
      phase: null,
      client_code: null,
      account_no: null,
      first_name: null,
      last_name: null,
      full_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_no: null,
      total_balance: null,
      delinquency_date: null,
      secondary_address1: null,
      secondary_address2: null,
      citysecondary: null,
      secondary_state: null,
      secondary_zip: null,
      secondary_phone_no: null,
      email: null,
      drivers_license: null,
      tax_id: null,
      birth_date: null,
      past_due_balance: null,
      current_balance: null,
      equipment_charges: null,
      contract_charges: null,
      fees: null,
      last_payment_date: null,
      guarantor_first_name: null,
      guarantor_last_name: null,
      guarantor_full_name: null,
      comment: null,
      account_type: null,
      other: null,
      user_id: state.userAccessContext?.id,
      modifiedby: state?.userAccessContext?.userName,
      file_name: null,
      contact_name: null,
      service_date: null,
      location: null,
      codebtor_Full_Name: null,
      codebtor_Street_Address1: null,
      codebtor_Street_Address2: null,
      Codebtor_City: null,
      Codebtor_State: null,
      Codebtor_Zip: null,
      Codebtor_BirthDate: null,
      Codebtor_Tax_ID: null,
      CoDebtor_First_Name: null,
      CoDebtor_Last_Name: null,
      CoDebtor_Phone1: null,
      CoDebtor_Phone2: null,
      Payments_applied_since_itemization_date: null,
      Credits_applied_since_itemization_date: null,
      Itemization_Date: null,
      Balance_owed_on_itemization_date: null,
      Fees_added_since_itemization_date: null,
      Interest_added_since_itemization_date: null,
      Payments_Credits_applied_since_itemization_date: null
    }
    return placementAccount;
  }

  const getTextFieldType = (datatype: string) => {
    switch (datatype) {
      case 'varchar': return 'text'
      case 'money': return 'text'
      case 'date': return 'date'
    }
  }

  const addMultipleRows = () => {
    let rows = _.cloneDeep(tablerows);
    let paList = _.cloneDeep(placementAccountList);
    for (let i = 0; i < numberOfRows; i++) {
      rows.push((rows.length + 1));
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      paList.push(placementAccount);
    }
    setPlacementAccountList(paList);
    setTableRows(rows);
  }

  const handleDeleteFilter = (r: any) => {
    if (tablerows.length == 1)
      return;
    let paList = _.cloneDeep(placementAccountList);
    let modifiedPAList = paList.filter((ele, index) => index != r);
    setPlacementAccountList(modifiedPAList);
    let validationerrorList = _.cloneDeep(validationErrors);
    validationerrorList = validationerrorList.filter((v) => v.index != r);
    setValidationErrors(validationerrorList);
    let tr = _.cloneDeep(tablerows);
    let trModified = tr.filter((ele, index) => index != r);
    trModified.forEach((f, indexnum) => { trModified[indexnum] = indexnum + 1 });
    setTableRows(trModified);
  }

  const handleAddRows = (value: string): void => {
    setNumberOfRows(value === '' ? '' : Number(value) || '');
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  async function handleChange(field_Name, index, event, field_Type) {
    let headerList = _.cloneDeep(selectedHeaderList);
    let targetValue = (field_Type == "money" ? (event === undefined ? "0" : event) : event.target.value);
    if (!headerList.includes(field_Name)) {
      headerList.push(field_Name);
      setSelectedHeaderList(headerList);
    } if (field_Name !== "Delinquency_Itemization_Date") {
      let accountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      eval('accountList[' + index + '].' + field_Name + ' = targetValue');
      setPlacementAccountList(accountList);
    } else if (field_Name == "Delinquency_Itemization_Date") {
      let accountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      accountList[index].delinquency_date = targetValue;
      accountList[index].Itemization_Date = targetValue;
      setPlacementAccountList(accountList);
    };
  }

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: GlobalStateAction.Busy });
        await useFetch<{ uwfclT_FLD1: IUwfDetails[] }>(`Placements/GetClientItemizationDate?client_Code=${clientCode === "-2" ? null : clientCode}`).then((GetUWFDetails) => {
          setGetUwfCltData(GetUWFDetails.data[0].uwfclT_FLD1);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [GetUwfCltData]);

  return (
    <React.Fragment>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            MANUAL ENTRY
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
            Please provide all the necessary data.
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setAlertOpen(false); setErrorStyle(true) }} className={classes.yesButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Container fixed className={classes.container} component="div" >
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              {(GetUwfCltData !== undefined) ?
                <Box width='45%' display="flex" p={1} bgcolor="white" className={classes.uwfBoxStyle}>
                  <span className={classes.InfoStyle}>
                    Your account itemization date is: <span className={classes.uwfStyle}>{GetUwfCltData}</span> . If you need to change this, please contact clientservices@armsolutions.com.
                  </span>
                </Box>
                : null}
              <Typography variant="h5" gutterBottom className={`${classes.title} ${"headertitle"}`}>
                <b>2.INPUT DATA </b>
              </Typography>
            </Grid>
            <Grid item xs={5} >
              <Box className={classes.LabelStyle}>
                <FormLabel style={{ fontWeight: 'bold', color: 'green', fontSize: 15 }}> Total Added Row : {tablerows?.length}</FormLabel>
              </Box>
              <Box p={0.5} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <Button id="FN_Next_btn" size="small" endIcon={<ArrowForwardIosIcon />} className={classes.buttons}
                  variant="contained" type='submit' color="primary" onClick={handleSubmit(handleFormSubmit)}>
                  Next
                </Button>
              </Box>
              <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <Button id-="FN_Back_btn" size="small" startIcon={<ArrowBackIosIcon />}
                  className={classes.buttons} variant="contained" color="primary"
                  component={Link} to="/placement">
                  Back
                </Button>
              </Box>
              <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <CurrencyInput
                  maxLength={2}
                  value={numberOfRows}
                  className={classes.inputField}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={handleAddRows}
                  style={{ textAlign: 'center' }}
                />
                <Button id="FN_AddRows" size="small" startIcon={<QueuePlayNextIcon />} className={classes.buttons} disabled={validationErrors.length > 0 ? true : false}
                  variant="contained" color="primary" onClick={addMultipleRows}>
                  Add Rows
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.messagegrid}>
              <Box p={1} width="100%" bgcolor="white">
                {isSubmit === true && savingEnable == false && !validationErrors.length ?
                  <Typography variant="body1" gutterBottom className={`${errorStyle === false ? classes.error_message : classes.error_message1}`}>
                    <b style={{ textDecoration: 'underline' }}>Note: </b>
                    {!state.busy ? 'The following fields are required:' : null}
                    {requireFields.map((field) => (<> &nbsp;{`${field.display_name}`},  </>))}
                  </Typography> : null}
              </Box>
              <Box bgcolor="white" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 12 }}>
                  {errorMessage.map((field) => (<> &nbsp;{`${field.errorMessage !== null ? field.errorMessage : null}`}{field.errorMessage !== null ? ',' : ''}  </>))}
                </Typography>
              </Box>
            </Grid>
          </Grid >
          <TableContainer component={Paper} className={classes.tablebody}>
            {templateList.length > 0 ?
              <Table stickyHeader aria-label="collapsible table">
                <TableHead className={classes.tableHead} >
                  <TableRow key='table-header' className={classes.tableHead1}>
                    {templateList.map((row) =>
                    (<StyledTableCell className={`${classes.tableRow1}`}
                      key={row.display_name + 'header'} >
                      <span className={classes.textWrap}>
                        {row.display_name.trim()}
                      </span>
                    </StyledTableCell>
                    ))}
                    <StyledTableCell className={classes.tableRow1}>
                      Delete
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {placementAccountList.map((r, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      {templateList.map((row) =>
                      (<StyledTableCell key={row.display_name + index} className={classes.tableRow}>
                        {row.data_type == 'date' ?
                          <TextField id={row.field_name + index} placeholder={row.display_name.replace('(*)', '')}
                            size="small"
                            name={row.field_name + index} type={getTextFieldType(row.data_type)}
                            className={classes.textField1} InputLabelProps={{ shrink: true }}
                            value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                            onChange={(e) => handleChange(row?.field_name, index, e, row?.data_type)}
                            key={row.display_name + index}
                            inputProps={{ maxLength: row?.field_length, max: (moment(new Date()).format('YYYY-MM-DD')) }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white" }}
                          />
                          : row.data_type == 'money' ?
                            <CurrencyInput
                              className={classes.moneyField}
                              intlConfig={{ locale: 'en-US', currency: 'USD' }}
                              id={row.field_name + index}
                              key={row.display_name + index}
                              value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                              allowNegativeValue={false}
                              placeholder={row.display_name.replace('(*)', '')}
                              maxLength={row?.field_length}
                              prefix='$'
                              disableAbbreviations={true}
                              decimalsLimit={2}
                              decimalScale={2}
                              onValueChange={(value, name) => {
                                handleChange(row?.field_name, index, value, row?.data_type)
                              }}
                              onKeyPress={preventMinus}
                              autoComplete='off'
                              style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white" }}
                            />
                            :
                            <TextField id={row.field_name + index} placeholder={row.display_name.replace('(*)', '')}
                              size="small"
                              name={row.field_name + index} type={getTextFieldType(row.data_type)}
                              className={classes.textField} InputLabelProps={{ shrink: true }}
                              key={row.display_name + index}
                              value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                              onChange={(e) => handleChange(row.field_name, index, e, row?.data_type)}
                              inputProps={{ maxLength: row?.field_length, max: row?.field_length }}
                              InputProps={{ disableUnderline: true }}
                              style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white", fontSize: 12 }}
                            />
                        }
                      </StyledTableCell>
                      ))}
                      <StyledTableCell className={classes.tableRow}>
                        <IconButton id="FR_DeleteIcon" aria-label="delete" disableRipple={true} disableFocusRipple={true}
                          onClick={() => handleDeleteFilter(index)} className={classes.deleteIconStyle} style={{ padding: '8px' }}>
                          <HighlightOffIcon style={{ color: "red", fontSize: 25 }} />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> :
              <Backdrop className={classes.backdrop} open={true}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
              </Backdrop>
            }
          </TableContainer>
        </Container >
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default withRouter(Fieldnames)