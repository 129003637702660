import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, createStyles, Dialog, DialogActions, DialogTitle, FormControl, FormLabel, Grid, IconButton, InputAdornment, makeStyles, Paper, Select, TextField, Theme, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { Alert, Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// Icons
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import additionalReport from '../../images/ACKReport/additionalcharges.svg';
// Models 
import { IAckClientInfo } from '../../models/IAckReport/IAckClientInfo';
import { IClientPhase } from '../../models/Placements/IClientPhase';
import { IAdditionalReportDownload } from '../../models/Roll/IRollClients';
// Componants
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        createReport: {
            borderRadius: '20px',
            marginTop: 5,
            marginBottom: 5,
            marginRight: 13,
            float: 'right',
            fontSize: 12,
            backgroundColor: 'green',
            '&:hover': {
                background: "green",
                color: "white",
            }
        },
        cancelRepot: {
            borderRadius: '20px',
            marginTop: 5,
            marginBottom: 5,
            marginRight: 13,
            float: 'right',
            fontSize: 12,
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                background: "red",
                color: "white",
            }
        },
        placementTitle: {
            color: 'blue',
            fontSize: 16,
            marginTop: '5px'
        },
        placementTitle1: {
            color: 'blue',
            fontSize: 16,
            marginTop: '10px'
        },
        formControl: {
            alignItems: "left",
            '& .MuiButtonBase-root': {
                padding: 0
            }
        },
        textField: {
            width: 300,
            backgroundColor: 'white',
            float: 'left',
            fontSize: 12,
            borderRadius: '10px',
            marginTop: '1px',
            borderBottom: '3.6px solid blue',
        },
        dropdownClient: {
            width: 300,
            float: 'left',
            backgroundColor: 'white',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            marginTop: '7px',
            borderBottom: '3.6px solid blue',
        },
        NoAccess: {
            color: 'red',
            fontSize: 13
        },
        smallFont: {
            fontSize: 13
        },
        ButtonStyle: {
            borderRadius: 20,
            fontSize: 11,
            marginTop: '3px',
            marginBottom: '3px',
            backgroundColor: 'green',
            "&:hover": {
                backgroundColor: 'green'
            }
        },
        textStyle: {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'black',
            fontSize: 13,
            marginTop: '2px'
        },
        GreenColor: {
            color: 'green',
        },
        textBoxStyle: {
            marginTop: '5px',
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                paddingLeft: '5px',
                paddingLight: '5px',
                fontSize: '10px'
            }
        },
        IconSizeStyle: {
            height: '20px',
            width: '20px'
        },
        ackTextStyle: {
            fontWeight: 'bold',
            marginTop: '4px',
            color: 'white',
            fontSize: 20,
        },
        Title: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center'
        },
        boxBorder: {
            border: '2px solid gray',
            padding: '2px 8px',
            marginTop: '10px',
            borderRadius: '5px',
            maxWidth: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        },
        textBoxStyle2: {
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                fontSize: 10
            }
        },
        scrollMargin: {
            '& div:first-child': {
                marginBottom: '5px',
            },
            '& div:nth-child(3) div': {
                backgroundColor: '#2377e4 !important'
            },
            '& div:nth-child(3)': {
                top: '10px !important',
                bottom: '5px !important'
            },
        },
        valueContainer: {
            "& > :not(:last-child)": {
                marginRight: '5px',
                marginTop: '2px'
            },
            "& > *": {
                marginBottom: '5px',
                marginTop: '2px'
            }
        },
        clientCodeBox: {
            width: 300,
            borderRadius: '10px',
            borderBottom: '3.7px solid blue',
            marginTop: '2px'
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        ClientcheckBoxStyle: {
            padding: '0px'
        },
        CheckBoxBorder: {
            borderTop: '1px solid gray',
        },
        labelStyle: {
            fontWeight: 'bold',
            color: 'green',
            fontSize: 14,
            marginTop: '15px'
        },
        dialogePaper: {
            minWidth: "700px"
        },
        clientStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px'
        },
        createStyle: {
            justifyContent: 'center',
            alignItem: 'center',
            display: 'flex',
            marginTop: '10px'
        },
        buttonborderStyle: {
            marginTop: '6px',
            backgroundColor: '#e7e7fb'
        },
        IconButtonripple: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        image: {
            width: '70px',
            height: '70px',
            textAlign: 'center'
        },
        text: {
            color: "black",
            textAlign: "center",
            fontSize: '16px',
            fontWeight: 'bold',
        },
    })
);

const usePopoverStyles = makeStyles({
    paper: {
        border: '2px solid black'
    }
});

const AckAdditionalReportView: React.FC<{ clients?: any }> = (props) => {
    const theme = useTheme();
    let history = useHistory();
    const classes = useRowStyles();
    const { state } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const popoverClasses = usePopoverStyles();
    const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);

    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [open, setOpen] = useState(false);

    const [StartDate, setStartDate] = useState<any>(new Date().setDate(new Date().getDate() - 1));
    const [EndDate, setEndDate] = useState(new Date());
    const [searchClientCode, setSearchClientCode] = useState("");
    const [confirmedClients, setConfirmedClients] = useState([]);
    const [SelectedClientCode, setSelectedClientCode] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [valClientCode, setValClientcode] = useState(0);
    const [isDisable, setIsDisable] = useState(false);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    useEffect(() => {
        if (SelectedClientCode?.length <= 0 || (moment(StartDate).format("YYYY-MM-DD") > moment(EndDate).format("YYYY-MM-DD"))
            || (SelectedClientCode?.length === 1 && selectedPhase === -1) || (selectedPhase === -1) || (valClientCode > 100)
            || StartDate === null || EndDate === null) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [SelectedClientCode, selectedPhase, StartDate, EndDate, valClientCode])

    const handleClose = () => {
        setOpen(false);
        setCheckAll(false);
        setSelectedClientCode([]);
        setSelectedPhase(-1);
        setStartDate(new Date().setDate(new Date().getDate() - 1));
        setEndDate(new Date());
        setSearchClientCode("");
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    useEffect(() => {
        setValClientcode(SelectedClientCode?.filter(r => r?.client_code?.includes("999"))?.length);
    }, [SelectedClientCode])

    const getAckReportsAsync = () => {
        let GetClients: IAdditionalReportDownload[] = [];
        SelectedClientCode.map((x) => {
            let ClientDetail: IAdditionalReportDownload = { dbT_CLIENT: x?.client_code };
            GetClients.push(ClientDetail);
        });
        history.push('/additionalackbuilder', {
            phaseName: selectedPhase, client_code: GetClients,
            startDate: StartDate, endDate: EndDate
        });
    }

    function onClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone);
        setSelectedPhase(-1);
        (async () => {
            await useFetch<{ placementPhaseResults: IClientPhase[] }>(`Placements/GetPlacementPhase?clt_code=${valueone[0]?.client_code}`).then((clientPhase) => {
                setClientPhase(clientPhase?.data?.['placementPhaseResults']);
            });
        })()
    }

    const handlePhaseChange = (event) => {
        setSelectedPhase(parseInt(event.target.value));
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
    };

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": (state?.userAccessContext.role === 0 || state?.userAccessContext.role === 1) ? false : true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            let NewClientList = confirmedClients.filter((c) => {
                return !SelectedClientCode.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClientCode([...new Set(SelectedClientCode?.concat(NewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        if (confirmedClients?.length > 100) {
            setIsDisable(true);
        } else if (confirmedClients?.length < 100) {
            if (confirmedClients?.length === 1) {
                (async () => {
                    await useFetch<{ placementPhaseResults: IClientPhase[] }>(`Placements/GetPlacementPhase?clt_code=${confirmedClients[0]?.client_code}`).then((clientPhase) => {
                        setClientPhase(clientPhase?.data?.['placementPhaseResults']);
                    });
                })()
            } else if (confirmedClients?.length != 1) {
                let newClientPhaseValues = [];
                const newPhase: IClientPhase = {
                    ...clientPhase, // copy existing properties from the old phase object
                    isContingency: true,
                    isFlatFee: true,
                    istParty: true,
                    template_id: 0
                };
                newClientPhaseValues.push(newPhase)
                setClientPhase(newClientPhaseValues);
            }

        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };


    const handleClickOpen = () => {
        setConfirmedClients([]);
        setLoading(true);
        (async () => {
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trimLeft().trimRight().toLocaleUpperCase(),
                "isAdmin": (state?.userAccessContext.role === 0 || state?.userAccessContext.role === 1) ? false : true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
                setOpen(true);
                setLoading(false);
            }).finally(() => {
                setShowProgressBar(false);
                setCheckAll(false);
                setLoading(false);
            });
        })()
    };

    const handleClear = () => {
        (async () => {
            setCheckAll(false);
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": (state?.userAccessContext.role === 0 || state?.userAccessContext.role === 1) ? false : true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = SelectedClientCode.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClientCode(NewClientList);
            setClientCodeOpen(false);
        }
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const onDelete = (clientcode) => () => {
        setSelectedClientCode((value) => value.filter((v) => v.client_code !== clientcode));
    };

    return (
        <React.Fragment>
            <Box>
                <IconButton id="ackReport" aria-label="ackReport" onClick={(_) => { setOpen(true); handleClickOpen() }} className={classes.IconButtonripple}>
                    <img src={additionalReport} alt="ackReport" className={classes.image} />
                </IconButton>
                <Box>
                    <Typography variant="h6" className={classes.text} onClick={(_) => { setOpen(true); handleClickOpen() }}>
                        Additional Charges Acknowledgment Report
                    </Typography>
                </Box>
            </Box>

            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} PaperProps={{ style: { borderRadius: 10 } }}
                aria-labelledby="responsive-dialog-title" fullWidth={true} classes={{ paper: classes.dialogePaper }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.ackTextStyle}>
                        ADDITIONAL CHARGES ACKNOWLEDGEMENT REPORT
                    </Typography>
                </DialogTitleHeader>
                {moment(StartDate).format("YYYY-MM-DD") > moment(EndDate).format("YYYY-MM-DD") ?
                    <DialogTitle style={{ padding: '5px 22px 0px 25px' }}>
                        <Alert severity="error" className="snackBarStyle1">
                            End date should be greater than the Start date!
                        </Alert>
                    </DialogTitle>
                    : ""}

                {valClientCode > 100 ?
                    <DialogTitle style={{ padding: '5px 22px 0px 25px' }}>
                        <Alert severity="error" className="snackBarStyle1">
                            Please select client codes below 100!
                        </Alert>
                    </DialogTitle>

                    : ""}

                <Grid container spacing={0} style={{ padding: '5px 30px' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                            <b> CHOOSE START DATE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Typography variant="h6" gutterBottom className={classes.placementTitle} style={{ marginLeft: '20px' }}>
                            <b> CHOOSE END DATE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    PopoverProps={{ classes: popoverClasses }}
                                    inputVariant="outlined"
                                    variant="inline"
                                    size="small"
                                    format={DateFormat}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    id="DocUpLog_StartDate"
                                    value={StartDate}
                                    onChange={handleFirstDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    PopoverProps={{ classes: popoverClasses }}
                                    size="small"
                                    disableToolbar
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    format={DateFormat}
                                    className={classes.textField}
                                    margin="normal"
                                    id="DocUpLog_EndDate"
                                    value={EndDate}
                                    style={{ marginLeft: '20px' }}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
                        <FormLabel className={classes.labelStyle}> Selected Client Code : {SelectedClientCode.length}</FormLabel>
                        <Paper className={classes.boxBorder}>
                            <Scrollbars autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={10}
                                autoHeight
                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                autoHeightMin={90}
                                autoHeightMax={90}>
                                {!SelectedClientCode.length ?
                                    <div className={classes.clientStyle}>
                                        <GroupAddIcon style={{ color: '#364F6B', fontSize: 40, textAlign: 'center' }} />
                                        <Typography variant="h6" style={{ fontSize: 15, textAlign: 'center' }}>
                                            <b>Kindly Select Client Codes</b>
                                        </Typography>
                                    </div>
                                    :
                                    <div className={`${classes.valueContainer}`} >
                                        {SelectedClientCode.map((v) => (
                                            <Chip key={v.client_code} color="secondary" size="small" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                        ))}
                                    </div>
                                }

                            </Scrollbars>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom className={classes.placementTitle1}>
                            <b> CHOOSE CLIENT CODE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom className={classes.placementTitle1} style={{ marginLeft: '20px' }}>
                            <b> CHOOSE PHASE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
                            <Box>
                                <Autocomplete
                                    multiple
                                    loading={loading}
                                    filterOptions={(options, state) => options}
                                    className={classes.clientCodeBox}
                                    autoComplete={false}
                                    id="checkboxes-tags-demo"
                                    options={confirmedClients}
                                    classes={{ paper: classes.paper2 }}
                                    disableClearable
                                    value={SelectedClientCode}
                                    open={ClientCodeOpen}
                                    renderTags={() => null}
                                    onChange={onClientCodeSelect}
                                    onOpen={() => {
                                        setClientCodeOpen(true);
                                    }}
                                    onClose={(e: any, reason: AutocompleteCloseReason) => {
                                        if (reason === "toggleInput") {
                                            setClientCodeOpen(false);
                                        }
                                    }}
                                    getOptionDisabled={(option) => option.client_code}
                                    getOptionLabel={(option) => option.client_code}
                                    getOptionSelected={(option, value) => option.client_code == value.client_code}
                                    renderOption={(option, { selected }) => (
                                        <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '5px' }}>
                                            {option?.client_code + "--" + option?.client_name}
                                        </span>
                                    )}
                                    ListboxProps={
                                        {
                                            style: {
                                                maxHeight: '180px'
                                            }
                                        }
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth size="small"
                                            value={searchClientCode} variant="outlined"
                                            type='text' placeholder="Search Clients"
                                            onChange={(e) => { setSearchClientCode(e.target.value); }}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') { handleSearchClient(event); handleClickOpen(); setClientCodeOpen(true); }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="primary" size={15} /> : null}
                                                        {params.InputProps.endAdornment}
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={(_) => { handleClickOpen(); setClientCodeOpen(true); }}>
                                                                <SearchIcon className={classes.IconSizeStyle} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                        <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                            <Box className={classes.ClientcheckBoxStyle} width="50%">
                                                                <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                    checked={checkAll}
                                                                    onChange={checkAllChange}
                                                                    id="check-all"
                                                                />
                                                                <span style={{ fontSize: 12 }}><b>SELECT ALL</b></span>
                                                            </Box>
                                                            <Box className={classes.ClientcheckBoxStyle} width="50%">
                                                                <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                    checked={deselectClient}
                                                                    onChange={(e) => { DeselectAllChange(e); }}
                                                                    id="check-all"
                                                                />
                                                                <span style={{ fontSize: 12 }}><b>DESELECT ALL</b></span>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                ),
                                            }}
                                            className={classes.textBoxStyle}
                                        />
                                    )}
                                />
                            </Box>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl id="Select_Phase" variant="outlined" size="small" disabled={SelectedClientCode?.length <= 0} style={{ marginLeft: '20px' }}>
                            <Select native value={selectedPhase} onChange={handlePhaseChange} className={classes.dropdownClient}>
                                <option value={-1} className={classes.smallFont}>Select Phase</option>
                                {SelectedClientCode?.length !== 1 ?
                                    <>
                                        state.userAccessContext?.multiplePlacementAccess ? <option value={4} className={classes.smallFont}>Multi Phase</option> : null
                                        <option value={1} className={classes.smallFont}>1st Party</option>
                                        <option value={2} className={classes.smallFont}>Flat Fee</option>
                                        <option value={3} className={classes.smallFont}>Contingency</option>
                                    </>
                                    : clientPhase.map((phase) => {
                                        return (
                                            <>
                                                state.userAccessContext?.multiplePlacementAccess ? <option value={4} className={classes.smallFont}>Multi Phase</option> : null
                                                {phase?.istParty ? <option value={1} className={classes.smallFont}>1st Party</option> : <option value={-1} className={classes.NoAccess}>1st Party</option>}
                                                {phase?.isFlatFee ? <option value={2} className={classes.smallFont}>Flat Fee</option> : <option value={-1} className={classes.NoAccess}>Flat Fee</option>}
                                                {phase?.isContingency ? <option value={3} className={classes.smallFont}>Contingency</option> : <option value={-1} className={classes.NoAccess}>Contingency</option>}
                                            </>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonborderStyle}>
                    <Button autoFocus color="primary" size="small" variant="contained"
                        disabled={isDisable}
                        onClick={() => { getAckReportsAsync(); }} startIcon={<CreateIcon />} className={classes.createReport}>
                        Generate Report
                    </Button>
                    <Button autoFocus color="primary" size="small" variant="contained" onClick={(e) => { handleClose(); }}
                        startIcon={<CancelIcon />} className={classes.cancelRepot}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog >
        </React.Fragment >
    )
}

export default AckAdditionalReportView