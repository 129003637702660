import {
  Backdrop, Box, Button, Grid, Paper, Snackbar, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from "react";
import PuffLoader from 'react-spinners/PuffLoader';
// models
import { IAllTemplateList } from "../../../models/Admin/ClientPlacements/IAllTemplateList";
import { useFetch } from "../../../utils/apiHelper";
// Components
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer';
import StyledCell from "../../GlobalStyles/StyledCell";
import { useStyles } from './ClientPlacementOptionsCss';
import CreateNewTemplate from "./CreateNewTemplate";
import DeleteTemplate from "./DeleteTemplate";
import DocumentPDFViewer from './DocumentPreview/DocumentPDFViewer';
import DownloadFiles from './DocumentPreview/DownloadFiles';
import EditTemplate from "./EditTemplate";
import StyledTableRow from '../../GlobalStyles/StyledTableRow';

export default function GA_EditTemplate() {
  const classes = useStyles();
  const [TemplateList, setTemplateList] = useState<IAllTemplateList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsUpdate(false);
    setChecked(false);
    setIsDelete(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event, page) => {
    setShowProgressBar(true);
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSave = () => {
    getTemplateAsync();
    setChecked(true);
  };

  const handleUpdate = () => {
    getTemplateAsync();
    setIsUpdate(true);
  };

  const handleDeleteSuccess = () => {
    getTemplateAsync();
    setIsDelete(true);
  };

  useEffect(() => {
    setShowProgressBar(true);
    (async () => {
      await useFetch<IAllTemplateList[]>("ClientPlacement/GetAllTemplate").then((GetAllTemplateList) => {
        setTemplateList(GetAllTemplateList?.data);
        setTotalRecords(GetAllTemplateList?.data?.length);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })().finally(() => {
      setShowProgressBar(false);
    });
  }, [rowsPerPage, page])

  const getTemplateAsync = () => {
    setShowProgressBar(true);
    (async () => {
      await useFetch<IAllTemplateList[]>("ClientPlacement/GetAllTemplate").then((GetAllTemplateList) => {
        setTemplateList(GetAllTemplateList?.data);
        setTotalRecords(GetAllTemplateList?.data?.length);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })().finally(() => {
      setShowProgressBar(false);
    });
  }

  return (
    <React.Fragment>
      <div className={classes.clientTemp}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom className={`${classes.heading} ${"headertitle"}`}>
                <b>CLIENT PLACEMENT OPTIONS</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CreateNewTemplate onSave={handleSave} />
              <Button id="CreateTemplate_btn" size="small" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} variant="contained" component={Link}
                to="/clientplacementoptions" color="primary" className={classes.btnManTemp} style={{ marginRight: '15px' }}>BACK</Button>
            </Grid>
          </Grid>
          <Grid component={Paper} item xs={12}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <TableHead >
                  <TableRow className={classes.tableHeaderStyle}>
                    <TableCell className={classes.tableCellColor}>
                      TEMPLATE NAME
                    </TableCell>
                    <TableCell className={classes.tableCellColor} >
                      AGENCY LOGO
                    </TableCell>
                    <TableCell className={classes.tableCellColor} >
                      PLACEMENT INSTRUCTIONS
                    </TableCell>
                    <TableCell className={classes.tableCellColor} align="center">
                      PDF PREVIEW
                    </TableCell>
                    <TableCell className={classes.tableCellColor} align="center">
                      ACTIONS
                    </TableCell>
                    <TableCell className={classes.tableCellColor} align="center">
                      DELETE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TemplateList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <StyledTableRow>
                        <StyledCell className={classes.tableData} align="left" scope="row">
                          <Typography variant="subtitle1" className={classes.subHeading}>
                            {row?.name}
                          </Typography>
                        </StyledCell>
                        <StyledCell className={classes.tableData} >
                          <DownloadFiles fileNameUri={row?.fileName_Img} fileUrl={row?.imageURI} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} >
                          <DownloadFiles fileNameUri={row?.fileName_PDF} fileUrl={row?.pdfURI} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <DocumentPDFViewer row={row} enable={row?.fileName_PDF?.split('.')?.pop() === 'pdf' ? true : row?.fileName_PDF?.split('.')?.pop() === 'PDF' ? true : false} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <EditTemplate templateRow={row} onUpdate={handleUpdate} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <DeleteTemplate templateRow={row} onDelete={handleDeleteSuccess} />
                        </StyledCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={0}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar open={isUpdate} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Template Updated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar className="snackBarStyle"
          open={checked} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Template has been created successfully!
          </Alert>
        </Snackbar>

        <Snackbar className="snackBarStyle" open={isDelete} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            Template Deleted Successfully.
          </Alert>
        </Snackbar>
      </div>

    </React.Fragment >
  )
}

