import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Snackbar, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import InquiryDetail from './DvInquiryDetail';
import useStyles from '../NCCcss/NotiCenterCss';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Columns } from '../../../models/Inquiry/config';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { useGlobalState } from '../../../store/GlobalStore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFetch, usePost } from '../../../utils/apiHelper';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { disputeValidation } from '../../../constants/Constant';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from '../../../models/NotificationCenter/INotificationCenter';

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const DvExpandableRow: React.FC<{ dv: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onHideAll: () => void, selectedDBTNumber: string, IsInvited: boolean }> = (props) => {
    const { dv } = props
    const theme = useTheme();
    const classes = useStyles()
    const componentRef = useRef();
    const { state } = useGlobalState();
    const fileUpload = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const prevCount = usePrevious(props.selectedDBTNumber);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const getAccountdetails = () => {
        (async () => {
            await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + dv.extraData_1).then((r) => {
                setDebtors(r.data);
            });
        })()
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setDisableButton(true);
        if ((event.target.files[0]?.type === 'image/jpeg' || event.target.files[0]?.type === 'application/pdf') && event.target.files[0]?.size > 0) {
            event.target.files && setSelectedFile(event.target.files[0])
        } else if (event.target.files[0]?.size <= 0) {
            setAlertOpen(true);
        } else {
            setAlertOpen(true);
        }
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(dv.extraData_1);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [dv.extraData_1, prevCount, props.selectedDBTNumber])

    const onSubmit = () => {
        if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'application/pdf') {
            (async () => {
                const formData = new FormData();
                const currentDate = moment(new Date(Date.now())).format('MMDDYY');
                const fileExtension = selectedFile?.name?.split('.')?.pop();
                const fileName = `${props?.dv?.extraData_1}-${currentDate}${props?.dv?.clT_Code}-BU.${fileExtension}`;
                formData.append("FormFile", selectedFile);
                formData.append("FileName", fileName);
                formData.append("FileSize", selectedFile?.size.toString());
                formData.append("UserId", state.userAccessContext?.id.toString());
                formData.append("CategoryId", "4");
                await usePost<any>("WebViewFiles/InsertBackUpFiles", formData).finally(() => {
                    setOpen(true);
                    hidenotificationForAllUsers();
                    setDisableButton(false);
                });
                UploadBackUpFile(selectedFile);
                if ((props?.IsInvited) && (state?.userAccessContext?.role >= 2)) {
                    let sendEmail = {
                        "debtorNumber": props?.dv?.extraData_1,
                        "clientId": props?.dv?.clT_Code,
                        "clientNo": props?.dv?.extraData_2,
                        "userName": state?.userAccessContext?.userName,
                        "fileName": fileName
                    }
                    await usePost<any>("SendDocumentEmail", sendEmail);
                }
            })()
        }
        else {
            setAlertOpen(true);
        }
    }

    const UploadBackUpFile = (selectedFile: File) => {
        if (selectedFile) {
            if (selectedFile?.type === 'image/jpeg' || selectedFile?.type === 'application/pdf') {
                (async () => {
                    const formData = new FormData();
                    let dateTime = new Date(Date.now());
                    const currentDate = moment(dateTime).format('MMDDYY');
                    const fileExtension = selectedFile?.name?.split('.')?.pop();
                    const fileName = `${props?.dv?.extraData_1}-${currentDate}${props?.dv?.clT_Code}-BU.${fileExtension}`;
                    formData.append("FormFile", selectedFile);
                    formData.append("ClientId", props?.dv?.clT_Code);
                    formData.append("file_name", fileName);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    formData.append("rid", props?.dv?.extraData_1)
                    formData.append("upload_date", dateTime.toString());
                    formData.append("file_size", selectedFile?.size.toString());
                    formData.append("ContainerName", disputeValidation);

                    await usePost<any>("File/FileInsert", formData);
                })()
            }
        }
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": dv?.notificationid,
                "userid": state.userAccessContext?.id,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
            props.onHideAll();
        })()
    }

    const setYieldLock = () => {
        (async () => {
            await useFetch<any>(`InquiryYieldLock?debtorNumber=${dv.extraData_1}&userId=${state.userAccessContext?.id.toString()}`).then((YieldLock) => {
                setGetActivestatus(YieldLock.data);
            });
        })()
    }

    const setYieldRelease = () => {
        (async () => {
            let YieldRelease = {
                "debtorNumber": GetDebtorNo,
                "userId": state.userAccessContext?.id
            }
            await usePost("InquiryReleaseLock", YieldRelease);
        })()
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                            Invalid File Type. Please select a PDF or JPG file.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); }} className={classes.yesButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <TableRow>
                <TableCell className={classes.tablecellPadding} >
                    <span className={classes.paymentText}>{dv.extraData_1}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding} >
                    <span className={classes.paymentText}>{dv.extraData_2}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{dv.clT_Code}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{dv.clT_Name} {dv.clT_Name_2}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding} >
                    <span className={classes.paymentText}>{moment(dv.createdDate).format(state.GlobalUtils?.settingValue)}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding} >
                    <Typography variant="subtitle1" title={selectedFile ? selectedFile.name : ""} className={classes.Textstyle}>
                        <b>{selectedFile ? selectedFile.name : ""}</b>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tablecellPadding} width="20%">
                    <Button className={classes.chooseButton} component="label" size="small"
                        variant="contained" startIcon={<CloudUploadIcon />}>
                        Choose File
                        <input
                            hidden
                            name="file"
                            ref={fileUpload}
                            id="NF_SelectFile"
                            multiple type="file"
                            onChange={changeHandler}
                            accept="image/jpeg,image/jpg,application/pdf"
                        />
                    </Button>
                    <Button variant="contained" color="primary" size="small" startIcon={<CheckCircleIcon />} className={classes.submitButton}
                        onClick={(_) => { onSubmit(); }} disabled={!selectedFile || !disableButton} >
                        Submit
                    </Button>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <IconButton style={{ padding: '5px' }} onClick={(_) => { props.onClick(dv.extraData_1); getAccountdetails(); (dv.extraData_1 !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                        {props.selected === true && debtors.length != 0 ?
                            <LightTooltip title="View Account Details">
                                <ArrowForwardIosIcon className={classes.forwardArrowMinus} />
                            </LightTooltip>
                            : <LightTooltip title="View Account Details">
                                <ArrowForwardIosIcon className={classes.forwardArrowPlus} />
                            </LightTooltip>
                        }
                    </IconButton>

                </TableCell>
            </TableRow>

            {debtors.map((sr) => {
                return (
                    <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                        <TableCell scope="row" colSpan={Columns.length}>
                            <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                <InquiryDetail dv={dv} inquiryDetail={sr} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                );
            })}

            <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    File Submitted Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default DvExpandableRow