import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: '0px 12px',
            },
        },
        rootHidden: {
            display: "none"
        },
        button: {
            borderRadius: 7,
            color: 'white',
            width: '135px',

        },
        inputField: {
            width: '90%',
            height: '38px',
            fontSize: 14,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold',
            float: 'right',
        },
        typographyTitleStyle: {
            textAlign: 'right',
            color: '#800080',
            fontSize: '16px',
            marginBottom: '14px',
            whiteSpace: 'nowrap',
        },
        currentamountStyle: {
            textAlign: 'right',
            fontSize: '18px',
            marginBottom: '15px',
        },
        currentamountStyleColor: {
            color: 'green',
        },
        typographyStyleColor: {
            color: 'green',
        },
        updateamountStyleContent: {
            textAlign: 'right',
            fontSize: '18px',
            marginBottom: '15px',
        },
        buttonStyle: {
            borderRadius: 20,
            '& .MuiButton-label': {
                fontSize: 11,
            },
        },
        typographyStyle: {
            marginTop: '-7px',
            color: 'green',
        },
        textStyle: {
            fontSize: '16px',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            bottom: '0.1cm',
            fontSize: 12,
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            fontSize: 12,
            bottom: '0.1cm',
            '&:hover': {
                background: "red",
            }
        },
        checkBoxStyle: {
            fontWeight: 'bold'
        },
        typographyFontStyle: {
            fontSize: '15px'
        },
        displayFlex: {
            display: 'flex',
        },
        typoFontSize: {
            fontSize: '14px',
        },
        gridFlex: {
            flex: '1',
            marginRight: '5px',
        },
        spanErrorSpacing: {
            marginLeft: '5px',
        },
        dialogTitle: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        dialogContent: {
            color: "black",
            fontSize: 16
        },
        colorRed: {
            color: 'red',
            fontSize: 14,
            marginTop: '-15px'
        },
        buttonBgStyle: {
            background: "green",
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        btnSpacing: {
            marginBottom: '5px',
            '& .MuiButton-label': {
                fontSize: 11,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            }
        },
        rootSubmit: {
            border: "0.5px solid black",
            borderRadius: 8,
            width: "60%",
            height: "auto",
            margin: "0px",
            padding: '0px 5px !important'
        },
        submitDiv: {
            marginLeft: "5px"
        },
        textSpan: {
            fontSize: 11,
        },
        textSpan2: {
            fontSize: 13,
        },
        iconBtnStyle: {
            padding: '0px',
            margin: '0px 5px',
        },
        btnRollSpacing: {
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        circleStyle: {
            color: 'white',
            height: "20px",
            width: "20px",
            marginLeft: '10px'
        },
        image3: {
            width: '30px',
            height: '30px',
            color: '#007FFF'
        },
        documentname: {
            marginLeft: "10px",
            color: "black",
            fontSize: 15
        },
        Visible: {
            fontSize: 25,
            color: '#1E90FF'
        },
        NonVisible: {
            fontSize: 25,
            color: '#AEAEAE',
        },
        btnGridSpacing: {
            marginTop: '15px',
        },
        btnBoxSpacing: {
            paddingLeft: '0px !important',
            '& .MuiBox-root': {
                padding: '0px 22px 0px 0px !important'
            },
        },
        gridLastSpacing: {
            marginLeft: '40px',
        },
        snackbarStyle: {
            marginTop: '-20px',
            '& .MuiAlert-root': {
                padding: '2px 13px !important',
                fontSize: '13px'
            }
        },
        imageStyle: {
            left: '227px',
            width: '50px',
            height: '0px',
            position: 'relative',
            bottom: '115px'
        },
        buttonStyle1: {
            borderRadius: 20,
            // minWidth: '100px',
            fontSize: 11,
            marginTop: '5px'
        },
        bottomright: {
            width: '70px',
            height: '70px',
        },
        snackBarStyle: {
            marginTop: '-18px',
        },
        alertBoxStyle: {
            fontSize: 16,
        },
        typographyStyle4: {
            marginTop: '15px',
            height: "10px"
        },
        earlyrollbtn: {
            paddingBottom: "10px",
            paddingTop: "10px",
            width: 165,
            borderRadius: 10,
            marginTop: "-40px",
            fontSize: 13,
            fontWeight: 'bold',
            marginLeft: "-30px",
            color: "white",
            position: 'relative',
        },
        dialogePaper: {
            minWidth: "700px"
        },
        titleHeaderOverride: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        },
        dialogboxPaper: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            minWidth: "700px"
        },
        textfield1: {
            textAlign: 'center',
            color: 'black',
            fontSize: 16
        },
        dialogaction: {
            gap: '3%',
            marginBottom: '5px'
        },
        ImageWarning: {
            width: '50px',
            height: '50px',
            marginTop: '5px',
        },
        confirmbtn: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            margin: '5px',
            fontSize: 12,
            '&:hover': {
                background: "green",
            }
        },
        cancelbtn: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            fontSize: 12,
            margin: '5px',
            '&:hover': {
                background: "red",
            }
        },
    }),
)

export default useStyles