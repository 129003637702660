import {
  Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, Backdrop,
  Container, TableContainer, TableHead, TableRow, CssBaseline, Typography, useTheme, LinearProgress, LinearProgressProps,
} from '@material-ui/core';
// Models and Components
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { useFetch, usePost } from '../../../utils/apiHelper';
import Footer from '../../../components/Footer/Footer';
import * as constant from '../../../constants/Constant';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import errormsg from '../../../images/WarningImage/errormsg.svg';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import VideoPlayer from '../../../components/YouTubePlayer/VideoPlayer';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { IClientCodeMap } from '../../../models/Placements/IClientCodeMapped';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { IFileDetails } from '../../../models/Files/IFileDetails';
import _ from 'lodash';
import { IDateFormatDetails } from '../../../models/Placements/IFixedDateFormat';
import DownloadRejectedAccounts from './DownloadRejectedAccounts';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 600,
    minHeight: 600,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 250px)',
      minHeight: 'calc( 100vh - 250px)',
    }
  },
  tableLayout: {
    tableLayout: 'auto',
  },
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    maxWidth: "100vw",
    maxHeight: 'calc( 100vh - 130px)',
    minHeight: 'calc( 100vh - 130px)',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    padding: '5px'
  },
  container1: {
    maxWidth: "100%",
    maxHeight: 'calc( 100vh - 130px)',
    minHeight: 'calc( 100vh - 130px)',
    alignItems: "center",
    borderRadius: 10,
    display: 'fixed',
    backgroundColor: 'white',
    padding: '5px'
  },
  errorContainer: {
    maxWidth: "850px",
    marginTop: '20px',
    minHeight: 450,
    borderRadius: 10,
    backgroundColor: 'white',
    border: '2px solid gray',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
    float: 'right'
  },
  backbutton: {
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
    float: 'right',
    marginRight: 10
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    color: 'red',
  },
  div: {
    margin: "0px 10px",
    paddingTop: '4px',
  },
  grid: {
    padding: '8px'
  },
  smallFont: {
    fontSize: '10px'
  },
  progressBar: {
    height: '6px'
  },
  title: {
    alignContent: "center",
    marginTop: '10px'
  },
  tableHead: {
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableLayout1: {
    marginBottom: '1cm'
  },
  buttonbox: {
    float: 'right',
    padding: '5px'
  },
  tableBody: {
    border: '1px solid #364F6B',
  },
  tableBody1: {
    border: '1px solid #364F6B',
    fontSize: 12,
    padding: '8px',
    whiteSpace: "nowrap",
    width: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tablebody: {
    maxHeight: 'calc( 100vh - 200px)',
    backgroundColor: 'whitesmoke',
  },
  textWrap: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "80%",
    overflow: 'hidden',
    '&:hover': {
      overflow: 'visible',
      transition: '1s',
    }
  },
  TableCellStyle: {
    color: "white",
    fontSize: 12,
    backgroundColor: '#364F6B',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: '7px',
    textIndent: '5px'
  },
  countMsg: {
    alignContent: "left",
    display: 'flex',
    marginLeft: '10px',
    marginTop: '10px',
    color: '#945a56',
    fontWeight: 900,
    fontSize: 16
  },
  LinearBar: {
    '&.MuiLinearProgress-root': {
      height: '10px'
    },
  },
}))

function CSVPreviewData(props) {
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();
  const [isPreview, setIsPreview] = useState(true);
  let placementMappingAccountList: IPlacementAccount[] = props.location.state.placementMappingAccountList;
  let clientCodeMappingList: IClientCodeMap[] = (props.location?.state?.clientCodeMappingList);
  let mappingValue1STP = (isPreview === false ? "" : props.location?.state?.mappingValue1STP);
  let mappingValuePrec = (isPreview === false ? "" : props.location?.state?.mappingValuePrec);
  let mappingValueCont = (isPreview === false ? "" : props.location?.state?.mappingValueCont);
  let placementAccountList: IPlacementAccount[] = props.location.state.placementAccountList;
  let placementAccountCSVData: string[][] = props.location?.state?.placementAccountCSVData;
  let feeChecked = (isPreview === false ? false : props.location?.state?.feeChecked);
  let checkCont = (isPreview === false ? false : props.location?.state?.checkCont);
  let check1STP = (isPreview === false ? false : props.location?.state?.check1STP);
  let checkPrec = (isPreview === false ? false : props.location?.state?.checkPrec);
  let finalDateFormat: IDateFormatDetails = props?.location?.state?.finalDateFormat;
  let mappedClientCodeValue: string = props.location.state.mappedClientCodeValue;
  let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
  let filteredHeaderList: string[] = props.location?.state?.filteredHeaderList;
  let placementList: IPlacementAccount[] = props.location.state.placementList;
  let selectedClients: IClientList[] = props.location.state.selectedClients;
  let filteredCSVData: string[][] = props.location?.state?.filteredCSVData;
  let templateList: ITemplateField[] = props.location.state.templateList;

  let clientList: IClientList[] = props.location.state.clientList;
  let mBalanceChecked = props.location?.state?.mBalanceChecked;
  let getStates: string[] = props.location?.state?.stateCodes;
  let mBalanceValue = (props.location?.state?.mBalanceValue);
  let phaseNumber: number = props.location.state.phaseNumber;
  let uploadedFile: any = props.location.state.uploadedFile;
  let clientCode: string = props.location.state.clientCode;
  let ignoreRowNumber = props.location?.state?.ignoreRows;
  let futureVal = props.location?.state?.futureVal;
  let feeValue = props.location?.state?.feeValue;
  let FileId: number = props?.location?.state?.FileId;
  let fileName: string = props?.location?.state?.fileName;
  let fileSize: string = props?.location?.state?.fileSize;
  let blobUri: string = props?.location?.state?.blobUri;
  let isMappingTemplateSelected: boolean = props?.location?.state?.isMappingTemplateSelected;
  const [phase, setPhase] = useState<string>('');
  let IsCSVUpload = true;
  const video = '3L6IgGjIPwc';
  const title = 'A.R.M. WebView: Placing Accounts with CSV File';
  const [subFId, setSubFId] = useState(0);
  const [progress, setProgress] = useState(10);
  const theme = useTheme();
  const [isAccountSubmited, setIsAccountSubmited] = useState<Boolean>(false);
  let PlacementRejectedAccountsList = [];
  let csvUrl: string = props?.location?.state?.RuleRejectedAccounts;

  function getCellData(cellValue: string, rowIndex: number, columnIndex: number): string {
    if (filteredHeaderList.findIndex(x => x == "phase")) {
      if (filteredHeaderList.findIndex(x => x == "phase") == columnIndex) {
        return placementAccountList[rowIndex].phase;
      }
    }
    if (filteredHeaderList.findIndex(x => x == "client_code")) {
      if (filteredHeaderList.findIndex(x => x == "client_code") == columnIndex) {
        return placementAccountList[rowIndex].client_code;
      }
    }
    if ((placementAccountList[rowIndex]?.phone_no !== null || placementAccountList[rowIndex]?.phone_no !== "") && (placementAccountList[rowIndex]?.phone_no === filteredCSVData[rowIndex][columnIndex])) {
      let PhoneValue = placementAccountList[rowIndex]?.phone_no?.replace(constant.phoneRegexVal, "");
      placementAccountList[rowIndex].phone_no = PhoneValue?.substring(0, 10);
      filteredCSVData[rowIndex][columnIndex] = placementAccountList[rowIndex]?.phone_no;
      return filteredCSVData[rowIndex][columnIndex];
    }
    return cellValue;
  }

  useEffect(() => {
    if (phaseNumber === 1) {
      setPhase(constant.FIRSTSTP);
      setSubFId(4);
    } else if (phaseNumber === 2) {
      setPhase(constant.PREC);
      setSubFId(5);
    } else if (phaseNumber === 3) {
      setPhase(constant.CONT);
      setSubFId(6);
    } else {
      setPhase("MULP");
      setSubFId(11);
    }
  }, [phaseNumber]);

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "MULP";
    }
  }

  const splitConditions = /[-/\s]+/;
  // Get the date values based on the user identified date format
  function findValidDateFormat(dateString, dateFormatValues) {
    let month;
    let day;
    let year;
    let finalYear = 2000;
    const dateParts = dateString?.trim()?.split(splitConditions);
    if (dateFormatValues === "MM/DD/YYYY") {
      // Extract month, day, and year from the input string
      month = dateParts[0];
      day = dateParts[1];
      year = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[2]);
      }
    }
    if (dateFormatValues === "DD/MM/YYYY") {
      // Extract day, month, and year from the input string
      day = dateParts[0];
      month = dateParts[1];
      year = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[2]);
      }
    }
    if (dateFormatValues === "YYYY/MM/DD") {
      // Extract year, month, and day from the input string
      year = dateParts[0];
      month = dateParts[1];
      day = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[0]);
      }
    }
    if (dateFormatValues === "YYYY/DD/MM") {
      // Extract year, day and month from the input string
      year = dateParts[0];
      day = dateParts[1];
      month = dateParts[2];
      if (year?.length === 2) {
        year = finalYear + Number(dateParts[0]);
      }
    }
    return `${year}/${month}/${day}`;
  }

  // Function to Convert the Date into "YYYY/DD/MM"
  function convertDateFormat(dateString, dateColumnName) {
    let finalDateValue = "";
    if (dateColumnName === constant.delinquency_date) {
      finalDateValue = findValidDateFormat(dateString, finalDateFormat?.delinquency_date);
    }
    if (dateColumnName === constant.Itemization_Date) {
      finalDateValue = findValidDateFormat(dateString, finalDateFormat?.Itemization_Date);
    }
    if (dateColumnName === constant.service_date) {
      finalDateValue = findValidDateFormat(dateString, finalDateFormat?.service_date);
    }
    if (dateColumnName === constant.birth_date) {
      finalDateValue = findValidDateFormat(dateString, finalDateFormat?.birth_date);
    }
    if (dateColumnName === constant.Codebtor_BirthDate) {
      finalDateValue = findValidDateFormat(dateString, finalDateFormat?.Codebtor_BirthDate);
    }
    // Return the date in YYYY/MM/DD format
    return finalDateValue;
  }

  async function handleSubmitAccount() {
    placementAccountList.map(x => {
      x.file_name = `${(FileId !== undefined) ? fileName : uploadedFile?.name}`;
      //Date Fields
      (x.delinquency_date !== null && x.delinquency_date?.trim() !== "") ? x.delinquency_date = convertDateFormat(x?.delinquency_date?.trim(), constant.delinquency_date) : x.delinquency_date = null;
      (x.Itemization_Date !== null && x.Itemization_Date?.trim() !== "") ? x.Itemization_Date = convertDateFormat(x?.Itemization_Date?.trim(), constant.Itemization_Date) : x.Itemization_Date = null;
      (x.service_date !== null && x.service_date?.trim() !== "") ? x.service_date = convertDateFormat(x?.service_date?.trim(), constant.service_date) : x.service_date = null;
      (x.last_payment_date !== null && x.last_payment_date?.trim() !== "") ? x.last_payment_date = convertDateFormat(x?.last_payment_date?.trim(), constant.last_payment_date) : x.last_payment_date = null;
      (x.Codebtor_BirthDate !== null && x.Codebtor_BirthDate?.trim() !== "") ? x.Codebtor_BirthDate = convertDateFormat(x?.Codebtor_BirthDate?.trim(), constant.Codebtor_BirthDate) : x.Codebtor_BirthDate = null;
      (x.birth_date !== null && x.birth_date?.trim() !== "") ? x.birth_date = convertDateFormat(x?.birth_date?.trim(), constant.birth_date) : x.birth_date = null;

      // Balance Fields
      (x.total_balance?.toString()?.includes("$") ? x.total_balance = Number(x.total_balance?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Balance_owed_on_itemization_date?.toString()?.includes("$") ? x.Balance_owed_on_itemization_date = Number(x.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Interest_added_since_itemization_date?.toString()?.includes("$") ? x.Interest_added_since_itemization_date = Number(x.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Fees_added_since_itemization_date?.toString()?.includes("$") ? x.Fees_added_since_itemization_date = Number(x.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Credits_applied_since_itemization_date?.toString()?.includes("$") ? x.Credits_applied_since_itemization_date = Number(x.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Payments_applied_since_itemization_date?.toString()?.includes("$") ? x.Payments_applied_since_itemization_date = Number(x.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.Payments_Credits_applied_since_itemization_date?.toString()?.includes("$") ? x.Payments_Credits_applied_since_itemization_date = Number(x.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, "")) : null);
      (x.current_balance?.toString()?.includes("$") ? x.current_balance = Number(x.current_balance?.toString()?.replace(/\$|,/g, "")) : null);
      (x.past_due_balance?.toString()?.includes("$") ? x.past_due_balance = Number(x.past_due_balance?.toString()?.replace(/\$|,/g, "")) : null);
      (x.equipment_charges?.toString()?.includes("$") ? x.equipment_charges = Number(x.equipment_charges?.toString()?.replace(/\$|,/g, "")) : null);
      (x?.contract_charges?.toString()?.includes("$") ? x.contract_charges = Number(x.contract_charges?.toString()?.replace(/\$|,/g, "")) : null);
      (x?.phone_no !== null || x?.phone_no !== "" ? x?.phone_no?.replace(constant.phoneRegexVal, "")?.substring(0, 10) : x?.phone_no);
      (x.state = x?.state?.trim());
      (x.Codebtor_State = x?.Codebtor_State?.trim())

      if (+phaseNumber != 4) { x.phase = getPhaseString(phaseNumber); }
      if (clientCode != "-2") { x.client_code = clientCode; }
    });

    try {
      dispatch({ type: GlobalStateAction.Busy });
      await usePost(`Placements/ClientUpload`, placementAccountList).then((response) => {
        if (response.status !== 202) {
          dispatch({ type: GlobalStateAction.Error, error: response.statusText });
        }
        UploadFile();
      });
      history.push(`/finish`, {
        clientCode: clientCode, clientList: clientList, selectedHeaderList: selectedHeaderList,
        feeValue: feeValue, mBalanceValue: Number(mBalanceValue), ignoreRows: ignoreRowNumber,
        isCSVUpload: IsCSVUpload, uploadedFile: uploadedFile, phaseNumber: phase, clientCodeMappingList: clientCodeMappingList, finalDateFormat: finalDateFormat,
        PlacementFaildRecords: csvUrl, placementAccountList: placementAccountList, PlacementRejectedAccountsList: PlacementRejectedAccountsList, placementAccountCSVData: placementAccountCSVData,
        templateList: templateList, futureVal: futureVal, placementMappingAccountList: placementMappingAccountList
      });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Idle });
      dispatch({ type: GlobalStateAction.Error, error: ex });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  const handleBackClick = () => {
    history.push('/csvfieldnames', {
      placementAccountCSVData: placementAccountCSVData, filteredCSVData: filteredCSVData, selectedHeaderList: selectedHeaderList, placementAccountList: placementAccountList,
      clientCode: clientCode, phaseNumber: phaseNumber, clientList: clientList, isPreview: isPreview, ignoreRows: ignoreRowNumber, placementAccountCSV: placementList,
      mBalanceValue: mBalanceValue, mBalanceChecked: mBalanceChecked, filteredHeaderList: filteredHeaderList, feeChecked: feeChecked, feeValue: feeValue, mappingValuePrec: mappingValuePrec,
      mappingValue1STP: mappingValue1STP, mappingValueCont: mappingValueCont, checkCont: checkCont, check1STP: check1STP, checkPrec: checkPrec, clientCodeMappingList: clientCodeMappingList,
      uploadedFile: uploadedFile, mappedClientCodeValue: mappedClientCodeValue, stateCodes: getStates, selectedClients: selectedClients, FileId: FileId, fileName: fileName, fileSize: fileSize, blobUri: blobUri, finalDateFormat: finalDateFormat,
      isMappingTemplateSelected: isMappingTemplateSelected
    });
  };

  const UploadFile = () => {
    (async () => {
      if (FileId !== undefined) {
        let request = {
          "file_name": fileName,
          "fileSize": fileSize,
          "uploadMethod": "0",
          "userid": state.userAccessContext?.id.toString(),
          "clientId": (clientCode == "-2" ? "MULC" : clientCode),
          "phase": (getPhaseString(phaseNumber)),
          "bloburi": blobUri,
          "containerName": "upload-csv",
          "subFolderId": subFId
        }
        await usePost<any>("Placements/InsertCSVClouduploadlog", request);
        updateFile();
      } else {
        const formData = new FormData();
        formData.append("FormFile", uploadedFile);
        formData.append("file_name", uploadedFile?.name);
        formData.append("file_size", uploadedFile && uploadedFile?.size);
        formData.append("ContainerName", "upload-csv-uat");
        formData.append("ClientId", (clientCode == "-2" ? "MULC" : clientCode));
        formData.append("UploadMethod", '0');
        formData.append("Phase", getPhaseString(phaseNumber));
        formData.append("userid", state.userAccessContext?.id.toString());
        formData.append("subFolderId", subFId?.toString());
        await usePost<any>("File/UploadCSVFiles", formData);
      }
    })()
  }

  const updateFile = () => {
    (async () => {
      let GetFileID: IFileDetails[] = [];
      let FileDetails: IFileDetails = { fileDetails: FileId };
      GetFileID.push(FileDetails);

      let request = {
        "fileId": _.cloneDeep(GetFileID),
        "categoryId": 2,
        "fileStatus": 3,
        "actionId": 3,
        "userId": state?.userAccessContext?.id,
        "modifiedDate": moment(new Date())?.format("YYYY-MM-DD"),
      }
      await usePost<any>("AuditLog/UpdateFileStatus", request);
    })();
  }

  const handleAccountValidate = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Container fixed className={!filteredCSVData?.length ? classes.container1 : classes.container} component="div" >
          <Grid container spacing={0}>
            <Grid item xs={2} >
              <Typography variant="h5" color='secondary' gutterBottom className={`${classes.title}`} >
                <b> 3. PREVIEW DATA </b>
              </Typography>
            </Grid>
            <Grid item xs={2} >
              {filteredCSVData?.length ?
                <Typography variant="h6" gutterBottom className={classes.countMsg} >
                  <b>Total no.of Accounts : </b> <span style={{ color: "green", fontWeight: 900 }}>{filteredCSVData?.length}</span>
                </Typography>
                : null}
            </Grid>
            {!isAccountSubmited ?
              <>
                <Grid item xs={2}>
                  <DownloadRejectedAccounts />
                </Grid>
                <Grid item xs={2}>
                  <Button id="CSVPD_submit_btn" size="small" fullWidth startIcon={<DownloadIcon />} variant="outlined" sx={{ mt: 1 }} className="blinkButton"
                    color="error">
                    <a href={csvUrl}
                      style={{ color: '#d32f2f', textDecoration: 'none' }}
                      download="PlacementRuleRejectedAccounts.csv"><b>By Rule Validation : {props?.location?.state?.TotalRecordRejected}</b>
                    </a>
                  </Button>
                </Grid>
              </>
              : null}
            {filteredCSVData?.length ?
              <Grid item xs={4}>
                <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="CSVPD_submit_btn" size="small" className={classes.buttons} variant="contained" endIcon={<DoubleArrowIcon />}
                    color="primary" onClick={handleSubmitAccount} disabled={!filteredCSVData?.length}> Submit Accounts </Button>
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="CSVPD_Back_btn" className={classes.backbutton} size="small" variant="contained" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                    color="primary" onClick={handleBackClick}>Back </Button>
                </Box>
              </Grid>
              : null}
          </Grid>
          {!filteredCSVData?.length ?
            <div className={classes.divStyle}>
              <Container fixed component="div" className={classes.errorContainer}>
                <Box p={1} flexShrink={0} bgcolor="white" style={{ marginTop: '1.5cm', float: 'right' }}>
                  <VideoPlayer video={video} title={title} />
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" style={{ marginTop: '0.5cm' }} >
                  <img src={errormsg} alt="Error Message" width="200px" height="200px" style={{ marginLeft: '2.5cm' }} />
                </Box>
                <Box p={2} flexShrink={0} bgcolor="white" justifyContent="center" justifyItems="center" display="flex">
                  <Typography gutterBottom style={{ fontSize: 20, color: 'black' }}>
                    <span style={{ fontSize: 26, color: 'red' }}><b>There are no accounts to submit</b><br /></span>
                    <span style={{ marginTop: '10px', fontSize: 18, }}>Please go back to the placement page screen to correct mapping errors.
                      If want know more about, workflow of placement page, watch Video in the above Help Icon.</span>
                  </Typography>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white" justifyContent="center" justifyItems="center" display="flex">
                  <Button id="CSVPD_Back_btn" size="small" className={classes.buttons} style={{ borderRadius: 30, fontSize: 12 }} variant="contained" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                    color="primary" onClick={handleBackClick}>Back to Field Mapping</Button>
                </Box>
              </Container>
            </div>
            :
            <TableContainer component={Paper} className={classes.tablebody}>
              <Table aria-label="collapsible table" size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    {filteredCSVData[0]?.map((headCell, index) => (
                      <TableCell className={classes.TableCellStyle} align={"left"} key={index} >
                        <span>
                          {templateList?.find((x) => x?.field_name == filteredHeaderList[index])?.display_name}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ marginBottom: "5px" }}>
                  {filteredCSVData?.map((row, rowIndex) => (
                    <TableRow key={"row" + rowIndex}>
                      {filteredCSVData[rowIndex]?.map((cell, cellIndex) => (
                        <TableCell className={classes.tableBody1} key={"cell" + rowIndex + "_" + cellIndex} >
                          {getCellData(cell, rowIndex, cellIndex)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Container>
        <Footer />
      </div>
    </React.Fragment >
  )
}

export default withRouter(CSVPreviewData)
