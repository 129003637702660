import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        "& .Mui-error": {
            color: 'red'
        },
        "& .MuiFormHelperText-root": {
            color: 'green'
        }
    },
    button: {
        borderRadius: 7,
        fontSize: 11,
        width: 155,
        fontWeight: 'bold'
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    confirmButton: {
        color: "white",
        backgroundColor: "green",
        marginTop: "3px",
        marginRight: "10px",
        float: 'right',
        borderRadius: 20,
        fontSize: 12,
        '&:hover': {
            background: "green",
            color: "white",
        }
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginTop: "3px",
        fontSize: 12,
        float: 'right',
        marginRight: "90px",
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
    dialogePaper: {
        minWidth: "1330px"
    },
    buttoncloseSpacing: {
        padding: '8px 15px'
    },
    modifiedStyle: {
        fontSize: '13px',
        color: 'gray',
        marginTop: '7px',
        marginLeft: '20px',
    },
    permittedFiled: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        minWidth: '400px',
        marginLeft: '0px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: '3px',
        paddingBottom: '3px'

    },
    permittedChecked: {
        backgroundColor: '#ebf2ff',
        color: '#2164cd',
        borderRadius: '5px',
        marginLeft: '0px',
        minWidth: '400px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: '3px',
        paddingBottom: '3px'
    },
    activityStyle: {
        flexDirection: 'row',
        padding: '2px',
        textOverflow: 'ellipsis'
    },
    noButton: {
        background: "red",
        display: 'flex',
        color: 'white',
        fontSize: 12,
        right: '10px',
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    showButton: {
        display: 'flex',
        color: 'white',
        fontSize: 12,
        right: '10px',
        borderRadius: 20,
    },
    tableCellColor: {
        color: "white",
        background: "#007FFF",
        padding: '3px 10px',
        fontSize: '12px',
        fontWeight: 'bold'
    },
    tableCellColor1: {
        padding: '8px 10px !important',
        fontSize: '12px',
        borderBottom: '2px solid gray'
    },
    tableBody: {
        maxHeight: 600,
        minHeight: 600,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 174px)',
            minHeight: 'calc( 100vh - 174px)',
        }
    },
    tableBodyTrack: {
        minHeight: 'calc( 100vh - 215px) !important',
        maxHeight: 'calc( 100vh - 215px) !important',
    },
    activityContainer: {
        maxHeight: 'calc( 100vh - 264px)',
        minHeight: 'calc( 100vh - 264px)',
    },
    activityListContainer: {
        maxHeight: 'calc( 100vh - 184px)',
        minHeight: 'calc( 100vh - 184px)',
    },
    tableBody1: {
        maxHeight: 500
    },
    actvityStyle: {
        fontSize: '13px',
        color: 'black'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    activityText: {
        marginTop: '5px !important',
        textOverflow: 'ellipsis',
        width: '330px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        marginLeft: '10px !important'
    },
    badgeStyle: {
        marginLeft: '7px',
        marginTop: '0px'
    },
    activityTextStyle: {
        marginLeft: '0px',
        // minWidth: '315px',
        // maxWidth: '450px !important',
        [theme.breakpoints.up('lg')]: {
            // maxWidth: '450px !important',
        }
    },
    activityDropDownStyle: {
        marginTop: '7px',
        marginLeft: '0px',
        // minWidth: '315px',
        // maxWidth: '450px !important',
        // [theme.breakpoints.up('lg')]: {
        //     maxWidth: '450px !important',
        // }
    },
    activityValues: {
        color: 'black',
        borderRadius: '5px',
        // width: '340px',
    },
    activityDiv: {
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '5px',
        padding: '0px 3px',
    },
    ContentStyle: {
        fontSize: 13,
        border: '1px solid #215176',
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 0.5px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
        padding:'5px',
        backgroundColor:'white'
    },
    expandicon: {
        backgroundColor: '#215176',
        color: 'white',
        borderRadius: 30
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '40px',
        borderBottom: '2px solid #0072E5'
    },
    title: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'center',
        fontSize: 18,
        marginRight: '100px'
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '40px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '50px'
    },
    controlWidth: {
        borderRadius: 5,
        // minWidth: '330px',
        [theme.breakpoints.up('lg')]: {
            // maxWidth: '450px',
        }
    },
    controlWidth1: {
        borderRadius: 5,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '100%',
        }
    },
    boxStyle: {
        padding: '5px 5px 5px 10px',
        borderRight: '1px solid gray',
        boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px'
    },
    closeIcon: {
        color: '#000',
        fontSize: 22
    },
    groupName: {
        background: 'linear-gradient(135deg, rgba(118, 176, 241, 0.2), rgba(32, 101, 209, 0.2)) rgb(255, 255, 255)',
        color: 'rgb(6, 27, 100)',
        padding: '5px 15px',
        borderRadius: '5px',
        textAlign: 'center'
    },
    agentGridContainer: {
        padding: '10px',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        marginTop: '10px'
    },
    TextNormal: {
        color: '#215176',
        fontSize: 14,
        fontWeight: 'bold'
    },
    TextNormal1: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold'
    },
    iconTrack: {
        width: '15px',
        height: '15px'
    },
    outlinedInput: {
        fontSize: 15,
        color: "black",
        marginTop: '-3px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    notchedOutline1: {
        color: 'white',
        border: '0px'
    },
    searchBox: {
        marginTop: '1rem',
        borderRadius: "10px",
        border: '2px solid #215176',
        height: '38px',
        textIndent: '10px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    deletePaperDialogue: {
        minWidth: "650px",
    },
    HeaderStyle: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    DialogBottom: {
        marginRight: '10px'
    },
    listItemStyle: {
        backgroundColor: 'white !important',
        '&:hover': {
            background: "white !important",
        }
    }
}));
export { useStyles };