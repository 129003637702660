import {
    Backdrop, Box, Button, CircularProgress, IconButton, Paper, Table, TableHead, TablePagination, TableSortLabel,
    Toolbar, TableRow, TableCell, Collapse, AppBar, TextField
} from '@material-ui/core';
import { Grid, TableBody, TableContainer, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { CSVLink } from "react-csv";
import { useGlobalState } from '../../../store/GlobalStore';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { Link } from 'react-router-dom';
import download from "../../../images/files/download01.svg";
import { IRollClients } from '../../../models/Roll/IRollClients';
import { usePost } from '../../../utils/apiHelper';
import Footer from '../../Footer/Footer';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../GlobalStyles/TableStyledCell';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { IAgentReportsContract, ICaseCreatedReport } from '../../../models/AgentReport/IAgentReportContract';
import AgentCaseCreatedExpandableRow from './AgentCaseCreatedExpandableRow';
import AgentCaseCreatedPrint from './AgentCaseCreatedPrint';
import * as constant from "../../../constants/Constant";
import useStyles from './CaseCreatedReportCss';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import clearIcon from "../../../images/buttonIcons/clearIcon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IAgentUserList } from '../../../models/AgentUser/IAgentUserList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const GenerateCaseCreatedAgentReport = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [agentReportDetails, setAgentReportDetails] = useState<ICaseCreatedReport[]>([]);
    const [order, setOrder] = useState("DBT_NO");
    const [orderBy, setOrderBy] = useState<string>("");
    const { state } = useGlobalState();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [exportagentreport, setExportAgentReport] = useState<ICaseCreatedReport[]>([]);
    const [groupedActivities, setGroupedActivities] = useState<{ [key: string]: ICaseCreatedReport[] }>({});
    const [expandedRow, setExpandedRow] = useState<string>(null);
    const [userList, setUserList] = useState<IAgentUserList[]>([]);
    const [clientCodeList, setClientCodeList] = useState<IAgentReportsContract[]>(props?.location?.state?.client_code);
    const [selectedClientCodeList, setSelectedClientCodeList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState<number>(null);
    const [selectedUser, setSelectedUser] = useState([]);

    let client_code: IRollClients[] = props?.location?.state?.client_code;
    let startDate: string = props?.location?.state?.startdate;
    let endDate: string = props?.location?.state?.enddate;

    startDate = moment(startDate).format(state?.GlobalUtils?.settingValue);

    const headers = [
        { label: "ACTIVITY NAME", key: "activityName" },
        { label: "CLIENT CODE", key: "clientCode" },
        { label: "DBT NO", key: "dbt_No" },
        { label: "FIELD NAME", key: "fieldName" },
        { label: "FIELD VALUE", key: "fieldValue" },
        { label: "CREATED DATE", key: "createdDate" },
        { label: "ACCOUNT BALANCE", key: "accountBalance" },
        { label: "AGENT USER", key: "agentUser" }
    ]

    useEffect(() => {
        getCaseCreatedReport();
        (async () => {
            let request = {
                searchText: "",
                orderBy: orderBy,
                rowsPerPage: 10000,
                recordNumber: 1,
                orderDirection: order,
                isFteUser: false,
            }
            await usePost<{ agentUserResults: IAgentUserList[], totalCount: number }>("AgentUser/GetAgentUsers", request).then((r) => {
                setUserList(r?.data['agentUserResults']);
            }).finally(() => {
            });
        })()
    }, [])

    const getCaseCreatedReport = () => {
        (async () => {
            let request = {
                "clientCodes": client_code,
                "startdate": startDate,
                "enddate": endDate,
                "userId": null
            }
            setLoading(true);
            await usePost<{ caseCreatedResults: ICaseCreatedReport[], totalCount: number }>("AgentUser/GetCaseCreatedReport", request).then((agentreport) => {
                setAgentReportDetails(agentreport?.data?.caseCreatedResults);

                // Group the Activities by the Client Code and Debtor Number and Activity Name
                const groupedData: { [key: string]: ICaseCreatedReport[] } = {};
                agentreport?.data?.caseCreatedResults?.forEach((activity) => {
                    const key = `${activity?.clientCode}_${activity?.dbt_No}_${activity?.activityName}_${activity?.createdDate}_${activity?.accountBalance}_${activity?.agentUser}`;
                    if (!groupedData[key]) {
                        groupedData[key] = [];
                    }
                    groupedData[key].push(activity);
                });
                setGroupedActivities(groupedData);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }

    const HandleFilter = () => {
        let newClientList = [];
        newClientList.push(selectedClientCodeList);
        (async () => {
            let request = {
                "clientCodes": newClientList?.length ? newClientList : client_code,
                "startdate": startDate,
                "enddate": endDate,
                "userId": selectedUserId
            }
            setLoading(true);
            await usePost<{ caseCreatedResults: ICaseCreatedReport[], totalCount: number }>("AgentUser/GetCaseCreatedReport", request).then((agentreport) => {
                setAgentReportDetails(agentreport?.data?.caseCreatedResults);

                // Group the Activities by the Client Code and Debtor Number and Activity Name
                const groupedData: { [key: string]: ICaseCreatedReport[] } = {};
                agentreport?.data?.caseCreatedResults?.forEach((activity) => {
                    const key = `${activity?.clientCode}_${activity?.dbt_No}_${activity?.activityName}`;
                    if (!groupedData[key]) {
                        groupedData[key] = [];
                    }
                    groupedData[key].push(activity);
                });
                setGroupedActivities(groupedData);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }

    useEffect(() => {
        (async () => {
            let request = {
                "clientCodes": client_code,
                "startdate": startDate,
                "enddate": endDate,
                "userId": selectedUserId
            }
            await usePost<{ caseCreatedResults: ICaseCreatedReport[], totalCount: number }>("AgentUser/AgentCaseCreatedExport", request)?.then((Exportagentreport) => {
                const formattedData = formatDates(Exportagentreport?.data?.caseCreatedResults);
                setExportAgentReport(formattedData);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [])

    // Function to format dates
    const formatDates = (data) => {
        return data.map(item => ({
            ...item,
            createdDate: moment(item.createdDate).format('YYYY-MM-DD HH:mm:ss')
        }));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const finalGroupedActivities = Object.keys(groupedActivities)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const downloadCSV = () => {
        // Convert table data to CSV format
        const csvContent = "data:text/csv;charset=utf-8," +
            Object.keys(groupedActivities)?.map((key) => {
                const rowData = groupedActivities[key]?.map((activity) => (
                    Object.values(activity)?.join(',')
                )).join('\n');
                return `${key}\n${rowData}`;
            }).join('\n');

        // Generate file name
        const fileName = `AgentCaseCreatedReport_${moment(startDate).format(state?.GlobalUtils?.settingValue)}_to_${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`;

        // Create a blob URL
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName); // Set file name here
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link);
    };

    const handleClientSelect = (event, selected) => {
        setSelectedClientCodeList(selected);
    };

    const handleUserSelect = (event, selected) => {
        setSelectedUserId(selected?.agentUserId);
        setSelectedUser(selected);
    };

    const resetValues = () => {
        setSelectedUser([]);
        setSelectedClientCodeList([]);
        setSelectedUserId(null);
    }


    const EnhancedTableToolbar = () => {
        const classes = useStyles();

        return (
            <Toolbar className={`${classes.root} ${classes.tableHeadRadius}`}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Box p={0} flexShrink={0} width="35%" display="flex">
                    <Typography variant="h6" className={`${classes.titleColor} ${"headertitle"}`}>
                        {`Case Created Reports - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                    </Typography>
                </Box>
                <Box p={0} flexShrink={0} width="54%" display="flex">
                    <Box width="30%">
                        <Autocomplete
                            id="free-solo-demo"
                            options={userList}
                            autoComplete autoHighlight
                            classes={{
                                paper: classes.paper1,
                                option: classes.autoCompleteFont
                            }}
                            getOptionLabel={(option) => option?.emailAddress}
                            onChange={handleUserSelect}
                            value={selectedUser}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 14, marginLeft: '5px', width: 250 }}>
                                        {option?.emailAddress}
                                    </span>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="Select User"
                                    className={classes.searchBar}
                                    InputProps={{
                                        ...params.InputProps, disableUnderline: true,
                                        classes: {
                                            root: classes.outlinedInput1,
                                            input: classes.searchTextStyle,
                                        }
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Box width="30%" style={{ paddingLeft: '10px' }}>
                        <Autocomplete className={classes.domainBorder}
                            id="free-solo-demo"
                            autoComplete autoHighlight
                            classes={{
                                paper: classes.paper1,
                                option: classes.autoCompleteFont
                            }}
                            options={clientCodeList}
                            getOptionLabel={(option) => option?.dbT_CLIENT}
                            value={selectedClientCodeList}
                            onChange={handleClientSelect}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 14, marginLeft: '5px', width: 250 }}>
                                        {option?.dbT_CLIENT}
                                    </span>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField placeholder="Select Client Code"
                                    {...params}
                                    className={classes.searchBar}
                                    InputProps={{
                                        ...params.InputProps, disableUnderline: true,
                                        classes: {
                                            root: classes.outlinedInput1,
                                            input: classes.searchTextStyle,
                                        }
                                    }} />
                            )}
                        />
                    </Box>
                    <Box p={0} flexShrink={0} width="30%">
                        <Button id="DocUpLog_Filter_Button" size="small" className={classes.buttons}
                            startIcon={<FilterAltIcon />} onClick={() => { HandleFilter(); }} variant="contained" color="primary" >
                            Filter
                        </Button>
                        <Button id="UpLog_Filter_Button" size="small" className={classes.ClearFilterButtonStyle}
                            startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}
                            onClick={() => { resetValues(); getCaseCreatedReport(); }} variant="contained" color="primary" >
                            Clear Filter
                        </Button>
                    </Box>
                </Box>
                <Box p={0} width="5%" >
                    <div className={classes.wrapper}>
                        <LightTooltip title="Back">
                            <Button id="PLC_Back_btn" className={classes.backbutton}
                                variant="contained" color="primary" size="small" component={Link} to="/agentcasecreatedreport" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}>
                                Back
                            </Button>
                        </LightTooltip>
                    </div>
                </Box>
                <Box p={0} width="3%" >
                    <AgentCaseCreatedPrint client_code={client_code} startDate={startDate} endDate={endDate} isAvailable={exportagentreport?.length > 0 ? true : false} />
                </Box>
                <Box p={0} width="3%" >
                    <div className={classes.wrapper}>
                        {/* <LightTooltip title="Download Reports List">
                            <IconButton disabled={!exportagentreport.length} className={classes.printStyle} onClick={downloadCSV}>
                                <img src={download} alt="Icon for Download" width="30px" height="30px" className={!exportagentreport.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                                {loading && <CircularProgress size={40} className={classes.fabProgressPrint} />}
                            </IconButton>
                        </LightTooltip> */}
                        <LightTooltip title="Download Reports List">
                            <IconButton disabled={!exportagentreport.length} className={classes.printStyle}>
                                <CSVLink
                                    data={exportagentreport}
                                    headers={headers}
                                    filename={`AgentCaseCreatedReport - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`}
                                    target="_blank" >
                                    <img src={download} alt="Icon for Download" width="30px" height="30px" className={!exportagentreport.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                                </CSVLink>
                                {loading && <CircularProgress size={40} className={classes.fabProgressPrint} />}
                            </IconButton>
                        </LightTooltip>
                    </div>
                </Box>
            </Toolbar>
        );
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function EnhancedTableHeads(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "clientCode", disablePadding: false, label: "CLIENT CODE", sortable: true, fontSize: '12px' },
            { id: "dbt_No", disablePadding: false, label: "DBT NO", sortable: true, fontSize: '12px' },
            { id: "activityName", disablePadding: false, label: "ACTIVITY NAME", sortable: true, fontSize: '12px' },
            { id: "createdDate", disablePadding: false, label: "CREATED DATE", sortable: true, fontSize: '12px' },
            { id: "accountBalance", disablePadding: false, label: "ACCOUNT BALANCE", sortable: true, fontSize: '12px' },
            { id: "agentUser", disablePadding: false, label: "AGENT USER", sortable: true, fontSize: '12px' },
        ];

        return (
            <TableHead>
                <StyledTableRow >
                    <StyledTableCell className={classes.TableCellStyle} padding="checkbox">
                    </StyledTableCell>
                    {headCells?.map(headCell => (
                        <StyledTableCell size="small" key={headCell?.id} sortDirection={orderBy === headCell?.id ? order : false}
                            className={classes.tableHeaderStyle} style={{ fontSize: `${headCell.fontSize}` }} >
                            <TableSortLabel active={orderBy === headCell?.id} direction={orderBy === headCell?.id ? order : "asc"} onClick={createSortHandler(headCell.id)} >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell?.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))
                    }
                </StyledTableRow>
            </TableHead >
        );
    }

    const handleRowExpand = (rowKey: string) => {
        setExpandedRow(expandedRow === rowKey ? null : rowKey);
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.gridPadding}>
                    <Grid item xs={12} component={Paper}>
                        <EnhancedTableToolbar />
                        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" stickyHeader>
                                <EnhancedTableHeads classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                                <TableBody>
                                    {finalGroupedActivities?.map((key, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow className={classes.tableHeaderStyle1} hover onClick={() => handleRowExpand(key)}>
                                                <TableCell size="small">
                                                    <IconButton aria-label="expand row" size="small" onClick={() => handleRowExpand(key)} >
                                                        {expandedRow === key ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell className={classes.tablefont}>{groupedActivities[key][0]?.clientCode}</TableCell>
                                                <TableCell className={classes.tablefont}>{groupedActivities[key][0]?.dbt_No}</TableCell>
                                                <TableCell className={classes.tablefont}>{groupedActivities[key][0]?.activityName}</TableCell>
                                                <TableCell className={classes.tablefont}>{moment(groupedActivities[key][0]?.createdDate).format(state?.GlobalUtils?.settingValue)}</TableCell>
                                                <TableCell className={classes.tablefont}>{groupedActivities[key][0]?.accountBalance}</TableCell>
                                                <TableCell className={classes.tablefont}>{groupedActivities[key][0]?.agentUser}</TableCell>
                                            </TableRow>
                                            <TableRow className={classes.root1}>
                                                <TableCell colSpan={7} classes={{ root: classes.customTable }} /* style={{ paddingBottom: 0, paddingTop: 0 }} */ >
                                                    <Collapse in={expandedRow === key} timeout="auto" unmountOnExit>
                                                        <Box paddingTop={1} paddingRight={54} paddingLeft={54} paddingBottom={2}>
                                                            <AppBar position="static" color="transparent" className={classes.rowExpand}>
                                                                <TableContainer component={Paper} className={`${classes.expandedTablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                                                    <Table aria-label="customized table" stickyHeader>
                                                                        <TableHead >
                                                                            <TableRow className={classes.tableHeaderStyle}>
                                                                                {constant.CaseCreatedReportHeader.map(head =>
                                                                                    <TableCell className={classes.tableCellColor}>
                                                                                        {head}
                                                                                    </TableCell>
                                                                                )
                                                                                }
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {groupedActivities[key]?.map((activity, idx) => (
                                                                                <AgentCaseCreatedExpandableRow row={activity} index={idx} />
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </AppBar>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                            {!loading && !(Object.keys(groupedActivities)?.length) ? <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                No records to display...
                            </Typography> : null}
                        </TableContainer>
                        <Grid container spacing={0} className={classes.footerBoxStyle}>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Inquiry_TablePagination"
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={Object.keys(groupedActivities)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default GenerateCaseCreatedAgentReport