import {
  Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, List, Snackbar, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Icons
import ShareIcon from '@material-ui/icons/Share';
import PersonIcon from '@material-ui/icons/Person';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
// Models
import { useFetch, usePost } from '../../../utils/apiHelper';
import { IUserForClient } from '../../../models/SendNotification/IUserForClient';
import { IClientCodeName } from '../../../models/SendNotification/IClientCodeName';
import { IPerformanceReportsResults, IPreReportIdList, IPreReportUserList } from '../../../models/PerformanceReport/IPerformanceReportsResults';
// Componants
import { useStyles } from './UsersCss';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@mui/material';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const Subscription = () => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [SearchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string>('');

  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedReportsList, setSelectedReportsList] = useState<IPerformanceReportsResults[]>([]);
  const [performanceReportsResult, setPerformanceReportsResult] = useState<IPerformanceReportsResults[]>([]);

  const [filter, setFilter] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());

  const handleClickOpen = () => {
    getAllClientCodeList();
    getPerformanceReportsList();
    setOpen(true);
  }

  const closeFilterSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFilter(false);
  };

  const reset = () => {
    setSelectedUsers([]);
    setUsersForClient([]);
    setSelectedClientCodes([]);
    setClientCodes([]);
    setSelectedClient("");
    setSearchText("");
  }

  const occuranceList = [
    { label: 'Daily' },
    { label: 'Weekly' },
    { label: 'Monthly' },
    { label: 'Specfic Date' }
  ]

  const handleClose1 = () => {
    setSendEmailOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedUsers([]);
    setUsersForClient([]);
    setSelectedClientCodes([]);
    setClientCodes([]);
    setSelectedClient("");
    setSearchText("");
  }

  const getAllClientCodeList = () => {
    (async () => {
      setshowProgressBar(true);
      await useFetch<IClientCodeName[]>('GetClientCodeList').then((GetClientCodeList) => {
        setClientCodes(GetClientCodeList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const getPerformanceReportsList = () => {
    (async () => {
      setshowProgressBar(true);
      await useFetch<IPerformanceReportsResults[]>('GetPerformanceReports').then((r) => {
        setPerformanceReportsResult(r?.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }


  const GetClientLists = () => {
    setshowProgressBar(true);
    (async () => {
      await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText.trim()}`).then((GetClientSearchList) => {
        setClientCodes(GetClientSearchList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes];

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handlePerformanceReportToggle = (reports: IPerformanceReportsResults) => () => {
    let newSelectedReports = [...SelectedReportsList];

    if (newSelectedReports.some(c => c.performanceReportId == reports.performanceReportId)) {
      newSelectedReports = newSelectedReports.filter(c => c.performanceReportId != reports.performanceReportId);
    } else {
      newSelectedReports.push(reports);
    }

    let reportsList = "";
    for (var checkReportsId in newSelectedReports) {
      reportsList += newSelectedReports[checkReportsId].performanceReportId;
      reportsList += ',';
    }
    setSelectedReportsList(newSelectedReports);
  }

  const getUsersForClient = (clientCodes) => {
    (async () => {
      setshowProgressBar(true);
      let request = {
        "client_code": clientCodes
      }
      await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClientList) => {
        setUsersForClient(GetUsersForClientList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const getClientUsersAsync = () => {
    setshowProgressBar(true);
    (async () => {
      let request = {
        "client_code": selectedClient,
        "name": SearchTerm?.trimLeft().trimRight()
      }
      await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
        setUsersForClient(GetUsersForClient.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const handleAllRight = () => {
    setshowProgressBar(true);
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" color="secondary" className={classes.clientheader} >
        <b>CLIENTS</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="small" style={{ color: "#1bc42c" }}
                    className={classes.clientcheckbox}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.clientlist} >
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {searchText && !ClientCodes.length ?
            <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
              No Clients to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} color="secondary" >
        <b>SELECTED USERS LISTS</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.clientlist}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const SelectReportList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" color="secondary" className={classes.clientheader} >
        <b>Report Name </b>
      </Typography>
      <Box width="100%" display="flex" flexDirection="column" className={classes.boxBorder1}>
        <div className={`${classes.ReportScrollCreate} ${"scrollbox"} ${"on-scrollbar"}`}>
          <List dense className={classes.selectboxpadding} component="div" role="list">
            {performanceReportsResult?.map((r, index) => {
              const labelId = `transfer-list-item-${index}-label`;
              return (
                <FormControl>
                  <FormControlLabel key={index}
                    control={<Checkbox size='small' checked={SelectedReportsList.some(s => s.performanceReportId == r.performanceReportId)} name="UserList" className={classes.paddingCheckbox1} />}
                    label={
                      <Typography variant="body2" className={classes.clientlist} >
                        <b>{r?.performanceReportName}</b>
                      </Typography>
                    }
                    onClick={handlePerformanceReportToggle(r)}
                    className={classes.permittedFiled}
                  />
                </FormControl>
              );
            })}
            {searchText && !ClientCodes.length ?
              <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                No Clients to display..
              </Typography> : null}
            <ListItem />
          </List>
        </div >
      </Box>
      <Box width="100%" display="flex" flexDirection="column" className={classes.boxEmailStyle}>
        <FormControl>
          <FormControlLabel
            control={<Checkbox size='small' name="UserList" className={classes.paddingCheckbox} />}
            label="Send Email" onClick={() => { setSendEmailOpen(true); }}
            className={`${classes.permittedFiled} ${classes.sendMail}`}
          />
        </FormControl>
      </Box>
    </React.Fragment >
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} color="secondary">
        <b>USERS</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {UsersForClient.map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    size="small" style={{ color: "#1bc42c" }}
                    className={classes.clientcheckbox}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.clientlist}>
                    <b>{user.firstName}{user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {SearchTerm && !UsersForClient.length ?
            <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
              No Users to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const InsertSubscription = () => {
    if (SelectedUsers.length > 0) {
      let reportsList = [];
      let usersListDetails = [];
      SelectedReportsList?.map((x) => {
        let reportsDetail: IPreReportIdList = { performanceReportId: x?.performanceReportId }
        reportsList.push(reportsDetail);
      });
      SelectedUsers?.map((s) => {
        let UsersDetails: IPreReportUserList = { userId: s?.id };
        usersListDetails.push(UsersDetails);
      });
      setshowProgressBar(true);
      let request = {
        "preReportsIdMapDetails": reportsList,
        "userIdMapDetails": usersListDetails,
      };
      usePost("InsertPreReportSubscription", request).then(() => {
        setSelectedReportsList([]);
        setSelectedUsers([]);
      }).finally(() => {
        setFilter(true);
        reset();
        setOpen(false);
        setshowProgressBar(false);
      })
    }
  }

  return (
    <div>
      <Button size="small" id="U_CreateUser" startIcon={<NotificationsActiveIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={`${classes.createBtn} ${classes.btnPadding}`}>
        Subscription
      </Button>

      <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogeReportShare }}
        fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" gutterBottom className={classes.titleHeader}>
            EDIT SUBSCRIPTION
          </Typography>
        </DialogTitleHeader>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent style={{ padding: '15px' }}>
          <Box display="flex">
            <Box width="20%" className={classes.sidebar}>
              {SelectReportList()}
            </Box>
            <Box width="80%" p={3} className={classes.sidebar1}>
              <Box display="flex">
                <Box width="23%" >
                  <input value={searchText}
                    type='text' placeholder="Search Clients"
                    className={classes.searchIcon}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                    maxLength={255}
                  />
                  <div className={classes.gridstyle}>
                    {clientCodeList()}
                  </div>
                </Box>
                <Box width="15%" display="flex" justifyContent="center" alignItems="center" >
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      id="NF_SelectAll_btn"
                      className={classes.selectallbutton}
                      variant="contained" color="primary"
                      size="small" aria-label="move all right"
                      onClick={handleAllRight}
                      startIcon={<GroupAddIcon />} >
                      Select All
                    </Button>
                    <Button
                      id="NF_RemoveAllClient_btn"
                      className={classes.selectallbutton}
                      variant="contained" color="primary"
                      size="small" onClick={handleAllLeftClient}
                      disabled={SelectedClientCodes.length === 0}
                      aria-label="move all left" startIcon={<PersonRemoveAlt1Icon />} >
                      Remove All
                    </Button>
                  </div>
                </Box>
                <Box width="24%" >
                  <input value={SearchTerm}
                    type='text' placeholder="Search Users"
                    className={classes.searchIcon}
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                    maxLength={255}
                  />
                  <div className={classes.gridstyle}>
                    {usersList()}
                  </div>
                </Box>
                <Box width="15%" display="flex" justifyContent="center" alignItems="center" >
                  <div style={{ textAlign: 'center' }}>
                    <Button id="NF_AddAll_btn" variant="contained" color="primary"
                      className={classes.selectallbutton} size="small"
                      onClick={handleCheckedRight} aria-label="move selected right"
                      startIcon={<GroupAddIcon />}>
                      Add All
                    </Button>
                    <Button
                      id="NF_RemoveAll_btn" variant="contained"
                      color="primary" className={classes.selectallbutton}
                      size="small" onClick={handleAllLeft}
                      aria-label="move all left" startIcon={<PersonRemoveAlt1Icon />}>
                      Remove All
                    </Button>
                  </div>
                </Box>
                <Box width="23%" >
                  <div className={classes.gridstyle} style={{ marginTop: '56px' }}>
                    {selectedUsersList()}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" disabled={!SelectedUsers.length || !SelectedReportsList.length} onClick={InsertSubscription} className={classes.shareBtn} startIcon={<ShareIcon />}>
            Share Report
          </Button>
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={handleClose} className={classes.noButton} startIcon={<CancelIcon />}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={sendEmailOpen} onClose={handleClose1}
        classes={{ paper: classes.dialogeInvitePaper }}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" className={classes.titleheader1}>
            SEND EMAIL NOTIFICATION
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant='subtitle2'>
            <b><u>Note :</u></b> This email send notification is used to send email to users based on the occurance type.
          </Typography>

          <Typography variant='subtitle1' className={classes.activityName}>
            <b> Occurance Type</b>
          </Typography>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={occuranceList}
            getOptionLabel={(option) => option.label}
            className={classes.autoCompleteStyle}
            renderInput={(params) => <TextField {...params} label="Occurance Type" variant="outlined" />}
          />

          <Typography variant='subtitle2' style={{ marginTop: '15px' }}>
            <b><u>Note :</u></b> The below "Date Time" will be visible when click on Occurance Type as "Specfic Date".
          </Typography>

          <Typography variant='subtitle1' className={classes.activityName}>
            <b>Specfic Date</b>
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk size='small'
              variant="inline"
              autoComplete='off'
              inputVariant="outlined"
              label="Specfic Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              className={classes.autoCompleteStyle}
              InputAdornmentProps={{ position: "end" }}
              onChange={date => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button size="small" id="DU_ok_btn" variant="contained" startIcon={<DoneAllIcon />} className={`${classes.yesButton} ${classes.btnPadding}`}>
            Submit
          </Button>
          <Button size="small" id="DU_Cancel_btn" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose1} color="primary" className={classes.noButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar className="snackBarStyle" open={filter} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeFilterSnackbar}>
        <Alert onClose={closeFilterSnackbar} severity="success" className="alertStyle">
          Report Shared Successfully!
        </Alert>
      </Snackbar>
    </div >
  );
}

export default Subscription