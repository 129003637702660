import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import * as constant from "../../constants/Constant";
import nofiles from '../../images/agentUser/agentInquiry/nofiles.svg';
import { IAgentInvoiceDetailsList } from '../../models/AgentInquiry/InvoiceDetails/IAgentInvoiceDetails';
import { useGlobalState } from '../../store/GlobalStore';
import useStyles from './InvoiceDetailsCSS';

const InvoiceDetails: React.FC<{ invoiceDetails: IAgentInvoiceDetailsList[], invoiceType: number }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const { invoiceType, invoiceDetails } = props;

    return (
        <React.Fragment >
            <div>
                <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Table aria-label="customized table" stickyHeader>
                        <TableHead >
                            <TableRow className={classes.tableHeaderStyle}>
                                {invoiceType == 1 ?
                                    constant.LocalInvoiceHeader.map(head =>
                                        <TableCell className={classes.tableCellColor}>
                                            {head}
                                        </TableCell>
                                    )
                                    : invoiceType == 2 ?
                                        constant.InvoiceHeader.map(head =>
                                            <TableCell className={classes.tableCellColor}>
                                                {head}
                                            </TableCell>
                                        )
                                        :
                                        constant.AmgnewInvoiceHeader.map(head =>
                                            <TableCell className={classes.tableCellColor}>
                                                {head}
                                            </TableCell>
                                        )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceType == 1 ?
                                invoiceDetails.map((row) => (
                                    <TableRow>
                                        <TableCell className={classes.tableRowStyle}>
                                            {row?.createdDate != null ? moment(row?.createdDate).format(state.GlobalUtils?.settingValue) : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} align="left" scope="row">
                                            {row?.invoiceNo}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} >
                                            {row?.invoiceDescription1}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle}>
                                            {row?.invoiceDate != null ? moment(row?.invoiceDate).format(state.GlobalUtils?.settingValue) : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} >
                                            {row?.serviceDate != null ? moment(row?.serviceDate).format(state.GlobalUtils?.settingValue) : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle}>
                                            {row?.invoiceAmount?.toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} >
                                            {row?.invoiceDueDate != null ? moment(row?.invoiceDueDate).format(state.GlobalUtils?.settingValue) : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} >
                                            {row?.invoiceDescription2}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} align="left" scope="row">
                                            {row?.dbT_NAME}
                                        </TableCell>
                                        <TableCell className={classes.tableRowStyle} >
                                            {row?.flD_01}
                                        </TableCell>
                                    </TableRow>
                                ))
                                : invoiceType === 2 ?
                                    invoiceDetails.map((row) => (
                                        <TableRow>
                                            <TableCell className={classes.tableRowStyle} align="left" scope="row">
                                                {row?.invoiceNo}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceDate != null ? moment(row?.invoiceDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle}>
                                                {row?.invoiceAmount?.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceUnitAmount?.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoice1}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoice2}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoice3}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoice4}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.lastPMTDate != null ? moment(row?.lastPMTDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    invoiceDetails.map((row) => (
                                        <TableRow>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.dataId}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} align="left" scope="row">
                                                {row?.invoiceId}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceDescription1}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} align="left" scope="row">
                                                {row?.invoiceNo}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle}>
                                                {row?.invoiceAmount?.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceDueDate != null ? moment(row?.invoiceDueDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceArrearDays}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoicDunning}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceType}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.invoiceDate != null ? moment(row?.invoiceDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.createdDate != null ? moment(row?.createdDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                            <TableCell className={classes.tableRowStyle} >
                                                {row?.updateDate != null ? moment(row?.updateDate).format(state.GlobalUtils?.settingValue) : ""}
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                    {!invoiceDetails.length ?
                        <>
                            <div className={classes.noRecordsColor}>
                                <img src={nofiles} width="75px" height="65px" />
                            </div>
                            <Typography variant='h6' className={classes.norecord}>
                                No invoice to display.
                            </Typography>
                        </>
                        : null
                    }
                </TableContainer>
            </div>
        </React.Fragment>
    )
}

export default InvoiceDetails
