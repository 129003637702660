import { useMsal } from '@azure/msal-react';
import {
    Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel,
    FormHelperText,
    Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Select, Snackbar, TableContainer, TextField, Toolbar, colors
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { msalConfig } from '../../../auth/authConfig';
import Footer from '../../../components/Footer/Footer';
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../../../components/GlobalStyles/DialogStyle';
import LightTooltip from '../../../components/GlobalStyles/LightTooltip';
import { ARMLogo, EmailRegex, EmailWithChar, NameRegex, NumberReg, PhoneRegex, StateRegex, ZipRegex, regexValidEmail, preventSpecialKeys } from '../../../constants/Constant';
import { IValidationErrors } from '../../../models/NBClientInformation/IClientInformationInput';
import { IBranchNameList, ICompanyNameList, INBBranchClientInformation, INewBusinessBranchDetails } from '../../../models/NBClientInformation/INBBranchClientInformation';
import { NBCompanyClientInformation } from '../../../models/NBClientInformation/NBCompanyClientInformation';
import { IProspectUsersDetails } from '../../../models/accesscontrol/ProspectUsersDetails';
import { usePost } from '../../../utils/apiHelper';
import { ProfileData } from '../../Authentication/ProfileData';
import { useStyles } from '../MultiLocation/multiLocationCss';
import WarningIcon from '@mui/icons-material/Warning';

const MultiLocationDetails: React.FC<{ userDetails: IProspectUsersDetails }> = (props) => {
    const userDetails = props?.userDetails;
    const preventBackSlash = "Backslash";
    const classes = useStyles();
    const { instance } = useMsal();
    const [open, setOpen] = useState(false);
    const [multiLocation, setMultiLocation] = useState(-1);
    const [tablerows, setTableRows] = useState<number[]>([1]);
    const [branchName, setBranchName] = useState();
    const [deleteLocation, setDeleteLocation] = useState<boolean>(false);
    const [newBranchAdded, setNewBranchAdded] = useState<boolean>(false);
    const [branchNameList, setBranchNameList] = useState<IBranchNameList[]>([]);
    const [confirmAllClientData, setConfirmAllClientData] = useState<boolean>(false);
    const [multipleBranchList, setMultipleBranchList] = useState<INBBranchClientInformation[]>([]);
    const [localState, setLocalState] = useState<INBBranchClientInformation>();
    const [newCompanyLocalState, setNewCompanyLocalState] = useState<NBCompanyClientInformation>();
    const [getCompanyDetails, setGetCompanyDetails] = useState<NBCompanyClientInformation>();
    const [getBranchDetails, setGetBranchDetails] = useState<INewBusinessBranchDetails[]>([]);
    const [progressBar, setShowProgressBar] = useState(false);
    const [companyId, setComapnyId] = useState<number>(0);
    const [clientConfirmation, setClientConfirmationTrigger] = useState(false);
    const [ClientInfoConfimation, setClientInfoConfimation] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<IValidationErrors[]>([]);
    const [companyValidationErrors, setCompanyValidationErrors] = useState<IValidationErrors[]>([]);
    const [companyCreditValues, setCompanyCreditValues] = useState([]);
    const [creditAcceptedValues, setCreditAcceptedValues] = useState([]);
    const [isCompanyInformationEntered, setIsCompanyInformationEntered] = useState<boolean>(false);
    const [isBranchInformationEntered, setBranchInformationEntered] = useState<boolean>(false);
    const [isDataSaved, setIsDataSaved] = useState<boolean>(false);
    const [isUserDetailsConfirmed, setIsUserDetailsConfirmed] = useState<boolean>(false);
    const [companyNameDetails, setCompanyNameDetails] = useState<ICompanyNameList[]>([]);
    const [branchId, setBranchId] = useState<number>(0);
    const [confirmDialogBox, setConfirmDialogBox] = useState(false);
    const [requiredFieldDialog, setRequiredFieldDialog] = useState<boolean>(false);
    const [branchRequiredFieldDialog, setBranchRequiredFieldDialog] = useState<boolean>(false);
    const [isDataConfirmed, setIsDataConfirmed] = useState<boolean>(false);

    let newValidationError = [];
    let newBranchValidationError = [];
    const [validationLocalState, setValidationLocalState] = useState(newValidationError);
    const [branchValidationLocalState, setBranchValidationLocalState] = useState(newBranchValidationError);

    const PREFIX = 'https://';
    // Validate the Required field Validation for Company Details 
    const handleValidateCompanyInfo = () => {
        let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
        if (!newCompanyLocalState?.comp_Name?.length || !newCompanyLocalState?.comp_PhoneNumber?.length || !newCompanyLocalState?.comp_phy_Address_Street?.length || !newCompanyLocalState?.comp_phy_Address_City?.length || !newCompanyLocalState?.comp_phy_Address_State?.length
            || !newCompanyLocalState?.comp_phy_Address_Zip?.length || !newCompanyLocalState?.comp_Mailing_Adress_Street?.length || !newCompanyLocalState?.comp_Mailing_Adress_City?.length
            || !newCompanyLocalState?.comp_Mailing_Adress_State?.length || !newCompanyLocalState?.comp_Mailing_Adress_Zip?.length || !newCompanyLocalState?.crop_Contact_Person?.length || !newCompanyLocalState?.crop_Contact_Title?.length
            || !newCompanyLocalState?.crop_Contact_Email?.length || !newCompanyLocalState?.day_to_Day_Contact?.length || !newCompanyLocalState?.day_to_Day_Contact_Title?.length
            || !newCompanyLocalState?.day_to_Day_Contact_Phone?.length || !newCompanyLocalState?.day_to_Day_Contact_Email?.length || !newCompanyLocalState?.numberof_Active_Customer?.length || !newCompanyLocalState?.est_Monthly_Placement?.length || !newCompanyLocalState?.software_Using?.length || !newCompanyLocalState?.authorize_ARM === null
            || !newCompanyLocalState?.company_Display_on_Collection_Notices?.length || !newCompanyLocalState?.collection_Notice_Phone?.length || !newCompanyLocalState?.remit_Address_Street?.length || !newCompanyLocalState?.remit_Address_City?.length || !newCompanyLocalState?.remit_Address_State?.length || !newCompanyLocalState?.remit_Address_Zip?.length
            || !newCompanyLocalState?.creditcard_Accepted?.length || !newCompanyLocalState?.account_Mostly?.length || !newCompanyLocalState?.billing_Directed_To?.length || !newCompanyLocalState?.reportEmailAddress?.length
            || (!newCompanyLocalState?.dayTodayAccountReports && !newCompanyLocalState?.monthlyPerformanceReport)) {
            if (!newCompanyLocalState?.comp_Name?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Name is required",
                    fieldName: "Company Name"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Name") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_PhoneNumber?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Phone Number is required",
                    fieldName: "Company Phone Number"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Phone Number") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_phy_Address_Street?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Physical Address - Street is required",
                    fieldName: "Company Physical Address - Street"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Physical Address - Street") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_phy_Address_City?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Physical Address - City is required",
                    fieldName: "Company Physical Address - City"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Physical Address - City") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_phy_Address_State?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Physical Address - State is required",
                    fieldName: "Company Physical Address - State"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Physical Address - State") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_phy_Address_Zip?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Physical Address - Zip Code is required",
                    fieldName: "Company Physical Address - Zip Code"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Physical Address - Zip Code") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_Mailing_Adress_Street?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Mailing Address - Street is required",
                    fieldName: "Company Mailing Address - Street"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Mailing Address - Street") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_Mailing_Adress_City?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Mailing Address - City is required",
                    fieldName: "Company Mailing Address - City"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Mailing Address - City") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_Mailing_Adress_State?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Mailing Address - State is required",
                    fieldName: "Company Mailing Address - State"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Mailing Address - State") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.comp_Mailing_Adress_Zip?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Company Mailing Address - ZipCode is required",
                    fieldName: "Company Mailing Address - ZipCode"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Mailing Address - ZipCode") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.crop_Contact_Person?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Corporate Contact Person is required",
                    fieldName: "Corporate Contact Person"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Corporate Contact Person") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.crop_Contact_Title?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Corporate Contact Title is required",
                    fieldName: "Corporate Contact Title"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Corporate Contact Title") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.crop_Contact_Email?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Corporate Contact Email is required",
                    fieldName: "Corporate Contact Email"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Corporate Contact Email") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!userDetails?.isMultiBranch) {
                if (!newCompanyLocalState?.day_to_Day_Contact?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Day to Day Contact Name is required",
                        fieldName: "Day to Day Contact Name"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Day to Day Contact Name") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.day_to_Day_Contact_Title?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Day to Day Contact Title is required",
                        fieldName: "Day to Day Contact Title"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Day to Day Contact Title") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.day_to_Day_Contact_Phone?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Day to Day Contact Phone Number is required",
                        fieldName: "Day to Day Contact Phone Number"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Day to Day Contact Phone Number") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.day_to_Day_Contact_Email?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Day to Day Contact Email is required",
                        fieldName: "Day to Day Contact Email"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Day to Day Contact Email") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.company_Display_on_Collection_Notices?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Company Name to Display on Collection Notices is required",
                        fieldName: "Company Name to Display on Collection Notices"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Company Name to Display on Collection Notices") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.collection_Notice_Phone?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Phone Number for Collection Notices is required",
                        fieldName: "Phone Number for Collection Notices"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Phone Number for Collection Notices") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.remit_Address_Street?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Remit Address for Payments - Street is required",
                        fieldName: "Remit Address for Payments - Street"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Remit Address for Payments - Street") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.remit_Address_City?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Remit Address for Payments - City is required",
                        fieldName: "Remit Address for Payments - City"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Remit Address for Payments - City") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.remit_Address_State?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Remit Address for Payments - State is required",
                        fieldName: "Remit Address for Payments - State"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Remit Address for Payments - State") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.remit_Address_Zip?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Remit Address for Payments - Zip Code is required",
                        fieldName: "Remit Address for Payments - Zip Code"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Remit Address for Payments - Zip Code") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.creditcard_Accepted?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Credit Cards Accepted is required",
                        fieldName: "Credit Cards Accepted"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Credit Cards Accepted") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
                if (!newCompanyLocalState?.account_Mostly?.length) {
                    let validation: IValidationErrors = {
                        errorNumber: 1, errorMessage: "Are your accounts mostly is required",
                        fieldName: "Are your accounts mostly"
                    };
                    if (currentValidationErrorList.findIndex(x => x.fieldName == "Are your accounts mostly") <= -1) {
                        currentValidationErrorList.push(validation);
                        setCompanyValidationErrors(currentValidationErrorList);
                    }
                }
            }
            if (!newCompanyLocalState?.numberof_Active_Customer?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Number of Active Customers is required",
                    fieldName: "Number of Active Customers"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Number of Active Customers") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.est_Monthly_Placement?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Estimated Number of Monthly Placements is required",
                    fieldName: "Estimated Number of Monthly Placements"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Estimated Number of Monthly Placements") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.software_Using?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Software (ex. PestPac, RCC, CORE, QuickBooks) is required",
                    fieldName: "Software"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Software") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (newCompanyLocalState?.authorize_ARM === null) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Do you authorize A.R.M. is required",
                    fieldName: "Do you authorize A.R.M"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Do you authorize A.R.M") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.billing_Directed_To?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Billing should be directed to is required",
                    fieldName: "Billing should be directed to"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Billing should be directed to") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.reportEmailAddress?.length) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: "Email Address is required",
                    fieldName: "Email Address"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "Email Address") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            if (!newCompanyLocalState?.monthlyPerformanceReport && !newCompanyLocalState?.dayTodayAccountReports) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: " Monthly Performance Report / Day to Day Account Update Reports is required",
                    fieldName: "monthlyPerformanceReport"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "monthlyPerformanceReport") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
                let validation1: IValidationErrors = {
                    errorNumber: 1, errorMessage: " Monthly Performance Report / Day to Day Account Update Reports is required",
                    fieldName: "dayTodayAccountReports"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "dayTodayAccountReports") <= -1) {
                    currentValidationErrorList.push(validation1);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            newValidationError = _.cloneDeep(currentValidationErrorList);
            newValidationError = [...currentValidationErrorList];
            setValidationLocalState(newValidationError);
            newValidationError?.length ? setRequiredFieldDialog(true) : setConfirmAllClientData(true);
        } else {
            if (isCompanyInformationEntered || isBranchInformationEntered) {
                setIsDataSaved(true);
            } else {
                setConfirmAllClientData(true);
            }
        }
    }

    /** 
     * This Part of code for validating all the controls for new business client information
     * 
     */
    // Start
    const handleValidateZipControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";
        if (name === "remit_Address_Zip") {
            errorFieldValues = "Remit Address for Payments - Zip Code";
        }
        if (name?.includes("Zip")) {
            let zipRegex = new RegExp(ZipRegex);
            let currentValidationErrorList = _.cloneDeep(validationErrors);

            if ((!zipRegex.test(controlValue))) {
                let zipvalidation: IValidationErrors = {
                    errorNumber: 1, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(zipvalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((zipRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }

    const handleValidateControlsState = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";
        if (name === "remit_Address_State") {
            errorFieldValues = "Remit Address for Payments - State";
        }
        if (name?.includes("State")) {
            let stateRegex = new RegExp(StateRegex);
            let currentValidationErrorList = _.cloneDeep(validationErrors);

            if ((!stateRegex.test(controlValue))) {
                let statevalidation: IValidationErrors = {
                    errorNumber: 2, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(statevalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((stateRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }

    const handleValidatePhoneControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";
        if (name === "collection_Notice_Phone") {
            errorFieldValues = "Phone Number for Collection Notices";
        } else if (name === "day_Today_Contact_Phone") {
            errorFieldValues = "Day to Day Contact Phone Number";
        }
        if (name?.includes("Phone")) {
            let phoneRegex = new RegExp(PhoneRegex);
            let currentValidationErrorList = _.cloneDeep(validationErrors);

            if ((!phoneRegex.test(controlValue))) {
                let phonevalidation: IValidationErrors = {
                    errorNumber: 3, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(phonevalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((phoneRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }

    const handleValidateEmailControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";
        if (name === "day_Today_Contact_Email") {
            errorFieldValues = "Day to Day Contact Email";
        } else if (name === "reportEmailAddress") {
            errorFieldValues = "Email Address";
        }
        if (name?.includes("Email") || name?.includes("reportEmailAddress")) {
            let emailRegex = new RegExp(EmailRegex);
            let currentValidationErrorList = _.cloneDeep(validationErrors);
            if ((!regexValidEmail.test(controlValue))) {
                let Emailvalidation: IValidationErrors = {
                    errorNumber: 4, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Emailvalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((regexValidEmail.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }

    const handleValidateReportEmailControls = (e) => {
        var controlValue = e.target.value;
        controlValue = controlValue?.split(",");
        let name = e.target.name;
        let errorFieldValues = "";
        if (name === "dayTodayAccountReportEmail") {
            errorFieldValues = "dayTodayAccountReportEmail";
        } else if (name === "reportEmailAddress") {
            errorFieldValues = "Email Address";
        }
        if (name?.includes("dayTodayAccountReportEmail") || name?.includes("reportEmailAddress")) {
            let emailRegex = new RegExp(regexValidEmail);
            let currentValidationErrorList = _.cloneDeep(validationErrors);
            let lastIndex = controlValue?.length - 1;
            if ((!emailRegex.test(controlValue[lastIndex]))) {
                let Emailvalidation: IValidationErrors = {
                    errorNumber: 4, errorMessage: errorFieldValues + " Formatting error:make sure all email addresses are correct and separated only by commas.",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Emailvalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((emailRegex.test(controlValue[lastIndex]))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }

    const handleValidateNameControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation

        if (name === "day_Today_Contact") {
            errorFieldValues = "Day to Day Contact";
        } else if (name === "day_Today_Contact_Title") {
            errorFieldValues = "Day to Day Contact Title";
        } else if (name === "comp_Name") {
            errorFieldValues = "Company Name";
        } else if (name === "remit_Address_Street") {
            errorFieldValues = "Remit Address for Payments - Street";
        } else if (name === "remit_Address_City") {
            errorFieldValues = "Remit Address for Payments - City";
        }

        if (name?.includes("Name") || name?.includes("Contact") || name?.includes("Day to Day Contact Title") || name?.includes("Website") || name?.includes("Remit Address") || name?.includes("City") || name?.includes("Corporate") || name?.includes("Street")) {
            let nameRegex = new RegExp(NameRegex);
            let currentValidationErrorList = _.cloneDeep(validationErrors);

            if ((!nameRegex.test(controlValue))) {
                let Namevalidation: IValidationErrors = {
                    errorNumber: 5, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Namevalidation);
                    setValidationErrors(currentValidationErrorList);
                }
            }
            else if ((nameRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setValidationErrors(errorList);
                }
            }
            newBranchValidationError = _.cloneDeep(currentValidationErrorList);
            newBranchValidationError = [...currentValidationErrorList];
            setBranchValidationLocalState(newBranchValidationError);
        }
    }
    //END

    /**
     * Company Information Regex Validation 
     * 
     */
    const handleCompanyValidateZipControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation

        if (name === "comp_phy_Address_Zip") {
            errorFieldValues = "Company Physical Address - Zip Code";
        } else if (name === "comp_Mailing_Adress_Zip") {
            errorFieldValues = "Company Mailing Address - ZipCode";
        } else if (name === "remit_Address_Zip") {
            errorFieldValues = "Remit Address for Payments - Zip Code";
        }

        if (name?.includes("Zip")) {
            let zipRegex = new RegExp(ZipRegex);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!zipRegex.test(controlValue))) {
                let zipvalidation: IValidationErrors = {
                    errorNumber: 1, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(zipvalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((zipRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleCompanyValidateControlsState = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "remit_Address_State") {
            errorFieldValues = "Remit Address for Payments - State";
        } else if (name === "comp_Mailing_Adress_State") {
            errorFieldValues = "Company Mailing Address - State";
        } else if (name === "comp_phy_Address_State") {
            errorFieldValues = "Company Physical Address - State";
        }
        if (name?.includes("remit_Address_State") || name?.includes("comp_Mailing_Adress_State") || name?.includes("comp_phy_Address_State")) {
            let stateRegex = new RegExp(StateRegex);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!stateRegex.test(controlValue))) {
                let statevalidation: IValidationErrors = {
                    errorNumber: 2, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(statevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((stateRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleCompanyValidatePhoneControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "comp_PhoneNumber") {
            errorFieldValues = "Company Phone Number";
        } else if (name === "crop_Contact_Phone") {
            errorFieldValues = "Corporate Contact Phone";
        } else if (name === "day_to_Day_Contact_Phone") {
            errorFieldValues = "Day to Day Contact Phone Number";
        } else if (name === "collection_Notice_Phone") {
            errorFieldValues = "Phone Number for Collection Notices";
        }

        if (name?.includes("Phone")) {
            let phoneRegex = new RegExp(PhoneRegex);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!phoneRegex.test(controlValue))) {
                let phonevalidation: IValidationErrors = {
                    errorNumber: 3, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(phonevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((phoneRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleCompanyValidateEmailControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "crop_Contact_Email") {
            errorFieldValues = "Corporate Contact Email";
        } else if (name === "day_to_Day_Contact_Email") {
            errorFieldValues = "Day to Day Contact Email";
        } else if (name === "reportEmailAddress") {
            errorFieldValues = "Email Address";
        }
        if (name?.includes("Email") || (name?.includes("Contact Email")) || (name?.includes("Day to Day Contact Email")) || name?.includes("reportEmailAddress")) {
            let emailRegex = new RegExp(EmailRegex);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
            if ((!emailRegex.test(controlValue))) {
                let Emailvalidation: IValidationErrors = {
                    errorNumber: 4, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Emailvalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((emailRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const preventSpecialCharacters = (e) => {
        if (preventSpecialKeys.includes(e.key)) {
            e.preventDefault();
        } else if (e.code === preventBackSlash) {
            e.preventDefault();
        }
    };

    const handleCompanyReportEmailControls = (e) => {
        let name = e.target.name;
        let errorFieldValues = "";

        var controlValue = e.target.value;
        controlValue = controlValue?.split(",");
        // identify the control names based on the field validation
        if (name === "dayTodayAccountReportEmail") {
            errorFieldValues = "dayTodayAccountReportEmail";
        } else if (name === "reportEmailAddress") {
            errorFieldValues = "Email Address";
        }
        if (name?.includes("dayTodayAccountReportEmail") || name?.includes("reportEmailAddress")) {
            let emailRegex = new RegExp(regexValidEmail);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
            let lastIndex = controlValue?.length - 1;
            if ((!emailRegex.test(controlValue[lastIndex]))) {
                let Emailvalidation: IValidationErrors = {
                    errorNumber: 4, errorMessage: errorFieldValues + " Formatting error:make sure all email addresses are correct and separated only by commas.",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Emailvalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((emailRegex.test(controlValue[lastIndex]))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleCompanyValidateNameControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "comp_Name") {
            errorFieldValues = "Company Name";
        } else if (name === "comp_phy_Address_Street") {
            errorFieldValues = "Company Physical Address - Street";
        } else if (name === "comp_phy_Address_City") {
            errorFieldValues = "Company Physical Address - City";
        } else if (name === "comp_Mailing_Adress_Street") {
            errorFieldValues = "Company Mailing Address - Street";
        } else if (name === "comp_Mailing_Adress_City") {
            errorFieldValues = "Company Mailing Address - City";
        } else if (name === "crop_Contact_Person") {
            errorFieldValues = "Corporate Contact Person";
        } else if (name === "crop_Contact_Title") {
            errorFieldValues = "Corporate Contact Title";
        } else if (name === "day_to_Day_Contact") {
            errorFieldValues = "Day to Day Contact Name";
        } else if (name === "day_to_Day_Contact_Title") {
            errorFieldValues = "Day to Day Contact Title";
        } else if (name === "other_Software") {
            errorFieldValues = "Other Software";
        } else if (name === "company_Display_on_Collection_Notices") {
            errorFieldValues = "Company Name to Display on Collection Notices";
        } else if (name === "remit_Address_Street") {
            errorFieldValues = "Remit Address for Payments - Street";
        } else if (name === "remit_Address_City") {
            errorFieldValues = "Remit Address for Payments - City";
        }

        if (name?.includes("Name") || name?.includes("Contact") || name?.includes("Day to Day Contact Title") || name?.includes("Website") || name?.includes("Remit Address")
            || name?.includes("City") || name?.includes("Corporate") || name?.includes("Street") || name?.includes("software")
            || name?.includes("company_Display_on_Collection_Notices") || name?.includes("comp_Name")) {
            let nameRegex = new RegExp(NameRegex);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!nameRegex.test(controlValue))) {
                let Namevalidation: IValidationErrors = {
                    errorNumber: 5, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Namevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((nameRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleValidateNumberControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "numberof_Active_Customer") {
            errorFieldValues = "Number of Active Customers";
        } else if (name === "est_Monthly_Placement") {
            errorFieldValues = "Estimated Number of Monthly Placements";
        }
        if (name?.includes("numberof_Active_Customer") || name?.includes("est_Monthly_Placement")) {
            let numRegex = new RegExp(NumberReg);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!numRegex.test(controlValue))) {
                let Namevalidation: IValidationErrors = {
                    errorNumber: 6, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Namevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((numRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleValidateEmailWithCharControls = (e) => {
        let controlValue = e.target.value;
        let name = e.target.name;
        let errorFieldValues = "";

        // identify the control names based on the field validation
        if (name === "collection_Notice_Payment_website") {
            errorFieldValues = "Payment Website for Collection Notices";
        }
        if (name?.includes("Website")) {
            let emailCharRegex = new RegExp(EmailWithChar);
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);

            if ((!emailCharRegex.test(controlValue))) {
                let Namevalidation: IValidationErrors = {
                    errorNumber: 7, errorMessage: errorFieldValues + " should be valid and required",
                    fieldName: errorFieldValues
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) <= -1) {
                    currentValidationErrorList.push(Namevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((emailCharRegex.test(controlValue))) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }
    /**
     * End of Regex Validation 
     */

    useEffect(() => {
        let newCompanyList = [];
        setShowProgressBar(true);
        // To get the New Business Comapny Information
        (async () => {
            let request = {
                "userId": userDetails?.prospectUserId,
                "emailAddress": userDetails?.prospectEmailAddress,
            }
            // API call to get the comapany information
            await usePost<{ companyInformation: NBCompanyClientInformation }>("Client/GetCompanyDetailsBasedUser", request).then((result) => {
                setGetCompanyDetails(result?.data?.companyInformation[0]);
                handleMapComapnyDetails(result?.data.companyInformation[0]);
                newCompanyList = (result?.data.companyInformation[0]?.creditcard_Accepted)?.split(",");
            })
        })().finally(() => {
            if (newCompanyList?.length > 0) {
                setCompanyCreditValues(newCompanyList);
            }
            setMultiLocation(0);
        })
        // To get the New Business Branch Information
        getAllBranchDetails();
    }, []);

    // Get the Company Id Based on the User Id
    useEffect(() => {
        (async () => {
            let requestResult = {
                "userId": userDetails?.prospectUserId,
                "emailAddress": userDetails?.prospectEmailAddress
            }
            await usePost<any>("Client/GetCompanyIdBasedOnUserName", requestResult).then((r) => {
                setComapnyId(r?.data[0]?.companyId);
            });
        })().finally(() => {
        })
    }, [])

    const getAllBranchDetails = () => {
        let newBranchInformation = [];
        (async () => {
            let request = {
                "userId": userDetails?.prospectUserId,
                "emailAddress": userDetails?.prospectEmailAddress,
                "branchId": null
            }
            await usePost<{ branchInformation: INewBusinessBranchDetails[] }>("Client/GetBranchDetailsBasedUser", request).then((result) => {
                setGetBranchDetails(result?.data?.branchInformation);
                getEmptyBranchInformation(null);
                result?.data?.branchInformation?.forEach((a) => {
                    let branchDetails: IBranchNameList = {
                        branchId: a?.branchID,
                        branchName: a?.branch_Location_Name
                    }
                    newBranchInformation.push(branchDetails)
                })
            })
        })().finally(() => {
            setBranchNameList(newBranchInformation);
            setShowProgressBar(false);
        })
    }
    const handleMapBranchInformation = (branchInformation: INewBusinessBranchDetails[]) => {
        let emptyBranchDetails: INBBranchClientInformation = {
            emailAddress: userDetails?.prospectEmailAddress,
            branch_Location_Name: branchInformation[0]?.branch_Location_Name,
            day_Today_Contact: branchInformation[0]?.day_Today_Contact,
            day_Today_Contact_Title: branchInformation[0]?.day_Today_Contact_Title,
            day_Today_Contact_Phone: branchInformation[0]?.day_Today_Contact_Phone,
            day_Today_Contact_PhoneExt: branchInformation[0]?.day_Today_Contact_PhoneExt,
            day_Today_Contact_Email: branchInformation[0]?.day_Today_Contact_Email,
            comp_Name: branchInformation[0]?.comp_Name,
            collection_Notice_Phone: branchInformation[0]?.collection_Notice_Phone,
            collection_Notice_PhoneExt: branchInformation[0]?.collection_Notice_PhoneExt,
            remit_Address_Street: branchInformation[0]?.remit_Address_Street,
            remit_Address_City: branchInformation[0]?.remit_Address_City,
            remit_Address_State: branchInformation[0]?.remit_Address_State,
            remit_Address_Zip: branchInformation[0]?.remit_Address_Zip,
            payment_Website: branchInformation[0]?.payment_Website,
            credit_Card_Accepted: branchInformation[0]?.credit_Card_Accepted,
            accounts_Mostly: branchInformation[0]?.accounts_Mostly,
            billing_Contact: branchInformation[0]?.billing_Contact,
            billing_Contact_Person: branchInformation[0]?.billing_Contact_Person,
            billing_Contact_Title: branchInformation[0]?.billing_Contact_Title,
            billing_Contact_Phone: branchInformation[0]?.billing_Contact_Phone,
            billing_Contact_PhoneExt: branchInformation[0]?.billing_Contact_PhoneExt,
            billing_Contact_Email: branchInformation[0]?.billing_Contact_Email,
            billing_Address_Street: branchInformation[0]?.billing_Address_Street,
            billing_Address_City: branchInformation[0]?.billing_Address_City,
            billing_Address_State: branchInformation[0]?.billing_Address_State,
            billing_Address_Zip: branchInformation[0]?.billing_Address_Zip,
            reportEmailAddress: branchInformation[0]?.reportEmailAddress,
            monthlyPerformanceReport: branchInformation[0]?.monthlyPerformanceReport,
            dayTodayAccountReports: branchInformation[0]?.dayTodayAccountReports,
            dayTodayAccountReportEmail: branchInformation[0]?.dayTodayAccountReportEmail,
            comp_ID: companyId,
            userID: userDetails?.prospectUserId
        }
        setLocalState(emptyBranchDetails);
    }

    const handleMapComapnyDetails = (companyInformation: NBCompanyClientInformation) => {
        const companyDetails: NBCompanyClientInformation = {
            emailAddress: userDetails?.prospectEmailAddress,
            comp_Name: companyInformation?.comp_Name,
            comp_PhoneNumber: companyInformation?.comp_PhoneNumber,
            comp_PhoneNumberExt: companyInformation?.comp_PhoneNumberExt,
            comp_Fax: companyInformation?.comp_Fax,
            comp_phy_Address_Street: companyInformation?.comp_phy_Address_Street,
            comp_phy_Address_City: companyInformation?.comp_phy_Address_City,
            comp_phy_Address_State: companyInformation?.comp_phy_Address_State,
            comp_phy_Address_Zip: companyInformation?.comp_phy_Address_Zip,
            comp_Mailing_Adress_Street: companyInformation?.comp_Mailing_Adress_Street,
            comp_Mailing_Adress_City: companyInformation?.comp_Mailing_Adress_City,
            comp_Mailing_Adress_State: companyInformation?.comp_Mailing_Adress_State,
            comp_Mailing_Adress_Zip: companyInformation?.comp_Mailing_Adress_Zip,
            comp_Website: companyInformation?.comp_Website,
            crop_Contact_Person: companyInformation?.crop_Contact_Person,
            crop_Contact_Title: companyInformation?.crop_Contact_Title,
            crop_Contact_Phone: companyInformation?.crop_Contact_Phone,
            crop_Contact_PhoneExt: companyInformation?.crop_Contact_PhoneExt,
            crop_Contact_Email: companyInformation?.crop_Contact_Email,
            day_to_Day_Contact: companyInformation?.day_to_Day_Contact,
            day_to_Day_Contact_Title: companyInformation?.day_to_Day_Contact_Title,
            day_to_Day_Contact_Phone: companyInformation?.day_to_Day_Contact_Phone,
            day_to_Day_Contact_PhoneExt: companyInformation?.day_to_Day_Contact_PhoneExt,
            day_to_Day_Contact_Email: companyInformation?.day_to_Day_Contact_Email,
            company_Display_on_Collection_Notices: companyInformation?.company_Display_on_Collection_Notices,
            collection_Notice_Phone: companyInformation?.collection_Notice_Phone,
            collection_Notice_PhoneExt: companyInformation?.collection_Notice_PhoneExt,
            remit_Address_Street: companyInformation?.remit_Address_Street,
            remit_Address_City: companyInformation?.remit_Address_City,
            remit_Address_State: companyInformation?.remit_Address_State,
            remit_Address_Zip: companyInformation?.remit_Address_Zip,
            collection_Notice_Payment_website: companyInformation?.collection_Notice_Payment_website,
            creditcard_Accepted: companyInformation?.creditcard_Accepted,
            account_Mostly: companyInformation?.account_Mostly,
            numberof_Active_Customer: companyInformation?.numberof_Active_Customer,
            est_Monthly_Placement: companyInformation?.est_Monthly_Placement,
            software_Using: companyInformation?.software_Using,
            other_Software: companyInformation?.other_Software,
            billing_Directed_To: companyInformation?.billing_Directed_To,
            billing_Contact: companyInformation?.billing_Contact,
            billing_Contact_Title: companyInformation?.billing_Contact_Title,
            billing_Contact_Phone: companyInformation?.billing_Contact_Phone,
            billing_Contact_PhoneExt: companyInformation?.billing_Contact_PhoneExt,
            billing_Contact_Email: companyInformation?.billing_Contact_Email,
            billing_Address_Street: companyInformation?.billing_Address_Street,
            billing_Address_City: companyInformation?.billing_Address_City,
            billing_Address_State: companyInformation?.billing_Address_State,
            billing_Address_Zip: companyInformation?.billing_Address_Zip,
            authorize_ARM: companyInformation?.authorize_ARM,
            userID: userDetails?.prospectUserId,
            reportEmailAddress: companyInformation?.reportEmailAddress,
            monthlyPerformanceReport: companyInformation?.monthlyPerformanceReport,
            dayTodayAccountReports: companyInformation?.dayTodayAccountReports,
            dayTodayAccountReportEmail: companyInformation?.dayTodayAccountReportEmail,
        }
        setNewCompanyLocalState(companyDetails);
    }

    const getEmptyBranchInformation = (newBranchName: string): INBBranchClientInformation => {
        let emptyBranchDetails: INBBranchClientInformation = {
            emailAddress: userDetails?.prospectEmailAddress,
            branch_Location_Name: newBranchName,
            day_Today_Contact: "",
            day_Today_Contact_Title: "",
            day_Today_Contact_Phone: "",
            day_Today_Contact_PhoneExt: "",
            day_Today_Contact_Email: "",
            comp_Name: "",
            collection_Notice_Phone: "",
            collection_Notice_PhoneExt: "",
            remit_Address_Street: "",
            remit_Address_City: "",
            remit_Address_State: "",
            remit_Address_Zip: "",
            payment_Website: "",
            credit_Card_Accepted: "",
            accounts_Mostly: "",
            billing_Contact: "",
            billing_Contact_Person: "",
            billing_Contact_Title: "",
            billing_Contact_Phone: "",
            billing_Contact_PhoneExt: "",
            billing_Contact_Email: "",
            billing_Address_Street: "",
            billing_Address_City: "",
            billing_Address_State: "",
            billing_Address_Zip: "",
            reportEmailAddress: "",
            dayTodayAccountReportEmail: "",
            monthlyPerformanceReport: false,
            dayTodayAccountReports: false,
            comp_ID: companyId,
            userID: userDetails?.prospectUserId
        }
        setLocalState(emptyBranchDetails);
        return emptyBranchDetails;
    }

    const getBranchClientInformation = (branchId: number) => {
        var newList = [];
        setShowProgressBar(true);
        (async () => {
            let request = {
                "userId": userDetails?.prospectUserId,
                "emailAddress": userDetails?.prospectEmailAddress,
                "branchId": branchId
            }
            await usePost<{ branchInformation: INewBusinessBranchDetails[] }>("Client/GetBranchDetailsBasedUser", request).then((result) => {
                if (newBranchAdded && !result?.data?.branchInformation?.length) {
                    getEmptyBranchInformation(null);
                } else {
                    newList = (result?.data.branchInformation[0]?.credit_Card_Accepted)?.split(",");
                    const indexToRemove = 0;
                    newList?.splice(indexToRemove, 1);
                    handleMapBranchInformation(result?.data?.branchInformation);
                }
            })
        })().finally(() => {
            setShowProgressBar(false);
            setCreditAcceptedValues(newList);
            setMultiLocation(branchId);
            setIsCompanyInformationEntered(false);
            setBranchInformationEntered(false);
        })
    }

    const addNewBranchDetails = (event) => {
        if (isCompanyInformationEntered || isBranchInformationEntered) {
            setIsDataSaved(true);
        } else {
            setNewBranchAdded(true);
            let rows = _.cloneDeep(tablerows);
            setMultiLocation(rows.length);
            if (!branchNameList?.length) {
                setBranchInformationEntered(true);
                let nameList: IBranchNameList = {
                    branchId: rows.length,
                    branchName: branchName
                }
                let branchList = _.cloneDeep(multipleBranchList);
                rows.push((rows.length + 1));
                let newBranchList: INBBranchClientInformation = getEmptyBranchInformation(branchName);
                branchList.push(newBranchList);
                setMultipleBranchList(branchList);
                setTableRows(rows);
                branchNameList.push(nameList);
            } else if (branchNameList?.length) {
                setBranchInformationEntered(true);
                let nameList: IBranchNameList = {
                    branchId: branchNameList[branchNameList?.length - 1]?.branchId + 1,
                    branchName: branchName
                }
                let branchList = _.cloneDeep(multipleBranchList);
                rows.push((rows.length));
                let newBranchList: INBBranchClientInformation = getEmptyBranchInformation(branchName);
                branchList.push(newBranchList);
                setMultipleBranchList(branchList);
                setTableRows(rows);
                branchNameList.push(nameList);
                setMultiLocation(branchNameList[branchNameList.length - 1]?.branchId);
            }
            setBranchName(null);
        }
    }

    const handleConfirmAllClientData = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "userId": userDetails?.prospectUserId,
                "emailAddress": userDetails?.prospectEmailAddress,
                "isClientInfoConfirmed": true,
                "id": null,
                "message": null,
                "emailSentTo": null
            }
            await usePost("Client/ConfirmAllClientData", request);
        })().finally(() => {
            setShowProgressBar(false);
            handleClose();
            setClientInfoConfimation(true);
            setIsUserDetailsConfirmed(true);
        }).finally(() => {
            let request = {
                "organizationName": newCompanyLocalState?.comp_Name,
                "userName": userDetails?.prospectEmailAddress
            }
            usePost<any>("ClientInfoConfirmationEmail", request).then((r) => {
            })
        })
    }

    const handleAddBranchName = (event) => {
        setBranchName(event?.target.value);
    }

    const handleClickOpen = () => {
        if (isCompanyInformationEntered || isBranchInformationEntered) {
            if (validationErrors?.length) {
                setBranchRequiredFieldDialog(true);
            } else if (companyValidationErrors?.length) {
                setRequiredFieldDialog(true)
            } else {
                setIsDataSaved(true);
            }
        } else {
            setOpen(true);
        }
    };

    const handleChangeValues = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value,
        });
        setBranchInformationEntered(true);
    }

    const handleCompanyDetailsChecked = (e) => {
        let name = e.target.name;
        setNewCompanyLocalState({
            ...newCompanyLocalState,
            [name]: e.target.value,
        });
        setIsCompanyInformationEntered(true);
    }

    const handleCompanyChecked = (e) => {
        let name = e.target.name;
        if (e.target.value === "Yes") {
            setNewCompanyLocalState({
                ...newCompanyLocalState,
                [name]: true,
            });
        } else if (e.target.value === "No") {
            setNewCompanyLocalState({
                ...newCompanyLocalState,
                [name]: false,
            });
        }
        setIsCompanyInformationEntered(true);
    }

    const handleGetCompanyDetails = (event) => {
        const name = event.target.name;
        if (event.target.value === "--Select Software--" && name === "software_Using") {
            setNewCompanyLocalState({
                ...newCompanyLocalState,
                [name]: null,
            });
        } else {
            setNewCompanyLocalState({
                ...newCompanyLocalState,
                [name]: event.target.value,
            });
        }
        setIsCompanyInformationEntered(true);
    }


    const handleValidateDropDown = (event) => {
        const name = event.target.name;
        let errorFieldValues = "";
        if (name === "software_Using") {
            errorFieldValues = "Software";
        }
        const value = event.target.value;
        if (value?.length) {
            if (companyValidationErrors.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                let errorList = companyValidationErrors.filter(x => x.fieldName !== errorFieldValues);
                setCompanyValidationErrors(errorList);
            }
        }
    }

    const handleChecked = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value,
        });
        setBranchInformationEntered(true);
    }

    const handleCompanyCreditCardChecked = (event) => {
        let name = event.target.name;
        var updatedList = [...companyCreditValues];
        if (event.target.checked) {
            updatedList = [...companyCreditValues, event.target.value];
        } else {
            updatedList.splice(companyCreditValues?.indexOf(event.target.value), 1);
        }
        setNewCompanyLocalState({
            ...newCompanyLocalState,
            [name]: updatedList?.toString(),
        });
        setCompanyCreditValues(updatedList);
        setIsCompanyInformationEntered(true);
    }

    const handleRadioButtonValidation = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name?.includes("billing_Directed_To") || name?.includes("account_Mostly")) {
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
            if ((!value?.length)) {
                let Namevalidation: IValidationErrors = {
                    errorNumber: 6, errorMessage: name + " should be valid and required",
                    fieldName: name
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == name) <= -1) {
                    currentValidationErrorList.push(Namevalidation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
            else if ((value?.length)) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == name) > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== name);
                    setCompanyValidationErrors(errorList);
                }
            }
        }
    }

    const handleValidateCreditCardValues = (event) => {
        let name = event.target.name;
        let errorFieldValues = "";
        if (name === "creditcard_Accepted") {
            errorFieldValues = "Credit Cards Accepted";
        }
        var updatedList = [...companyCreditValues];
        if (event.target.checked) {
            updatedList = [...companyCreditValues, event.target.value];
        } else {
            updatedList.splice(companyCreditValues?.indexOf(event.target.value), 1);
        }
        let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
        if (updatedList?.length >= 1) {
            if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                setCompanyValidationErrors(errorList);
            }
            setNewCompanyLocalState({
                ...newCompanyLocalState,
                [name]: updatedList?.toString(),
            });
        } else if (updatedList?.length === 0) {
            let validation: IValidationErrors = {
                errorNumber: 1, errorMessage: "Credit Cards Accepted is required",
                fieldName: errorFieldValues
            };
            if (currentValidationErrorList.findIndex(x => x.fieldName == "Credit Cards Accepted") <= -1) {
                currentValidationErrorList.push(validation);
                setCompanyValidationErrors(currentValidationErrorList);
            }
        }
        setCompanyCreditValues(updatedList);
    }

    const handleValidateBranchCreditCardValues = (event) => {
        let name = event.target.name;
        let errorFieldValues = "";
        if (name === "credit_Card_Accepted") {
            errorFieldValues = "Credit Cards Accepted";
        }
        var updatedList = [...creditAcceptedValues];
        if (event.target.checked) {
            updatedList = [...creditAcceptedValues, event.target.value];
        } else {
            updatedList.splice(creditAcceptedValues?.indexOf(event.target.value), 1);
        }
        let currentValidationErrorList = _.cloneDeep(validationErrors);
        if (updatedList?.length >= 1) {
            if (currentValidationErrorList.findIndex(x => x.fieldName == errorFieldValues) > -1) {
                let errorList = currentValidationErrorList.filter(x => x.fieldName !== errorFieldValues);
                setValidationErrors(errorList);
            }
            setLocalState({
                ...localState,
                [name]: updatedList?.toString(),
            });
        } else if (updatedList?.length === 0 || updatedList?.length === 1) {
            let validation: IValidationErrors = {
                errorNumber: 1, errorMessage: "Credit Cards Accepted is required",
                fieldName: errorFieldValues
            };
            if (currentValidationErrorList.findIndex(x => x.fieldName == "Credit Cards Accepted") <= -1) {
                currentValidationErrorList.push(validation);
                setValidationErrors(currentValidationErrorList);
            }
        }
        newBranchValidationError = _.cloneDeep(currentValidationErrorList);
        newBranchValidationError = [...currentValidationErrorList];
        setBranchValidationLocalState(newBranchValidationError);
        setCreditAcceptedValues(updatedList);
    }

    const handleBranchCheckBoxChecked = (event) => {
        let name = event.target.name;
        var updatedList = [...creditAcceptedValues];
        if (event.target.checked) {
            updatedList = [...creditAcceptedValues, event.target.value];
        } else {
            updatedList.splice(creditAcceptedValues?.indexOf(event.target.value), 1);
        }
        setLocalState({
            ...localState,
            [name]: updatedList?.toString(),
        });
        setCreditAcceptedValues(updatedList);
        setBranchInformationEntered(true);
    }

    const handleClose = () => {
        setOpen(false);
        setDeleteLocation(false);
        setConfirmAllClientData(false);
        setIsDataSaved(false);
        setIsDataConfirmed(false);
    };

    const handleDialogClose = () => {
        setOpen(false);
        setIsDataSaved(false);
        setDeleteLocation(false);
        setRequiredFieldDialog(false);
        setConfirmAllClientData(false);
        getBranchClientInformation(branchId);
        setValidationErrors([]);
        setCompanyValidationErrors([]);
        //newBranchAdded ? setBranchNameList(branchNameList.filter((v) => v?.branchId !== branchId)) : null
    }

    const handleConfirmationClose = () => {
        setConfirmDialogBox(false);
    }

    useEffect(() => {
        if (isDataConfirmed) {
            handleSave();
        }
    }, [isDataConfirmed])

    const handleSave = () => {
        setShowProgressBar(true);
        if (multiLocation === 0) {
            (async () => {
                let newCompanyValue: NBCompanyClientInformation[] = [];
                newCompanyValue.push(newCompanyLocalState);
                if (!newCompanyValue[0]?.comp_Website) {
                    newCompanyValue[0].comp_Website = null;
                } else {
                    newCompanyValue[0].comp_Website = newCompanyValue[0]?.comp_Website?.includes(PREFIX)
                        ? newCompanyValue[0]?.comp_Website
                        : PREFIX + newCompanyValue[0]?.comp_Website;
                }

                if (!newCompanyValue[0]?.collection_Notice_Payment_website) {
                    newCompanyValue[0].collection_Notice_Payment_website = null;
                }
                else {
                    newCompanyValue[0].collection_Notice_Payment_website = newCompanyValue[0]?.collection_Notice_Payment_website?.includes(PREFIX)
                        ? newCompanyValue[0]?.collection_Notice_Payment_website
                        : PREFIX + newCompanyValue[0]?.collection_Notice_Payment_website;
                }
                let request = {
                    "createdDate": new Date(),
                    "modifiedDate": new Date(),
                    "nBCompanyClientInformation": newCompanyValue
                }
                await usePost<ICompanyNameList>("Client/InsertNBCompanyInformation", request).then((r) => {
                    if (r?.data[0]?.companyId !== null || r?.data[0]?.companyId !== undefined) {
                        setComapnyId(r?.data[0]?.companyId);
                        setCompanyNameDetails(r?.data[0]);
                    }
                });
            })().finally(() => {
                setShowProgressBar(false);
                setClientConfirmationTrigger(true);
                handleClose();
            })
        } else if (multiLocation != 0) {
            (async () => {
                let newBranchValue: INBBranchClientInformation[] = [];
                newBranchValue.push(localState);
                let request = {
                    "createdDate": new Date(),
                    "modifiedDate": new Date(),
                    "branchId": newBranchAdded ? null : multiLocation,
                    "nBBranchClientInformation": newBranchValue
                }
                await usePost("Client/InsertNBBranchInformation", request);
            })().finally(() => {
                setMultiLocation(0);
                setBranchNameList([]);
                handleEmptyState();
                handleClose();
            })
        }
        setIsCompanyInformationEntered(false);
        setBranchInformationEntered(false);
        if (isDataConfirmed) {
            handleConfirmAllClientData();
        }
    }

    const handleEmptyState = () => {
        setNewBranchAdded(false);
        getAllBranchDetails();
        setShowProgressBar(false);
        setClientConfirmationTrigger(true);
    }

    const handleOpenDialog = (event) => {
        setDeleteLocation(true);
    }

    const handleDeleteBranchInformation = (branchId: number) => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "branchId": branchId
            }
            usePost("Client/DeleteBranchInformation", request).then(() => {
            }).finally(() => {
                setBranchNameList(branchNameList.filter((v) => v?.branchId !== branchId));
                if (!branchNameList.length) {
                    setMultiLocation(0);
                }
            });
        })().finally(() => {
            setMultiLocation(0);
            getAllBranchDetails(); // call back function to get the Branch Information
            setDeleteLocation(false);
        })
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setClientConfirmationTrigger(false);
        setClientInfoConfimation(false);
    };

    const CompanyDetails = [
        { title: '--Select Software--' },
        { title: 'RCC' },
        { title: 'PestPac' },
        { title: 'FuelPak' },
        { title: 'CORE' },
        { title: 'ServSuite' },
        { title: 'BlueCow' },
        { title: 'CarGas' },
        { title: 'DesertMicro' },
        { title: 'ADDS' },
        { title: 'Pestroutes' },
        { title: 'Other' },
    ];

    const CreditDetails = [
        { title: 'Visa' },
        { title: 'Mastercard' },
        { title: 'Discover' },
        { title: 'American Express' },
        { title: 'None' },
    ];


    const handleSwitchBranch = (branchId: number) => {
        if (isCompanyInformationEntered || isBranchInformationEntered) {
            if (validationErrors?.length) {
                setBranchRequiredFieldDialog(true);
            } else {
                setIsDataSaved(true);
            }
        } else {
            getBranchClientInformation(branchId);
            setIsDataSaved(false);
        }
    }
    const handleSwitchCompany = () => {
        if (isCompanyInformationEntered || isBranchInformationEntered) {
            if (validationErrors?.length) {
                setBranchRequiredFieldDialog(true);
            } else {
                setIsDataSaved(true);
            }
        } else {
            setMultiLocation(0);
            setIsDataSaved(false);
        }
    }

    const signout = () => {
        setConfirmDialogBox(false);
        instance.logout({
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        })
    }

    const handleCompanyReportChecked = (event) => {
        let name = event.target.name;
        setNewCompanyLocalState({
            ...newCompanyLocalState,
            [name]: event.target.checked,
        });
        setIsCompanyInformationEntered(true);
        handleReportValidation(event);
    };

    const handleReportValidation = (event) => {
        let name = event.target.name;
        if (name?.includes("dayTodayAccountReports") || name?.includes("monthlyPerformanceReport")) {
            let currentValidationErrorList = _.cloneDeep(companyValidationErrors);
            if ((event?.target?.checked)) {
                if (currentValidationErrorList.findIndex(x => x.fieldName == "dayTodayAccountReports" || x.fieldName == "monthlyPerformanceReport") > -1) {
                    let errorList = currentValidationErrorList.filter(x => x.fieldName !== "dayTodayAccountReports");
                    let newErrorList = errorList?.filter(x => x.fieldName !== "monthlyPerformanceReport");
                    setCompanyValidationErrors(newErrorList);
                }
            } else if (!event?.target?.checked && (!newCompanyLocalState?.monthlyPerformanceReport && !newCompanyLocalState?.dayTodayAccountReports)) {
                let validation: IValidationErrors = {
                    errorNumber: 1, errorMessage: " Monthly Performance Report / Day to Day Account Update Reports is required",
                    fieldName: "monthlyPerformanceReport"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "monthlyPerformanceReport") <= -1) {
                    currentValidationErrorList.push(validation);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
                let validation1: IValidationErrors = {
                    errorNumber: 1, errorMessage: " Monthly Performance Report / Day to Day Account Update Reports is required",
                    fieldName: "dayTodayAccountReports"
                };
                if (currentValidationErrorList.findIndex(x => x.fieldName == "dayTodayAccountReports") <= -1) {
                    currentValidationErrorList.push(validation1);
                    setCompanyValidationErrors(currentValidationErrorList);
                }
            }
        }
    }

    const handleBranchChecked = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.checked,
        });
        setBranchInformationEntered(true);
    }

    return (
        <div >
            <Backdrop className={classes.backdrop} open={progressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar className="snackBarStyle" open={clientConfirmation} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Client Information Saved Successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={ClientInfoConfimation} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Your Client Information Confirmed Successfully.
                </Alert>
            </Snackbar>
            {isUserDetailsConfirmed ?
                <ProfileData userDetails={userDetails} isConfirmed={true} />
                :
                <>
                    <AppBar position="fixed" className={classes.appBar} >
                        <Toolbar className={classes.toolbarStyle}>
                            <Grid container spacing={0}>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.menuButton}>
                                    <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={4} className={classes.menuButton}>
                                    <Typography align='center' style={{ color: 'black', marginTop: '9px' }}>
                                        Logged in as : <span style={{ color: 'blue' }}><b>{userDetails?.prospectEmailAddress}</b></span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={6} md={6} lg={2} >
                                    <Typography variant="h6" className={classes.title1}>
                                        <b>CLIENT DETAILS</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} className={classes.buttonDisplay} >
                                    {!userDetails?.isAdminApproved ?
                                        <LightTooltip title="Admin Approval is pending">
                                            <InfoOutlinedIcon className={classes.informationIconStyle} />
                                        </LightTooltip>
                                        : null
                                    }
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        onClick={handleSave}
                                        disabled={multiLocation === 0 ? !!companyValidationErrors?.length : !!validationErrors?.length}
                                        className={classes.saveBtn}
                                        color="primary" >
                                        SAVE
                                    </Button>
                                    <Button
                                        variant="contained"
                                        startIcon={<DoneAllIcon />}
                                        size="small"
                                        onClick={(e) => { handleValidateCompanyInfo(); }}
                                        className={classes.cofirmBtn}
                                        color="primary"
                                    >
                                        SAVE AND SUBMIT
                                    </Button>
                                    {userDetails?.isMultiBranch ?
                                        <Button
                                            variant="contained"
                                            startIcon={<AddCircleIcon />}
                                            size="small"
                                            onClick={(e) => { handleClickOpen() }}
                                            className={classes.addLocation}
                                            color="primary" >
                                            ADD BRANCH
                                        </Button>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>

                    <div className="wrapper">
                        <div className="sidebar">
                            <ul className={classes.ulSidebarDisplay}>
                                <li className='row' id={`${multiLocation == 0 ? "active" : ""}`} onClick={(_) => { handleSwitchCompany() }} >
                                    <div id="title" >
                                        {companyNameDetails[0]?.companyName?.length ?
                                            companyNameDetails[0]?.companyName
                                            :
                                            newCompanyLocalState?.comp_Name?.length ?
                                                newCompanyLocalState?.comp_Name
                                                :
                                                <b>COMPANY NAME</b>
                                        }
                                    </div>
                                    {userDetails?.isMultiBranch ?
                                        <div id="icon">
                                            <AddCircleIcon onClick={(e) => { handleClickOpen() }} />
                                        </div >
                                        : null
                                    }
                                </li >
                                {branchNameList.map((row, index) => (
                                    <div id={`${row?.branchId}`} className={`${multiLocation == row?.branchId ? classes.activeStyle : ""} ${"row"}`}>
                                        <LightTooltip id={`${row?.branchId}`} title={row?.branchName}>
                                            <Typography variant='h6' className={classes.textDisplay} id={`${row?.branchId}`}
                                                onClick={() => { handleSwitchBranch(row?.branchId); setBranchId(row?.branchId); }}>
                                                <div id="title" key={row?.branchId}>
                                                    <b>{row?.branchName}</b>
                                                </div>
                                            </Typography>
                                        </LightTooltip>
                                        <div id="icon" key={row?.branchId}>
                                            <IconButton size="small" id={`${row?.branchId}`} onClick={(e) => { handleOpenDialog(e); handleSwitchBranch(row?.branchId); }}>
                                                <RemoveCircleIcon style={{ color: 'white' }} />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </ul >
                            <hr className='line-break-logout' />
                            <div className="logout">
                                <li className='row' onClick={() => { setConfirmDialogBox(true); }}>
                                    <div id="title" style={{ marginLeft: '40px' }}>
                                        LOGOUT
                                    </div>
                                    <div id="icon">
                                        <IconButton style={{ padding: '8px' }} >
                                            <ExitToAppIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </div>
                                </li>
                            </div>
                        </div >
                        <div className="main_content">
                            <Grid container spacing={0} >
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    {multiLocation == 0 ?
                                        <div>
                                            <TableContainer className={`${classes.pageLayout} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                <Grid container component={Paper} spacing={0} style={{ padding: '10px' }}>
                                                    <Grid xs={12} sm={12} md={12} lg={12} >
                                                        <Typography className={classes.headerText}><b>***Information will appear on collection notices and account EXACTLY as it is typed in to the below.***</b></Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={12} >
                                                        <Typography variant='h6' className={classes.headStyle}>
                                                            <b>COMPANY INFORMATION</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Name <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Name"
                                                            placeholder="Company Name"
                                                            value={newCompanyLocalState?.comp_Name}
                                                            className={companyValidationErrors?.map((field) => ("Company Name" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Name" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Name") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Phone Number <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_PhoneNumber"
                                                            value={newCompanyLocalState?.comp_PhoneNumber}
                                                            placeholder="Company Phone Number"
                                                            className={companyValidationErrors?.map((field) => ("Company Phone Number" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Phone Number" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Phone Number") ? true : false}
                                                            onChange={(e) => { handleCompanyValidatePhoneControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                        <TextField
                                                            name="comp_PhoneNumberExt"
                                                            placeholder="ext."
                                                            value={newCompanyLocalState?.comp_PhoneNumberExt}
                                                            className={classes.phoneNumberExt}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                                inputProps: {
                                                                    maxLength: 10 // Replace 10 with your desired maximum length
                                                                }
                                                            }}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Fax Number
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Fax"
                                                            placeholder="Company Fax"
                                                            value={newCompanyLocalState?.comp_Fax}
                                                            className={companyValidationErrors?.map((field) => ("comp_Fax" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("comp_Fax" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "comp_Fax") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Physical Address - Street <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_phy_Address_Street"
                                                            value={newCompanyLocalState?.comp_phy_Address_Street}
                                                            placeholder="Company Physical Address - Street"
                                                            className={companyValidationErrors?.map((field) => ("Company Physical Address - Street" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Physical Address - Street" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Physical Address - Street") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Physical Address - City <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_phy_Address_City"
                                                            value={newCompanyLocalState?.comp_phy_Address_City}
                                                            placeholder="Company Physical Address - City"
                                                            className={companyValidationErrors?.map((field) => ("Company Physical Address - City" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Physical Address - City" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Physical Address - City") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Physical Address - State <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_phy_Address_State"
                                                            value={newCompanyLocalState?.comp_phy_Address_State}
                                                            placeholder="Company Physical Address - State"
                                                            className={companyValidationErrors?.map((field) => ("Company Physical Address - State" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Physical Address - State" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Physical Address - State") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateControlsState(e); handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Physical Address - Zip Code <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_phy_Address_Zip"
                                                            value={newCompanyLocalState?.comp_phy_Address_Zip}
                                                            placeholder="Company Physical Address - Zip Code"
                                                            className={companyValidationErrors?.map((field) => ("Company Physical Address - Zip Code" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Physical Address - Zip Code" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Physical Address - Zip Code") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateZipControls(e); handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Mailing Address - Street <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Mailing_Adress_Street"
                                                            value={newCompanyLocalState?.comp_Mailing_Adress_Street}
                                                            placeholder="Company Mailing Address - Street"
                                                            className={companyValidationErrors?.map((field) => ("Company Mailing Address - Street" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Mailing Address - Street" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Mailing Address - Street") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Mailing Address - City <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Mailing_Adress_City"
                                                            value={newCompanyLocalState?.comp_Mailing_Adress_City}
                                                            placeholder="Company Mailing Address - City"
                                                            className={companyValidationErrors?.map((field) => ("Company Mailing Address - City" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Mailing Address - City" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Mailing Address - City") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Mailing Address - State <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Mailing_Adress_State"
                                                            value={newCompanyLocalState?.comp_Mailing_Adress_State}
                                                            placeholder="Company Mailing Address - State"
                                                            className={companyValidationErrors?.map((field) => ("Company Mailing Address - State" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Mailing Address - State" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Mailing Address - State") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateControlsState(e); handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Mailing Address - ZipCode <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Mailing_Adress_Zip"
                                                            value={newCompanyLocalState?.comp_Mailing_Adress_Zip}
                                                            placeholder="Company Mailing Address - ZipCode"
                                                            className={companyValidationErrors?.map((field) => ("Company Mailing Address - ZipCode" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Company Mailing Address - ZipCode" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Mailing Address - ZipCode") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateZipControls(e); handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Company Website (https://) only
                                                        </Typography>
                                                        <TextField
                                                            name="comp_Website"
                                                            value={newCompanyLocalState?.comp_Website}
                                                            placeholder="Company Website"
                                                            className={companyValidationErrors?.map((field) => ("comp_Website" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                                startAdornment: (
                                                                    newCompanyLocalState?.comp_Website?.includes(PREFIX) ? null :
                                                                        <InputAdornment position="start">
                                                                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                <span style={{ marginLeft: 3, alignItems: 'flex-end' }}>{PREFIX}</span>
                                                                            </div>
                                                                        </InputAdornment>
                                                                ),
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("comp_Website" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "comp_Website") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Corporate Contact Person <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="crop_Contact_Person"
                                                            value={newCompanyLocalState?.crop_Contact_Person}
                                                            placeholder="Corporate Contact Person"
                                                            className={companyValidationErrors?.map((field) => ("Corporate Contact Person" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Corporate Contact Person" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Corporate Contact Person") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Corporate Contact Title <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="crop_Contact_Title"
                                                            value={newCompanyLocalState?.crop_Contact_Title}
                                                            placeholder="Corporate Contact Title"
                                                            className={companyValidationErrors?.map((field) => ("Corporate Contact Title" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Corporate Contact Title" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Corporate Contact Title") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Corporate Contact Phone<span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="crop_Contact_Phone"
                                                            value={newCompanyLocalState?.crop_Contact_Phone}
                                                            placeholder="Corporate Contact Phone"
                                                            className={companyValidationErrors?.map((field) => ("Corporate Contact Phone" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Corporate Contact Phone" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Corporate Contact Phone") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e); handleCompanyValidatePhoneControls(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                        <TextField
                                                            name="crop_Contact_PhoneExt"
                                                            placeholder="Ext."
                                                            value={newCompanyLocalState?.crop_Contact_PhoneExt}
                                                            className={classes.phoneNumberExt}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                                inputProps: {
                                                                    maxLength: 10 // Replace 10 with your desired maximum length
                                                                }
                                                            }}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Corporate Contact Email <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="crop_Contact_Email"
                                                            value={newCompanyLocalState?.crop_Contact_Email}
                                                            placeholder="Corporate Contact Email"
                                                            className={companyValidationErrors?.map((field) => ("Corporate Contact Email" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Corporate Contact Email" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Corporate Contact Email") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateEmailControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {userDetails?.isMultiBranch ?
                                                    null
                                                    :
                                                    <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} >
                                                            <Typography variant='h6' className={classes.headStyle}>
                                                                <b>DAY TO DAY CONTACT INFORMATION</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Name <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="day_to_Day_Contact"
                                                                value={newCompanyLocalState?.day_to_Day_Contact}
                                                                placeholder="Day to Day Contact Name"
                                                                className={companyValidationErrors?.map((field) => ("Day to Day Contact Name" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Day to Day Contact Name" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Name") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Title <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="day_to_Day_Contact_Title"
                                                                value={newCompanyLocalState?.day_to_Day_Contact_Title}
                                                                placeholder="Day to Day Contact Title"
                                                                className={companyValidationErrors?.map((field) => ("Day to Day Contact Title" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Day to Day Contact Title" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Title") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Phone Number <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="day_to_Day_Contact_Phone"
                                                                value={newCompanyLocalState?.day_to_Day_Contact_Phone}
                                                                placeholder="Day to Day Contact Phone Number"
                                                                className={companyValidationErrors?.map((field) => ("Day to Day Contact Phone Number" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Day to Day Contact Phone Number" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Phone Number") ? true : false}
                                                                onChange={(e) => { handleCompanyValidatePhoneControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                            <TextField
                                                                name="day_to_Day_Contact_PhoneExt"
                                                                placeholder="Ext."
                                                                value={newCompanyLocalState?.day_to_Day_Contact_PhoneExt}
                                                                className={classes.phoneNumberExt}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                    inputProps: {
                                                                        maxLength: 10 // Replace 10 with your desired maximum length
                                                                    }
                                                                }}
                                                                onChange={(e) => { handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Emails <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="day_to_Day_Contact_Email"
                                                                value={newCompanyLocalState?.day_to_Day_Contact_Email}
                                                                placeholder="Day to Day Contact Email"
                                                                className={companyValidationErrors?.map((field) => ("Day to Day Contact Email" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Day to Day Contact Email" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Email") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateEmailControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                }

                                                <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                    <Grid xs={12} sm={12} md={12} lg={12} >
                                                        <Typography variant='h6' className={classes.headStyle}>
                                                            <b>GENERAL COLLECTION SET-UP INFORMATION</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Number of Active Customers <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="numberof_Active_Customer"
                                                            value={newCompanyLocalState?.numberof_Active_Customer}
                                                            placeholder="Number of Active Customers"
                                                            className={companyValidationErrors?.map((field) => ("Number of Active Customers" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Number of Active Customers" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Number of Active Customers") ? true : false}
                                                            onChange={(e) => { handleValidateNumberControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Estimated Number of Monthly Placements <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="est_Monthly_Placement"
                                                            value={newCompanyLocalState?.est_Monthly_Placement}
                                                            placeholder="Estimated Number of Monthly Placements"
                                                            className={companyValidationErrors?.map((field) => ("Estimated Number of Monthly Placements" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Estimated Number of Monthly Placements" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Estimated Number of Monthly Placements") ? true : false}
                                                            onChange={(e) => { handleValidateNumberControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Software (ex. PestPac, RCC, CORE, QuickBooks) <span className="required">*</span>
                                                        </Typography>
                                                        <FormControl size="small" variant="outlined" className={classes.textfieldStyle}>
                                                            <Select native
                                                                value={newCompanyLocalState?.software_Using}
                                                                onChange={(e) => { handleValidateDropDown(e); handleGetCompanyDetails(e); }}
                                                                name="software_Using" variant="outlined"
                                                                inputProps={{
                                                                    name: 'software_Using',
                                                                    id: 'outlined-age-native-simple',
                                                                }}>
                                                                {CompanyDetails.map((r) => {
                                                                    return (
                                                                        <option value={r?.title}>{r?.title}</option>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            If you chose other, what software do you use?
                                                        </Typography>
                                                        <TextField
                                                            disabled={newCompanyLocalState?.software_Using != "Other"}
                                                            name="other_Software"
                                                            value={newCompanyLocalState?.other_Software}
                                                            placeholder="If you chose other, what software do you use?"
                                                            className={companyValidationErrors?.map((field) => ("Other Software" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("Other Software" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Other Software") ? true : false}
                                                            onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={8}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Do you authorize A.R.M. to settle Contignency accounts that are $500 or less for 75% of their balance? <span className="required">*</span>
                                                        </Typography>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup aria-label="gender" name="authorize_ARM" className={classes.radioGroup}>
                                                                <FormControlLabel value="Yes" name="authorize_ARM" control={<Radio checked={newCompanyLocalState?.authorize_ARM === true} onChange={(e) => { handleCompanyChecked(e); }} />} label="Yes" />
                                                                <FormControlLabel value="No" name="authorize_ARM" control={<Radio checked={newCompanyLocalState?.authorize_ARM === false} onChange={(e) => { handleCompanyChecked(e); }} />} label="No" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {userDetails?.isMultiBranch ? null
                                                    :
                                                    <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} >
                                                            <Typography variant='h6' className={classes.headStyle}>
                                                                <b>COLLECTION SETUP INFROMATION</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Company Name to Display on Collection Notices <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="company_Display_on_Collection_Notices"
                                                                value={newCompanyLocalState?.company_Display_on_Collection_Notices}
                                                                placeholder="Company Name to Display on Collection Notices"
                                                                className={companyValidationErrors?.map((field) => ("Company Name to Display on Collection Notices" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Company Name to Display on Collection Notices" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Company Name to Display on Collection Notices") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Phone Number for Collection Notices <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="collection_Notice_Phone"
                                                                value={newCompanyLocalState?.collection_Notice_Phone}
                                                                placeholder="Phone Number for Collection Notices"
                                                                className={companyValidationErrors?.map((field) => ("Phone Number for Collection Notices" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Phone Number for Collection Notices" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Phone Number for Collection Notices") ? true : false}
                                                                onChange={(e) => { handleCompanyValidatePhoneControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                            <TextField
                                                                name="collection_Notice_PhoneExt"
                                                                placeholder="Ext."
                                                                value={newCompanyLocalState?.collection_Notice_PhoneExt}
                                                                className={classes.phoneNumberExt}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                    inputProps: {
                                                                        maxLength: 10 // Replace 10 with your desired maximum length
                                                                    }
                                                                }}
                                                                onChange={(e) => { handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - Street <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_Street"
                                                                value={newCompanyLocalState?.remit_Address_Street}
                                                                placeholder="Remit Address for Payments - Street"
                                                                className={companyValidationErrors?.map((field) => ("Remit Address for Payments - Street" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Remit Address for Payments - Street" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - Street") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - City <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_City"
                                                                value={newCompanyLocalState?.remit_Address_City}
                                                                placeholder="Remit Address for Payments - City"
                                                                className={companyValidationErrors?.map((field) => ("Remit Address for Payments - City" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Remit Address for Payments - City" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - City") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateNameControls(e); handleGetCompanyDetails(e) }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - State <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_State"
                                                                value={newCompanyLocalState?.remit_Address_State}
                                                                placeholder="Remit Address for Payments - State"
                                                                className={companyValidationErrors?.map((field) => ("Remit Address for Payments - State" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Remit Address for Payments - State" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - State") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateControlsState(e); handleGetCompanyDetails(e); }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - Zip Code <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_Zip"
                                                                value={newCompanyLocalState?.remit_Address_Zip}
                                                                placeholder="Remit Address for Payments - Zip Code"
                                                                className={companyValidationErrors?.map((field) => ("Remit Address for Payments - Zip Code" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Remit Address for Payments - Zip Code" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - Zip Code") ? true : false}
                                                                onChange={(e) => { handleCompanyValidateZipControls(e); handleGetCompanyDetails(e); }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>

                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Payment Website (https://) for Collection Notices
                                                            </Typography>
                                                            <TextField
                                                                name="collection_Notice_Payment_website"
                                                                value={newCompanyLocalState?.collection_Notice_Payment_website}
                                                                placeholder="Payment Website for Collection Notices"
                                                                className={companyValidationErrors?.map((field) => ("Payment Website for Collection Notices" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                    startAdornment: (
                                                                        newCompanyLocalState?.collection_Notice_Payment_website?.includes(PREFIX) ? null :
                                                                            <InputAdornment position="start">
                                                                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                    <span style={{ marginLeft: 3, alignItems: 'flex-end' }}>{PREFIX}</span>
                                                                                </div>
                                                                            </InputAdornment>
                                                                    ),
                                                                }}
                                                                helperText={companyValidationErrors?.map((field) => ("Payment Website for Collection Notices" === field?.fieldName ? field?.errorMessage : null))}
                                                                error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Payment Website for Collection Notices") ? true : false}
                                                                onChange={(e) => { handleValidateEmailWithCharControls(e); handleGetCompanyDetails(e); }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>

                                                        <Grid xs={12} sm={12} md={12} lg={6}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Credit Cards Accepted <span className="required">*</span>
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="gender" name="creditcard_Accepted" className={classes.radioGroup}>
                                                                    {CreditDetails?.map((items) => {
                                                                        return (
                                                                            <>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox name="creditcard_Accepted"
                                                                                        checked={newCompanyLocalState?.creditcard_Accepted?.includes(items?.title)}
                                                                                        onChange={(e) => { handleValidateCreditCardValues(e); handleCompanyCreditCardChecked(e); }} value={`${items?.title}`} />} label={`${items?.title}`} />
                                                                            </>
                                                                        )
                                                                    })}
                                                                    <FormHelperText style={{ color: 'red' }}>{companyValidationErrors?.map((field) => ("Credit Cards Accepted" === field?.fieldName ? field?.errorMessage : null))}</FormHelperText>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={6}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Are your accounts mostly? <span className="required">*</span>
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="gender" name="account_Mostly" className={classes.radioGroup}>
                                                                    <FormControlLabel value="responsible" onChange={(e) => { handleRadioButtonValidation(e); handleCompanyDetailsChecked(e); }} control={<Radio checked={newCompanyLocalState?.account_Mostly === "responsible"} />} label="Residential" />
                                                                    <FormControlLabel value="commercial" onChange={(e) => { handleRadioButtonValidation(e); handleCompanyDetailsChecked(e); }} control={<Radio checked={newCompanyLocalState?.account_Mostly === "commercial"} />} label="Commercial" />
                                                                    <FormControlLabel value="mixofboth" onChange={(e) => { handleRadioButtonValidation(e); handleCompanyDetailsChecked(e); }} control={<Radio checked={newCompanyLocalState?.account_Mostly === "mixofboth"} />} label="Even Mix of Both" />
                                                                </RadioGroup>
                                                                <FormHelperText style={{ color: 'red' }}>{companyValidationErrors?.map((field) => ("account_Mostly" === field?.fieldName ? field?.errorMessage : null))}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                    <Grid xs={12} sm={12} md={12} lg={12} >
                                                        <Typography variant='h6' className={classes.headStyle}>
                                                            <b>BILLING INFORMATION - OUR INVOICE TO YOU</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing should be directed to: <span className="required">*</span>
                                                        </Typography>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup aria-label="gender" name="billing_Directed_To" className={classes.radioGroup}>
                                                                <FormControlLabel value="Corporate" name="billing_Directed_To" onChange={(e) => { handleRadioButtonValidation(e); handleCompanyDetailsChecked(e); }} control={<Radio checked={newCompanyLocalState?.billing_Directed_To === "Corporate"} />} label="Corporate" />
                                                                <FormControlLabel value="The Branch" name="billing_Directed_To" onChange={(e) => { handleRadioButtonValidation(e); handleCompanyDetailsChecked(e); }} control={<Radio checked={newCompanyLocalState?.billing_Directed_To === "The Branch"} />} label="The Branch" />
                                                            </RadioGroup>
                                                            <FormHelperText style={{ color: 'red' }}>{companyValidationErrors?.map((field) => ("billing_Directed_To" === field?.fieldName ? field?.errorMessage : null))}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            If you chose Corporate, who is our Billing Contact?
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Contact"
                                                            value={newCompanyLocalState?.billing_Contact}
                                                            placeholder="If you chose Corporate, who is our Billing Contact?"
                                                            className={companyValidationErrors?.map((field) => ("billing_Contact" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Contact" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Contact") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Contact Title
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Contact_Title"
                                                            value={newCompanyLocalState?.billing_Contact_Title}
                                                            placeholder="Billing Contact Title"
                                                            className={companyValidationErrors?.map((field) => ("billing_Contact_Title" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Contact_Title" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Title") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Contact Phone Number
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Contact_Phone"
                                                            value={newCompanyLocalState?.billing_Contact_Phone}
                                                            placeholder="Billing Contact Phone Number"
                                                            className={companyValidationErrors?.map((field) => ("billing_Contact_Phone" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Contact_Phone" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Phone") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                        <TextField
                                                            name="billing_Contact_PhoneExt"
                                                            placeholder="Ext."
                                                            value={newCompanyLocalState?.billing_Contact_PhoneExt}
                                                            className={classes.phoneNumberExt}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                                inputProps: {
                                                                    maxLength: 10 // Replace 10 with your desired maximum length
                                                                }
                                                            }}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Contact Email
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Contact_Email"
                                                            value={newCompanyLocalState?.billing_Contact_Email}
                                                            placeholder="Billing Contact Email"
                                                            className={companyValidationErrors?.map((field) => ("billing_Contact_Email" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Contact_Email" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Email") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Address - Street
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Address_Street"
                                                            value={newCompanyLocalState?.billing_Address_Street}
                                                            placeholder="Billing Address - Street"
                                                            className={companyValidationErrors?.map((field) => ("billing_Address_Street" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Address_Street" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Address_Street") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Address - City
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Address_City"
                                                            value={newCompanyLocalState?.billing_Address_City}
                                                            placeholder="Billing Address - City"
                                                            className={companyValidationErrors?.map((field) => ("billing_Address_City" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Address_City" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Address_City") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e) }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Address - State
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Address_State"
                                                            value={newCompanyLocalState?.billing_Address_State}
                                                            placeholder="Billing Address - State"
                                                            className={companyValidationErrors?.map((field) => ("billing_Address_State" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Address_State" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Address_State") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Billing Address - Zip Code
                                                        </Typography>
                                                        <TextField
                                                            name="billing_Address_Zip"
                                                            value={newCompanyLocalState?.billing_Address_Zip}
                                                            placeholder="Billing Address - Zip Code"
                                                            className={companyValidationErrors?.map((field) => ("billing_Address_Zip" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            helperText={companyValidationErrors?.map((field) => ("billing_Address_Zip" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "billing_Address_Zip") ? true : false}
                                                            onChange={(e) => { handleGetCompanyDetails(e); }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                    <Grid xs={12} sm={12} md={12} lg={12}>
                                                        <Typography variant='h6' className={classes.headStyle}>
                                                            <b>REPORTING</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={6}>
                                                        <FormControl error={!companyValidationErrors?.findIndex(x => x?.fieldName === "monthlyPerformanceReport") ? true : false}  >
                                                            <FormControlLabel
                                                                style={{ color: !companyValidationErrors?.findIndex((x) => x?.fieldName === 'monthlyPerformanceReport') ? 'red' : '#0072E5' }}
                                                                className={classes.textfieldColor}
                                                                control={<Checkbox
                                                                    checked={newCompanyLocalState?.monthlyPerformanceReport}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    name="monthlyPerformanceReport"
                                                                    onChange={(e) => { handleCompanyReportChecked(e) }}
                                                                />
                                                                }
                                                                labelPlacement="end"
                                                                label={
                                                                    <Typography style={{ fontWeight: 'bold' }}>
                                                                        Monthly Performance Report <span className="required">*</span>
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormHelperText>
                                                                {companyValidationErrors?.map((field) => ("monthlyPerformanceReport" === field?.fieldName ? field?.errorMessage : null))}
                                                            </FormHelperText>
                                                        </FormControl>
                                                        <TextField id="outlined-basic" size="small"
                                                            name="dayTodayAccountReportEmail"
                                                            label="Email Address"
                                                            placeholder='Enter multiple Email with comma separator'
                                                            onKeyPress={preventSpecialCharacters}
                                                            value={newCompanyLocalState?.dayTodayAccountReportEmail}
                                                            onChange={(e) => { handleCompanyReportEmailControls(e); handleGetCompanyDetails(e); }}
                                                            helperText={companyValidationErrors?.map((field) => ("dayTodayAccountReportEmail" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "dayTodayAccountReportEmail") ? true : false}
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={6}>
                                                        <FormControl error={!companyValidationErrors?.findIndex(x => x?.fieldName === "dayTodayAccountReports") ? true : false}>
                                                            <FormControlLabel
                                                                style={{ color: !companyValidationErrors?.findIndex((x) => x?.fieldName === 'dayTodayAccountReports') ? 'red' : '#0072E5' }}
                                                                className={classes.textfieldColor}
                                                                control={<Checkbox checked={newCompanyLocalState?.dayTodayAccountReports}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    onChange={(e) => { handleCompanyReportChecked(e); }}
                                                                    name="dayTodayAccountReports" />
                                                                }
                                                                labelPlacement="end"
                                                                label={
                                                                    <Typography style={{ fontWeight: 'bold' }}>
                                                                        Day to Day Account Update Reports <span className="required">*</span>
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormHelperText>
                                                                {companyValidationErrors?.map((field) => ("dayTodayAccountReports" === field?.fieldName ? field?.errorMessage : null))}
                                                            </FormHelperText>
                                                        </FormControl>
                                                        <TextField id="outlined-basic" size="small"
                                                            name="reportEmailAddress"
                                                            label="Email Address"
                                                            placeholder='Enter multiple Email with comma separator'
                                                            onKeyPress={preventSpecialCharacters}
                                                            value={newCompanyLocalState?.reportEmailAddress}
                                                            onChange={(e) => { handleCompanyReportEmailControls(e); handleGetCompanyDetails(e); }}
                                                            helperText={companyValidationErrors?.map((field) => ("Email Address" === field?.fieldName ? field?.errorMessage : null))}
                                                            error={!companyValidationErrors?.findIndex(x => x?.fieldName === "Email Address") ? true : false}
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableContainer>
                                        </div >
                                        :
                                        multiLocation != 0 ?
                                            branchNameList?.map((row, index) => (
                                                <div >
                                                    {row?.branchId === multiLocation ?
                                                        <TableContainer key={row?.branchId} className={`${classes.pageLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
                                                            <Grid container component={Paper} spacing={0} style={{ padding: '10px' }}>
                                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                                    <Typography className={classes.headerText}><b>***Information will appear on collection notices and account EXACTLY as it is typed in to the below.***</b></Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                                    <Typography variant='h6' className={classes.headStyle}>
                                                                        <b>BRANCH / LOCATION INFORMATION</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Branch/Location Name and/or Number
                                                                    </Typography>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        name="branch_Location_Name"
                                                                        placeholder="Location Name"
                                                                        value={localState?.branch_Location_Name}
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("branch_Location_Name" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "branch_Location_Name") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Day to Day Contact<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        name="day_Today_Contact"
                                                                        placeholder="Contact"
                                                                        value={localState?.day_Today_Contact}
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Day to Day Contact" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact") ? true : false}
                                                                        onChange={(e) => { handleValidateNameControls(e); handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Day to Day Contact Title<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        name="day_Today_Contact_Title"
                                                                        value={localState?.day_Today_Contact_Title}
                                                                        className={validationErrors?.map((field) => ("Day to Day Contact Title" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Day to Day Contact Title" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Title") ? true : false}
                                                                        onChange={(e) => { handleValidateNameControls(e); handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={6}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Day to Day Contact Phone Number <span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        name="day_Today_Contact_Phone"
                                                                        value={localState?.day_Today_Contact_Phone}
                                                                        className={validationErrors?.map((field) => ("Day to Day Contact Phone Number" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Day to Day Contact Phone Number" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Phone Number") ? true : false}
                                                                        onChange={(e) => { handleValidatePhoneControls(e); handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                    <TextField
                                                                        name="day_Today_Contact_PhoneExt"
                                                                        placeholder="Ext."
                                                                        value={localState?.day_Today_Contact_PhoneExt}
                                                                        className={classes.phoneNumberExt}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={6}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Day to Day Contact Email <span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        name="day_Today_Contact_Email"
                                                                        value={localState?.day_Today_Contact_Email}
                                                                        className={validationErrors?.map((field) => ("Day to Day Contact Email" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Day to Day Contact Email" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Day to Day Contact Email") ? true : false}
                                                                        onChange={(e) => { handleValidateEmailControls(e); handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                                    <Typography variant='h6' className={classes.headStyle}>
                                                                        <b>COLLECTION SETUP INFORMATION</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Company Name to Display on Collection Notices<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="comp_Name"
                                                                        value={localState?.comp_Name}
                                                                        placeholder="Company Name to Display on Collection Notices"
                                                                        className={validationErrors?.map((field) => ("Company Name" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Company Name" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Company Name") ? true : false}
                                                                        onChange={(e) => { handleValidateNameControls(e); handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Phone Number for Collection Notices<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="collection_Notice_Phone"
                                                                        value={localState?.collection_Notice_Phone}
                                                                        placeholder="Phone Number for Collection Notices"
                                                                        className={validationErrors?.map((field) => ("Phone Number for Collection Notices" === field?.fieldName ? field?.errorMessage : null)) ? classes.phoneNumberWarning : classes.phoneNumberField}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Phone Number for Collection Notices" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Phone Number for Collection Notices") ? true : false}
                                                                        onChange={(e) => { handleValidatePhoneControls(e); handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                    <TextField
                                                                        name="collection_Notice_PhoneExt"
                                                                        placeholder="Ext."
                                                                        value={localState?.collection_Notice_PhoneExt}
                                                                        className={classes.phoneNumberExt}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Remit Address for Payments - Street<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="remit_Address_Street"
                                                                        value={localState?.remit_Address_Street}
                                                                        placeholder="Remit Address for Payments - Street"
                                                                        className={validationErrors?.map((field) => ("Remit Address for Payments - Street" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Remit Address for Payments - Street" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - Street") ? true : false}
                                                                        onChange={(e) => { handleValidateNameControls(e); handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Remit Address for Payments - City<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="remit_Address_City"
                                                                        value={localState?.remit_Address_City}
                                                                        placeholder="Remit Address for Payments - City"
                                                                        className={validationErrors?.map((field) => ("Remit Address for Payments - City" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Remit Address for Payments - City" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - City") ? true : false}
                                                                        onChange={(e) => { handleValidateNameControls(e); handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Remit Address for Payments - State<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="remit_Address_State"
                                                                        value={localState?.remit_Address_State}
                                                                        placeholder="Remit Address for Payments - State"
                                                                        className={validationErrors?.map((field) => ("Remit Address for Payments - State" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Remit Address for Payments - State" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - State") ? true : false}
                                                                        onChange={(e) => { handleValidateControlsState(e); handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Remit Address for Payments - Zip Code<span className="required">*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        name="remit_Address_Zip"
                                                                        value={localState?.remit_Address_Zip}
                                                                        placeholder="Remit Address for Payments - Zip Code"
                                                                        className={validationErrors?.map((field) => ("Remit Address for Payments - Zip Code" === field?.fieldName ? field?.errorMessage : null)) ? classes.warning : classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("Remit Address for Payments - Zip Code" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Remit Address for Payments - Zip Code") ? true : false}
                                                                        onChange={(e) => { handleValidateZipControls(e); handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Payment Website for Collection Notices
                                                                    </Typography>
                                                                    <TextField
                                                                        name="payment_Website"
                                                                        value={localState?.payment_Website}
                                                                        placeholder="Payment Website for Collection Notices"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("payment_Website" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "payment_Website") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={8}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Credit Cards Accepted<span className="required">*</span>
                                                                    </Typography>
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup aria-label="gender" name="credit_Card_Accepted" className={classes.radioGroup}>
                                                                            {CreditDetails?.map((items) => {
                                                                                return (
                                                                                    <>
                                                                                        <FormControlLabel control={<Checkbox name="credit_Card_Accepted"
                                                                                            checked={localState?.credit_Card_Accepted?.includes(items?.title)}
                                                                                            onChange={(e) => { handleValidateBranchCreditCardValues(e); handleBranchCheckBoxChecked(e); }} value={`${items?.title}`} />} label={`${items?.title}`} />
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </RadioGroup>
                                                                        <FormHelperText style={{ color: 'red' }}>{validationErrors?.map((field) => ("Credit Cards Accepted" === field?.fieldName ? field?.errorMessage : null))}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Are your accounts mostly?<span className="required">*</span>
                                                                    </Typography>
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup aria-label="gender" name="accounts_Mostly" className={classes.radioGroup}>
                                                                            <FormControlLabel value="responsible" checked={localState?.accounts_Mostly === "responsible"} onChange={(e) => { handleChecked(e); }} control={<Radio />} label="Residential" />
                                                                            <FormControlLabel value="commercial" checked={localState?.accounts_Mostly === "commercial"} onChange={(e) => { handleChecked(e); }} control={<Radio />} label="Commercial" />
                                                                            <FormControlLabel value="mixofboth" checked={localState?.accounts_Mostly === "mixofboth"} onChange={(e) => { handleChecked(e); }} control={<Radio />} label="Even Mix of Both" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                                    <Typography variant='h6' className={classes.headStyle}>
                                                                        <b>BILLING INFORMATION - OUR INVOICE TO YOU</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing should be directed to:<span className="required">*</span>
                                                                    </Typography>
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup aria-label="gender" name="billing_Contact" className={classes.radioGroup}>
                                                                            <FormControlLabel value="Corporate" checked={localState?.billing_Contact === "Corporate"} onChange={(e) => { handleChecked(e); }} control={<Radio />} label="Corporate" />
                                                                            <FormControlLabel value="Branch" checked={localState?.billing_Contact === "Branch"} onChange={(e) => { handleChecked(e); }} control={<Radio />} label="The Branch" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        If you chose Corporate, who is our Billing Contact?
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Contact_Person"
                                                                        value={localState?.billing_Contact_Person}
                                                                        placeholder="If you chose Corporate, who is our Billing Contact?"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Contact_Person" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Person") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Contact Title
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Contact_Title"
                                                                        value={localState?.billing_Contact_Title}
                                                                        placeholder="Billing Contact Title"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Contact_Title" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Title") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Contact Phone Number
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Contact_Phone"
                                                                        value={localState?.billing_Contact_Phone}
                                                                        placeholder="Billing Contact Phone Number"
                                                                        className={classes.phoneNumberField}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Contact_Phone" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Phone") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                    <TextField
                                                                        name="billing_Contact_PhoneExt"
                                                                        placeholder="Ext."
                                                                        value={localState?.billing_Contact_PhoneExt}
                                                                        className={classes.phoneNumberExt}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Contact Email
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Contact_Email"
                                                                        value={localState?.billing_Contact_Email}
                                                                        placeholder="Billing Contact Email"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Contact_Email" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Contact_Email") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Address - Street
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Address_Street"
                                                                        value={localState?.billing_Address_Street}
                                                                        placeholder="Billing Address - Street"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Address_Street" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Address_Street") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Address - City
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Address_City"
                                                                        value={localState?.billing_Address_City}
                                                                        placeholder="Billing Address - City"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Address_City" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Address_City") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e) }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Address - State
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Address_State"
                                                                        value={localState?.billing_Address_State}
                                                                        placeholder="Billing Address - State"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Address_State" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Address_State") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Billing Address - Zip Code
                                                                    </Typography>
                                                                    <TextField
                                                                        name="billing_Address_Zip"
                                                                        value={localState?.billing_Address_Zip}
                                                                        placeholder="Billing Address - Zip Code"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        helperText={validationErrors?.map((field) => ("billing_Address_Zip" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "billing_Address_Zip") ? true : false}
                                                                        onChange={(e) => { handleChangeValues(e); }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                                <Grid xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography variant='h6' className={classes.headStyle}>
                                                                        <b>REPORTING</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={6}>
                                                                    <FormControlLabel
                                                                        className={classes.textfieldColor}
                                                                        control={<Checkbox checked={localState?.monthlyPerformanceReport}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            onChange={handleBranchChecked} name="monthlyPerformanceReport" />
                                                                        }
                                                                        labelPlacement="end"
                                                                        label={
                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                Monthly Performance Report <span className="required">*</span>
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        label="Email Address"
                                                                        placeholder='Enter multiple Email with comma separator'
                                                                        name="dayTodayAccountReportEmail"
                                                                        value={localState?.dayTodayAccountReportEmail}
                                                                        onKeyPress={preventSpecialCharacters}
                                                                        onChange={(e) => { handleValidateReportEmailControls(e); handleChangeValues(e); }}
                                                                        helperText={validationErrors?.map((field) => ("dayTodayAccountReportEmail" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "dayTodayAccountReportEmail") ? true : false}
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={6} lg={5}>
                                                                    <FormControlLabel
                                                                        className={classes.textfieldColor}
                                                                        control={<Checkbox checked={localState?.dayTodayAccountReports}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            onChange={handleBranchChecked} name="dayTodayAccountReports" />
                                                                        }
                                                                        labelPlacement="end"
                                                                        label={
                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                Day to Day Account Update Reports <span className="required">*</span>
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        label="Email Address"
                                                                        placeholder='Enter multiple Email with comma separator'
                                                                        name="reportEmailAddress"
                                                                        value={localState?.reportEmailAddress}
                                                                        onKeyPress={preventSpecialCharacters}
                                                                        onChange={(e) => { handleValidateReportEmailControls(e); handleChangeValues(e); }}
                                                                        helperText={validationErrors?.map((field) => ("Email Address" === field?.fieldName ? field?.errorMessage : null))}
                                                                        error={!validationErrors?.findIndex(x => x?.fieldName === "Email Address") ? true : false}
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </TableContainer>
                                                        : null
                                                    }
                                                </div>
                                            ))
                                            : null}
                                </Grid>
                            </Grid>
                            <Footer />
                        </div>
                    </div >
                </>
            }
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth={'sm'}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 15, width: 680 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        ADD LOCATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={0} >
                        <Grid xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className={classes.textfieldColor}>
                                Branch/Location Name and/or Number
                            </Typography>
                            <TextField
                                autoComplete='off'
                                name="LocationName"
                                label="Location Name"
                                onChange={handleAddBranchName}
                                className={classes.textfieldStyle}
                                InputProps={{
                                    classes: {
                                        root: classes.blueBorderBottom,
                                        notchedOutline: classes.noBorder
                                    },
                                }}
                                margin="dense" variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Ok_btn" size="small" variant="contained" startIcon={<SaveIcon />} onClick={(e) => { addNewBranchDetails(e); handleClose(); }} className={classes.yesButton}>
                        Submit
                    </Button>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { handleClose(); }} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteLocation} onClose={handleClose} TransitionComponent={Transition}
                classes={{ paper: classes.deletePaperDialogue }}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        DELETE LOCATION OR BRANCH INFORMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" gutterBottom className={classes.titleConfirm}>
                        Are you sure you want to delete "Branch/ Location Name and/or Number"?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Ok_btn" size="small" variant="contained" startIcon={<SaveIcon />} onClick={(e) => { handleDeleteBranchInformation(multiLocation) }} className={classes.yesButton}>
                        Ok
                    </Button>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { handleClose(); }} color="primary" className={classes.noButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmAllClientData} onClose={handleClose} TransitionComponent={Transition}
                classes={{ paper: classes.deletePaperDialogue }}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        CONFIRM CLIENT DATA SUBMISSION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" gutterBottom className={classes.titleConfirm}>
                        Are you sure this information is accurate and ready to submit?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Ok_btn" size="small" variant="contained" startIcon={<SaveIcon />}
                        onClick={(e) => { setIsDataConfirmed(true); }} className={classes.confirmButton}
                        disabled={!userDetails?.isAdminApproved} >
                        Confirm
                    </Button>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { handleClose(); }} color="primary" className={classes.noButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isDataSaved} onClose={handleDialogClose} TransitionComponent={Transition}
                classes={{ paper: classes.deletePaperDialogue }}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        Client Details
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" gutterBottom className={classes.titleConfirm}>
                        The client details are modified would you like to save?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Ok_btn" size="small" variant="contained" startIcon={<SaveIcon />}
                        onClick={(e) => { handleSave(); }} className={classes.confirmButton}>
                        Save
                    </Button>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { handleDialogClose(); }} color="primary" className={classes.noButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                open={confirmDialogBox} onClose={handleConfirmationClose} maxWidth={'xs'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleConfirmationClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        CONFIRMATION MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle}>
                            Are you sure you want to Sign-out?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} onClick={signout} className={classes.yesButton}>
                        Yes
                    </Button>
                    <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<CancelIcon />} onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={requiredFieldDialog} onClose={() => { setRequiredFieldDialog(false) }} TransitionComponent={Transition}
                maxWidth={'sm'}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={() => { setRequiredFieldDialog(false) }}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        ERROR DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText style={{ padding: '5px 15px' }}>
                        <Typography variant="body1" className={classes.requiredLabel} >
                            <div>
                                <WarningIcon className={classes.warningIcon} />
                            </div>
                            <span className={classes.requiredText}>Please Fill all the Required Fields!</span>
                        </Typography>
                        <Typography variant="h6" className={classes.missingLabel}>
                            Missing required fields are :
                        </Typography>
                        <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <ul className={classes.errorfields}>
                                {validationLocalState?.length ?
                                    validationLocalState?.map((value) => (
                                        <li key={value?.errorNumber} >
                                            {value?.fieldName}
                                        </li>
                                    ))
                                    :
                                    companyValidationErrors?.map((value) => (
                                        <li key={value?.errorNumber} >
                                            {value?.fieldName}
                                        </li>
                                    ))
                                }
                            </ul>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { setRequiredFieldDialog(false); }} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={branchRequiredFieldDialog} onClose={() => { setBranchRequiredFieldDialog(false) }} TransitionComponent={Transition}
                maxWidth={'sm'}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={() => { setBranchRequiredFieldDialog(false) }}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        ERROR DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText style={{ padding: '5px 15px' }}>
                        <Typography variant="body1" className={classes.requiredLabel} >
                            <div>
                                <WarningIcon className={classes.warningIcon} />
                            </div>
                            <span className={classes.requiredText}>Please Fill all the Required Fields!</span>
                        </Typography>
                        <Typography variant="h6" className={classes.missingLabel}>
                            Missing required fields are :
                        </Typography>
                        <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <ul className={classes.errorfields}>
                                {branchValidationLocalState?.map((value) => (
                                    <li key={value?.errorNumber} >
                                        {value?.fieldName}
                                    </li>
                                ))}
                            </ul>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { setBranchRequiredFieldDialog(false); }} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default MultiLocationDetails