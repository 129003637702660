import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel,
    Paper, Radio, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography
} from '@material-ui/core';
// Icon 
import DateFnsUtils from '@date-io/date-fns';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
// Componant
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useState } from 'react';
import * as constant from "../../constants/Constant";
import { ICustomizeControls, IProcedureName } from '../../models/AgentTemplate/IAgentCustomizeControl';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from './ReportStyleCSS';
import _ from 'lodash';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { IPerformanceReportsResult } from '../../models/Reports/ReportsList';
import { IPreReportControlIdList, IReportControlDetailsResult, IReportsValuesContract } from '../../models/PerformanceReport/IPerformanceReportsResults';

const EditPerformance: React.FC<{ row: IPerformanceReportsResult, onSave: () => void, }> = (props) => {
    const classes = useStyles();
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [reportProcType, setReportProcType] = useState<number>(0);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [reportMasterContorlList, setReportMasterControlList] = useState<ICustomizeControls[]>([]);
    const [reportDynamicList, setReportDynamicList] = useState([]);
    const [ReportDetailsList, setReportDetailsList] = useState<IReportsValuesContract[]>([]);
    const [reportControlDetails, setReportControlDetails] = useState<any>([]);
    const [isReportUpdated, setIsReportUpdated] = useState<boolean>(false);
    const [metaData, setMetaData] = useState<IProcedureName[]>([]);

    let getReportDetails: IPerformanceReportsResult = {
        performanceReportId: row?.performanceReportId,
        performanceReportName: row?.performanceReportName,
        createdDate: row?.createdDate,
        createdBy: row?.createdBy,
        modifiedDate: row?.modifiedDate,
        chartType: row?.chartType,
        metaDataType: row?.metaDataType,
        emailFrequency: row?.emailFrequency,
        emailRecurrence: row?.emailRecurrence,
        isActive: row?.isActive,
        email: row?.email,
        reportProcedureId: row?.reportProcedureId,
        procedureName: row?.procedureName,
        procedureDisplayName: row?.procedureDisplayName
    }
    const [localState, setLocalState] = useState(getReportDetails);

    let dropDownValue;
    let optionItems;

    const CloseMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsReportUpdated(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = () => {
        setOpen(true);
        getControlList();
        getReportsControlValues();
        getMetaData();
    };

    const getReportsControlValues = () => {
        (async () => {
            let request = {
                "performanceReportId": row?.performanceReportId
            }
            await usePost<{ reportControlResults: IReportControlDetailsResult[], reportsValuesContract: IReportsValuesContract[] }>("GetPerformanceReportDetails", request).then((ReportResult) => {
                setReportControlDetails(ReportResult?.data?.reportControlResults);//cc
                setReportDetailsList(ReportResult?.data?.reportsValuesContract);
                setReportDynamicList(ReportResult?.data?.reportControlResults);
            })
        })().finally(() => {
            setOpen(true);
        })
    }

    const handleClose = () => {
        setOpen(false);
        setReportDetailsList([]);
        setLocalState(getReportDetails);
        setReportDynamicList([]);
        setReportControlDetails([]);
        setIsReportUpdated(false);
    };

    const handleChange = () => {

    }

    const handleProcChange = (event) => {
        let name = event.target.name;
        metaData?.forEach((a) => {
            if (event.target.value === a?.procedureDisplayName) {
                setReportProcType(a?.id);
            }
        })
        setLocalState({
            ...localState,
            [name]: event.target.value
        });
    }

    const getMetaData = () => {
        (async () => {
            await useFetch<IProcedureName[]>("GetPerformanceMetaDataFilter").then((r) => {
                setMetaData(r?.data);
            });
        })()
    }

    const getControlList = () => {
        (async () => {
            let request = {
                "activityId": 0,
                "controlId": 0,
                "actionType": 2
            }
            await usePost<ICustomizeControls[]>("AgentUser/GetCustomizeControlById", request).then((r) => {
                setReportMasterControlList(r.data);
            })
        })()
    }

    const handleControlClick = (event) => {
        let NewReportDynamicList = [];
        let controlReport = {
            controlId: event.currentTarget.id,
            controlName: event.currentTarget.name,
            label: event.currentTarget.value,
        }
        NewReportDynamicList.push(controlReport);

        // Filter the duplicate values from the Report control list 
        var NewControlList = NewReportDynamicList.filter((c) => {
            return !reportDynamicList.find((r) => {
                return c.controlId == r.controlId
            })
        });
        setReportDynamicList([...new Set(reportDynamicList?.concat(NewControlList?.map((r) => r)))]);
    }

    const handleRemoveControl = (event) => {
        let newValue = event.currentTarget.id;
        var NewControlList = reportDynamicList.filter((c) => {
            return !reportControlDetails.find((r) => {
                return c.controlId == newValue
            })
        });
        setReportDynamicList(NewControlList);
    }

    const handleReportNameChange = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value,
        });
    }

    const handleUpdatePerformanceReport = () => {
        let reportsControlList = [];
        reportDynamicList?.map((x) => {
            let controlDetail: IPreReportControlIdList = { controlId: x?.controlId }
            reportsControlList.push(controlDetail);
        });
        (async () => {
            let request = {
                "performanceReportId": row?.performanceReportId,
                "performanceReportName": localState?.performanceReportName,
                "modifiedDate": new Date(),
                "metaDataType": 0,
                "chartType": 0,
                "reportProcedureId": reportProcType,
                "preControlMapDetails": reportsControlList,
            }
            await usePost<any>("UpdatePerformanceReport", request).then(() => {
                setReportDynamicList([]);
                setIsReportUpdated(true);
            });
        })().finally(() => {
            handleClose();
            props?.onSave();
        })
    }


    return (
        <div>
            <Button size="small" className={classes.ButtonStyle1}
                variant="contained" startIcon={<AssessmentIcon />} color="primary" onClick={handleClickOpen}>
                Edit
            </Button>

            <Dialog open={open} keepMounted TransitionComponent={Transition} maxWidth={'md'} PaperProps={{ style: { borderRadius: 10 } }} onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        EDIT PERFORMANCE REPORT
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Report Name</b>
                            </Typography>
                            <TextField id="CNT_Name"
                                variant="outlined"
                                fullWidth margin="dense"
                                autoComplete="off"
                                value={localState?.performanceReportName}
                                className={classes.nameStyle}
                                inputProps={{ maxLength: 250 }}
                                label="Enter report name"
                                size="small" name="performanceReportName"
                                onChange={handleReportNameChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Report Type</b>
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <InputLabel htmlFor="outlined-age-native-simple"> Report Type</InputLabel>
                                <Select native onChange={handleChange} label="Select Report Type" >
                                    <option value={10}>Performance Report</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Report-Meta data Type</b>
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <Select native
                                    value={localState?.procedureDisplayName}
                                    onChange={(e) => { handleProcChange(e); }}
                                    name="procedureDisplayName"
                                    inputProps={{
                                        name: 'procedureDisplayName',
                                        id: 'outlined-age-native-simple',
                                    }}>
                                    {metaData.map((r) => {
                                        return (
                                            <option value={r?.procedureDisplayName}>{r?.procedureDisplayName}</option>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Chart Type</b>
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <InputLabel htmlFor="outlined-age-native-simple"> Report Chart Type</InputLabel>
                                <Select native onChange={handleChange} label="Select Report Type" >
                                    <option value={10}>Chart Type 1</option>
                                    <option value={20}>Chart Type 2</option>
                                    <option value={30}>Chart Type 3</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Dynamic Controls</b>
                            </Typography>
                            <TableContainer component={Paper} className={`${classes.gridflex} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table aria-label="customized table">
                                    <TableBody >
                                        {reportMasterContorlList?.map((r) => {
                                            return (
                                                <TableRow>
                                                    <TableCell width="90%" className={classes.controlPadding} style={{ padding: '10.6px' }}>
                                                        <Button name={`${r?.controlName}`}
                                                            id={`${r?.controlId}`}
                                                            value={`${r?.labelName}`}
                                                            size="small" variant="contained" autoFocus
                                                            color="primary" style={{ width: '225px' }}
                                                            onClick={handleControlClick}>
                                                            {r?.labelName}
                                                        </Button>
                                                    </TableCell>

                                                    <TableCell width="10%" className={classes.controlPadding}>
                                                        <IconButton size="small" color="primary"
                                                            name={`${r?.controlName}`}
                                                            id={`${r?.controlId}`}
                                                            value={`${r?.labelName}`}
                                                            onClick={handleControlClick}>
                                                            <ArrowForwardIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        {/* Selected Controls appear here*/}
                        <Grid item xs={12} sm={8} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Selected Controls</b>
                            </Typography>
                            <TableContainer component={Paper} className={`${classes.gridflex} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table aria-label="customized table">
                                    <TableBody >
                                        {reportDynamicList?.map((r) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell width="93%" className={classes.controlPadding}>
                                                            {r?.controlName == constant?.textBox ?
                                                                <TextField id="CNT_Name"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    fullWidth margin="dense"
                                                                    inputProps={{ maxLength: 250 }}
                                                                    label={r?.label}
                                                                    className={classes.controlDisplay}
                                                                    size="small" name="activityDescription"
                                                                />
                                                                : r?.controlName == constant?.dropDownList ?
                                                                    <FormControl size="small" variant="outlined" className={classes.controlDisplay}>
                                                                        <InputLabel htmlFor="outlined-age-native-simple">{r?.label}</InputLabel>
                                                                        <Select native
                                                                            label={`${r?.label}`}
                                                                            autoComplete='false'
                                                                            inputProps={{
                                                                                name: 'role',
                                                                                id: 'outlined-age-native-simple',
                                                                            }}>
                                                                            {dropDownValue = (r?.controlValues)?.split("|")}
                                                                            {optionItems = dropDownValue?.map((r) => {
                                                                                return (
                                                                                    <option value={r}>{r}</option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </FormControl>
                                                                    : r?.controlName == constant?.checkBox ?
                                                                        <>
                                                                            {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                                                                return (
                                                                                    <div className={classes.formControl}>
                                                                                        <Checkbox name={r?.label} disabled style={{ padding: '2px 0px' }} />
                                                                                        <span className={classes.controlName} >{result}</span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                        : r?.controlName == constant?.radioButton ?
                                                                            <>
                                                                                {optionItems = (r?.controlValues)?.split("|")?.map((result) => {
                                                                                    return (
                                                                                        <div className={classes.formControl}>
                                                                                            <Radio name={r?.label} disabled style={{ padding: '2px 0px' }} />
                                                                                            <span className={classes.controlName} >{result}</span>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                            : r?.controlName == constant?.datePicker ?
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <KeyboardDatePicker
                                                                                        autoOk size='small' variant="inline"
                                                                                        autoComplete='off' inputVariant="outlined"
                                                                                        label={r?.label} className={classes.controlDisplay}
                                                                                        format="MM/dd/yyyy" value={selectedDate}
                                                                                        onChange={date => handleDateChange(date)}
                                                                                        InputAdornmentProps={{ position: "end" }} />
                                                                                </MuiPickersUtilsProvider>
                                                                                : r?.controlName == constant?.textArea ?
                                                                                    <TextField id="CNT_Name"
                                                                                        variant="outlined"
                                                                                        autoComplete='off'
                                                                                        multiline rows={4}
                                                                                        fullWidth margin="dense"
                                                                                        inputProps={{ maxLength: 250 }}
                                                                                        label={r?.label}
                                                                                        size="small" name="activityDescription"
                                                                                    />
                                                                                    : null}
                                                        </TableCell>
                                                        <TableCell width="7%" className={classes.controlPadding}>
                                                            <IconButton size="small" color="primary"
                                                                id={`${r?.controlId}`}
                                                                onClick={handleRemoveControl} >
                                                                <CancelIcon style={{ color: 'red' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CPO_OK_btn" size="small" color="primary" startIcon={<ViewTimelineIcon />} className={classes.viewBtn} autoFocus>
                        View Report
                    </Button>
                    <Button id="CPO_OK_btn" size="small" color="primary" startIcon={<CheckCircleIcon />} disabled={!localState?.performanceReportName?.length || !reportDynamicList?.length}
                        onClick={handleUpdatePerformanceReport} className={classes.submitBtn} autoFocus>
                        Submit
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" startIcon={<CancelIcon />} className={classes.cancelBtn} autoFocus onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >
            <Snackbar className="snackBarStyle" open={isReportUpdated} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={CloseMessage}>
                <Alert onClose={CloseMessage} severity="success" className="alertStyle">
                    Performance Report Updated Successfully.
                </Alert>
            </Snackbar>
        </div >
    )
}

export default EditPerformance