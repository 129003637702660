import {
    AppBar, Box, Grid, IconButton, makeStyles, Tab, Tabs, Typography
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@mui/icons-material/Autorenew';
import React, { useEffect, useState } from 'react';
import { rollVerbiage } from '../../constants/Constant';
import lock from "../../images/inquiry/lock.svg";
import Activelock from "../../images/inquiry/unlocked.svg";
import { IAccountHistory, ITransaction } from '../../models/IInquiry';
import { IAccStatus } from '../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { IAgentInvoiceDetailsList } from '../../models/AgentInquiry/InvoiceDetails/IAgentInvoiceDetails';
import { IDebtor } from '../../models/Reports/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import LightTooltipLeft from '../GlobalStyles/LightTooltipLeft';
import { RollSpinner } from '../GlobalStyles/Loading';
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails';
import ReportAccountDetails from './ReportAccountDetails';
import ReportAccountHistory from './ReportAccountHistory';
import ReportTransactionAuditHistory from './ReportTransactionAuditHistory';

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginRight: "2px",
        width: "15%",
        boxShadow: "1px 3px 1px #9E9E9E",
        background: "#800080",
        color: "white",
        borderRadius: 5,
        fontSize: 11,
        height: '0px',
        minHeight: '40px'
    },
    tab1: {
        marginTop: "4px",
        marginLeft: "10px"
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
    rowexpand: {
        display: 'flex',
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 250,
        minWidth: "100%",
        '& .MuiBox-root': {
            padding: '0px 15px 5px'
        }
    },
    image1: {
        width: '30px',
        height: '30px',
    },
    image2: {
        width: '30px',
        height: '25px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    indicator: {
        top: "40px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
    imagegrid: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    iconLabelWrapper: {
        flexDirection: "row"
    },
    image3: {
        width: '30px',
        height: '30px',
        color: '#007FFF'
    }
}))

const ReportsDetail: React.FC<{ DebtorDetails: IDebtor, accountHistory?: IAccountHistory[], disable?: IGetRolledPlacement, transactionHistory?: ITransaction[], isBlocked: boolean, onCheck: () => void }> = (props) => {
    const { DebtorDetails, isBlocked } = props
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const { state } = useGlobalState();
    const UserName = state.userAccessContext?.id;

    const [invoiceDetails, setInvoiceDetails] = useState<IAgentInvoiceDetailsList[]>([]);
    const [GetLetterDetails, setGetLetterDetails] = useState<ILetterDetails[]>([]);
    const [visibleLetter, setVisibleLetter] = useState<IClosedLetterDetails[]>([]);
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [isClosed, setisClosed] = useState<IGetRolledPlacement>();
    const [history, setHistory] = useState<ITransactionHistory[]>([]);
    const [AccDetails, setAccDetails] = useState<IAccStatus>();
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [checkData, setCheckData] = React.useState(false);
    const [invoiceType, setInvoiceType] = useState(1);
    const [spin, setSpin] = React.useState(false);
    const [mapped, setMapped] = useState(false);


    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${DebtorDetails.agencyID}`).then((GetDebtorHistory) => {
                setHistory(GetDebtorHistory.data);
            });
            setMapped(history.length > 0 ? true : false);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${DebtorDetails.agencyID}`).then((GetTransactionHistory) => {
                setTransaction(GetTransactionHistory.data);
            });
            setCheckData(Transaction.length > 0 ? true : false);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${DebtorDetails.agencyID}`).then((r) => {
                setAccDetails(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, [])

    const GetAccStatus = () => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${DebtorDetails.agencyID}`).then((r) => {
                setAccDetails(r?.data);
            });
        })()
    }

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${DebtorDetails.agencyID}`).then((data) => {
                setisClosed(data?.data[0]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, []);

    useEffect(() => {
        if (visibleLetter.length <= 0) {
            getClosedLetterDetails();
        }
    }, []);

    async function getClosedLetterDetails() {
        await useFetch<IClosedLetterDetails[]>(`AccountLetterDetailsGet?DBTNumber=${DebtorDetails.agencyID.toString()}`).then((GetClosedLetterDetails) => {
            setVisibleLetter(GetClosedLetterDetails.data);
        });
    }

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${DebtorDetails.agencyID}`).then((GetHistory) => {
                setHistory(GetHistory.data);
            });
        })()
    }, [])

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": 0,
                "userid": UserName,
                "debtorid": DebtorDetails.agencyID,
                "typeId": 5,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    useEffect(() => {
        (async () => {
            let request = { "debtorId": DebtorDetails.agencyID };
            await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request).then((GetLetterDetails) => {
                setGetLetterDetails(GetLetterDetails.data["letterDetails"]);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${DebtorDetails.agencyID}`).then((data) => {
                setisClosed(data?.data[0]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, []);

    const DisableButton = () => {
        (async () => {
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${DebtorDetails.agencyID}`).then((data) => {
                setisClosed(data.data[0]);
            })
        })()
    }

    // API for get the Agent Invoice Details 
    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            let request = { "dbt_no": DebtorDetails?.agencyID, "clientCode": DebtorDetails?.client };
            await usePost<{ invoiceDetailsResults: IAgentInvoiceDetailsList[], invoiceType: number }>
                ("Invoice/GetInvoiceDetails", request).then((r) => {
                    setInvoiceDetails(r?.data?.invoiceDetailsResults);
                    setInvoiceType(r?.data?.invoiceType);
                }).finally(() => {
                    setshowProgressBar(false);
                });
        })()
    }, [])

    return (
        <Box >
            <AppBar position="static" color="transparent" className={classes.rowexpand}>
                <Tabs id="R_Tab" className={classes.tab1}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="R_AccountDetails" className={`${classes.tabs} ${classes.iconLabelWrapper}`} label="Account Details" />
                    <Tab id="R_AccountHistory" className={classes.tabs} label="Account History" />
                    <Tab id="R_Transaction Audit History" className={classes.tabs} label="Transaction Audit History" />
                    {state?.userAccessContext?.role === 3 || state?.userAccessContext?.role === 2 ?
                        <Tab id="AI_InvoiceDetails" className={classes.tabs} label="Invoice Details" />
                        : null
                    }
                    <Grid item xs={7} className={classes.imagegrid}>
                        {isClosed?.rejectedRoll === true ?
                            <IconButton id="Active_Status" className="close-account-info" >
                                <LightTooltipLeft title={rollVerbiage}>
                                    <InfoIcon className={classes.image1} />
                                </LightTooltipLeft>
                            </IconButton>
                            : null
                        }
                        {isBlocked ?
                            <React.Fragment>
                                <IconButton id="Active_Status" >
                                    <img src={lock} alt="Lock image for account Details" className={classes.image2} />
                                </IconButton>
                                <IconButton id="Active_Status" onClick={(_) => { props.onCheck(); refreshCanvas(); }}>
                                    <Autorenew fontSize="medium" className={`${spin ? classes.rotateIcon : ""}`} />
                                </IconButton>
                            </React.Fragment>
                            :
                            <IconButton id="Active_Status" >
                                <img src={Activelock} alt="UnLock image for account Details" className={classes.image1} />
                            </IconButton>
                        }
                    </Grid>
                </Tabs>
                <TabPanel value={value} index={0}>
                    {ProgressBar ? <RollSpinner /> :
                        <React.Fragment>
                            <ReportAccountDetails debtors={DebtorDetails} letterDetails={visibleLetter} isEnable={isClosed} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} GetDebtorLetterDetails={GetLetterDetails} GetAccStatus={AccDetails} OnGetDetails={GetAccStatus} onEroll={DisableButton} />
                        </React.Fragment>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    < ReportAccountHistory debtors={DebtorDetails} histories={history} mapped={mapped} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ReportTransactionAuditHistory debtors={DebtorDetails} transactions={Transaction} mapped={checkData} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <InvoiceDetails invoiceDetails={invoiceDetails} invoiceType={invoiceType} />
                </TabPanel>
            </AppBar >
        </Box >
    )
}

export default ReportsDetail