import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react'
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory'
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { useGlobalState } from '../../../store/GlobalStore';
import { Box, Typography } from '@material-ui/core';
import useStyles from '../NCCcss/NotiCenterCss';

const TransactionAuditHistory: React.FC<{ debtors: IDebtor, transactions: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { transactions } = props;
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    // Inspired by the microsoft spinners.
    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    return (
        <React.Fragment >
            <Box p={0} style={{ padding: '0px 10px' }}>
                <h3 className={classes.typoTitle}> Transactions</h3>
                <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.rootTransAuditHis} >
                    <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        autoHeightMin={150}
                        autoHeightMax={150}
                        width="100%">
                        {transactions.length > 0 ?
                            transactions.map((transactionsHistories) => {
                                if (transactionsHistories.activityType !== "0") {
                                    return (
                                        < Typography gutterBottom className={classes.typoRecordData}>
                                            <p>  {moment(transactionsHistories.activityDt).format(state.GlobalUtils?.settingValue)}
                                                &nbsp; {transactionsHistories.activity}
                                            </p>
                                        </Typography>
                                    )
                                } else {
                                    return (
                                        < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                                            No Records To Display
                                        </Typography>
                                    )
                                }
                            })
                            : <Progress />
                        }
                    </Scrollbars>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default TransactionAuditHistory
