import React from "react";
import { useState } from "react";
import { PuffLoader } from 'react-spinners';
import { useStyles } from "./ProspectUserCss";
import { Dialog, Button } from "@mui/material";
import { usePost } from "../../utils/apiHelper";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useGlobalState } from "../../store/GlobalStore";
import { Backdrop, Box, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { regexValidEmail } from "../../constants/Constant";
import orgName from "../../images/agentUser/newOrganization.svg";
import updateEmail from "../../images/agentUser/updateEmail.svg";
import { Transition } from "../GlobalStyles/DialogBoxTransition";
import { DialogTitleDelete, DialogTitleHeader } from "../GlobalStyles/DialogStyle";
import { IProspectUsersDetails } from "../../models/accesscontrol/ProspectUsersDetails";

const TransferOwnership: React.FC<{ usersListResult: IProspectUsersDetails[], onUpdate: () => void }> = (props) => {
  const { usersListResult } = props;
  const classes = useStyles();
  const { state } = useGlobalState();
  const [openDialog, setOpen] = useState(false);
  const [Emailerror, setEmailError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [ModifiedReason, setModifiedReason] = useState("");
  const [ProgressBar, setshowProgressBar] = useState(false);
  const [negatedUserOpen, setNegatedUserOpen] = useState(false);
  const [isNewOrganization, setIsNewOrganization] = useState(0);
  const [swtichOrganization, setSwtichOrganization] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [selectedUserDetail, setSelectedUserDetail] = useState<any>([]);
  const [newOrganizationAdminEmail, setNewOrganizationAdminEmail] = useState<string>("");
  // Filter the list based on the condition
  const filteredItems = usersListResult?.filter((v) => v?.isNewOrginization)

  const CheckUserExists = "AgentUser/CheckUserExists";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedUserDetail([]);
    setSelectedUser([]);
    setOpen(false);
    setEmailError("");
    setShowErrorMessage("");
    setModifiedReason("");
    setNegatedUserOpen(false);
    setSwtichOrganization(false);
    setNewOrganizationAdminEmail("");
  };

  const handleOrganizationOpen = () => {
    if (selectedUser?.length) {
      setSwtichOrganization(true);
    } else {
      setShowErrorMessage("Prospect User must be selected");
    }
  };

  const handleOrganizationClose = () => {
    setEmailError("");
    setModifiedReason("");
    setShowErrorMessage("");
    setSwtichOrganization(false);
    setNewOrganizationAdminEmail("");
  };

  // this function to read the Reason for why the user admin email is changed or why the account is switch to new client setup
  const handleModifiedReason = (event) => {
    setModifiedReason(event.target.value);
  }

  const handleNegatedUserClose = () => {
    setNegatedUserOpen(false);
  }

  const handleConfirmNegatedUser = () => {
    setNegatedUserOpen(true);
  }

  const handleSelectedUser = (event, selected) => {
    if (selected === null) {
      setSelectedUser([]);  // Set to an empty array when no user is selected
      setSelectedUserDetail([]);
      setShowErrorMessage("Prospect User must be selected");
    } else {
      setShowErrorMessage("");
      setSelectedUser([selected]);  // Update with the selected user in an array
      setSelectedUserDetail(selected);
    }
  };

  const handleSubmit = () => {
    setshowProgressBar(true);
    (async () => {
      let request = {
        "prospectUserId": selectedUser[0]?.prospectUserId,
        "userId": state?.userAccessContext?.id,
        "isOrganizationEmailUpdated": isNewOrganization === 1 ? 2 : 1,
        "reason": ModifiedReason,
        "organizationAdminEmailId": isNewOrganization === 1 ? selectedUser[0]?.prospectEmailAddress : newOrganizationAdminEmail,
        "oldAdminEmailId": selectedUser[0]?.organizationAdminEmailId
      }
      await usePost("User/InsertProspectUserHistory", request).then((r) => {
      }).finally(() => {
        // Callback function once the modification history is tracked
        props?.onUpdate();
        handleClose();
        setshowProgressBar(false);
      });
    })()
  };

  const handleNewEmailVal = (e) => {
    const email = e.target.value;
    if (regexValidEmail.test(email)) {
      (async () => {
        setIsLoading(false);
        let checkUser = { "emailAddress": email };
        await usePost<any>(CheckUserExists, checkUser).then(async (r) => {
          if (!r?.data[0]?.isWebViewUser && !r?.data[0]?.isAgentUser) {
            setEmailError("User not exists in WebView, Please try alternate Email!");
          } else {
            setEmailError("");
          }
        }).finally(() => {
          setIsLoading(false);
        });
      })()
    } else if (!e.target.value?.trim()?.length) {
      setEmailError("");
    }
    else {
      setEmailError("Please enter valid mail address");
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" size="small" style={{ borderRadius: "20px", marginTop: "5px" }} onClick={handleClickOpen} >
        TRANSFER OWNERSHIP
      </Button>

      <Dialog open={openDialog} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" maxWidth={"md"} >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="body1" gutterBottom className={classes.titleheader} >
            <b>TRANSFER OWNERSHIP</b>
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0} boxShadow={4} component={Paper} marginTop={2} >
            <Grid item xs={6} padding={2} borderRight={"1px dashed lightgray"}>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                id="combo-box-demo"
                options={filteredItems}
                className={classes.clientCodeBox}
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                value={selectedUserDetail}
                getOptionLabel={(option) => option.prospectEmailAddress}
                onChange={(e, selected) => handleSelectedUser(e, selected)}
                getOptionSelected={(option, value) => option.prospectEmailAddress === value.prospectEmailAddress}
                renderOption={(option, { selected }) => (
                  <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '5px', width: 200 }}>
                    {option.prospectEmailAddress}
                  </span>
                )}
                ListboxProps={{ style: { maxHeight: '160px' } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={!!showErrorMessage}
                    helperText={showErrorMessage}
                    variant="outlined"
                    label="Select Prospect User"
                  />
                )}
              />
              <Grid container spacing={1} mt={0.5} xs={12}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    <b>First Name</b>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    : {selectedUser[0]?.prospectGivenName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    <b>Last Name </b>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    : {selectedUser[0]?.prospectLastName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    <b>Organization Name </b>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    : {selectedUser[0]?.organizationName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    <b>Sign Up Date </b>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    : {selectedUser[0]?.prospectCreatedDate}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    <b>Admin Email Address </b>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                    : {selectedUser[0]?.organizationAdminEmailId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} padding={2}>
              <Grid container display={"flex"}>
                <Grid item xs={12} component={Paper} className={classes.organizationGrid} onClick={() => { handleOrganizationOpen(); setIsNewOrganization(1); }} p={1} mt={1} >
                  <Box m={0} width="20%">
                    <img src={orgName} width="50px" alt="org-name" />
                  </Box>
                  <Box m={0} width="80%">
                    <Typography variant="subtitle1">
                      <b>Switch to Existing Organization</b>
                    </Typography>
                    {selectedUser[0]?.organizationName?.length ? (
                      <Typography variant="body2">
                        {selectedUser[0]?.organizationName}
                      </Typography>
                    ) : (
                      <Typography variant="body2" style={{ color: "red" }}>
                        Information not provided
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.organizationGrid} onClick={() => { handleOrganizationOpen(); setIsNewOrganization(2); }} p={1} mt={1} >
                  <Box m={0} width="20%">
                    <img src={updateEmail} width="50px" alt="org-name" />
                  </Box>
                  <Box m={0} width="80%">
                    <Typography variant="subtitle1"> <b>Update Admin Email Address</b> </Typography>
                    {selectedUser[0]?.organizationAdminEmailId?.length ? (
                      <Typography variant="body2">
                        {selectedUser[0]?.organizationAdminEmailId}
                      </Typography>
                    ) : (
                      <Typography variant="body2" style={{ color: "red" }}>
                        Information not provided
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose} color="error" sx={{ borderRadius: 4 }} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog TransitionComponent={Transition} open={swtichOrganization} onClose={handleOrganizationClose} maxWidth={"sm"} aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleOrganizationClose} >
          {isNewOrganization == 1 ? (
            <Typography variant="h6" className={classes.titleheader}>
              CONFIRMATION MESSAGE
            </Typography>
          ) : isNewOrganization == 2 ? (
            <Typography variant="h6" className={classes.titleheader}>
              UPDATE ADMIN EMAIL
            </Typography>
          ) : null}
        </DialogTitleDelete>
        <DialogContent>
          {isNewOrganization == 1 ? (
            <>
              <Typography variant="subtitle1" className={classes.textfield} gutterBottom >
                Are you sure, you want to <b>"Switch to Existing Organization"?</b>
              </Typography>

              <Grid container spacing={1} style={{ padding: "5px" }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.textStyle}>
                    <b>Please enter the reason to change Existing organization</b>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    multiline
                    rows={4}
                    label="Please enter the reason"
                    variant="outlined"
                    size="small"
                    onChange={(e) => { handleModifiedReason(e); }}
                    className={classes.newEmailAddressStyle}
                  />
                </Grid>
              </Grid>
            </>
          ) : isNewOrganization == 2 ? (
            <Grid container spacing={1} style={{ padding: "5px" }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" className={classes.textStyle}>
                  <b>Organization Name</b>
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Organization Name"
                  variant="outlined"
                  value={selectedUser[0]?.organizationName}
                  disabled
                  size="small"
                  className={classes.newEmailAddressStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" className={classes.textStyle}>
                  <b>Organization Admin Email</b>
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Current Admin Email Id"
                  value={selectedUser[0]?.organizationAdminEmailId}
                  variant="outlined"
                  size="small"
                  disabled
                  className={classes.newEmailAddressStyle}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.textStyle}>
                  <b>New Admin Email Address</b>
                </Typography>
                <div style={{ display: 'flex' }}>
                  <TextField
                    id="outlined-basic"
                    label="New Email Address"
                    variant="outlined"
                    size="small"
                    onChange={(e) => { handleNewEmailVal(e); setNewOrganizationAdminEmail(e.target.value); }}
                    helperText={Emailerror} error={!!Emailerror}
                    className={classes.newEmailAddressStyle}
                  />
                  {isloading ?
                    <div className={classes.loadingDiv}>
                      <div className={`${'loader2'} ${classes.spinnerStyle}`} />
                    </div>
                    :
                    null
                  }
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.textStyle}>
                  <b>
                    Please enter the reason to change New Admin Email Address
                  </b>
                </Typography>
                <TextField
                  id="outlined-basic"
                  multiline
                  rows={4}
                  label="Please enter the reason"
                  variant="outlined"
                  size="small"
                  onChange={(e) => { handleModifiedReason(e); }}
                  className={classes.newEmailAddressStyle}
                />
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          {isNewOrganization == 1 ? (
            <>
              <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} className={classes.yesButton} style={{ backgroundColor: 'green' }}
                disabled={!ModifiedReason?.length} onClick={handleSubmit} >
                Yes
              </Button>
              <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleOrganizationClose}
                color="primary" style={{ backgroundColor: 'red' }} className={classes.noButton} >
                No
              </Button>
            </>
          ) : isNewOrganization == 2 ? (
            <>
              <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} className={classes.yesButton} style={{ backgroundColor: 'green' }}
                disabled={isDisable || !newOrganizationAdminEmail?.length || !ModifiedReason?.length || !!Emailerror?.length} onClick={handleConfirmNegatedUser} >
                Submit
              </Button>
              <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleOrganizationClose}
                color="primary" style={{ backgroundColor: 'red' }} className={classes.noButton} >
                Close
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>


      <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 10 } }} open={negatedUserOpen} onClose={handleNegatedUserClose}
        aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'xs'} >
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleNegatedUserClose}>
          <Typography variant="h6" className={classes.titleheader}>
            INFORMATION MESSAGE
          </Typography>
        </DialogTitleHeader>
        <DialogContent >
          <Typography variant='body2'>
            This Client/Users has been considered as <b>"Requesting WebView Access User".</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />}
            className={classes.yesButton} onClick={handleSubmit}>
            Yes
          </Button>
          <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleNegatedUserClose} color="primary" className={classes.noButton}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransferOwnership;
