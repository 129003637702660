import {
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StyledTableRow from "../GlobalStyles/StyledTableRow";
import StyledCell from "../GlobalStyles/StyledCell";
import { useStyles } from "./FileUploadCSS";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

function createData(
  name: string,
  calories: string,
  fat: number,
  carbs: number,
  protein: number,
  price: number,
  // recivedTime: Date,
  // process: string,
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

const steps = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box style={{ maxWidth: 400, marginLeft: "64px" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              style={{
                color: index === 1 ? "blue" : "black",
                marginLeft: "5px",
                fontWeight:'bold'
              }}
              icon={
                index === 1 ? (
                  <IconButton
                    style={{
                      padding: "5px",
                      backgroundColor: "rgb(32 85 121)",
                      color: "white",
                    }}
                  >
                    <SettingsSuggestIcon style={{ color: "white" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{
                      padding: "5px",
                      backgroundColor: "rgb(32 85 121)",
                      color: "white",
                    }}
                  >
                    <CloudUploadIcon style={{ color: "white" }} />
                  </IconButton>
                )
              }
            >
              <b>{step.label}</b>
            </StepLabel>
            <Typography style={{ marginLeft: "50px", width: "200%" }}>
              {step.description}
            </Typography>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const UploadedFiles = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow style={{ borderBottom: "unset" }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.calories}</TableCell>
          <TableCell align="right">{row.fat}</TableCell>
          <TableCell align="right">{row.carbs}</TableCell>
          <TableCell align="right">{row.protein}</TableCell>
          <TableCell align="right">{row.price}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box style={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableBody>{VerticalLinearStepper()}</TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = [createData("Test Document 1", "CSV", 6.0, 24, 4.0, 3.99)];

  const FileList = [
    { title: "Uploaded Document1" },
    { title: "Uploaded Document2" },
    { title: "Uploaded Document3" },
    { title: "Uploaded Document4" },
    { title: "Uploaded Document5" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
    { title: "Uploaded Document6" },
  ];

  return (
    <div>
      <Box style={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h5"
              style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
            >
              <b>File Manager</b>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TableContainer
              component={Paper}
              className={`${
                classes.tablebody
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table aria-label="collapsible table" stickyHeader>
                <TableHead
                  style={{
                    backgroundColor: "rgb(0 127 255)",
                    color: "white",
                    fontSize: "18px",
                  }}
                >
                  <TableRow
                    style={{
                      backgroundColor: "rgb(0 127 255)",
                      color: "white",
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white", justifyContent:'center', alignItems:'center'
                      }}
                    >
                      FILES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FileList?.map((r) => {
                    return (
                      <StyledTableRow>
                        <StyledCell align="left" scope="row">
                          <Typography variant="subtitle1">
                            {r?.title}
                          </Typography>
                        </StyledCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={10}>
            <TableContainer
              component={Paper}
              className={`${
                classes.tablebody
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table aria-label="collapsible table" stickyHeader>
                <TableHead
                  style={{ backgroundColor: "rgb(0 127 255)", color: "white" }}
                >
                  <TableRow
                    style={{
                      backgroundColor: "rgb(0 127 255)",
                      color: "white",
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                    />
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                    >
                      FILE NAME
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                      align="right"
                    >
                      FILE FORMAT
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                      align="right"
                    >
                      FILE SIZE
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                      align="right"
                    >
                      TOTAL COUNT
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                      align="right"
                    >
                      NOTIFICATION SENT TO CLIENT
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(0 127 255)",
                        color: "white",
                      }}
                      align="center"
                    >
                      TEST
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UploadedFiles;
