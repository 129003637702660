import { AppBar, Box, IconButton, makeStyles, createStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Toolbar, Typography, Dialog, Paper } from '@material-ui/core';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import AvatarTooltip from '../../GlobalStyles/AvatarTooltip';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import printImg from "../../../images/ACKReport/AckPrint.svg";
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import CloseIcon from '@material-ui/icons/Close';
import Footer from '../../Footer/Footer';
import { IAgentExportReport } from '../../../models/AgentReport/IAgentReportContract';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        tablebodyPrint: {
            overflowX: 'hidden',
            marginTop: 0,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 118vh - 260px)',
                minHeight: 'calc( 118vh - 260px)',
            },
        },
        appBar: {
            position: 'relative',
            color: 'white',
            backgroundColor: '#007fff',
            minHeight: '35px'
        },
        printTitle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 18,
            textAlign: 'center'
        },
        CloseIconColor: {
            color: 'white'
        },
        report: {
            overflowX: 'hidden'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            width: 150
        },
        textStyle1: {
            textAlign: 'right',
            marginTop: '15px',
            marginRight: 10,
            fontSize: '14px'
        },
        textStyle: {
            textAlign: 'right',
            marginTop: '15px',
            fontSize: '14px'
        },
        bottomFooter: {
            marginBottom: '5px'
        },
        wrapper: {
            position: 'relative'
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            fontSize: "14px"
        },
        tableCellColor: {
            background: "#007FFF",
            color: "white",
            padding: '3px 10px',
            fontSize: '11px'
        },
        tableCellStyle: {
            padding: '5px 10px',
            fontSize: '12px'
        },
        spinnerStyle: {
            marginTop: '350px',
            marginBottom: '5px'
        }
    })
);

const AgentPrint = (props) => {
    const { isAvailable, startDate, endDate, client_code } = props;
    const { state } = useGlobalState();
    const classes = useRowStyles();
    const [printbox, setPrintBox] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const pageStyle = `{ size: 11.7in 16.5in}`;
    const [loading, setloading] = useState(false);
    const [printagentreport, setPrintAgentReport] = useState<IAgentExportReport[]>([]);

    const GetAgentReportPrint = () => {
        setloading(true);
        (async () => {
            let requestPrint = {
                "clientCodes": client_code,
                "startdate": moment(startDate)?.format("YYYY-MM-DD"),
                "enddate": moment(endDate)?.format("YYYY-MM-DD"),
                "orderDirection":  1,
                "orderBy": "ActivityFieldId"
            }
            await usePost<{ agentReportList: IAgentExportReport[], totalCount: number }>("AgentUser/ExportAgentReports", requestPrint)?.then((Printagentreport) => {
                setPrintAgentReport(Printagentreport?.data?.agentReportList);
            }).finally(() => {
                setloading(false);
            });
        })()
    }

    const handleOpenDialog = () => {
        setPrintBox(true);
        GetAgentReportPrint();
    }

    const handleCloseDialog = () => {
        setPrintBox(false);
    }

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <AvatarTooltip title="Print Report">
                    <IconButton>
                        <img src={printImg} alt="Icon for Print" width="25px" height="25px" onClick={(_) => { isAvailable ? handleOpenDialog() : null }} />
                    </IconButton>
                </AvatarTooltip>
            </div>
            <Dialog fullScreen open={printbox} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} >
                    <Toolbar className={classes.appBar}>
                        <Box display="flex" alignItems="center" alignContent="center" width="95%">
                            <Typography variant="h6" className={classes.printTitle}>
                                AGENT ACTIVITY REPORTS
                            </Typography>
                        </ Box>
                        <Box display="flex" alignItems="right" alignContent="right" width="5%">
                            <div>
                                <style type="text/css" media="print">
                                    {"\ @page { size: landscape; }\ "}
                                </style>
                                <ReactToPrint pageStyle={pageStyle}
                                    trigger={() =>
                                        <LightTooltip title="Print Reports List">
                                            <IconButton color="primary" id="RB_PrintIcon" aria-label="print" component="label">
                                                <img src={printImg} alt="Icon for Print" width="22px" height="22px" />
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                    documentTitle={`Agent Activity Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                                    content={() => componentRef.current}
                                />
                            </div>
                            <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                                <CloseIcon fontSize="small" className={classes.CloseIconColor} />
                            </IconButton>
                        </ Box>
                    </Toolbar>
                </AppBar>
                <div ref={componentRef} className={classes.report}>
                    {loading === true ?
                        <React.Fragment>
                            <Box display="flex" alignItems="center" justifyContent="center" className={classes.spinnerStyle}>
                                <div className="loader" />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" >
                                <span>Loading Report Print Preview</span>
                            </Box>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Box display='flex' m={3} >
                                <TableContainer component={Paper} className={`${classes.tablebodyPrint} ${"scrollbox"} ${"on-scrollbar"}`} >
                                    <Table id="AgentActivityReportsDetailList" aria-label="customized table" size="small" stickyHeader >
                                        <TableHead>
                                            <TableRow className={classes.tableHeaderStyle}>
                                                <TableCell className={classes.tableCellColor}>
                                                    CLIENT CODE
                                                </TableCell>
                                                <TableCell className={classes.tableCellColor}>
                                                    EMAIL ADDRESS
                                                </TableCell>
                                                <TableCell className={classes.tableCellColor}>
                                                    FULL NAME
                                                </TableCell>
                                                <TableCell className={classes.tableCellColor}>
                                                    ACTIVITY
                                                </TableCell>
                                                <TableCell className={classes.tableCellColor}>
                                                    TOTAL COUNT
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {printagentreport?.map((row) => {
                                                return (
                                                    <TableRow hover>
                                                        <TableCell className={classes.tableCellStyle}>{row?.clientCode}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.emailAddress}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.fullName}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.activityName}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.totalCount}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <div className={classes.bottomFooter}>
                                <Footer />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </Dialog>
        </React.Fragment>
    )

}

export default AgentPrint

