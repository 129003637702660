import { Backdrop, Box, Button, CircularProgress, createStyles, IconButton, makeStyles, Paper, Table, TableHead, TablePagination, TableSortLabel, Theme, Toolbar, Typography } from '@material-ui/core';
import { Grid, TableBody, TableContainer } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { PuffLoader } from 'react-spinners';
import { useGlobalState } from '../../../store/GlobalStore';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import download from "../../../images/files/download01.svg";
import { IRollClients } from '../../../models/Roll/IRollClients';
import { usePost } from '../../../utils/apiHelper';
import Footer from '../../Footer/Footer';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../GlobalStyles/TableStyledCell';
import AgentExpandableRow from './AgentExpandableRow';
import AgentPrint from './AgentPrint';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { IAgentExportReport } from '../../../models/AgentReport/IAgentReportContract';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        div: {
            flexGrow: 1, 
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        gridPadding: {
            padding: '8px'
        },
        tablebody: {
            maxHeight: 700,
            minHeight: 700,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 218px)',
                minHeight: 'calc( 100vh - 218px)',
            },
        },
        noRecordsStyle: {
            color: "red",
            marginTop: "5px",
            fontSize: 18
        },
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            border: '1px solid gray',
            minHeight: '35px'
        },
        tableHeadRadius: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px'
        },
        titleColor: {
            color: "blue",
            fontWeight: 'bold',
        },
        wrapper: {
            position: 'relative'
        },
        backbutton: {
            borderRadius: 20,
            fontSize: 11,
            fontWeight: 'bold',
            background: "#007FFF",
            color: "white",
            marginRight: '10px'
        },
        printStyle: {
            borderRadius: 30,
            marginTop: '5px',
            padding: '2px 2px'
        },
        downloadIconStyle: {
            color: "#a1a1a1",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '10px'
        },
        downloadIconStyle1: {
            color: "#2377E4",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '1%'
        },
        fabProgressPrint: {
            color: green[500],
            position: 'absolute',
            top: 3,
            left: 8,
            zIndex: 1,
        },
        footerBoxStyle: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        buttonalign1: {
            borderRadius: 50
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            padding: '5px 0px 5px 5px !important',
            fontSize: '11px'
        },
    })
)

const GenerateAgentReport = (props) => {
    const classes = useRowStyles();
    const [loading, setLoading] = useState(false);
    const [agentReportDetails, setAgentReportDetails] = useState<IAgentExportReport[]>([]);
    const [order, setOrder] = useState("DBT_NO");
    const [orderBy, setOrderBy] = useState<string>("");
    const { state } = useGlobalState();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [TotalRecords, setTotalRecords] = useState<number>(0);
    const [exportagentreport, setExportAgentReport] = useState<IAgentExportReport[]>([]);

    let client_code: IRollClients[] = props?.location?.state?.client_code;
    let startDate: string = props?.location?.state?.startdate;
    let endDate: string = props?.location?.state?.enddate;

    startDate = moment(startDate).format(state?.GlobalUtils?.settingValue)

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const headers = [
        {
            label: "CLIENT CODE",
            key: "clientCode"
        },
        {
            label: "Email Address",
            key: "emailAddress"
        },
        {
            label: "FULL NAME",
            key: "fullName"
        },
        {
            label: "COMMUNICATION METHOD",
            key: "communicationMethod"
        },
        {
            label: "ACTIVITY NAME",
            key: "activityName"
        },
        {
            label: "TOTAL COUNT",
            key: "totalCount"
        },
    ]

    useEffect(() => {
        (async () => {
            let request = {
                "clientCodes": client_code,
                "startdate": startDate,
                "enddate": endDate,
                "rowsPerPage": rowsPerPage,
                "orderDirection": order === "asc" ? 0 : 1,
                "startingRecordNumber": (page * rowsPerPage) + 1,
                "orderBy": "ActivityFieldId"
            }
            setLoading(true);
            await usePost<{ agentReportList: IAgentExportReport[], totalCount: number }>("AgentUser/GetAgentReport", request).then((agentreport) => {
                setAgentReportDetails(agentreport?.data?.agentReportList);
                setTotalRecords(agentreport?.data?.totalCount);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [page, order, orderBy, rowsPerPage])

    useEffect(() => {
        (async () => {
            let request = {
                "clientCodes": client_code,
                "startdate": moment(startDate)?.format("YYYY-MM-DD"),
                "enddate": moment(endDate)?.format("YYYY-MM-DD"),
                "orderDirection": order === "asc" ? 0 : 1,
                "orderBy": "ActivityFieldId"
            }
            await usePost<{ agentReportList: IAgentExportReport[], totalCount: number }>("AgentUser/ExportAgentReports", request)?.then((Exportagentreport) => {
                setExportAgentReport(Exportagentreport?.data?.agentReportList);
                setTotalRecords(Exportagentreport?.data?.totalCount);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [])

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map(el => el[0]);
    }

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const EnhancedTableToolbar = () => {
        const classes = useRowStyles();

        return (
            <Toolbar className={`${classes.root} ${classes.tableHeadRadius}`}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Box p={0} flexShrink={0} width="89%" display="flex" >
                    <Typography variant="h6" className={`${classes.titleColor} ${"headertitle"}`}>
                        {`Agent Activity Reports - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                    </Typography>
                </Box>
                <Box p={0} width="5%" >
                    <div className={classes.wrapper}>
                        <LightTooltip title="Back">
                            <Button id="PLC_Back_btn" className={classes.backbutton}
                                variant="contained" color="primary" size="small" component={Link} to="/agentreport" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}>
                                Back
                            </Button>
                        </LightTooltip>
                    </div>
                </Box>
                <Box p={0} width="3%" >
                    <AgentPrint client_code={client_code} startDate={startDate} endDate={endDate} isAvailable={exportagentreport?.length > 0 ? true : false} />
                </Box>
                <Box p={0} width="3%" >
                    <div className={classes.wrapper}>
                        <LightTooltip title="Download Reports List">
                            <IconButton disabled={!exportagentreport.length} className={classes.printStyle}>
                                <CSVLink
                                    data={exportagentreport}
                                    headers={headers}
                                    filename={`Agent Activity Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`}
                                    target="_blank" >
                                    <img src={download} alt="Icon for Download" width="30px" height="30px" className={!exportagentreport.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                                </CSVLink>
                                {loading && <CircularProgress size={40} className={classes.fabProgressPrint} />}
                            </IconButton>
                        </LightTooltip>
                    </div>
                </Box>
            </Toolbar>
        );
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "dbT_COMPANY", disablePadding: false, label: "CLIENT CODE", sortable: true, fontSize: '12px' },
            { id: "placemenT_DATE", disablePadding: false, label: "EMAIL ADDRESS", sortable: true, fontSize: '12px' },
            { id: "dbT_NO", disablePadding: false, label: "FULL NAME", sortable: true, fontSize: '12px' },
            { id: "communication_method", disablePadding: false, label: "COMMUNICATION METHOD", sortable: true, fontSize: '12px' },
            { id: "placemenT_DATE", disablePadding: false, label: "ACTIVITY NAME", sortable: true, fontSize: '12px' },
            { id: "dbT_CLNT_ACNT1", disablePadding: false, label: "TOTAL COUNT", sortable: true, fontSize: '12px' },
        ];

        return (
            <TableHead>
                <StyledTableRow >
                    {headCells?.map(headCell => (
                        <StyledTableCell size="small"
                            key={headCell?.id}
                            sortDirection={orderBy === headCell?.id ? order : false}
                            className={classes.tableHeaderStyle}
                            style={{ fontSize: `${headCell.fontSize}` }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell?.id}
                                direction={orderBy === headCell?.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell?.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))
                    }
                </StyledTableRow>
            </TableHead >
        );
    }


    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.gridPadding}>
                    <Grid item xs={12} component={Paper}>
                        <EnhancedTableToolbar />
                        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(agentReportDetails, getComparator(order, orderBy))?.map((r) => {
                                        return (
                                            <AgentExpandableRow row={r} />
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                            {!loading && !agentReportDetails?.length ? <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                No records to display...
                            </Typography> : null}
                        </TableContainer>
                        <Grid container spacing={0} className={classes.footerBoxStyle}>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Inquiry_TablePagination"
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={TotalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default GenerateAgentReport