
import { Box, IconButton, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import useStyles from '../../GlobalStyles/AccountDetailCss';
// Icons
import Snackbar from '@material-ui/core/Snackbar';
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
// Models
import { IPlacedAccount } from '../../../models/Inquiry/IPlacedAccount';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const Submit: React.FC<{ clientId: string, dbtNo: string, placedAccounts: IPlacedAccount[], onSubmit: () => void, BlockAwaitAccess: boolean }> = (props) => {
    const classes = useStyles();
    const { dispatch } = useGlobalState();
    const [undoSubmit, setundoSubmit] = React.useState(false);

    const undoAdjustment = (adjustmentAmount: number, adjustmentType: string) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "Dbt_No": props?.dbtNo,
                    "AdjustmentAmount": adjustmentAmount.toFixed(2),
                    "AdjustmentType": adjustmentType
                }
                await usePost<any>("Account/UndoAdjustment", request);
                await useFetch<any>("Account/UndoPaymentAmount");
                props.onSubmit();
                setundoSubmit(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setundoSubmit(false);
    };

    if (props.placedAccounts.find(x => x?.prpcl_Pay_Amt > 0 || x?.prcpl_Adj_Amt != 0 || x?.fee_Pay_Amt > 0 || x?.fee_Adj_Amt != 0)) {
        return (
            <React.Fragment>
                <Box className={classes.rootSubmit} margin={2} >
                    <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        autoHeightMin={80}
                        autoHeightMax={80}
                        style={{ width: "100%" }}>
                        <div className={classes.submitDiv}>
                            <Typography variant="overline" display="block" className={classes.textSpan}>
                                Pending...
                            </Typography>
                            {props.placedAccounts.reverse().map(p => {
                                return (
                                    <React.Fragment>
                                        <span className={classes.textSpan2}>
                                            {Math.abs(p?.prpcl_Pay_Amt) > 0 ? <div >Principal Payment: {p?.prpcl_Pay_Amt.toFixed(2)} <IconButton disabled={props?.BlockAwaitAccess} id="I_Undo_PrincipalPayment" onClick={() => { setundoSubmit(true); undoAdjustment(p?.prpcl_Pay_Amt, "Principal Payment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                            {Math.abs(p?.prcpl_Adj_Amt) > 0 ? <div>Principal Adjustment: {p?.prcpl_Adj_Amt.toFixed(2)}<IconButton disabled={props?.BlockAwaitAccess} id="I_Undo_PrincipalAdjustment" onClick={() => { setundoSubmit(true); undoAdjustment(p?.prcpl_Adj_Amt, "Principal Adjustment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                            {Math.abs(p?.fee_Pay_Amt) > 0 ? <div>Late Fee Payment: {p?.fee_Pay_Amt.toFixed(2)}<IconButton disabled={props?.BlockAwaitAccess} id="I_Undo_FeePayment" onClick={() => { setundoSubmit(true); undoAdjustment(p?.fee_Pay_Amt, "Fee Payment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                            {Math.abs(p?.fee_Adj_Amt) > 0 ? <div>Late Fee Adjustment: {p?.fee_Adj_Amt.toFixed(2)}<IconButton disabled={props?.BlockAwaitAccess} id="I_Undo_FeeAdjustment" onClick={() => { setundoSubmit(true); undoAdjustment(p?.fee_Adj_Amt, "Fee Adjustment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                        </span>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </Scrollbars>
                    <Snackbar className="snackBarStyle" open={undoSubmit} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                            Undo Transaction Successfully!
                        </Alert>
                    </Snackbar>
                </Box>

            </React.Fragment>
        )
    }
    else {
        return (null)
    }
}

export default Submit