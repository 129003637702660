import { Checkbox, FormControl, FormControlLabel, ListItemText, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { IActivityContract } from '../../../models/AgentTemplate/IActivityContract';
import _ from 'lodash';
import { useStyles } from './AG_TemplateCss';
import { ActivityList } from '../../../models/AgentInquiry/IAgentActivityListCount';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';

interface Props {
    data: ActivityList[];
    onSaveActivity: (event: any, parentActivityId: number) => void;
}

const AgentTreeView: React.FC<Props> = ({ data, onSaveActivity }) => {
    const classes = useStyles();
    const [saveActivity, setSaveActivity] = useState<IActivityContract[]>([]);

    const handleChecked = (e, parentActivityId) => {
        let activityList = _.cloneDeep(saveActivity);
        const { checked } = e.target;

        const value = Number(e.target.value);

        // Add or remove the child activity ID based on the checked status
        if (checked) {
            if (activityList.findIndex(x => x.activityFieldId === value) === -1) {
                activityList.push({ activityFieldId: value });
            }
        } else {
            const index = activityList.findIndex(x => x.activityFieldId === value);
            if (index !== -1) {
                activityList.splice(index, 1);
            }
        }

        // Add root activity ID (parentActivityId) and its ancestors recursively
        if (parentActivityId && !activityList.some(item => item.activityFieldId === parentActivityId)) {
            activityList.push({ activityFieldId: parentActivityId });
            // Find the parent activity in the activities array
            const parentActivity = data.find(activity => activity.ActivityMapId === parentActivityId);
            // Recursively call handleChecked with the parent activity ID
            if (parentActivity && parentActivity.Activities) {
                handleChecked({ target: { value: parentActivityId, checked: true } }, parentActivity?.Activities[0].ActivityMapId);
            }
        }
        // Set the updated saveActivity list
        setSaveActivity(activityList);
        onSaveActivity(e, parentActivityId);
    };

    const renderActivities = (activities: ActivityList[]) => {
        return activities.map(activity => (
            <React.Fragment key={activity.ActivityMapId}>
                <ListItemButton sx={{ pt: 0, pb: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        {activity.Activities ? (
                            activity?.ActivityMapId === activity.ActivityMapId ? (
                                <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                            ) : (
                                <AddBoxOutlinedIcon fontSize="small" />
                            )
                        ) : (
                            <label>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        id={`checkbox-${activity.ActivityMapId}`}
                                        control={<Checkbox size="small" name={`${activity?.ActivityName}`} />}
                                        labelPlacement="end"
                                        value={activity?.ActivityMapId}
                                        label={`${activity?.ActivityName}`}
                                        onChange={(e) => { handleChecked(e, activity.ParentId); }}
                                        className={`${!(saveActivity.findIndex(x => x.activityFieldId == activity?.ActivityMapId) <= -1) ? classes.permittedChecked : classes.permittedFiled}`}
                                    />
                                </FormControl>
                            </label>
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {activity.Activities ? (
                            <Typography variant="body2" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                {activity?.ActivityName}
                            </Typography>
                        ) : null}
                    </ListItemText>
                </ListItemButton>
                {activity.Activities && <ul>{renderActivities(activity.Activities)}</ul>}
            </React.Fragment>
        ));
    };

    return (
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.tableBodyTrack}>
            {renderActivities(data)}
        </Scrollbars>
    );
};

export default AgentTreeView;
