import {
    Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, Snackbar,
    Grid, IconButton, makeStyles, TextField, Typography, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from "@material-ui/icons/Replay";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Models
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { IRollPlacement } from '../../models/Inquiry/IRollPlacement';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions';
import { InsertTransactionLog } from '../TransactionLogs/InsertTransactionLog';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 7,
        fontSize: 10,
        fontWeight: 'bold',
    },
    inputField: {
        height: '45px',
        fontSize: 14,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        width: "300px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    description: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        width: '85%',
        fontSize: 14,
        marginLeft: '30px'
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    confirmButton: {
        color: "white",
        marginTop: "7px",
        marginRight: "10px",
        float: 'right',
        borderRadius: 20,
        fontSize: 12
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginRight: "20px",
        marginTop: "7px",
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
    formcontrol: {
        marginTop: "0.3cm"
    },
    formcontrol1: {
        marginTop: "0.2cm"
    },
    formcontrol2: {
        marginLeft: '1.4cm',
        marginTop: "0.2cm"
    },
    circleStyle: {
        color: 'white',
        height: "20px",
        width: "20px",
        marginLeft: '10px'
    },
    dialogePaper: {
        minWidth: "800px"
    }
}))

const CloseAndRemove: React.FC<{ dbtNumber: string, clientId: string, removed: boolean, rolled: boolean, onRollRemove: () => void, onUndoCloseRemove: () => void, onSubmit: () => void, dbT_PRINCIPAL_DUE: number, BlockAwaitAccess: boolean, closeReason: string, closeRemDetails: boolean, onUpdate: () => void }> = (props) => {
    const classes = useStyles()
    const { state } = useGlobalState()
    const [flag, setFlag] = React.useState(true);
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [CloseNote, setCloseNote] = useState<string>("");
    const [closeAcctBal, setcloseAcctBal] = useState<any>();
    const [closeAcctReason, setValue] = useState('');
    const [Removed, setRemoved] = useState(props?.closeRemDetails);
    const [inputVal, setinputVal] = useState<any>('');
    const canCloseRemoveAccount = state.userAccessContext?.closingAccounts;
    const [open, setOpen] = React.useState(false);
    const ERollDisable = props?.rolled
    const UserName = state.userAccessContext?.id;
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>(0);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.dbT_PRINCIPAL_DUE);
    const [isValid, setIsValid] = useState(true);
    const [isReasonValid, setReasonValid] = useState('');
    const [closeAccRem, setCloseAccRem] = useState(false);
    const [closeAccUndoRem, setCloseAccRemUndo] = useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleOnValueChange = (value: string): void => {
        setPrincipalPaymentAmount(value === '0' || '' ? setIsValid(true) : value || '');
        setcloseAcctBal(value === '0' || '' ? setIsValid(true) : value || '');
        setinputVal(value === '0' || '' ? setIsValid(true) : value || '');
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)));
    }, [props.dbT_PRINCIPAL_DUE, closeAcctBal, principalPaymentAmount])

    useEffect(() => {
        closeAcctReason == 'Paid' ? ((closeAcctBal > props.dbT_PRINCIPAL_DUE) ? setIsValid(false) : setIsValid(true)) : setIsValid(true)
    }, [updatedPrincipalAmount, closeAcctReason])

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
        setCloseNote("");
    };

    function emptyCheck() {
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    }

    async function CloseAccount() {
        setButtonLoading(true);
        let CloseBalance = parseFloat(closeAcctBal);
        let request = {
            "dbtNumber": props.dbtNumber,
            "close_acct_note": CloseNote,
            "close_acct_bal": CloseBalance,
            "close_acct_reason": closeAcctReason,
            "user_Id": UserName,
            "isCloseRemove": true,
            "isClosed": false
        };

        if (closeAcctReason == 'Paid') {
            let Principal = ((props.dbT_PRINCIPAL_DUE - CloseBalance).toFixed(2));
            let requestPaid = {
                "prcpl_Pay_Amt": Principal,
                "close_acct_note": CloseNote,
                "dbtNumber": props.dbtNumber,
                "close_acct_reason": closeAcctReason,
                "close_acct_bal": CloseBalance,
                "user_Id": UserName,
                "isCloseRemove": true,
                "isClosed": false
            };
            let requestRollClose = {
                "dbt_No": props.dbtNumber,
                "dbt_Client": props.clientId,
                "user_Id": UserName
            };
            await usePost('Account/ClosedPlacedAccounts', requestPaid);
            await usePost<IRollPlacement[]>('RollClose', requestRollClose).finally(() => {
                setButtonLoading(false);
            });
            RollCloseRemove();
            props.onSubmit();
            setPrincipalAmount(Principal);
        }
        else {
            await usePost('Account/EmptyPlacedAccounts', request).finally(() => {
                setButtonLoading(false);
            });
        }
        RollCloseRemove();
        setRemoved(true);
        setshowUndoButton(true);
    }

    async function RollCloseRemove() {
        props.onUpdate();
        setRemoved(true);
        props.onRollRemove();
        if (closeAcctReason == 'Paid') {
            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Close & Remove", operationText: `Payment to Principal: $${(props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)}, Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
        } else {
            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Close & Remove", operationText: `Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
        }
    }

    async function undoCloseandRemove() {
        let request = {
            "DBTNumber": props.dbtNumber,
            "isCloseRemove": true,
            "isClosed": false
        }
        await usePost("Account/PlacedAccounts", request);
        let CloseRemove = {
            "dbtNumber": props.dbtNumber
        };
        await usePost('Account/UndoCloseRemove', CloseRemove);
        props.onUpdate();
        props.onUndoCloseRemove();
        if (props?.closeReason === "Paid") {
            undoAdjustment(principalAmount);
        }
        setshowUndoButton(false);

        let feedOperation = [];
        let operations: IInsertTransactionLog = {
            operationName: "Undo Close & Remove Account", operationText: "Close & Remove Account: Cancelled"
        };
        feedOperation.push(operations);

        InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCloseAccRem(false);
        setCloseAccRemUndo(false);
    };

    const undoAdjustment = (adjustmentAmount: number) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNumber,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": "Principal Payment"
            }
            await usePost<any>("Account/UndoAdjustment", request);
            props.onSubmit();
        })()
    }

    return (
        <React.Fragment>
            <Button id="Roll_CAR" variant="contained" className={classes.button} size="large"
                startIcon={<ClearIcon />}
                onClick={() => { setOpen(true); }}
                disabled={!canCloseRemoveAccount || Removed || ERollDisable || props.removed || props.BlockAwaitAccess || props.closeRemDetails} color="primary">
                {Removed || props.removed || props.closeRemDetails ? "Removed" : "Close & Remove"}
                {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
            </Button>
            {(Removed || props.removed || props.closeRemDetails) && (
                <IconButton
                    id="Roll_Undo_CAR"
                    color="primary"
                    size="small"
                    aria-label="Undo Close And Reomve"
                    onClick={() => {
                        setCloseAccRemUndo(true);
                        undoCloseandRemove();
                        setRemoved(false);
                    }}
                    disabled={props.BlockAwaitAccess}>
                    <ReplayIcon fontSize="small" />
                </IconButton>
            )}
            <Dialog TransitionComponent={Transition} open={open} onClose={handleClose}
                classes={{ paper: classes.dialogePaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CLOSE AND REMOVE
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error" className="snackBarStyle">
                            Please enter a payment amount that is less than the amount due.
                        </Alert>
                        : null
                    }
                </DialogTitle>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <TextField
                            id="Roll_CA_Description"
                            placeholder="Why is the account being Closed?"
                            size="medium"
                            multiline
                            rows={6}
                            value={CloseNote} onChange={handleReasonNote}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            className={classes.description}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <form>
                            <CurrencyInput
                                className={classes.inputField}
                                id="Roll_CA_Balance"
                                placeholder="$ Current Balance Amount in Your System"
                                value={closeAcctBal}
                                prefix='$'
                                decimalsLimit={2}
                                onValueChange={handleOnValueChange}
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onKeyPress={preventMinus}
                                decimalScale={2}
                                maxLength={13}
                            />
                        </form>
                        <FormControl component="fieldset" className={classes.formcontrol}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <RadioGroup id="Roll_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol1}>
                                        <FormControlLabel value="Paid" control={<Radio size="small" />} label="Paid" />
                                        <FormControlLabel value="Bankruptcy" control={<Radio size="small" />} label="Bankruptcy" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioGroup id="ROll_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol2}>
                                        <FormControlLabel value="Disputing" control={<Radio size="small" />} label="Disputing" />
                                        <FormControlLabel value="Other" control={<Radio size="small" />} label="Other" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogActions >
                    <Button id="Roll_CAR_Confirm" variant="contained" className={classes.confirmButton} startIcon={<CheckCircleIcon />} disabled={!inputVal || !isReasonValid || !isValid}
                        style={{ background: !inputVal || !isReasonValid || !isValid ? "#e0e0e0" : "green" }} size="small"
                        onClick={() => { setCloseAccRem(true); setOpen(false); setFlag(!flag); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); emptyCheck(); }} >
                        Confirm
                    </Button>
                    <Button id="Roll_CAR_Close" variant="outlined" onClick={handleClose} size="small" className={classes.cancelButton} startIcon={<CancelIcon />}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className="snackBarStyle" open={closeAccRem} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account has been Closed and Removed Successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={closeAccUndoRem} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Closed and Removed Account has been undone successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default CloseAndRemove