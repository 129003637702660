import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    tablecellStyle: {
        background: '#007FFF',
        color: 'white'
    },
    footerStyle: {
        "& > td > div": {
            height: 30,
            minHeight: 30
        },
        height: 30
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        // border: '1px solid gray',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    TableCellStyleHead: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left',
        border: '1px solid #007FFF'
    },
    titleHead: {
        textAlign: 'left',
        marginLeft: '5px',
        marginTop: '10px',
    },
    TableCellStyle1: {
        fontSize: 13,
    },
    searchBox: {
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '95%',
        marginTop: '8px',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    searchInput: {
        padding: '7px',
    },
    tableBody: {
        maxHeight: 400,
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 211px)',
            minHeight: 'calc( 100vh - 211px)',
        },
    },
    reportCell: {
        padding: '3px 11px',
        fontSize: 13,
    },
    footer: {
        marginTop: '15px',
        float: 'right',
        marginRight: '60px',
        '& .MuiTypography-body1': {
            fontSize: 12,
        }
    },
    btnManTemp1: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    activityDropDownStyle: {
        width: '100%',
        marginTop: '7px',
        minHeight: '5%'
    },
    dialogePaper1: {
        minWidth: "70%",
        minHeight: "70%"
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    reportHeader: {
        color: 'blue',
        marginTop: '5px'
    },
    controlSpace1: {
        width: '95%',
        marginTop: '8px'
    },
    controlSpace2: {
        width: '100%',
        marginTop: '8px'
    },
    div: {
        padding: 8
    },
    gridflex1: {
        padding: '5px 5px',
        borderRadius: '5px',
        marginTop: '5px',
        width: '90%',
        borderTop: '4px solid #3bb78f',
        overflowX: 'hidden',
        maxHeight: 580,
        minHeight: 580,
        borderBottom: '1px solid gray',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 500px)',
            minHeight: 'calc( 100vh - 500px)',
        },
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    gridflex: {
        padding: '5px 5px',
        borderRadius: '5px',
        marginTop: '5px',
        borderTop: '4px solid #3bb78f',
        overflowX: 'hidden',
        maxHeight: 580,
        minHeight: 580,
        borderBottom: '1px solid gray',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 500px)',
            minHeight: 'calc( 100vh - 500px)',
        },
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    controlPadding: {
        padding: '0px 5px',
        borderBottom: '0px solid white !important',
        '&.MuiTableCell-root': {
            borderBottom: '0px solid white !important',
        }
    },
    controlDisplay: {
        width: '100%',
        marginTop: '5px',
        marginBottom: '7px',
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    sidebar: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRight: '1px solid gray',
        padding: '10px 10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
    },
    controlSpace3: {
        width: '100%',
        marginTop: '8px',
        borderRadius: 3
    },
    yesButton: {
        background: "green",
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        color: 'white',
        fontSize: 12,
        marginRight: '20px',
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    backButton: {
        borderRadius: 20,
        marginTop: '10px',
        marginRight: '5px',
        fontSize: '11px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
export { useStyles };