import {
  Avatar, Backdrop, Box, Button, Checkbox, createStyles, Grid, IconButton, InputAdornment, makeStyles, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Theme, Typography
} from '@material-ui/core';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { PuffLoader } from 'react-spinners';
// Icons
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import AgentUser from '../../images/users/AgentUser.svg';
// Componant
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import Clone from '../../components/ManageAgentUsers/CloneAgentUser';
import CreateAgentUser from '../../components/ManageAgentUsers/CreateAgentUser';
import DeleteUser from '../../components/ManageAgentUsers/DeleteAgentUser';
import EditAgentUser from '../../components/ManageAgentUsers/EditAgentUser';
import Imporsonate from '../../components/ManageAgentUsers/Impersonate';
import Invite from '../../components/ManageAgentUsers/InviteAgentUser';
// Models
import { IAgentInvitation } from '../../models/AgentUser/IAgentInvitation';
import { IAgentUserList } from '../../models/AgentUser/IAgentUserList';
import { useFetch, usePost } from '../../utils/apiHelper';
import FTEHistory from '../../components/ManageAgentUsers/FTEHistory';
import LightTooltip from '../../components/GlobalStyles/LightTooltip';
import { StyledLabel } from '../../utils/StyledLabel';
import NotificationPermission from '../../components/ManageAgentUsers/NotificationPermission';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    agentUser: {
      width: 35,
      height: 35,
      borderRadius: "100%",
      border: '2px solid black',
    },
    table: {
      minWidth: 700,
    },
    div: {
      flexGrow: 1,
      padding: '8px'
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    domainStyle: {
      width: '100%',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 10,
      border: '2px solid #215176',
      backgroundColor: 'white',
      fontSize: 14,
      textIndent: '10px',
      marginBottom: '5px',
    },
    searchBar: {
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 10,
      border: '2px solid #215176',
      width: '100%',
      fontSize: 14,
      textIndent: '10px',
      marginBottom: '5px',
      marginTop: '2px',
      height: '38px',
    },
    boxStyle: {
      marginTop: '5px',
      marginLeft: '15px',
    },
    TextHighlight: {
      color: '#000000',
      fontWeight: 'bold',
      backgroundColor: 'yellow',
      fontSize: 13,
    },
    TextNormal: {
      color: 'black',
      fontSize: 13
    },
    tablebody: {
      maxHeight: 500,
      minHeight: 500,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 255px)',
        minHeight: 'calc( 100vh - 255px)',
      },
    },
    invitationsend: {
      fontSize: 14,
      '@media (min-width: 600px) and (max-width: 660px)': {
        fontSize: 12,
      }
    },
    invitationbutton: {
      borderRadius: 20,
      height: '30px',
      background: "#215176",
      color: "white",
      '&:hover': {
        background: "#215176",
      }
    },
    createUser: {
      fontSize: 12,

    },
    paper1: {
      border: "2px solid black",
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
      marginTop: '-10px',
      // paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline1: {
      color: 'white',
      border: '0px'
    },
    IconSizeStyle: {
      height: '22px',
      width: '22px',
      padidng: '0px'
    },
    IconButtonStyle: {
      padidng: '0px'
    },
    buttonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: '5px',
      '& .MuiBox-root': {
        padding: '5px',
      },
      '@media (min-width: 600px)': {
        justifyContent: "flex-end",
      }
    },
    UserListStyle: {
      textAlign: 'center',
      marginLeft: '12px',
      marginTop: '8px',
      '@media (min-width: 600px)': {
        textAlign: 'left',
      },
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 13,
      padding: '3px 15px'
    },
    outlinedInput1: {
      fontSize: 14,
      color: "black",
      paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    ErrorMessageStyle: {
      color: "red",
      marginTop: "10px",
      fontSize: '16px',
      fontWeight: 'bold'
    },
    footer: {
      marginTop: '15px',
      float: 'right',
    },
    flexGrid: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '3px',
      '& .MuiBox-root': {
        padding: '5px',
      },
    },
    searchStyle: {
      padding: '0px 10px',
    },
    paginationCenter: {
      alignItems: 'center',
    },
    domainBorder: {
      borderRadius: '50px',
    },
    checkboxColor: {
      color: 'white !important',
    },
    contentEnd: {
      justifyContent: 'end',
    },
    usertablecellStyle: {
      padding: '3px 15px'
    },
    createBtn: {
      borderRadius: 20,
      fontSize: 11,
    },
    button: {
      fontSize: 11,
      borderRadius: 20,
      background: "#215176",
      '&:hover': {
        background: "#215176",
      }
    },
    editButton: {
      fontSize: 11,
      borderRadius: 20,
      backgroundColor: 'green',
      '&:hover': {
        background: "green",
      }
    },
    deleteBtnRadius: {
      borderRadius: 30,
      fontSize: 11
    },
    deleteIcon: {
      color: 'red',
      fontSize: 24
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    usertablecellStyle2: {
      padding: '3px 12px 3px 0px'
    },
    fteUserStyle: {
      backgroundColor: '#e3fcfc',
      '&:hover': {
        background: "#e3fcfc",
      }
    }
  })
);

const AgentUsers = () => {
  const classes = useRowStyles();

  const [order, setOrder] = useState("desc");
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("emailAddress");
  const [ProgressBar, setshowProgressBar] = useState(true);

  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(15);

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const [selected, setSelected] = useState<number[]>([]);
  const [userList, setUserList] = useState<IAgentUserList[]>([]);
  const [InvitationCount, setInvitationCount] = useState<IAgentInvitation[]>([]);
  const [ischecked, setIsChecked] = useState(false);
  const [switchButton, setSwitchButton] = useState(0);

  useEffect(() => {
    getAgentUserList();
  }, [pageNumber, recordsPerPage, order, orderBy, ischecked])

  const getAgentUserList = () => {
    setshowProgressBar(true);
    (async () => {
      let request = {
        searchText: searchText,
        orderBy: orderBy,
        rowsPerPage: recordsPerPage,
        recordNumber: (pageNumber * recordsPerPage) + 1,
        orderDirection: order,
        isFteUser: !ischecked ? false : true,
      }
      await usePost<{ agentUserResults: IAgentUserList[], totalCount: number }>("AgentUser/GetAgentUsers", request).then((r) => {
        setUserList(r?.data['agentUserResults']);
        setTotal(r?.data?.totalCount);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const clearAgentUserList = () => {
    setSearchText("");
    setshowProgressBar(true);
    (async () => {
      let request = {
        searchText: "",
        orderBy: orderBy,
        rowsPerPage: recordsPerPage,
        recordNumber: (pageNumber * recordsPerPage) + 1,
        orderDirection: order,
        isFteUser: !ischecked ? false : true,
      }
      await usePost<{ agentUserResults: IAgentUserList[], totalCount: number }>("AgentUser/GetAgentUsers", request).then((r) => {
        setUserList(r?.data['agentUserResults']);
        setTotal(r?.data?.totalCount);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  function handleSwitched(e: ChangeEvent<HTMLInputElement>) {
    const newIsChecked = e.target.checked;
    setIsChecked(newIsChecked);

    if (newIsChecked) {
      setSwitchButton(1);
    } else {
      setSwitchButton(0);
    }
  }

  useEffect(() => {
    (async () => {
      await useFetch<IAgentInvitation[]>("AgentUser/GetAgentInvitiedCount").then((r) => {
        setInvitationCount(r?.data);
      });
    })()
  }, [])

  const InvitationCountAsync = () => {
    (async () => {
      await useFetch<IAgentInvitation[]>("AgentUser/GetAgentInvitiedCount").then((r) => {
        setInvitationCount(r?.data);
      });
    })()
  }

  const handleAsync = () => {
    getAgentUserList();
    InvitationCountAsync();
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort,
      onSelectAllClick,
      numSelected, rowCount
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const headCells = [
      { id: "", disablePadding: false, label: "", sortable: false, },
      { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
      { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
      { id: "emailAddress", disablePadding: false, label: "EMAIL", sortable: true },
      { id: "", disablePadding: false, label: "", sortable: false, },
      { id: "Actions", disablePadding: false, label: "ACTIONS" },
      { id: "", disablePadding: false, label: "", sortable: false, },
      { id: "", disablePadding: false, label: "", sortable: false, order: false },
      { id: "History", disablePadding: false, label: "HISTORY", sortable: false, order: false },
      { id: "Delete", disablePadding: false, label: "DELETE", sortable: false, order: false }
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.TableCellStyle} padding="checkbox">
            <Checkbox className={classes.checkboxColor} size="small"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all Users' }}
            />
          </TableCell>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPageNumber(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handlePageChange = (event, page) => {
    setPageNumber(page);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecordsPerPage(+event.target.value);
    setPageNumber(0);
  };

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={0} xs={12} className={classes.maingrid}>
          <Grid item xs={2} sm={3} lg={3}>
            <Typography variant="h6" className={`${classes.UserListStyle} ${"headertitle"}`}>
              <b> AGENT USERS LIST </b>
            </Typography>
          </Grid>
          <Grid item xs={10} sm={5} lg={5}>
            <Grid container spacing={0}>
              <Grid item xs={6} className={`${classes.flexGrid} ${classes.contentEnd}`}>
                <Box p={1}>
                  <Typography variant="h6" className={classes.invitationsend}>
                    Invitation Sent
                  </Typography>
                </Box>
                <Box p={1}>
                  <Button size="small" className={classes.invitationbutton}
                    variant="contained" startIcon={<EmailIcon />} >
                    {InvitationCount[0]?.invitedCount}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.flexGrid}>
                <Box p={1}>
                  <Button size="small" className={classes.invitationbutton} startIcon={<DoneAllIcon />}>
                    {InvitationCount[0]?.activeCount}
                  </Button>
                </Box>
                <Box p={1}>
                  <Typography variant="h6" className={classes.invitationsend}>
                    Invitation Accepted
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} lg={2} direction="column">
            <Box className={classes.buttonStyle} >
              <Box display="flex" p={1} style={{ marginLeft: '-10px' }}>
                <LightTooltip title="Switch to view FTE Permitted users">
                  <StyledLabel htmlFor="checkbox" checked={ischecked}>
                    <input
                      id="checkbox"
                      type="checkbox"
                      checked={ischecked}
                      onChange={handleSwitched} />
                  </StyledLabel>
                </LightTooltip>
                <Typography style={{ padding: '2px 7px', fontSize: '16px' }}><b>FTE Users</b></Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} lg={2} direction="column">
            <Box className={classes.buttonStyle} >
              <Box display="flex" p={1}>
                <NotificationPermission />
              </Box>
              <Box display="flex" p={1}>
                <CreateAgentUser onSave={getAgentUserList} />
              </Box>
            </Box>
          </Grid>

          <Grid item className={classes.searchStyle} xs={12}>
            <TextField value={searchText} variant="outlined"
              type='text' placeholder="Search Users (First Name, Last Name or Email)"
              className={classes.searchBar}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText != "") {
                  setPageNumber(0);
                  getAgentUserList();
                  setShowSearchIcon(false);
                } else if (event.key === 'Enter' && searchText === "") {
                  setPageNumber(0);
                  clearAgentUserList();
                  setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxlength: 256 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline1,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={(_) => { getAgentUserList(); setShowSearchIcon(false); }} className={classes.IconButtonStyle}>
                        <SearchIcon className={classes.IconSizeStyle} />
                      </IconButton>
                      :
                      <IconButton onClick={(_) => { clearAgentUserList(); setShowSearchIcon(true); }} className={classes.IconButtonStyle}>
                        <CloseIcon className={classes.IconSizeStyle} />
                      </IconButton>
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12} component={Paper} style={{ marginTop: '3px' }}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table className="main-table" aria-label="customized table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={total}
                />
                <TableBody>
                  {userList.map(
                    (row, index) => {
                      const isItemSelected = isSelected(row?.agentUserId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={index} selected={isItemSelected} className={`${row?.ftePermission ? classes.fteUserStyle : null}`}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}>
                          <TableCell padding="checkbox" className={classes.usertablecellStyle}>
                            <Checkbox
                              size="small"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row?.agentUserId)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                            <AvatarTooltip title="Agent User">
                              <Avatar className={classes.agentUser} src={AgentUser}></Avatar>
                            </AvatarTooltip>
                          </TableCell>

                          <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                            <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.firstName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row?.firstName}
                            </span>
                          </TableCell>
                          <TableCell className={classes.usertablecellStyle}>
                            <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.lastName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row?.lastName}
                            </span>
                          </TableCell>
                          <TableCell align="left" className={classes.usertablecellStyle}>
                            <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row?.emailAddress?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row?.emailAddress}
                            </span>
                          </TableCell>
                          <TableCell align="center" className={classes.usertablecellStyle}>
                            <Invite id={row?.agentUserId} email={row?.emailAddress} onSentInvite={handleAsync} InviteStatus={row?.invitationStatus} />
                          </TableCell>
                          <TableCell align="center" className={classes.usertablecellStyle}>
                            <Imporsonate email={row?.emailAddress} />
                          </TableCell>
                          <TableCell align="center" className={classes.usertablecellStyle}>
                            <Clone userId={row?.agentUserId} onSave={handleAsync} />
                          </TableCell>
                          <TableCell align="center" className={classes.usertablecellStyle}>
                            <EditAgentUser row={row} userId={row?.agentUserId} onSave={handleAsync} />
                          </TableCell>
                          <TableCell align="center" className={classes.usertablecellStyle}>
                            <FTEHistory agentUserId={row?.agentUserId} row={row} />
                          </TableCell>
                          <TableCell align="left" className={classes.usertablecellStyle}>
                            <DeleteUser id={row?.agentUserId} email={row?.emailAddress} onDelete={handleAsync} />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {!total && !ProgressBar ?
                <Typography gutterBottom className={classes.ErrorMessageStyle}>
                  No records to display..
                </Typography> : null}
            </TableContainer>

            <Box m={0} display="flex" style={{ flexDirection: 'row' }}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={recordsPerPage}
                    page={pageNumber}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
};

export default AgentUsers