import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomizeControl from '../GsettingAgentActivity/CustomizeControl';

const useStyles = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: 8
    }
}))

const PerformanceReport = () => {
    const classes = useStyles();
    return (
        <div className={classes.div}>
            <Grid container spacing={0}>
                <Grid lg={12}>
                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                        <b>PERFORMANCE REPORT</b>
                    </Typography>
                </Grid>
                <Grid lg={12}>
                    <CustomizeControl activityId={0} actionType={2} />
                </Grid>
            </Grid>
        </div>
    )
}

export default PerformanceReport