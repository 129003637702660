//DBT_COMPANY
export const FIRSTSTP = '1STP';
export const PREC = 'PREC';
export const CONT = 'CONT';
export const Phases = ['1STP', 'CONT']

//Awaiting Amt Payment
export const AwaitingPayment338 = "338";
export const AwaitingPayment324 = "324";
export const AwaitingDocs326 = "326";

//NopaymentMade
export const NoPaymentMade = "https://webviewrpfunctions-uat.azurewebsites.net/api/FuncNoPaymentMadeUAT";

//Placement Cloud Option
export const CsvFile = ".csv";
export const TxtFile = ".txt";
export const ValZipCode = /^\d{5}[-\s]?(?:\d{4})?$/;

//Regex validation
export const AllowOnlyAlphabetsWithSpace = /^[a-zA-Z0-9]*$/;
export const AgencyIdValidation = /^[0-9]*$/;
export const AllowOnlyDigits = /^[0-9a-zA-Z./-]*$/;
export const AllowAddress = /^[#.0-9a-zA-Z\s,-/]*$/;
export const AllowCity = /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/;
export const AllowState = /^[a-zA-Z]*$/;
export const AllowAmount = /^[0-9]+(\.[0-9][0-9])?$/;
export const AllowOnlyAlphabets = /^[a-zA-Z0-9]*$/;
export const phoneRegex = /^([0-9]{10}|[0-9]{12})$/;
export const AllowOnlyAlphabetsNumbers = /[~`!@#^&*()-+=%<>?.,:;{}|[\]\\$"]/;
export const AllowAlphabetsNumbers = /[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/;
//Manage File Access
export const AllowAlphabets = /^[a-z0-9-]*$/;

//Email Regex
export const regexValidEmail = /^([A-Za-z0-9À-žñ]+(?:['+._-][A-Za-z0-9À-žñ]+)*)@([A-Za-z0-9]+(?:[.+-][A-Za-z0-9]+)*\.[A-Za-z]{2,6})$/;

// Client Confirmation
export const EmailRegex = /^([A-Za-z0-9]+(?:['+._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[A-Za-z]{2,6})$/;
export const PhoneRegex = /^(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/;
export const StateRegex = /[^,]*[a-zA-Z]{2}/;
export const ZipRegex = /^\d{5}(-\d{4})?$/;
export const NameRegex = /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!@#$()~^{}[]|\_+%&*]{7,}$/;
export const NumberReg = /^[0-9]+(\.[0-9][0-9])?$/;
export const EmailWithChar = /^([A-Za-z0-9]+\.[a-z]{2,4})$/;
export const ConfirmText = /[~`!#^&*()-+=%<>?,:/\\$'"]/;

//Special Character
export const preventSpecialKeys = ["~", "!", "#", "$", "%", "^", "&", "*", "(", ")", "=", "{", "}", "'", ">", "<", "?", "|", "/", ";", "`"];
export const serviceEmailID = "clientservices@armsolutions.com";
export const url = "https://webviewnew-uat.armsolutions.com/";

//Mass File Insert API Key
export const apiKey = "82bdfed6-d676-49dc-9199-a6a396adf602";
export const MassFileTrigger = "https://webviewrpfunctions-uat.azurewebsites.net/api/FuncMassFileUploadUAT";

//Placement CFPB
// export const currencyReg = /^\$?-?[0-9,]+([.][0-9]+)?$/;
export const currencyReg = /^\$?-?[0-9,]+([.][0-9]+)?$|((?:\$-|\$$))/;

// export const totalBalanceReg = /^\$?(?!1(\.1+)?$)?(1|-?[1-9]\d*)(\.\d{1,})?$/; old
export const totalBalanceReg = /^\$?[0-9,]+([.][0-9]+)?$/;
export const zipReg = /^\d{5}(-\d{4})?$/;
export const StateReg = /^[a-zA-Z]{2}$/;
// export const accountNumberRegex = /^[A-Za-z0-9]{1,19}$/; // Old regex for account number

// export const accountNumberRegex = /^(?=.{1,50}$)[\s\S]*$/;

export const accountNumberRegex = /^[A-Za-z0-9-_]{1,50}$/;

//export const zipVal = /^((\d{5}-\d{4})|(\d{5}-)|(\d{5})|([A-Za-z0-9]{3}\s[a-zA-Z0-9_]{3}))$/; // Allowed Alpha numaric Values
export const zipVal = /^[A-Za-z0-9 -]{1,10}$/;  // Allowed Alpha numaric Values and maxlength of zip code

export const phoneVal = /^$|(\(?\d\d\d\)?)?( |-|\.)?\d\d\d( |-|)?\d{4,4}$|((?:None|none|NONE)){1,30}/; // Allowed None Option in the Phone Number

export const phoneRegexVal = /[^0-9]/g;

//LocalHost URL
export const LocalHost = "http://localhost:3000";

// Report Encrypted URL
export const reportKey = "U2FsdGVkX19GSvQrdBeQNFiDvmCzuJg1QZmCwflUfAI=";

// Roll Encrypted URL
export const rollKey = "U2FsdGVkX18it8CZGNjDuCS2bwKof55K1cYXk/qTr2w=";

//UAT Url
export const reportSubRouteKey = "https://webviewnew-dev.armsolutions.com/U2FsdGVkX19GSvQrdBeQNFiDvmCzuJg1QZmCwflUfAI=";
export const reportSubRoute = "https://webviewnew-dev.armsolutions.com/report";

export const rollSubRouteKey = "https://webviewnew-dev.armsolutions.com/U2FsdGVkX18it8CZGNjDuCS2bwKof55K1cYXk/qTr2w=";
export const rollSubRoute = "https://webviewnew-dev.armsolutions.com/roll";

//Report Constants
export const video = "xx5GLZN5k8Y";
export const videoTitle = "A.R.M. Webview: Using Reports";

//Roll Constants
export const rollVideo = "VqOXNUDL3-0";
export const rollVideoTitle = "A R M WebView: Rolling Accounts from Flat Fee to Contingency";

//Webview SFTP Container
export const WebViewSFTPContainer = "webview-sftp-container";

//placement DateFormat
export let formats = ['DD-MM-YYYY HH:mm:ss A', 'MM-DD-YYYY HH:mm:ss A', 'MM/DD/YYYY HH:mm:ss A', 'DD/MM/YYYY HH:mm:ss A',
    'M/DD/YYYY HH:mm:ss A', 'D/MM/YYYY HH:mm:ss A', 'D/M/YYYY HH:mm:ss A', 'dd/mm/yyyy HH:mm:ss A', 'YYYY/MM/DD HH:mm:ss A',
    'YYYY/DD/MM HH:mm:ss A', 'YYYY-MM-DD HH:mm:ss A', 'YYYY-DD-MM HH:mm:ss A', 'dd-mm-yyyy HH:mm:ss A', 'yyyy-mm-dd HH:mm:ss A',
    'yyyy/mm/dd HH:mm:ss A', 'DD-MM-YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'DD/MM/YYYY', 'M/DD/YYYY', 'D/MM/YYYY', 'D/M/YYYY',
    'dd/mm/yyyy', 'YYYY/MM/DD', 'YYYY/DD/MM', 'YYYY-MM-DD', 'YYYY-DD-MM', 'dd-mm-yyyy', 'yyyy-mm-dd', 'yyyy/mm/dd', 'mm/dd/yyyy',
    'mm-dd-yyyy', 'MM-DD-yyyy', 'DD-MM-yyyy', 'MM-DD-YY', 'mm-dd-yy', 'MM/DD/YY', 'mm/dd/yy', 'DD-MM-YY', 'dd-mm-yy', 'DD/MM/YY', 'MM/D/YYYY H:mm A', 'MM-D-YYYY H:mm A', 'mm/d/yyyy H:mm A', 'mm-d-yyyy H:mm A',
    'dd/mm/yy', 'MM/D/YYYY HH:mm:ss A', 'mm/d/yyyy HH:mm:ss A', 'M/D/YYYY HH:mm:ss A', 'M-D-YYYY HH:mm:ss A', 'MM-D-YYYY HH:mm:ss A', 'YYYY/D/MM HH:mm:ss A',
    'YYYY/D/M HH:mm:ss A', 'YYYY-M-D HH:mm:ss A', 'YYYY-D-MM HH:mm:ss A', 'DD/M/YYYY HH:mm:ss A', 'dd/m/yyyy HH:mm:ss A', 'DD-MM-YYYY H:mm:ss A',
    'MM-DD-YYYY H:mm:ss A', 'MM/DD/YYYY H:mm:ss A', 'DD/MM/YYYY H:mm:ss A', 'M/DD/YYYY H:mm:ss A', 'D/MM/YYYY H:mm:ss A', 'D/M/YYYY H:mm:ss A',
    'dd/mm/yyyy H:mm:ss A', 'YYYY/MM/DD H:mm:ss A', 'YYYY/DD/MM H:mm:ss A', 'YYYY-MM-DD H:mm:ss A', 'YYYY-DD-MM H:mm:ss A', 'dd-mm-yyyy H:mm:ss A',
    'yyyy-mm-dd H:mm:ss A', 'yyyy/mm/dd H:mm:ss A', 'MM/D/YYYY H:mm:ss A', 'mm/d/yyyy H:mm:ss A', 'M/D/YYYY H:mm:ss A', 'M-D-YYYY H:mm:ss A', 'MM-D-YYYY H:mm:ss A',
    'YYYY/D/MM H:mm:ss A', 'YYYY/D/M H:mm:ss A', 'YYYY-M-D H:mm:ss A', 'YYYY-D-MM H:mm:ss A', 'DD/M/YYYY H:mm:ss A', 'dd/m/yyyy H:mm:ss A', 'YYYYMMDD HH:mm:ss A',
    'YYYY-DD-MM H:mm:ss', 'YYYY/DD/MM HH:mm:ss', 'YYYY/D/MM H:mm:ss', 'YYYY/D/M H:mm:ss', 'YYYY-D-MM H:mm:ss', 'YYYY-D-M H:mm:ss', 'YYYY-DD-M H:mm:ss', 'YYYYDDMM HH:mm:ss',
    'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'YYYYMMDD HH:mm:ss', 'YYYY-M-D HH:mm:ss', 'YYYY/M/DD HH:mm:ss', 'YYYY/M/D HH:mm:ss', 'YYYY/MM/D HH:mm:ss',
    'DD-MM-YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm:ss', 'D/MM/YYYY H:mm:ss', 'D/M/YYYY H:mm:ss', 'DD/M/YYYY HH:mm:ss', 'MM-DD-YYYY HH:mm:ss', 'MM/DD/YYYY HH:mm:ss', 'M/DD/YYYY HH:mm:ss', 'MM/D/YYYY HH:mm:ss', 'M-D-YYYY HH:mm:ss', 'M/D/YYYY HH:mm:ss',
    'D/M/YY', 'D-M-YY', 'M/D/YY', 'M-D-YY', 'YY/M/D', 'YY-M-D', 'YY/D/M', 'YY-D-M', 'D/MM/YY', 'D-MM-YY', 'DD/M/YY', 'DD-M-YY', 'M/DD/YY', 'M-DD-YY', 'MM/D/YY', 'MM-D-YY',
    'YYYY-D-M H:mm', 'M-D-YYYY H:mm', 'YYYY/D/M H:mm', 'M/D/YYYY H:mm', 'YYYYDDMM H:mm', 'YYYYMMDD H:mm', 'M-DD-YYYY H:mm', 'M/DD/YYYY H:mm', 'DD/M/YYYY H:mm A', 'DD-M-YYYY H:mm A', 'dd/m/yyyy H:mm A', 'dd-m-yyyy H:mm A',
    'YYYY/MM/DD H:mm', 'YYYY/MM/D H:mm', 'MM/D/YYYY H:mm', 'MM/DD/YYYY H:mm', 'D/MM/YYYY H:mm', 'DD/MM/YYYY H:mm', 'YYYY-MM-DD H:mm', 'YYYY-MM-D H:mm', 'MM-D-YYYY H:mm', 'MM-DD-YYYY H:mm', 'D-MM-YYYY H:mm', 'DD-MM-YYYY H:mm', 'YYYY/DD/MM H:mm', 'YYYY-DD-MM H:mm',
    'M/DD/YYYY H:mm A', 'M-DD-YYYY H:mm A', 'm/dd/yyyy H:mm A', 'm-dd-yyyy H:mm A'];



//User File Contains Regex
export const FileContainRegex = /^[0-9a-zA-Z]+([,_-][0-9a-zA-Z]+)*$/;
export const valComma = /[\w]*[,][\w]*/;
export const exampleOne = "ClientCode,ClientCode";
export const exampeTwo = "ClientName1-2,ClientName1-2";

//Notification center
export const title = 'A.R.M. WebView: Introducing the Notification Center';
export const notificationVideo = 'BLJ4yBYR6qc';

//Close and Close&Remove Verbiage
export const closeVerbiage = `Close Account and Close & Remove functions are unavailable for this account. Please contact ${"clientservices@armsolutions.com"}`;
export const rollVerbiage = `Unable to Roll this account. Please Contact ${"clientservices@armsolutions.com"}`;

//Header Logo
export const ARMLogo = "https://www.armsolutions.com/website/wp-content/themes/arm-solutions/style/images/header-logo.png";

// Regex
export const validateRegex = "https://regexr.com/";

// Agent User Validation
export const firstNameregex = /[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/;
export const lastNameregex = /[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/;
export const emailRegex = /^([À-žñA-Za-z0-9]+(?:['+._-][À-žñA-Za-z0-9]+)*)@([À-žñA-Za-z0-9]+(?:[.-][À-žñA-Za-z0-9]+)*\.[À-žA-Za-z]{2,6})$/;


// Invoice Details tab Table Headers

export const LocalInvoiceHeader = ["CREATED DATE", "INVOICE NUMBER", "INVOICE DESCRIPTION01", "INVOICE DATE", "SERVICE DATE", "INVOICE AMOUNT", "INVOICE DUE DATE", "INVOICE DESCRIPTION02", "DEM NAME", "FLD 01"];

export const AmgnewInvoiceHeader = ["DATA ID", "INVOICE ID", "SML DESCRIPTION", "INVOICE NUMBER", "INVOICE AMOUNT", "INVOICE DUE DATE", "INVOICE ARREAR DAYS", "INVOICE DUNNING", "INVOICE TYPE", "INVOICE DATE", "CREATED DATE", "UPDATE DATE"];

export const InvoiceHeader = ["INVOICE NUMBER", "INVOICE DATE", "INVOICE AMOUNT", "INVOICE UNIT AMOUNT", "INVOICE01", "INVOICE02", "INVOICE03", "INVOICE04", "LAST PMT DATE"];

//MassFileUpload File Name validation
export const FileNameVal = /^\d{9}-\d{6}([a-zA-Z0-9]{6})(-[a-zA-Z0-9]{2})+\.pdf$/

//Containers
export const clientBackup = "clientbackup-uat";
export const disputeValidation = "dispute-validation-uat";

//Manage Activity Template
export const unSubscribed = "UNSUBSCRIBED";
export const defaultTempFirstParty = "Global Template - First Party";
export const defaultTempFlatFee = "Global Template - Flat Fee";
export const defaultTempContingency = "Global Template - Contingency";

// PDF Logo and Preview
export const basePdf = "data:application/pdf;base64";
export const baseImage = "data:image/png,image/jpg,image/jpeg;base64";

// Placement Video Title
export const videoFile = "Alqnt6BS3aQ";
export const videoPlacementTitle = "A.R.M. WebView: Placing Accounts with CSV File";

// Agent Inquiry Page
export const AgentActivityCode = "SFDC Case: ";

// Agent Dynamic Activity Control Values 
export const textBox = "TextBox";
export const dropDownList = "DropDownList";
export const maxLength = "maxLengthValue";
export const checkBox = "CheckBox";
export const radioButton = "RadioButton";
export const datePicker = "DatePicker";
export const textArea = "TextArea";

export const dorpDownSelectedValue = "Select";

export const customizeLabelRegex = /[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/;
export const customizeMaxLengthRegex = /[~`!@#^&*()-+=%<>?.,:;a-zA-Z {}[\]/\\$'"]/;
export const customizeControlValueRegex = /[~`!@#^&*()-+=%<>?.,:;{}/[\]\\$'"]/;

// Performance Report 
export const clientCode = "Client Code"
export const state = "State"
export const startDate = "Start Date"
export const endDate = "End Date"

// date Columns for Placements
export const delinquency_date = "delinquency_date";
export const birth_date = "birth_date";
export const last_payment_date = "last_payment_date";
export const service_date = "service_date";
export const Codebtor_BirthDate = "Codebtor_BirthDate";
export const Itemization_Date = "Itemization_Date";
export const Delinquency_Itemization_Date = "Delinquency_Itemization_Date";

// Date format for placement CSV uplaod
export let yearDateFormat = ['YYYY/DD/MM HH:mm:ss A', 'YYYY-DD-MM HH:mm:ss A', 'YYYY/DD/MM', 'YYYY-DD-MM', 'YYYY/D/MM HH:mm:ss A', 'YYYY/D/M HH:mm:ss A', 'YYYY-D-MM HH:mm:ss A', 'YYYY/DD/MM H:mm:ss A',
    'YYYY-DD-MM H:mm:ss A', 'YYYY/D/MM H:mm:ss A', 'YYYY/D/M H:mm:ss A', 'YYYY-D-MM H:mm:ss A', 'YYYY-D-M', 'YYYY/D/M', 'YY/DD/MM', 'YY-DD-MM', 'YYYYDDMM HH:mm:ss A', 'YYYYDDMM', 'yyyyddmm', 'YYYY-D-M HH:mm:ss A'
    , 'YYYY-DD-MM H:mm:ss', 'YYYY/DD/MM HH:mm:ss', 'YYYY/D/MM H:mm:ss', 'YYYY/D/M H:mm:ss', 'YYYY-D-MM H:mm:ss', 'YYYY-D-M H:mm:ss', 'YYYY-DD-M H:mm:ss', 'YYYYDDMM HH:mm:ss',
    'YY/D/M', 'YY-D-M', 'YYYY/D/M H:mm', 'YYYY-D-M H:mm', 'YYYY/D/M H:mm', 'YYYY-D-M H:mm', 'YYYY/DD/MM H:mm', 'YYYY-DD-MM H:mm', 'YYYYDDMM H:mm']

export let yearMonthFormat = ['YYYY/MM/DD HH:mm:ss A', 'YYYY-MM-DD HH:mm:ss A', 'yyyy-mm-dd HH:mm:ss A', 'yyyy/mm/dd HH:mm:ss A', 'YYYY/MM/DD', 'YYYY/MM/DD H:mm:ss A',
    'yyyy-mm-dd H:mm:ss A', 'yyyy/mm/dd H:mm:ss A', 'YYYY-MM-DD', 'YYYY-M-D', 'YYYY/M/D', 'YY/MM/DD', 'YY-MM-DD', 'YYYYMMDD HH:mm:ss A', 'YYYYMMDD', 'yyyymmdd', 'YYYY-M-D HH:mm:ss A',
    'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'YYYYMMDD HH:mm:ss', 'YYYY-M-D HH:mm:ss', 'YYYY/M/DD HH:mm:ss', 'YYYY/M/D HH:mm:ss', 'YYYY/MM/D HH:mm:ss',
    'YY/M/D', 'YY-M-D', 'YYYY/MM/DD H:mm', 'YYYY/MM/D H:mm', 'YYYY-MM-DD H:mm', 'YYYY-MM-D H:mm', 'YYYYMMDD H:mm']

export let dayMonthFormat = ['DD-MM-yyyy', 'dd-mm-yyyy H:mm:ss A', 'DD/M/YYYY H:mm:ss A', 'dd/m/yyyy H:mm:ss A', 'DD-MM-YYYY HH:mm:ss A', 'DD/MM/YYYY HH:mm:ss A',
    'D/MM/YYYY HH:mm:ss A', 'D/M/YYYY HH:mm:ss A', 'dd/mm/yyyy HH:mm:ss A', 'dd-mm-yyyy HH:mm:ss A', 'DD-MM-YYYY', 'DD/MM/YYYY', 'D/MM/YYYY', 'D/M/YYYY', 'dd/mm/yyyy', 'dd-mm-yyyy', 'DD-MM-YY', 'dd-mm-yy', 'DD/MM/YY',
    'dd/mm/yy', 'DD/MM/YYYY H:mm:ss A', 'dd/mm/yyyy H:mm:ss A', 'D/MM/YYYY H:mm:ss A', 'D/M/YYYY H:mm:ss A', 'DD/M/YYYY HH:mm:ss A', 'dd/m/yyyy HH:mm:ss A', 'DD-MM-YYYY H:mm:ss A',
    'D-M-YYYY', 'd-m-yyyy', 'd/m/yyyy', 'DD/M/YYYY', 'DD-M-YYYY', 'dd/m/yyyy', 'dd-m-yyyy', 'DD-MM-YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm:ss', 'D/MM/YYYY H:mm:ss', 'D/M/YYYY H:mm:ss', 'DD/M/YYYY HH:mm:ss', 'D/M/YY', 'D-M-YY', 'D/MM/YY', 'D-MM-YY', 'DD/M/YY', 'DD-M-YY',
    'D/MM/YYYY H:mm', 'DD/MM/YYYY H:mm', 'D-MM-YYYY H:mm', 'DD-MM-YYYY H:mm', 'DD/M/YYYY H:mm A', 'DD-M-YYYY H:mm A', 'dd/m/yyyy H:mm A', 'dd-m-yyyy H:mm A']

export let monthDayFormat = ['MM-DD-YYYY HH:mm:ss A', 'MM/DD/YYYY HH:mm:ss A', 'M/DD/YYYY HH:mm:ss A', 'MM-DD-YYYY', 'MM/DD/YYYY', 'M/DD/YYYY', 'mm/dd/yyyy',
    'mm-dd-yyyy', "MM-DD-yyyy", "MM-DD-YY", "mm-dd-yy", "MM/DD/YY", "mm/dd/yy", 'MM/D/YYYY HH:mm:ss A', 'mm/d/yyyy HH:mm:ss A', 'M/D/YYYY HH:mm:ss A', 'M-D-YYYY HH:mm:ss A', 'MM-D-YYYY HH:mm:ss A',
    'MM-DD-YYYY H:mm:ss A', 'MM/DD/YYYY H:mm:ss A', 'M/DD/YYYY H:mm:ss A', 'MM/D/YYYY H:mm:ss A', 'mm/d/yyyy H:mm:ss A', 'M/D/YYYY H:mm:ss A', 'M-D-YYYY H:mm:ss A', 'MM-D-YYYY H:mm:ss A',
    'M/D/YYYY', 'M-D-YYYY', 'm/d/yyyy', 'm-d-yyyy', 'MM-DD-YYYY HH:mm:ss', 'MM/DD/YYYY HH:mm:ss', 'M/DD/YYYY HH:mm:ss', 'MM/D/YYYY HH:mm:ss', 'M-D-YYYY HH:mm:ss', 'M/D/YYYY HH:mm:ss', 'M/D/YY', 'M-D-YY', 'M/DD/YY', 'M-DD-YY', 'MM/D/YY', 'MM-D-YY',
    'M/D/YYYY H:mm', 'M-D-YYYY H:mm', 'MM-D-YYYY H:mm', 'MM-DD-YYYY H:mm', 'M-DD-YYYY H:mm', 'M/DD/YYYY H:mm', 'MM/D/YYYY H:mm A', 'MM-D-YYYY H:mm A', 'mm/d/yyyy H:mm A', 'mm-d-yyyy H:mm A',
    'M/DD/YYYY H:mm A', 'M-DD-YYYY H:mm A', 'm/dd/yyyy H:mm A', 'm-dd-yyyy H:mm A']

// Placement Date Format
export const DateFormat = [
    "--Select Format--", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/DD/MM", "YYYY/MM/DD", "YYYYMMDD", "YYYYDDMM", "DD-MM-YYYY", "MM-DD-YYYY", "YYYY-DD-MM", "YYYY-MM-DD"
]

export const errorMessage = "Please map the field to display identified date format";

export const dateFormatErrorMessage = "Source date format is mismatching with file date format to override the soure date format please go to FIX DATE VALUE button and select the date format as per the file date format";

export const ClientInfoConfirmedByUser = "Client InfoConfirmed By User"
export const IsClientOnboarded = "Client Verified"
export const ContractUploaded = "Contract Uploaded"
export const IsClientSetup = "Client Setup Confirmed"
export const IsUserActivated = "User Activated"
export const NewClient = "New Client"
export const WebViewAccessRequested = "WebView Access Requested"

export const regexGroupName = /^[a-zA-Z0-9\-/'\s]+$/;

export const ignoreRowValue = "-1";
export const FTEPermission = "FTEPermissionGiven "

export const StateCodeNM = "NM"
export const StateCodeNV = "NV"


// Case created Reports Headers
export const CaseCreatedReportHeader = ["FIELD NAME", "FIELD VALUE"];