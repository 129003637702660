import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, List, ListItem, Snackbar, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Alert, Autocomplete } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { IPerformanceReportsResults, IPreReportIdList } from '../../../models/PerformanceReport/IPerformanceReportsResults';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './UsersCss';

const EditSubscription: React.FC<{ userId: number }> = (props) => {
    const classes = useStyles();
    const { userId } = props;
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [subscribed, setSubscribedReports] = useState<IPerformanceReportsResults[]>([]);
    const [CurrentReportsList, setCurrentReportsList] = useState([]);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [filter, setFilter] = useState(false);


    const handleEditSubscription = () => {
        setOpen(true);
        getSubscribedReportByUSer();
    }

    const handleClose = () => {
        setOpen(false);
        setCurrentReportsList([]);
    }

    const handleClose1 = () => {
        setSendEmailOpen(false);
        setCurrentReportsList([]);
    }

    const closeFilterSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFilter(false);
    };

    const occuranceList = [
        { label: 'Daily' },
        { label: 'Weekly' },
        { label: 'Monthly' },
        { label: 'Specfic Date' }
    ]

    const getSubscribedReportByUSer = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IPerformanceReportsResults[]>(`User/GetSubscribedReports?userId=${userId}`).then((reports) => {
                setSubscribedReports(reports?.data);
                let currentReport = _.cloneDeep(CurrentReportsList);
                reports?.data?.forEach((x) => {
                    let reportList = {
                        performanceReportId: x.performanceReportId,
                        performanceReportName: x?.performanceReportName,
                        isEnable: true
                    };
                    currentReport.push(reportList);
                })
                setCurrentReportsList(currentReport);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const handleReportChecked = (e, checked) => {
        let newReportsList = [...CurrentReportsList];
        newReportsList.forEach((i) => {
            if (i.performanceReportId === parseInt(e.target.value)) {
                i.isEnable = checked;
            }
        });
        setCurrentReportsList(newReportsList);
    }

    const handleSubmit = () => {
        setShowProgressBar(true);
        (async () => {
            let reportRequest = [];
            CurrentReportsList?.map((x) => {
                if (x?.isEnable) {
                    let reportdetails: IPreReportIdList = { performanceReportId: x?.performanceReportId }
                    reportRequest.push(reportdetails);
                }
            });
            let request = {
                "preReportsIdMapDetails": reportRequest,
                "userId": userId
            }
            await usePost<any>("User/ModifiedSubscribedReport", request);
            setOpen(false);
            setFilter(true);
            setCurrentReportsList([]);
        })()
    }
    return (
        <React.Fragment>
            <div>
                <Button size="small" id="EU_Edit" startIcon={<BorderColorIcon />} variant="contained" color="primary" className={classes.editButton} onClick={() => { handleEditSubscription() }}>
                    Edit Subscription
                </Button>

                <Dialog maxWidth='md' TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 15, maxHeight: 'calc(100% - 40px)' } }}
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleHeader}>
                            EDIT USER SUBSCRIPTION
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent style={{ padding: '15px 15px' }}>
                        <Backdrop className={classes.backdrop} open={ProgressBar}>
                            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        <Box display="flex" >
                            <Box width="100%" className={classes.sidebar} style={{ borderRadius: 5 }}>
                                <Typography variant="button" display="block" color="secondary" className={classes.clientheader} >
                                    <b>Report Name </b>
                                </Typography>
                                <Box width="100%" display="flex" flexDirection="column" className={classes.boxBorder1}>
                                    <div className={`${classes.ReportScroll} ${"scrollbox"} ${"on-scrollbar"}`}>
                                        <List dense className={classes.selectboxpadding} component="div" role="list">
                                            {CurrentReportsList?.map((r) => {
                                                return (
                                                    <FormControl>
                                                        <FormControlLabel
                                                            checked={r?.isEnable}
                                                            value={r?.performanceReportId}
                                                            control={<Checkbox size='small' name={`${r?.performanceReportName}`} className={classes.paddingCheckbox} />}
                                                            label={r?.performanceReportName}
                                                            onChange={(e, selected) => { handleReportChecked(e, selected); }}
                                                            className={r?.isEnable ? classes.permittedChecked : classes.permittedFiled}
                                                        />
                                                    </FormControl>
                                                );
                                            })}
                                            {!ProgressBar && !CurrentReportsList.length ?
                                                <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                                                    No Reports to display..
                                                </Typography> : null}
                                            <ListItem />
                                        </List>
                                    </div >
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={0} >
                            <Grid xs={8} sm={8}>
                                <FormControl className={classes.boxEmailStyle2}>
                                    {<FormControlLabel
                                        control={<Checkbox size='small' name="UserList" style={{ padding: '0px 7px' }} />}
                                        label="Send Email" onClick={() => { setSendEmailOpen(true); }}
                                        style={{ marginLeft: '2px' }}
                                    />}
                                </FormControl>
                            </Grid>
                            <Grid xs={4} sm={4} className={classes.buttonDisplay}>
                                <Button size="small" id="DU_ok_btn" variant="contained" startIcon={<DoneAllIcon />} className={classes.shareBtn} onClick={handleSubmit}>
                                    Update Subscription
                                </Button>
                                <Button size="small" id="DU_Cancel_btn" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                <Dialog TransitionComponent={Transition}
                    fullScreen={fullScreen} open={sendEmailOpen} onClose={handleClose1}
                    classes={{ paper: classes.dialogeInvitePaper }}
                    aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
                        <Typography variant="h6" className={classes.titleheader1}>
                            SEND EMAIL NOTIFICATION
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent>
                        <Typography variant='subtitle2'>
                            <b><u>Note :</u></b> This email send notification is used to send email to users based on the occurance type.
                        </Typography>

                        <Typography variant='subtitle1' className={classes.activityName}>
                            <b> Occurance Type</b>
                        </Typography>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={occuranceList}
                            className={classes.controlStyle}
                            renderInput={(params) => <TextField {...params} label="Occurance Type" variant="outlined" />}
                        />

                        <Typography variant='subtitle2' style={{ marginTop: '15px' }}>
                            <b><u>Note :</u></b> The below "Date Time" will be visible when click on Occurance Type as "Specfic Date".
                        </Typography>

                        <Typography variant='subtitle1' className={classes.activityName}>
                            <b>Specfic Date</b>
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk size='small'
                                variant="inline"
                                autoComplete='off'
                                inputVariant="outlined"
                                label="Specfic Date"
                                format="MM/dd/yyyy"
                                value={selectedDate}
                                className={classes.controlStyle}
                                InputAdornmentProps={{ position: "end" }}
                                onChange={date => handleDateChange(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" id="DU_ok_btn" variant="contained" startIcon={<DoneAllIcon />} className={classes.yesButton} style={{ marginRight: '5px' }} onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button size="small" id="DU_Cancel_btn" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose1} color="primary" className={classes.noButton}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar className="snackBarStyle" open={filter} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeFilterSnackbar}>
                    <Alert onClose={closeFilterSnackbar} severity="success" className="alertStyle">
                        Report Shared Successfully!
                    </Alert>
                </Snackbar>
            </div>
        </React.Fragment>
    );
}

export default EditSubscription

