import DateFnsUtils from '@date-io/date-fns';
import {
  Avatar, Box, Button, CircularProgress, CssBaseline, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import NotificationTable from "../../components/GlobalAdmin/NotificationRollBack/NotificationTable";
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import StyledCell from "../../components/GlobalStyles/StyledCell";
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
import { IActiveUsers } from "../../models/NotificationRollBack/IActiveUsers";
import { INotificationContract } from '../../models/NotificationRollBack/INotificationContract';
import { INotificationTypeList } from '../../models/NotificationRollBack/INotificationTypeList';
import { IRollbackNotificationList } from "../../models/NotificationRollBack/IRollbackNotificationList";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

import { Variant } from "@material-ui/core/styles/createTypography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "2cm",
    alignItems: "center"
  },
  root: {
    flexGrow: 1,
    padding: '3px 10px 2px 10px'
  },
  actions: {
    width: "100%",
    marginTop: "0.5cm"
  },
  buttons: {
    borderRadius: 20,
    background: "#007FFF",
    color: "white",
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: '3px'
  },
  textField: {
    marginTop: "12px",
    alignContent: 'flex-start',
    justify: "flex-start",
    padding: '0',
    width: '93%',
    '& .MuiInputLabel-root': {
      fontSize: 14.2
    },
    '& .MuiInputBase-input': {
      fontSize: 14.2
    },
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  formControl: {
    margintop: "1cm",
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid gray',
    marginTop: '0px'
  },
  filter: {
    marginTop: '15px',
    color: 'blue',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  descriptionText: {
    textAlign: 'left',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
    fontSize: '12px',
  },
  TextHighlight: {
    color: 'black',
    fontWeight: 'bold',
    background: "yellow",
  },
  rollbutton: {
    float: 'left',
    borderRadius: "20px",
    fontSize: 12,
    marginTop: '3px'
  },
  tablebody: {
    maxHeight: '440px',
    minHeight: '440px',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 260px)',
      minHeight: 'calc( 100vh - 260px)',
    },
  },
  searchBox: {
    borderRadius: "20px",
    border: '2px black solid',
    width: '80%',
    height: '35px',
    fontSize: 25,
    textIndent: '10px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  },
  paper1: {
    border: "2px solid black",
  },
  userDropdownStyle: {
    marginTop: "-4px",
    width: '95%'
  },
  NotificationStyle: {
    marginTop: "-4px",
    width: '95%'
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: 12,
    padding: '3px 15px'
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '3px 15px'
  },
  label: {
    '&$focused': {
      color: 'white',
      border: '0px'
    },
  },
  focused: {},
  outlinedInput: {
    fontSize: 14,
    color: "black",
    marginTop: '-4px',
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  notchedOutline: {
    color: 'white',
    border: '0px'
  },
  TextNormal: {
    color: 'black',
    fontSize: 12
  },
  ErrorMessage: {
    color: "red",
    marginTop: "10px",
    textAlign: "center",
    fontSize: 15
  },
  IconSizeStyle: {
    fontSize: 20,
    marginLeft: '2px'
  },
  gridheader: {
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    filter: 'drop-shadow(0 -6mm 4mm gray))',
  },
  actionTakenStyle1: {
    marginTop: "14px",
    width: "175px",
  },
  rolledBackStyle: {
    marginTop: "14px",
    width: "175px"
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '50px'
  },
  clientAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  globalAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  superAdminColor: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  regularUser: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  fileAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid gray',
  },
  ErrorMessageStyle: {
    color: "red",
    textAlign: 'center',
    fontSize: 13
  },
  textStyle: {
    textAlign: 'left'
  },
  autoCompleteFont: {
    fontSize: 13,
  },
}))

const usePopoverStyles = makeStyles({
  paper: {
    border: '2px solid black'
  }
});

function useVariant(variant?: Variant) {
  const [defaultVariant, setDefaultVariant] = useState<Variant>("h6");
  const largeScreen = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (largeScreen) {
      setDefaultVariant("h5");
    } else {
      setDefaultVariant("h6");
    }
  }, [largeScreen]);

  return variant || defaultVariant;
}


function NotificationRollback(props) {
  const variant = useVariant(props.variant);
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { state, dispatch } = useGlobalState();

  const [EndDate, setEndDate] = useState(new Date());
  const [StartDate, setStartDate] = useState(new Date());

  const [rollbackNotification, setRollbackNotification] = useState<IRollbackNotificationList[]>([]);
  const [NotificationType, setNotificationType] = useState<INotificationTypeList[]>([]);
  const [SelectNotificationType, setSelectNotificationType] = useState<any>();
  const [ActionTaken, setActionTaken] = useState<INotificationContract>({});
  const [RolledBack, setRolledBack] = useState<INotificationContract>({});
  const [searchNotification, setSearchNotification] = useState<string>();
  const [activeUsers, setactiveUsers] = useState<IActiveUsers[]>([]);
  const [SelectActiveUser, setSelectActiveUser] = useState<any>();

  const [orderBy, setOrderBy] = useState<string>("createdDate");
  const [order, setOrder] = useState<any>('desc');

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const usersLoading = activeUsers.length === 0;
  const [page, setPage] = React.useState(0);

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [selectedUser, setSelectedUser] = useState(false);
  const [rollBack, setRollBack] = useState(false);
  const [NotifyId, setNotifyId] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = useState(false);
  const [mapped, setMapped] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "firstname " + " " + "lastname", numeric: false, disablePadding: false, label: "USER", sortable: true },
    { id: "createdDate", numeric: false, disablePadding: false, label: "DATE", sortable: true },
    { id: "notificationName", numeric: false, disablePadding: false, label: "NOTIFICATION TYPE", sortable: true },
    { id: "extraData_3", numeric: false, disablePadding: false, label: "SUBJECT", sortable: true },
    { id: "", numeric: false, disablePadding: false, label: "REMOVE", sortable: false }
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.TableCellStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id || headCell.sortable ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const initiateSearch = () => {
    setPage(0);
    setTriggerSearch(!triggerSearch);
    getRollbackNotificationListAsync();
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setShowProgressBar(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setShowProgressBar(true);
  };

  const handleFirstDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleActionChange = (event) => {
    const name = event.target.name;
    setAction(true);
    setActionTaken({
      ...ActionTaken,
      [name]: event.target.value
    });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleRolledbackChange = (event) => {
    const name = event.target.name;
    setRollBack(true);
    setRolledBack({
      ...RolledBack,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    getRollbackNotificationListAsync();
  }, [activeUsers, page, rowsPerPage])

  const handleFilter = () => {
    getRollbackNotificationListAsync();
    setOpen(true);
  }

  function getRollbackNotificationListAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      let request = {
        "rowsPerPage": rowsPerPage,
        "searchtext": searchNotification?.trimLeft().trimRight() ?? "",
        "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
        "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
        "userid": (selectedUser ? (SelectActiveUser === null ? 0 : SelectActiveUser?.id) : 0),
        "notificationtypeid": (NotifyId ? 0 : SelectNotificationType?.notificationTypeId),
        "actiontaken": (action ? parseInt(ActionTaken.actiontaken.toString()) : 0),
        "rolledback": (rollBack ? RolledBack.rolledback : 0),
        "startingRecordNumber": (page * rowsPerPage) + 1,
      };
      await usePost<{ rollbackNotification: IRollbackNotificationList[], totalCount: number }>("GetRollbackNotificationList", request).then((GetNotificationList) => {
        setRollbackNotification(GetNotificationList.data["rollbackNotification"]);
        setMapped(!GetNotificationList.data["rollbackNotification"].length ? true : false);
        setTotalRecords(GetNotificationList.data.totalCount);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })();
  }

  useEffect(() => {
    (async () => {
      try {
        await useFetch<IActiveUsers[]>("GetActiveUsers").then((GetActiveUsers) => {
          setactiveUsers(GetActiveUsers.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        await useFetch<INotificationTypeList[]>("GetNotificationTypeList").then((NotificationTypeList) => {
          setNotificationType(NotificationTypeList.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  function onUsersSelect(event, value) {
    setSelectActiveUser(value);
    setSelectedUser(true);
    setPage(0);
    (async () => {
      try {
        await useFetch<IActiveUsers[]>("GetActiveUsers?id=" + value.id).then((GetActiveUsers) => {
          setactiveUsers(GetActiveUsers.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  function onNotificationSelect(event, value) {
    setPage(0);
    (async () => {
      try {
        await useFetch<INotificationTypeList[]>("GetNotificationTypeList?notificationTypeId=" + value.notificationTypeId);
        setSelectNotificationType(value?.notificationTypeId === 10 ? 0 : value);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  useEffect(() => {
    if (SelectNotificationType?.notificationTypeId === 10 || SelectNotificationType === undefined) {
      setNotifyId(true);
    } else {
      setNotifyId(false);
    }
  }, [NotifyId, SelectNotificationType])


  const RollAllBack = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      let request = {
        "rowsPerPage": (rowsPerPage),
        "searchtext": searchNotification ?? "",
        "startdate": (selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
        "enddate": (selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
        "userid": (selectedUser ? SelectActiveUser.id : 0),
        "notificationtypeid": (NotifyId ? 0 : SelectNotificationType.notificationTypeId),
        "actiontaken": (action ? ActionTaken.actiontaken : 0),
        "rolledback": (rollBack ? RolledBack.rolledback : 0),
        "startingRecordNumber": (page * rowsPerPage) + 1,
      };
      await usePost<IRollbackNotificationList[]>("RollbackAllNotification", request).finally(() => {
        setShowProgressBar(false);
      });
      getRollbackNotificationListAsync();
    })();
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function openConfirmDialog() {
    setDialogOpen(true);
  }

  function RollBackAllNotification() {
    RollAllBack();
    setDialogOpen(false);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function clearSearchValues() {
    setSearchNotification("");
    getRollbackNotificationListAsyncClear();
    setShowSearchIcon(true);
    setPage(0);
  }
  function getRollbackNotificationListAsyncClear() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      let request = {
        "rowsPerPage": rowsPerPage,
        "searchtext": "",
        "startdate": (selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
        "enddate": (selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
        "userid": (selectedUser ? (SelectActiveUser === null ? 0 : SelectActiveUser.id) : 0),
        "notificationtypeid": (NotifyId ? 0 : SelectNotificationType.notificationTypeId),
        "actiontaken": (action ? parseInt(ActionTaken.actiontaken.toString()) : 0),
        "rolledback": (rollBack ? RolledBack.rolledback : 0),
        "startingRecordNumber": (page * rowsPerPage) + 1,
      };
      await usePost<{ rollbackNotification: IRollbackNotificationList[], totalCount: number }>("GetRollbackNotificationList", request).then((GetNotificationList) => {
        setRollbackNotification(GetNotificationList.data["rollbackNotification"]);
        setMapped(!GetNotificationList.data["rollbackNotification"].length ? true : false);
        setTotalRecords(GetNotificationList.data.totalCount);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })();
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container xs={12}>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
              <b>NOTIFICATION ROLLBACK</b>
            </Typography>
          </Grid>
          <Grid item xs={5} sm={4} lg={6} >
            <TextField value={searchNotification} variant="outlined" size="small"
              type='text' placeholder="Search Notification"
              className={classes.searchBox}
              onChange={e => setSearchNotification(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchNotification != "") {
                  initiateSearch(); setShowSearchIcon(false);
                } else if (event.key === 'Enter' && searchNotification === "") {
                  initiateSearch(); setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxlength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { initiateSearch(); setShowSearchIcon(false); }}>
                        <SearchIcon className={classes.IconSizeStyle} />
                      </IconButton>
                      :
                      <IconButton onClick={e => { clearSearchValues(); }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
            {StartDate > EndDate ? <Typography variant='h6' className={classes.ErrorMessageStyle}>End date should be greater than the Start date!</Typography> : ""}
          </Grid>
          <Grid item xs={4} sm={3} >
            <Box display="flex" justifyContent="flex-end">
              <Button size="small" id="NRB_RollBackAll" startIcon={<AutorenewIcon />} variant="contained" color="primary" onClick={openConfirmDialog} className={classes.rollbutton}>RollBack All</Button>
            </Box>
          </Grid>
          <Grid container xs={12} spacing={0} justify='space-evenly' className={classes.gridheader}>
            <Grid item xs={6} sm={4} md={3} lg={2} justify="flex-start">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  label="Start Date"
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format={DateFormat}
                  className={classes.textField}
                  margin="normal"
                  id="NRB_StartDate"
                  value={StartDate}
                  onChange={handleFirstDateChange}
                  inputVariant="outlined"
                  name="startdate"
                  disableFuture={true}
                  PopoverProps={{ classes: popoverClasses }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  inputVariant="outlined"
                  label="End Date"
                  format={DateFormat}
                  className={classes.textField}
                  margin="normal"
                  id="NRB_EndDate"
                  value={EndDate}
                  onChange={handleEndDateChange}
                  name="enddate"
                  disableFuture={true}
                  PopoverProps={{ classes: popoverClasses }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <Autocomplete
                size="small"
                id="NRB_SelectUsers"
                autoComplete autoHighlight
                className={classes.userDropdownStyle}
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                options={activeUsers} onChange={onUsersSelect}
                getOptionLabel={(option) => option.firstname + " " + option.lastname}
                renderInput={(params) => (
                  <TextField {...params} label="Users" margin="normal" variant="outlined" size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={2}>
              <Autocomplete
                id="NRB_NotificationType"
                autoComplete autoHighlight
                className={classes.NotificationStyle}
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                options={NotificationType} onChange={onNotificationSelect}
                getOptionLabel={(option) => option.notificationName}
                renderInput={(params) => (
                  <TextField {...params} label="Notification Type" margin="normal" variant="outlined"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={3} style={{ display: 'flex', gap: '15px' }}>
              <FormControl size="small" variant="outlined" className={`${classes.formControl} ${classes.actionTakenStyle1}`}>
                <InputLabel htmlFor="outlined-age-native-simple">Action Taken</InputLabel>
                <Select id="NRB_SelectAction" native value={ActionTaken.actiontaken} onChange={handleActionChange} label="Action Taken"
                  inputProps={{
                    name: 'actiontaken',
                    id: 'outlined-age-native-simple',
                  }}>
                  <option value={0} className={classes.autoCompleteFont}>All</option>
                  <option value={1} className={classes.autoCompleteFont}>No</option>
                  <option value={2} className={classes.autoCompleteFont}>Yes</option>
                </Select>
              </FormControl>
              <FormControl size="small" variant="outlined" className={`${classes.formControl} ${classes.rolledBackStyle}`}>
                <InputLabel htmlFor="outlined-age-native">Rolled-Back</InputLabel>
                <Select id="NRB_SelectRolledBack" native value={RolledBack.rolledback} onChange={handleRolledbackChange} label="Rolled-Back"
                  inputProps={{
                    name: 'rolledback',
                    id: 'NRB_ShowORhidden',
                  }}>
                  <option value={0} className={classes.autoCompleteFont}>All</option>
                  <option value={1} className={classes.autoCompleteFont}>Show</option>
                  <option value={2} className={classes.autoCompleteFont}>Hidden</option>
                </Select>
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={3} lg={1} className={classes.filter}>
                <Button size="small" id="NRB_Filter" className={classes.buttons} onClick={() => { handleFilter(); }}
                  startIcon={<FilterAltIcon />} variant="contained" color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid>
                <IconButton id="NRB_FilterIcon" aria-label="Filter" onClick={() => { handleFilter(); }} className={classes.filter} disabled={StartDate > EndDate}>
                  <FilterListIcon style={{ fontSize: 25 }} />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} component={Paper}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table id="rollClients" aria-label="collapsible table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rollbackNotification, getComparator(order, orderBy)).map(r => {
                    return (
                      <React.Fragment>
                        <TableRow hover >
                          <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                            {r.role === 3 ?
                              <AvatarTooltip title="Super Admin">
                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                              </AvatarTooltip>
                              : r.role === 2 ?
                                <AvatarTooltip title="Global Admin">
                                  <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                </AvatarTooltip>
                                : r.role === 1 ?
                                  <AvatarTooltip title="Client Admin">
                                    <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : r.role === 0 ?
                                    <AvatarTooltip title="Regular User">
                                      <Avatar className={classes.regularUser} src={RegularUser}></Avatar>
                                    </AvatarTooltip>
                                    : <AvatarTooltip title="File Admin">
                                      <Avatar className={classes.fileAdmin} src={FileAdmin}></Avatar>
                                    </AvatarTooltip>
                            }
                          </StyledCell>
                          <StyledCell align='left' className={classes.TableCellStyle1}>
                            {r.firstname + " " + r.lastname}
                          </StyledCell>
                          <StyledCell scope="row" className={classes.TableCellStyle1}>
                            {r.createdDate !== null ? moment(r.createdDate).format(state.GlobalUtils?.settingValue) : null}
                          </StyledCell>
                          <StyledCell align='left' className={classes.TableCellStyle1}>
                            {r.notificationName}
                          </StyledCell>
                          <StyledCell align='left' className={classes.TableCellStyle1}>
                            <Typography style={{ width: '800px' }} className={classes.descriptionText}>
                              <span className={`${searchNotification === "" ? classes.TextNormal : r.notificationText === null ? null : r.notificationText.includes(searchNotification?.trimLeft().trimRight()) ? classes.TextHighlight : ""}`}>
                                {r.notificationText?.replace('µ', ' ')}
                              </span>
                            </Typography>
                          </StyledCell>
                          <StyledCell align='left' className={classes.TableCellStyle1}>
                            <NotificationTable row={r} onRollBack={getRollbackNotificationListAsync} />
                          </StyledCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              {mapped === true ?
                < Typography variant="h6" gutterBottom className={classes.ErrorMessage}>
                  No records to display..
                </Typography>
                : null}
            </TableContainer>
            <Grid container spacing={0}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={isDialogOpen}
          PaperProps={{ style: { borderRadius: 15 } }}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
            <Typography gutterBottom className={classes.titleheader}>
              ROLLBACK ALL
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h5" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                Are you sure, you want to Rollback All Notification?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { RollBackAllNotification(); }} variant="contained" className={classes.yesbutton} autoFocus>
              Yes
            </Button>
            <Button onClick={handleCloseDialog} className={classes.nobutton} variant="contained" color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Successfully Triggered!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment >
  )
}

export default NotificationRollback