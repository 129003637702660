import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
    boxView: {
        margin: '-2px',
        color: "blue"
    },
    norecord: {
        color: "red",
        fontSize: 15,
        textAlign: 'center',
        marginTop: '10px',
    },
    history: {
        color: "#009900",
        fontSize: 14
    },
    headerStyle: {
        color: "black",
        fontSize: 15
    },
    tablebody: {
        maxHeight: 200,
        minHeight: 200,
        marginBottom: '10px'
    },
    tableHeaderStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: "14px",
        padding: '3px 10px',
    },
    tableCellColor: {
        color: "white",
        background: "#007FFF",
        padding: '2px 10px',
        fontSize: '11px'
    },
    tableRowStyle: {
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: 12,
    },
    subHeading: {
        fontSize: '14px',
    },
    noRecordsColor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30px',
    },
}))

export default useStyles