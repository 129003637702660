import { css } from "@emotion/react";
import { AppBar, Box, Grid, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@mui/icons-material/Autorenew';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { rollVerbiage } from "../../../constants/Constant";
import ReadOnly from "../../../images/agentUser/readonlySupport.svg";
import lock from "../../../images/inquiry/lock.svg";
import Activelock from "../../../images/inquiry/unlocked.svg";
import { IAgentDebtor } from "../../../models/AgentInquiry/IAgentDebtor";
import { IAgentTemplatePermission } from "../../../models/AgentInquiry/IAgentTemplatePermission";
import { IAgentInvoiceDetailsList } from '../../../models/AgentInquiry/InvoiceDetails/IAgentInvoiceDetails';
import { IAccStatus } from "../../../models/Inquiry/IAccStatus";
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import LightTooltip from "../../GlobalStyles/LightTooltip";
import LightTooltipLeft from "../../GlobalStyles/LightTooltipLeft";
import InvoiceDetails from "../../InvoiceDetails/InvoiceDetails";
import AccountDetails from './AccountDetails';
import AccountHistory from './AccountHistory';
import TransactionAuditHistory from './TransactionAuditHistory';

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}  >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginRight: "2px",
        width: "15%",
        boxShadow: "1px 3px 1px #9E9E9E",
        background: "#800080",
        color: "white",
        borderRadius: 5,
        fontSize: 11,
        height: '0px',
        minHeight: '40px'
    },
    tab1: {
        marginTop: "4px",
        marginLeft: "10px"
    },
    iconLabelWrapper: {
        flexDirection: "row"
    },
    image1: {
        width: '30px',
        height: '30px',
        color: '#007FFF'
    },
    image2: {
        width: '30px',
        height: '25px',
    },
    readonlyImage: {
        width: '25px',
        height: '25px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    AwaitUsers: {
        backgroundColor: 'lightgray'
    },
    indicator: {
        top: "40px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
    rowExpand: {
        display: 'flex',
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 250,
        minWidth: "100%",
        '& .MuiBox-root': {
            padding: '0px 15px 1px 15px'
        }
    },
    imagegrid: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    imageInfo: {
        width: '40px',
        height: '40px'
    },
    Certimage2: {
        width: '40px',
        height: '35px'
    },
    boxStyle: {
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: '10px',
    },
    text1Style: {
        paddingLeft: '5px',
        marginTop: '15px',
        fontSize: 18
    },
    text2Style: {
        paddingRight: '5px',
        marginTop: '15px',
        fontSize: 18
    },
    bottomright: {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: '90px',
        height: '85px',
    },
    iconPadding: {
        padding: '3px 5px'
    }
}))

const InquiryDetail: React.FC<{ inquiryDetail: IAgentDebtor, disable?: IGetRolledPlacement, isBlocked: boolean, onCheck: () => void }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [value, setValue] = useState(0);
    const [spin, setSpin] = useState(false);
    const [mapped, setMapped] = useState(false);
    const UserName = state.userAccessContext?.id;
    const [checkData, setCheckData] = useState(false);
    const [invoiceType, setInvoiceType] = useState(1);
    const { inquiryDetail, disable, isBlocked } = props;
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [AccDetails, setAccDetails] = useState<IAccStatus>();
    const [isClosed, setisClosed] = useState<IGetRolledPlacement>();
    const [history, setHistory] = useState<ITransactionHistory[]>([]);
    const readOnlyAccess = state?.AgentUserAccessContext?.readOnlyAccess;
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [GetLetterDetails, setGetLetterDetails] = useState<ILetterDetails[]>([]);
    const [visibleLetter, setVisibleLetter] = useState<IClosedLetterDetails[]>([]);
    const [invoiceDetails, setInvoiceDetails] = useState<IAgentInvoiceDetailsList[]>([]);
    const [templatePermission, setTemplatePermission] = useState<IAgentTemplatePermission[]>([]);

    /* Spinner */
    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    useEffect(() => {
        (async () => {
            let request = {
                "agentUserId": state?.AgentUserAccessContext?.agentUserId,
                "clientCode": inquiryDetail?.dbT_CLIENT,
                "phaseCode": inquiryDetail?.dbT_COMPANY
            }
            setshowProgressBar(true);
            await usePost<{ agentTemplatePermissions: IAgentTemplatePermission[] }>("AgentUser/GetAgentUserActivity", request).then((r) => {
                setTemplatePermission(r?.data?.agentTemplatePermissions);
            }).catch(() => {
                setshowProgressBar(false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetTransactionHistory) => {
                setTransaction(GetTransactionHistory.data);
                setCheckData(GetTransactionHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetDebtorHistory) => {
                setHistory(GetDebtorHistory.data);
                setMapped(GetDebtorHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    useEffect(() => {
        if (visibleLetter.length <= 0) {
            getClosedLetterDetails();
        }
    }, []);

    async function getClosedLetterDetails() {
        await useFetch<IClosedLetterDetails[]>(`AccountLetterDetailsGet?DBTNumber=${inquiryDetail.dbT_NO.toString()}`).then((LetterVisible) => {
            setVisibleLetter(LetterVisible.data);
        });
    }

    useEffect(() => {
        (async () => {
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            })
        })()
    }, []);

    useEffect(() => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            })
        })()
    }, [])

    const GetAccStatus = () => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            });
        })()
    }

    const DisableButton = () => {
        (async () => {
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data.data[0]);
            })
        })()
    }

    useEffect(() => {
        if (GetLetterDetails.length <= 0) {
            (async () => {
                let request = { "debtorId": inquiryDetail.dbT_NO };
                const response = await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request);
                const data = response.data.letterDetails.map((a) => {
                    return { ...a, clT_NAME_1: a.clT_NAME_1 }
                })
                setGetLetterDetails(data);
            })()
        }
    }, [])

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": 0,
                "userid": UserName,
                "debtorid": inquiryDetail.dbT_NO,
                "typeId": 5,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    // API for get the Agent Invoice Details
    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            let request = { "dbt_no": inquiryDetail?.dbT_NO, "clientCode": inquiryDetail?.dbT_CLIENT };
            await usePost<{ invoiceDetailsResults: IAgentInvoiceDetailsList[], invoiceType: number }>
                ("Invoice/GetInvoiceDetails", request).then((r) => {
                    setInvoiceDetails(r?.data?.invoiceDetailsResults);
                    setInvoiceType(r?.data?.invoiceType);
                }).finally(() => {
                    setshowProgressBar(false);
                });
        })()
    }, [])

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.rowExpand}>
                <Tabs id="I_Tab"
                    value={value} className={classes.tab1} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example" >
                    <Tab id="AI_AccountDetails" className={`${classes.tabs} ${classes.iconLabelWrapper}`} label="Account Details" />
                    <Tab id="AI_AccountHistory" className={classes.tabs} label="Account History" />
                    <Tab id="AI_TransactionAuditHistory" className={classes.tabs} label="Transaction Audit History" />
                    <Tab id="AI_InvoiceDetails" className={classes.tabs} label="Invoice Details" />
                    <Grid item xs={7} className={classes.imagegrid}>
                        {templatePermission[0]?.readOnly || readOnlyAccess ?
                            <IconButton className={classes.iconPadding}>
                                <LightTooltip title="Read Only" >
                                    <img src={ReadOnly} alt="Indication for read only" className={classes.readonlyImage} />
                                </LightTooltip>
                            </IconButton>
                            :
                            null
                        }
                        {isClosed?.rejectedRoll === true ?
                            <IconButton id="Active_Status" className={`${classes.iconPadding} ${"close-account-info"}`}>
                                <LightTooltipLeft title={rollVerbiage}>
                                    <InfoIcon className={classes.image1} />
                                </LightTooltipLeft>
                            </IconButton>
                            : null
                        }
                        {isBlocked ?
                            <>
                                <IconButton id="Active_Status" className={classes.iconPadding}>
                                    <img src={lock} alt="Lock image for account Details" className={classes.image2} />
                                </IconButton>
                                <IconButton id="Active_Status" onClick={(_) => { props.onCheck(); refreshCanvas(); }} className={classes.iconPadding}>
                                    <Autorenew fontSize="medium" className={`${spin ? classes.rotateIcon : null}`} />
                                </IconButton>
                            </>
                            :
                            <IconButton id="Active_Status" className={classes.iconPadding}>
                                <img src={Activelock} alt="Unlock image for account Details" className={classes.image1} />
                            </IconButton>
                        }
                    </Grid>
                </Tabs>
                <TabPanel value={value} index={0}>
                    {ProgressBar ? <PuffLoader css={override} size={80} color={"blue"} speedMultiplier={1.5} /> :
                        <React.Fragment>
                            <AccountDetails debtors={inquiryDetail} letterDetails={visibleLetter} disablee={disable} isEnable={isClosed} onEroll={DisableButton} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} GetDebtorLetterDetails={GetLetterDetails} GetAccStatus={AccDetails} OnGetDetails={GetAccStatus} templateAccess={templatePermission[0]} />
                        </React.Fragment>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AccountHistory debtors={inquiryDetail} histories={history} mapped={mapped} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TransactionAuditHistory debtors={inquiryDetail} transactions={Transaction} mapped={checkData} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <InvoiceDetails invoiceDetails={invoiceDetails} invoiceType={invoiceType} />
                </TabPanel>

            </AppBar >
        </Box >
    )
}

export default InquiryDetail
