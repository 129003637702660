import {
    Box,
    Dialog, DialogActions, DialogContent, DialogContentText, Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React, { useState } from 'react';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import important from '../../images/users/warning.svg';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            borderRadius: 20,
            fontSize: 11,
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            fontSize: 12,
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            fontSize: 12,
            display: 'flex',
            right: '10px',
            '&:hover': {
                background: "red",
            }
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        imageSize: {
            height: '80px',
            width: '80px'
        },
        textcolor: {
            color: 'blue'
        },
        textprop: {
            marginTop: "15px",
            color: "black"
        }
    })
);

const InviteUserforCA: React.FC<{ userRow: IUserInfo, onInvite: () => void }> = (props) => {
    const theme = useTheme();
    const classes = useRowStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [sendInvite, setInvite] = useState(false);
    const [CheckEmail, setCheckEmail] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setCheckEmail(false);
    };

    const checkSecondaryEmail = () => {
        (async () => {
            let request = {
                "email": props?.userRow?.userName
            }
            await usePost<any>("User/CheckSecondaryEmail", request).then((r) => {
                if (r.data?.isExists === true && props?.userRow?.portalUserLogOn === true) {
                    setCheckEmail(true);
                } else {
                    setOpen(true);
                }
            });
        })()
    }

    async function getInviteUsersAsync() {
        setOpen(false);
        dispatch({ type: GlobalStateAction.Busy });
        try {
            await useFetch<IUserInfo>("User/InviteUser?userId=" + props.userRow.id);
            props.onInvite();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvite(false);
    };

    return (
        <div>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'xs'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        Invite User
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                            Are you sure, you want to Invite this User
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" size="small" variant="contained" onClick={(_) => { getInviteUsersAsync(); setInvite(true); }} className={classes.yesButton}>
                        Yes
                    </Button>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Button id="InviteUser_btn" size="small" variant="contained" className={classes.button} startIcon={props.userRow.portalUserLogOn ? <DoneAllIcon /> : props.userRow.portalUserExist ? <ContactMailIcon /> : <ContactMailIcon />} color="primary" onClick={(_) => { checkSecondaryEmail(); }} disabled={props.userRow.portalUserLogOn} style={{ borderRadius: 20 }}>
                {props.userRow.portalUserLogOn ? "Invited" : props.userRow.portalUserExist ? "Re-Invite" : "Invite"}
            </Button>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={CheckEmail} onClose={handleCloseDialog} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        USER INFO
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <img src={important} alt="Icon for Invite User" className={classes.imageSize} />
                        </Box>
                        <Typography variant="h5" className={classes.textprop} gutterBottom>
                            <b className={classes.textcolor}>"{props?.userRow?.userName}"</b> Email id conflicts with existing primary email id. Please contact <b className={classes.textcolor}>"ClientService@armsolutions.com"</b> for resolution.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_Cancel_btn" variant="contained" autoFocus onClick={handleCloseDialog} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={sendInvite} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Invitation Sent Successfully!
                </Alert>
            </Snackbar>
        </div >
    );
}

export default InviteUserforCA