import { AppBar, Box, Grid, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
// Icons
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@mui/icons-material/Autorenew';
import lock from "../../images/inquiry/lock.svg";
import Activelock from "../../images/inquiry/unlocked.svg";
// Models
import React, { useEffect, useState } from 'react';
import { rollVerbiage } from '../../constants/Constant';
import { IAccountHistory, ITransaction } from '../../models/IInquiry';
import { IAccStatus } from '../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { IDebtor } from '../../models/Roll/IDebtor';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
// Components
import LightTooltipLeft from "../GlobalStyles/LightTooltipLeft";
import { RollSpinner } from '../GlobalStyles/Loading';
import AccountDetails from './AccountDetails';
import AccountHistory from './AccountHistory';
import TransactionAuditHistory from './TransactionAuditHistory';

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginRight: "2px",
        width: "15%",
        boxShadow: "1px 3px 1px #9E9E9E",
        background: "#800080",
        color: "white",
        borderRadius: 5,
        fontSize: 11,
        height: '0px',
        minHeight: '40px',
        '& .MuiTab-wrapper': {
            whiteSpace: 'nowrap',
        }
    },
    image1: {
        width: '30px',
        height: '30px',
    },
    image2: {
        width: '30px',
        height: '25px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    rowExpand: {
        display: 'flex',
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 250,
        minWidth: "100%",
        '& .MuiBox-root': {
            padding: '0px 15px 2px 15px'
        }
    },
    indicator: {
        top: "38px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
    TabMargin: {
        marginTop: "4px",
        marginLeft: "10px"
    },
    Tab1Style: {
        background: "#800080",
        color: "white", borderRadius: 5
    },
    Tab2Style: {
        background: "#800080",
        color: "white",
        borderBottom: "none",
        borderRadius: 5
    },
    Tab3Style: {
        background: "#800080",
        color: "white",
        borderRadius: 5
    },
    image3: {
        width: '30px',
        height: '30px',
        color: '#007FFF'
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
}))


const RollClientDetails: React.FC<{ DebtorDetails: IDebtor, accountHistory?: IAccountHistory[], transactionHistory?: ITransaction[], onRollRemove: () => void, onUndoCloseRemove: () => void, onEroll: () => void, onDelete: () => void, isBlocked: boolean, onCheck: () => void }> = (props) => {
    const classes = useStyles();
    const { DebtorDetails, onRollRemove, onUndoCloseRemove, onEroll, onDelete, isBlocked } = props;
    const [value, setValue] = React.useState(0);
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [GetLetterDetails, setGetLetterDetails] = useState<ILetterDetails[]>([]);
    const [visibleLetter, setVisibleLetter] = useState<IClosedLetterDetails[]>([]);
    const [isClosed, setisClosed] = useState<IGetRolledPlacement>();
    const [mapped, setMapped] = useState(false);
    const [historyDetails, setHistoryDetails] = useState<ITransactionHistory[]>([]);
    const [checkData, setCheckData] = React.useState(false);
    const [spin, setSpin] = React.useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [AccDetails, setAccDetails] = useState<IAccStatus>();

    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${DebtorDetails.dbT_NO}`).then((GetDebtorHistory) => {
                setHistoryDetails(GetDebtorHistory.data);
                setMapped(GetDebtorHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${DebtorDetails.dbT_NO}`).then((GetTransactionHistory) => {
                setTransaction(GetTransactionHistory.data);
                setCheckData(GetTransactionHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${DebtorDetails.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${DebtorDetails.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, [])

    const GetAccStatus = () => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${DebtorDetails.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            });
        })()
    }

    useEffect(() => {
        if (visibleLetter.length <= 0) {
            getClosedLetterDetails();
        }
    }, []);

    async function getClosedLetterDetails() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            await useFetch<IClosedLetterDetails[]>(`AccountLetterDetailsGet?DBTNumber=${DebtorDetails.dbT_NO.toString()}`).then((ClosedLetterDetailsGet) => {
                setVisibleLetter(ClosedLetterDetailsGet.data);
            });
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = { "debtorId": DebtorDetails.dbT_NO };
                await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request).then((GetLetterDetails) => {
                    setGetLetterDetails(GetLetterDetails.data["letterDetails"]);
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])


    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": DebtorDetails.dbT_NO,
                    "typeId": 5,
                    "userRole": 1
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.rowExpand}>
                <Tabs id="Roll_Tab" className={classes.TabMargin}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="Roll_AccountDetails" className={`${classes.tabs} ${classes.Tab1Style}`} label="Account Details" />
                    <Tab id="Roll_AccountHistory" className={`${classes.tabs} ${classes.Tab2Style}`} label="Account History" />
                    <Tab id="TransctionAuditHistory" className={`${classes.tabs} ${classes.Tab3Style}`} label="Transaction Audit History" />
                    <Grid item xs={7} className={classes.gridFlex}>
                        {isClosed?.rejectedRoll === true ?
                            <IconButton id="Active_Status" className="close-account-info" >
                                <LightTooltipLeft title={rollVerbiage}>
                                    <InfoIcon className={classes.image1} />
                                </LightTooltipLeft>
                            </IconButton>
                            : null
                        }
                        {isBlocked ?
                            <React.Fragment>
                                <IconButton id="Active_Status" >
                                    <img src={lock} alt="Lock image for account Details" className={classes.image2} />
                                </IconButton>
                                <IconButton id="Active_Status" onClick={(_) => { props.onCheck(); refreshCanvas(); }}>
                                    <Autorenew fontSize="medium" className={`${spin ? classes.rotateIcon : ""}`} />
                                </IconButton>
                            </React.Fragment>
                            :
                            <IconButton id="Active_Status" >
                                <img src={Activelock} alt="UnLock image for account Details" className={classes.image1} />
                            </IconButton>
                        }
                    </Grid>

                </Tabs>
                <TabPanel value={value} index={0}  >
                    {ProgressBar ? <RollSpinner /> :
                        <React.Fragment>
                            <AccountDetails debtor={DebtorDetails} onRollRemove={onRollRemove} onUndoCloseRemove={onUndoCloseRemove} onEroll={onEroll} onDelete={onDelete} letterDetails={visibleLetter} isEnable={isClosed} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} GetDebtorLetterDetails={GetLetterDetails} GetAccStatus={AccDetails} OnGetDetails={GetAccStatus} />
                        </React.Fragment>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    < AccountHistory debtors={DebtorDetails} histories={historyDetails} mapped={mapped} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TransactionAuditHistory debtors={DebtorDetails} transactions={Transaction} mapped={checkData} />
                </TabPanel>
            </AppBar>
        </Box>
    )
}

export default RollClientDetails