export const Columns = [
  { field: 'Client', type: 'string', width: 100, id: 'dbT_CLIENT', sortable: true },
  { field: 'Client Name', type: 'string', width: 100, id: 'clT_NAME_1', sortable: true },
  { field: 'PlcMonth', type: 'string', width: 100, id: 'plcMonth', sortable: true },
  { field: 'Plc YYMM', type: 'string', width: 100, id: 'plcYYMM', sortable: true },
  { field: 'Placed Qty', type: 'string', width: 150, id: 'placedQty', sortable: true },
  { field: 'PlacedAmt', type: 'string', width: 250, id: 'placedAmt', sortable: true },
  { field: 'AvgBalance', type: 'string', width: 70, id: 'avgBal', sortable: true },
  { field: 'weightedAge', type: 'string', width: 200, id: 'weightedAge', sortable: true },
  { field: 'NewAmt', type: 'string', width: 100, id: 'newAmt', sortable: true },
  { field: 'BkAmt', type: 'string', width: 120, id: 'bkAmt', sortable: true },
  { field: 'ActiveAmt', type: 'string', width: 120, id: 'activeAmt', sortable: true },
  { field: 'SkipAmt', type: 'string', width: 200, id: 'skipAmt', sortable: true },
  { field: 'TotalWeightedAvgAge', type: 'datetime', width: 100, id: 'totalWeightedAvgAge', sortable: true, align: "center" },
  { field: 'TotalAvgBal', type: 'datetime', width: 100, id: 'totalAvgBal', sortable: true },
  { field: 'CollectionPct', type: 'string', width: 120, id: 'collectionPct', sortable: true },
]
