import {
  AppBar, Backdrop, Dialog, DialogContent, DialogActions, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Button, Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HistoryIcon from "@mui/icons-material/History";
import { useState } from "react";
import { PuffLoader } from "react-spinners";
import { ARMLogo, FTEPermission } from "../../constants/Constant";
import { usePost } from "../../utils/apiHelper";
import { Transition } from "../GlobalStyles/DialogBoxTransition";
import { useStyles } from "../ProspectUser/ProspectUserCss";
import { useGlobalState } from "../../store/GlobalStore";
import moment from 'moment';
import React from "react";
import { IFtePermissionList, IFteTemplateList } from "../../models/AgentUser/IAgentClientDetails";
import { IAgentUserEditDetails } from "../../models/AgentUser/IAgentUserEditDetails";
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';

const FTEHistory: React.FC<{ agentUserId: Number; row: IAgentUserEditDetails }> = (props) => {
  const { agentUserId, row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [ProgressBar, setShowProgressBar] = useState(false);
  const { state } = useGlobalState();
  const [fTEHistory, setFTEHistory] = useState <IFtePermissionList[]>([]);
  const [showTemplateDetails, setShowTemplateDetails] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<IFteTemplateList[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
    getFTEHistory();
  };

  const handleShowTemplateDetails = () => {
    handleGetTemplateList();
    setShowTemplateDetails(true);
  }

  const handleCloseDialogBox = () => {
    setShowTemplateDetails(false);
  }

  const handleGetTemplateList = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        agentUserId: agentUserId,
      };
      await usePost<IFteTemplateList[]>("AgentUser/GetFTETemplateList", request).then((result) => {
        setTemplateDetails(result?.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })();
  }

  const getFTEHistory = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        agentUserId: agentUserId,
      };
      await usePost < IFtePermissionList[] > ("AgentUser/GetFTEHistory", request).then((result) => {
        setFTEHistory(result?.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })();
  }

  const handleClose = () => {
    setOpen(false);
  };

  function EnhancedTemplateTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells1 = [
      {
        id: "clientCode",
        disablePadding: false,
        label: "CLIENT CODE",
        sortable: false,
      },
      {
        id: "phaseCode",
        disablePadding: false,
        label: "PHASE CODE",
        sortable: false,
      },
      {
        id: "TemplateName",
        disablePadding: false,
        label: "TEMPLATE NAME",
        sortable: false,
      }
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells1.map((headCell) => (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "clientCode",
        disablePadding: false,
        label: "CLIENT CODE",
        sortable: false,
      },
      {
        id: "phaseCode",
        disablePadding: false,
        label: "PHASE CODE",
        sortable: false,
      },
      {
        id: "fTEAction",
        disablePadding: false,
        label: "FTE ACTION",
        sortable: false,
      },
      {
        id: "previousTemplateName",
        disablePadding: false,
        label: "OLD TEMPLATE",
        sortable: false,
      },
      {
        id: "currentTemplateName",
        disablePadding: false,
        label: "NEW TEMPLATE",
        sortable: false,
      },
      {
        id: "actionTakenBy",
        disablePadding: false,
        label: "ACTION TAKEN BY",
        sortable: false,
      },
      {
        id: "fTEActionTakenDate",
        disablePadding: false,
        label: "ACTION DATE",
        sortable: false,
      },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div>
      <IconButton style={{ padding: "6px" }} onClick={handleClickOpen}>
        <HistoryIcon style={{ color: "#B30987" }} />
      </IconButton>

      <Dialog fullScreen className={classes.dialog} open={open} onClose={handleClose} TransitionComponent={Transition} >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarStyle}>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
              <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <b> AGENT FTE HISTORY ({row?.firstName}{" "} {row?.lastName} - {row?.emailAddress}) </b>
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => setOpen(false)} aria-label="close" style={{ padding: "5px" }} >
              <CloseIcon className={classes.closeIconButton} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent>
          <TableContainer component={Paper} className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`} >
            <Table size="small" aria-label="customized table" stickyHeader>
              <EnhancedTableHead classes={classes} />
              {fTEHistory?.map((row) => (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <span>
                        {row?.clientCode}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row?.phaseCode}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        <Chip label={row?.fteAction} className={`${row?.fteActionId === 1 ? classes.chipStyle : row?.fteActionId === 2 ? classes.chipStyle1 : row?.fteActionId === 3 ? classes.chipStyle2 : row?.fteActionId === 4 ? classes.chipStyle3 : row?.fteActionId === 5 ? classes.chipStyle4 : null}`}/>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row?.previousTemplateName}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row?.currentTemplateName === null && row?.fteAction === FTEPermission ? (
                          <span className={classes.viewTemplateStyle} onClick={handleShowTemplateDetails}>
                            View Template
                          </span>
                        ) : (
                          row?.currentTemplateName
                        )}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row?.actionTakenBy}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" className={classes.tableCellFontSize}>
                        {moment(row?.fteActionTakenDate).format(state?.GlobalUtils?.settingValue)}{" "}
                        {moment(row?.fteActionTakenDate).format("HH:mm")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
            {!fTEHistory.length ? (
              <Typography variant="h6" align="center" gutterBottom
                className={classes.ErrorMessageStyle1} >
                No history to display..
              </Typography>
            ) : null}
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog TransitionComponent={Transition} open={showTemplateDetails}
        className={classes.dialogboxOverride}
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
          <Typography variant="h5" gutterBottom className={classes.titleheader}>
            TEMPLATE DETAILS
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <TableContainer component={Paper} className={`${classes.tablebodyStyle} ${"scrollbox"} ${"on-scrollbar"}`} >
          {templateDetails?.length ?
            (<>
                  <Table size="small" aria-label="customized table" stickyHeader>
                  <EnhancedTemplateTableHead classes={classes} />
                  {templateDetails?.map((row) => (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <span>
                            {row?.clientCode}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            {row?.phaseCode}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            {row?.templateName}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </> ) : 
              < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle} style={{marginTop: '20px', marginLeft:'40%', fontSize:'18px'}} >
              No records to display..
              </Typography>
          }
            
            {!fTEHistory.length ? (
              <Typography variant="h6" align="center" gutterBottom
                className={classes.ErrorMessageStyle1} >
                No history to display..
              </Typography>
            ) : null}
          </TableContainer>

        </DialogContent>
        <DialogActions>
          <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleCloseDialogBox} color="primary" className={classes.noButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FTEHistory;
