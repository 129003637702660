import { Backdrop, Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { IPerformanceReportsResults } from '../../../models/PerformanceReport/IPerformanceReportsResults';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import Footer from '../../Footer/Footer';
import { StyledTableRow } from '../../GlobalStyles/TableStyledCell';
import { useStyles } from '../MangeActivityTemplate/AG_TemplateCss';
import EditReport from './EditReport';


function EnhancedTableHead(props) {
    const {
        classes,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: "", label: "REPORT NAME", sortable: true },
        { id: "", label: "PROCEDURE NAME", sortable: true },
        { id: "", label: "CREATED DATE", sortable: true },
        { id: "", label: "ACTIONS", sortable: false },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id} id="AT_TableHeader"
                        className={classes.TableCellStyle}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const ManageReports = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [performanceReportsResult, setPerformanceReportsResult] = useState<IPerformanceReportsResults[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageNumber(0);
    };

    useEffect(() => {
        getPerformanceReportsList();
    }, []);

    const getPerformanceReportsList = () => {
        (async () => {
            setShowProgressBar(true);
            let request = {
                "userid": state.userAccessContext?.id,
                "searchText": null,
                "userType": state.userAccessContext?.role,
                "startingRecordNumber": 0,
                "rowsPerPage": rowsPerPage
            }
            await usePost<{ performanceReportResults: IPerformanceReportsResults[], totalCount: number }>('PerformanceReportGetByUser', request).then((r) => {
                setPerformanceReportsResult(r?.data?.performanceReportResults);
                setTotalCount(r?.data?.totalCount);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }


    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Box component='span' m={0} width="20%">
                        <Typography variant="h6" gutterBottom className={`${classes.titleHead} ${"headertitle"}`}>
                            <b>MANAGE REPORTS</b>
                        </Typography>
                    </Box>
                    <Box component='span' m={0} width="70%">
                        <TextField variant="outlined"
                            className={classes.searchBox}
                            size="small"
                            type='text' placeholder="Search Report Name"
                            inputProps={{ maxlength: 256 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box component='span' m={0} width="10%">
                        <Button variant='contained' color="primary" size="small" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} className={classes.backButton}  >
                            Back
                        </Button>
                    </Box>

                    <Grid component={Paper} xs={12} sm={12} lg={12} >
                        <TableContainer component={Paper} className={` ${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table>
                                <EnhancedTableHead
                                    classes={classes}
                                />
                                <TableBody>
                                    {performanceReportsResult?.map((r) => {
                                        return (
                                            <StyledTableRow hover >
                                                <TableCell className={classes.reportCell}>
                                                    {r?.performanceReportName}
                                                </TableCell >
                                                <TableCell className={classes.reportCell}>
                                                    {r?.procedureDisplayName}
                                                </TableCell>
                                                <TableCell className={classes.reportCell}>
                                                    {moment(r?.createdDate).format(state.GlobalUtils?.settingValue)}
                                                </TableCell>
                                                <TableCell className={classes.reportCell} >
                                                    <EditReport row={r} />
                                                </TableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid component={Paper} container spacing={0}>
                    <Box m={0} width="65%">
                        <div className="footer">
                            <Footer />
                        </div>
                    </Box>
                    <Box m={0} width="35%">
                        <div className="pagination">
                            <TablePagination
                                id="Report_TablePagination"
                                rowsPerPageOptions={[15, 25, 50]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={pageNumber}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Box>
                </Grid>
            </div>


        </React.Fragment>

    )

}



export default ManageReports