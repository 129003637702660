import { Box, Button, Dialog, DialogContent, FormControl, Grid, makeStyles, Select, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CreateIcon from '@mui/icons-material/Create';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IAckList } from '../../models/IAckReport/IAckList';
import { IClientPhase } from '../../models/Placements/IClientPhase';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            width: "100%",
            marginTop: "5px",
            marginLeft: '5px',
            maxHeight: 500,
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        reportname: {
            margin: 8,
            width: '100%',
            borderBottom: '2px solid blue',
            borderRadius: '10px',
            boxShadow: '0px 0px 2px 0px rgba(103, 128, 159, 1)',
            marginTop: '25px'
        },
        checkboxText: {
            marginLeft: '3px',
            marginTop: '5px'
        },
        createReport: {
            borderRadius: '20px',
            marginBottom: "0.5cm",
            float: 'right',
            marginLeft: '25px'
        },
        filterbutton: {
            borderRadius: '20px',
            float: 'right',
            marginBottom: "0.5cm"
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        yesButton: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        MessageStyle: {
            marginTop: "15px",
            color: "black",
        },
        title: {
            alignContent: "left",
            color: "blue",
            marginTop: '0.7cm'
        },
        dropdown: {
            marginTop: '20px'
        },
        placementTitle: {
            color: 'blue',
            marginTop: '15px',
        },
        formControl: {
            minWidth: 600,
            marginRight: theme.spacing(1),
            alignItems: "left"
        },
        textField: {
            width: 400,
            backgroundColor: 'white',
            border: '2px solid blue',
            borderRadius: 5,
            float: 'left'
        },
        dropdownClient: {
            width: 400,
            float: 'left',
            backgroundColor: 'white',
            border: '2px solid blue',
            borderRadius: 5
        },
        paper1: {
            border: "2px solid black",
        },
        NoAccess: {
            color: 'red'
        },
        operatorBoxGap1: {
            gap: '50px',
            marginTop: '8px'
        },
        operatorBoxGap2: {
            gap: '72px',
            marginTop: '8px'
        },
        operatorBoxGap3: {
            gap: '40px',
            marginTop: '8px'
        },
        operatorBoxGap4: {
            gap: '100px',
            marginTop: '8px'
        },
        ReportHeaderStyle: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        ButtonStyle: {
            borderRadius: 20,
            backgroundColor: 'green',
            "&:hover": {
                backgroundColor: 'green'
            }
        }
    })
);

const EditAckReport: React.FC<{ reportRow?: IAckList, getReports?: () => void, reportId?: any, clients?: any }> = (props) => {
    const theme = useTheme();
    const classes = useRowStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [defaultReport, setDefaultReport] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [ReportName, setReportName] = useState("");
    const [disable, setDisable] = useState(false);
    const { state, dispatch } = useGlobalState();

    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");

    const [SelectedClientCode, setSelectedClientCode] = useState<any>([]);
    const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);

    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [phaseNumber, setPhaseNumber] = useState<number>(-1);

    useEffect(() => {
        if (state?.userAccessContext?.role === 2) {
            setDisable(false);
        } else if (state?.userAccessContext?.role === 3) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, []);

    useEffect(() => {
        if (localState.phaseName === "1STP") {
            setPhaseNumber(1);
        } else if (localState.phaseName === "PREC") {
            setPhaseNumber(2);
        } else if (localState.phaseName === "CONT") {
            setPhaseNumber(3);
        } else {
            setPhaseNumber(-1);
        }
    }, [phaseNumber]);

    var EditReportList: IAckList = {
        id: props.reportRow?.id,
        client_code: props.reportRow?.client_code,
        client_name: props.reportRow?.client_name,
        createdDate: props.reportRow?.createdDate,
        defaultReport: props.reportRow?.defaultReport,
        startDate: props.reportRow?.startDate,
        endDate: props.reportRow?.endDate,
        phaseName: props.reportRow?.phaseName,
        reportName: props.reportRow?.reportName,
        userId: props.reportRow?.userId
    }

    const [localState, setLocalState] = useState(EditReportList);

    const handleChangeReport = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value
        });
    };

    async function createReportAsync() {
        let request = {
            "reportId": props.reportRow.id,
            "reportName": localState.reportName,
            "client_code": SelectedClientCode.length === 0 ? props.reportRow.client_code : SelectedClientCode,
            "startDate": moment(localState.startDate).format("YYYY-MM-DD"),
            "endDate": moment(localState.endDate).format("YYYY-MM-DD"),
            "phaseId": selectedPhase,
            "default_report": defaultReport
        }
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        await usePost<any>("ModifyAckReport", request).then(() => {
            handleClose();
            props.getReports();
        }).finally(() => {
            setShowProgressBar(false);
        });
    }
    const handleClickOpen = () => {
        setOpen(true);
        if (props.reportRow.client_code != null) {
            useFetch<{ placementPhaseResults: IClientPhase[] }>(`Placements/GetPlacementPhase?clt_code=${props.reportRow.client_code}`).then((clientPhase) => {
                setClientPhase(clientPhase?.data?.['placementPhaseResults']);
            });
        }
        if (props.reportRow.phaseName === "1STP") {
            setSelectedPhase(1);
        } else if (props.reportRow.phaseName === "PREC") {
            setSelectedPhase(2);
        } else if (props.reportRow.phaseName === "CONT") {
            setSelectedPhase(3);
        } else {
            setSelectedPhase(-1);
        }
        setLocalState(EditReportList);
    };

    const handleChange = (event) => {
        setDefaultReport(event.target.checked);
        setIsDefault(event.target.checked);
    };

    const handleClose = () => {
        resetValues();
        setOpen(false);
        setIsDefault(false);
    };
    const resetValues = () => {
        setError(null);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const HandleReportNameVal = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(/[~`!@#^&*()+=%<>?.,:;{}|\\$'"]/)) {
            setError("");
            setReportName(newValue);
        } else {
            setError("please enter characters and numbers only");
        }
    };

    function onClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone?.client_code);
        (async () => {
            try {
                await useFetch<{ placementPhaseResults: IClientPhase[] }>(`Placements/GetPlacementPhase?clt_code=${valueone?.client_code}`).then((clientPhase) => {
                    setClientPhase(clientPhase?.data?.['placementPhaseResults']);
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const handlePhaseChange = (event) => {
        setSelectedPhase(parseInt(event.target.value));
    };

    return (
        <React.Fragment>
            <div>
                <Button className={classes.ButtonStyle} variant="contained" startIcon={<BorderColorIcon />} color="primary" onClick={handleClickOpen}>
                    EDIT
                </Button>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen} open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: 15 } }}
                    aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'} >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h5" gutterBottom className={classes.ReportHeaderStyle}>
                            EDIT ACKNOWLEDGEMENT REPORT
                        </Typography>
                    </DialogTitleHeader>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <DialogContent>
                        <Grid item xs={12}>
                            <TextField id="CR_Name"
                                className={classes.reportname}
                                name="reportName"
                                onChange={(e) => { handleChangeReport(e); HandleReportNameVal(e); }}
                                placeholder="Enter Report Name" fullWidth margin="normal" variant="outlined"
                                value={localState.reportName}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 20 }
                                }} required helperText={error} error={!!error} inputProps={{ maxlength: 100 }} />

                            <div className={classes.actions}>
                                <Box display="flex" className={classes.operatorBoxGap1}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE START DATE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant='outlined'
                                                id="date"
                                                type="date"
                                                name="startDate"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={moment(localState.startDate).format('YYYY-MM-DD')}
                                                onChange={(e) => { handleChangeReport(e); }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap2}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE END DATE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                variant='outlined'
                                                id="date"
                                                type="date"
                                                name="endDate"
                                                value={moment(localState.endDate).format('YYYY-MM-DD')}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => { handleChangeReport(e); }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap3}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE CLIENT CODE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl className={classes.formControl}>
                                            <Autocomplete
                                                id="clientCode-select"
                                                options={props.clients}
                                                value={props.reportRow}
                                                className={classes.dropdownClient}
                                                classes={{ paper: classes.paper1 }}
                                                autoHighlight
                                                onChange={onClientCodeSelect}
                                                getOptionLabel={(option) => option?.client_code + "--" + option?.client_name}
                                                getOptionSelected={(option, value) => option?.client_code === value?.client_code}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        {option?.client_code + "--" + option?.client_name}
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select client"
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" className={classes.operatorBoxGap4}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                                            <b> CHOOSE PHASE </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl id="Select_Phase" variant="outlined" className={classes.formControl} >
                                            <Select native value={selectedPhase === -1 ? props.reportRow.phaseName : selectedPhase} onChange={handlePhaseChange} className={classes.dropdownClient} name="phaseName">
                                                <option value={-1}>Select Phase</option>
                                                {clientPhase.map((phase) => {
                                                    return (
                                                        <>
                                                            {phase?.istParty ? <option value={1}>1st Party</option> : <option value={-1} className={classes.NoAccess}>1st Party</option>}
                                                            {phase?.isFlatFee ? <option value={2}>Flat Fee</option> : <option value={-1} className={classes.NoAccess}>Flat Fee</option>}
                                                            {phase?.isContingency ? <option value={3}>Contingency</option> : <option value={-1} className={classes.NoAccess}>Contingency</option>}
                                                        </>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </div>
                            <Box p={2} flexShrink={0} bgcolor="white">
                                <Button autoFocus color="primary" variant="contained"
                                    onClick={() => { createReportAsync(); }} startIcon={<CreateIcon />} className={classes.createReport}
                                    disabled={!localState.reportName || !localState.startDate || !localState.endDate || selectedPhase == -1 || !SelectedClientCode}>
                                    Update Report
                                </Button>
                            </Box>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        </React.Fragment >
    );
}

export default EditAckReport