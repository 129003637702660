import DateFnsUtils from '@date-io/date-fns';
import {
  Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, makeStyles, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import StyledCell from "../../../components/GlobalStyles/StyledCell";
import clearIcon from '../../../images/buttonIcons/clearIcon.svg';
import { IPipelineDetails } from '../../../models/GlobalUtil/IPipelineDetails';
import { IPipelineLogDetails } from '../../../models/GlobalUtil/IPipelineLogDetails';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 400,
  },
  userStyle: {
    width: '400px',
    marginTop: '-6px',
  },
  button: {
    borderRadius: 20,
    marginRight: theme.spacing(5),
    marginTop: '15px',
    fontSize: 12,
  },
  button1: {
    borderRadius: 20,
    marginTop: '15px',
    fontSize: 12,
  },
  textField: {
    marginTop: "10px",
    width: 350,
    marginRight: '10px',
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  grid: {
    padding: '8px'
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  root: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'red'
  },
  Title1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '10px',
    color: "blue"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 485,
    minHeight: 485,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 220px)',
      minHeight: 'calc( 100vh - 220px)',
    },
  },
  iconStyle: {
    width: '16px',
    marginTop: '-5px',
  },
  paper1: {
    border: "2px solid black",
  },
  TableCellHeaderStyle: {
    background: "#007FFF",
    color: "white"
  },
  ErrorMessageStyle: {
    color: "red",
    marginTop: "10px",
    textAlign: 'center'
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '80px'
  },
  tableheader: {
    background: "#007FFF",
    color: "white",
    fontSize: 12,
    padding: '3px 15px'
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '5px 15px'
  },
  norecord: {
    color: "red",
    marginTop: "10px"
  },
  smallFont: {
    fontSize: 12,
  },
  titleheader1: {
    marginLeft: '10px',
    marginTop: '5px'
  },
  filterStyle: {
    gap: '5%'
  }
}))

const usePopoverStyles = makeStyles({
  paper: {
    border: '2px solid black'
  }
});


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableheader}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  { id: "triggerName", numeric: false, disablePadding: false, label: "TRIGGER NAME" },
  { id: "pipelineName", numeric: false, disablePadding: false, label: "PIPELINE NAME", sortable: true },
  { id: "triggerTime", numeric: false, disablePadding: false, label: "TRIGGER DATE TIME", sortable: true },
  { id: "triggerType", numeric: false, disablePadding: false, label: "TRIGGER TYPE", sortable: true },
  { id: "adfName", numeric: false, disablePadding: false, label: "ADF NAME", sortable: true },
];

const AdfPipelineLogs = () => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { state, dispatch } = useGlobalState()
  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const [PipelineDetails, setPipelineDetails] = useState<IPipelineDetails[]>([]);
  const [AdfLogsHistory, setAdfLogsHistory] = useState<IPipelineLogDetails[]>([]);
  const [SelectPipeline, setSelectPipeline] = useState<any>();

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);

  const [StartDate, setStartDateFrom] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [clearFilter, setClearFilter] = React.useState(false);
  const [mapped, setMapped] = useState(false);

  const [orderBy, setOrderBy] = React.useState(" ");
  const [order, setOrder] = React.useState("asc");

  const pipelineLoading = PipelineDetails.length === 0;

  function resetvalues() {
    setSelectedFirstDate(false);
    setSelectedEndDate(false);
    setStartDateFrom(null);
    setEndDate(null);
    setSelectPipeline(null);
    setPage(0);
    setSelectedPipeline(false);
    setSelectPipeline([]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setClearFilter(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setshowProgressBar(true);
    setPage(newPage);
  };

  const handleFirstDateChange = (date: Date | null) => {
    setStartDateFrom(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setshowProgressBar(true);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        await useFetch<IPipelineDetails[]>("GlobalSettings/GetADFPipelineDetails").then((GetPipelineOptions) => {
          setPipelineDetails(GetPipelineOptions.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const onPipelineSelect = (event, selected) => {
    if (selected) {
      const selectedValue = PipelineDetails.find(x => x.pipelineName === selected.pipelineName);
      setSelectPipeline(selectedValue.pipelineName);
      setSelectedPipeline(true);
      setPage(0);
    }
    else {
      setSelectPipeline({ pipelineName: "", activityType: "" });
    }
  };

  useEffect(() => {
    getADFHistoryAsync()
  }, [page, rowsPerPage, order, orderBy])

  const TriggerFilter = () => {
    getADFHistoryAsync();
    setOpen(true);
  }

  async function getADFHistoryAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    let request = {
      "runDateFrom": (selectedFirstDate && StartDate === null ? null : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
      "runDateTo": (selectedEndDate && EndDate === null ? null : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
      "pipelineName": (selectedPipeline ? (SelectPipeline.pipelineName === "" ? null : SelectPipeline) : null),
      "startRecordNumber": (page * rowsPerPage) + 1,
      "rowperpage": rowsPerPage,
    };
    await usePost<{ adfLogs: IPipelineLogDetails[], qty: number }>("GlobalSettings/GetADFPipelineLogs", request).then((GetADFPipelineLogsList) => {
      setAdfLogsHistory(GetADFPipelineLogsList.data["adfLogs"]);
      setMapped(!GetADFPipelineLogsList.data["adfLogs"].length ? true : false);
      setTotalRecords(GetADFPipelineLogsList.data.qty);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  async function ClearADFHistoryAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    let request = {
      "runDateFrom": null,
      "runDateTo": null,
      "pipelineName": null,
      "startRecordNumber": (page * rowsPerPage) + 1,
      "rowperpage": rowsPerPage,
    };
    await usePost<{ adfLogs: IPipelineLogDetails[], qty: number }>("GlobalSettings/GetADFPipelineLogs", request).then((GetADFPipelineLogsList) => {
      if (GetADFPipelineLogsList.status == 200) {
        setClearFilter(true);
      }
      setAdfLogsHistory(GetADFPipelineLogsList.data["adfLogs"]);
      setMapped(!GetADFPipelineLogsList.data["adfLogs"].length ? true : false);
      setTotalRecords(GetADFPipelineLogsList.data.qty);
    }).finally(() => {
      resetvalues();
      setshowProgressBar(false);
      setSelectPipeline([]);
    });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />

        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.Title}>
            {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>End date should be greater than the Start date!</Typography> : ""}
          </Grid>

          <Grid container className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={4} sm={3} md={3} lg={2} className={classes.Title1} >
              <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleheader1}`}>
                <b> ADF ACTIVITY LOGS</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    size="small"
                    disableToolbar
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_StartDate"
                    value={StartDate}
                    onChange={handleFirstDateChange}
                    disableFuture={true}
                    PopoverProps={{ classes: popoverClasses }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    size="small"
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="End Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_EndDate"
                    value={EndDate}
                    onChange={handleEndDateChange}
                    inputVariant="outlined"
                    PopoverProps={{ classes: popoverClasses }}
                    disableFuture={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete size="small"
                  id="UpHis_SelectUsers"
                  autoComplete autoHighlight
                  classes={{
                    paper: classes.paper1,
                    option: classes.smallFont
                  }}
                  options={PipelineDetails}
                  getOptionLabel={(option) => option.pipelineName}
                  value={SelectPipeline}
                  className={classes.userStyle}
                  onChange={onPipelineSelect}
                  loading={pipelineLoading}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span> {option.pipelineName}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Pipeline Name" margin="normal" variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {pipelineLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={3} justify='space-evenly'>
                <Button id="UpHis_Filter_btn" size="small" className={classes.button}
                  onClick={() => { TriggerFilter(); }} variant="contained" startIcon={<FilterAltIcon />} color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" size="small" className={classes.button1} startIcon={<img src={clearIcon} alt="Icon for clear filter" className={classes.iconStyle} />}
                  onClick={() => { ClearADFHistoryAsync(); setshowProgressBar(true); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item xs={4} sm={6} md={4} lg={2} alignContent='flex-end' >
                <IconButton id="UpHis_FilterIcon_btn" size="small" aria-label="Filter" style={{ color: 'blue' }} disabled={StartDate > EndDate} >
                  <FilterListIcon style={{ fontSize: 25 }} />
                </IconButton>
                <IconButton id="UpLog_Filter_IconButton" size="small" aria-label="Filter" onClick={() => { ClearADFHistoryAsync(); }} style={{ color: 'blue' }} >
                  <ClearRoundedIcon style={{ fontSize: 25 }} />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid component={Paper} item xs={12}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(AdfLogsHistory, getComparator(order, orderBy)).map(
                      (row) => {
                        return (
                          <TableRow >
                            <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                              {row.triggerName}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.pipelineName}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {moment(row.triggerTime).format(state.GlobalUtils?.settingValue)}
                              {moment(row.triggerTime).format(' HH:mm:ss')}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.triggerType}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.adfName}
                            </StyledCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {mapped === true ?
                  < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography>
                  : null}
              </TableContainer>
              <Grid container spacing={0}>
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">
                    <TablePagination
                      id="UpHis_TablePagination"
                      rowsPerPageOptions={[15, 25, 50]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar className="snackBarStyle"
          open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
          autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Successfully Triggered!
          </Alert>
        </Snackbar>
        <Snackbar className="snackBarStyle"
          open={clearFilter} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
          autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="alertStyle">
            Filter Cleared Successfully !
          </Alert>
        </Snackbar>

      </div>
    </React.Fragment >
  )
}

export default AdfPipelineLogs