import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    tablebody: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 80px)',
            minHeight: 'calc( 100vh - 80px)',
        },
    },
}))

export {useStyles}